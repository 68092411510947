/* import the necessary Bootstrap files */
$primary: $primary-color;

@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.nav-tabs {
    display: flex;
    flex-direction: row;
    gap: 4px;

    border-bottom: unset;

    .nav-item {
        .nav-link {
            /* Frame 10256 */
            box-sizing: border-box;

            /* Auto layout */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px;
            gap: 4px;

            /* Base Color /Dark #100 */
            border: 1px solid #E8E9EB;
            border-radius: 8px;
            color: #616371;

            &.active {
                border: 1px solid $primary;
                background-color: transparent;
                color: $primary-color;
            }
        }
    }
}

.modal-content {
    border-radius: 12px;
}