@import "./abstract/variables";
@import "./theme/fonts";
@import "./theme/font-gilroy";
@import "./theme/bootstrap.scss";

//@import "./theme/modifications";
//@import "./theme/default";
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  font-family: 'Gilroy', sans-serif !important;
  font-size: 0.9rem !important;
  transition: all .2s;
}

#root {
  height: 100%;
}

.highcharts-credits {
  display: none !important;
}

h3 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

p {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base Color /Dark #700 */
  color: #616371;
}

.modal .modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.table {
  thead {
    tr  {
      th {        
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        /* Base Color /Dark #600 */
        color: #787985;
        
        &.col-actions {
          width: 110px !important;
        }
      }
    }
  }
  tbody {
    tr {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */


      /* Base Color /Dark #800 */
      color: #4B4D5D;
      &.deleting {
        background-color: rgba(255, 50, 50, 0.2) !important;
      }
    }
  }

}
.spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.btn-sm {
  font-size: 0.6rem !important;
}
.modal {
  background-color: rgba(10, 10, 10, 0.3);
}

.btn:focus {
  box-shadow: none !important;
}
.content-filter {
  .react-datepicker-wrapper {
    width: auto !important;

    .form-control {
      font-size: .85rem !important;
    }
  }

  .input-group-text, .btn {
    height: 2.15rem !important;
  }

  .btn {
    border-color: #ccc;
  }
}

.shadow-hover:hover {
  box-shadow: 0 0 0.5cm rgba(100,100,100,0.2);
  transition: all .2s ease-in-out;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #e0e0e0 !important;
  opacity: 1; /* Firefox */
}

.table > :not(:first-child) {
   border-top: unset !important;
}


.chip {
  border: 1px solid #dadada;
  height: 2rem;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}
.highcharts-series.highcharts-line-series path {
  stroke-width: 1;
}

page[size="TRA"] {
  background: white;
  width: 3in;
  height: 7in;
  display: block;
  margin: 0 auto 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

.expandable {
  &>.expander-icon {
    cursor: pointer;
    &:hover {
      color: rgba(50, 140, 250, 0.4);
    }
  }
  transition: all 0.5s ease;
  &.expanded {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin: 0 !important;
    transition: all 0.5s ease;
  }
}

@media only screen and (max-width: 768px) {
  .modal .modal-xl {
    width: 100% !important;
  }
  .modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}
.d-none, .d-block {
  transition: all .5s;
}
