@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Thin.otf") format("opentype");
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-UltraLight.otf") format("opentype");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Light.otf") format("opentype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Medium.otf") format("opentype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-SemiBold.otf") format("opentype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Bold.otf") format("opentype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf") format("opentype");
    font-style: normal;
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url("/src/assets/fonts/Radomir\ Tinkov\ -\ Gilroy-Black.otf") format("opentype");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}