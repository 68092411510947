@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .i1Ob-q_h6, h5, .i1Ob-q_h5, h4, .i1Ob-q_h4, h3, .i1Ob-q_h3, h2, .i1Ob-q_h2, h1, .i1Ob-q_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .i1Ob-q_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .i1Ob-q_h1 {
    font-size: 2.5rem;
  }
}

h2, .i1Ob-q_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .i1Ob-q_h2 {
    font-size: 2rem;
  }
}

h3, .i1Ob-q_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .i1Ob-q_h3 {
    font-size: 1.75rem;
  }
}

h4, .i1Ob-q_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .i1Ob-q_h4 {
    font-size: 1.5rem;
  }
}

h5, .i1Ob-q_h5 {
  font-size: 1.25rem;
}

h6, .i1Ob-q_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .i1Ob-q_small {
  font-size: .875em;
}

mark, .i1Ob-q_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.i1Ob-q_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.i1Ob-q_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-1 {
    font-size: 5rem;
  }
}

.i1Ob-q_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-2 {
    font-size: 4.5rem;
  }
}

.i1Ob-q_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-3 {
    font-size: 4rem;
  }
}

.i1Ob-q_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-4 {
    font-size: 3.5rem;
  }
}

.i1Ob-q_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-5 {
    font-size: 3rem;
  }
}

.i1Ob-q_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .i1Ob-q_display-6 {
    font-size: 2.5rem;
  }
}

.i1Ob-q_list-unstyled, .i1Ob-q_list-inline {
  padding-left: 0;
  list-style: none;
}

.i1Ob-q_list-inline-item {
  display: inline-block;
}

.i1Ob-q_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.i1Ob-q_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.i1Ob-q_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.i1Ob-q_blockquote > :last-child {
  margin-bottom: 0;
}

.i1Ob-q_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.i1Ob-q_blockquote-footer:before {
  content: "— ";
}

.i1Ob-q_img-fluid {
  max-width: 100%;
  height: auto;
}

.i1Ob-q_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.i1Ob-q_figure {
  display: inline-block;
}

.i1Ob-q_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.i1Ob-q_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.i1Ob-q_container, .i1Ob-q_container-fluid, .i1Ob-q_container-xxl, .i1Ob-q_container-xl, .i1Ob-q_container-lg, .i1Ob-q_container-md, .i1Ob-q_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .i1Ob-q_container-sm, .i1Ob-q_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_container-md, .i1Ob-q_container-sm, .i1Ob-q_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_container-lg, .i1Ob-q_container-md, .i1Ob-q_container-sm, .i1Ob-q_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_container-xl, .i1Ob-q_container-lg, .i1Ob-q_container-md, .i1Ob-q_container-sm, .i1Ob-q_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_container-xxl, .i1Ob-q_container-xl, .i1Ob-q_container-lg, .i1Ob-q_container-md, .i1Ob-q_container-sm, .i1Ob-q_container {
    max-width: 1320px;
  }
}

.i1Ob-q_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.i1Ob-q_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.i1Ob-q_col {
  flex: 1 0;
}

.i1Ob-q_row-cols-auto > * {
  width: auto;
  flex: none;
}

.i1Ob-q_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.i1Ob-q_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.i1Ob-q_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.i1Ob-q_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.i1Ob-q_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.i1Ob-q_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.i1Ob-q_col-auto {
  width: auto;
  flex: none;
}

.i1Ob-q_col-1 {
  width: 8.33333%;
  flex: none;
}

.i1Ob-q_col-2 {
  width: 16.6667%;
  flex: none;
}

.i1Ob-q_col-3 {
  width: 25%;
  flex: none;
}

.i1Ob-q_col-4 {
  width: 33.3333%;
  flex: none;
}

.i1Ob-q_col-5 {
  width: 41.6667%;
  flex: none;
}

.i1Ob-q_col-6 {
  width: 50%;
  flex: none;
}

.i1Ob-q_col-7 {
  width: 58.3333%;
  flex: none;
}

.i1Ob-q_col-8 {
  width: 66.6667%;
  flex: none;
}

.i1Ob-q_col-9 {
  width: 75%;
  flex: none;
}

.i1Ob-q_col-10 {
  width: 83.3333%;
  flex: none;
}

.i1Ob-q_col-11 {
  width: 91.6667%;
  flex: none;
}

.i1Ob-q_col-12 {
  width: 100%;
  flex: none;
}

.i1Ob-q_offset-1 {
  margin-left: 8.33333%;
}

.i1Ob-q_offset-2 {
  margin-left: 16.6667%;
}

.i1Ob-q_offset-3 {
  margin-left: 25%;
}

.i1Ob-q_offset-4 {
  margin-left: 33.3333%;
}

.i1Ob-q_offset-5 {
  margin-left: 41.6667%;
}

.i1Ob-q_offset-6 {
  margin-left: 50%;
}

.i1Ob-q_offset-7 {
  margin-left: 58.3333%;
}

.i1Ob-q_offset-8 {
  margin-left: 66.6667%;
}

.i1Ob-q_offset-9 {
  margin-left: 75%;
}

.i1Ob-q_offset-10 {
  margin-left: 83.3333%;
}

.i1Ob-q_offset-11 {
  margin-left: 91.6667%;
}

.i1Ob-q_g-0, .i1Ob-q_gx-0 {
  --bs-gutter-x: 0;
}

.i1Ob-q_g-0, .i1Ob-q_gy-0 {
  --bs-gutter-y: 0;
}

.i1Ob-q_g-1, .i1Ob-q_gx-1 {
  --bs-gutter-x: .25rem;
}

.i1Ob-q_g-1, .i1Ob-q_gy-1 {
  --bs-gutter-y: .25rem;
}

.i1Ob-q_g-2, .i1Ob-q_gx-2 {
  --bs-gutter-x: .5rem;
}

.i1Ob-q_g-2, .i1Ob-q_gy-2 {
  --bs-gutter-y: .5rem;
}

.i1Ob-q_g-3, .i1Ob-q_gx-3 {
  --bs-gutter-x: 1rem;
}

.i1Ob-q_g-3, .i1Ob-q_gy-3 {
  --bs-gutter-y: 1rem;
}

.i1Ob-q_g-4, .i1Ob-q_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.i1Ob-q_g-4, .i1Ob-q_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.i1Ob-q_g-5, .i1Ob-q_gx-5 {
  --bs-gutter-x: 3rem;
}

.i1Ob-q_g-5, .i1Ob-q_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .i1Ob-q_col-sm {
    flex: 1 0;
  }

  .i1Ob-q_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .i1Ob-q_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-sm-auto {
    width: auto;
    flex: none;
  }

  .i1Ob-q_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .i1Ob-q_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .i1Ob-q_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .i1Ob-q_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .i1Ob-q_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .i1Ob-q_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .i1Ob-q_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .i1Ob-q_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_offset-sm-0 {
    margin-left: 0;
  }

  .i1Ob-q_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .i1Ob-q_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .i1Ob-q_offset-sm-3 {
    margin-left: 25%;
  }

  .i1Ob-q_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .i1Ob-q_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .i1Ob-q_offset-sm-6 {
    margin-left: 50%;
  }

  .i1Ob-q_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .i1Ob-q_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .i1Ob-q_offset-sm-9 {
    margin-left: 75%;
  }

  .i1Ob-q_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .i1Ob-q_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .i1Ob-q_g-sm-0, .i1Ob-q_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .i1Ob-q_g-sm-0, .i1Ob-q_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .i1Ob-q_g-sm-1, .i1Ob-q_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .i1Ob-q_g-sm-1, .i1Ob-q_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .i1Ob-q_g-sm-2, .i1Ob-q_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .i1Ob-q_g-sm-2, .i1Ob-q_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .i1Ob-q_g-sm-3, .i1Ob-q_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .i1Ob-q_g-sm-3, .i1Ob-q_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .i1Ob-q_g-sm-4, .i1Ob-q_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .i1Ob-q_g-sm-4, .i1Ob-q_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .i1Ob-q_g-sm-5, .i1Ob-q_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .i1Ob-q_g-sm-5, .i1Ob-q_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_col-md {
    flex: 1 0;
  }

  .i1Ob-q_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .i1Ob-q_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .i1Ob-q_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-md-auto {
    width: auto;
    flex: none;
  }

  .i1Ob-q_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .i1Ob-q_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-md-3 {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .i1Ob-q_col-md-6 {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .i1Ob-q_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .i1Ob-q_col-md-9 {
    width: 75%;
    flex: none;
  }

  .i1Ob-q_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .i1Ob-q_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .i1Ob-q_col-md-12 {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_offset-md-0 {
    margin-left: 0;
  }

  .i1Ob-q_offset-md-1 {
    margin-left: 8.33333%;
  }

  .i1Ob-q_offset-md-2 {
    margin-left: 16.6667%;
  }

  .i1Ob-q_offset-md-3 {
    margin-left: 25%;
  }

  .i1Ob-q_offset-md-4 {
    margin-left: 33.3333%;
  }

  .i1Ob-q_offset-md-5 {
    margin-left: 41.6667%;
  }

  .i1Ob-q_offset-md-6 {
    margin-left: 50%;
  }

  .i1Ob-q_offset-md-7 {
    margin-left: 58.3333%;
  }

  .i1Ob-q_offset-md-8 {
    margin-left: 66.6667%;
  }

  .i1Ob-q_offset-md-9 {
    margin-left: 75%;
  }

  .i1Ob-q_offset-md-10 {
    margin-left: 83.3333%;
  }

  .i1Ob-q_offset-md-11 {
    margin-left: 91.6667%;
  }

  .i1Ob-q_g-md-0, .i1Ob-q_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .i1Ob-q_g-md-0, .i1Ob-q_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .i1Ob-q_g-md-1, .i1Ob-q_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .i1Ob-q_g-md-1, .i1Ob-q_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .i1Ob-q_g-md-2, .i1Ob-q_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .i1Ob-q_g-md-2, .i1Ob-q_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .i1Ob-q_g-md-3, .i1Ob-q_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .i1Ob-q_g-md-3, .i1Ob-q_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .i1Ob-q_g-md-4, .i1Ob-q_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .i1Ob-q_g-md-4, .i1Ob-q_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .i1Ob-q_g-md-5, .i1Ob-q_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .i1Ob-q_g-md-5, .i1Ob-q_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_col-lg {
    flex: 1 0;
  }

  .i1Ob-q_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .i1Ob-q_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-lg-auto {
    width: auto;
    flex: none;
  }

  .i1Ob-q_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .i1Ob-q_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .i1Ob-q_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .i1Ob-q_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .i1Ob-q_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .i1Ob-q_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .i1Ob-q_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .i1Ob-q_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_offset-lg-0 {
    margin-left: 0;
  }

  .i1Ob-q_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .i1Ob-q_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .i1Ob-q_offset-lg-3 {
    margin-left: 25%;
  }

  .i1Ob-q_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .i1Ob-q_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .i1Ob-q_offset-lg-6 {
    margin-left: 50%;
  }

  .i1Ob-q_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .i1Ob-q_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .i1Ob-q_offset-lg-9 {
    margin-left: 75%;
  }

  .i1Ob-q_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .i1Ob-q_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .i1Ob-q_g-lg-0, .i1Ob-q_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .i1Ob-q_g-lg-0, .i1Ob-q_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .i1Ob-q_g-lg-1, .i1Ob-q_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .i1Ob-q_g-lg-1, .i1Ob-q_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .i1Ob-q_g-lg-2, .i1Ob-q_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .i1Ob-q_g-lg-2, .i1Ob-q_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .i1Ob-q_g-lg-3, .i1Ob-q_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .i1Ob-q_g-lg-3, .i1Ob-q_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .i1Ob-q_g-lg-4, .i1Ob-q_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .i1Ob-q_g-lg-4, .i1Ob-q_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .i1Ob-q_g-lg-5, .i1Ob-q_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .i1Ob-q_g-lg-5, .i1Ob-q_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_col-xl {
    flex: 1 0;
  }

  .i1Ob-q_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .i1Ob-q_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-xl-auto {
    width: auto;
    flex: none;
  }

  .i1Ob-q_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .i1Ob-q_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .i1Ob-q_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .i1Ob-q_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .i1Ob-q_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .i1Ob-q_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .i1Ob-q_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .i1Ob-q_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_offset-xl-0 {
    margin-left: 0;
  }

  .i1Ob-q_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .i1Ob-q_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .i1Ob-q_offset-xl-3 {
    margin-left: 25%;
  }

  .i1Ob-q_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .i1Ob-q_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .i1Ob-q_offset-xl-6 {
    margin-left: 50%;
  }

  .i1Ob-q_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .i1Ob-q_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .i1Ob-q_offset-xl-9 {
    margin-left: 75%;
  }

  .i1Ob-q_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .i1Ob-q_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .i1Ob-q_g-xl-0, .i1Ob-q_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .i1Ob-q_g-xl-0, .i1Ob-q_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .i1Ob-q_g-xl-1, .i1Ob-q_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .i1Ob-q_g-xl-1, .i1Ob-q_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .i1Ob-q_g-xl-2, .i1Ob-q_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .i1Ob-q_g-xl-2, .i1Ob-q_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .i1Ob-q_g-xl-3, .i1Ob-q_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .i1Ob-q_g-xl-3, .i1Ob-q_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .i1Ob-q_g-xl-4, .i1Ob-q_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .i1Ob-q_g-xl-4, .i1Ob-q_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .i1Ob-q_g-xl-5, .i1Ob-q_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .i1Ob-q_g-xl-5, .i1Ob-q_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_col-xxl {
    flex: 1 0;
  }

  .i1Ob-q_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .i1Ob-q_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .i1Ob-q_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .i1Ob-q_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .i1Ob-q_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .i1Ob-q_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .i1Ob-q_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .i1Ob-q_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .i1Ob-q_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .i1Ob-q_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .i1Ob-q_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .i1Ob-q_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .i1Ob-q_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .i1Ob-q_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .i1Ob-q_offset-xxl-0 {
    margin-left: 0;
  }

  .i1Ob-q_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .i1Ob-q_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .i1Ob-q_offset-xxl-3 {
    margin-left: 25%;
  }

  .i1Ob-q_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .i1Ob-q_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .i1Ob-q_offset-xxl-6 {
    margin-left: 50%;
  }

  .i1Ob-q_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .i1Ob-q_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .i1Ob-q_offset-xxl-9 {
    margin-left: 75%;
  }

  .i1Ob-q_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .i1Ob-q_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .i1Ob-q_g-xxl-0, .i1Ob-q_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .i1Ob-q_g-xxl-0, .i1Ob-q_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .i1Ob-q_g-xxl-1, .i1Ob-q_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .i1Ob-q_g-xxl-1, .i1Ob-q_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .i1Ob-q_g-xxl-2, .i1Ob-q_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .i1Ob-q_g-xxl-2, .i1Ob-q_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .i1Ob-q_g-xxl-3, .i1Ob-q_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .i1Ob-q_g-xxl-3, .i1Ob-q_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .i1Ob-q_g-xxl-4, .i1Ob-q_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .i1Ob-q_g-xxl-4, .i1Ob-q_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .i1Ob-q_g-xxl-5, .i1Ob-q_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .i1Ob-q_g-xxl-5, .i1Ob-q_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.i1Ob-q_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.i1Ob-q_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.i1Ob-q_table > tbody {
  vertical-align: inherit;
}

.i1Ob-q_table > thead {
  vertical-align: bottom;
}

.i1Ob-q_table-group-divider {
  border-top: 2px solid;
}

.i1Ob-q_caption-top {
  caption-side: top;
}

.i1Ob-q_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.i1Ob-q_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.i1Ob-q_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.i1Ob-q_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.i1Ob-q_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.i1Ob-q_table-striped > tbody > tr:nth-of-type(2n+1) > *, .i1Ob-q_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.i1Ob-q_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.i1Ob-q_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.i1Ob-q_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.i1Ob-q_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .i1Ob-q_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .i1Ob-q_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .i1Ob-q_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .i1Ob-q_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .i1Ob-q_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.i1Ob-q_form-label {
  margin-bottom: .5rem;
}

.i1Ob-q_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.i1Ob-q_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.i1Ob-q_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.i1Ob-q_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.i1Ob-q_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-control {
    transition: none;
  }
}

.i1Ob-q_form-control[type="file"] {
  overflow: hidden;
}

.i1Ob-q_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.i1Ob-q_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.i1Ob-q_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.i1Ob-q_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.i1Ob-q_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-control::file-selector-button {
    transition: none;
  }
}

.i1Ob-q_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.i1Ob-q_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.i1Ob-q_form-control-plaintext:focus {
  outline: 0;
}

.i1Ob-q_form-control-plaintext.i1Ob-q_form-control-sm, .i1Ob-q_form-control-plaintext.i1Ob-q_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.i1Ob-q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.i1Ob-q_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.i1Ob-q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.i1Ob-q_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.i1Ob-q_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.i1Ob-q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.i1Ob-q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.i1Ob-q_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.i1Ob-q_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.i1Ob-q_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.i1Ob-q_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.i1Ob-q_form-control-color.i1Ob-q_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.i1Ob-q_form-control-color.i1Ob-q_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.i1Ob-q_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-select {
    transition: none;
  }
}

.i1Ob-q_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_form-select[multiple], .i1Ob-q_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.i1Ob-q_form-select:disabled {
  background-color: #e9ecef;
}

.i1Ob-q_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.i1Ob-q_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.i1Ob-q_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.i1Ob-q_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.i1Ob-q_form-check .i1Ob-q_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.i1Ob-q_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.i1Ob-q_form-check-reverse .i1Ob-q_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.i1Ob-q_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.i1Ob-q_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.i1Ob-q_form-check-input[type="radio"] {
  border-radius: 50%;
}

.i1Ob-q_form-check-input:active {
  filter: brightness(90%);
}

.i1Ob-q_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.i1Ob-q_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.i1Ob-q_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.i1Ob-q_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.i1Ob-q_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.i1Ob-q_form-check-input[disabled] ~ .i1Ob-q_form-check-label, .i1Ob-q_form-check-input:disabled ~ .i1Ob-q_form-check-label {
  cursor: default;
  opacity: .5;
}

.i1Ob-q_form-switch {
  padding-left: 2.5em;
}

.i1Ob-q_form-switch .i1Ob-q_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-switch .i1Ob-q_form-check-input {
    transition: none;
  }
}

.i1Ob-q_form-switch .i1Ob-q_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.i1Ob-q_form-switch .i1Ob-q_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.i1Ob-q_form-switch.i1Ob-q_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.i1Ob-q_form-switch.i1Ob-q_form-check-reverse .i1Ob-q_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.i1Ob-q_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.i1Ob-q_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.i1Ob-q_btn-check[disabled] + .i1Ob-q_btn, .i1Ob-q_btn-check:disabled + .i1Ob-q_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.i1Ob-q_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.i1Ob-q_form-range:focus {
  outline: 0;
}

.i1Ob-q_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_form-range::-moz-focus-outer {
  border: 0;
}

.i1Ob-q_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.i1Ob-q_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.i1Ob-q_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.i1Ob-q_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-range::-moz-range-thumb {
    transition: none;
  }
}

.i1Ob-q_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.i1Ob-q_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.i1Ob-q_form-range:disabled {
  pointer-events: none;
}

.i1Ob-q_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.i1Ob-q_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.i1Ob-q_form-floating {
  position: relative;
}

.i1Ob-q_form-floating > .i1Ob-q_form-control, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext, .i1Ob-q_form-floating > .i1Ob-q_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.i1Ob-q_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_form-floating > label {
    transition: none;
  }
}

.i1Ob-q_form-floating > .i1Ob-q_form-control, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext {
  padding: 1rem .75rem;
}

.i1Ob-q_form-floating > .i1Ob-q_form-control::placeholder, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext::placeholder {
  color: #0000;
}

.i1Ob-q_form-floating > .i1Ob-q_form-control:focus, .i1Ob-q_form-floating > .i1Ob-q_form-control:not(:placeholder-shown), .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext:focus, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.i1Ob-q_form-floating > .i1Ob-q_form-control:-webkit-autofill, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.i1Ob-q_form-floating > .i1Ob-q_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.i1Ob-q_form-floating > .i1Ob-q_form-control:focus ~ label, .i1Ob-q_form-floating > .i1Ob-q_form-control:not(:placeholder-shown) ~ label, .i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext ~ label, .i1Ob-q_form-floating > .i1Ob-q_form-select ~ label, .i1Ob-q_form-floating > .i1Ob-q_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.i1Ob-q_form-floating > .i1Ob-q_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.i1Ob-q_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.i1Ob-q_input-group > .i1Ob-q_form-control, .i1Ob-q_input-group > .i1Ob-q_form-select, .i1Ob-q_input-group > .i1Ob-q_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.i1Ob-q_input-group > .i1Ob-q_form-control:focus, .i1Ob-q_input-group > .i1Ob-q_form-select:focus, .i1Ob-q_input-group > .i1Ob-q_form-floating:focus-within {
  z-index: 5;
}

.i1Ob-q_input-group .i1Ob-q_btn {
  z-index: 2;
  position: relative;
}

.i1Ob-q_input-group .i1Ob-q_btn:focus {
  z-index: 5;
}

.i1Ob-q_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.i1Ob-q_input-group-lg > .i1Ob-q_form-control, .i1Ob-q_input-group-lg > .i1Ob-q_form-select, .i1Ob-q_input-group-lg > .i1Ob-q_input-group-text, .i1Ob-q_input-group-lg > .i1Ob-q_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.i1Ob-q_input-group-sm > .i1Ob-q_form-control, .i1Ob-q_input-group-sm > .i1Ob-q_form-select, .i1Ob-q_input-group-sm > .i1Ob-q_input-group-text, .i1Ob-q_input-group-sm > .i1Ob-q_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.i1Ob-q_input-group-lg > .i1Ob-q_form-select, .i1Ob-q_input-group-sm > .i1Ob-q_form-select {
  padding-right: 3rem;
}

.i1Ob-q_input-group:not(.i1Ob-q_has-validation) > :not(:last-child):not(.i1Ob-q_dropdown-toggle):not(.i1Ob-q_dropdown-menu):not(.i1Ob-q_form-floating), .i1Ob-q_input-group:not(.i1Ob-q_has-validation) > .i1Ob-q_dropdown-toggle:nth-last-child(n+3), .i1Ob-q_input-group:not(.i1Ob-q_has-validation) > .i1Ob-q_form-floating:not(:last-child) > .i1Ob-q_form-control, .i1Ob-q_input-group:not(.i1Ob-q_has-validation) > .i1Ob-q_form-floating:not(:last-child) > .i1Ob-q_form-select, .i1Ob-q_input-group.i1Ob-q_has-validation > :nth-last-child(n+3):not(.i1Ob-q_dropdown-toggle):not(.i1Ob-q_dropdown-menu):not(.i1Ob-q_form-floating), .i1Ob-q_input-group.i1Ob-q_has-validation > .i1Ob-q_dropdown-toggle:nth-last-child(n+4), .i1Ob-q_input-group.i1Ob-q_has-validation > .i1Ob-q_form-floating:nth-last-child(n+3) > .i1Ob-q_form-control, .i1Ob-q_input-group.i1Ob-q_has-validation > .i1Ob-q_form-floating:nth-last-child(n+3) > .i1Ob-q_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.i1Ob-q_input-group > :not(:first-child):not(.i1Ob-q_dropdown-menu):not(.i1Ob-q_valid-tooltip):not(.i1Ob-q_valid-feedback):not(.i1Ob-q_invalid-tooltip):not(.i1Ob-q_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.i1Ob-q_input-group > .i1Ob-q_form-floating:not(:first-child) > .i1Ob-q_form-control, .i1Ob-q_input-group > .i1Ob-q_form-floating:not(:first-child) > .i1Ob-q_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.i1Ob-q_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.i1Ob-q_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.i1Ob-q_was-validated :valid ~ .i1Ob-q_valid-feedback, .i1Ob-q_was-validated :valid ~ .i1Ob-q_valid-tooltip, .i1Ob-q_is-valid ~ .i1Ob-q_valid-feedback, .i1Ob-q_is-valid ~ .i1Ob-q_valid-tooltip {
  display: block;
}

.i1Ob-q_was-validated .i1Ob-q_form-control:valid, .i1Ob-q_form-control.i1Ob-q_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-control:valid:focus, .i1Ob-q_form-control.i1Ob-q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.i1Ob-q_was-validated textarea.i1Ob-q_form-control:valid, textarea.i1Ob-q_form-control.i1Ob-q_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-select:valid, .i1Ob-q_form-select.i1Ob-q_is-valid {
  border-color: #198754;
}

.i1Ob-q_was-validated .i1Ob-q_form-select:valid:not([multiple]):not([size]), .i1Ob-q_was-validated .i1Ob-q_form-select:valid:not([multiple])[size="1"], .i1Ob-q_form-select.i1Ob-q_is-valid:not([multiple]):not([size]), .i1Ob-q_form-select.i1Ob-q_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.i1Ob-q_was-validated .i1Ob-q_form-select:valid:focus, .i1Ob-q_form-select.i1Ob-q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.i1Ob-q_was-validated .i1Ob-q_form-control-color:valid, .i1Ob-q_form-control-color.i1Ob-q_is-valid {
  width: calc(1.5em + 3.75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:valid, .i1Ob-q_form-check-input.i1Ob-q_is-valid {
  border-color: #198754;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:valid:checked, .i1Ob-q_form-check-input.i1Ob-q_is-valid:checked {
  background-color: #198754;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:valid:focus, .i1Ob-q_form-check-input.i1Ob-q_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:valid ~ .i1Ob-q_form-check-label, .i1Ob-q_form-check-input.i1Ob-q_is-valid ~ .i1Ob-q_form-check-label {
  color: #198754;
}

.i1Ob-q_form-check-inline .i1Ob-q_form-check-input ~ .i1Ob-q_valid-feedback {
  margin-left: .5em;
}

.i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-control:not(:focus):valid, .i1Ob-q_input-group > .i1Ob-q_form-control:not(:focus).i1Ob-q_is-valid, .i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-select:not(:focus):valid, .i1Ob-q_input-group > .i1Ob-q_form-select:not(:focus).i1Ob-q_is-valid, .i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-floating:not(:focus-within):valid, .i1Ob-q_input-group > .i1Ob-q_form-floating:not(:focus-within).i1Ob-q_is-valid {
  z-index: 3;
}

.i1Ob-q_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.i1Ob-q_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.i1Ob-q_was-validated :invalid ~ .i1Ob-q_invalid-feedback, .i1Ob-q_was-validated :invalid ~ .i1Ob-q_invalid-tooltip, .i1Ob-q_is-invalid ~ .i1Ob-q_invalid-feedback, .i1Ob-q_is-invalid ~ .i1Ob-q_invalid-tooltip {
  display: block;
}

.i1Ob-q_was-validated .i1Ob-q_form-control:invalid, .i1Ob-q_form-control.i1Ob-q_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-control:invalid:focus, .i1Ob-q_form-control.i1Ob-q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.i1Ob-q_was-validated textarea.i1Ob-q_form-control:invalid, textarea.i1Ob-q_form-control.i1Ob-q_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-select:invalid, .i1Ob-q_form-select.i1Ob-q_is-invalid {
  border-color: #dc3545;
}

.i1Ob-q_was-validated .i1Ob-q_form-select:invalid:not([multiple]):not([size]), .i1Ob-q_was-validated .i1Ob-q_form-select:invalid:not([multiple])[size="1"], .i1Ob-q_form-select.i1Ob-q_is-invalid:not([multiple]):not([size]), .i1Ob-q_form-select.i1Ob-q_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.i1Ob-q_was-validated .i1Ob-q_form-select:invalid:focus, .i1Ob-q_form-select.i1Ob-q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.i1Ob-q_was-validated .i1Ob-q_form-control-color:invalid, .i1Ob-q_form-control-color.i1Ob-q_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:invalid, .i1Ob-q_form-check-input.i1Ob-q_is-invalid {
  border-color: #dc3545;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:invalid:checked, .i1Ob-q_form-check-input.i1Ob-q_is-invalid:checked {
  background-color: #dc3545;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:invalid:focus, .i1Ob-q_form-check-input.i1Ob-q_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.i1Ob-q_was-validated .i1Ob-q_form-check-input:invalid ~ .i1Ob-q_form-check-label, .i1Ob-q_form-check-input.i1Ob-q_is-invalid ~ .i1Ob-q_form-check-label {
  color: #dc3545;
}

.i1Ob-q_form-check-inline .i1Ob-q_form-check-input ~ .i1Ob-q_invalid-feedback {
  margin-left: .5em;
}

.i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-control:not(:focus):invalid, .i1Ob-q_input-group > .i1Ob-q_form-control:not(:focus).i1Ob-q_is-invalid, .i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-select:not(:focus):invalid, .i1Ob-q_input-group > .i1Ob-q_form-select:not(:focus).i1Ob-q_is-invalid, .i1Ob-q_was-validated .i1Ob-q_input-group > .i1Ob-q_form-floating:not(:focus-within):invalid, .i1Ob-q_input-group > .i1Ob-q_form-floating:not(:focus-within).i1Ob-q_is-invalid {
  z-index: 4;
}

.i1Ob-q_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_btn {
    transition: none;
  }
}

.i1Ob-q_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.i1Ob-q_btn-check + .i1Ob-q_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.i1Ob-q_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.i1Ob-q_btn-check:focus-visible + .i1Ob-q_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.i1Ob-q_btn-check:checked + .i1Ob-q_btn, :not(.i1Ob-q_btn-check) + .i1Ob-q_btn:active, .i1Ob-q_btn:first-child:active, .i1Ob-q_btn.i1Ob-q_active, .i1Ob-q_btn.i1Ob-q_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.i1Ob-q_btn-check:checked + .i1Ob-q_btn:focus-visible, :not(.i1Ob-q_btn-check) + .i1Ob-q_btn:active:focus-visible, .i1Ob-q_btn:first-child:active:focus-visible, .i1Ob-q_btn.i1Ob-q_active:focus-visible, .i1Ob-q_btn.i1Ob-q_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.i1Ob-q_btn:disabled, .i1Ob-q_btn.i1Ob-q_disabled, fieldset:disabled .i1Ob-q_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.i1Ob-q_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.i1Ob-q_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.i1Ob-q_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.i1Ob-q_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.i1Ob-q_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.i1Ob-q_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.i1Ob-q_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.i1Ob-q_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.i1Ob-q_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.i1Ob-q_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.i1Ob-q_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.i1Ob-q_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.i1Ob-q_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.i1Ob-q_btn-lg, .i1Ob-q_btn-group-lg > .i1Ob-q_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.i1Ob-q_btn-sm, .i1Ob-q_btn-group-sm > .i1Ob-q_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.i1Ob-q_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_fade {
    transition: none;
  }
}

.i1Ob-q_fade:not(.i1Ob-q_show) {
  opacity: 0;
}

.i1Ob-q_collapse:not(.i1Ob-q_show) {
  display: none;
}

.i1Ob-q_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_collapsing {
    transition: none;
  }
}

.i1Ob-q_collapsing.i1Ob-q_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_collapsing.i1Ob-q_collapse-horizontal {
    transition: none;
  }
}

.i1Ob-q_dropup, .i1Ob-q_dropend, .i1Ob-q_dropdown, .i1Ob-q_dropstart, .i1Ob-q_dropup-center, .i1Ob-q_dropdown-center {
  position: relative;
}

.i1Ob-q_dropdown-toggle {
  white-space: nowrap;
}

.i1Ob-q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.i1Ob-q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.i1Ob-q_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.i1Ob-q_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.i1Ob-q_dropdown-menu-start {
  --bs-position: start;
}

.i1Ob-q_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.i1Ob-q_dropdown-menu-end {
  --bs-position: end;
}

.i1Ob-q_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .i1Ob-q_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .i1Ob-q_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .i1Ob-q_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .i1Ob-q_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_dropdown-menu-md-start {
    --bs-position: start;
  }

  .i1Ob-q_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .i1Ob-q_dropdown-menu-md-end {
    --bs-position: end;
  }

  .i1Ob-q_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .i1Ob-q_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .i1Ob-q_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .i1Ob-q_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .i1Ob-q_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .i1Ob-q_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .i1Ob-q_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .i1Ob-q_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .i1Ob-q_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .i1Ob-q_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.i1Ob-q_dropup .i1Ob-q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.i1Ob-q_dropup .i1Ob-q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.i1Ob-q_dropup .i1Ob-q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.i1Ob-q_dropend .i1Ob-q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.i1Ob-q_dropend .i1Ob-q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.i1Ob-q_dropend .i1Ob-q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.i1Ob-q_dropend .i1Ob-q_dropdown-toggle:after {
  vertical-align: 0;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-toggle:before {
  vertical-align: 0;
}

.i1Ob-q_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.i1Ob-q_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.i1Ob-q_dropdown-item:hover, .i1Ob-q_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.i1Ob-q_dropdown-item.i1Ob-q_active, .i1Ob-q_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.i1Ob-q_dropdown-item.i1Ob-q_disabled, .i1Ob-q_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.i1Ob-q_dropdown-menu.i1Ob-q_show {
  display: block;
}

.i1Ob-q_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.i1Ob-q_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.i1Ob-q_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.i1Ob-q_btn-group, .i1Ob-q_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.i1Ob-q_btn-group > .i1Ob-q_btn, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn {
  flex: auto;
  position: relative;
}

.i1Ob-q_btn-group > .i1Ob-q_btn-check:checked + .i1Ob-q_btn, .i1Ob-q_btn-group > .i1Ob-q_btn-check:focus + .i1Ob-q_btn, .i1Ob-q_btn-group > .i1Ob-q_btn:hover, .i1Ob-q_btn-group > .i1Ob-q_btn:focus, .i1Ob-q_btn-group > .i1Ob-q_btn:active, .i1Ob-q_btn-group > .i1Ob-q_btn.i1Ob-q_active, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-check:checked + .i1Ob-q_btn, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-check:focus + .i1Ob-q_btn, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn:hover, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn:focus, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn:active, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn.i1Ob-q_active {
  z-index: 1;
}

.i1Ob-q_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.i1Ob-q_btn-toolbar .i1Ob-q_input-group {
  width: auto;
}

.i1Ob-q_btn-group {
  border-radius: .375rem;
}

.i1Ob-q_btn-group > :not(.i1Ob-q_btn-check:first-child) + .i1Ob-q_btn, .i1Ob-q_btn-group > .i1Ob-q_btn-group:not(:first-child) {
  margin-left: -1px;
}

.i1Ob-q_btn-group > .i1Ob-q_btn:not(:last-child):not(.i1Ob-q_dropdown-toggle), .i1Ob-q_btn-group > .i1Ob-q_btn.i1Ob-q_dropdown-toggle-split:first-child, .i1Ob-q_btn-group > .i1Ob-q_btn-group:not(:last-child) > .i1Ob-q_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.i1Ob-q_btn-group > .i1Ob-q_btn:nth-child(n+3), .i1Ob-q_btn-group > :not(.i1Ob-q_btn-check) + .i1Ob-q_btn, .i1Ob-q_btn-group > .i1Ob-q_btn-group:not(:first-child) > .i1Ob-q_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.i1Ob-q_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.i1Ob-q_dropdown-toggle-split:after, .i1Ob-q_dropup .i1Ob-q_dropdown-toggle-split:after, .i1Ob-q_dropend .i1Ob-q_dropdown-toggle-split:after {
  margin-left: 0;
}

.i1Ob-q_dropstart .i1Ob-q_dropdown-toggle-split:before {
  margin-right: 0;
}

.i1Ob-q_btn-sm + .i1Ob-q_dropdown-toggle-split, .i1Ob-q_btn-group-sm > .i1Ob-q_btn + .i1Ob-q_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.i1Ob-q_btn-lg + .i1Ob-q_dropdown-toggle-split, .i1Ob-q_btn-group-lg > .i1Ob-q_btn + .i1Ob-q_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.i1Ob-q_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.i1Ob-q_btn-group-vertical > .i1Ob-q_btn, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-group {
  width: 100%;
}

.i1Ob-q_btn-group-vertical > .i1Ob-q_btn:not(:first-child), .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-group:not(:first-child) {
  margin-top: -1px;
}

.i1Ob-q_btn-group-vertical > .i1Ob-q_btn:not(:last-child):not(.i1Ob-q_dropdown-toggle), .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-group:not(:last-child) > .i1Ob-q_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.i1Ob-q_btn-group-vertical > .i1Ob-q_btn ~ .i1Ob-q_btn, .i1Ob-q_btn-group-vertical > .i1Ob-q_btn-group:not(:first-child) > .i1Ob-q_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.i1Ob-q_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.i1Ob-q_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_nav-link {
    transition: none;
  }
}

.i1Ob-q_nav-link:hover, .i1Ob-q_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.i1Ob-q_nav-link.i1Ob-q_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.i1Ob-q_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-link:hover, .i1Ob-q_nav-tabs .i1Ob-q_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-link.i1Ob-q_disabled, .i1Ob-q_nav-tabs .i1Ob-q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-link.i1Ob-q_active, .i1Ob-q_nav-tabs .i1Ob-q_nav-item.i1Ob-q_show .i1Ob-q_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.i1Ob-q_nav-tabs .i1Ob-q_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.i1Ob-q_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.i1Ob-q_nav-pills .i1Ob-q_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.i1Ob-q_nav-pills .i1Ob-q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.i1Ob-q_nav-pills .i1Ob-q_nav-link.i1Ob-q_active, .i1Ob-q_nav-pills .i1Ob-q_show > .i1Ob-q_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.i1Ob-q_nav-fill > .i1Ob-q_nav-link, .i1Ob-q_nav-fill .i1Ob-q_nav-item {
  text-align: center;
  flex: auto;
}

.i1Ob-q_nav-justified > .i1Ob-q_nav-link, .i1Ob-q_nav-justified .i1Ob-q_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.i1Ob-q_nav-fill .i1Ob-q_nav-item .i1Ob-q_nav-link, .i1Ob-q_nav-justified .i1Ob-q_nav-item .i1Ob-q_nav-link {
  width: 100%;
}

.i1Ob-q_tab-content > .i1Ob-q_tab-pane {
  display: none;
}

.i1Ob-q_tab-content > .i1Ob-q_active {
  display: block;
}

.i1Ob-q_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.i1Ob-q_navbar > .i1Ob-q_container, .i1Ob-q_navbar > .i1Ob-q_container-fluid, .i1Ob-q_navbar > .i1Ob-q_container-sm, .i1Ob-q_navbar > .i1Ob-q_container-md, .i1Ob-q_navbar > .i1Ob-q_container-lg, .i1Ob-q_navbar > .i1Ob-q_container-xl, .i1Ob-q_navbar > .i1Ob-q_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.i1Ob-q_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.i1Ob-q_navbar-brand:hover, .i1Ob-q_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.i1Ob-q_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.i1Ob-q_navbar-nav .i1Ob-q_show > .i1Ob-q_nav-link, .i1Ob-q_navbar-nav .i1Ob-q_nav-link.i1Ob-q_active {
  color: var(--bs-navbar-active-color);
}

.i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
  position: static;
}

.i1Ob-q_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.i1Ob-q_navbar-text a, .i1Ob-q_navbar-text a:hover, .i1Ob-q_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.i1Ob-q_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.i1Ob-q_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_navbar-toggler {
    transition: none;
  }
}

.i1Ob-q_navbar-toggler:hover {
  text-decoration: none;
}

.i1Ob-q_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.i1Ob-q_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.i1Ob-q_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .i1Ob-q_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-nav {
    flex-direction: row;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
    position: absolute;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-nav-scroll {
    overflow: visible;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_navbar-toggler {
    display: none;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_navbar-expand-sm .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-nav {
    flex-direction: row;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
    position: absolute;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-nav-scroll {
    overflow: visible;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_navbar-toggler {
    display: none;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_navbar-expand-md .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-nav {
    flex-direction: row;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
    position: absolute;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-nav-scroll {
    overflow: visible;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_navbar-toggler {
    display: none;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_navbar-expand-lg .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-nav {
    flex-direction: row;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
    position: absolute;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-nav-scroll {
    overflow: visible;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_navbar-toggler {
    display: none;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_navbar-expand-xl .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-nav {
    flex-direction: row;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
    position: absolute;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-nav-scroll {
    overflow: visible;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_navbar-toggler {
    display: none;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_navbar-expand-xxl .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.i1Ob-q_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-nav {
  flex-direction: row;
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-nav .i1Ob-q_dropdown-menu {
  position: absolute;
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-nav .i1Ob-q_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-nav-scroll {
  overflow: visible;
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.i1Ob-q_navbar-expand .i1Ob-q_navbar-toggler {
  display: none;
}

.i1Ob-q_navbar-expand .i1Ob-q_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.i1Ob-q_navbar-expand .i1Ob-q_offcanvas .i1Ob-q_offcanvas-header {
  display: none;
}

.i1Ob-q_navbar-expand .i1Ob-q_offcanvas .i1Ob-q_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.i1Ob-q_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.i1Ob-q_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.i1Ob-q_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.i1Ob-q_card > .i1Ob-q_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.i1Ob-q_card > .i1Ob-q_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.i1Ob-q_card > .i1Ob-q_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.i1Ob-q_card > .i1Ob-q_card-header + .i1Ob-q_list-group, .i1Ob-q_card > .i1Ob-q_list-group + .i1Ob-q_card-footer {
  border-top: 0;
}

.i1Ob-q_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.i1Ob-q_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.i1Ob-q_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.i1Ob-q_card-text:last-child {
  margin-bottom: 0;
}

.i1Ob-q_card-link + .i1Ob-q_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.i1Ob-q_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.i1Ob-q_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.i1Ob-q_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.i1Ob-q_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.i1Ob-q_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.i1Ob-q_card-header-tabs .i1Ob-q_nav-link.i1Ob-q_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.i1Ob-q_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.i1Ob-q_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.i1Ob-q_card-img, .i1Ob-q_card-img-top, .i1Ob-q_card-img-bottom {
  width: 100%;
}

.i1Ob-q_card-img, .i1Ob-q_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.i1Ob-q_card-img, .i1Ob-q_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.i1Ob-q_card-group > .i1Ob-q_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .i1Ob-q_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .i1Ob-q_card-group > .i1Ob-q_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card + .i1Ob-q_card {
    border-left: 0;
    margin-left: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:last-child) .i1Ob-q_card-img-top, .i1Ob-q_card-group > .i1Ob-q_card:not(:last-child) .i1Ob-q_card-header {
    border-top-right-radius: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:last-child) .i1Ob-q_card-img-bottom, .i1Ob-q_card-group > .i1Ob-q_card:not(:last-child) .i1Ob-q_card-footer {
    border-bottom-right-radius: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:first-child) .i1Ob-q_card-img-top, .i1Ob-q_card-group > .i1Ob-q_card:not(:first-child) .i1Ob-q_card-header {
    border-top-left-radius: 0;
  }

  .i1Ob-q_card-group > .i1Ob-q_card:not(:first-child) .i1Ob-q_card-img-bottom, .i1Ob-q_card-group > .i1Ob-q_card:not(:first-child) .i1Ob-q_card-footer {
    border-bottom-left-radius: 0;
  }
}

.i1Ob-q_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.i1Ob-q_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_accordion-button {
    transition: none;
  }
}

.i1Ob-q_accordion-button:not(.i1Ob-q_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.i1Ob-q_accordion-button:not(.i1Ob-q_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.i1Ob-q_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_accordion-button:after {
    transition: none;
  }
}

.i1Ob-q_accordion-button:hover {
  z-index: 2;
}

.i1Ob-q_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.i1Ob-q_accordion-header {
  margin-bottom: 0;
}

.i1Ob-q_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.i1Ob-q_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.i1Ob-q_accordion-item:first-of-type .i1Ob-q_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.i1Ob-q_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.i1Ob-q_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.i1Ob-q_accordion-item:last-of-type .i1Ob-q_accordion-button.i1Ob-q_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.i1Ob-q_accordion-item:last-of-type .i1Ob-q_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.i1Ob-q_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.i1Ob-q_accordion-flush .i1Ob-q_accordion-collapse {
  border-width: 0;
}

.i1Ob-q_accordion-flush .i1Ob-q_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.i1Ob-q_accordion-flush .i1Ob-q_accordion-item:first-child {
  border-top: 0;
}

.i1Ob-q_accordion-flush .i1Ob-q_accordion-item:last-child {
  border-bottom: 0;
}

.i1Ob-q_accordion-flush .i1Ob-q_accordion-item .i1Ob-q_accordion-button, .i1Ob-q_accordion-flush .i1Ob-q_accordion-item .i1Ob-q_accordion-button.i1Ob-q_collapsed {
  border-radius: 0;
}

.i1Ob-q_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.i1Ob-q_breadcrumb-item + .i1Ob-q_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.i1Ob-q_breadcrumb-item + .i1Ob-q_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.i1Ob-q_breadcrumb-item.i1Ob-q_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.i1Ob-q_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.i1Ob-q_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_page-link {
    transition: none;
  }
}

.i1Ob-q_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.i1Ob-q_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.i1Ob-q_page-link.i1Ob-q_active, .i1Ob-q_active > .i1Ob-q_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.i1Ob-q_page-link.i1Ob-q_disabled, .i1Ob-q_disabled > .i1Ob-q_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.i1Ob-q_page-item:not(:first-child) .i1Ob-q_page-link {
  margin-left: -1px;
}

.i1Ob-q_page-item:first-child .i1Ob-q_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.i1Ob-q_page-item:last-child .i1Ob-q_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.i1Ob-q_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.i1Ob-q_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.i1Ob-q_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.i1Ob-q_badge:empty {
  display: none;
}

.i1Ob-q_btn .i1Ob-q_badge {
  position: relative;
  top: -1px;
}

.i1Ob-q_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.i1Ob-q_alert-heading {
  color: inherit;
}

.i1Ob-q_alert-link {
  font-weight: 700;
}

.i1Ob-q_alert-dismissible {
  padding-right: 3rem;
}

.i1Ob-q_alert-dismissible .i1Ob-q_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.i1Ob-q_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.i1Ob-q_alert-primary .i1Ob-q_alert-link {
  color: #25224b;
}

.i1Ob-q_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.i1Ob-q_alert-secondary .i1Ob-q_alert-link {
  color: #34383c;
}

.i1Ob-q_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.i1Ob-q_alert-success .i1Ob-q_alert-link {
  color: #0c4128;
}

.i1Ob-q_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.i1Ob-q_alert-info .i1Ob-q_alert-link {
  color: #04414d;
}

.i1Ob-q_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.i1Ob-q_alert-warning .i1Ob-q_alert-link {
  color: #523e02;
}

.i1Ob-q_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.i1Ob-q_alert-danger .i1Ob-q_alert-link {
  color: #6a1a21;
}

.i1Ob-q_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.i1Ob-q_alert-light .i1Ob-q_alert-link {
  color: #4f5050;
}

.i1Ob-q_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.i1Ob-q_alert-dark .i1Ob-q_alert-link {
  color: #101214;
}

@keyframes i1Ob-q_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.i1Ob-q_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.i1Ob-q_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_progress-bar {
    transition: none;
  }
}

.i1Ob-q_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.i1Ob-q_progress-bar-animated {
  animation: 1s linear infinite i1Ob-q_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_progress-bar-animated {
    animation: none;
  }
}

.i1Ob-q_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.i1Ob-q_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.i1Ob-q_list-group-numbered > .i1Ob-q_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.i1Ob-q_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.i1Ob-q_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.i1Ob-q_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.i1Ob-q_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.i1Ob-q_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.i1Ob-q_list-group-item.i1Ob-q_disabled, .i1Ob-q_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.i1Ob-q_list-group-item.i1Ob-q_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
  border-top-width: 0;
}

.i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.i1Ob-q_list-group-horizontal {
  flex-direction: row;
}

.i1Ob-q_list-group-horizontal > .i1Ob-q_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.i1Ob-q_list-group-horizontal > .i1Ob-q_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.i1Ob-q_list-group-horizontal > .i1Ob-q_list-group-item.i1Ob-q_active {
  margin-top: 0;
}

.i1Ob-q_list-group-horizontal > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.i1Ob-q_list-group-horizontal > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .i1Ob-q_list-group-horizontal-sm {
    flex-direction: row;
  }

  .i1Ob-q_list-group-horizontal-sm > .i1Ob-q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-sm > .i1Ob-q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-sm > .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-top: 0;
  }

  .i1Ob-q_list-group-horizontal-sm > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .i1Ob-q_list-group-horizontal-sm > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .i1Ob-q_list-group-horizontal-md {
    flex-direction: row;
  }

  .i1Ob-q_list-group-horizontal-md > .i1Ob-q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-md > .i1Ob-q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-md > .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-top: 0;
  }

  .i1Ob-q_list-group-horizontal-md > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .i1Ob-q_list-group-horizontal-md > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .i1Ob-q_list-group-horizontal-lg {
    flex-direction: row;
  }

  .i1Ob-q_list-group-horizontal-lg > .i1Ob-q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-lg > .i1Ob-q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-lg > .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-top: 0;
  }

  .i1Ob-q_list-group-horizontal-lg > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .i1Ob-q_list-group-horizontal-lg > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_list-group-horizontal-xl {
    flex-direction: row;
  }

  .i1Ob-q_list-group-horizontal-xl > .i1Ob-q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-xl > .i1Ob-q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-xl > .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-top: 0;
  }

  .i1Ob-q_list-group-horizontal-xl > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .i1Ob-q_list-group-horizontal-xl > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .i1Ob-q_list-group-horizontal-xxl > .i1Ob-q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-xxl > .i1Ob-q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .i1Ob-q_list-group-horizontal-xxl > .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-top: 0;
  }

  .i1Ob-q_list-group-horizontal-xxl > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .i1Ob-q_list-group-horizontal-xxl > .i1Ob-q_list-group-item + .i1Ob-q_list-group-item.i1Ob-q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.i1Ob-q_list-group-flush {
  border-radius: 0;
}

.i1Ob-q_list-group-flush > .i1Ob-q_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.i1Ob-q_list-group-flush > .i1Ob-q_list-group-item:last-child {
  border-bottom-width: 0;
}

.i1Ob-q_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.i1Ob-q_list-group-item-primary.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-primary.i1Ob-q_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.i1Ob-q_list-group-item-primary.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.i1Ob-q_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.i1Ob-q_list-group-item-secondary.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-secondary.i1Ob-q_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.i1Ob-q_list-group-item-secondary.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.i1Ob-q_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.i1Ob-q_list-group-item-success.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-success.i1Ob-q_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.i1Ob-q_list-group-item-success.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.i1Ob-q_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.i1Ob-q_list-group-item-info.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-info.i1Ob-q_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.i1Ob-q_list-group-item-info.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.i1Ob-q_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.i1Ob-q_list-group-item-warning.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-warning.i1Ob-q_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.i1Ob-q_list-group-item-warning.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.i1Ob-q_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.i1Ob-q_list-group-item-danger.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-danger.i1Ob-q_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.i1Ob-q_list-group-item-danger.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.i1Ob-q_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.i1Ob-q_list-group-item-light.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-light.i1Ob-q_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.i1Ob-q_list-group-item-light.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.i1Ob-q_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.i1Ob-q_list-group-item-dark.i1Ob-q_list-group-item-action:hover, .i1Ob-q_list-group-item-dark.i1Ob-q_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.i1Ob-q_list-group-item-dark.i1Ob-q_list-group-item-action.i1Ob-q_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.i1Ob-q_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.i1Ob-q_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.i1Ob-q_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.i1Ob-q_btn-close:disabled, .i1Ob-q_btn-close.i1Ob-q_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.i1Ob-q_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.i1Ob-q_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.i1Ob-q_toast.i1Ob-q_showing {
  opacity: 0;
}

.i1Ob-q_toast:not(.i1Ob-q_show) {
  display: none;
}

.i1Ob-q_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.i1Ob-q_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.i1Ob-q_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.i1Ob-q_toast-header .i1Ob-q_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.i1Ob-q_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.i1Ob-q_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.i1Ob-q_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.i1Ob-q_modal.i1Ob-q_fade .i1Ob-q_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_modal.i1Ob-q_fade .i1Ob-q_modal-dialog {
    transition: none;
  }
}

.i1Ob-q_modal.i1Ob-q_show .i1Ob-q_modal-dialog {
  transform: none;
}

.i1Ob-q_modal.i1Ob-q_modal-static .i1Ob-q_modal-dialog {
  transform: scale(1.02);
}

.i1Ob-q_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.i1Ob-q_modal-dialog-scrollable .i1Ob-q_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.i1Ob-q_modal-dialog-scrollable .i1Ob-q_modal-body {
  overflow-y: auto;
}

.i1Ob-q_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.i1Ob-q_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.i1Ob-q_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.i1Ob-q_modal-backdrop.i1Ob-q_fade {
  opacity: 0;
}

.i1Ob-q_modal-backdrop.i1Ob-q_show {
  opacity: var(--bs-backdrop-opacity);
}

.i1Ob-q_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.i1Ob-q_modal-header .i1Ob-q_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.i1Ob-q_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.i1Ob-q_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.i1Ob-q_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.i1Ob-q_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .i1Ob-q_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .i1Ob-q_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .i1Ob-q_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_modal-lg, .i1Ob-q_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.i1Ob-q_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.i1Ob-q_modal-fullscreen .i1Ob-q_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.i1Ob-q_modal-fullscreen .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen .i1Ob-q_modal-footer {
  border-radius: 0;
}

.i1Ob-q_modal-fullscreen .i1Ob-q_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .i1Ob-q_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .i1Ob-q_modal-fullscreen-sm-down .i1Ob-q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-sm-down .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen-sm-down .i1Ob-q_modal-footer {
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-sm-down .i1Ob-q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .i1Ob-q_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .i1Ob-q_modal-fullscreen-md-down .i1Ob-q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-md-down .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen-md-down .i1Ob-q_modal-footer {
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-md-down .i1Ob-q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .i1Ob-q_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .i1Ob-q_modal-fullscreen-lg-down .i1Ob-q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-lg-down .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen-lg-down .i1Ob-q_modal-footer {
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-lg-down .i1Ob-q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .i1Ob-q_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .i1Ob-q_modal-fullscreen-xl-down .i1Ob-q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-xl-down .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen-xl-down .i1Ob-q_modal-footer {
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-xl-down .i1Ob-q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .i1Ob-q_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .i1Ob-q_modal-fullscreen-xxl-down .i1Ob-q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-xxl-down .i1Ob-q_modal-header, .i1Ob-q_modal-fullscreen-xxl-down .i1Ob-q_modal-footer {
    border-radius: 0;
  }

  .i1Ob-q_modal-fullscreen-xxl-down .i1Ob-q_modal-body {
    overflow-y: auto;
  }
}

.i1Ob-q_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.i1Ob-q_tooltip.i1Ob-q_show {
  opacity: var(--bs-tooltip-opacity);
}

.i1Ob-q_tooltip .i1Ob-q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.i1Ob-q_tooltip .i1Ob-q_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.i1Ob-q_bs-tooltip-top .i1Ob-q_tooltip-arrow, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="top"] .i1Ob-q_tooltip-arrow {
  bottom: 0;
}

.i1Ob-q_bs-tooltip-top .i1Ob-q_tooltip-arrow:before, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="top"] .i1Ob-q_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.i1Ob-q_bs-tooltip-end .i1Ob-q_tooltip-arrow, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="right"] .i1Ob-q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.i1Ob-q_bs-tooltip-end .i1Ob-q_tooltip-arrow:before, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="right"] .i1Ob-q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.i1Ob-q_bs-tooltip-bottom .i1Ob-q_tooltip-arrow, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="bottom"] .i1Ob-q_tooltip-arrow {
  top: 0;
}

.i1Ob-q_bs-tooltip-bottom .i1Ob-q_tooltip-arrow:before, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="bottom"] .i1Ob-q_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.i1Ob-q_bs-tooltip-start .i1Ob-q_tooltip-arrow, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="left"] .i1Ob-q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.i1Ob-q_bs-tooltip-start .i1Ob-q_tooltip-arrow:before, .i1Ob-q_bs-tooltip-auto[data-popper-placement^="left"] .i1Ob-q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.i1Ob-q_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.i1Ob-q_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.i1Ob-q_popover .i1Ob-q_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.i1Ob-q_popover .i1Ob-q_popover-arrow:before, .i1Ob-q_popover .i1Ob-q_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.i1Ob-q_bs-popover-top > .i1Ob-q_popover-arrow, .i1Ob-q_bs-popover-auto[data-popper-placement^="top"] > .i1Ob-q_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.i1Ob-q_bs-popover-top > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="top"] > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-top > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="top"] > .i1Ob-q_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.i1Ob-q_bs-popover-top > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="top"] > .i1Ob-q_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.i1Ob-q_bs-popover-top > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="top"] > .i1Ob-q_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.i1Ob-q_bs-popover-end > .i1Ob-q_popover-arrow, .i1Ob-q_bs-popover-auto[data-popper-placement^="right"] > .i1Ob-q_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.i1Ob-q_bs-popover-end > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="right"] > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-end > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="right"] > .i1Ob-q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.i1Ob-q_bs-popover-end > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="right"] > .i1Ob-q_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.i1Ob-q_bs-popover-end > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="right"] > .i1Ob-q_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.i1Ob-q_bs-popover-bottom > .i1Ob-q_popover-arrow, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] > .i1Ob-q_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.i1Ob-q_bs-popover-bottom > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-bottom > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] > .i1Ob-q_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.i1Ob-q_bs-popover-bottom > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] > .i1Ob-q_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.i1Ob-q_bs-popover-bottom > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] > .i1Ob-q_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.i1Ob-q_bs-popover-bottom .i1Ob-q_popover-header:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="bottom"] .i1Ob-q_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.i1Ob-q_bs-popover-start > .i1Ob-q_popover-arrow, .i1Ob-q_bs-popover-auto[data-popper-placement^="left"] > .i1Ob-q_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.i1Ob-q_bs-popover-start > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="left"] > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-start > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="left"] > .i1Ob-q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.i1Ob-q_bs-popover-start > .i1Ob-q_popover-arrow:before, .i1Ob-q_bs-popover-auto[data-popper-placement^="left"] > .i1Ob-q_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.i1Ob-q_bs-popover-start > .i1Ob-q_popover-arrow:after, .i1Ob-q_bs-popover-auto[data-popper-placement^="left"] > .i1Ob-q_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.i1Ob-q_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.i1Ob-q_popover-header:empty {
  display: none;
}

.i1Ob-q_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.i1Ob-q_carousel {
  position: relative;
}

.i1Ob-q_carousel.i1Ob-q_pointer-event {
  touch-action: pan-y;
}

.i1Ob-q_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.i1Ob-q_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.i1Ob-q_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_carousel-item {
    transition: none;
  }
}

.i1Ob-q_carousel-item.i1Ob-q_active, .i1Ob-q_carousel-item-next, .i1Ob-q_carousel-item-prev {
  display: block;
}

.i1Ob-q_carousel-item-next:not(.i1Ob-q_carousel-item-start), .i1Ob-q_active.i1Ob-q_carousel-item-end {
  transform: translateX(100%);
}

.i1Ob-q_carousel-item-prev:not(.i1Ob-q_carousel-item-end), .i1Ob-q_active.i1Ob-q_carousel-item-start {
  transform: translateX(-100%);
}

.i1Ob-q_carousel-fade .i1Ob-q_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.i1Ob-q_carousel-fade .i1Ob-q_carousel-item.i1Ob-q_active, .i1Ob-q_carousel-fade .i1Ob-q_carousel-item-next.i1Ob-q_carousel-item-start, .i1Ob-q_carousel-fade .i1Ob-q_carousel-item-prev.i1Ob-q_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.i1Ob-q_carousel-fade .i1Ob-q_active.i1Ob-q_carousel-item-start, .i1Ob-q_carousel-fade .i1Ob-q_active.i1Ob-q_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_carousel-fade .i1Ob-q_active.i1Ob-q_carousel-item-start, .i1Ob-q_carousel-fade .i1Ob-q_active.i1Ob-q_carousel-item-end {
    transition: none;
  }
}

.i1Ob-q_carousel-control-prev, .i1Ob-q_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_carousel-control-prev, .i1Ob-q_carousel-control-next {
    transition: none;
  }
}

.i1Ob-q_carousel-control-prev:hover, .i1Ob-q_carousel-control-prev:focus, .i1Ob-q_carousel-control-next:hover, .i1Ob-q_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.i1Ob-q_carousel-control-prev {
  left: 0;
}

.i1Ob-q_carousel-control-next {
  right: 0;
}

.i1Ob-q_carousel-control-prev-icon, .i1Ob-q_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.i1Ob-q_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.i1Ob-q_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.i1Ob-q_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.i1Ob-q_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.i1Ob-q_carousel-indicators .i1Ob-q_active {
  opacity: 1;
}

.i1Ob-q_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.i1Ob-q_carousel-dark .i1Ob-q_carousel-control-prev-icon, .i1Ob-q_carousel-dark .i1Ob-q_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.i1Ob-q_carousel-dark .i1Ob-q_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.i1Ob-q_carousel-dark .i1Ob-q_carousel-caption {
  color: #000;
}

.i1Ob-q_spinner-grow, .i1Ob-q_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes i1Ob-q_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.i1Ob-q_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.i1Ob-q_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes i1Ob-q_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.i1Ob-q_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.i1Ob-q_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_spinner-border, .i1Ob-q_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.i1Ob-q_offcanvas, .i1Ob-q_offcanvas-xxl, .i1Ob-q_offcanvas-xl, .i1Ob-q_offcanvas-lg, .i1Ob-q_offcanvas-md, .i1Ob-q_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .i1Ob-q_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .i1Ob-q_offcanvas-sm.i1Ob-q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .i1Ob-q_offcanvas-sm.i1Ob-q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .i1Ob-q_offcanvas-sm.i1Ob-q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .i1Ob-q_offcanvas-sm.i1Ob-q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .i1Ob-q_offcanvas-sm.i1Ob-q_showing, .i1Ob-q_offcanvas-sm.i1Ob-q_show:not(.i1Ob-q_hiding) {
    transform: none;
  }

  .i1Ob-q_offcanvas-sm.i1Ob-q_showing, .i1Ob-q_offcanvas-sm.i1Ob-q_hiding, .i1Ob-q_offcanvas-sm.i1Ob-q_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .i1Ob-q_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .i1Ob-q_offcanvas-sm .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_offcanvas-sm .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .i1Ob-q_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .i1Ob-q_offcanvas-md.i1Ob-q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .i1Ob-q_offcanvas-md.i1Ob-q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .i1Ob-q_offcanvas-md.i1Ob-q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .i1Ob-q_offcanvas-md.i1Ob-q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .i1Ob-q_offcanvas-md.i1Ob-q_showing, .i1Ob-q_offcanvas-md.i1Ob-q_show:not(.i1Ob-q_hiding) {
    transform: none;
  }

  .i1Ob-q_offcanvas-md.i1Ob-q_showing, .i1Ob-q_offcanvas-md.i1Ob-q_hiding, .i1Ob-q_offcanvas-md.i1Ob-q_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .i1Ob-q_offcanvas-md .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_offcanvas-md .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .i1Ob-q_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .i1Ob-q_offcanvas-lg.i1Ob-q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .i1Ob-q_offcanvas-lg.i1Ob-q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .i1Ob-q_offcanvas-lg.i1Ob-q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .i1Ob-q_offcanvas-lg.i1Ob-q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .i1Ob-q_offcanvas-lg.i1Ob-q_showing, .i1Ob-q_offcanvas-lg.i1Ob-q_show:not(.i1Ob-q_hiding) {
    transform: none;
  }

  .i1Ob-q_offcanvas-lg.i1Ob-q_showing, .i1Ob-q_offcanvas-lg.i1Ob-q_hiding, .i1Ob-q_offcanvas-lg.i1Ob-q_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .i1Ob-q_offcanvas-lg .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_offcanvas-lg .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .i1Ob-q_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .i1Ob-q_offcanvas-xl.i1Ob-q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .i1Ob-q_offcanvas-xl.i1Ob-q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .i1Ob-q_offcanvas-xl.i1Ob-q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .i1Ob-q_offcanvas-xl.i1Ob-q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .i1Ob-q_offcanvas-xl.i1Ob-q_showing, .i1Ob-q_offcanvas-xl.i1Ob-q_show:not(.i1Ob-q_hiding) {
    transform: none;
  }

  .i1Ob-q_offcanvas-xl.i1Ob-q_showing, .i1Ob-q_offcanvas-xl.i1Ob-q_hiding, .i1Ob-q_offcanvas-xl.i1Ob-q_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .i1Ob-q_offcanvas-xl .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_offcanvas-xl .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .i1Ob-q_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .i1Ob-q_offcanvas-xxl.i1Ob-q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .i1Ob-q_offcanvas-xxl.i1Ob-q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .i1Ob-q_offcanvas-xxl.i1Ob-q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .i1Ob-q_offcanvas-xxl.i1Ob-q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .i1Ob-q_offcanvas-xxl.i1Ob-q_showing, .i1Ob-q_offcanvas-xxl.i1Ob-q_show:not(.i1Ob-q_hiding) {
    transform: none;
  }

  .i1Ob-q_offcanvas-xxl.i1Ob-q_showing, .i1Ob-q_offcanvas-xxl.i1Ob-q_hiding, .i1Ob-q_offcanvas-xxl.i1Ob-q_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .i1Ob-q_offcanvas-xxl .i1Ob-q_offcanvas-header {
    display: none;
  }

  .i1Ob-q_offcanvas-xxl .i1Ob-q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.i1Ob-q_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .i1Ob-q_offcanvas {
    transition: none;
  }
}

.i1Ob-q_offcanvas.i1Ob-q_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.i1Ob-q_offcanvas.i1Ob-q_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.i1Ob-q_offcanvas.i1Ob-q_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.i1Ob-q_offcanvas.i1Ob-q_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.i1Ob-q_offcanvas.i1Ob-q_showing, .i1Ob-q_offcanvas.i1Ob-q_show:not(.i1Ob-q_hiding) {
  transform: none;
}

.i1Ob-q_offcanvas.i1Ob-q_showing, .i1Ob-q_offcanvas.i1Ob-q_hiding, .i1Ob-q_offcanvas.i1Ob-q_show {
  visibility: visible;
}

.i1Ob-q_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.i1Ob-q_offcanvas-backdrop.i1Ob-q_fade {
  opacity: 0;
}

.i1Ob-q_offcanvas-backdrop.i1Ob-q_show {
  opacity: .5;
}

.i1Ob-q_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.i1Ob-q_offcanvas-header .i1Ob-q_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.i1Ob-q_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.i1Ob-q_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.i1Ob-q_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.i1Ob-q_placeholder.i1Ob-q_btn:before {
  content: "";
  display: inline-block;
}

.i1Ob-q_placeholder-xs {
  min-height: .6em;
}

.i1Ob-q_placeholder-sm {
  min-height: .8em;
}

.i1Ob-q_placeholder-lg {
  min-height: 1.2em;
}

.i1Ob-q_placeholder-glow .i1Ob-q_placeholder {
  animation: 2s ease-in-out infinite i1Ob-q_placeholder-glow;
}

@keyframes i1Ob-q_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.i1Ob-q_placeholder-wave {
  animation: 2s linear infinite i1Ob-q_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes i1Ob-q_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.i1Ob-q_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.i1Ob-q_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.i1Ob-q_link-primary {
  color: #4c469c !important;
}

.i1Ob-q_link-primary:hover, .i1Ob-q_link-primary:focus {
  color: #3d387d !important;
}

.i1Ob-q_link-secondary {
  color: #6c757d !important;
}

.i1Ob-q_link-secondary:hover, .i1Ob-q_link-secondary:focus {
  color: #565e64 !important;
}

.i1Ob-q_link-success {
  color: #198754 !important;
}

.i1Ob-q_link-success:hover, .i1Ob-q_link-success:focus {
  color: #146c43 !important;
}

.i1Ob-q_link-info {
  color: #0dcaf0 !important;
}

.i1Ob-q_link-info:hover, .i1Ob-q_link-info:focus {
  color: #3dd5f3 !important;
}

.i1Ob-q_link-warning {
  color: #ffc107 !important;
}

.i1Ob-q_link-warning:hover, .i1Ob-q_link-warning:focus {
  color: #ffcd39 !important;
}

.i1Ob-q_link-danger {
  color: #dc3545 !important;
}

.i1Ob-q_link-danger:hover, .i1Ob-q_link-danger:focus {
  color: #b02a37 !important;
}

.i1Ob-q_link-light {
  color: #f8f9fa !important;
}

.i1Ob-q_link-light:hover, .i1Ob-q_link-light:focus {
  color: #f9fafb !important;
}

.i1Ob-q_link-dark {
  color: #212529 !important;
}

.i1Ob-q_link-dark:hover, .i1Ob-q_link-dark:focus {
  color: #1a1e21 !important;
}

.i1Ob-q_ratio {
  width: 100%;
  position: relative;
}

.i1Ob-q_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.i1Ob-q_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.i1Ob-q_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.i1Ob-q_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.i1Ob-q_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.i1Ob-q_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.i1Ob-q_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.i1Ob-q_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.i1Ob-q_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.i1Ob-q_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .i1Ob-q_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .i1Ob-q_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .i1Ob-q_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .i1Ob-q_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .i1Ob-q_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .i1Ob-q_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.i1Ob-q_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.i1Ob-q_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.i1Ob-q_visually-hidden, .i1Ob-q_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.i1Ob-q_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.i1Ob-q_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.i1Ob-q_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.i1Ob-q_align-baseline {
  vertical-align: baseline !important;
}

.i1Ob-q_align-top {
  vertical-align: top !important;
}

.i1Ob-q_align-middle {
  vertical-align: middle !important;
}

.i1Ob-q_align-bottom {
  vertical-align: bottom !important;
}

.i1Ob-q_align-text-bottom {
  vertical-align: text-bottom !important;
}

.i1Ob-q_align-text-top {
  vertical-align: text-top !important;
}

.i1Ob-q_float-start {
  float: left !important;
}

.i1Ob-q_float-end {
  float: right !important;
}

.i1Ob-q_float-none {
  float: none !important;
}

.i1Ob-q_opacity-0 {
  opacity: 0 !important;
}

.i1Ob-q_opacity-25 {
  opacity: .25 !important;
}

.i1Ob-q_opacity-50 {
  opacity: .5 !important;
}

.i1Ob-q_opacity-75 {
  opacity: .75 !important;
}

.i1Ob-q_opacity-100 {
  opacity: 1 !important;
}

.i1Ob-q_overflow-auto {
  overflow: auto !important;
}

.i1Ob-q_overflow-hidden {
  overflow: hidden !important;
}

.i1Ob-q_overflow-visible {
  overflow: visible !important;
}

.i1Ob-q_overflow-scroll {
  overflow: scroll !important;
}

.i1Ob-q_d-inline {
  display: inline !important;
}

.i1Ob-q_d-inline-block {
  display: inline-block !important;
}

.i1Ob-q_d-block {
  display: block !important;
}

.i1Ob-q_d-grid {
  display: grid !important;
}

.i1Ob-q_d-table {
  display: table !important;
}

.i1Ob-q_d-table-row {
  display: table-row !important;
}

.i1Ob-q_d-table-cell {
  display: table-cell !important;
}

.i1Ob-q_d-flex {
  display: flex !important;
}

.i1Ob-q_d-inline-flex {
  display: inline-flex !important;
}

.i1Ob-q_d-none {
  display: none !important;
}

.i1Ob-q_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.i1Ob-q_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.i1Ob-q_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.i1Ob-q_shadow-none {
  box-shadow: none !important;
}

.i1Ob-q_position-static {
  position: static !important;
}

.i1Ob-q_position-relative {
  position: relative !important;
}

.i1Ob-q_position-absolute {
  position: absolute !important;
}

.i1Ob-q_position-fixed {
  position: fixed !important;
}

.i1Ob-q_position-sticky {
  position: sticky !important;
}

.i1Ob-q_top-0 {
  top: 0 !important;
}

.i1Ob-q_top-50 {
  top: 50% !important;
}

.i1Ob-q_top-100 {
  top: 100% !important;
}

.i1Ob-q_bottom-0 {
  bottom: 0 !important;
}

.i1Ob-q_bottom-50 {
  bottom: 50% !important;
}

.i1Ob-q_bottom-100 {
  bottom: 100% !important;
}

.i1Ob-q_start-0 {
  left: 0 !important;
}

.i1Ob-q_start-50 {
  left: 50% !important;
}

.i1Ob-q_start-100 {
  left: 100% !important;
}

.i1Ob-q_end-0 {
  right: 0 !important;
}

.i1Ob-q_end-50 {
  right: 50% !important;
}

.i1Ob-q_end-100 {
  right: 100% !important;
}

.i1Ob-q_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.i1Ob-q_translate-middle-x {
  transform: translateX(-50%) !important;
}

.i1Ob-q_translate-middle-y {
  transform: translateY(-50%) !important;
}

.i1Ob-q_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.i1Ob-q_border-0 {
  border: 0 !important;
}

.i1Ob-q_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.i1Ob-q_border-top-0 {
  border-top: 0 !important;
}

.i1Ob-q_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.i1Ob-q_border-end-0 {
  border-right: 0 !important;
}

.i1Ob-q_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.i1Ob-q_border-bottom-0 {
  border-bottom: 0 !important;
}

.i1Ob-q_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.i1Ob-q_border-start-0 {
  border-left: 0 !important;
}

.i1Ob-q_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.i1Ob-q_border-1 {
  --bs-border-width: 1px;
}

.i1Ob-q_border-2 {
  --bs-border-width: 2px;
}

.i1Ob-q_border-3 {
  --bs-border-width: 3px;
}

.i1Ob-q_border-4 {
  --bs-border-width: 4px;
}

.i1Ob-q_border-5 {
  --bs-border-width: 5px;
}

.i1Ob-q_border-opacity-10 {
  --bs-border-opacity: .1;
}

.i1Ob-q_border-opacity-25 {
  --bs-border-opacity: .25;
}

.i1Ob-q_border-opacity-50 {
  --bs-border-opacity: .5;
}

.i1Ob-q_border-opacity-75 {
  --bs-border-opacity: .75;
}

.i1Ob-q_border-opacity-100 {
  --bs-border-opacity: 1;
}

.i1Ob-q_w-25 {
  width: 25% !important;
}

.i1Ob-q_w-50 {
  width: 50% !important;
}

.i1Ob-q_w-75 {
  width: 75% !important;
}

.i1Ob-q_w-100 {
  width: 100% !important;
}

.i1Ob-q_w-auto {
  width: auto !important;
}

.i1Ob-q_mw-100 {
  max-width: 100% !important;
}

.i1Ob-q_vw-100 {
  width: 100vw !important;
}

.i1Ob-q_min-vw-100 {
  min-width: 100vw !important;
}

.i1Ob-q_h-25 {
  height: 25% !important;
}

.i1Ob-q_h-50 {
  height: 50% !important;
}

.i1Ob-q_h-75 {
  height: 75% !important;
}

.i1Ob-q_h-100 {
  height: 100% !important;
}

.i1Ob-q_h-auto {
  height: auto !important;
}

.i1Ob-q_mh-100 {
  max-height: 100% !important;
}

.i1Ob-q_vh-100 {
  height: 100vh !important;
}

.i1Ob-q_min-vh-100 {
  min-height: 100vh !important;
}

.i1Ob-q_flex-fill {
  flex: auto !important;
}

.i1Ob-q_flex-row {
  flex-direction: row !important;
}

.i1Ob-q_flex-column {
  flex-direction: column !important;
}

.i1Ob-q_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.i1Ob-q_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.i1Ob-q_flex-grow-0 {
  flex-grow: 0 !important;
}

.i1Ob-q_flex-grow-1 {
  flex-grow: 1 !important;
}

.i1Ob-q_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.i1Ob-q_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.i1Ob-q_flex-wrap {
  flex-wrap: wrap !important;
}

.i1Ob-q_flex-nowrap {
  flex-wrap: nowrap !important;
}

.i1Ob-q_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.i1Ob-q_justify-content-start {
  justify-content: flex-start !important;
}

.i1Ob-q_justify-content-end {
  justify-content: flex-end !important;
}

.i1Ob-q_justify-content-center {
  justify-content: center !important;
}

.i1Ob-q_justify-content-between {
  justify-content: space-between !important;
}

.i1Ob-q_justify-content-around {
  justify-content: space-around !important;
}

.i1Ob-q_justify-content-evenly {
  justify-content: space-evenly !important;
}

.i1Ob-q_align-items-start {
  align-items: flex-start !important;
}

.i1Ob-q_align-items-end {
  align-items: flex-end !important;
}

.i1Ob-q_align-items-center {
  align-items: center !important;
}

.i1Ob-q_align-items-baseline {
  align-items: baseline !important;
}

.i1Ob-q_align-items-stretch {
  align-items: stretch !important;
}

.i1Ob-q_align-content-start {
  align-content: flex-start !important;
}

.i1Ob-q_align-content-end {
  align-content: flex-end !important;
}

.i1Ob-q_align-content-center {
  align-content: center !important;
}

.i1Ob-q_align-content-between {
  align-content: space-between !important;
}

.i1Ob-q_align-content-around {
  align-content: space-around !important;
}

.i1Ob-q_align-content-stretch {
  align-content: stretch !important;
}

.i1Ob-q_align-self-auto {
  align-self: auto !important;
}

.i1Ob-q_align-self-start {
  align-self: flex-start !important;
}

.i1Ob-q_align-self-end {
  align-self: flex-end !important;
}

.i1Ob-q_align-self-center {
  align-self: center !important;
}

.i1Ob-q_align-self-baseline {
  align-self: baseline !important;
}

.i1Ob-q_align-self-stretch {
  align-self: stretch !important;
}

.i1Ob-q_order-first {
  order: -1 !important;
}

.i1Ob-q_order-0 {
  order: 0 !important;
}

.i1Ob-q_order-1 {
  order: 1 !important;
}

.i1Ob-q_order-2 {
  order: 2 !important;
}

.i1Ob-q_order-3 {
  order: 3 !important;
}

.i1Ob-q_order-4 {
  order: 4 !important;
}

.i1Ob-q_order-5 {
  order: 5 !important;
}

.i1Ob-q_order-last {
  order: 6 !important;
}

.i1Ob-q_m-0 {
  margin: 0 !important;
}

.i1Ob-q_m-1 {
  margin: .25rem !important;
}

.i1Ob-q_m-2 {
  margin: .5rem !important;
}

.i1Ob-q_m-3 {
  margin: 1rem !important;
}

.i1Ob-q_m-4 {
  margin: 1.5rem !important;
}

.i1Ob-q_m-5 {
  margin: 3rem !important;
}

.i1Ob-q_m-auto {
  margin: auto !important;
}

.i1Ob-q_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.i1Ob-q_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.i1Ob-q_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.i1Ob-q_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.i1Ob-q_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.i1Ob-q_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.i1Ob-q_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.i1Ob-q_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.i1Ob-q_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.i1Ob-q_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.i1Ob-q_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.i1Ob-q_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.i1Ob-q_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.i1Ob-q_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.i1Ob-q_mt-0 {
  margin-top: 0 !important;
}

.i1Ob-q_mt-1 {
  margin-top: .25rem !important;
}

.i1Ob-q_mt-2 {
  margin-top: .5rem !important;
}

.i1Ob-q_mt-3 {
  margin-top: 1rem !important;
}

.i1Ob-q_mt-4 {
  margin-top: 1.5rem !important;
}

.i1Ob-q_mt-5 {
  margin-top: 3rem !important;
}

.i1Ob-q_mt-auto {
  margin-top: auto !important;
}

.i1Ob-q_me-0 {
  margin-right: 0 !important;
}

.i1Ob-q_me-1 {
  margin-right: .25rem !important;
}

.i1Ob-q_me-2 {
  margin-right: .5rem !important;
}

.i1Ob-q_me-3 {
  margin-right: 1rem !important;
}

.i1Ob-q_me-4 {
  margin-right: 1.5rem !important;
}

.i1Ob-q_me-5 {
  margin-right: 3rem !important;
}

.i1Ob-q_me-auto {
  margin-right: auto !important;
}

.i1Ob-q_mb-0 {
  margin-bottom: 0 !important;
}

.i1Ob-q_mb-1 {
  margin-bottom: .25rem !important;
}

.i1Ob-q_mb-2 {
  margin-bottom: .5rem !important;
}

.i1Ob-q_mb-3 {
  margin-bottom: 1rem !important;
}

.i1Ob-q_mb-4 {
  margin-bottom: 1.5rem !important;
}

.i1Ob-q_mb-5 {
  margin-bottom: 3rem !important;
}

.i1Ob-q_mb-auto {
  margin-bottom: auto !important;
}

.i1Ob-q_ms-0 {
  margin-left: 0 !important;
}

.i1Ob-q_ms-1 {
  margin-left: .25rem !important;
}

.i1Ob-q_ms-2 {
  margin-left: .5rem !important;
}

.i1Ob-q_ms-3 {
  margin-left: 1rem !important;
}

.i1Ob-q_ms-4 {
  margin-left: 1.5rem !important;
}

.i1Ob-q_ms-5 {
  margin-left: 3rem !important;
}

.i1Ob-q_ms-auto {
  margin-left: auto !important;
}

.i1Ob-q_p-0 {
  padding: 0 !important;
}

.i1Ob-q_p-1 {
  padding: .25rem !important;
}

.i1Ob-q_p-2 {
  padding: .5rem !important;
}

.i1Ob-q_p-3 {
  padding: 1rem !important;
}

.i1Ob-q_p-4 {
  padding: 1.5rem !important;
}

.i1Ob-q_p-5 {
  padding: 3rem !important;
}

.i1Ob-q_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.i1Ob-q_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.i1Ob-q_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.i1Ob-q_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.i1Ob-q_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.i1Ob-q_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.i1Ob-q_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.i1Ob-q_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.i1Ob-q_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.i1Ob-q_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.i1Ob-q_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.i1Ob-q_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.i1Ob-q_pt-0 {
  padding-top: 0 !important;
}

.i1Ob-q_pt-1 {
  padding-top: .25rem !important;
}

.i1Ob-q_pt-2 {
  padding-top: .5rem !important;
}

.i1Ob-q_pt-3 {
  padding-top: 1rem !important;
}

.i1Ob-q_pt-4 {
  padding-top: 1.5rem !important;
}

.i1Ob-q_pt-5 {
  padding-top: 3rem !important;
}

.i1Ob-q_pe-0 {
  padding-right: 0 !important;
}

.i1Ob-q_pe-1 {
  padding-right: .25rem !important;
}

.i1Ob-q_pe-2 {
  padding-right: .5rem !important;
}

.i1Ob-q_pe-3 {
  padding-right: 1rem !important;
}

.i1Ob-q_pe-4 {
  padding-right: 1.5rem !important;
}

.i1Ob-q_pe-5 {
  padding-right: 3rem !important;
}

.i1Ob-q_pb-0 {
  padding-bottom: 0 !important;
}

.i1Ob-q_pb-1 {
  padding-bottom: .25rem !important;
}

.i1Ob-q_pb-2 {
  padding-bottom: .5rem !important;
}

.i1Ob-q_pb-3 {
  padding-bottom: 1rem !important;
}

.i1Ob-q_pb-4 {
  padding-bottom: 1.5rem !important;
}

.i1Ob-q_pb-5 {
  padding-bottom: 3rem !important;
}

.i1Ob-q_ps-0 {
  padding-left: 0 !important;
}

.i1Ob-q_ps-1 {
  padding-left: .25rem !important;
}

.i1Ob-q_ps-2 {
  padding-left: .5rem !important;
}

.i1Ob-q_ps-3 {
  padding-left: 1rem !important;
}

.i1Ob-q_ps-4 {
  padding-left: 1.5rem !important;
}

.i1Ob-q_ps-5 {
  padding-left: 3rem !important;
}

.i1Ob-q_gap-0 {
  gap: 0 !important;
}

.i1Ob-q_gap-1 {
  gap: .25rem !important;
}

.i1Ob-q_gap-2 {
  gap: .5rem !important;
}

.i1Ob-q_gap-3 {
  gap: 1rem !important;
}

.i1Ob-q_gap-4 {
  gap: 1.5rem !important;
}

.i1Ob-q_gap-5 {
  gap: 3rem !important;
}

.i1Ob-q_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.i1Ob-q_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.i1Ob-q_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.i1Ob-q_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.i1Ob-q_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.i1Ob-q_fs-5 {
  font-size: 1.25rem !important;
}

.i1Ob-q_fs-6 {
  font-size: 1rem !important;
}

.i1Ob-q_fst-italic {
  font-style: italic !important;
}

.i1Ob-q_fst-normal {
  font-style: normal !important;
}

.i1Ob-q_fw-light {
  font-weight: 300 !important;
}

.i1Ob-q_fw-lighter {
  font-weight: lighter !important;
}

.i1Ob-q_fw-normal {
  font-weight: 400 !important;
}

.i1Ob-q_fw-bold {
  font-weight: 700 !important;
}

.i1Ob-q_fw-semibold {
  font-weight: 600 !important;
}

.i1Ob-q_fw-bolder {
  font-weight: bolder !important;
}

.i1Ob-q_lh-1 {
  line-height: 1 !important;
}

.i1Ob-q_lh-sm {
  line-height: 1.25 !important;
}

.i1Ob-q_lh-base {
  line-height: 1.5 !important;
}

.i1Ob-q_lh-lg {
  line-height: 2 !important;
}

.i1Ob-q_text-start {
  text-align: left !important;
}

.i1Ob-q_text-end {
  text-align: right !important;
}

.i1Ob-q_text-center {
  text-align: center !important;
}

.i1Ob-q_text-decoration-none {
  text-decoration: none !important;
}

.i1Ob-q_text-decoration-underline {
  text-decoration: underline !important;
}

.i1Ob-q_text-decoration-line-through {
  text-decoration: line-through !important;
}

.i1Ob-q_text-lowercase {
  text-transform: lowercase !important;
}

.i1Ob-q_text-uppercase {
  text-transform: uppercase !important;
}

.i1Ob-q_text-capitalize {
  text-transform: capitalize !important;
}

.i1Ob-q_text-wrap {
  white-space: normal !important;
}

.i1Ob-q_text-nowrap {
  white-space: nowrap !important;
}

.i1Ob-q_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.i1Ob-q_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.i1Ob-q_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.i1Ob-q_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.i1Ob-q_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.i1Ob-q_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.i1Ob-q_text-opacity-25 {
  --bs-text-opacity: .25;
}

.i1Ob-q_text-opacity-50 {
  --bs-text-opacity: .5;
}

.i1Ob-q_text-opacity-75 {
  --bs-text-opacity: .75;
}

.i1Ob-q_text-opacity-100 {
  --bs-text-opacity: 1;
}

.i1Ob-q_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.i1Ob-q_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.i1Ob-q_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.i1Ob-q_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.i1Ob-q_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.i1Ob-q_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.i1Ob-q_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.i1Ob-q_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.i1Ob-q_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.i1Ob-q_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.i1Ob-q_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.i1Ob-q_pe-none {
  pointer-events: none !important;
}

.i1Ob-q_pe-auto {
  pointer-events: auto !important;
}

.i1Ob-q_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_rounded-0 {
  border-radius: 0 !important;
}

.i1Ob-q_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.i1Ob-q_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.i1Ob-q_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.i1Ob-q_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.i1Ob-q_rounded-circle {
  border-radius: 50% !important;
}

.i1Ob-q_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.i1Ob-q_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.i1Ob-q_visible {
  visibility: visible !important;
}

.i1Ob-q_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .i1Ob-q_float-sm-start {
    float: left !important;
  }

  .i1Ob-q_float-sm-end {
    float: right !important;
  }

  .i1Ob-q_float-sm-none {
    float: none !important;
  }

  .i1Ob-q_d-sm-inline {
    display: inline !important;
  }

  .i1Ob-q_d-sm-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-sm-block {
    display: block !important;
  }

  .i1Ob-q_d-sm-grid {
    display: grid !important;
  }

  .i1Ob-q_d-sm-table {
    display: table !important;
  }

  .i1Ob-q_d-sm-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-sm-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-sm-flex {
    display: flex !important;
  }

  .i1Ob-q_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-sm-none {
    display: none !important;
  }

  .i1Ob-q_flex-sm-fill {
    flex: auto !important;
  }

  .i1Ob-q_flex-sm-row {
    flex-direction: row !important;
  }

  .i1Ob-q_flex-sm-column {
    flex-direction: column !important;
  }

  .i1Ob-q_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .i1Ob-q_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .i1Ob-q_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .i1Ob-q_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .i1Ob-q_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .i1Ob-q_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .i1Ob-q_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .i1Ob-q_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .i1Ob-q_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .i1Ob-q_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .i1Ob-q_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .i1Ob-q_justify-content-sm-center {
    justify-content: center !important;
  }

  .i1Ob-q_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .i1Ob-q_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .i1Ob-q_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .i1Ob-q_align-items-sm-start {
    align-items: flex-start !important;
  }

  .i1Ob-q_align-items-sm-end {
    align-items: flex-end !important;
  }

  .i1Ob-q_align-items-sm-center {
    align-items: center !important;
  }

  .i1Ob-q_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .i1Ob-q_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .i1Ob-q_align-content-sm-start {
    align-content: flex-start !important;
  }

  .i1Ob-q_align-content-sm-end {
    align-content: flex-end !important;
  }

  .i1Ob-q_align-content-sm-center {
    align-content: center !important;
  }

  .i1Ob-q_align-content-sm-between {
    align-content: space-between !important;
  }

  .i1Ob-q_align-content-sm-around {
    align-content: space-around !important;
  }

  .i1Ob-q_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .i1Ob-q_align-self-sm-auto {
    align-self: auto !important;
  }

  .i1Ob-q_align-self-sm-start {
    align-self: flex-start !important;
  }

  .i1Ob-q_align-self-sm-end {
    align-self: flex-end !important;
  }

  .i1Ob-q_align-self-sm-center {
    align-self: center !important;
  }

  .i1Ob-q_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .i1Ob-q_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .i1Ob-q_order-sm-first {
    order: -1 !important;
  }

  .i1Ob-q_order-sm-0 {
    order: 0 !important;
  }

  .i1Ob-q_order-sm-1 {
    order: 1 !important;
  }

  .i1Ob-q_order-sm-2 {
    order: 2 !important;
  }

  .i1Ob-q_order-sm-3 {
    order: 3 !important;
  }

  .i1Ob-q_order-sm-4 {
    order: 4 !important;
  }

  .i1Ob-q_order-sm-5 {
    order: 5 !important;
  }

  .i1Ob-q_order-sm-last {
    order: 6 !important;
  }

  .i1Ob-q_m-sm-0 {
    margin: 0 !important;
  }

  .i1Ob-q_m-sm-1 {
    margin: .25rem !important;
  }

  .i1Ob-q_m-sm-2 {
    margin: .5rem !important;
  }

  .i1Ob-q_m-sm-3 {
    margin: 1rem !important;
  }

  .i1Ob-q_m-sm-4 {
    margin: 1.5rem !important;
  }

  .i1Ob-q_m-sm-5 {
    margin: 3rem !important;
  }

  .i1Ob-q_m-sm-auto {
    margin: auto !important;
  }

  .i1Ob-q_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .i1Ob-q_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .i1Ob-q_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .i1Ob-q_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .i1Ob-q_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .i1Ob-q_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .i1Ob-q_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .i1Ob-q_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .i1Ob-q_mt-sm-0 {
    margin-top: 0 !important;
  }

  .i1Ob-q_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .i1Ob-q_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .i1Ob-q_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .i1Ob-q_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .i1Ob-q_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .i1Ob-q_mt-sm-auto {
    margin-top: auto !important;
  }

  .i1Ob-q_me-sm-0 {
    margin-right: 0 !important;
  }

  .i1Ob-q_me-sm-1 {
    margin-right: .25rem !important;
  }

  .i1Ob-q_me-sm-2 {
    margin-right: .5rem !important;
  }

  .i1Ob-q_me-sm-3 {
    margin-right: 1rem !important;
  }

  .i1Ob-q_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_me-sm-5 {
    margin-right: 3rem !important;
  }

  .i1Ob-q_me-sm-auto {
    margin-right: auto !important;
  }

  .i1Ob-q_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .i1Ob-q_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .i1Ob-q_ms-sm-0 {
    margin-left: 0 !important;
  }

  .i1Ob-q_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .i1Ob-q_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .i1Ob-q_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .i1Ob-q_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .i1Ob-q_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .i1Ob-q_ms-sm-auto {
    margin-left: auto !important;
  }

  .i1Ob-q_p-sm-0 {
    padding: 0 !important;
  }

  .i1Ob-q_p-sm-1 {
    padding: .25rem !important;
  }

  .i1Ob-q_p-sm-2 {
    padding: .5rem !important;
  }

  .i1Ob-q_p-sm-3 {
    padding: 1rem !important;
  }

  .i1Ob-q_p-sm-4 {
    padding: 1.5rem !important;
  }

  .i1Ob-q_p-sm-5 {
    padding: 3rem !important;
  }

  .i1Ob-q_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .i1Ob-q_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .i1Ob-q_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .i1Ob-q_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .i1Ob-q_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .i1Ob-q_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .i1Ob-q_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_pt-sm-0 {
    padding-top: 0 !important;
  }

  .i1Ob-q_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .i1Ob-q_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .i1Ob-q_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .i1Ob-q_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .i1Ob-q_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .i1Ob-q_pe-sm-0 {
    padding-right: 0 !important;
  }

  .i1Ob-q_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .i1Ob-q_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .i1Ob-q_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .i1Ob-q_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .i1Ob-q_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .i1Ob-q_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_ps-sm-0 {
    padding-left: 0 !important;
  }

  .i1Ob-q_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .i1Ob-q_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .i1Ob-q_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .i1Ob-q_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .i1Ob-q_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .i1Ob-q_gap-sm-0 {
    gap: 0 !important;
  }

  .i1Ob-q_gap-sm-1 {
    gap: .25rem !important;
  }

  .i1Ob-q_gap-sm-2 {
    gap: .5rem !important;
  }

  .i1Ob-q_gap-sm-3 {
    gap: 1rem !important;
  }

  .i1Ob-q_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .i1Ob-q_gap-sm-5 {
    gap: 3rem !important;
  }

  .i1Ob-q_text-sm-start {
    text-align: left !important;
  }

  .i1Ob-q_text-sm-end {
    text-align: right !important;
  }

  .i1Ob-q_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .i1Ob-q_float-md-start {
    float: left !important;
  }

  .i1Ob-q_float-md-end {
    float: right !important;
  }

  .i1Ob-q_float-md-none {
    float: none !important;
  }

  .i1Ob-q_d-md-inline {
    display: inline !important;
  }

  .i1Ob-q_d-md-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-md-block {
    display: block !important;
  }

  .i1Ob-q_d-md-grid {
    display: grid !important;
  }

  .i1Ob-q_d-md-table {
    display: table !important;
  }

  .i1Ob-q_d-md-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-md-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-md-flex {
    display: flex !important;
  }

  .i1Ob-q_d-md-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-md-none {
    display: none !important;
  }

  .i1Ob-q_flex-md-fill {
    flex: auto !important;
  }

  .i1Ob-q_flex-md-row {
    flex-direction: row !important;
  }

  .i1Ob-q_flex-md-column {
    flex-direction: column !important;
  }

  .i1Ob-q_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .i1Ob-q_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .i1Ob-q_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .i1Ob-q_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .i1Ob-q_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .i1Ob-q_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .i1Ob-q_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .i1Ob-q_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .i1Ob-q_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .i1Ob-q_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .i1Ob-q_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .i1Ob-q_justify-content-md-center {
    justify-content: center !important;
  }

  .i1Ob-q_justify-content-md-between {
    justify-content: space-between !important;
  }

  .i1Ob-q_justify-content-md-around {
    justify-content: space-around !important;
  }

  .i1Ob-q_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .i1Ob-q_align-items-md-start {
    align-items: flex-start !important;
  }

  .i1Ob-q_align-items-md-end {
    align-items: flex-end !important;
  }

  .i1Ob-q_align-items-md-center {
    align-items: center !important;
  }

  .i1Ob-q_align-items-md-baseline {
    align-items: baseline !important;
  }

  .i1Ob-q_align-items-md-stretch {
    align-items: stretch !important;
  }

  .i1Ob-q_align-content-md-start {
    align-content: flex-start !important;
  }

  .i1Ob-q_align-content-md-end {
    align-content: flex-end !important;
  }

  .i1Ob-q_align-content-md-center {
    align-content: center !important;
  }

  .i1Ob-q_align-content-md-between {
    align-content: space-between !important;
  }

  .i1Ob-q_align-content-md-around {
    align-content: space-around !important;
  }

  .i1Ob-q_align-content-md-stretch {
    align-content: stretch !important;
  }

  .i1Ob-q_align-self-md-auto {
    align-self: auto !important;
  }

  .i1Ob-q_align-self-md-start {
    align-self: flex-start !important;
  }

  .i1Ob-q_align-self-md-end {
    align-self: flex-end !important;
  }

  .i1Ob-q_align-self-md-center {
    align-self: center !important;
  }

  .i1Ob-q_align-self-md-baseline {
    align-self: baseline !important;
  }

  .i1Ob-q_align-self-md-stretch {
    align-self: stretch !important;
  }

  .i1Ob-q_order-md-first {
    order: -1 !important;
  }

  .i1Ob-q_order-md-0 {
    order: 0 !important;
  }

  .i1Ob-q_order-md-1 {
    order: 1 !important;
  }

  .i1Ob-q_order-md-2 {
    order: 2 !important;
  }

  .i1Ob-q_order-md-3 {
    order: 3 !important;
  }

  .i1Ob-q_order-md-4 {
    order: 4 !important;
  }

  .i1Ob-q_order-md-5 {
    order: 5 !important;
  }

  .i1Ob-q_order-md-last {
    order: 6 !important;
  }

  .i1Ob-q_m-md-0 {
    margin: 0 !important;
  }

  .i1Ob-q_m-md-1 {
    margin: .25rem !important;
  }

  .i1Ob-q_m-md-2 {
    margin: .5rem !important;
  }

  .i1Ob-q_m-md-3 {
    margin: 1rem !important;
  }

  .i1Ob-q_m-md-4 {
    margin: 1.5rem !important;
  }

  .i1Ob-q_m-md-5 {
    margin: 3rem !important;
  }

  .i1Ob-q_m-md-auto {
    margin: auto !important;
  }

  .i1Ob-q_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .i1Ob-q_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .i1Ob-q_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .i1Ob-q_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .i1Ob-q_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .i1Ob-q_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .i1Ob-q_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .i1Ob-q_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .i1Ob-q_mt-md-0 {
    margin-top: 0 !important;
  }

  .i1Ob-q_mt-md-1 {
    margin-top: .25rem !important;
  }

  .i1Ob-q_mt-md-2 {
    margin-top: .5rem !important;
  }

  .i1Ob-q_mt-md-3 {
    margin-top: 1rem !important;
  }

  .i1Ob-q_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .i1Ob-q_mt-md-5 {
    margin-top: 3rem !important;
  }

  .i1Ob-q_mt-md-auto {
    margin-top: auto !important;
  }

  .i1Ob-q_me-md-0 {
    margin-right: 0 !important;
  }

  .i1Ob-q_me-md-1 {
    margin-right: .25rem !important;
  }

  .i1Ob-q_me-md-2 {
    margin-right: .5rem !important;
  }

  .i1Ob-q_me-md-3 {
    margin-right: 1rem !important;
  }

  .i1Ob-q_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_me-md-5 {
    margin-right: 3rem !important;
  }

  .i1Ob-q_me-md-auto {
    margin-right: auto !important;
  }

  .i1Ob-q_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .i1Ob-q_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_mb-md-auto {
    margin-bottom: auto !important;
  }

  .i1Ob-q_ms-md-0 {
    margin-left: 0 !important;
  }

  .i1Ob-q_ms-md-1 {
    margin-left: .25rem !important;
  }

  .i1Ob-q_ms-md-2 {
    margin-left: .5rem !important;
  }

  .i1Ob-q_ms-md-3 {
    margin-left: 1rem !important;
  }

  .i1Ob-q_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .i1Ob-q_ms-md-5 {
    margin-left: 3rem !important;
  }

  .i1Ob-q_ms-md-auto {
    margin-left: auto !important;
  }

  .i1Ob-q_p-md-0 {
    padding: 0 !important;
  }

  .i1Ob-q_p-md-1 {
    padding: .25rem !important;
  }

  .i1Ob-q_p-md-2 {
    padding: .5rem !important;
  }

  .i1Ob-q_p-md-3 {
    padding: 1rem !important;
  }

  .i1Ob-q_p-md-4 {
    padding: 1.5rem !important;
  }

  .i1Ob-q_p-md-5 {
    padding: 3rem !important;
  }

  .i1Ob-q_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .i1Ob-q_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .i1Ob-q_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .i1Ob-q_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .i1Ob-q_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .i1Ob-q_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .i1Ob-q_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_pt-md-0 {
    padding-top: 0 !important;
  }

  .i1Ob-q_pt-md-1 {
    padding-top: .25rem !important;
  }

  .i1Ob-q_pt-md-2 {
    padding-top: .5rem !important;
  }

  .i1Ob-q_pt-md-3 {
    padding-top: 1rem !important;
  }

  .i1Ob-q_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .i1Ob-q_pt-md-5 {
    padding-top: 3rem !important;
  }

  .i1Ob-q_pe-md-0 {
    padding-right: 0 !important;
  }

  .i1Ob-q_pe-md-1 {
    padding-right: .25rem !important;
  }

  .i1Ob-q_pe-md-2 {
    padding-right: .5rem !important;
  }

  .i1Ob-q_pe-md-3 {
    padding-right: 1rem !important;
  }

  .i1Ob-q_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_pe-md-5 {
    padding-right: 3rem !important;
  }

  .i1Ob-q_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .i1Ob-q_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_ps-md-0 {
    padding-left: 0 !important;
  }

  .i1Ob-q_ps-md-1 {
    padding-left: .25rem !important;
  }

  .i1Ob-q_ps-md-2 {
    padding-left: .5rem !important;
  }

  .i1Ob-q_ps-md-3 {
    padding-left: 1rem !important;
  }

  .i1Ob-q_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .i1Ob-q_ps-md-5 {
    padding-left: 3rem !important;
  }

  .i1Ob-q_gap-md-0 {
    gap: 0 !important;
  }

  .i1Ob-q_gap-md-1 {
    gap: .25rem !important;
  }

  .i1Ob-q_gap-md-2 {
    gap: .5rem !important;
  }

  .i1Ob-q_gap-md-3 {
    gap: 1rem !important;
  }

  .i1Ob-q_gap-md-4 {
    gap: 1.5rem !important;
  }

  .i1Ob-q_gap-md-5 {
    gap: 3rem !important;
  }

  .i1Ob-q_text-md-start {
    text-align: left !important;
  }

  .i1Ob-q_text-md-end {
    text-align: right !important;
  }

  .i1Ob-q_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .i1Ob-q_float-lg-start {
    float: left !important;
  }

  .i1Ob-q_float-lg-end {
    float: right !important;
  }

  .i1Ob-q_float-lg-none {
    float: none !important;
  }

  .i1Ob-q_d-lg-inline {
    display: inline !important;
  }

  .i1Ob-q_d-lg-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-lg-block {
    display: block !important;
  }

  .i1Ob-q_d-lg-grid {
    display: grid !important;
  }

  .i1Ob-q_d-lg-table {
    display: table !important;
  }

  .i1Ob-q_d-lg-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-lg-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-lg-flex {
    display: flex !important;
  }

  .i1Ob-q_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-lg-none {
    display: none !important;
  }

  .i1Ob-q_flex-lg-fill {
    flex: auto !important;
  }

  .i1Ob-q_flex-lg-row {
    flex-direction: row !important;
  }

  .i1Ob-q_flex-lg-column {
    flex-direction: column !important;
  }

  .i1Ob-q_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .i1Ob-q_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .i1Ob-q_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .i1Ob-q_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .i1Ob-q_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .i1Ob-q_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .i1Ob-q_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .i1Ob-q_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .i1Ob-q_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .i1Ob-q_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .i1Ob-q_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .i1Ob-q_justify-content-lg-center {
    justify-content: center !important;
  }

  .i1Ob-q_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .i1Ob-q_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .i1Ob-q_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .i1Ob-q_align-items-lg-start {
    align-items: flex-start !important;
  }

  .i1Ob-q_align-items-lg-end {
    align-items: flex-end !important;
  }

  .i1Ob-q_align-items-lg-center {
    align-items: center !important;
  }

  .i1Ob-q_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .i1Ob-q_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .i1Ob-q_align-content-lg-start {
    align-content: flex-start !important;
  }

  .i1Ob-q_align-content-lg-end {
    align-content: flex-end !important;
  }

  .i1Ob-q_align-content-lg-center {
    align-content: center !important;
  }

  .i1Ob-q_align-content-lg-between {
    align-content: space-between !important;
  }

  .i1Ob-q_align-content-lg-around {
    align-content: space-around !important;
  }

  .i1Ob-q_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .i1Ob-q_align-self-lg-auto {
    align-self: auto !important;
  }

  .i1Ob-q_align-self-lg-start {
    align-self: flex-start !important;
  }

  .i1Ob-q_align-self-lg-end {
    align-self: flex-end !important;
  }

  .i1Ob-q_align-self-lg-center {
    align-self: center !important;
  }

  .i1Ob-q_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .i1Ob-q_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .i1Ob-q_order-lg-first {
    order: -1 !important;
  }

  .i1Ob-q_order-lg-0 {
    order: 0 !important;
  }

  .i1Ob-q_order-lg-1 {
    order: 1 !important;
  }

  .i1Ob-q_order-lg-2 {
    order: 2 !important;
  }

  .i1Ob-q_order-lg-3 {
    order: 3 !important;
  }

  .i1Ob-q_order-lg-4 {
    order: 4 !important;
  }

  .i1Ob-q_order-lg-5 {
    order: 5 !important;
  }

  .i1Ob-q_order-lg-last {
    order: 6 !important;
  }

  .i1Ob-q_m-lg-0 {
    margin: 0 !important;
  }

  .i1Ob-q_m-lg-1 {
    margin: .25rem !important;
  }

  .i1Ob-q_m-lg-2 {
    margin: .5rem !important;
  }

  .i1Ob-q_m-lg-3 {
    margin: 1rem !important;
  }

  .i1Ob-q_m-lg-4 {
    margin: 1.5rem !important;
  }

  .i1Ob-q_m-lg-5 {
    margin: 3rem !important;
  }

  .i1Ob-q_m-lg-auto {
    margin: auto !important;
  }

  .i1Ob-q_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .i1Ob-q_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .i1Ob-q_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .i1Ob-q_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .i1Ob-q_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .i1Ob-q_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .i1Ob-q_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .i1Ob-q_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .i1Ob-q_mt-lg-0 {
    margin-top: 0 !important;
  }

  .i1Ob-q_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .i1Ob-q_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .i1Ob-q_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .i1Ob-q_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .i1Ob-q_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .i1Ob-q_mt-lg-auto {
    margin-top: auto !important;
  }

  .i1Ob-q_me-lg-0 {
    margin-right: 0 !important;
  }

  .i1Ob-q_me-lg-1 {
    margin-right: .25rem !important;
  }

  .i1Ob-q_me-lg-2 {
    margin-right: .5rem !important;
  }

  .i1Ob-q_me-lg-3 {
    margin-right: 1rem !important;
  }

  .i1Ob-q_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_me-lg-5 {
    margin-right: 3rem !important;
  }

  .i1Ob-q_me-lg-auto {
    margin-right: auto !important;
  }

  .i1Ob-q_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .i1Ob-q_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .i1Ob-q_ms-lg-0 {
    margin-left: 0 !important;
  }

  .i1Ob-q_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .i1Ob-q_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .i1Ob-q_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .i1Ob-q_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .i1Ob-q_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .i1Ob-q_ms-lg-auto {
    margin-left: auto !important;
  }

  .i1Ob-q_p-lg-0 {
    padding: 0 !important;
  }

  .i1Ob-q_p-lg-1 {
    padding: .25rem !important;
  }

  .i1Ob-q_p-lg-2 {
    padding: .5rem !important;
  }

  .i1Ob-q_p-lg-3 {
    padding: 1rem !important;
  }

  .i1Ob-q_p-lg-4 {
    padding: 1.5rem !important;
  }

  .i1Ob-q_p-lg-5 {
    padding: 3rem !important;
  }

  .i1Ob-q_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .i1Ob-q_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .i1Ob-q_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .i1Ob-q_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .i1Ob-q_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .i1Ob-q_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .i1Ob-q_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_pt-lg-0 {
    padding-top: 0 !important;
  }

  .i1Ob-q_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .i1Ob-q_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .i1Ob-q_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .i1Ob-q_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .i1Ob-q_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .i1Ob-q_pe-lg-0 {
    padding-right: 0 !important;
  }

  .i1Ob-q_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .i1Ob-q_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .i1Ob-q_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .i1Ob-q_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .i1Ob-q_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .i1Ob-q_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_ps-lg-0 {
    padding-left: 0 !important;
  }

  .i1Ob-q_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .i1Ob-q_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .i1Ob-q_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .i1Ob-q_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .i1Ob-q_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .i1Ob-q_gap-lg-0 {
    gap: 0 !important;
  }

  .i1Ob-q_gap-lg-1 {
    gap: .25rem !important;
  }

  .i1Ob-q_gap-lg-2 {
    gap: .5rem !important;
  }

  .i1Ob-q_gap-lg-3 {
    gap: 1rem !important;
  }

  .i1Ob-q_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .i1Ob-q_gap-lg-5 {
    gap: 3rem !important;
  }

  .i1Ob-q_text-lg-start {
    text-align: left !important;
  }

  .i1Ob-q_text-lg-end {
    text-align: right !important;
  }

  .i1Ob-q_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_float-xl-start {
    float: left !important;
  }

  .i1Ob-q_float-xl-end {
    float: right !important;
  }

  .i1Ob-q_float-xl-none {
    float: none !important;
  }

  .i1Ob-q_d-xl-inline {
    display: inline !important;
  }

  .i1Ob-q_d-xl-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-xl-block {
    display: block !important;
  }

  .i1Ob-q_d-xl-grid {
    display: grid !important;
  }

  .i1Ob-q_d-xl-table {
    display: table !important;
  }

  .i1Ob-q_d-xl-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-xl-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-xl-flex {
    display: flex !important;
  }

  .i1Ob-q_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-xl-none {
    display: none !important;
  }

  .i1Ob-q_flex-xl-fill {
    flex: auto !important;
  }

  .i1Ob-q_flex-xl-row {
    flex-direction: row !important;
  }

  .i1Ob-q_flex-xl-column {
    flex-direction: column !important;
  }

  .i1Ob-q_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .i1Ob-q_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .i1Ob-q_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .i1Ob-q_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .i1Ob-q_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .i1Ob-q_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .i1Ob-q_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .i1Ob-q_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .i1Ob-q_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .i1Ob-q_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .i1Ob-q_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .i1Ob-q_justify-content-xl-center {
    justify-content: center !important;
  }

  .i1Ob-q_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .i1Ob-q_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .i1Ob-q_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .i1Ob-q_align-items-xl-start {
    align-items: flex-start !important;
  }

  .i1Ob-q_align-items-xl-end {
    align-items: flex-end !important;
  }

  .i1Ob-q_align-items-xl-center {
    align-items: center !important;
  }

  .i1Ob-q_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .i1Ob-q_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .i1Ob-q_align-content-xl-start {
    align-content: flex-start !important;
  }

  .i1Ob-q_align-content-xl-end {
    align-content: flex-end !important;
  }

  .i1Ob-q_align-content-xl-center {
    align-content: center !important;
  }

  .i1Ob-q_align-content-xl-between {
    align-content: space-between !important;
  }

  .i1Ob-q_align-content-xl-around {
    align-content: space-around !important;
  }

  .i1Ob-q_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .i1Ob-q_align-self-xl-auto {
    align-self: auto !important;
  }

  .i1Ob-q_align-self-xl-start {
    align-self: flex-start !important;
  }

  .i1Ob-q_align-self-xl-end {
    align-self: flex-end !important;
  }

  .i1Ob-q_align-self-xl-center {
    align-self: center !important;
  }

  .i1Ob-q_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .i1Ob-q_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .i1Ob-q_order-xl-first {
    order: -1 !important;
  }

  .i1Ob-q_order-xl-0 {
    order: 0 !important;
  }

  .i1Ob-q_order-xl-1 {
    order: 1 !important;
  }

  .i1Ob-q_order-xl-2 {
    order: 2 !important;
  }

  .i1Ob-q_order-xl-3 {
    order: 3 !important;
  }

  .i1Ob-q_order-xl-4 {
    order: 4 !important;
  }

  .i1Ob-q_order-xl-5 {
    order: 5 !important;
  }

  .i1Ob-q_order-xl-last {
    order: 6 !important;
  }

  .i1Ob-q_m-xl-0 {
    margin: 0 !important;
  }

  .i1Ob-q_m-xl-1 {
    margin: .25rem !important;
  }

  .i1Ob-q_m-xl-2 {
    margin: .5rem !important;
  }

  .i1Ob-q_m-xl-3 {
    margin: 1rem !important;
  }

  .i1Ob-q_m-xl-4 {
    margin: 1.5rem !important;
  }

  .i1Ob-q_m-xl-5 {
    margin: 3rem !important;
  }

  .i1Ob-q_m-xl-auto {
    margin: auto !important;
  }

  .i1Ob-q_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .i1Ob-q_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .i1Ob-q_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .i1Ob-q_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .i1Ob-q_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .i1Ob-q_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .i1Ob-q_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .i1Ob-q_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .i1Ob-q_mt-xl-0 {
    margin-top: 0 !important;
  }

  .i1Ob-q_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .i1Ob-q_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .i1Ob-q_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .i1Ob-q_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .i1Ob-q_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .i1Ob-q_mt-xl-auto {
    margin-top: auto !important;
  }

  .i1Ob-q_me-xl-0 {
    margin-right: 0 !important;
  }

  .i1Ob-q_me-xl-1 {
    margin-right: .25rem !important;
  }

  .i1Ob-q_me-xl-2 {
    margin-right: .5rem !important;
  }

  .i1Ob-q_me-xl-3 {
    margin-right: 1rem !important;
  }

  .i1Ob-q_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_me-xl-5 {
    margin-right: 3rem !important;
  }

  .i1Ob-q_me-xl-auto {
    margin-right: auto !important;
  }

  .i1Ob-q_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .i1Ob-q_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .i1Ob-q_ms-xl-0 {
    margin-left: 0 !important;
  }

  .i1Ob-q_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .i1Ob-q_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .i1Ob-q_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .i1Ob-q_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .i1Ob-q_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .i1Ob-q_ms-xl-auto {
    margin-left: auto !important;
  }

  .i1Ob-q_p-xl-0 {
    padding: 0 !important;
  }

  .i1Ob-q_p-xl-1 {
    padding: .25rem !important;
  }

  .i1Ob-q_p-xl-2 {
    padding: .5rem !important;
  }

  .i1Ob-q_p-xl-3 {
    padding: 1rem !important;
  }

  .i1Ob-q_p-xl-4 {
    padding: 1.5rem !important;
  }

  .i1Ob-q_p-xl-5 {
    padding: 3rem !important;
  }

  .i1Ob-q_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .i1Ob-q_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .i1Ob-q_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .i1Ob-q_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .i1Ob-q_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .i1Ob-q_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .i1Ob-q_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_pt-xl-0 {
    padding-top: 0 !important;
  }

  .i1Ob-q_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .i1Ob-q_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .i1Ob-q_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .i1Ob-q_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .i1Ob-q_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .i1Ob-q_pe-xl-0 {
    padding-right: 0 !important;
  }

  .i1Ob-q_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .i1Ob-q_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .i1Ob-q_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .i1Ob-q_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .i1Ob-q_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .i1Ob-q_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_ps-xl-0 {
    padding-left: 0 !important;
  }

  .i1Ob-q_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .i1Ob-q_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .i1Ob-q_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .i1Ob-q_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .i1Ob-q_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .i1Ob-q_gap-xl-0 {
    gap: 0 !important;
  }

  .i1Ob-q_gap-xl-1 {
    gap: .25rem !important;
  }

  .i1Ob-q_gap-xl-2 {
    gap: .5rem !important;
  }

  .i1Ob-q_gap-xl-3 {
    gap: 1rem !important;
  }

  .i1Ob-q_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .i1Ob-q_gap-xl-5 {
    gap: 3rem !important;
  }

  .i1Ob-q_text-xl-start {
    text-align: left !important;
  }

  .i1Ob-q_text-xl-end {
    text-align: right !important;
  }

  .i1Ob-q_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .i1Ob-q_float-xxl-start {
    float: left !important;
  }

  .i1Ob-q_float-xxl-end {
    float: right !important;
  }

  .i1Ob-q_float-xxl-none {
    float: none !important;
  }

  .i1Ob-q_d-xxl-inline {
    display: inline !important;
  }

  .i1Ob-q_d-xxl-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-xxl-block {
    display: block !important;
  }

  .i1Ob-q_d-xxl-grid {
    display: grid !important;
  }

  .i1Ob-q_d-xxl-table {
    display: table !important;
  }

  .i1Ob-q_d-xxl-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-xxl-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-xxl-flex {
    display: flex !important;
  }

  .i1Ob-q_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-xxl-none {
    display: none !important;
  }

  .i1Ob-q_flex-xxl-fill {
    flex: auto !important;
  }

  .i1Ob-q_flex-xxl-row {
    flex-direction: row !important;
  }

  .i1Ob-q_flex-xxl-column {
    flex-direction: column !important;
  }

  .i1Ob-q_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .i1Ob-q_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .i1Ob-q_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .i1Ob-q_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .i1Ob-q_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .i1Ob-q_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .i1Ob-q_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .i1Ob-q_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .i1Ob-q_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .i1Ob-q_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .i1Ob-q_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .i1Ob-q_justify-content-xxl-center {
    justify-content: center !important;
  }

  .i1Ob-q_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .i1Ob-q_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .i1Ob-q_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .i1Ob-q_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .i1Ob-q_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .i1Ob-q_align-items-xxl-center {
    align-items: center !important;
  }

  .i1Ob-q_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .i1Ob-q_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .i1Ob-q_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .i1Ob-q_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .i1Ob-q_align-content-xxl-center {
    align-content: center !important;
  }

  .i1Ob-q_align-content-xxl-between {
    align-content: space-between !important;
  }

  .i1Ob-q_align-content-xxl-around {
    align-content: space-around !important;
  }

  .i1Ob-q_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .i1Ob-q_align-self-xxl-auto {
    align-self: auto !important;
  }

  .i1Ob-q_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .i1Ob-q_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .i1Ob-q_align-self-xxl-center {
    align-self: center !important;
  }

  .i1Ob-q_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .i1Ob-q_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .i1Ob-q_order-xxl-first {
    order: -1 !important;
  }

  .i1Ob-q_order-xxl-0 {
    order: 0 !important;
  }

  .i1Ob-q_order-xxl-1 {
    order: 1 !important;
  }

  .i1Ob-q_order-xxl-2 {
    order: 2 !important;
  }

  .i1Ob-q_order-xxl-3 {
    order: 3 !important;
  }

  .i1Ob-q_order-xxl-4 {
    order: 4 !important;
  }

  .i1Ob-q_order-xxl-5 {
    order: 5 !important;
  }

  .i1Ob-q_order-xxl-last {
    order: 6 !important;
  }

  .i1Ob-q_m-xxl-0 {
    margin: 0 !important;
  }

  .i1Ob-q_m-xxl-1 {
    margin: .25rem !important;
  }

  .i1Ob-q_m-xxl-2 {
    margin: .5rem !important;
  }

  .i1Ob-q_m-xxl-3 {
    margin: 1rem !important;
  }

  .i1Ob-q_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .i1Ob-q_m-xxl-5 {
    margin: 3rem !important;
  }

  .i1Ob-q_m-xxl-auto {
    margin: auto !important;
  }

  .i1Ob-q_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .i1Ob-q_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .i1Ob-q_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .i1Ob-q_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .i1Ob-q_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .i1Ob-q_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .i1Ob-q_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .i1Ob-q_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .i1Ob-q_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .i1Ob-q_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .i1Ob-q_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .i1Ob-q_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .i1Ob-q_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .i1Ob-q_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .i1Ob-q_mt-xxl-auto {
    margin-top: auto !important;
  }

  .i1Ob-q_me-xxl-0 {
    margin-right: 0 !important;
  }

  .i1Ob-q_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .i1Ob-q_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .i1Ob-q_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .i1Ob-q_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .i1Ob-q_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .i1Ob-q_me-xxl-auto {
    margin-right: auto !important;
  }

  .i1Ob-q_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .i1Ob-q_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .i1Ob-q_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .i1Ob-q_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .i1Ob-q_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .i1Ob-q_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .i1Ob-q_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .i1Ob-q_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .i1Ob-q_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .i1Ob-q_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .i1Ob-q_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .i1Ob-q_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .i1Ob-q_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .i1Ob-q_ms-xxl-auto {
    margin-left: auto !important;
  }

  .i1Ob-q_p-xxl-0 {
    padding: 0 !important;
  }

  .i1Ob-q_p-xxl-1 {
    padding: .25rem !important;
  }

  .i1Ob-q_p-xxl-2 {
    padding: .5rem !important;
  }

  .i1Ob-q_p-xxl-3 {
    padding: 1rem !important;
  }

  .i1Ob-q_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .i1Ob-q_p-xxl-5 {
    padding: 3rem !important;
  }

  .i1Ob-q_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .i1Ob-q_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .i1Ob-q_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .i1Ob-q_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .i1Ob-q_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .i1Ob-q_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .i1Ob-q_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .i1Ob-q_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .i1Ob-q_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .i1Ob-q_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .i1Ob-q_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .i1Ob-q_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .i1Ob-q_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .i1Ob-q_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .i1Ob-q_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .i1Ob-q_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .i1Ob-q_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .i1Ob-q_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .i1Ob-q_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .i1Ob-q_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .i1Ob-q_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .i1Ob-q_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .i1Ob-q_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .i1Ob-q_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .i1Ob-q_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .i1Ob-q_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .i1Ob-q_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .i1Ob-q_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .i1Ob-q_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .i1Ob-q_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .i1Ob-q_gap-xxl-0 {
    gap: 0 !important;
  }

  .i1Ob-q_gap-xxl-1 {
    gap: .25rem !important;
  }

  .i1Ob-q_gap-xxl-2 {
    gap: .5rem !important;
  }

  .i1Ob-q_gap-xxl-3 {
    gap: 1rem !important;
  }

  .i1Ob-q_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .i1Ob-q_gap-xxl-5 {
    gap: 3rem !important;
  }

  .i1Ob-q_text-xxl-start {
    text-align: left !important;
  }

  .i1Ob-q_text-xxl-end {
    text-align: right !important;
  }

  .i1Ob-q_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .i1Ob-q_fs-1 {
    font-size: 2.5rem !important;
  }

  .i1Ob-q_fs-2 {
    font-size: 2rem !important;
  }

  .i1Ob-q_fs-3 {
    font-size: 1.75rem !important;
  }

  .i1Ob-q_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .i1Ob-q_d-print-inline {
    display: inline !important;
  }

  .i1Ob-q_d-print-inline-block {
    display: inline-block !important;
  }

  .i1Ob-q_d-print-block {
    display: block !important;
  }

  .i1Ob-q_d-print-grid {
    display: grid !important;
  }

  .i1Ob-q_d-print-table {
    display: table !important;
  }

  .i1Ob-q_d-print-table-row {
    display: table-row !important;
  }

  .i1Ob-q_d-print-table-cell {
    display: table-cell !important;
  }

  .i1Ob-q_d-print-flex {
    display: flex !important;
  }

  .i1Ob-q_d-print-inline-flex {
    display: inline-flex !important;
  }

  .i1Ob-q_d-print-none {
    display: none !important;
  }
}

.i1Ob-q_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.i1Ob-q_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-item .i1Ob-q_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.i1Ob-q_nav-tabs .i1Ob-q_nav-item .i1Ob-q_nav-link.i1Ob-q_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.i1Ob-q_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#i1Ob-q_root {
  height: 100%;
}

.i1Ob-q_highcharts-credits {
  display: none !important;
}

h3, .i1Ob-q_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.i1Ob-q_modal .i1Ob-q_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.i1Ob-q_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.i1Ob-q_table thead tr th.i1Ob-q_col-actions {
  width: 110px !important;
}

table.i1Ob-q_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.i1Ob-q_table tbody tr.i1Ob-q_deleting {
  background-color: #ff323233 !important;
}

.i1Ob-q_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.i1Ob-q_btn-sm, .i1Ob-q_btn-group-sm > .i1Ob-q_btn {
  font-size: .6rem !important;
}

.i1Ob-q_modal {
  background-color: #0a0a0a4d;
}

.i1Ob-q_btn:focus {
  box-shadow: none !important;
}

.i1Ob-q_content-filter .i1Ob-q_react-datepicker-wrapper {
  width: auto !important;
}

.i1Ob-q_content-filter .i1Ob-q_react-datepicker-wrapper .i1Ob-q_form-control {
  font-size: .85rem !important;
}

.i1Ob-q_content-filter .i1Ob-q_input-group-text, .i1Ob-q_content-filter .i1Ob-q_btn {
  height: 2.15rem !important;
}

.i1Ob-q_content-filter .i1Ob-q_btn {
  border-color: #ccc;
}

.i1Ob-q_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.i1Ob-q_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.i1Ob-q_table > :not(:first-child) {
  border-top: unset !important;
}

.i1Ob-q_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.i1Ob-q_highcharts-series.i1Ob-q_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.i1Ob-q_expandable {
  transition: all .5s;
}

.i1Ob-q_expandable > .i1Ob-q_expander-icon {
  cursor: pointer;
}

.i1Ob-q_expandable > .i1Ob-q_expander-icon:hover {
  color: #328cfa66;
}

.i1Ob-q_expandable.i1Ob-q_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .i1Ob-q_modal .i1Ob-q_modal-xl {
    width: 100% !important;
  }

  .i1Ob-q_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.i1Ob-q_d-none, .i1Ob-q_d-block {
  transition: all .5s;
}

.i1Ob-q_wrapper {
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.i1Ob-q_wrapper .i1Ob-q_left {
  aspect-ratio: 823 / 1037;
  height: 100vh;
  position: relative;
}

.i1Ob-q_wrapper .i1Ob-q_left .i1Ob-q_img {
  height: 100%;
  width: 100%;
  object-position: top;
}

.i1Ob-q_wrapper .i1Ob-q_left .i1Ob-q_logo {
  z-index: 3;
  position: absolute;
  top: 15%;
  left: 20%;
}

.i1Ob-q_wrapper .i1Ob-q_right {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_art2 {
  height: 30%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_art3 {
  height: 9%;
  position: absolute;
  top: 0;
  left: 0;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_art4 {
  height: 15%;
  position: absolute;
  top: 23%;
  right: 0;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_splashForm {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_content {
  width: 450px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_content h1, .i1Ob-q_wrapper .i1Ob-q_right .i1Ob-q_content .i1Ob-q_h1 {
  font-size: 32px;
  font-weight: 600;
}

.UbWM0q_login {
  min-width: 360px;
  background-color: #fff;
  border-radius: 1rem;
  flex-direction: column;
  gap: 10px;
  padding: 2rem;
  transition: all .5s ease-in-out;
  display: flex;
  position: relative;
}

.UbWM0q_login > h1 {
  color: #212121;
  font-size: 1.8rem;
  font-weight: 600;
}

.UbWM0q_login > input {
  border: 1px solid #212121;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, ._Dfzpa_h6, h5, ._Dfzpa_h5, h4, ._Dfzpa_h4, h3, ._Dfzpa_h3, h2, ._Dfzpa_h2, h1, ._Dfzpa_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, ._Dfzpa_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, ._Dfzpa_h1 {
    font-size: 2.5rem;
  }
}

h2, ._Dfzpa_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, ._Dfzpa_h2 {
    font-size: 2rem;
  }
}

h3, ._Dfzpa_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, ._Dfzpa_h3 {
    font-size: 1.75rem;
  }
}

h4, ._Dfzpa_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, ._Dfzpa_h4 {
    font-size: 1.5rem;
  }
}

h5, ._Dfzpa_h5 {
  font-size: 1.25rem;
}

h6, ._Dfzpa_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, ._Dfzpa_small {
  font-size: .875em;
}

mark, ._Dfzpa_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

._Dfzpa_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

._Dfzpa_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-1 {
    font-size: 5rem;
  }
}

._Dfzpa_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-2 {
    font-size: 4.5rem;
  }
}

._Dfzpa_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-3 {
    font-size: 4rem;
  }
}

._Dfzpa_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-4 {
    font-size: 3.5rem;
  }
}

._Dfzpa_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-5 {
    font-size: 3rem;
  }
}

._Dfzpa_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._Dfzpa_display-6 {
    font-size: 2.5rem;
  }
}

._Dfzpa_list-unstyled, ._Dfzpa_list-inline {
  padding-left: 0;
  list-style: none;
}

._Dfzpa_list-inline-item {
  display: inline-block;
}

._Dfzpa_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

._Dfzpa_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

._Dfzpa_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

._Dfzpa_blockquote > :last-child {
  margin-bottom: 0;
}

._Dfzpa_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

._Dfzpa_blockquote-footer:before {
  content: "— ";
}

._Dfzpa_img-fluid {
  max-width: 100%;
  height: auto;
}

._Dfzpa_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

._Dfzpa_figure {
  display: inline-block;
}

._Dfzpa_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

._Dfzpa_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

._Dfzpa_container, ._Dfzpa_container-fluid, ._Dfzpa_container-xxl, ._Dfzpa_container-xl, ._Dfzpa_container-lg, ._Dfzpa_container-md, ._Dfzpa_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  ._Dfzpa_container-sm, ._Dfzpa_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_container-md, ._Dfzpa_container-sm, ._Dfzpa_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_container-lg, ._Dfzpa_container-md, ._Dfzpa_container-sm, ._Dfzpa_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_container-xl, ._Dfzpa_container-lg, ._Dfzpa_container-md, ._Dfzpa_container-sm, ._Dfzpa_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_container-xxl, ._Dfzpa_container-xl, ._Dfzpa_container-lg, ._Dfzpa_container-md, ._Dfzpa_container-sm, ._Dfzpa_container {
    max-width: 1320px;
  }
}

._Dfzpa_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

._Dfzpa_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

._Dfzpa_col {
  flex: 1 0;
}

._Dfzpa_row-cols-auto > * {
  width: auto;
  flex: none;
}

._Dfzpa_row-cols-1 > * {
  width: 100%;
  flex: none;
}

._Dfzpa_row-cols-2 > * {
  width: 50%;
  flex: none;
}

._Dfzpa_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

._Dfzpa_row-cols-4 > * {
  width: 25%;
  flex: none;
}

._Dfzpa_row-cols-5 > * {
  width: 20%;
  flex: none;
}

._Dfzpa_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

._Dfzpa_col-auto {
  width: auto;
  flex: none;
}

._Dfzpa_col-1 {
  width: 8.33333%;
  flex: none;
}

._Dfzpa_col-2 {
  width: 16.6667%;
  flex: none;
}

._Dfzpa_col-3 {
  width: 25%;
  flex: none;
}

._Dfzpa_col-4 {
  width: 33.3333%;
  flex: none;
}

._Dfzpa_col-5 {
  width: 41.6667%;
  flex: none;
}

._Dfzpa_col-6 {
  width: 50%;
  flex: none;
}

._Dfzpa_col-7 {
  width: 58.3333%;
  flex: none;
}

._Dfzpa_col-8 {
  width: 66.6667%;
  flex: none;
}

._Dfzpa_col-9 {
  width: 75%;
  flex: none;
}

._Dfzpa_col-10 {
  width: 83.3333%;
  flex: none;
}

._Dfzpa_col-11 {
  width: 91.6667%;
  flex: none;
}

._Dfzpa_col-12 {
  width: 100%;
  flex: none;
}

._Dfzpa_offset-1 {
  margin-left: 8.33333%;
}

._Dfzpa_offset-2 {
  margin-left: 16.6667%;
}

._Dfzpa_offset-3 {
  margin-left: 25%;
}

._Dfzpa_offset-4 {
  margin-left: 33.3333%;
}

._Dfzpa_offset-5 {
  margin-left: 41.6667%;
}

._Dfzpa_offset-6 {
  margin-left: 50%;
}

._Dfzpa_offset-7 {
  margin-left: 58.3333%;
}

._Dfzpa_offset-8 {
  margin-left: 66.6667%;
}

._Dfzpa_offset-9 {
  margin-left: 75%;
}

._Dfzpa_offset-10 {
  margin-left: 83.3333%;
}

._Dfzpa_offset-11 {
  margin-left: 91.6667%;
}

._Dfzpa_g-0, ._Dfzpa_gx-0 {
  --bs-gutter-x: 0;
}

._Dfzpa_g-0, ._Dfzpa_gy-0 {
  --bs-gutter-y: 0;
}

._Dfzpa_g-1, ._Dfzpa_gx-1 {
  --bs-gutter-x: .25rem;
}

._Dfzpa_g-1, ._Dfzpa_gy-1 {
  --bs-gutter-y: .25rem;
}

._Dfzpa_g-2, ._Dfzpa_gx-2 {
  --bs-gutter-x: .5rem;
}

._Dfzpa_g-2, ._Dfzpa_gy-2 {
  --bs-gutter-y: .5rem;
}

._Dfzpa_g-3, ._Dfzpa_gx-3 {
  --bs-gutter-x: 1rem;
}

._Dfzpa_g-3, ._Dfzpa_gy-3 {
  --bs-gutter-y: 1rem;
}

._Dfzpa_g-4, ._Dfzpa_gx-4 {
  --bs-gutter-x: 1.5rem;
}

._Dfzpa_g-4, ._Dfzpa_gy-4 {
  --bs-gutter-y: 1.5rem;
}

._Dfzpa_g-5, ._Dfzpa_gx-5 {
  --bs-gutter-x: 3rem;
}

._Dfzpa_g-5, ._Dfzpa_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  ._Dfzpa_col-sm {
    flex: 1 0;
  }

  ._Dfzpa_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  ._Dfzpa_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-sm-auto {
    width: auto;
    flex: none;
  }

  ._Dfzpa_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  ._Dfzpa_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-sm-3 {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  ._Dfzpa_col-sm-6 {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  ._Dfzpa_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  ._Dfzpa_col-sm-9 {
    width: 75%;
    flex: none;
  }

  ._Dfzpa_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  ._Dfzpa_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  ._Dfzpa_col-sm-12 {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_offset-sm-0 {
    margin-left: 0;
  }

  ._Dfzpa_offset-sm-1 {
    margin-left: 8.33333%;
  }

  ._Dfzpa_offset-sm-2 {
    margin-left: 16.6667%;
  }

  ._Dfzpa_offset-sm-3 {
    margin-left: 25%;
  }

  ._Dfzpa_offset-sm-4 {
    margin-left: 33.3333%;
  }

  ._Dfzpa_offset-sm-5 {
    margin-left: 41.6667%;
  }

  ._Dfzpa_offset-sm-6 {
    margin-left: 50%;
  }

  ._Dfzpa_offset-sm-7 {
    margin-left: 58.3333%;
  }

  ._Dfzpa_offset-sm-8 {
    margin-left: 66.6667%;
  }

  ._Dfzpa_offset-sm-9 {
    margin-left: 75%;
  }

  ._Dfzpa_offset-sm-10 {
    margin-left: 83.3333%;
  }

  ._Dfzpa_offset-sm-11 {
    margin-left: 91.6667%;
  }

  ._Dfzpa_g-sm-0, ._Dfzpa_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  ._Dfzpa_g-sm-0, ._Dfzpa_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  ._Dfzpa_g-sm-1, ._Dfzpa_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  ._Dfzpa_g-sm-1, ._Dfzpa_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  ._Dfzpa_g-sm-2, ._Dfzpa_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  ._Dfzpa_g-sm-2, ._Dfzpa_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  ._Dfzpa_g-sm-3, ._Dfzpa_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  ._Dfzpa_g-sm-3, ._Dfzpa_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  ._Dfzpa_g-sm-4, ._Dfzpa_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._Dfzpa_g-sm-4, ._Dfzpa_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._Dfzpa_g-sm-5, ._Dfzpa_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  ._Dfzpa_g-sm-5, ._Dfzpa_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_col-md {
    flex: 1 0;
  }

  ._Dfzpa_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  ._Dfzpa_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  ._Dfzpa_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-md-auto {
    width: auto;
    flex: none;
  }

  ._Dfzpa_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  ._Dfzpa_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-md-3 {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  ._Dfzpa_col-md-6 {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  ._Dfzpa_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  ._Dfzpa_col-md-9 {
    width: 75%;
    flex: none;
  }

  ._Dfzpa_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  ._Dfzpa_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  ._Dfzpa_col-md-12 {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_offset-md-0 {
    margin-left: 0;
  }

  ._Dfzpa_offset-md-1 {
    margin-left: 8.33333%;
  }

  ._Dfzpa_offset-md-2 {
    margin-left: 16.6667%;
  }

  ._Dfzpa_offset-md-3 {
    margin-left: 25%;
  }

  ._Dfzpa_offset-md-4 {
    margin-left: 33.3333%;
  }

  ._Dfzpa_offset-md-5 {
    margin-left: 41.6667%;
  }

  ._Dfzpa_offset-md-6 {
    margin-left: 50%;
  }

  ._Dfzpa_offset-md-7 {
    margin-left: 58.3333%;
  }

  ._Dfzpa_offset-md-8 {
    margin-left: 66.6667%;
  }

  ._Dfzpa_offset-md-9 {
    margin-left: 75%;
  }

  ._Dfzpa_offset-md-10 {
    margin-left: 83.3333%;
  }

  ._Dfzpa_offset-md-11 {
    margin-left: 91.6667%;
  }

  ._Dfzpa_g-md-0, ._Dfzpa_gx-md-0 {
    --bs-gutter-x: 0;
  }

  ._Dfzpa_g-md-0, ._Dfzpa_gy-md-0 {
    --bs-gutter-y: 0;
  }

  ._Dfzpa_g-md-1, ._Dfzpa_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  ._Dfzpa_g-md-1, ._Dfzpa_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  ._Dfzpa_g-md-2, ._Dfzpa_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  ._Dfzpa_g-md-2, ._Dfzpa_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  ._Dfzpa_g-md-3, ._Dfzpa_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  ._Dfzpa_g-md-3, ._Dfzpa_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  ._Dfzpa_g-md-4, ._Dfzpa_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._Dfzpa_g-md-4, ._Dfzpa_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._Dfzpa_g-md-5, ._Dfzpa_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  ._Dfzpa_g-md-5, ._Dfzpa_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_col-lg {
    flex: 1 0;
  }

  ._Dfzpa_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  ._Dfzpa_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-lg-auto {
    width: auto;
    flex: none;
  }

  ._Dfzpa_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  ._Dfzpa_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-lg-3 {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  ._Dfzpa_col-lg-6 {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  ._Dfzpa_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  ._Dfzpa_col-lg-9 {
    width: 75%;
    flex: none;
  }

  ._Dfzpa_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  ._Dfzpa_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  ._Dfzpa_col-lg-12 {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_offset-lg-0 {
    margin-left: 0;
  }

  ._Dfzpa_offset-lg-1 {
    margin-left: 8.33333%;
  }

  ._Dfzpa_offset-lg-2 {
    margin-left: 16.6667%;
  }

  ._Dfzpa_offset-lg-3 {
    margin-left: 25%;
  }

  ._Dfzpa_offset-lg-4 {
    margin-left: 33.3333%;
  }

  ._Dfzpa_offset-lg-5 {
    margin-left: 41.6667%;
  }

  ._Dfzpa_offset-lg-6 {
    margin-left: 50%;
  }

  ._Dfzpa_offset-lg-7 {
    margin-left: 58.3333%;
  }

  ._Dfzpa_offset-lg-8 {
    margin-left: 66.6667%;
  }

  ._Dfzpa_offset-lg-9 {
    margin-left: 75%;
  }

  ._Dfzpa_offset-lg-10 {
    margin-left: 83.3333%;
  }

  ._Dfzpa_offset-lg-11 {
    margin-left: 91.6667%;
  }

  ._Dfzpa_g-lg-0, ._Dfzpa_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  ._Dfzpa_g-lg-0, ._Dfzpa_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  ._Dfzpa_g-lg-1, ._Dfzpa_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  ._Dfzpa_g-lg-1, ._Dfzpa_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  ._Dfzpa_g-lg-2, ._Dfzpa_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  ._Dfzpa_g-lg-2, ._Dfzpa_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  ._Dfzpa_g-lg-3, ._Dfzpa_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  ._Dfzpa_g-lg-3, ._Dfzpa_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  ._Dfzpa_g-lg-4, ._Dfzpa_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._Dfzpa_g-lg-4, ._Dfzpa_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._Dfzpa_g-lg-5, ._Dfzpa_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  ._Dfzpa_g-lg-5, ._Dfzpa_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_col-xl {
    flex: 1 0;
  }

  ._Dfzpa_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  ._Dfzpa_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-xl-auto {
    width: auto;
    flex: none;
  }

  ._Dfzpa_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._Dfzpa_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-xl-3 {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._Dfzpa_col-xl-6 {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._Dfzpa_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._Dfzpa_col-xl-9 {
    width: 75%;
    flex: none;
  }

  ._Dfzpa_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._Dfzpa_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._Dfzpa_col-xl-12 {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_offset-xl-0 {
    margin-left: 0;
  }

  ._Dfzpa_offset-xl-1 {
    margin-left: 8.33333%;
  }

  ._Dfzpa_offset-xl-2 {
    margin-left: 16.6667%;
  }

  ._Dfzpa_offset-xl-3 {
    margin-left: 25%;
  }

  ._Dfzpa_offset-xl-4 {
    margin-left: 33.3333%;
  }

  ._Dfzpa_offset-xl-5 {
    margin-left: 41.6667%;
  }

  ._Dfzpa_offset-xl-6 {
    margin-left: 50%;
  }

  ._Dfzpa_offset-xl-7 {
    margin-left: 58.3333%;
  }

  ._Dfzpa_offset-xl-8 {
    margin-left: 66.6667%;
  }

  ._Dfzpa_offset-xl-9 {
    margin-left: 75%;
  }

  ._Dfzpa_offset-xl-10 {
    margin-left: 83.3333%;
  }

  ._Dfzpa_offset-xl-11 {
    margin-left: 91.6667%;
  }

  ._Dfzpa_g-xl-0, ._Dfzpa_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  ._Dfzpa_g-xl-0, ._Dfzpa_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  ._Dfzpa_g-xl-1, ._Dfzpa_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  ._Dfzpa_g-xl-1, ._Dfzpa_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  ._Dfzpa_g-xl-2, ._Dfzpa_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  ._Dfzpa_g-xl-2, ._Dfzpa_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  ._Dfzpa_g-xl-3, ._Dfzpa_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  ._Dfzpa_g-xl-3, ._Dfzpa_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  ._Dfzpa_g-xl-4, ._Dfzpa_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._Dfzpa_g-xl-4, ._Dfzpa_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._Dfzpa_g-xl-5, ._Dfzpa_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  ._Dfzpa_g-xl-5, ._Dfzpa_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_col-xxl {
    flex: 1 0;
  }

  ._Dfzpa_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  ._Dfzpa_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-xxl-auto {
    width: auto;
    flex: none;
  }

  ._Dfzpa_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._Dfzpa_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._Dfzpa_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  ._Dfzpa_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._Dfzpa_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._Dfzpa_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  ._Dfzpa_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._Dfzpa_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._Dfzpa_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  ._Dfzpa_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._Dfzpa_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._Dfzpa_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  ._Dfzpa_offset-xxl-0 {
    margin-left: 0;
  }

  ._Dfzpa_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  ._Dfzpa_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  ._Dfzpa_offset-xxl-3 {
    margin-left: 25%;
  }

  ._Dfzpa_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  ._Dfzpa_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  ._Dfzpa_offset-xxl-6 {
    margin-left: 50%;
  }

  ._Dfzpa_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  ._Dfzpa_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  ._Dfzpa_offset-xxl-9 {
    margin-left: 75%;
  }

  ._Dfzpa_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  ._Dfzpa_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  ._Dfzpa_g-xxl-0, ._Dfzpa_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  ._Dfzpa_g-xxl-0, ._Dfzpa_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  ._Dfzpa_g-xxl-1, ._Dfzpa_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  ._Dfzpa_g-xxl-1, ._Dfzpa_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  ._Dfzpa_g-xxl-2, ._Dfzpa_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  ._Dfzpa_g-xxl-2, ._Dfzpa_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  ._Dfzpa_g-xxl-3, ._Dfzpa_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  ._Dfzpa_g-xxl-3, ._Dfzpa_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  ._Dfzpa_g-xxl-4, ._Dfzpa_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._Dfzpa_g-xxl-4, ._Dfzpa_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._Dfzpa_g-xxl-5, ._Dfzpa_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  ._Dfzpa_g-xxl-5, ._Dfzpa_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

._Dfzpa_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

._Dfzpa_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

._Dfzpa_table > tbody {
  vertical-align: inherit;
}

._Dfzpa_table > thead {
  vertical-align: bottom;
}

._Dfzpa_table-group-divider {
  border-top: 2px solid;
}

._Dfzpa_caption-top {
  caption-side: top;
}

._Dfzpa_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

._Dfzpa_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

._Dfzpa_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

._Dfzpa_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

._Dfzpa_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

._Dfzpa_table-striped > tbody > tr:nth-of-type(2n+1) > *, ._Dfzpa_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

._Dfzpa_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

._Dfzpa_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

._Dfzpa_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._Dfzpa_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  ._Dfzpa_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  ._Dfzpa_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  ._Dfzpa_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  ._Dfzpa_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  ._Dfzpa_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

._Dfzpa_form-label {
  margin-bottom: .5rem;
}

._Dfzpa_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

._Dfzpa_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

._Dfzpa_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

._Dfzpa_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

._Dfzpa_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-control {
    transition: none;
  }
}

._Dfzpa_form-control[type="file"] {
  overflow: hidden;
}

._Dfzpa_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._Dfzpa_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._Dfzpa_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

._Dfzpa_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

._Dfzpa_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

._Dfzpa_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-control::file-selector-button {
    transition: none;
  }
}

._Dfzpa_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

._Dfzpa_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

._Dfzpa_form-control-plaintext:focus {
  outline: 0;
}

._Dfzpa_form-control-plaintext._Dfzpa_form-control-sm, ._Dfzpa_form-control-plaintext._Dfzpa_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

._Dfzpa_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._Dfzpa_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

._Dfzpa_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._Dfzpa_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea._Dfzpa_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea._Dfzpa_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea._Dfzpa_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

._Dfzpa_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

._Dfzpa_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._Dfzpa_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

._Dfzpa_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

._Dfzpa_form-control-color._Dfzpa_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

._Dfzpa_form-control-color._Dfzpa_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

._Dfzpa_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-select {
    transition: none;
  }
}

._Dfzpa_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._Dfzpa_form-select[multiple], ._Dfzpa_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

._Dfzpa_form-select:disabled {
  background-color: #e9ecef;
}

._Dfzpa_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

._Dfzpa_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

._Dfzpa_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

._Dfzpa_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

._Dfzpa_form-check ._Dfzpa_form-check-input {
  float: left;
  margin-left: -1.5em;
}

._Dfzpa_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

._Dfzpa_form-check-reverse ._Dfzpa_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

._Dfzpa_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

._Dfzpa_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

._Dfzpa_form-check-input[type="radio"] {
  border-radius: 50%;
}

._Dfzpa_form-check-input:active {
  filter: brightness(90%);
}

._Dfzpa_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._Dfzpa_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

._Dfzpa_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

._Dfzpa_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

._Dfzpa_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

._Dfzpa_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

._Dfzpa_form-check-input[disabled] ~ ._Dfzpa_form-check-label, ._Dfzpa_form-check-input:disabled ~ ._Dfzpa_form-check-label {
  cursor: default;
  opacity: .5;
}

._Dfzpa_form-switch {
  padding-left: 2.5em;
}

._Dfzpa_form-switch ._Dfzpa_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-switch ._Dfzpa_form-check-input {
    transition: none;
  }
}

._Dfzpa_form-switch ._Dfzpa_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

._Dfzpa_form-switch ._Dfzpa_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

._Dfzpa_form-switch._Dfzpa_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

._Dfzpa_form-switch._Dfzpa_form-check-reverse ._Dfzpa_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

._Dfzpa_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

._Dfzpa_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

._Dfzpa_btn-check[disabled] + ._Dfzpa_btn, ._Dfzpa_btn-check:disabled + ._Dfzpa_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

._Dfzpa_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

._Dfzpa_form-range:focus {
  outline: 0;
}

._Dfzpa_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

._Dfzpa_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

._Dfzpa_form-range::-moz-focus-outer {
  border: 0;
}

._Dfzpa_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

._Dfzpa_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

._Dfzpa_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._Dfzpa_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-range::-moz-range-thumb {
    transition: none;
  }
}

._Dfzpa_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

._Dfzpa_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._Dfzpa_form-range:disabled {
  pointer-events: none;
}

._Dfzpa_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

._Dfzpa_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

._Dfzpa_form-floating {
  position: relative;
}

._Dfzpa_form-floating > ._Dfzpa_form-control, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext, ._Dfzpa_form-floating > ._Dfzpa_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

._Dfzpa_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_form-floating > label {
    transition: none;
  }
}

._Dfzpa_form-floating > ._Dfzpa_form-control, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext {
  padding: 1rem .75rem;
}

._Dfzpa_form-floating > ._Dfzpa_form-control::placeholder, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext::placeholder {
  color: #0000;
}

._Dfzpa_form-floating > ._Dfzpa_form-control:focus, ._Dfzpa_form-floating > ._Dfzpa_form-control:not(:placeholder-shown), ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext:focus, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._Dfzpa_form-floating > ._Dfzpa_form-control:-webkit-autofill, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._Dfzpa_form-floating > ._Dfzpa_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._Dfzpa_form-floating > ._Dfzpa_form-control:focus ~ label, ._Dfzpa_form-floating > ._Dfzpa_form-control:not(:placeholder-shown) ~ label, ._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext ~ label, ._Dfzpa_form-floating > ._Dfzpa_form-select ~ label, ._Dfzpa_form-floating > ._Dfzpa_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

._Dfzpa_form-floating > ._Dfzpa_form-control-plaintext ~ label {
  border-width: 1px 0;
}

._Dfzpa_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

._Dfzpa_input-group > ._Dfzpa_form-control, ._Dfzpa_input-group > ._Dfzpa_form-select, ._Dfzpa_input-group > ._Dfzpa_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

._Dfzpa_input-group > ._Dfzpa_form-control:focus, ._Dfzpa_input-group > ._Dfzpa_form-select:focus, ._Dfzpa_input-group > ._Dfzpa_form-floating:focus-within {
  z-index: 5;
}

._Dfzpa_input-group ._Dfzpa_btn {
  z-index: 2;
  position: relative;
}

._Dfzpa_input-group ._Dfzpa_btn:focus {
  z-index: 5;
}

._Dfzpa_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

._Dfzpa_input-group-lg > ._Dfzpa_form-control, ._Dfzpa_input-group-lg > ._Dfzpa_form-select, ._Dfzpa_input-group-lg > ._Dfzpa_input-group-text, ._Dfzpa_input-group-lg > ._Dfzpa_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._Dfzpa_input-group-sm > ._Dfzpa_form-control, ._Dfzpa_input-group-sm > ._Dfzpa_form-select, ._Dfzpa_input-group-sm > ._Dfzpa_input-group-text, ._Dfzpa_input-group-sm > ._Dfzpa_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._Dfzpa_input-group-lg > ._Dfzpa_form-select, ._Dfzpa_input-group-sm > ._Dfzpa_form-select {
  padding-right: 3rem;
}

._Dfzpa_input-group:not(._Dfzpa_has-validation) > :not(:last-child):not(._Dfzpa_dropdown-toggle):not(._Dfzpa_dropdown-menu):not(._Dfzpa_form-floating), ._Dfzpa_input-group:not(._Dfzpa_has-validation) > ._Dfzpa_dropdown-toggle:nth-last-child(n+3), ._Dfzpa_input-group:not(._Dfzpa_has-validation) > ._Dfzpa_form-floating:not(:last-child) > ._Dfzpa_form-control, ._Dfzpa_input-group:not(._Dfzpa_has-validation) > ._Dfzpa_form-floating:not(:last-child) > ._Dfzpa_form-select, ._Dfzpa_input-group._Dfzpa_has-validation > :nth-last-child(n+3):not(._Dfzpa_dropdown-toggle):not(._Dfzpa_dropdown-menu):not(._Dfzpa_form-floating), ._Dfzpa_input-group._Dfzpa_has-validation > ._Dfzpa_dropdown-toggle:nth-last-child(n+4), ._Dfzpa_input-group._Dfzpa_has-validation > ._Dfzpa_form-floating:nth-last-child(n+3) > ._Dfzpa_form-control, ._Dfzpa_input-group._Dfzpa_has-validation > ._Dfzpa_form-floating:nth-last-child(n+3) > ._Dfzpa_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._Dfzpa_input-group > :not(:first-child):not(._Dfzpa_dropdown-menu):not(._Dfzpa_valid-tooltip):not(._Dfzpa_valid-feedback):not(._Dfzpa_invalid-tooltip):not(._Dfzpa_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

._Dfzpa_input-group > ._Dfzpa_form-floating:not(:first-child) > ._Dfzpa_form-control, ._Dfzpa_input-group > ._Dfzpa_form-floating:not(:first-child) > ._Dfzpa_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._Dfzpa_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._Dfzpa_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._Dfzpa_was-validated :valid ~ ._Dfzpa_valid-feedback, ._Dfzpa_was-validated :valid ~ ._Dfzpa_valid-tooltip, ._Dfzpa_is-valid ~ ._Dfzpa_valid-feedback, ._Dfzpa_is-valid ~ ._Dfzpa_valid-tooltip {
  display: block;
}

._Dfzpa_was-validated ._Dfzpa_form-control:valid, ._Dfzpa_form-control._Dfzpa_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-control:valid:focus, ._Dfzpa_form-control._Dfzpa_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._Dfzpa_was-validated textarea._Dfzpa_form-control:valid, textarea._Dfzpa_form-control._Dfzpa_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-select:valid, ._Dfzpa_form-select._Dfzpa_is-valid {
  border-color: #198754;
}

._Dfzpa_was-validated ._Dfzpa_form-select:valid:not([multiple]):not([size]), ._Dfzpa_was-validated ._Dfzpa_form-select:valid:not([multiple])[size="1"], ._Dfzpa_form-select._Dfzpa_is-valid:not([multiple]):not([size]), ._Dfzpa_form-select._Dfzpa_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._Dfzpa_was-validated ._Dfzpa_form-select:valid:focus, ._Dfzpa_form-select._Dfzpa_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._Dfzpa_was-validated ._Dfzpa_form-control-color:valid, ._Dfzpa_form-control-color._Dfzpa_is-valid {
  width: calc(1.5em + 3.75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:valid, ._Dfzpa_form-check-input._Dfzpa_is-valid {
  border-color: #198754;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:valid:checked, ._Dfzpa_form-check-input._Dfzpa_is-valid:checked {
  background-color: #198754;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:valid:focus, ._Dfzpa_form-check-input._Dfzpa_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:valid ~ ._Dfzpa_form-check-label, ._Dfzpa_form-check-input._Dfzpa_is-valid ~ ._Dfzpa_form-check-label {
  color: #198754;
}

._Dfzpa_form-check-inline ._Dfzpa_form-check-input ~ ._Dfzpa_valid-feedback {
  margin-left: .5em;
}

._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-control:not(:focus):valid, ._Dfzpa_input-group > ._Dfzpa_form-control:not(:focus)._Dfzpa_is-valid, ._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-select:not(:focus):valid, ._Dfzpa_input-group > ._Dfzpa_form-select:not(:focus)._Dfzpa_is-valid, ._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-floating:not(:focus-within):valid, ._Dfzpa_input-group > ._Dfzpa_form-floating:not(:focus-within)._Dfzpa_is-valid {
  z-index: 3;
}

._Dfzpa_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._Dfzpa_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._Dfzpa_was-validated :invalid ~ ._Dfzpa_invalid-feedback, ._Dfzpa_was-validated :invalid ~ ._Dfzpa_invalid-tooltip, ._Dfzpa_is-invalid ~ ._Dfzpa_invalid-feedback, ._Dfzpa_is-invalid ~ ._Dfzpa_invalid-tooltip {
  display: block;
}

._Dfzpa_was-validated ._Dfzpa_form-control:invalid, ._Dfzpa_form-control._Dfzpa_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-control:invalid:focus, ._Dfzpa_form-control._Dfzpa_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._Dfzpa_was-validated textarea._Dfzpa_form-control:invalid, textarea._Dfzpa_form-control._Dfzpa_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-select:invalid, ._Dfzpa_form-select._Dfzpa_is-invalid {
  border-color: #dc3545;
}

._Dfzpa_was-validated ._Dfzpa_form-select:invalid:not([multiple]):not([size]), ._Dfzpa_was-validated ._Dfzpa_form-select:invalid:not([multiple])[size="1"], ._Dfzpa_form-select._Dfzpa_is-invalid:not([multiple]):not([size]), ._Dfzpa_form-select._Dfzpa_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._Dfzpa_was-validated ._Dfzpa_form-select:invalid:focus, ._Dfzpa_form-select._Dfzpa_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._Dfzpa_was-validated ._Dfzpa_form-control-color:invalid, ._Dfzpa_form-control-color._Dfzpa_is-invalid {
  width: calc(1.5em + 3.75rem);
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:invalid, ._Dfzpa_form-check-input._Dfzpa_is-invalid {
  border-color: #dc3545;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:invalid:checked, ._Dfzpa_form-check-input._Dfzpa_is-invalid:checked {
  background-color: #dc3545;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:invalid:focus, ._Dfzpa_form-check-input._Dfzpa_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

._Dfzpa_was-validated ._Dfzpa_form-check-input:invalid ~ ._Dfzpa_form-check-label, ._Dfzpa_form-check-input._Dfzpa_is-invalid ~ ._Dfzpa_form-check-label {
  color: #dc3545;
}

._Dfzpa_form-check-inline ._Dfzpa_form-check-input ~ ._Dfzpa_invalid-feedback {
  margin-left: .5em;
}

._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-control:not(:focus):invalid, ._Dfzpa_input-group > ._Dfzpa_form-control:not(:focus)._Dfzpa_is-invalid, ._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-select:not(:focus):invalid, ._Dfzpa_input-group > ._Dfzpa_form-select:not(:focus)._Dfzpa_is-invalid, ._Dfzpa_was-validated ._Dfzpa_input-group > ._Dfzpa_form-floating:not(:focus-within):invalid, ._Dfzpa_input-group > ._Dfzpa_form-floating:not(:focus-within)._Dfzpa_is-invalid {
  z-index: 4;
}

._Dfzpa_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_btn {
    transition: none;
  }
}

._Dfzpa_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

._Dfzpa_btn-check + ._Dfzpa_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

._Dfzpa_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._Dfzpa_btn-check:focus-visible + ._Dfzpa_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._Dfzpa_btn-check:checked + ._Dfzpa_btn, :not(._Dfzpa_btn-check) + ._Dfzpa_btn:active, ._Dfzpa_btn:first-child:active, ._Dfzpa_btn._Dfzpa_active, ._Dfzpa_btn._Dfzpa_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

._Dfzpa_btn-check:checked + ._Dfzpa_btn:focus-visible, :not(._Dfzpa_btn-check) + ._Dfzpa_btn:active:focus-visible, ._Dfzpa_btn:first-child:active:focus-visible, ._Dfzpa_btn._Dfzpa_active:focus-visible, ._Dfzpa_btn._Dfzpa_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

._Dfzpa_btn:disabled, ._Dfzpa_btn._Dfzpa_disabled, fieldset:disabled ._Dfzpa_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

._Dfzpa_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

._Dfzpa_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

._Dfzpa_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

._Dfzpa_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

._Dfzpa_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

._Dfzpa_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

._Dfzpa_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

._Dfzpa_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

._Dfzpa_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

._Dfzpa_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

._Dfzpa_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

._Dfzpa_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

._Dfzpa_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

._Dfzpa_btn-lg, ._Dfzpa_btn-group-lg > ._Dfzpa_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

._Dfzpa_btn-sm, ._Dfzpa_btn-group-sm > ._Dfzpa_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

._Dfzpa_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_fade {
    transition: none;
  }
}

._Dfzpa_fade:not(._Dfzpa_show) {
  opacity: 0;
}

._Dfzpa_collapse:not(._Dfzpa_show) {
  display: none;
}

._Dfzpa_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_collapsing {
    transition: none;
  }
}

._Dfzpa_collapsing._Dfzpa_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_collapsing._Dfzpa_collapse-horizontal {
    transition: none;
  }
}

._Dfzpa_dropup, ._Dfzpa_dropend, ._Dfzpa_dropdown, ._Dfzpa_dropstart, ._Dfzpa_dropup-center, ._Dfzpa_dropdown-center {
  position: relative;
}

._Dfzpa_dropdown-toggle {
  white-space: nowrap;
}

._Dfzpa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

._Dfzpa_dropdown-toggle:empty:after {
  margin-left: 0;
}

._Dfzpa_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

._Dfzpa_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

._Dfzpa_dropdown-menu-start {
  --bs-position: start;
}

._Dfzpa_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

._Dfzpa_dropdown-menu-end {
  --bs-position: end;
}

._Dfzpa_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  ._Dfzpa_dropdown-menu-sm-start {
    --bs-position: start;
  }

  ._Dfzpa_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._Dfzpa_dropdown-menu-sm-end {
    --bs-position: end;
  }

  ._Dfzpa_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_dropdown-menu-md-start {
    --bs-position: start;
  }

  ._Dfzpa_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._Dfzpa_dropdown-menu-md-end {
    --bs-position: end;
  }

  ._Dfzpa_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_dropdown-menu-lg-start {
    --bs-position: start;
  }

  ._Dfzpa_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._Dfzpa_dropdown-menu-lg-end {
    --bs-position: end;
  }

  ._Dfzpa_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_dropdown-menu-xl-start {
    --bs-position: start;
  }

  ._Dfzpa_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._Dfzpa_dropdown-menu-xl-end {
    --bs-position: end;
  }

  ._Dfzpa_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  ._Dfzpa_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._Dfzpa_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  ._Dfzpa_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

._Dfzpa_dropup ._Dfzpa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

._Dfzpa_dropup ._Dfzpa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

._Dfzpa_dropup ._Dfzpa_dropdown-toggle:empty:after {
  margin-left: 0;
}

._Dfzpa_dropend ._Dfzpa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

._Dfzpa_dropend ._Dfzpa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

._Dfzpa_dropend ._Dfzpa_dropdown-toggle:empty:after {
  margin-left: 0;
}

._Dfzpa_dropend ._Dfzpa_dropdown-toggle:after {
  vertical-align: 0;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-toggle:empty:after {
  margin-left: 0;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-toggle:before {
  vertical-align: 0;
}

._Dfzpa_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

._Dfzpa_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

._Dfzpa_dropdown-item:hover, ._Dfzpa_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

._Dfzpa_dropdown-item._Dfzpa_active, ._Dfzpa_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

._Dfzpa_dropdown-item._Dfzpa_disabled, ._Dfzpa_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

._Dfzpa_dropdown-menu._Dfzpa_show {
  display: block;
}

._Dfzpa_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

._Dfzpa_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

._Dfzpa_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

._Dfzpa_btn-group, ._Dfzpa_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

._Dfzpa_btn-group > ._Dfzpa_btn, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn {
  flex: auto;
  position: relative;
}

._Dfzpa_btn-group > ._Dfzpa_btn-check:checked + ._Dfzpa_btn, ._Dfzpa_btn-group > ._Dfzpa_btn-check:focus + ._Dfzpa_btn, ._Dfzpa_btn-group > ._Dfzpa_btn:hover, ._Dfzpa_btn-group > ._Dfzpa_btn:focus, ._Dfzpa_btn-group > ._Dfzpa_btn:active, ._Dfzpa_btn-group > ._Dfzpa_btn._Dfzpa_active, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-check:checked + ._Dfzpa_btn, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-check:focus + ._Dfzpa_btn, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn:hover, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn:focus, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn:active, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn._Dfzpa_active {
  z-index: 1;
}

._Dfzpa_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

._Dfzpa_btn-toolbar ._Dfzpa_input-group {
  width: auto;
}

._Dfzpa_btn-group {
  border-radius: .375rem;
}

._Dfzpa_btn-group > :not(._Dfzpa_btn-check:first-child) + ._Dfzpa_btn, ._Dfzpa_btn-group > ._Dfzpa_btn-group:not(:first-child) {
  margin-left: -1px;
}

._Dfzpa_btn-group > ._Dfzpa_btn:not(:last-child):not(._Dfzpa_dropdown-toggle), ._Dfzpa_btn-group > ._Dfzpa_btn._Dfzpa_dropdown-toggle-split:first-child, ._Dfzpa_btn-group > ._Dfzpa_btn-group:not(:last-child) > ._Dfzpa_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._Dfzpa_btn-group > ._Dfzpa_btn:nth-child(n+3), ._Dfzpa_btn-group > :not(._Dfzpa_btn-check) + ._Dfzpa_btn, ._Dfzpa_btn-group > ._Dfzpa_btn-group:not(:first-child) > ._Dfzpa_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._Dfzpa_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

._Dfzpa_dropdown-toggle-split:after, ._Dfzpa_dropup ._Dfzpa_dropdown-toggle-split:after, ._Dfzpa_dropend ._Dfzpa_dropdown-toggle-split:after {
  margin-left: 0;
}

._Dfzpa_dropstart ._Dfzpa_dropdown-toggle-split:before {
  margin-right: 0;
}

._Dfzpa_btn-sm + ._Dfzpa_dropdown-toggle-split, ._Dfzpa_btn-group-sm > ._Dfzpa_btn + ._Dfzpa_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

._Dfzpa_btn-lg + ._Dfzpa_dropdown-toggle-split, ._Dfzpa_btn-group-lg > ._Dfzpa_btn + ._Dfzpa_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

._Dfzpa_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

._Dfzpa_btn-group-vertical > ._Dfzpa_btn, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-group {
  width: 100%;
}

._Dfzpa_btn-group-vertical > ._Dfzpa_btn:not(:first-child), ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-group:not(:first-child) {
  margin-top: -1px;
}

._Dfzpa_btn-group-vertical > ._Dfzpa_btn:not(:last-child):not(._Dfzpa_dropdown-toggle), ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-group:not(:last-child) > ._Dfzpa_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

._Dfzpa_btn-group-vertical > ._Dfzpa_btn ~ ._Dfzpa_btn, ._Dfzpa_btn-group-vertical > ._Dfzpa_btn-group:not(:first-child) > ._Dfzpa_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._Dfzpa_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._Dfzpa_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_nav-link {
    transition: none;
  }
}

._Dfzpa_nav-link:hover, ._Dfzpa_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

._Dfzpa_nav-link._Dfzpa_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

._Dfzpa_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

._Dfzpa_nav-tabs ._Dfzpa_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

._Dfzpa_nav-tabs ._Dfzpa_nav-link:hover, ._Dfzpa_nav-tabs ._Dfzpa_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

._Dfzpa_nav-tabs ._Dfzpa_nav-link._Dfzpa_disabled, ._Dfzpa_nav-tabs ._Dfzpa_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._Dfzpa_nav-tabs ._Dfzpa_nav-link._Dfzpa_active, ._Dfzpa_nav-tabs ._Dfzpa_nav-item._Dfzpa_show ._Dfzpa_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

._Dfzpa_nav-tabs ._Dfzpa_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._Dfzpa_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

._Dfzpa_nav-pills ._Dfzpa_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

._Dfzpa_nav-pills ._Dfzpa_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._Dfzpa_nav-pills ._Dfzpa_nav-link._Dfzpa_active, ._Dfzpa_nav-pills ._Dfzpa_show > ._Dfzpa_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

._Dfzpa_nav-fill > ._Dfzpa_nav-link, ._Dfzpa_nav-fill ._Dfzpa_nav-item {
  text-align: center;
  flex: auto;
}

._Dfzpa_nav-justified > ._Dfzpa_nav-link, ._Dfzpa_nav-justified ._Dfzpa_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

._Dfzpa_nav-fill ._Dfzpa_nav-item ._Dfzpa_nav-link, ._Dfzpa_nav-justified ._Dfzpa_nav-item ._Dfzpa_nav-link {
  width: 100%;
}

._Dfzpa_tab-content > ._Dfzpa_tab-pane {
  display: none;
}

._Dfzpa_tab-content > ._Dfzpa_active {
  display: block;
}

._Dfzpa_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

._Dfzpa_navbar > ._Dfzpa_container, ._Dfzpa_navbar > ._Dfzpa_container-fluid, ._Dfzpa_navbar > ._Dfzpa_container-sm, ._Dfzpa_navbar > ._Dfzpa_container-md, ._Dfzpa_navbar > ._Dfzpa_container-lg, ._Dfzpa_navbar > ._Dfzpa_container-xl, ._Dfzpa_navbar > ._Dfzpa_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._Dfzpa_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

._Dfzpa_navbar-brand:hover, ._Dfzpa_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

._Dfzpa_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._Dfzpa_navbar-nav ._Dfzpa_show > ._Dfzpa_nav-link, ._Dfzpa_navbar-nav ._Dfzpa_nav-link._Dfzpa_active {
  color: var(--bs-navbar-active-color);
}

._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
  position: static;
}

._Dfzpa_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

._Dfzpa_navbar-text a, ._Dfzpa_navbar-text a:hover, ._Dfzpa_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

._Dfzpa_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

._Dfzpa_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_navbar-toggler {
    transition: none;
  }
}

._Dfzpa_navbar-toggler:hover {
  text-decoration: none;
}

._Dfzpa_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

._Dfzpa_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

._Dfzpa_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  ._Dfzpa_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-nav {
    flex-direction: row;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
    position: absolute;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-nav-scroll {
    overflow: visible;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_navbar-toggler {
    display: none;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_navbar-expand-sm ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-nav {
    flex-direction: row;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
    position: absolute;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-nav-scroll {
    overflow: visible;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_navbar-toggler {
    display: none;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_navbar-expand-md ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-nav {
    flex-direction: row;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
    position: absolute;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-nav-scroll {
    overflow: visible;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_navbar-toggler {
    display: none;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_navbar-expand-lg ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-nav {
    flex-direction: row;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
    position: absolute;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-nav-scroll {
    overflow: visible;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_navbar-toggler {
    display: none;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_navbar-expand-xl ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-nav {
    flex-direction: row;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
    position: absolute;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-nav-scroll {
    overflow: visible;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_navbar-toggler {
    display: none;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_navbar-expand-xxl ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

._Dfzpa_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-nav {
  flex-direction: row;
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-nav ._Dfzpa_dropdown-menu {
  position: absolute;
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-nav ._Dfzpa_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-nav-scroll {
  overflow: visible;
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

._Dfzpa_navbar-expand ._Dfzpa_navbar-toggler {
  display: none;
}

._Dfzpa_navbar-expand ._Dfzpa_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

._Dfzpa_navbar-expand ._Dfzpa_offcanvas ._Dfzpa_offcanvas-header {
  display: none;
}

._Dfzpa_navbar-expand ._Dfzpa_offcanvas ._Dfzpa_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

._Dfzpa_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

._Dfzpa_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

._Dfzpa_card > hr {
  margin-left: 0;
  margin-right: 0;
}

._Dfzpa_card > ._Dfzpa_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

._Dfzpa_card > ._Dfzpa_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

._Dfzpa_card > ._Dfzpa_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

._Dfzpa_card > ._Dfzpa_card-header + ._Dfzpa_list-group, ._Dfzpa_card > ._Dfzpa_list-group + ._Dfzpa_card-footer {
  border-top: 0;
}

._Dfzpa_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

._Dfzpa_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

._Dfzpa_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

._Dfzpa_card-text:last-child {
  margin-bottom: 0;
}

._Dfzpa_card-link + ._Dfzpa_card-link {
  margin-left: var(--bs-card-spacer-x);
}

._Dfzpa_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

._Dfzpa_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

._Dfzpa_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

._Dfzpa_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

._Dfzpa_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

._Dfzpa_card-header-tabs ._Dfzpa_nav-link._Dfzpa_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

._Dfzpa_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

._Dfzpa_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

._Dfzpa_card-img, ._Dfzpa_card-img-top, ._Dfzpa_card-img-bottom {
  width: 100%;
}

._Dfzpa_card-img, ._Dfzpa_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

._Dfzpa_card-img, ._Dfzpa_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

._Dfzpa_card-group > ._Dfzpa_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  ._Dfzpa_card-group {
    flex-flow: wrap;
    display: flex;
  }

  ._Dfzpa_card-group > ._Dfzpa_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card + ._Dfzpa_card {
    border-left: 0;
    margin-left: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:last-child) ._Dfzpa_card-img-top, ._Dfzpa_card-group > ._Dfzpa_card:not(:last-child) ._Dfzpa_card-header {
    border-top-right-radius: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:last-child) ._Dfzpa_card-img-bottom, ._Dfzpa_card-group > ._Dfzpa_card:not(:last-child) ._Dfzpa_card-footer {
    border-bottom-right-radius: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:first-child) ._Dfzpa_card-img-top, ._Dfzpa_card-group > ._Dfzpa_card:not(:first-child) ._Dfzpa_card-header {
    border-top-left-radius: 0;
  }

  ._Dfzpa_card-group > ._Dfzpa_card:not(:first-child) ._Dfzpa_card-img-bottom, ._Dfzpa_card-group > ._Dfzpa_card:not(:first-child) ._Dfzpa_card-footer {
    border-bottom-left-radius: 0;
  }
}

._Dfzpa_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

._Dfzpa_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_accordion-button {
    transition: none;
  }
}

._Dfzpa_accordion-button:not(._Dfzpa_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

._Dfzpa_accordion-button:not(._Dfzpa_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

._Dfzpa_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_accordion-button:after {
    transition: none;
  }
}

._Dfzpa_accordion-button:hover {
  z-index: 2;
}

._Dfzpa_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

._Dfzpa_accordion-header {
  margin-bottom: 0;
}

._Dfzpa_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

._Dfzpa_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

._Dfzpa_accordion-item:first-of-type ._Dfzpa_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

._Dfzpa_accordion-item:not(:first-of-type) {
  border-top: 0;
}

._Dfzpa_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._Dfzpa_accordion-item:last-of-type ._Dfzpa_accordion-button._Dfzpa_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

._Dfzpa_accordion-item:last-of-type ._Dfzpa_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._Dfzpa_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

._Dfzpa_accordion-flush ._Dfzpa_accordion-collapse {
  border-width: 0;
}

._Dfzpa_accordion-flush ._Dfzpa_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

._Dfzpa_accordion-flush ._Dfzpa_accordion-item:first-child {
  border-top: 0;
}

._Dfzpa_accordion-flush ._Dfzpa_accordion-item:last-child {
  border-bottom: 0;
}

._Dfzpa_accordion-flush ._Dfzpa_accordion-item ._Dfzpa_accordion-button, ._Dfzpa_accordion-flush ._Dfzpa_accordion-item ._Dfzpa_accordion-button._Dfzpa_collapsed {
  border-radius: 0;
}

._Dfzpa_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

._Dfzpa_breadcrumb-item + ._Dfzpa_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

._Dfzpa_breadcrumb-item + ._Dfzpa_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

._Dfzpa_breadcrumb-item._Dfzpa_active {
  color: var(--bs-breadcrumb-item-active-color);
}

._Dfzpa_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._Dfzpa_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_page-link {
    transition: none;
  }
}

._Dfzpa_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

._Dfzpa_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

._Dfzpa_page-link._Dfzpa_active, ._Dfzpa_active > ._Dfzpa_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

._Dfzpa_page-link._Dfzpa_disabled, ._Dfzpa_disabled > ._Dfzpa_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

._Dfzpa_page-item:not(:first-child) ._Dfzpa_page-link {
  margin-left: -1px;
}

._Dfzpa_page-item:first-child ._Dfzpa_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

._Dfzpa_page-item:last-child ._Dfzpa_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

._Dfzpa_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

._Dfzpa_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

._Dfzpa_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

._Dfzpa_badge:empty {
  display: none;
}

._Dfzpa_btn ._Dfzpa_badge {
  position: relative;
  top: -1px;
}

._Dfzpa_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

._Dfzpa_alert-heading {
  color: inherit;
}

._Dfzpa_alert-link {
  font-weight: 700;
}

._Dfzpa_alert-dismissible {
  padding-right: 3rem;
}

._Dfzpa_alert-dismissible ._Dfzpa_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

._Dfzpa_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

._Dfzpa_alert-primary ._Dfzpa_alert-link {
  color: #25224b;
}

._Dfzpa_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

._Dfzpa_alert-secondary ._Dfzpa_alert-link {
  color: #34383c;
}

._Dfzpa_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

._Dfzpa_alert-success ._Dfzpa_alert-link {
  color: #0c4128;
}

._Dfzpa_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

._Dfzpa_alert-info ._Dfzpa_alert-link {
  color: #04414d;
}

._Dfzpa_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

._Dfzpa_alert-warning ._Dfzpa_alert-link {
  color: #523e02;
}

._Dfzpa_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

._Dfzpa_alert-danger ._Dfzpa_alert-link {
  color: #6a1a21;
}

._Dfzpa_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

._Dfzpa_alert-light ._Dfzpa_alert-link {
  color: #4f5050;
}

._Dfzpa_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

._Dfzpa_alert-dark ._Dfzpa_alert-link {
  color: #101214;
}

@keyframes _Dfzpa_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

._Dfzpa_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

._Dfzpa_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_progress-bar {
    transition: none;
  }
}

._Dfzpa_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

._Dfzpa_progress-bar-animated {
  animation: 1s linear infinite _Dfzpa_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_progress-bar-animated {
    animation: none;
  }
}

._Dfzpa_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

._Dfzpa_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

._Dfzpa_list-group-numbered > ._Dfzpa_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

._Dfzpa_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

._Dfzpa_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

._Dfzpa_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

._Dfzpa_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

._Dfzpa_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

._Dfzpa_list-group-item._Dfzpa_disabled, ._Dfzpa_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

._Dfzpa_list-group-item._Dfzpa_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
  border-top-width: 0;
}

._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

._Dfzpa_list-group-horizontal {
  flex-direction: row;
}

._Dfzpa_list-group-horizontal > ._Dfzpa_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

._Dfzpa_list-group-horizontal > ._Dfzpa_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

._Dfzpa_list-group-horizontal > ._Dfzpa_list-group-item._Dfzpa_active {
  margin-top: 0;
}

._Dfzpa_list-group-horizontal > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

._Dfzpa_list-group-horizontal > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  ._Dfzpa_list-group-horizontal-sm {
    flex-direction: row;
  }

  ._Dfzpa_list-group-horizontal-sm > ._Dfzpa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-sm > ._Dfzpa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-sm > ._Dfzpa_list-group-item._Dfzpa_active {
    margin-top: 0;
  }

  ._Dfzpa_list-group-horizontal-sm > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._Dfzpa_list-group-horizontal-sm > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  ._Dfzpa_list-group-horizontal-md {
    flex-direction: row;
  }

  ._Dfzpa_list-group-horizontal-md > ._Dfzpa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-md > ._Dfzpa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-md > ._Dfzpa_list-group-item._Dfzpa_active {
    margin-top: 0;
  }

  ._Dfzpa_list-group-horizontal-md > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._Dfzpa_list-group-horizontal-md > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  ._Dfzpa_list-group-horizontal-lg {
    flex-direction: row;
  }

  ._Dfzpa_list-group-horizontal-lg > ._Dfzpa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-lg > ._Dfzpa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-lg > ._Dfzpa_list-group-item._Dfzpa_active {
    margin-top: 0;
  }

  ._Dfzpa_list-group-horizontal-lg > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._Dfzpa_list-group-horizontal-lg > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_list-group-horizontal-xl {
    flex-direction: row;
  }

  ._Dfzpa_list-group-horizontal-xl > ._Dfzpa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-xl > ._Dfzpa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-xl > ._Dfzpa_list-group-item._Dfzpa_active {
    margin-top: 0;
  }

  ._Dfzpa_list-group-horizontal-xl > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._Dfzpa_list-group-horizontal-xl > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_list-group-horizontal-xxl {
    flex-direction: row;
  }

  ._Dfzpa_list-group-horizontal-xxl > ._Dfzpa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-xxl > ._Dfzpa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._Dfzpa_list-group-horizontal-xxl > ._Dfzpa_list-group-item._Dfzpa_active {
    margin-top: 0;
  }

  ._Dfzpa_list-group-horizontal-xxl > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._Dfzpa_list-group-horizontal-xxl > ._Dfzpa_list-group-item + ._Dfzpa_list-group-item._Dfzpa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

._Dfzpa_list-group-flush {
  border-radius: 0;
}

._Dfzpa_list-group-flush > ._Dfzpa_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

._Dfzpa_list-group-flush > ._Dfzpa_list-group-item:last-child {
  border-bottom-width: 0;
}

._Dfzpa_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

._Dfzpa_list-group-item-primary._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-primary._Dfzpa_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

._Dfzpa_list-group-item-primary._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

._Dfzpa_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

._Dfzpa_list-group-item-secondary._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-secondary._Dfzpa_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

._Dfzpa_list-group-item-secondary._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

._Dfzpa_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

._Dfzpa_list-group-item-success._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-success._Dfzpa_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

._Dfzpa_list-group-item-success._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

._Dfzpa_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

._Dfzpa_list-group-item-info._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-info._Dfzpa_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

._Dfzpa_list-group-item-info._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

._Dfzpa_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

._Dfzpa_list-group-item-warning._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-warning._Dfzpa_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

._Dfzpa_list-group-item-warning._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

._Dfzpa_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

._Dfzpa_list-group-item-danger._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-danger._Dfzpa_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

._Dfzpa_list-group-item-danger._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

._Dfzpa_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

._Dfzpa_list-group-item-light._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-light._Dfzpa_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

._Dfzpa_list-group-item-light._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

._Dfzpa_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

._Dfzpa_list-group-item-dark._Dfzpa_list-group-item-action:hover, ._Dfzpa_list-group-item-dark._Dfzpa_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

._Dfzpa_list-group-item-dark._Dfzpa_list-group-item-action._Dfzpa_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

._Dfzpa_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

._Dfzpa_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

._Dfzpa_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._Dfzpa_btn-close:disabled, ._Dfzpa_btn-close._Dfzpa_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

._Dfzpa_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

._Dfzpa_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

._Dfzpa_toast._Dfzpa_showing {
  opacity: 0;
}

._Dfzpa_toast:not(._Dfzpa_show) {
  display: none;
}

._Dfzpa_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

._Dfzpa_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

._Dfzpa_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

._Dfzpa_toast-header ._Dfzpa_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

._Dfzpa_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

._Dfzpa_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

._Dfzpa_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

._Dfzpa_modal._Dfzpa_fade ._Dfzpa_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_modal._Dfzpa_fade ._Dfzpa_modal-dialog {
    transition: none;
  }
}

._Dfzpa_modal._Dfzpa_show ._Dfzpa_modal-dialog {
  transform: none;
}

._Dfzpa_modal._Dfzpa_modal-static ._Dfzpa_modal-dialog {
  transform: scale(1.02);
}

._Dfzpa_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

._Dfzpa_modal-dialog-scrollable ._Dfzpa_modal-content {
  max-height: 100%;
  overflow: hidden;
}

._Dfzpa_modal-dialog-scrollable ._Dfzpa_modal-body {
  overflow-y: auto;
}

._Dfzpa_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

._Dfzpa_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

._Dfzpa_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

._Dfzpa_modal-backdrop._Dfzpa_fade {
  opacity: 0;
}

._Dfzpa_modal-backdrop._Dfzpa_show {
  opacity: var(--bs-backdrop-opacity);
}

._Dfzpa_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._Dfzpa_modal-header ._Dfzpa_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

._Dfzpa_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

._Dfzpa_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

._Dfzpa_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._Dfzpa_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  ._Dfzpa_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  ._Dfzpa_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  ._Dfzpa_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_modal-lg, ._Dfzpa_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_modal-xl {
    --bs-modal-width: 1140px;
  }
}

._Dfzpa_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

._Dfzpa_modal-fullscreen ._Dfzpa_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

._Dfzpa_modal-fullscreen ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen ._Dfzpa_modal-footer {
  border-radius: 0;
}

._Dfzpa_modal-fullscreen ._Dfzpa_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  ._Dfzpa_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._Dfzpa_modal-fullscreen-sm-down ._Dfzpa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-sm-down ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen-sm-down ._Dfzpa_modal-footer {
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-sm-down ._Dfzpa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  ._Dfzpa_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._Dfzpa_modal-fullscreen-md-down ._Dfzpa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-md-down ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen-md-down ._Dfzpa_modal-footer {
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-md-down ._Dfzpa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  ._Dfzpa_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._Dfzpa_modal-fullscreen-lg-down ._Dfzpa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-lg-down ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen-lg-down ._Dfzpa_modal-footer {
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-lg-down ._Dfzpa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  ._Dfzpa_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._Dfzpa_modal-fullscreen-xl-down ._Dfzpa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-xl-down ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen-xl-down ._Dfzpa_modal-footer {
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-xl-down ._Dfzpa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  ._Dfzpa_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._Dfzpa_modal-fullscreen-xxl-down ._Dfzpa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-xxl-down ._Dfzpa_modal-header, ._Dfzpa_modal-fullscreen-xxl-down ._Dfzpa_modal-footer {
    border-radius: 0;
  }

  ._Dfzpa_modal-fullscreen-xxl-down ._Dfzpa_modal-body {
    overflow-y: auto;
  }
}

._Dfzpa_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

._Dfzpa_tooltip._Dfzpa_show {
  opacity: var(--bs-tooltip-opacity);
}

._Dfzpa_tooltip ._Dfzpa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

._Dfzpa_tooltip ._Dfzpa_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

._Dfzpa_bs-tooltip-top ._Dfzpa_tooltip-arrow, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="top"] ._Dfzpa_tooltip-arrow {
  bottom: 0;
}

._Dfzpa_bs-tooltip-top ._Dfzpa_tooltip-arrow:before, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="top"] ._Dfzpa_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

._Dfzpa_bs-tooltip-end ._Dfzpa_tooltip-arrow, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="right"] ._Dfzpa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

._Dfzpa_bs-tooltip-end ._Dfzpa_tooltip-arrow:before, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="right"] ._Dfzpa_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

._Dfzpa_bs-tooltip-bottom ._Dfzpa_tooltip-arrow, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="bottom"] ._Dfzpa_tooltip-arrow {
  top: 0;
}

._Dfzpa_bs-tooltip-bottom ._Dfzpa_tooltip-arrow:before, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="bottom"] ._Dfzpa_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

._Dfzpa_bs-tooltip-start ._Dfzpa_tooltip-arrow, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="left"] ._Dfzpa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

._Dfzpa_bs-tooltip-start ._Dfzpa_tooltip-arrow:before, ._Dfzpa_bs-tooltip-auto[data-popper-placement^="left"] ._Dfzpa_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

._Dfzpa_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

._Dfzpa_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

._Dfzpa_popover ._Dfzpa_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

._Dfzpa_popover ._Dfzpa_popover-arrow:before, ._Dfzpa_popover ._Dfzpa_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

._Dfzpa_bs-popover-top > ._Dfzpa_popover-arrow, ._Dfzpa_bs-popover-auto[data-popper-placement^="top"] > ._Dfzpa_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._Dfzpa_bs-popover-top > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="top"] > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-top > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="top"] > ._Dfzpa_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._Dfzpa_bs-popover-top > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="top"] > ._Dfzpa_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

._Dfzpa_bs-popover-top > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="top"] > ._Dfzpa_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

._Dfzpa_bs-popover-end > ._Dfzpa_popover-arrow, ._Dfzpa_bs-popover-auto[data-popper-placement^="right"] > ._Dfzpa_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._Dfzpa_bs-popover-end > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="right"] > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-end > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="right"] > ._Dfzpa_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._Dfzpa_bs-popover-end > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="right"] > ._Dfzpa_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

._Dfzpa_bs-popover-end > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="right"] > ._Dfzpa_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

._Dfzpa_bs-popover-bottom > ._Dfzpa_popover-arrow, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] > ._Dfzpa_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._Dfzpa_bs-popover-bottom > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-bottom > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] > ._Dfzpa_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._Dfzpa_bs-popover-bottom > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] > ._Dfzpa_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

._Dfzpa_bs-popover-bottom > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] > ._Dfzpa_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

._Dfzpa_bs-popover-bottom ._Dfzpa_popover-header:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="bottom"] ._Dfzpa_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

._Dfzpa_bs-popover-start > ._Dfzpa_popover-arrow, ._Dfzpa_bs-popover-auto[data-popper-placement^="left"] > ._Dfzpa_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._Dfzpa_bs-popover-start > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="left"] > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-start > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="left"] > ._Dfzpa_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._Dfzpa_bs-popover-start > ._Dfzpa_popover-arrow:before, ._Dfzpa_bs-popover-auto[data-popper-placement^="left"] > ._Dfzpa_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

._Dfzpa_bs-popover-start > ._Dfzpa_popover-arrow:after, ._Dfzpa_bs-popover-auto[data-popper-placement^="left"] > ._Dfzpa_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

._Dfzpa_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

._Dfzpa_popover-header:empty {
  display: none;
}

._Dfzpa_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

._Dfzpa_carousel {
  position: relative;
}

._Dfzpa_carousel._Dfzpa_pointer-event {
  touch-action: pan-y;
}

._Dfzpa_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

._Dfzpa_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

._Dfzpa_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_carousel-item {
    transition: none;
  }
}

._Dfzpa_carousel-item._Dfzpa_active, ._Dfzpa_carousel-item-next, ._Dfzpa_carousel-item-prev {
  display: block;
}

._Dfzpa_carousel-item-next:not(._Dfzpa_carousel-item-start), ._Dfzpa_active._Dfzpa_carousel-item-end {
  transform: translateX(100%);
}

._Dfzpa_carousel-item-prev:not(._Dfzpa_carousel-item-end), ._Dfzpa_active._Dfzpa_carousel-item-start {
  transform: translateX(-100%);
}

._Dfzpa_carousel-fade ._Dfzpa_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

._Dfzpa_carousel-fade ._Dfzpa_carousel-item._Dfzpa_active, ._Dfzpa_carousel-fade ._Dfzpa_carousel-item-next._Dfzpa_carousel-item-start, ._Dfzpa_carousel-fade ._Dfzpa_carousel-item-prev._Dfzpa_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

._Dfzpa_carousel-fade ._Dfzpa_active._Dfzpa_carousel-item-start, ._Dfzpa_carousel-fade ._Dfzpa_active._Dfzpa_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_carousel-fade ._Dfzpa_active._Dfzpa_carousel-item-start, ._Dfzpa_carousel-fade ._Dfzpa_active._Dfzpa_carousel-item-end {
    transition: none;
  }
}

._Dfzpa_carousel-control-prev, ._Dfzpa_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_carousel-control-prev, ._Dfzpa_carousel-control-next {
    transition: none;
  }
}

._Dfzpa_carousel-control-prev:hover, ._Dfzpa_carousel-control-prev:focus, ._Dfzpa_carousel-control-next:hover, ._Dfzpa_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

._Dfzpa_carousel-control-prev {
  left: 0;
}

._Dfzpa_carousel-control-next {
  right: 0;
}

._Dfzpa_carousel-control-prev-icon, ._Dfzpa_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

._Dfzpa_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

._Dfzpa_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

._Dfzpa_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._Dfzpa_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

._Dfzpa_carousel-indicators ._Dfzpa_active {
  opacity: 1;
}

._Dfzpa_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

._Dfzpa_carousel-dark ._Dfzpa_carousel-control-prev-icon, ._Dfzpa_carousel-dark ._Dfzpa_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

._Dfzpa_carousel-dark ._Dfzpa_carousel-indicators [data-bs-target] {
  background-color: #000;
}

._Dfzpa_carousel-dark ._Dfzpa_carousel-caption {
  color: #000;
}

._Dfzpa_spinner-grow, ._Dfzpa_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes _Dfzpa_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

._Dfzpa_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

._Dfzpa_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes _Dfzpa_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

._Dfzpa_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

._Dfzpa_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_spinner-border, ._Dfzpa_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

._Dfzpa_offcanvas, ._Dfzpa_offcanvas-xxl, ._Dfzpa_offcanvas-xl, ._Dfzpa_offcanvas-lg, ._Dfzpa_offcanvas-md, ._Dfzpa_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  ._Dfzpa_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  ._Dfzpa_offcanvas-sm._Dfzpa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._Dfzpa_offcanvas-sm._Dfzpa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._Dfzpa_offcanvas-sm._Dfzpa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._Dfzpa_offcanvas-sm._Dfzpa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._Dfzpa_offcanvas-sm._Dfzpa_showing, ._Dfzpa_offcanvas-sm._Dfzpa_show:not(._Dfzpa_hiding) {
    transform: none;
  }

  ._Dfzpa_offcanvas-sm._Dfzpa_showing, ._Dfzpa_offcanvas-sm._Dfzpa_hiding, ._Dfzpa_offcanvas-sm._Dfzpa_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  ._Dfzpa_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._Dfzpa_offcanvas-sm ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_offcanvas-sm ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  ._Dfzpa_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  ._Dfzpa_offcanvas-md._Dfzpa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._Dfzpa_offcanvas-md._Dfzpa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._Dfzpa_offcanvas-md._Dfzpa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._Dfzpa_offcanvas-md._Dfzpa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._Dfzpa_offcanvas-md._Dfzpa_showing, ._Dfzpa_offcanvas-md._Dfzpa_show:not(._Dfzpa_hiding) {
    transform: none;
  }

  ._Dfzpa_offcanvas-md._Dfzpa_showing, ._Dfzpa_offcanvas-md._Dfzpa_hiding, ._Dfzpa_offcanvas-md._Dfzpa_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._Dfzpa_offcanvas-md ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_offcanvas-md ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  ._Dfzpa_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  ._Dfzpa_offcanvas-lg._Dfzpa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._Dfzpa_offcanvas-lg._Dfzpa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._Dfzpa_offcanvas-lg._Dfzpa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._Dfzpa_offcanvas-lg._Dfzpa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._Dfzpa_offcanvas-lg._Dfzpa_showing, ._Dfzpa_offcanvas-lg._Dfzpa_show:not(._Dfzpa_hiding) {
    transform: none;
  }

  ._Dfzpa_offcanvas-lg._Dfzpa_showing, ._Dfzpa_offcanvas-lg._Dfzpa_hiding, ._Dfzpa_offcanvas-lg._Dfzpa_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._Dfzpa_offcanvas-lg ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_offcanvas-lg ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  ._Dfzpa_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  ._Dfzpa_offcanvas-xl._Dfzpa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._Dfzpa_offcanvas-xl._Dfzpa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._Dfzpa_offcanvas-xl._Dfzpa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._Dfzpa_offcanvas-xl._Dfzpa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._Dfzpa_offcanvas-xl._Dfzpa_showing, ._Dfzpa_offcanvas-xl._Dfzpa_show:not(._Dfzpa_hiding) {
    transform: none;
  }

  ._Dfzpa_offcanvas-xl._Dfzpa_showing, ._Dfzpa_offcanvas-xl._Dfzpa_hiding, ._Dfzpa_offcanvas-xl._Dfzpa_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._Dfzpa_offcanvas-xl ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_offcanvas-xl ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  ._Dfzpa_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  ._Dfzpa_offcanvas-xxl._Dfzpa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._Dfzpa_offcanvas-xxl._Dfzpa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._Dfzpa_offcanvas-xxl._Dfzpa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._Dfzpa_offcanvas-xxl._Dfzpa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._Dfzpa_offcanvas-xxl._Dfzpa_showing, ._Dfzpa_offcanvas-xxl._Dfzpa_show:not(._Dfzpa_hiding) {
    transform: none;
  }

  ._Dfzpa_offcanvas-xxl._Dfzpa_showing, ._Dfzpa_offcanvas-xxl._Dfzpa_hiding, ._Dfzpa_offcanvas-xxl._Dfzpa_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._Dfzpa_offcanvas-xxl ._Dfzpa_offcanvas-header {
    display: none;
  }

  ._Dfzpa_offcanvas-xxl ._Dfzpa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

._Dfzpa_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._Dfzpa_offcanvas {
    transition: none;
  }
}

._Dfzpa_offcanvas._Dfzpa_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

._Dfzpa_offcanvas._Dfzpa_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

._Dfzpa_offcanvas._Dfzpa_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

._Dfzpa_offcanvas._Dfzpa_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

._Dfzpa_offcanvas._Dfzpa_showing, ._Dfzpa_offcanvas._Dfzpa_show:not(._Dfzpa_hiding) {
  transform: none;
}

._Dfzpa_offcanvas._Dfzpa_showing, ._Dfzpa_offcanvas._Dfzpa_hiding, ._Dfzpa_offcanvas._Dfzpa_show {
  visibility: visible;
}

._Dfzpa_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

._Dfzpa_offcanvas-backdrop._Dfzpa_fade {
  opacity: 0;
}

._Dfzpa_offcanvas-backdrop._Dfzpa_show {
  opacity: .5;
}

._Dfzpa_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._Dfzpa_offcanvas-header ._Dfzpa_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

._Dfzpa_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

._Dfzpa_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

._Dfzpa_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

._Dfzpa_placeholder._Dfzpa_btn:before {
  content: "";
  display: inline-block;
}

._Dfzpa_placeholder-xs {
  min-height: .6em;
}

._Dfzpa_placeholder-sm {
  min-height: .8em;
}

._Dfzpa_placeholder-lg {
  min-height: 1.2em;
}

._Dfzpa_placeholder-glow ._Dfzpa_placeholder {
  animation: 2s ease-in-out infinite _Dfzpa_placeholder-glow;
}

@keyframes _Dfzpa_placeholder-glow {
  50% {
    opacity: .2;
  }
}

._Dfzpa_placeholder-wave {
  animation: 2s linear infinite _Dfzpa_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes _Dfzpa_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

._Dfzpa_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

._Dfzpa_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

._Dfzpa_link-primary {
  color: #4c469c !important;
}

._Dfzpa_link-primary:hover, ._Dfzpa_link-primary:focus {
  color: #3d387d !important;
}

._Dfzpa_link-secondary {
  color: #6c757d !important;
}

._Dfzpa_link-secondary:hover, ._Dfzpa_link-secondary:focus {
  color: #565e64 !important;
}

._Dfzpa_link-success {
  color: #198754 !important;
}

._Dfzpa_link-success:hover, ._Dfzpa_link-success:focus {
  color: #146c43 !important;
}

._Dfzpa_link-info {
  color: #0dcaf0 !important;
}

._Dfzpa_link-info:hover, ._Dfzpa_link-info:focus {
  color: #3dd5f3 !important;
}

._Dfzpa_link-warning {
  color: #ffc107 !important;
}

._Dfzpa_link-warning:hover, ._Dfzpa_link-warning:focus {
  color: #ffcd39 !important;
}

._Dfzpa_link-danger {
  color: #dc3545 !important;
}

._Dfzpa_link-danger:hover, ._Dfzpa_link-danger:focus {
  color: #b02a37 !important;
}

._Dfzpa_link-light {
  color: #f8f9fa !important;
}

._Dfzpa_link-light:hover, ._Dfzpa_link-light:focus {
  color: #f9fafb !important;
}

._Dfzpa_link-dark {
  color: #212529 !important;
}

._Dfzpa_link-dark:hover, ._Dfzpa_link-dark:focus {
  color: #1a1e21 !important;
}

._Dfzpa_ratio {
  width: 100%;
  position: relative;
}

._Dfzpa_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

._Dfzpa_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._Dfzpa_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

._Dfzpa_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

._Dfzpa_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

._Dfzpa_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

._Dfzpa_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._Dfzpa_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

._Dfzpa_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

._Dfzpa_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  ._Dfzpa_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._Dfzpa_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._Dfzpa_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._Dfzpa_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._Dfzpa_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._Dfzpa_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

._Dfzpa_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

._Dfzpa_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

._Dfzpa_visually-hidden, ._Dfzpa_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

._Dfzpa_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

._Dfzpa_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._Dfzpa_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

._Dfzpa_align-baseline {
  vertical-align: baseline !important;
}

._Dfzpa_align-top {
  vertical-align: top !important;
}

._Dfzpa_align-middle {
  vertical-align: middle !important;
}

._Dfzpa_align-bottom {
  vertical-align: bottom !important;
}

._Dfzpa_align-text-bottom {
  vertical-align: text-bottom !important;
}

._Dfzpa_align-text-top {
  vertical-align: text-top !important;
}

._Dfzpa_float-start {
  float: left !important;
}

._Dfzpa_float-end {
  float: right !important;
}

._Dfzpa_float-none {
  float: none !important;
}

._Dfzpa_opacity-0 {
  opacity: 0 !important;
}

._Dfzpa_opacity-25 {
  opacity: .25 !important;
}

._Dfzpa_opacity-50 {
  opacity: .5 !important;
}

._Dfzpa_opacity-75 {
  opacity: .75 !important;
}

._Dfzpa_opacity-100 {
  opacity: 1 !important;
}

._Dfzpa_overflow-auto {
  overflow: auto !important;
}

._Dfzpa_overflow-hidden {
  overflow: hidden !important;
}

._Dfzpa_overflow-visible {
  overflow: visible !important;
}

._Dfzpa_overflow-scroll {
  overflow: scroll !important;
}

._Dfzpa_d-inline {
  display: inline !important;
}

._Dfzpa_d-inline-block {
  display: inline-block !important;
}

._Dfzpa_d-block {
  display: block !important;
}

._Dfzpa_d-grid {
  display: grid !important;
}

._Dfzpa_d-table {
  display: table !important;
}

._Dfzpa_d-table-row {
  display: table-row !important;
}

._Dfzpa_d-table-cell {
  display: table-cell !important;
}

._Dfzpa_d-flex {
  display: flex !important;
}

._Dfzpa_d-inline-flex {
  display: inline-flex !important;
}

._Dfzpa_d-none {
  display: none !important;
}

._Dfzpa_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

._Dfzpa_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

._Dfzpa_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

._Dfzpa_shadow-none {
  box-shadow: none !important;
}

._Dfzpa_position-static {
  position: static !important;
}

._Dfzpa_position-relative {
  position: relative !important;
}

._Dfzpa_position-absolute {
  position: absolute !important;
}

._Dfzpa_position-fixed {
  position: fixed !important;
}

._Dfzpa_position-sticky {
  position: sticky !important;
}

._Dfzpa_top-0 {
  top: 0 !important;
}

._Dfzpa_top-50 {
  top: 50% !important;
}

._Dfzpa_top-100 {
  top: 100% !important;
}

._Dfzpa_bottom-0 {
  bottom: 0 !important;
}

._Dfzpa_bottom-50 {
  bottom: 50% !important;
}

._Dfzpa_bottom-100 {
  bottom: 100% !important;
}

._Dfzpa_start-0 {
  left: 0 !important;
}

._Dfzpa_start-50 {
  left: 50% !important;
}

._Dfzpa_start-100 {
  left: 100% !important;
}

._Dfzpa_end-0 {
  right: 0 !important;
}

._Dfzpa_end-50 {
  right: 50% !important;
}

._Dfzpa_end-100 {
  right: 100% !important;
}

._Dfzpa_translate-middle {
  transform: translate(-50%, -50%) !important;
}

._Dfzpa_translate-middle-x {
  transform: translateX(-50%) !important;
}

._Dfzpa_translate-middle-y {
  transform: translateY(-50%) !important;
}

._Dfzpa_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._Dfzpa_border-0 {
  border: 0 !important;
}

._Dfzpa_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._Dfzpa_border-top-0 {
  border-top: 0 !important;
}

._Dfzpa_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._Dfzpa_border-end-0 {
  border-right: 0 !important;
}

._Dfzpa_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._Dfzpa_border-bottom-0 {
  border-bottom: 0 !important;
}

._Dfzpa_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._Dfzpa_border-start-0 {
  border-left: 0 !important;
}

._Dfzpa_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

._Dfzpa_border-1 {
  --bs-border-width: 1px;
}

._Dfzpa_border-2 {
  --bs-border-width: 2px;
}

._Dfzpa_border-3 {
  --bs-border-width: 3px;
}

._Dfzpa_border-4 {
  --bs-border-width: 4px;
}

._Dfzpa_border-5 {
  --bs-border-width: 5px;
}

._Dfzpa_border-opacity-10 {
  --bs-border-opacity: .1;
}

._Dfzpa_border-opacity-25 {
  --bs-border-opacity: .25;
}

._Dfzpa_border-opacity-50 {
  --bs-border-opacity: .5;
}

._Dfzpa_border-opacity-75 {
  --bs-border-opacity: .75;
}

._Dfzpa_border-opacity-100 {
  --bs-border-opacity: 1;
}

._Dfzpa_w-25 {
  width: 25% !important;
}

._Dfzpa_w-50 {
  width: 50% !important;
}

._Dfzpa_w-75 {
  width: 75% !important;
}

._Dfzpa_w-100 {
  width: 100% !important;
}

._Dfzpa_w-auto {
  width: auto !important;
}

._Dfzpa_mw-100 {
  max-width: 100% !important;
}

._Dfzpa_vw-100 {
  width: 100vw !important;
}

._Dfzpa_min-vw-100 {
  min-width: 100vw !important;
}

._Dfzpa_h-25 {
  height: 25% !important;
}

._Dfzpa_h-50 {
  height: 50% !important;
}

._Dfzpa_h-75 {
  height: 75% !important;
}

._Dfzpa_h-100 {
  height: 100% !important;
}

._Dfzpa_h-auto {
  height: auto !important;
}

._Dfzpa_mh-100 {
  max-height: 100% !important;
}

._Dfzpa_vh-100 {
  height: 100vh !important;
}

._Dfzpa_min-vh-100 {
  min-height: 100vh !important;
}

._Dfzpa_flex-fill {
  flex: auto !important;
}

._Dfzpa_flex-row {
  flex-direction: row !important;
}

._Dfzpa_flex-column {
  flex-direction: column !important;
}

._Dfzpa_flex-row-reverse {
  flex-direction: row-reverse !important;
}

._Dfzpa_flex-column-reverse {
  flex-direction: column-reverse !important;
}

._Dfzpa_flex-grow-0 {
  flex-grow: 0 !important;
}

._Dfzpa_flex-grow-1 {
  flex-grow: 1 !important;
}

._Dfzpa_flex-shrink-0 {
  flex-shrink: 0 !important;
}

._Dfzpa_flex-shrink-1 {
  flex-shrink: 1 !important;
}

._Dfzpa_flex-wrap {
  flex-wrap: wrap !important;
}

._Dfzpa_flex-nowrap {
  flex-wrap: nowrap !important;
}

._Dfzpa_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._Dfzpa_justify-content-start {
  justify-content: flex-start !important;
}

._Dfzpa_justify-content-end {
  justify-content: flex-end !important;
}

._Dfzpa_justify-content-center {
  justify-content: center !important;
}

._Dfzpa_justify-content-between {
  justify-content: space-between !important;
}

._Dfzpa_justify-content-around {
  justify-content: space-around !important;
}

._Dfzpa_justify-content-evenly {
  justify-content: space-evenly !important;
}

._Dfzpa_align-items-start {
  align-items: flex-start !important;
}

._Dfzpa_align-items-end {
  align-items: flex-end !important;
}

._Dfzpa_align-items-center {
  align-items: center !important;
}

._Dfzpa_align-items-baseline {
  align-items: baseline !important;
}

._Dfzpa_align-items-stretch {
  align-items: stretch !important;
}

._Dfzpa_align-content-start {
  align-content: flex-start !important;
}

._Dfzpa_align-content-end {
  align-content: flex-end !important;
}

._Dfzpa_align-content-center {
  align-content: center !important;
}

._Dfzpa_align-content-between {
  align-content: space-between !important;
}

._Dfzpa_align-content-around {
  align-content: space-around !important;
}

._Dfzpa_align-content-stretch {
  align-content: stretch !important;
}

._Dfzpa_align-self-auto {
  align-self: auto !important;
}

._Dfzpa_align-self-start {
  align-self: flex-start !important;
}

._Dfzpa_align-self-end {
  align-self: flex-end !important;
}

._Dfzpa_align-self-center {
  align-self: center !important;
}

._Dfzpa_align-self-baseline {
  align-self: baseline !important;
}

._Dfzpa_align-self-stretch {
  align-self: stretch !important;
}

._Dfzpa_order-first {
  order: -1 !important;
}

._Dfzpa_order-0 {
  order: 0 !important;
}

._Dfzpa_order-1 {
  order: 1 !important;
}

._Dfzpa_order-2 {
  order: 2 !important;
}

._Dfzpa_order-3 {
  order: 3 !important;
}

._Dfzpa_order-4 {
  order: 4 !important;
}

._Dfzpa_order-5 {
  order: 5 !important;
}

._Dfzpa_order-last {
  order: 6 !important;
}

._Dfzpa_m-0 {
  margin: 0 !important;
}

._Dfzpa_m-1 {
  margin: .25rem !important;
}

._Dfzpa_m-2 {
  margin: .5rem !important;
}

._Dfzpa_m-3 {
  margin: 1rem !important;
}

._Dfzpa_m-4 {
  margin: 1.5rem !important;
}

._Dfzpa_m-5 {
  margin: 3rem !important;
}

._Dfzpa_m-auto {
  margin: auto !important;
}

._Dfzpa_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

._Dfzpa_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

._Dfzpa_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

._Dfzpa_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

._Dfzpa_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

._Dfzpa_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

._Dfzpa_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

._Dfzpa_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

._Dfzpa_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

._Dfzpa_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

._Dfzpa_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

._Dfzpa_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

._Dfzpa_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

._Dfzpa_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

._Dfzpa_mt-0 {
  margin-top: 0 !important;
}

._Dfzpa_mt-1 {
  margin-top: .25rem !important;
}

._Dfzpa_mt-2 {
  margin-top: .5rem !important;
}

._Dfzpa_mt-3 {
  margin-top: 1rem !important;
}

._Dfzpa_mt-4 {
  margin-top: 1.5rem !important;
}

._Dfzpa_mt-5 {
  margin-top: 3rem !important;
}

._Dfzpa_mt-auto {
  margin-top: auto !important;
}

._Dfzpa_me-0 {
  margin-right: 0 !important;
}

._Dfzpa_me-1 {
  margin-right: .25rem !important;
}

._Dfzpa_me-2 {
  margin-right: .5rem !important;
}

._Dfzpa_me-3 {
  margin-right: 1rem !important;
}

._Dfzpa_me-4 {
  margin-right: 1.5rem !important;
}

._Dfzpa_me-5 {
  margin-right: 3rem !important;
}

._Dfzpa_me-auto {
  margin-right: auto !important;
}

._Dfzpa_mb-0 {
  margin-bottom: 0 !important;
}

._Dfzpa_mb-1 {
  margin-bottom: .25rem !important;
}

._Dfzpa_mb-2 {
  margin-bottom: .5rem !important;
}

._Dfzpa_mb-3 {
  margin-bottom: 1rem !important;
}

._Dfzpa_mb-4 {
  margin-bottom: 1.5rem !important;
}

._Dfzpa_mb-5 {
  margin-bottom: 3rem !important;
}

._Dfzpa_mb-auto {
  margin-bottom: auto !important;
}

._Dfzpa_ms-0 {
  margin-left: 0 !important;
}

._Dfzpa_ms-1 {
  margin-left: .25rem !important;
}

._Dfzpa_ms-2 {
  margin-left: .5rem !important;
}

._Dfzpa_ms-3 {
  margin-left: 1rem !important;
}

._Dfzpa_ms-4 {
  margin-left: 1.5rem !important;
}

._Dfzpa_ms-5 {
  margin-left: 3rem !important;
}

._Dfzpa_ms-auto {
  margin-left: auto !important;
}

._Dfzpa_p-0 {
  padding: 0 !important;
}

._Dfzpa_p-1 {
  padding: .25rem !important;
}

._Dfzpa_p-2 {
  padding: .5rem !important;
}

._Dfzpa_p-3 {
  padding: 1rem !important;
}

._Dfzpa_p-4 {
  padding: 1.5rem !important;
}

._Dfzpa_p-5 {
  padding: 3rem !important;
}

._Dfzpa_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._Dfzpa_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

._Dfzpa_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

._Dfzpa_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

._Dfzpa_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

._Dfzpa_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

._Dfzpa_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

._Dfzpa_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

._Dfzpa_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

._Dfzpa_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

._Dfzpa_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

._Dfzpa_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

._Dfzpa_pt-0 {
  padding-top: 0 !important;
}

._Dfzpa_pt-1 {
  padding-top: .25rem !important;
}

._Dfzpa_pt-2 {
  padding-top: .5rem !important;
}

._Dfzpa_pt-3 {
  padding-top: 1rem !important;
}

._Dfzpa_pt-4 {
  padding-top: 1.5rem !important;
}

._Dfzpa_pt-5 {
  padding-top: 3rem !important;
}

._Dfzpa_pe-0 {
  padding-right: 0 !important;
}

._Dfzpa_pe-1 {
  padding-right: .25rem !important;
}

._Dfzpa_pe-2 {
  padding-right: .5rem !important;
}

._Dfzpa_pe-3 {
  padding-right: 1rem !important;
}

._Dfzpa_pe-4 {
  padding-right: 1.5rem !important;
}

._Dfzpa_pe-5 {
  padding-right: 3rem !important;
}

._Dfzpa_pb-0 {
  padding-bottom: 0 !important;
}

._Dfzpa_pb-1 {
  padding-bottom: .25rem !important;
}

._Dfzpa_pb-2 {
  padding-bottom: .5rem !important;
}

._Dfzpa_pb-3 {
  padding-bottom: 1rem !important;
}

._Dfzpa_pb-4 {
  padding-bottom: 1.5rem !important;
}

._Dfzpa_pb-5 {
  padding-bottom: 3rem !important;
}

._Dfzpa_ps-0 {
  padding-left: 0 !important;
}

._Dfzpa_ps-1 {
  padding-left: .25rem !important;
}

._Dfzpa_ps-2 {
  padding-left: .5rem !important;
}

._Dfzpa_ps-3 {
  padding-left: 1rem !important;
}

._Dfzpa_ps-4 {
  padding-left: 1.5rem !important;
}

._Dfzpa_ps-5 {
  padding-left: 3rem !important;
}

._Dfzpa_gap-0 {
  gap: 0 !important;
}

._Dfzpa_gap-1 {
  gap: .25rem !important;
}

._Dfzpa_gap-2 {
  gap: .5rem !important;
}

._Dfzpa_gap-3 {
  gap: 1rem !important;
}

._Dfzpa_gap-4 {
  gap: 1.5rem !important;
}

._Dfzpa_gap-5 {
  gap: 3rem !important;
}

._Dfzpa_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

._Dfzpa_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

._Dfzpa_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

._Dfzpa_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

._Dfzpa_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

._Dfzpa_fs-5 {
  font-size: 1.25rem !important;
}

._Dfzpa_fs-6 {
  font-size: 1rem !important;
}

._Dfzpa_fst-italic {
  font-style: italic !important;
}

._Dfzpa_fst-normal {
  font-style: normal !important;
}

._Dfzpa_fw-light {
  font-weight: 300 !important;
}

._Dfzpa_fw-lighter {
  font-weight: lighter !important;
}

._Dfzpa_fw-normal {
  font-weight: 400 !important;
}

._Dfzpa_fw-bold {
  font-weight: 700 !important;
}

._Dfzpa_fw-semibold {
  font-weight: 600 !important;
}

._Dfzpa_fw-bolder {
  font-weight: bolder !important;
}

._Dfzpa_lh-1 {
  line-height: 1 !important;
}

._Dfzpa_lh-sm {
  line-height: 1.25 !important;
}

._Dfzpa_lh-base {
  line-height: 1.5 !important;
}

._Dfzpa_lh-lg {
  line-height: 2 !important;
}

._Dfzpa_text-start {
  text-align: left !important;
}

._Dfzpa_text-end {
  text-align: right !important;
}

._Dfzpa_text-center {
  text-align: center !important;
}

._Dfzpa_text-decoration-none {
  text-decoration: none !important;
}

._Dfzpa_text-decoration-underline {
  text-decoration: underline !important;
}

._Dfzpa_text-decoration-line-through {
  text-decoration: line-through !important;
}

._Dfzpa_text-lowercase {
  text-transform: lowercase !important;
}

._Dfzpa_text-uppercase {
  text-transform: uppercase !important;
}

._Dfzpa_text-capitalize {
  text-transform: capitalize !important;
}

._Dfzpa_text-wrap {
  white-space: normal !important;
}

._Dfzpa_text-nowrap {
  white-space: nowrap !important;
}

._Dfzpa_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

._Dfzpa_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

._Dfzpa_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

._Dfzpa_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

._Dfzpa_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

._Dfzpa_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

._Dfzpa_text-opacity-25 {
  --bs-text-opacity: .25;
}

._Dfzpa_text-opacity-50 {
  --bs-text-opacity: .5;
}

._Dfzpa_text-opacity-75 {
  --bs-text-opacity: .75;
}

._Dfzpa_text-opacity-100 {
  --bs-text-opacity: 1;
}

._Dfzpa_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

._Dfzpa_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

._Dfzpa_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

._Dfzpa_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

._Dfzpa_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

._Dfzpa_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

._Dfzpa_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

._Dfzpa_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

._Dfzpa_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

._Dfzpa_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

._Dfzpa_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

._Dfzpa_pe-none {
  pointer-events: none !important;
}

._Dfzpa_pe-auto {
  pointer-events: auto !important;
}

._Dfzpa_rounded {
  border-radius: var(--bs-border-radius) !important;
}

._Dfzpa_rounded-0 {
  border-radius: 0 !important;
}

._Dfzpa_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

._Dfzpa_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

._Dfzpa_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

._Dfzpa_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

._Dfzpa_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

._Dfzpa_rounded-circle {
  border-radius: 50% !important;
}

._Dfzpa_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

._Dfzpa_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

._Dfzpa_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

._Dfzpa_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

._Dfzpa_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

._Dfzpa_visible {
  visibility: visible !important;
}

._Dfzpa_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  ._Dfzpa_float-sm-start {
    float: left !important;
  }

  ._Dfzpa_float-sm-end {
    float: right !important;
  }

  ._Dfzpa_float-sm-none {
    float: none !important;
  }

  ._Dfzpa_d-sm-inline {
    display: inline !important;
  }

  ._Dfzpa_d-sm-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-sm-block {
    display: block !important;
  }

  ._Dfzpa_d-sm-grid {
    display: grid !important;
  }

  ._Dfzpa_d-sm-table {
    display: table !important;
  }

  ._Dfzpa_d-sm-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-sm-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-sm-flex {
    display: flex !important;
  }

  ._Dfzpa_d-sm-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-sm-none {
    display: none !important;
  }

  ._Dfzpa_flex-sm-fill {
    flex: auto !important;
  }

  ._Dfzpa_flex-sm-row {
    flex-direction: row !important;
  }

  ._Dfzpa_flex-sm-column {
    flex-direction: column !important;
  }

  ._Dfzpa_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._Dfzpa_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._Dfzpa_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  ._Dfzpa_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  ._Dfzpa_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._Dfzpa_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._Dfzpa_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  ._Dfzpa_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  ._Dfzpa_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._Dfzpa_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  ._Dfzpa_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  ._Dfzpa_justify-content-sm-center {
    justify-content: center !important;
  }

  ._Dfzpa_justify-content-sm-between {
    justify-content: space-between !important;
  }

  ._Dfzpa_justify-content-sm-around {
    justify-content: space-around !important;
  }

  ._Dfzpa_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  ._Dfzpa_align-items-sm-start {
    align-items: flex-start !important;
  }

  ._Dfzpa_align-items-sm-end {
    align-items: flex-end !important;
  }

  ._Dfzpa_align-items-sm-center {
    align-items: center !important;
  }

  ._Dfzpa_align-items-sm-baseline {
    align-items: baseline !important;
  }

  ._Dfzpa_align-items-sm-stretch {
    align-items: stretch !important;
  }

  ._Dfzpa_align-content-sm-start {
    align-content: flex-start !important;
  }

  ._Dfzpa_align-content-sm-end {
    align-content: flex-end !important;
  }

  ._Dfzpa_align-content-sm-center {
    align-content: center !important;
  }

  ._Dfzpa_align-content-sm-between {
    align-content: space-between !important;
  }

  ._Dfzpa_align-content-sm-around {
    align-content: space-around !important;
  }

  ._Dfzpa_align-content-sm-stretch {
    align-content: stretch !important;
  }

  ._Dfzpa_align-self-sm-auto {
    align-self: auto !important;
  }

  ._Dfzpa_align-self-sm-start {
    align-self: flex-start !important;
  }

  ._Dfzpa_align-self-sm-end {
    align-self: flex-end !important;
  }

  ._Dfzpa_align-self-sm-center {
    align-self: center !important;
  }

  ._Dfzpa_align-self-sm-baseline {
    align-self: baseline !important;
  }

  ._Dfzpa_align-self-sm-stretch {
    align-self: stretch !important;
  }

  ._Dfzpa_order-sm-first {
    order: -1 !important;
  }

  ._Dfzpa_order-sm-0 {
    order: 0 !important;
  }

  ._Dfzpa_order-sm-1 {
    order: 1 !important;
  }

  ._Dfzpa_order-sm-2 {
    order: 2 !important;
  }

  ._Dfzpa_order-sm-3 {
    order: 3 !important;
  }

  ._Dfzpa_order-sm-4 {
    order: 4 !important;
  }

  ._Dfzpa_order-sm-5 {
    order: 5 !important;
  }

  ._Dfzpa_order-sm-last {
    order: 6 !important;
  }

  ._Dfzpa_m-sm-0 {
    margin: 0 !important;
  }

  ._Dfzpa_m-sm-1 {
    margin: .25rem !important;
  }

  ._Dfzpa_m-sm-2 {
    margin: .5rem !important;
  }

  ._Dfzpa_m-sm-3 {
    margin: 1rem !important;
  }

  ._Dfzpa_m-sm-4 {
    margin: 1.5rem !important;
  }

  ._Dfzpa_m-sm-5 {
    margin: 3rem !important;
  }

  ._Dfzpa_m-sm-auto {
    margin: auto !important;
  }

  ._Dfzpa_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._Dfzpa_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._Dfzpa_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._Dfzpa_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._Dfzpa_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._Dfzpa_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._Dfzpa_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._Dfzpa_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._Dfzpa_mt-sm-0 {
    margin-top: 0 !important;
  }

  ._Dfzpa_mt-sm-1 {
    margin-top: .25rem !important;
  }

  ._Dfzpa_mt-sm-2 {
    margin-top: .5rem !important;
  }

  ._Dfzpa_mt-sm-3 {
    margin-top: 1rem !important;
  }

  ._Dfzpa_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  ._Dfzpa_mt-sm-5 {
    margin-top: 3rem !important;
  }

  ._Dfzpa_mt-sm-auto {
    margin-top: auto !important;
  }

  ._Dfzpa_me-sm-0 {
    margin-right: 0 !important;
  }

  ._Dfzpa_me-sm-1 {
    margin-right: .25rem !important;
  }

  ._Dfzpa_me-sm-2 {
    margin-right: .5rem !important;
  }

  ._Dfzpa_me-sm-3 {
    margin-right: 1rem !important;
  }

  ._Dfzpa_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_me-sm-5 {
    margin-right: 3rem !important;
  }

  ._Dfzpa_me-sm-auto {
    margin-right: auto !important;
  }

  ._Dfzpa_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  ._Dfzpa_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_mb-sm-auto {
    margin-bottom: auto !important;
  }

  ._Dfzpa_ms-sm-0 {
    margin-left: 0 !important;
  }

  ._Dfzpa_ms-sm-1 {
    margin-left: .25rem !important;
  }

  ._Dfzpa_ms-sm-2 {
    margin-left: .5rem !important;
  }

  ._Dfzpa_ms-sm-3 {
    margin-left: 1rem !important;
  }

  ._Dfzpa_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  ._Dfzpa_ms-sm-5 {
    margin-left: 3rem !important;
  }

  ._Dfzpa_ms-sm-auto {
    margin-left: auto !important;
  }

  ._Dfzpa_p-sm-0 {
    padding: 0 !important;
  }

  ._Dfzpa_p-sm-1 {
    padding: .25rem !important;
  }

  ._Dfzpa_p-sm-2 {
    padding: .5rem !important;
  }

  ._Dfzpa_p-sm-3 {
    padding: 1rem !important;
  }

  ._Dfzpa_p-sm-4 {
    padding: 1.5rem !important;
  }

  ._Dfzpa_p-sm-5 {
    padding: 3rem !important;
  }

  ._Dfzpa_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._Dfzpa_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._Dfzpa_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._Dfzpa_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._Dfzpa_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._Dfzpa_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Dfzpa_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_pt-sm-0 {
    padding-top: 0 !important;
  }

  ._Dfzpa_pt-sm-1 {
    padding-top: .25rem !important;
  }

  ._Dfzpa_pt-sm-2 {
    padding-top: .5rem !important;
  }

  ._Dfzpa_pt-sm-3 {
    padding-top: 1rem !important;
  }

  ._Dfzpa_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  ._Dfzpa_pt-sm-5 {
    padding-top: 3rem !important;
  }

  ._Dfzpa_pe-sm-0 {
    padding-right: 0 !important;
  }

  ._Dfzpa_pe-sm-1 {
    padding-right: .25rem !important;
  }

  ._Dfzpa_pe-sm-2 {
    padding-right: .5rem !important;
  }

  ._Dfzpa_pe-sm-3 {
    padding-right: 1rem !important;
  }

  ._Dfzpa_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_pe-sm-5 {
    padding-right: 3rem !important;
  }

  ._Dfzpa_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  ._Dfzpa_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_ps-sm-0 {
    padding-left: 0 !important;
  }

  ._Dfzpa_ps-sm-1 {
    padding-left: .25rem !important;
  }

  ._Dfzpa_ps-sm-2 {
    padding-left: .5rem !important;
  }

  ._Dfzpa_ps-sm-3 {
    padding-left: 1rem !important;
  }

  ._Dfzpa_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  ._Dfzpa_ps-sm-5 {
    padding-left: 3rem !important;
  }

  ._Dfzpa_gap-sm-0 {
    gap: 0 !important;
  }

  ._Dfzpa_gap-sm-1 {
    gap: .25rem !important;
  }

  ._Dfzpa_gap-sm-2 {
    gap: .5rem !important;
  }

  ._Dfzpa_gap-sm-3 {
    gap: 1rem !important;
  }

  ._Dfzpa_gap-sm-4 {
    gap: 1.5rem !important;
  }

  ._Dfzpa_gap-sm-5 {
    gap: 3rem !important;
  }

  ._Dfzpa_text-sm-start {
    text-align: left !important;
  }

  ._Dfzpa_text-sm-end {
    text-align: right !important;
  }

  ._Dfzpa_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  ._Dfzpa_float-md-start {
    float: left !important;
  }

  ._Dfzpa_float-md-end {
    float: right !important;
  }

  ._Dfzpa_float-md-none {
    float: none !important;
  }

  ._Dfzpa_d-md-inline {
    display: inline !important;
  }

  ._Dfzpa_d-md-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-md-block {
    display: block !important;
  }

  ._Dfzpa_d-md-grid {
    display: grid !important;
  }

  ._Dfzpa_d-md-table {
    display: table !important;
  }

  ._Dfzpa_d-md-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-md-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-md-flex {
    display: flex !important;
  }

  ._Dfzpa_d-md-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-md-none {
    display: none !important;
  }

  ._Dfzpa_flex-md-fill {
    flex: auto !important;
  }

  ._Dfzpa_flex-md-row {
    flex-direction: row !important;
  }

  ._Dfzpa_flex-md-column {
    flex-direction: column !important;
  }

  ._Dfzpa_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._Dfzpa_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._Dfzpa_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  ._Dfzpa_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  ._Dfzpa_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._Dfzpa_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._Dfzpa_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  ._Dfzpa_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  ._Dfzpa_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._Dfzpa_justify-content-md-start {
    justify-content: flex-start !important;
  }

  ._Dfzpa_justify-content-md-end {
    justify-content: flex-end !important;
  }

  ._Dfzpa_justify-content-md-center {
    justify-content: center !important;
  }

  ._Dfzpa_justify-content-md-between {
    justify-content: space-between !important;
  }

  ._Dfzpa_justify-content-md-around {
    justify-content: space-around !important;
  }

  ._Dfzpa_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  ._Dfzpa_align-items-md-start {
    align-items: flex-start !important;
  }

  ._Dfzpa_align-items-md-end {
    align-items: flex-end !important;
  }

  ._Dfzpa_align-items-md-center {
    align-items: center !important;
  }

  ._Dfzpa_align-items-md-baseline {
    align-items: baseline !important;
  }

  ._Dfzpa_align-items-md-stretch {
    align-items: stretch !important;
  }

  ._Dfzpa_align-content-md-start {
    align-content: flex-start !important;
  }

  ._Dfzpa_align-content-md-end {
    align-content: flex-end !important;
  }

  ._Dfzpa_align-content-md-center {
    align-content: center !important;
  }

  ._Dfzpa_align-content-md-between {
    align-content: space-between !important;
  }

  ._Dfzpa_align-content-md-around {
    align-content: space-around !important;
  }

  ._Dfzpa_align-content-md-stretch {
    align-content: stretch !important;
  }

  ._Dfzpa_align-self-md-auto {
    align-self: auto !important;
  }

  ._Dfzpa_align-self-md-start {
    align-self: flex-start !important;
  }

  ._Dfzpa_align-self-md-end {
    align-self: flex-end !important;
  }

  ._Dfzpa_align-self-md-center {
    align-self: center !important;
  }

  ._Dfzpa_align-self-md-baseline {
    align-self: baseline !important;
  }

  ._Dfzpa_align-self-md-stretch {
    align-self: stretch !important;
  }

  ._Dfzpa_order-md-first {
    order: -1 !important;
  }

  ._Dfzpa_order-md-0 {
    order: 0 !important;
  }

  ._Dfzpa_order-md-1 {
    order: 1 !important;
  }

  ._Dfzpa_order-md-2 {
    order: 2 !important;
  }

  ._Dfzpa_order-md-3 {
    order: 3 !important;
  }

  ._Dfzpa_order-md-4 {
    order: 4 !important;
  }

  ._Dfzpa_order-md-5 {
    order: 5 !important;
  }

  ._Dfzpa_order-md-last {
    order: 6 !important;
  }

  ._Dfzpa_m-md-0 {
    margin: 0 !important;
  }

  ._Dfzpa_m-md-1 {
    margin: .25rem !important;
  }

  ._Dfzpa_m-md-2 {
    margin: .5rem !important;
  }

  ._Dfzpa_m-md-3 {
    margin: 1rem !important;
  }

  ._Dfzpa_m-md-4 {
    margin: 1.5rem !important;
  }

  ._Dfzpa_m-md-5 {
    margin: 3rem !important;
  }

  ._Dfzpa_m-md-auto {
    margin: auto !important;
  }

  ._Dfzpa_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._Dfzpa_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._Dfzpa_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._Dfzpa_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._Dfzpa_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._Dfzpa_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._Dfzpa_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._Dfzpa_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._Dfzpa_mt-md-0 {
    margin-top: 0 !important;
  }

  ._Dfzpa_mt-md-1 {
    margin-top: .25rem !important;
  }

  ._Dfzpa_mt-md-2 {
    margin-top: .5rem !important;
  }

  ._Dfzpa_mt-md-3 {
    margin-top: 1rem !important;
  }

  ._Dfzpa_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  ._Dfzpa_mt-md-5 {
    margin-top: 3rem !important;
  }

  ._Dfzpa_mt-md-auto {
    margin-top: auto !important;
  }

  ._Dfzpa_me-md-0 {
    margin-right: 0 !important;
  }

  ._Dfzpa_me-md-1 {
    margin-right: .25rem !important;
  }

  ._Dfzpa_me-md-2 {
    margin-right: .5rem !important;
  }

  ._Dfzpa_me-md-3 {
    margin-right: 1rem !important;
  }

  ._Dfzpa_me-md-4 {
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_me-md-5 {
    margin-right: 3rem !important;
  }

  ._Dfzpa_me-md-auto {
    margin-right: auto !important;
  }

  ._Dfzpa_mb-md-0 {
    margin-bottom: 0 !important;
  }

  ._Dfzpa_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_mb-md-auto {
    margin-bottom: auto !important;
  }

  ._Dfzpa_ms-md-0 {
    margin-left: 0 !important;
  }

  ._Dfzpa_ms-md-1 {
    margin-left: .25rem !important;
  }

  ._Dfzpa_ms-md-2 {
    margin-left: .5rem !important;
  }

  ._Dfzpa_ms-md-3 {
    margin-left: 1rem !important;
  }

  ._Dfzpa_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  ._Dfzpa_ms-md-5 {
    margin-left: 3rem !important;
  }

  ._Dfzpa_ms-md-auto {
    margin-left: auto !important;
  }

  ._Dfzpa_p-md-0 {
    padding: 0 !important;
  }

  ._Dfzpa_p-md-1 {
    padding: .25rem !important;
  }

  ._Dfzpa_p-md-2 {
    padding: .5rem !important;
  }

  ._Dfzpa_p-md-3 {
    padding: 1rem !important;
  }

  ._Dfzpa_p-md-4 {
    padding: 1.5rem !important;
  }

  ._Dfzpa_p-md-5 {
    padding: 3rem !important;
  }

  ._Dfzpa_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._Dfzpa_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._Dfzpa_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._Dfzpa_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._Dfzpa_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._Dfzpa_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Dfzpa_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_pt-md-0 {
    padding-top: 0 !important;
  }

  ._Dfzpa_pt-md-1 {
    padding-top: .25rem !important;
  }

  ._Dfzpa_pt-md-2 {
    padding-top: .5rem !important;
  }

  ._Dfzpa_pt-md-3 {
    padding-top: 1rem !important;
  }

  ._Dfzpa_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  ._Dfzpa_pt-md-5 {
    padding-top: 3rem !important;
  }

  ._Dfzpa_pe-md-0 {
    padding-right: 0 !important;
  }

  ._Dfzpa_pe-md-1 {
    padding-right: .25rem !important;
  }

  ._Dfzpa_pe-md-2 {
    padding-right: .5rem !important;
  }

  ._Dfzpa_pe-md-3 {
    padding-right: 1rem !important;
  }

  ._Dfzpa_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_pe-md-5 {
    padding-right: 3rem !important;
  }

  ._Dfzpa_pb-md-0 {
    padding-bottom: 0 !important;
  }

  ._Dfzpa_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_ps-md-0 {
    padding-left: 0 !important;
  }

  ._Dfzpa_ps-md-1 {
    padding-left: .25rem !important;
  }

  ._Dfzpa_ps-md-2 {
    padding-left: .5rem !important;
  }

  ._Dfzpa_ps-md-3 {
    padding-left: 1rem !important;
  }

  ._Dfzpa_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  ._Dfzpa_ps-md-5 {
    padding-left: 3rem !important;
  }

  ._Dfzpa_gap-md-0 {
    gap: 0 !important;
  }

  ._Dfzpa_gap-md-1 {
    gap: .25rem !important;
  }

  ._Dfzpa_gap-md-2 {
    gap: .5rem !important;
  }

  ._Dfzpa_gap-md-3 {
    gap: 1rem !important;
  }

  ._Dfzpa_gap-md-4 {
    gap: 1.5rem !important;
  }

  ._Dfzpa_gap-md-5 {
    gap: 3rem !important;
  }

  ._Dfzpa_text-md-start {
    text-align: left !important;
  }

  ._Dfzpa_text-md-end {
    text-align: right !important;
  }

  ._Dfzpa_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  ._Dfzpa_float-lg-start {
    float: left !important;
  }

  ._Dfzpa_float-lg-end {
    float: right !important;
  }

  ._Dfzpa_float-lg-none {
    float: none !important;
  }

  ._Dfzpa_d-lg-inline {
    display: inline !important;
  }

  ._Dfzpa_d-lg-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-lg-block {
    display: block !important;
  }

  ._Dfzpa_d-lg-grid {
    display: grid !important;
  }

  ._Dfzpa_d-lg-table {
    display: table !important;
  }

  ._Dfzpa_d-lg-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-lg-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-lg-flex {
    display: flex !important;
  }

  ._Dfzpa_d-lg-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-lg-none {
    display: none !important;
  }

  ._Dfzpa_flex-lg-fill {
    flex: auto !important;
  }

  ._Dfzpa_flex-lg-row {
    flex-direction: row !important;
  }

  ._Dfzpa_flex-lg-column {
    flex-direction: column !important;
  }

  ._Dfzpa_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._Dfzpa_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._Dfzpa_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  ._Dfzpa_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  ._Dfzpa_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._Dfzpa_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._Dfzpa_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  ._Dfzpa_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  ._Dfzpa_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._Dfzpa_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  ._Dfzpa_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  ._Dfzpa_justify-content-lg-center {
    justify-content: center !important;
  }

  ._Dfzpa_justify-content-lg-between {
    justify-content: space-between !important;
  }

  ._Dfzpa_justify-content-lg-around {
    justify-content: space-around !important;
  }

  ._Dfzpa_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  ._Dfzpa_align-items-lg-start {
    align-items: flex-start !important;
  }

  ._Dfzpa_align-items-lg-end {
    align-items: flex-end !important;
  }

  ._Dfzpa_align-items-lg-center {
    align-items: center !important;
  }

  ._Dfzpa_align-items-lg-baseline {
    align-items: baseline !important;
  }

  ._Dfzpa_align-items-lg-stretch {
    align-items: stretch !important;
  }

  ._Dfzpa_align-content-lg-start {
    align-content: flex-start !important;
  }

  ._Dfzpa_align-content-lg-end {
    align-content: flex-end !important;
  }

  ._Dfzpa_align-content-lg-center {
    align-content: center !important;
  }

  ._Dfzpa_align-content-lg-between {
    align-content: space-between !important;
  }

  ._Dfzpa_align-content-lg-around {
    align-content: space-around !important;
  }

  ._Dfzpa_align-content-lg-stretch {
    align-content: stretch !important;
  }

  ._Dfzpa_align-self-lg-auto {
    align-self: auto !important;
  }

  ._Dfzpa_align-self-lg-start {
    align-self: flex-start !important;
  }

  ._Dfzpa_align-self-lg-end {
    align-self: flex-end !important;
  }

  ._Dfzpa_align-self-lg-center {
    align-self: center !important;
  }

  ._Dfzpa_align-self-lg-baseline {
    align-self: baseline !important;
  }

  ._Dfzpa_align-self-lg-stretch {
    align-self: stretch !important;
  }

  ._Dfzpa_order-lg-first {
    order: -1 !important;
  }

  ._Dfzpa_order-lg-0 {
    order: 0 !important;
  }

  ._Dfzpa_order-lg-1 {
    order: 1 !important;
  }

  ._Dfzpa_order-lg-2 {
    order: 2 !important;
  }

  ._Dfzpa_order-lg-3 {
    order: 3 !important;
  }

  ._Dfzpa_order-lg-4 {
    order: 4 !important;
  }

  ._Dfzpa_order-lg-5 {
    order: 5 !important;
  }

  ._Dfzpa_order-lg-last {
    order: 6 !important;
  }

  ._Dfzpa_m-lg-0 {
    margin: 0 !important;
  }

  ._Dfzpa_m-lg-1 {
    margin: .25rem !important;
  }

  ._Dfzpa_m-lg-2 {
    margin: .5rem !important;
  }

  ._Dfzpa_m-lg-3 {
    margin: 1rem !important;
  }

  ._Dfzpa_m-lg-4 {
    margin: 1.5rem !important;
  }

  ._Dfzpa_m-lg-5 {
    margin: 3rem !important;
  }

  ._Dfzpa_m-lg-auto {
    margin: auto !important;
  }

  ._Dfzpa_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._Dfzpa_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._Dfzpa_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._Dfzpa_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._Dfzpa_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._Dfzpa_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._Dfzpa_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._Dfzpa_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._Dfzpa_mt-lg-0 {
    margin-top: 0 !important;
  }

  ._Dfzpa_mt-lg-1 {
    margin-top: .25rem !important;
  }

  ._Dfzpa_mt-lg-2 {
    margin-top: .5rem !important;
  }

  ._Dfzpa_mt-lg-3 {
    margin-top: 1rem !important;
  }

  ._Dfzpa_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  ._Dfzpa_mt-lg-5 {
    margin-top: 3rem !important;
  }

  ._Dfzpa_mt-lg-auto {
    margin-top: auto !important;
  }

  ._Dfzpa_me-lg-0 {
    margin-right: 0 !important;
  }

  ._Dfzpa_me-lg-1 {
    margin-right: .25rem !important;
  }

  ._Dfzpa_me-lg-2 {
    margin-right: .5rem !important;
  }

  ._Dfzpa_me-lg-3 {
    margin-right: 1rem !important;
  }

  ._Dfzpa_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_me-lg-5 {
    margin-right: 3rem !important;
  }

  ._Dfzpa_me-lg-auto {
    margin-right: auto !important;
  }

  ._Dfzpa_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  ._Dfzpa_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_mb-lg-auto {
    margin-bottom: auto !important;
  }

  ._Dfzpa_ms-lg-0 {
    margin-left: 0 !important;
  }

  ._Dfzpa_ms-lg-1 {
    margin-left: .25rem !important;
  }

  ._Dfzpa_ms-lg-2 {
    margin-left: .5rem !important;
  }

  ._Dfzpa_ms-lg-3 {
    margin-left: 1rem !important;
  }

  ._Dfzpa_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  ._Dfzpa_ms-lg-5 {
    margin-left: 3rem !important;
  }

  ._Dfzpa_ms-lg-auto {
    margin-left: auto !important;
  }

  ._Dfzpa_p-lg-0 {
    padding: 0 !important;
  }

  ._Dfzpa_p-lg-1 {
    padding: .25rem !important;
  }

  ._Dfzpa_p-lg-2 {
    padding: .5rem !important;
  }

  ._Dfzpa_p-lg-3 {
    padding: 1rem !important;
  }

  ._Dfzpa_p-lg-4 {
    padding: 1.5rem !important;
  }

  ._Dfzpa_p-lg-5 {
    padding: 3rem !important;
  }

  ._Dfzpa_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._Dfzpa_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._Dfzpa_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._Dfzpa_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._Dfzpa_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._Dfzpa_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Dfzpa_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_pt-lg-0 {
    padding-top: 0 !important;
  }

  ._Dfzpa_pt-lg-1 {
    padding-top: .25rem !important;
  }

  ._Dfzpa_pt-lg-2 {
    padding-top: .5rem !important;
  }

  ._Dfzpa_pt-lg-3 {
    padding-top: 1rem !important;
  }

  ._Dfzpa_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  ._Dfzpa_pt-lg-5 {
    padding-top: 3rem !important;
  }

  ._Dfzpa_pe-lg-0 {
    padding-right: 0 !important;
  }

  ._Dfzpa_pe-lg-1 {
    padding-right: .25rem !important;
  }

  ._Dfzpa_pe-lg-2 {
    padding-right: .5rem !important;
  }

  ._Dfzpa_pe-lg-3 {
    padding-right: 1rem !important;
  }

  ._Dfzpa_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_pe-lg-5 {
    padding-right: 3rem !important;
  }

  ._Dfzpa_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  ._Dfzpa_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_ps-lg-0 {
    padding-left: 0 !important;
  }

  ._Dfzpa_ps-lg-1 {
    padding-left: .25rem !important;
  }

  ._Dfzpa_ps-lg-2 {
    padding-left: .5rem !important;
  }

  ._Dfzpa_ps-lg-3 {
    padding-left: 1rem !important;
  }

  ._Dfzpa_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  ._Dfzpa_ps-lg-5 {
    padding-left: 3rem !important;
  }

  ._Dfzpa_gap-lg-0 {
    gap: 0 !important;
  }

  ._Dfzpa_gap-lg-1 {
    gap: .25rem !important;
  }

  ._Dfzpa_gap-lg-2 {
    gap: .5rem !important;
  }

  ._Dfzpa_gap-lg-3 {
    gap: 1rem !important;
  }

  ._Dfzpa_gap-lg-4 {
    gap: 1.5rem !important;
  }

  ._Dfzpa_gap-lg-5 {
    gap: 3rem !important;
  }

  ._Dfzpa_text-lg-start {
    text-align: left !important;
  }

  ._Dfzpa_text-lg-end {
    text-align: right !important;
  }

  ._Dfzpa_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_float-xl-start {
    float: left !important;
  }

  ._Dfzpa_float-xl-end {
    float: right !important;
  }

  ._Dfzpa_float-xl-none {
    float: none !important;
  }

  ._Dfzpa_d-xl-inline {
    display: inline !important;
  }

  ._Dfzpa_d-xl-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-xl-block {
    display: block !important;
  }

  ._Dfzpa_d-xl-grid {
    display: grid !important;
  }

  ._Dfzpa_d-xl-table {
    display: table !important;
  }

  ._Dfzpa_d-xl-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-xl-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-xl-flex {
    display: flex !important;
  }

  ._Dfzpa_d-xl-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-xl-none {
    display: none !important;
  }

  ._Dfzpa_flex-xl-fill {
    flex: auto !important;
  }

  ._Dfzpa_flex-xl-row {
    flex-direction: row !important;
  }

  ._Dfzpa_flex-xl-column {
    flex-direction: column !important;
  }

  ._Dfzpa_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._Dfzpa_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._Dfzpa_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  ._Dfzpa_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  ._Dfzpa_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._Dfzpa_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._Dfzpa_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  ._Dfzpa_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._Dfzpa_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._Dfzpa_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  ._Dfzpa_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  ._Dfzpa_justify-content-xl-center {
    justify-content: center !important;
  }

  ._Dfzpa_justify-content-xl-between {
    justify-content: space-between !important;
  }

  ._Dfzpa_justify-content-xl-around {
    justify-content: space-around !important;
  }

  ._Dfzpa_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  ._Dfzpa_align-items-xl-start {
    align-items: flex-start !important;
  }

  ._Dfzpa_align-items-xl-end {
    align-items: flex-end !important;
  }

  ._Dfzpa_align-items-xl-center {
    align-items: center !important;
  }

  ._Dfzpa_align-items-xl-baseline {
    align-items: baseline !important;
  }

  ._Dfzpa_align-items-xl-stretch {
    align-items: stretch !important;
  }

  ._Dfzpa_align-content-xl-start {
    align-content: flex-start !important;
  }

  ._Dfzpa_align-content-xl-end {
    align-content: flex-end !important;
  }

  ._Dfzpa_align-content-xl-center {
    align-content: center !important;
  }

  ._Dfzpa_align-content-xl-between {
    align-content: space-between !important;
  }

  ._Dfzpa_align-content-xl-around {
    align-content: space-around !important;
  }

  ._Dfzpa_align-content-xl-stretch {
    align-content: stretch !important;
  }

  ._Dfzpa_align-self-xl-auto {
    align-self: auto !important;
  }

  ._Dfzpa_align-self-xl-start {
    align-self: flex-start !important;
  }

  ._Dfzpa_align-self-xl-end {
    align-self: flex-end !important;
  }

  ._Dfzpa_align-self-xl-center {
    align-self: center !important;
  }

  ._Dfzpa_align-self-xl-baseline {
    align-self: baseline !important;
  }

  ._Dfzpa_align-self-xl-stretch {
    align-self: stretch !important;
  }

  ._Dfzpa_order-xl-first {
    order: -1 !important;
  }

  ._Dfzpa_order-xl-0 {
    order: 0 !important;
  }

  ._Dfzpa_order-xl-1 {
    order: 1 !important;
  }

  ._Dfzpa_order-xl-2 {
    order: 2 !important;
  }

  ._Dfzpa_order-xl-3 {
    order: 3 !important;
  }

  ._Dfzpa_order-xl-4 {
    order: 4 !important;
  }

  ._Dfzpa_order-xl-5 {
    order: 5 !important;
  }

  ._Dfzpa_order-xl-last {
    order: 6 !important;
  }

  ._Dfzpa_m-xl-0 {
    margin: 0 !important;
  }

  ._Dfzpa_m-xl-1 {
    margin: .25rem !important;
  }

  ._Dfzpa_m-xl-2 {
    margin: .5rem !important;
  }

  ._Dfzpa_m-xl-3 {
    margin: 1rem !important;
  }

  ._Dfzpa_m-xl-4 {
    margin: 1.5rem !important;
  }

  ._Dfzpa_m-xl-5 {
    margin: 3rem !important;
  }

  ._Dfzpa_m-xl-auto {
    margin: auto !important;
  }

  ._Dfzpa_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._Dfzpa_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._Dfzpa_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._Dfzpa_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._Dfzpa_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._Dfzpa_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._Dfzpa_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._Dfzpa_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._Dfzpa_mt-xl-0 {
    margin-top: 0 !important;
  }

  ._Dfzpa_mt-xl-1 {
    margin-top: .25rem !important;
  }

  ._Dfzpa_mt-xl-2 {
    margin-top: .5rem !important;
  }

  ._Dfzpa_mt-xl-3 {
    margin-top: 1rem !important;
  }

  ._Dfzpa_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  ._Dfzpa_mt-xl-5 {
    margin-top: 3rem !important;
  }

  ._Dfzpa_mt-xl-auto {
    margin-top: auto !important;
  }

  ._Dfzpa_me-xl-0 {
    margin-right: 0 !important;
  }

  ._Dfzpa_me-xl-1 {
    margin-right: .25rem !important;
  }

  ._Dfzpa_me-xl-2 {
    margin-right: .5rem !important;
  }

  ._Dfzpa_me-xl-3 {
    margin-right: 1rem !important;
  }

  ._Dfzpa_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_me-xl-5 {
    margin-right: 3rem !important;
  }

  ._Dfzpa_me-xl-auto {
    margin-right: auto !important;
  }

  ._Dfzpa_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  ._Dfzpa_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_mb-xl-auto {
    margin-bottom: auto !important;
  }

  ._Dfzpa_ms-xl-0 {
    margin-left: 0 !important;
  }

  ._Dfzpa_ms-xl-1 {
    margin-left: .25rem !important;
  }

  ._Dfzpa_ms-xl-2 {
    margin-left: .5rem !important;
  }

  ._Dfzpa_ms-xl-3 {
    margin-left: 1rem !important;
  }

  ._Dfzpa_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  ._Dfzpa_ms-xl-5 {
    margin-left: 3rem !important;
  }

  ._Dfzpa_ms-xl-auto {
    margin-left: auto !important;
  }

  ._Dfzpa_p-xl-0 {
    padding: 0 !important;
  }

  ._Dfzpa_p-xl-1 {
    padding: .25rem !important;
  }

  ._Dfzpa_p-xl-2 {
    padding: .5rem !important;
  }

  ._Dfzpa_p-xl-3 {
    padding: 1rem !important;
  }

  ._Dfzpa_p-xl-4 {
    padding: 1.5rem !important;
  }

  ._Dfzpa_p-xl-5 {
    padding: 3rem !important;
  }

  ._Dfzpa_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._Dfzpa_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._Dfzpa_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._Dfzpa_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._Dfzpa_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._Dfzpa_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Dfzpa_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_pt-xl-0 {
    padding-top: 0 !important;
  }

  ._Dfzpa_pt-xl-1 {
    padding-top: .25rem !important;
  }

  ._Dfzpa_pt-xl-2 {
    padding-top: .5rem !important;
  }

  ._Dfzpa_pt-xl-3 {
    padding-top: 1rem !important;
  }

  ._Dfzpa_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  ._Dfzpa_pt-xl-5 {
    padding-top: 3rem !important;
  }

  ._Dfzpa_pe-xl-0 {
    padding-right: 0 !important;
  }

  ._Dfzpa_pe-xl-1 {
    padding-right: .25rem !important;
  }

  ._Dfzpa_pe-xl-2 {
    padding-right: .5rem !important;
  }

  ._Dfzpa_pe-xl-3 {
    padding-right: 1rem !important;
  }

  ._Dfzpa_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_pe-xl-5 {
    padding-right: 3rem !important;
  }

  ._Dfzpa_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  ._Dfzpa_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_ps-xl-0 {
    padding-left: 0 !important;
  }

  ._Dfzpa_ps-xl-1 {
    padding-left: .25rem !important;
  }

  ._Dfzpa_ps-xl-2 {
    padding-left: .5rem !important;
  }

  ._Dfzpa_ps-xl-3 {
    padding-left: 1rem !important;
  }

  ._Dfzpa_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  ._Dfzpa_ps-xl-5 {
    padding-left: 3rem !important;
  }

  ._Dfzpa_gap-xl-0 {
    gap: 0 !important;
  }

  ._Dfzpa_gap-xl-1 {
    gap: .25rem !important;
  }

  ._Dfzpa_gap-xl-2 {
    gap: .5rem !important;
  }

  ._Dfzpa_gap-xl-3 {
    gap: 1rem !important;
  }

  ._Dfzpa_gap-xl-4 {
    gap: 1.5rem !important;
  }

  ._Dfzpa_gap-xl-5 {
    gap: 3rem !important;
  }

  ._Dfzpa_text-xl-start {
    text-align: left !important;
  }

  ._Dfzpa_text-xl-end {
    text-align: right !important;
  }

  ._Dfzpa_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  ._Dfzpa_float-xxl-start {
    float: left !important;
  }

  ._Dfzpa_float-xxl-end {
    float: right !important;
  }

  ._Dfzpa_float-xxl-none {
    float: none !important;
  }

  ._Dfzpa_d-xxl-inline {
    display: inline !important;
  }

  ._Dfzpa_d-xxl-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-xxl-block {
    display: block !important;
  }

  ._Dfzpa_d-xxl-grid {
    display: grid !important;
  }

  ._Dfzpa_d-xxl-table {
    display: table !important;
  }

  ._Dfzpa_d-xxl-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-xxl-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-xxl-flex {
    display: flex !important;
  }

  ._Dfzpa_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-xxl-none {
    display: none !important;
  }

  ._Dfzpa_flex-xxl-fill {
    flex: auto !important;
  }

  ._Dfzpa_flex-xxl-row {
    flex-direction: row !important;
  }

  ._Dfzpa_flex-xxl-column {
    flex-direction: column !important;
  }

  ._Dfzpa_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._Dfzpa_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._Dfzpa_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  ._Dfzpa_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  ._Dfzpa_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._Dfzpa_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._Dfzpa_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  ._Dfzpa_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._Dfzpa_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._Dfzpa_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  ._Dfzpa_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  ._Dfzpa_justify-content-xxl-center {
    justify-content: center !important;
  }

  ._Dfzpa_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  ._Dfzpa_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  ._Dfzpa_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  ._Dfzpa_align-items-xxl-start {
    align-items: flex-start !important;
  }

  ._Dfzpa_align-items-xxl-end {
    align-items: flex-end !important;
  }

  ._Dfzpa_align-items-xxl-center {
    align-items: center !important;
  }

  ._Dfzpa_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  ._Dfzpa_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  ._Dfzpa_align-content-xxl-start {
    align-content: flex-start !important;
  }

  ._Dfzpa_align-content-xxl-end {
    align-content: flex-end !important;
  }

  ._Dfzpa_align-content-xxl-center {
    align-content: center !important;
  }

  ._Dfzpa_align-content-xxl-between {
    align-content: space-between !important;
  }

  ._Dfzpa_align-content-xxl-around {
    align-content: space-around !important;
  }

  ._Dfzpa_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  ._Dfzpa_align-self-xxl-auto {
    align-self: auto !important;
  }

  ._Dfzpa_align-self-xxl-start {
    align-self: flex-start !important;
  }

  ._Dfzpa_align-self-xxl-end {
    align-self: flex-end !important;
  }

  ._Dfzpa_align-self-xxl-center {
    align-self: center !important;
  }

  ._Dfzpa_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  ._Dfzpa_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  ._Dfzpa_order-xxl-first {
    order: -1 !important;
  }

  ._Dfzpa_order-xxl-0 {
    order: 0 !important;
  }

  ._Dfzpa_order-xxl-1 {
    order: 1 !important;
  }

  ._Dfzpa_order-xxl-2 {
    order: 2 !important;
  }

  ._Dfzpa_order-xxl-3 {
    order: 3 !important;
  }

  ._Dfzpa_order-xxl-4 {
    order: 4 !important;
  }

  ._Dfzpa_order-xxl-5 {
    order: 5 !important;
  }

  ._Dfzpa_order-xxl-last {
    order: 6 !important;
  }

  ._Dfzpa_m-xxl-0 {
    margin: 0 !important;
  }

  ._Dfzpa_m-xxl-1 {
    margin: .25rem !important;
  }

  ._Dfzpa_m-xxl-2 {
    margin: .5rem !important;
  }

  ._Dfzpa_m-xxl-3 {
    margin: 1rem !important;
  }

  ._Dfzpa_m-xxl-4 {
    margin: 1.5rem !important;
  }

  ._Dfzpa_m-xxl-5 {
    margin: 3rem !important;
  }

  ._Dfzpa_m-xxl-auto {
    margin: auto !important;
  }

  ._Dfzpa_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._Dfzpa_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._Dfzpa_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._Dfzpa_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._Dfzpa_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._Dfzpa_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._Dfzpa_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._Dfzpa_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._Dfzpa_mt-xxl-0 {
    margin-top: 0 !important;
  }

  ._Dfzpa_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  ._Dfzpa_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  ._Dfzpa_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  ._Dfzpa_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  ._Dfzpa_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  ._Dfzpa_mt-xxl-auto {
    margin-top: auto !important;
  }

  ._Dfzpa_me-xxl-0 {
    margin-right: 0 !important;
  }

  ._Dfzpa_me-xxl-1 {
    margin-right: .25rem !important;
  }

  ._Dfzpa_me-xxl-2 {
    margin-right: .5rem !important;
  }

  ._Dfzpa_me-xxl-3 {
    margin-right: 1rem !important;
  }

  ._Dfzpa_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  ._Dfzpa_me-xxl-5 {
    margin-right: 3rem !important;
  }

  ._Dfzpa_me-xxl-auto {
    margin-right: auto !important;
  }

  ._Dfzpa_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  ._Dfzpa_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  ._Dfzpa_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  ._Dfzpa_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  ._Dfzpa_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._Dfzpa_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  ._Dfzpa_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  ._Dfzpa_ms-xxl-0 {
    margin-left: 0 !important;
  }

  ._Dfzpa_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  ._Dfzpa_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  ._Dfzpa_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  ._Dfzpa_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  ._Dfzpa_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  ._Dfzpa_ms-xxl-auto {
    margin-left: auto !important;
  }

  ._Dfzpa_p-xxl-0 {
    padding: 0 !important;
  }

  ._Dfzpa_p-xxl-1 {
    padding: .25rem !important;
  }

  ._Dfzpa_p-xxl-2 {
    padding: .5rem !important;
  }

  ._Dfzpa_p-xxl-3 {
    padding: 1rem !important;
  }

  ._Dfzpa_p-xxl-4 {
    padding: 1.5rem !important;
  }

  ._Dfzpa_p-xxl-5 {
    padding: 3rem !important;
  }

  ._Dfzpa_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._Dfzpa_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._Dfzpa_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._Dfzpa_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._Dfzpa_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._Dfzpa_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Dfzpa_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_pt-xxl-0 {
    padding-top: 0 !important;
  }

  ._Dfzpa_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  ._Dfzpa_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  ._Dfzpa_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  ._Dfzpa_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  ._Dfzpa_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  ._Dfzpa_pe-xxl-0 {
    padding-right: 0 !important;
  }

  ._Dfzpa_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  ._Dfzpa_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  ._Dfzpa_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  ._Dfzpa_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  ._Dfzpa_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  ._Dfzpa_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  ._Dfzpa_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  ._Dfzpa_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  ._Dfzpa_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  ._Dfzpa_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._Dfzpa_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  ._Dfzpa_ps-xxl-0 {
    padding-left: 0 !important;
  }

  ._Dfzpa_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  ._Dfzpa_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  ._Dfzpa_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  ._Dfzpa_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  ._Dfzpa_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  ._Dfzpa_gap-xxl-0 {
    gap: 0 !important;
  }

  ._Dfzpa_gap-xxl-1 {
    gap: .25rem !important;
  }

  ._Dfzpa_gap-xxl-2 {
    gap: .5rem !important;
  }

  ._Dfzpa_gap-xxl-3 {
    gap: 1rem !important;
  }

  ._Dfzpa_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  ._Dfzpa_gap-xxl-5 {
    gap: 3rem !important;
  }

  ._Dfzpa_text-xxl-start {
    text-align: left !important;
  }

  ._Dfzpa_text-xxl-end {
    text-align: right !important;
  }

  ._Dfzpa_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._Dfzpa_fs-1 {
    font-size: 2.5rem !important;
  }

  ._Dfzpa_fs-2 {
    font-size: 2rem !important;
  }

  ._Dfzpa_fs-3 {
    font-size: 1.75rem !important;
  }

  ._Dfzpa_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  ._Dfzpa_d-print-inline {
    display: inline !important;
  }

  ._Dfzpa_d-print-inline-block {
    display: inline-block !important;
  }

  ._Dfzpa_d-print-block {
    display: block !important;
  }

  ._Dfzpa_d-print-grid {
    display: grid !important;
  }

  ._Dfzpa_d-print-table {
    display: table !important;
  }

  ._Dfzpa_d-print-table-row {
    display: table-row !important;
  }

  ._Dfzpa_d-print-table-cell {
    display: table-cell !important;
  }

  ._Dfzpa_d-print-flex {
    display: flex !important;
  }

  ._Dfzpa_d-print-inline-flex {
    display: inline-flex !important;
  }

  ._Dfzpa_d-print-none {
    display: none !important;
  }
}

._Dfzpa_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

._Dfzpa_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

._Dfzpa_nav-tabs ._Dfzpa_nav-item ._Dfzpa_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

._Dfzpa_nav-tabs ._Dfzpa_nav-item ._Dfzpa_nav-link._Dfzpa_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

._Dfzpa_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#_Dfzpa_root {
  height: 100%;
}

._Dfzpa_highcharts-credits {
  display: none !important;
}

h3, ._Dfzpa_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

._Dfzpa_modal ._Dfzpa_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table._Dfzpa_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table._Dfzpa_table thead tr th._Dfzpa_col-actions {
  width: 110px !important;
}

table._Dfzpa_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table._Dfzpa_table tbody tr._Dfzpa_deleting {
  background-color: #ff323233 !important;
}

._Dfzpa_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

._Dfzpa_btn-sm, ._Dfzpa_btn-group-sm > ._Dfzpa_btn {
  font-size: .6rem !important;
}

._Dfzpa_modal {
  background-color: #0a0a0a4d;
}

._Dfzpa_btn:focus {
  box-shadow: none !important;
}

._Dfzpa_content-filter ._Dfzpa_react-datepicker-wrapper {
  width: auto !important;
}

._Dfzpa_content-filter ._Dfzpa_react-datepicker-wrapper ._Dfzpa_form-control {
  font-size: .85rem !important;
}

._Dfzpa_content-filter ._Dfzpa_input-group-text, ._Dfzpa_content-filter ._Dfzpa_btn {
  height: 2.15rem !important;
}

._Dfzpa_content-filter ._Dfzpa_btn {
  border-color: #ccc;
}

._Dfzpa_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

._Dfzpa_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

._Dfzpa_table > :not(:first-child) {
  border-top: unset !important;
}

._Dfzpa_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

._Dfzpa_highcharts-series._Dfzpa_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

._Dfzpa_expandable {
  transition: all .5s;
}

._Dfzpa_expandable > ._Dfzpa_expander-icon {
  cursor: pointer;
}

._Dfzpa_expandable > ._Dfzpa_expander-icon:hover {
  color: #328cfa66;
}

._Dfzpa_expandable._Dfzpa_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  ._Dfzpa_modal ._Dfzpa_modal-xl {
    width: 100% !important;
  }

  ._Dfzpa_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

._Dfzpa_d-none, ._Dfzpa_d-block {
  transition: all .5s;
}

._Dfzpa_inputGroup label {
  color: #000;
  flex: none;
  order: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

._Dfzpa_inputGroup input {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ededf5;
  border-radius: 8px;
  flex-direction: row;
  flex: none;
  order: 1;
  align-items: center;
  gap: 19px;
  padding: 10px 16px;
  display: flex;
}

._Dfzpa_inputGroup input::placeholder {
  color: #a5a6ae;
  flex: none;
  order: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .xwjyaW_h6, h5, .xwjyaW_h5, h4, .xwjyaW_h4, h3, .xwjyaW_h3, h2, .xwjyaW_h2, h1, .xwjyaW_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .xwjyaW_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .xwjyaW_h1 {
    font-size: 2.5rem;
  }
}

h2, .xwjyaW_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .xwjyaW_h2 {
    font-size: 2rem;
  }
}

h3, .xwjyaW_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .xwjyaW_h3 {
    font-size: 1.75rem;
  }
}

h4, .xwjyaW_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .xwjyaW_h4 {
    font-size: 1.5rem;
  }
}

h5, .xwjyaW_h5 {
  font-size: 1.25rem;
}

h6, .xwjyaW_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .xwjyaW_small {
  font-size: .875em;
}

mark, .xwjyaW_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.xwjyaW_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.xwjyaW_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-1 {
    font-size: 5rem;
  }
}

.xwjyaW_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-2 {
    font-size: 4.5rem;
  }
}

.xwjyaW_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-3 {
    font-size: 4rem;
  }
}

.xwjyaW_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-4 {
    font-size: 3.5rem;
  }
}

.xwjyaW_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-5 {
    font-size: 3rem;
  }
}

.xwjyaW_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .xwjyaW_display-6 {
    font-size: 2.5rem;
  }
}

.xwjyaW_list-unstyled, .xwjyaW_list-inline {
  padding-left: 0;
  list-style: none;
}

.xwjyaW_list-inline-item {
  display: inline-block;
}

.xwjyaW_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.xwjyaW_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.xwjyaW_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.xwjyaW_blockquote > :last-child {
  margin-bottom: 0;
}

.xwjyaW_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.xwjyaW_blockquote-footer:before {
  content: "— ";
}

.xwjyaW_img-fluid {
  max-width: 100%;
  height: auto;
}

.xwjyaW_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.xwjyaW_figure {
  display: inline-block;
}

.xwjyaW_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.xwjyaW_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.xwjyaW_container, .xwjyaW_container-fluid, .xwjyaW_container-xxl, .xwjyaW_container-xl, .xwjyaW_container-lg, .xwjyaW_container-md, .xwjyaW_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .xwjyaW_container-sm, .xwjyaW_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .xwjyaW_container-md, .xwjyaW_container-sm, .xwjyaW_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .xwjyaW_container-lg, .xwjyaW_container-md, .xwjyaW_container-sm, .xwjyaW_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_container-xl, .xwjyaW_container-lg, .xwjyaW_container-md, .xwjyaW_container-sm, .xwjyaW_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_container-xxl, .xwjyaW_container-xl, .xwjyaW_container-lg, .xwjyaW_container-md, .xwjyaW_container-sm, .xwjyaW_container {
    max-width: 1320px;
  }
}

.xwjyaW_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.xwjyaW_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.xwjyaW_col {
  flex: 1 0;
}

.xwjyaW_row-cols-auto > * {
  width: auto;
  flex: none;
}

.xwjyaW_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.xwjyaW_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.xwjyaW_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.xwjyaW_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.xwjyaW_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.xwjyaW_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.xwjyaW_col-auto {
  width: auto;
  flex: none;
}

.xwjyaW_col-1 {
  width: 8.33333%;
  flex: none;
}

.xwjyaW_col-2 {
  width: 16.6667%;
  flex: none;
}

.xwjyaW_col-3 {
  width: 25%;
  flex: none;
}

.xwjyaW_col-4 {
  width: 33.3333%;
  flex: none;
}

.xwjyaW_col-5 {
  width: 41.6667%;
  flex: none;
}

.xwjyaW_col-6 {
  width: 50%;
  flex: none;
}

.xwjyaW_col-7 {
  width: 58.3333%;
  flex: none;
}

.xwjyaW_col-8 {
  width: 66.6667%;
  flex: none;
}

.xwjyaW_col-9 {
  width: 75%;
  flex: none;
}

.xwjyaW_col-10 {
  width: 83.3333%;
  flex: none;
}

.xwjyaW_col-11 {
  width: 91.6667%;
  flex: none;
}

.xwjyaW_col-12 {
  width: 100%;
  flex: none;
}

.xwjyaW_offset-1 {
  margin-left: 8.33333%;
}

.xwjyaW_offset-2 {
  margin-left: 16.6667%;
}

.xwjyaW_offset-3 {
  margin-left: 25%;
}

.xwjyaW_offset-4 {
  margin-left: 33.3333%;
}

.xwjyaW_offset-5 {
  margin-left: 41.6667%;
}

.xwjyaW_offset-6 {
  margin-left: 50%;
}

.xwjyaW_offset-7 {
  margin-left: 58.3333%;
}

.xwjyaW_offset-8 {
  margin-left: 66.6667%;
}

.xwjyaW_offset-9 {
  margin-left: 75%;
}

.xwjyaW_offset-10 {
  margin-left: 83.3333%;
}

.xwjyaW_offset-11 {
  margin-left: 91.6667%;
}

.xwjyaW_g-0, .xwjyaW_gx-0 {
  --bs-gutter-x: 0;
}

.xwjyaW_g-0, .xwjyaW_gy-0 {
  --bs-gutter-y: 0;
}

.xwjyaW_g-1, .xwjyaW_gx-1 {
  --bs-gutter-x: .25rem;
}

.xwjyaW_g-1, .xwjyaW_gy-1 {
  --bs-gutter-y: .25rem;
}

.xwjyaW_g-2, .xwjyaW_gx-2 {
  --bs-gutter-x: .5rem;
}

.xwjyaW_g-2, .xwjyaW_gy-2 {
  --bs-gutter-y: .5rem;
}

.xwjyaW_g-3, .xwjyaW_gx-3 {
  --bs-gutter-x: 1rem;
}

.xwjyaW_g-3, .xwjyaW_gy-3 {
  --bs-gutter-y: 1rem;
}

.xwjyaW_g-4, .xwjyaW_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.xwjyaW_g-4, .xwjyaW_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.xwjyaW_g-5, .xwjyaW_gx-5 {
  --bs-gutter-x: 3rem;
}

.xwjyaW_g-5, .xwjyaW_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .xwjyaW_col-sm {
    flex: 1 0;
  }

  .xwjyaW_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .xwjyaW_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .xwjyaW_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .xwjyaW_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .xwjyaW_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .xwjyaW_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-sm-auto {
    width: auto;
    flex: none;
  }

  .xwjyaW_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .xwjyaW_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .xwjyaW_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .xwjyaW_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .xwjyaW_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .xwjyaW_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .xwjyaW_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .xwjyaW_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .xwjyaW_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .xwjyaW_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .xwjyaW_offset-sm-0 {
    margin-left: 0;
  }

  .xwjyaW_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .xwjyaW_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .xwjyaW_offset-sm-3 {
    margin-left: 25%;
  }

  .xwjyaW_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .xwjyaW_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .xwjyaW_offset-sm-6 {
    margin-left: 50%;
  }

  .xwjyaW_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .xwjyaW_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .xwjyaW_offset-sm-9 {
    margin-left: 75%;
  }

  .xwjyaW_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .xwjyaW_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .xwjyaW_g-sm-0, .xwjyaW_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .xwjyaW_g-sm-0, .xwjyaW_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .xwjyaW_g-sm-1, .xwjyaW_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .xwjyaW_g-sm-1, .xwjyaW_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .xwjyaW_g-sm-2, .xwjyaW_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .xwjyaW_g-sm-2, .xwjyaW_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .xwjyaW_g-sm-3, .xwjyaW_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .xwjyaW_g-sm-3, .xwjyaW_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .xwjyaW_g-sm-4, .xwjyaW_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .xwjyaW_g-sm-4, .xwjyaW_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .xwjyaW_g-sm-5, .xwjyaW_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .xwjyaW_g-sm-5, .xwjyaW_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .xwjyaW_col-md {
    flex: 1 0;
  }

  .xwjyaW_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .xwjyaW_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .xwjyaW_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .xwjyaW_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .xwjyaW_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .xwjyaW_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-md-auto {
    width: auto;
    flex: none;
  }

  .xwjyaW_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .xwjyaW_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-md-3 {
    width: 25%;
    flex: none;
  }

  .xwjyaW_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .xwjyaW_col-md-6 {
    width: 50%;
    flex: none;
  }

  .xwjyaW_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .xwjyaW_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .xwjyaW_col-md-9 {
    width: 75%;
    flex: none;
  }

  .xwjyaW_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .xwjyaW_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .xwjyaW_col-md-12 {
    width: 100%;
    flex: none;
  }

  .xwjyaW_offset-md-0 {
    margin-left: 0;
  }

  .xwjyaW_offset-md-1 {
    margin-left: 8.33333%;
  }

  .xwjyaW_offset-md-2 {
    margin-left: 16.6667%;
  }

  .xwjyaW_offset-md-3 {
    margin-left: 25%;
  }

  .xwjyaW_offset-md-4 {
    margin-left: 33.3333%;
  }

  .xwjyaW_offset-md-5 {
    margin-left: 41.6667%;
  }

  .xwjyaW_offset-md-6 {
    margin-left: 50%;
  }

  .xwjyaW_offset-md-7 {
    margin-left: 58.3333%;
  }

  .xwjyaW_offset-md-8 {
    margin-left: 66.6667%;
  }

  .xwjyaW_offset-md-9 {
    margin-left: 75%;
  }

  .xwjyaW_offset-md-10 {
    margin-left: 83.3333%;
  }

  .xwjyaW_offset-md-11 {
    margin-left: 91.6667%;
  }

  .xwjyaW_g-md-0, .xwjyaW_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .xwjyaW_g-md-0, .xwjyaW_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .xwjyaW_g-md-1, .xwjyaW_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .xwjyaW_g-md-1, .xwjyaW_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .xwjyaW_g-md-2, .xwjyaW_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .xwjyaW_g-md-2, .xwjyaW_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .xwjyaW_g-md-3, .xwjyaW_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .xwjyaW_g-md-3, .xwjyaW_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .xwjyaW_g-md-4, .xwjyaW_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .xwjyaW_g-md-4, .xwjyaW_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .xwjyaW_g-md-5, .xwjyaW_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .xwjyaW_g-md-5, .xwjyaW_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .xwjyaW_col-lg {
    flex: 1 0;
  }

  .xwjyaW_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .xwjyaW_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .xwjyaW_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .xwjyaW_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .xwjyaW_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .xwjyaW_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-lg-auto {
    width: auto;
    flex: none;
  }

  .xwjyaW_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .xwjyaW_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .xwjyaW_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .xwjyaW_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .xwjyaW_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .xwjyaW_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .xwjyaW_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .xwjyaW_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .xwjyaW_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .xwjyaW_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .xwjyaW_offset-lg-0 {
    margin-left: 0;
  }

  .xwjyaW_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .xwjyaW_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .xwjyaW_offset-lg-3 {
    margin-left: 25%;
  }

  .xwjyaW_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .xwjyaW_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .xwjyaW_offset-lg-6 {
    margin-left: 50%;
  }

  .xwjyaW_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .xwjyaW_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .xwjyaW_offset-lg-9 {
    margin-left: 75%;
  }

  .xwjyaW_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .xwjyaW_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .xwjyaW_g-lg-0, .xwjyaW_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .xwjyaW_g-lg-0, .xwjyaW_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .xwjyaW_g-lg-1, .xwjyaW_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .xwjyaW_g-lg-1, .xwjyaW_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .xwjyaW_g-lg-2, .xwjyaW_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .xwjyaW_g-lg-2, .xwjyaW_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .xwjyaW_g-lg-3, .xwjyaW_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .xwjyaW_g-lg-3, .xwjyaW_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .xwjyaW_g-lg-4, .xwjyaW_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .xwjyaW_g-lg-4, .xwjyaW_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .xwjyaW_g-lg-5, .xwjyaW_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .xwjyaW_g-lg-5, .xwjyaW_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_col-xl {
    flex: 1 0;
  }

  .xwjyaW_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .xwjyaW_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .xwjyaW_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .xwjyaW_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .xwjyaW_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .xwjyaW_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-xl-auto {
    width: auto;
    flex: none;
  }

  .xwjyaW_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .xwjyaW_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .xwjyaW_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .xwjyaW_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .xwjyaW_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .xwjyaW_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .xwjyaW_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .xwjyaW_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .xwjyaW_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .xwjyaW_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .xwjyaW_offset-xl-0 {
    margin-left: 0;
  }

  .xwjyaW_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .xwjyaW_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .xwjyaW_offset-xl-3 {
    margin-left: 25%;
  }

  .xwjyaW_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .xwjyaW_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .xwjyaW_offset-xl-6 {
    margin-left: 50%;
  }

  .xwjyaW_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .xwjyaW_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .xwjyaW_offset-xl-9 {
    margin-left: 75%;
  }

  .xwjyaW_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .xwjyaW_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .xwjyaW_g-xl-0, .xwjyaW_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .xwjyaW_g-xl-0, .xwjyaW_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .xwjyaW_g-xl-1, .xwjyaW_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .xwjyaW_g-xl-1, .xwjyaW_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .xwjyaW_g-xl-2, .xwjyaW_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .xwjyaW_g-xl-2, .xwjyaW_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .xwjyaW_g-xl-3, .xwjyaW_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .xwjyaW_g-xl-3, .xwjyaW_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .xwjyaW_g-xl-4, .xwjyaW_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .xwjyaW_g-xl-4, .xwjyaW_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .xwjyaW_g-xl-5, .xwjyaW_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .xwjyaW_g-xl-5, .xwjyaW_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_col-xxl {
    flex: 1 0;
  }

  .xwjyaW_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .xwjyaW_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .xwjyaW_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .xwjyaW_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .xwjyaW_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .xwjyaW_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .xwjyaW_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .xwjyaW_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .xwjyaW_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .xwjyaW_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .xwjyaW_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .xwjyaW_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .xwjyaW_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .xwjyaW_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .xwjyaW_offset-xxl-0 {
    margin-left: 0;
  }

  .xwjyaW_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .xwjyaW_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .xwjyaW_offset-xxl-3 {
    margin-left: 25%;
  }

  .xwjyaW_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .xwjyaW_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .xwjyaW_offset-xxl-6 {
    margin-left: 50%;
  }

  .xwjyaW_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .xwjyaW_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .xwjyaW_offset-xxl-9 {
    margin-left: 75%;
  }

  .xwjyaW_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .xwjyaW_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .xwjyaW_g-xxl-0, .xwjyaW_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .xwjyaW_g-xxl-0, .xwjyaW_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .xwjyaW_g-xxl-1, .xwjyaW_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .xwjyaW_g-xxl-1, .xwjyaW_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .xwjyaW_g-xxl-2, .xwjyaW_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .xwjyaW_g-xxl-2, .xwjyaW_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .xwjyaW_g-xxl-3, .xwjyaW_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .xwjyaW_g-xxl-3, .xwjyaW_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .xwjyaW_g-xxl-4, .xwjyaW_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .xwjyaW_g-xxl-4, .xwjyaW_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .xwjyaW_g-xxl-5, .xwjyaW_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .xwjyaW_g-xxl-5, .xwjyaW_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.xwjyaW_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.xwjyaW_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.xwjyaW_table > tbody {
  vertical-align: inherit;
}

.xwjyaW_table > thead {
  vertical-align: bottom;
}

.xwjyaW_table-group-divider {
  border-top: 2px solid;
}

.xwjyaW_caption-top {
  caption-side: top;
}

.xwjyaW_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.xwjyaW_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.xwjyaW_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.xwjyaW_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.xwjyaW_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.xwjyaW_table-striped > tbody > tr:nth-of-type(2n+1) > *, .xwjyaW_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.xwjyaW_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.xwjyaW_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.xwjyaW_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.xwjyaW_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .xwjyaW_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .xwjyaW_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .xwjyaW_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .xwjyaW_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .xwjyaW_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.xwjyaW_form-label {
  margin-bottom: .5rem;
}

.xwjyaW_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.xwjyaW_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.xwjyaW_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.xwjyaW_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.xwjyaW_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-control {
    transition: none;
  }
}

.xwjyaW_form-control[type="file"] {
  overflow: hidden;
}

.xwjyaW_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.xwjyaW_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.xwjyaW_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.xwjyaW_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.xwjyaW_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.xwjyaW_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-control::file-selector-button {
    transition: none;
  }
}

.xwjyaW_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.xwjyaW_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.xwjyaW_form-control-plaintext:focus {
  outline: 0;
}

.xwjyaW_form-control-plaintext.xwjyaW_form-control-sm, .xwjyaW_form-control-plaintext.xwjyaW_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.xwjyaW_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.xwjyaW_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.xwjyaW_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.xwjyaW_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.xwjyaW_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.xwjyaW_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.xwjyaW_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.xwjyaW_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.xwjyaW_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.xwjyaW_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.xwjyaW_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.xwjyaW_form-control-color.xwjyaW_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.xwjyaW_form-control-color.xwjyaW_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.xwjyaW_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-select {
    transition: none;
  }
}

.xwjyaW_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.xwjyaW_form-select[multiple], .xwjyaW_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.xwjyaW_form-select:disabled {
  background-color: #e9ecef;
}

.xwjyaW_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.xwjyaW_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.xwjyaW_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.xwjyaW_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.xwjyaW_form-check .xwjyaW_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.xwjyaW_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.xwjyaW_form-check-reverse .xwjyaW_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.xwjyaW_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.xwjyaW_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.xwjyaW_form-check-input[type="radio"] {
  border-radius: 50%;
}

.xwjyaW_form-check-input:active {
  filter: brightness(90%);
}

.xwjyaW_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.xwjyaW_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.xwjyaW_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.xwjyaW_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.xwjyaW_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.xwjyaW_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.xwjyaW_form-check-input[disabled] ~ .xwjyaW_form-check-label, .xwjyaW_form-check-input:disabled ~ .xwjyaW_form-check-label {
  cursor: default;
  opacity: .5;
}

.xwjyaW_form-switch {
  padding-left: 2.5em;
}

.xwjyaW_form-switch .xwjyaW_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-switch .xwjyaW_form-check-input {
    transition: none;
  }
}

.xwjyaW_form-switch .xwjyaW_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.xwjyaW_form-switch .xwjyaW_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.xwjyaW_form-switch.xwjyaW_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.xwjyaW_form-switch.xwjyaW_form-check-reverse .xwjyaW_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.xwjyaW_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.xwjyaW_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.xwjyaW_btn-check[disabled] + .xwjyaW_btn, .xwjyaW_btn-check:disabled + .xwjyaW_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.xwjyaW_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.xwjyaW_form-range:focus {
  outline: 0;
}

.xwjyaW_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.xwjyaW_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.xwjyaW_form-range::-moz-focus-outer {
  border: 0;
}

.xwjyaW_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.xwjyaW_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.xwjyaW_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.xwjyaW_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-range::-moz-range-thumb {
    transition: none;
  }
}

.xwjyaW_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.xwjyaW_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.xwjyaW_form-range:disabled {
  pointer-events: none;
}

.xwjyaW_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.xwjyaW_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.xwjyaW_form-floating {
  position: relative;
}

.xwjyaW_form-floating > .xwjyaW_form-control, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext, .xwjyaW_form-floating > .xwjyaW_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.xwjyaW_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_form-floating > label {
    transition: none;
  }
}

.xwjyaW_form-floating > .xwjyaW_form-control, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext {
  padding: 1rem .75rem;
}

.xwjyaW_form-floating > .xwjyaW_form-control::placeholder, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext::placeholder {
  color: #0000;
}

.xwjyaW_form-floating > .xwjyaW_form-control:focus, .xwjyaW_form-floating > .xwjyaW_form-control:not(:placeholder-shown), .xwjyaW_form-floating > .xwjyaW_form-control-plaintext:focus, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.xwjyaW_form-floating > .xwjyaW_form-control:-webkit-autofill, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.xwjyaW_form-floating > .xwjyaW_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.xwjyaW_form-floating > .xwjyaW_form-control:focus ~ label, .xwjyaW_form-floating > .xwjyaW_form-control:not(:placeholder-shown) ~ label, .xwjyaW_form-floating > .xwjyaW_form-control-plaintext ~ label, .xwjyaW_form-floating > .xwjyaW_form-select ~ label, .xwjyaW_form-floating > .xwjyaW_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.xwjyaW_form-floating > .xwjyaW_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.xwjyaW_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.xwjyaW_input-group > .xwjyaW_form-control, .xwjyaW_input-group > .xwjyaW_form-select, .xwjyaW_input-group > .xwjyaW_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.xwjyaW_input-group > .xwjyaW_form-control:focus, .xwjyaW_input-group > .xwjyaW_form-select:focus, .xwjyaW_input-group > .xwjyaW_form-floating:focus-within {
  z-index: 5;
}

.xwjyaW_input-group .xwjyaW_btn {
  z-index: 2;
  position: relative;
}

.xwjyaW_input-group .xwjyaW_btn:focus {
  z-index: 5;
}

.xwjyaW_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.xwjyaW_input-group-lg > .xwjyaW_form-control, .xwjyaW_input-group-lg > .xwjyaW_form-select, .xwjyaW_input-group-lg > .xwjyaW_input-group-text, .xwjyaW_input-group-lg > .xwjyaW_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.xwjyaW_input-group-sm > .xwjyaW_form-control, .xwjyaW_input-group-sm > .xwjyaW_form-select, .xwjyaW_input-group-sm > .xwjyaW_input-group-text, .xwjyaW_input-group-sm > .xwjyaW_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.xwjyaW_input-group-lg > .xwjyaW_form-select, .xwjyaW_input-group-sm > .xwjyaW_form-select {
  padding-right: 3rem;
}

.xwjyaW_input-group:not(.xwjyaW_has-validation) > :not(:last-child):not(.xwjyaW_dropdown-toggle):not(.xwjyaW_dropdown-menu):not(.xwjyaW_form-floating), .xwjyaW_input-group:not(.xwjyaW_has-validation) > .xwjyaW_dropdown-toggle:nth-last-child(n+3), .xwjyaW_input-group:not(.xwjyaW_has-validation) > .xwjyaW_form-floating:not(:last-child) > .xwjyaW_form-control, .xwjyaW_input-group:not(.xwjyaW_has-validation) > .xwjyaW_form-floating:not(:last-child) > .xwjyaW_form-select, .xwjyaW_input-group.xwjyaW_has-validation > :nth-last-child(n+3):not(.xwjyaW_dropdown-toggle):not(.xwjyaW_dropdown-menu):not(.xwjyaW_form-floating), .xwjyaW_input-group.xwjyaW_has-validation > .xwjyaW_dropdown-toggle:nth-last-child(n+4), .xwjyaW_input-group.xwjyaW_has-validation > .xwjyaW_form-floating:nth-last-child(n+3) > .xwjyaW_form-control, .xwjyaW_input-group.xwjyaW_has-validation > .xwjyaW_form-floating:nth-last-child(n+3) > .xwjyaW_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.xwjyaW_input-group > :not(:first-child):not(.xwjyaW_dropdown-menu):not(.xwjyaW_valid-tooltip):not(.xwjyaW_valid-feedback):not(.xwjyaW_invalid-tooltip):not(.xwjyaW_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.xwjyaW_input-group > .xwjyaW_form-floating:not(:first-child) > .xwjyaW_form-control, .xwjyaW_input-group > .xwjyaW_form-floating:not(:first-child) > .xwjyaW_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.xwjyaW_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.xwjyaW_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.xwjyaW_was-validated :valid ~ .xwjyaW_valid-feedback, .xwjyaW_was-validated :valid ~ .xwjyaW_valid-tooltip, .xwjyaW_is-valid ~ .xwjyaW_valid-feedback, .xwjyaW_is-valid ~ .xwjyaW_valid-tooltip {
  display: block;
}

.xwjyaW_was-validated .xwjyaW_form-control:valid, .xwjyaW_form-control.xwjyaW_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.xwjyaW_was-validated .xwjyaW_form-control:valid:focus, .xwjyaW_form-control.xwjyaW_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.xwjyaW_was-validated textarea.xwjyaW_form-control:valid, textarea.xwjyaW_form-control.xwjyaW_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.xwjyaW_was-validated .xwjyaW_form-select:valid, .xwjyaW_form-select.xwjyaW_is-valid {
  border-color: #198754;
}

.xwjyaW_was-validated .xwjyaW_form-select:valid:not([multiple]):not([size]), .xwjyaW_was-validated .xwjyaW_form-select:valid:not([multiple])[size="1"], .xwjyaW_form-select.xwjyaW_is-valid:not([multiple]):not([size]), .xwjyaW_form-select.xwjyaW_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.xwjyaW_was-validated .xwjyaW_form-select:valid:focus, .xwjyaW_form-select.xwjyaW_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.xwjyaW_was-validated .xwjyaW_form-control-color:valid, .xwjyaW_form-control-color.xwjyaW_is-valid {
  width: calc(1.5em + 3.75rem);
}

.xwjyaW_was-validated .xwjyaW_form-check-input:valid, .xwjyaW_form-check-input.xwjyaW_is-valid {
  border-color: #198754;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:valid:checked, .xwjyaW_form-check-input.xwjyaW_is-valid:checked {
  background-color: #198754;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:valid:focus, .xwjyaW_form-check-input.xwjyaW_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:valid ~ .xwjyaW_form-check-label, .xwjyaW_form-check-input.xwjyaW_is-valid ~ .xwjyaW_form-check-label {
  color: #198754;
}

.xwjyaW_form-check-inline .xwjyaW_form-check-input ~ .xwjyaW_valid-feedback {
  margin-left: .5em;
}

.xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-control:not(:focus):valid, .xwjyaW_input-group > .xwjyaW_form-control:not(:focus).xwjyaW_is-valid, .xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-select:not(:focus):valid, .xwjyaW_input-group > .xwjyaW_form-select:not(:focus).xwjyaW_is-valid, .xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-floating:not(:focus-within):valid, .xwjyaW_input-group > .xwjyaW_form-floating:not(:focus-within).xwjyaW_is-valid {
  z-index: 3;
}

.xwjyaW_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.xwjyaW_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.xwjyaW_was-validated :invalid ~ .xwjyaW_invalid-feedback, .xwjyaW_was-validated :invalid ~ .xwjyaW_invalid-tooltip, .xwjyaW_is-invalid ~ .xwjyaW_invalid-feedback, .xwjyaW_is-invalid ~ .xwjyaW_invalid-tooltip {
  display: block;
}

.xwjyaW_was-validated .xwjyaW_form-control:invalid, .xwjyaW_form-control.xwjyaW_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.xwjyaW_was-validated .xwjyaW_form-control:invalid:focus, .xwjyaW_form-control.xwjyaW_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.xwjyaW_was-validated textarea.xwjyaW_form-control:invalid, textarea.xwjyaW_form-control.xwjyaW_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.xwjyaW_was-validated .xwjyaW_form-select:invalid, .xwjyaW_form-select.xwjyaW_is-invalid {
  border-color: #dc3545;
}

.xwjyaW_was-validated .xwjyaW_form-select:invalid:not([multiple]):not([size]), .xwjyaW_was-validated .xwjyaW_form-select:invalid:not([multiple])[size="1"], .xwjyaW_form-select.xwjyaW_is-invalid:not([multiple]):not([size]), .xwjyaW_form-select.xwjyaW_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.xwjyaW_was-validated .xwjyaW_form-select:invalid:focus, .xwjyaW_form-select.xwjyaW_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.xwjyaW_was-validated .xwjyaW_form-control-color:invalid, .xwjyaW_form-control-color.xwjyaW_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.xwjyaW_was-validated .xwjyaW_form-check-input:invalid, .xwjyaW_form-check-input.xwjyaW_is-invalid {
  border-color: #dc3545;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:invalid:checked, .xwjyaW_form-check-input.xwjyaW_is-invalid:checked {
  background-color: #dc3545;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:invalid:focus, .xwjyaW_form-check-input.xwjyaW_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.xwjyaW_was-validated .xwjyaW_form-check-input:invalid ~ .xwjyaW_form-check-label, .xwjyaW_form-check-input.xwjyaW_is-invalid ~ .xwjyaW_form-check-label {
  color: #dc3545;
}

.xwjyaW_form-check-inline .xwjyaW_form-check-input ~ .xwjyaW_invalid-feedback {
  margin-left: .5em;
}

.xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-control:not(:focus):invalid, .xwjyaW_input-group > .xwjyaW_form-control:not(:focus).xwjyaW_is-invalid, .xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-select:not(:focus):invalid, .xwjyaW_input-group > .xwjyaW_form-select:not(:focus).xwjyaW_is-invalid, .xwjyaW_was-validated .xwjyaW_input-group > .xwjyaW_form-floating:not(:focus-within):invalid, .xwjyaW_input-group > .xwjyaW_form-floating:not(:focus-within).xwjyaW_is-invalid {
  z-index: 4;
}

.xwjyaW_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_btn {
    transition: none;
  }
}

.xwjyaW_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.xwjyaW_btn-check + .xwjyaW_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.xwjyaW_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.xwjyaW_btn-check:focus-visible + .xwjyaW_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.xwjyaW_btn-check:checked + .xwjyaW_btn, :not(.xwjyaW_btn-check) + .xwjyaW_btn:active, .xwjyaW_btn:first-child:active, .xwjyaW_btn.xwjyaW_active, .xwjyaW_btn.xwjyaW_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.xwjyaW_btn-check:checked + .xwjyaW_btn:focus-visible, :not(.xwjyaW_btn-check) + .xwjyaW_btn:active:focus-visible, .xwjyaW_btn:first-child:active:focus-visible, .xwjyaW_btn.xwjyaW_active:focus-visible, .xwjyaW_btn.xwjyaW_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.xwjyaW_btn:disabled, .xwjyaW_btn.xwjyaW_disabled, fieldset:disabled .xwjyaW_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.xwjyaW_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.xwjyaW_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.xwjyaW_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.xwjyaW_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.xwjyaW_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.xwjyaW_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.xwjyaW_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.xwjyaW_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.xwjyaW_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.xwjyaW_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.xwjyaW_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.xwjyaW_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.xwjyaW_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.xwjyaW_btn-lg, .xwjyaW_btn-group-lg > .xwjyaW_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.xwjyaW_btn-sm, .xwjyaW_btn-group-sm > .xwjyaW_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.xwjyaW_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_fade {
    transition: none;
  }
}

.xwjyaW_fade:not(.xwjyaW_show) {
  opacity: 0;
}

.xwjyaW_collapse:not(.xwjyaW_show) {
  display: none;
}

.xwjyaW_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_collapsing {
    transition: none;
  }
}

.xwjyaW_collapsing.xwjyaW_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_collapsing.xwjyaW_collapse-horizontal {
    transition: none;
  }
}

.xwjyaW_dropup, .xwjyaW_dropend, .xwjyaW_dropdown, .xwjyaW_dropstart, .xwjyaW_dropup-center, .xwjyaW_dropdown-center {
  position: relative;
}

.xwjyaW_dropdown-toggle {
  white-space: nowrap;
}

.xwjyaW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.xwjyaW_dropdown-toggle:empty:after {
  margin-left: 0;
}

.xwjyaW_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.xwjyaW_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.xwjyaW_dropdown-menu-start {
  --bs-position: start;
}

.xwjyaW_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.xwjyaW_dropdown-menu-end {
  --bs-position: end;
}

.xwjyaW_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .xwjyaW_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .xwjyaW_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .xwjyaW_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .xwjyaW_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .xwjyaW_dropdown-menu-md-start {
    --bs-position: start;
  }

  .xwjyaW_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .xwjyaW_dropdown-menu-md-end {
    --bs-position: end;
  }

  .xwjyaW_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .xwjyaW_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .xwjyaW_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .xwjyaW_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .xwjyaW_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .xwjyaW_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .xwjyaW_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .xwjyaW_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .xwjyaW_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .xwjyaW_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .xwjyaW_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.xwjyaW_dropup .xwjyaW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.xwjyaW_dropup .xwjyaW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.xwjyaW_dropup .xwjyaW_dropdown-toggle:empty:after {
  margin-left: 0;
}

.xwjyaW_dropend .xwjyaW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.xwjyaW_dropend .xwjyaW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.xwjyaW_dropend .xwjyaW_dropdown-toggle:empty:after {
  margin-left: 0;
}

.xwjyaW_dropend .xwjyaW_dropdown-toggle:after {
  vertical-align: 0;
}

.xwjyaW_dropstart .xwjyaW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.xwjyaW_dropstart .xwjyaW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.xwjyaW_dropstart .xwjyaW_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.xwjyaW_dropstart .xwjyaW_dropdown-toggle:empty:after {
  margin-left: 0;
}

.xwjyaW_dropstart .xwjyaW_dropdown-toggle:before {
  vertical-align: 0;
}

.xwjyaW_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.xwjyaW_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.xwjyaW_dropdown-item:hover, .xwjyaW_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.xwjyaW_dropdown-item.xwjyaW_active, .xwjyaW_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.xwjyaW_dropdown-item.xwjyaW_disabled, .xwjyaW_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.xwjyaW_dropdown-menu.xwjyaW_show {
  display: block;
}

.xwjyaW_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.xwjyaW_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.xwjyaW_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.xwjyaW_btn-group, .xwjyaW_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.xwjyaW_btn-group > .xwjyaW_btn, .xwjyaW_btn-group-vertical > .xwjyaW_btn {
  flex: auto;
  position: relative;
}

.xwjyaW_btn-group > .xwjyaW_btn-check:checked + .xwjyaW_btn, .xwjyaW_btn-group > .xwjyaW_btn-check:focus + .xwjyaW_btn, .xwjyaW_btn-group > .xwjyaW_btn:hover, .xwjyaW_btn-group > .xwjyaW_btn:focus, .xwjyaW_btn-group > .xwjyaW_btn:active, .xwjyaW_btn-group > .xwjyaW_btn.xwjyaW_active, .xwjyaW_btn-group-vertical > .xwjyaW_btn-check:checked + .xwjyaW_btn, .xwjyaW_btn-group-vertical > .xwjyaW_btn-check:focus + .xwjyaW_btn, .xwjyaW_btn-group-vertical > .xwjyaW_btn:hover, .xwjyaW_btn-group-vertical > .xwjyaW_btn:focus, .xwjyaW_btn-group-vertical > .xwjyaW_btn:active, .xwjyaW_btn-group-vertical > .xwjyaW_btn.xwjyaW_active {
  z-index: 1;
}

.xwjyaW_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.xwjyaW_btn-toolbar .xwjyaW_input-group {
  width: auto;
}

.xwjyaW_btn-group {
  border-radius: .375rem;
}

.xwjyaW_btn-group > :not(.xwjyaW_btn-check:first-child) + .xwjyaW_btn, .xwjyaW_btn-group > .xwjyaW_btn-group:not(:first-child) {
  margin-left: -1px;
}

.xwjyaW_btn-group > .xwjyaW_btn:not(:last-child):not(.xwjyaW_dropdown-toggle), .xwjyaW_btn-group > .xwjyaW_btn.xwjyaW_dropdown-toggle-split:first-child, .xwjyaW_btn-group > .xwjyaW_btn-group:not(:last-child) > .xwjyaW_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.xwjyaW_btn-group > .xwjyaW_btn:nth-child(n+3), .xwjyaW_btn-group > :not(.xwjyaW_btn-check) + .xwjyaW_btn, .xwjyaW_btn-group > .xwjyaW_btn-group:not(:first-child) > .xwjyaW_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.xwjyaW_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.xwjyaW_dropdown-toggle-split:after, .xwjyaW_dropup .xwjyaW_dropdown-toggle-split:after, .xwjyaW_dropend .xwjyaW_dropdown-toggle-split:after {
  margin-left: 0;
}

.xwjyaW_dropstart .xwjyaW_dropdown-toggle-split:before {
  margin-right: 0;
}

.xwjyaW_btn-sm + .xwjyaW_dropdown-toggle-split, .xwjyaW_btn-group-sm > .xwjyaW_btn + .xwjyaW_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.xwjyaW_btn-lg + .xwjyaW_dropdown-toggle-split, .xwjyaW_btn-group-lg > .xwjyaW_btn + .xwjyaW_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.xwjyaW_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.xwjyaW_btn-group-vertical > .xwjyaW_btn, .xwjyaW_btn-group-vertical > .xwjyaW_btn-group {
  width: 100%;
}

.xwjyaW_btn-group-vertical > .xwjyaW_btn:not(:first-child), .xwjyaW_btn-group-vertical > .xwjyaW_btn-group:not(:first-child) {
  margin-top: -1px;
}

.xwjyaW_btn-group-vertical > .xwjyaW_btn:not(:last-child):not(.xwjyaW_dropdown-toggle), .xwjyaW_btn-group-vertical > .xwjyaW_btn-group:not(:last-child) > .xwjyaW_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.xwjyaW_btn-group-vertical > .xwjyaW_btn ~ .xwjyaW_btn, .xwjyaW_btn-group-vertical > .xwjyaW_btn-group:not(:first-child) > .xwjyaW_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.xwjyaW_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.xwjyaW_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_nav-link {
    transition: none;
  }
}

.xwjyaW_nav-link:hover, .xwjyaW_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.xwjyaW_nav-link.xwjyaW_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.xwjyaW_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.xwjyaW_nav-tabs .xwjyaW_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.xwjyaW_nav-tabs .xwjyaW_nav-link:hover, .xwjyaW_nav-tabs .xwjyaW_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.xwjyaW_nav-tabs .xwjyaW_nav-link.xwjyaW_disabled, .xwjyaW_nav-tabs .xwjyaW_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.xwjyaW_nav-tabs .xwjyaW_nav-link.xwjyaW_active, .xwjyaW_nav-tabs .xwjyaW_nav-item.xwjyaW_show .xwjyaW_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.xwjyaW_nav-tabs .xwjyaW_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.xwjyaW_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.xwjyaW_nav-pills .xwjyaW_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.xwjyaW_nav-pills .xwjyaW_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.xwjyaW_nav-pills .xwjyaW_nav-link.xwjyaW_active, .xwjyaW_nav-pills .xwjyaW_show > .xwjyaW_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.xwjyaW_nav-fill > .xwjyaW_nav-link, .xwjyaW_nav-fill .xwjyaW_nav-item {
  text-align: center;
  flex: auto;
}

.xwjyaW_nav-justified > .xwjyaW_nav-link, .xwjyaW_nav-justified .xwjyaW_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.xwjyaW_nav-fill .xwjyaW_nav-item .xwjyaW_nav-link, .xwjyaW_nav-justified .xwjyaW_nav-item .xwjyaW_nav-link {
  width: 100%;
}

.xwjyaW_tab-content > .xwjyaW_tab-pane {
  display: none;
}

.xwjyaW_tab-content > .xwjyaW_active {
  display: block;
}

.xwjyaW_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.xwjyaW_navbar > .xwjyaW_container, .xwjyaW_navbar > .xwjyaW_container-fluid, .xwjyaW_navbar > .xwjyaW_container-sm, .xwjyaW_navbar > .xwjyaW_container-md, .xwjyaW_navbar > .xwjyaW_container-lg, .xwjyaW_navbar > .xwjyaW_container-xl, .xwjyaW_navbar > .xwjyaW_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.xwjyaW_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.xwjyaW_navbar-brand:hover, .xwjyaW_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.xwjyaW_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.xwjyaW_navbar-nav .xwjyaW_show > .xwjyaW_nav-link, .xwjyaW_navbar-nav .xwjyaW_nav-link.xwjyaW_active {
  color: var(--bs-navbar-active-color);
}

.xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
  position: static;
}

.xwjyaW_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.xwjyaW_navbar-text a, .xwjyaW_navbar-text a:hover, .xwjyaW_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.xwjyaW_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.xwjyaW_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_navbar-toggler {
    transition: none;
  }
}

.xwjyaW_navbar-toggler:hover {
  text-decoration: none;
}

.xwjyaW_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.xwjyaW_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.xwjyaW_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .xwjyaW_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-nav {
    flex-direction: row;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
    position: absolute;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-nav .xwjyaW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-nav-scroll {
    overflow: visible;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_navbar-toggler {
    display: none;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_navbar-expand-sm .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .xwjyaW_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-nav {
    flex-direction: row;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
    position: absolute;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-nav .xwjyaW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-nav-scroll {
    overflow: visible;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_navbar-toggler {
    display: none;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_navbar-expand-md .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .xwjyaW_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-nav {
    flex-direction: row;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
    position: absolute;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-nav .xwjyaW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-nav-scroll {
    overflow: visible;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_navbar-toggler {
    display: none;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_navbar-expand-lg .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-nav {
    flex-direction: row;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
    position: absolute;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-nav .xwjyaW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-nav-scroll {
    overflow: visible;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_navbar-toggler {
    display: none;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_navbar-expand-xl .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-nav {
    flex-direction: row;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
    position: absolute;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-nav .xwjyaW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-nav-scroll {
    overflow: visible;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_navbar-toggler {
    display: none;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_navbar-expand-xxl .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.xwjyaW_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.xwjyaW_navbar-expand .xwjyaW_navbar-nav {
  flex-direction: row;
}

.xwjyaW_navbar-expand .xwjyaW_navbar-nav .xwjyaW_dropdown-menu {
  position: absolute;
}

.xwjyaW_navbar-expand .xwjyaW_navbar-nav .xwjyaW_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.xwjyaW_navbar-expand .xwjyaW_navbar-nav-scroll {
  overflow: visible;
}

.xwjyaW_navbar-expand .xwjyaW_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.xwjyaW_navbar-expand .xwjyaW_navbar-toggler {
  display: none;
}

.xwjyaW_navbar-expand .xwjyaW_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.xwjyaW_navbar-expand .xwjyaW_offcanvas .xwjyaW_offcanvas-header {
  display: none;
}

.xwjyaW_navbar-expand .xwjyaW_offcanvas .xwjyaW_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.xwjyaW_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.xwjyaW_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.xwjyaW_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.xwjyaW_card > .xwjyaW_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.xwjyaW_card > .xwjyaW_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.xwjyaW_card > .xwjyaW_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.xwjyaW_card > .xwjyaW_card-header + .xwjyaW_list-group, .xwjyaW_card > .xwjyaW_list-group + .xwjyaW_card-footer {
  border-top: 0;
}

.xwjyaW_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.xwjyaW_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.xwjyaW_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.xwjyaW_card-text:last-child {
  margin-bottom: 0;
}

.xwjyaW_card-link + .xwjyaW_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.xwjyaW_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.xwjyaW_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.xwjyaW_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.xwjyaW_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.xwjyaW_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.xwjyaW_card-header-tabs .xwjyaW_nav-link.xwjyaW_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.xwjyaW_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.xwjyaW_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.xwjyaW_card-img, .xwjyaW_card-img-top, .xwjyaW_card-img-bottom {
  width: 100%;
}

.xwjyaW_card-img, .xwjyaW_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.xwjyaW_card-img, .xwjyaW_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.xwjyaW_card-group > .xwjyaW_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .xwjyaW_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .xwjyaW_card-group > .xwjyaW_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card + .xwjyaW_card {
    border-left: 0;
    margin-left: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:last-child) .xwjyaW_card-img-top, .xwjyaW_card-group > .xwjyaW_card:not(:last-child) .xwjyaW_card-header {
    border-top-right-radius: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:last-child) .xwjyaW_card-img-bottom, .xwjyaW_card-group > .xwjyaW_card:not(:last-child) .xwjyaW_card-footer {
    border-bottom-right-radius: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:first-child) .xwjyaW_card-img-top, .xwjyaW_card-group > .xwjyaW_card:not(:first-child) .xwjyaW_card-header {
    border-top-left-radius: 0;
  }

  .xwjyaW_card-group > .xwjyaW_card:not(:first-child) .xwjyaW_card-img-bottom, .xwjyaW_card-group > .xwjyaW_card:not(:first-child) .xwjyaW_card-footer {
    border-bottom-left-radius: 0;
  }
}

.xwjyaW_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.xwjyaW_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_accordion-button {
    transition: none;
  }
}

.xwjyaW_accordion-button:not(.xwjyaW_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.xwjyaW_accordion-button:not(.xwjyaW_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.xwjyaW_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_accordion-button:after {
    transition: none;
  }
}

.xwjyaW_accordion-button:hover {
  z-index: 2;
}

.xwjyaW_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.xwjyaW_accordion-header {
  margin-bottom: 0;
}

.xwjyaW_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.xwjyaW_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.xwjyaW_accordion-item:first-of-type .xwjyaW_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.xwjyaW_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.xwjyaW_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.xwjyaW_accordion-item:last-of-type .xwjyaW_accordion-button.xwjyaW_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.xwjyaW_accordion-item:last-of-type .xwjyaW_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.xwjyaW_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.xwjyaW_accordion-flush .xwjyaW_accordion-collapse {
  border-width: 0;
}

.xwjyaW_accordion-flush .xwjyaW_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.xwjyaW_accordion-flush .xwjyaW_accordion-item:first-child {
  border-top: 0;
}

.xwjyaW_accordion-flush .xwjyaW_accordion-item:last-child {
  border-bottom: 0;
}

.xwjyaW_accordion-flush .xwjyaW_accordion-item .xwjyaW_accordion-button, .xwjyaW_accordion-flush .xwjyaW_accordion-item .xwjyaW_accordion-button.xwjyaW_collapsed {
  border-radius: 0;
}

.xwjyaW_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.xwjyaW_breadcrumb-item + .xwjyaW_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.xwjyaW_breadcrumb-item + .xwjyaW_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.xwjyaW_breadcrumb-item.xwjyaW_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.xwjyaW_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.xwjyaW_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_page-link {
    transition: none;
  }
}

.xwjyaW_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.xwjyaW_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.xwjyaW_page-link.xwjyaW_active, .xwjyaW_active > .xwjyaW_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.xwjyaW_page-link.xwjyaW_disabled, .xwjyaW_disabled > .xwjyaW_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.xwjyaW_page-item:not(:first-child) .xwjyaW_page-link {
  margin-left: -1px;
}

.xwjyaW_page-item:first-child .xwjyaW_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.xwjyaW_page-item:last-child .xwjyaW_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.xwjyaW_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.xwjyaW_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.xwjyaW_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.xwjyaW_badge:empty {
  display: none;
}

.xwjyaW_btn .xwjyaW_badge {
  position: relative;
  top: -1px;
}

.xwjyaW_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.xwjyaW_alert-heading {
  color: inherit;
}

.xwjyaW_alert-link {
  font-weight: 700;
}

.xwjyaW_alert-dismissible {
  padding-right: 3rem;
}

.xwjyaW_alert-dismissible .xwjyaW_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.xwjyaW_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.xwjyaW_alert-primary .xwjyaW_alert-link {
  color: #25224b;
}

.xwjyaW_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.xwjyaW_alert-secondary .xwjyaW_alert-link {
  color: #34383c;
}

.xwjyaW_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.xwjyaW_alert-success .xwjyaW_alert-link {
  color: #0c4128;
}

.xwjyaW_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.xwjyaW_alert-info .xwjyaW_alert-link {
  color: #04414d;
}

.xwjyaW_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.xwjyaW_alert-warning .xwjyaW_alert-link {
  color: #523e02;
}

.xwjyaW_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.xwjyaW_alert-danger .xwjyaW_alert-link {
  color: #6a1a21;
}

.xwjyaW_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.xwjyaW_alert-light .xwjyaW_alert-link {
  color: #4f5050;
}

.xwjyaW_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.xwjyaW_alert-dark .xwjyaW_alert-link {
  color: #101214;
}

@keyframes xwjyaW_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.xwjyaW_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.xwjyaW_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_progress-bar {
    transition: none;
  }
}

.xwjyaW_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.xwjyaW_progress-bar-animated {
  animation: 1s linear infinite xwjyaW_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_progress-bar-animated {
    animation: none;
  }
}

.xwjyaW_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.xwjyaW_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.xwjyaW_list-group-numbered > .xwjyaW_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.xwjyaW_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.xwjyaW_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.xwjyaW_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.xwjyaW_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.xwjyaW_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.xwjyaW_list-group-item.xwjyaW_disabled, .xwjyaW_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.xwjyaW_list-group-item.xwjyaW_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.xwjyaW_list-group-item + .xwjyaW_list-group-item {
  border-top-width: 0;
}

.xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.xwjyaW_list-group-horizontal {
  flex-direction: row;
}

.xwjyaW_list-group-horizontal > .xwjyaW_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.xwjyaW_list-group-horizontal > .xwjyaW_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.xwjyaW_list-group-horizontal > .xwjyaW_list-group-item.xwjyaW_active {
  margin-top: 0;
}

.xwjyaW_list-group-horizontal > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.xwjyaW_list-group-horizontal > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .xwjyaW_list-group-horizontal-sm {
    flex-direction: row;
  }

  .xwjyaW_list-group-horizontal-sm > .xwjyaW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .xwjyaW_list-group-horizontal-sm > .xwjyaW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .xwjyaW_list-group-horizontal-sm > .xwjyaW_list-group-item.xwjyaW_active {
    margin-top: 0;
  }

  .xwjyaW_list-group-horizontal-sm > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .xwjyaW_list-group-horizontal-sm > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .xwjyaW_list-group-horizontal-md {
    flex-direction: row;
  }

  .xwjyaW_list-group-horizontal-md > .xwjyaW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .xwjyaW_list-group-horizontal-md > .xwjyaW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .xwjyaW_list-group-horizontal-md > .xwjyaW_list-group-item.xwjyaW_active {
    margin-top: 0;
  }

  .xwjyaW_list-group-horizontal-md > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .xwjyaW_list-group-horizontal-md > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .xwjyaW_list-group-horizontal-lg {
    flex-direction: row;
  }

  .xwjyaW_list-group-horizontal-lg > .xwjyaW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .xwjyaW_list-group-horizontal-lg > .xwjyaW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .xwjyaW_list-group-horizontal-lg > .xwjyaW_list-group-item.xwjyaW_active {
    margin-top: 0;
  }

  .xwjyaW_list-group-horizontal-lg > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .xwjyaW_list-group-horizontal-lg > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .xwjyaW_list-group-horizontal-xl {
    flex-direction: row;
  }

  .xwjyaW_list-group-horizontal-xl > .xwjyaW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .xwjyaW_list-group-horizontal-xl > .xwjyaW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .xwjyaW_list-group-horizontal-xl > .xwjyaW_list-group-item.xwjyaW_active {
    margin-top: 0;
  }

  .xwjyaW_list-group-horizontal-xl > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .xwjyaW_list-group-horizontal-xl > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .xwjyaW_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .xwjyaW_list-group-horizontal-xxl > .xwjyaW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .xwjyaW_list-group-horizontal-xxl > .xwjyaW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .xwjyaW_list-group-horizontal-xxl > .xwjyaW_list-group-item.xwjyaW_active {
    margin-top: 0;
  }

  .xwjyaW_list-group-horizontal-xxl > .xwjyaW_list-group-item + .xwjyaW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .xwjyaW_list-group-horizontal-xxl > .xwjyaW_list-group-item + .xwjyaW_list-group-item.xwjyaW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.xwjyaW_list-group-flush {
  border-radius: 0;
}

.xwjyaW_list-group-flush > .xwjyaW_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.xwjyaW_list-group-flush > .xwjyaW_list-group-item:last-child {
  border-bottom-width: 0;
}

.xwjyaW_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.xwjyaW_list-group-item-primary.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-primary.xwjyaW_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.xwjyaW_list-group-item-primary.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.xwjyaW_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.xwjyaW_list-group-item-secondary.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-secondary.xwjyaW_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.xwjyaW_list-group-item-secondary.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.xwjyaW_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.xwjyaW_list-group-item-success.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-success.xwjyaW_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.xwjyaW_list-group-item-success.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.xwjyaW_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.xwjyaW_list-group-item-info.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-info.xwjyaW_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.xwjyaW_list-group-item-info.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.xwjyaW_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.xwjyaW_list-group-item-warning.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-warning.xwjyaW_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.xwjyaW_list-group-item-warning.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.xwjyaW_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.xwjyaW_list-group-item-danger.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-danger.xwjyaW_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.xwjyaW_list-group-item-danger.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.xwjyaW_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.xwjyaW_list-group-item-light.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-light.xwjyaW_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.xwjyaW_list-group-item-light.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.xwjyaW_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.xwjyaW_list-group-item-dark.xwjyaW_list-group-item-action:hover, .xwjyaW_list-group-item-dark.xwjyaW_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.xwjyaW_list-group-item-dark.xwjyaW_list-group-item-action.xwjyaW_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.xwjyaW_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.xwjyaW_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.xwjyaW_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.xwjyaW_btn-close:disabled, .xwjyaW_btn-close.xwjyaW_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.xwjyaW_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.xwjyaW_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.xwjyaW_toast.xwjyaW_showing {
  opacity: 0;
}

.xwjyaW_toast:not(.xwjyaW_show) {
  display: none;
}

.xwjyaW_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.xwjyaW_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.xwjyaW_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.xwjyaW_toast-header .xwjyaW_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.xwjyaW_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.xwjyaW_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.xwjyaW_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.xwjyaW_modal.xwjyaW_fade .xwjyaW_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_modal.xwjyaW_fade .xwjyaW_modal-dialog {
    transition: none;
  }
}

.xwjyaW_modal.xwjyaW_show .xwjyaW_modal-dialog {
  transform: none;
}

.xwjyaW_modal.xwjyaW_modal-static .xwjyaW_modal-dialog {
  transform: scale(1.02);
}

.xwjyaW_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.xwjyaW_modal-dialog-scrollable .xwjyaW_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.xwjyaW_modal-dialog-scrollable .xwjyaW_modal-body {
  overflow-y: auto;
}

.xwjyaW_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.xwjyaW_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.xwjyaW_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.xwjyaW_modal-backdrop.xwjyaW_fade {
  opacity: 0;
}

.xwjyaW_modal-backdrop.xwjyaW_show {
  opacity: var(--bs-backdrop-opacity);
}

.xwjyaW_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.xwjyaW_modal-header .xwjyaW_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.xwjyaW_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.xwjyaW_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.xwjyaW_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.xwjyaW_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .xwjyaW_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .xwjyaW_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .xwjyaW_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .xwjyaW_modal-lg, .xwjyaW_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.xwjyaW_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.xwjyaW_modal-fullscreen .xwjyaW_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.xwjyaW_modal-fullscreen .xwjyaW_modal-header, .xwjyaW_modal-fullscreen .xwjyaW_modal-footer {
  border-radius: 0;
}

.xwjyaW_modal-fullscreen .xwjyaW_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .xwjyaW_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .xwjyaW_modal-fullscreen-sm-down .xwjyaW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-sm-down .xwjyaW_modal-header, .xwjyaW_modal-fullscreen-sm-down .xwjyaW_modal-footer {
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-sm-down .xwjyaW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .xwjyaW_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .xwjyaW_modal-fullscreen-md-down .xwjyaW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-md-down .xwjyaW_modal-header, .xwjyaW_modal-fullscreen-md-down .xwjyaW_modal-footer {
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-md-down .xwjyaW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .xwjyaW_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .xwjyaW_modal-fullscreen-lg-down .xwjyaW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-lg-down .xwjyaW_modal-header, .xwjyaW_modal-fullscreen-lg-down .xwjyaW_modal-footer {
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-lg-down .xwjyaW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .xwjyaW_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .xwjyaW_modal-fullscreen-xl-down .xwjyaW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-xl-down .xwjyaW_modal-header, .xwjyaW_modal-fullscreen-xl-down .xwjyaW_modal-footer {
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-xl-down .xwjyaW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .xwjyaW_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .xwjyaW_modal-fullscreen-xxl-down .xwjyaW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-xxl-down .xwjyaW_modal-header, .xwjyaW_modal-fullscreen-xxl-down .xwjyaW_modal-footer {
    border-radius: 0;
  }

  .xwjyaW_modal-fullscreen-xxl-down .xwjyaW_modal-body {
    overflow-y: auto;
  }
}

.xwjyaW_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.xwjyaW_tooltip.xwjyaW_show {
  opacity: var(--bs-tooltip-opacity);
}

.xwjyaW_tooltip .xwjyaW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.xwjyaW_tooltip .xwjyaW_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.xwjyaW_bs-tooltip-top .xwjyaW_tooltip-arrow, .xwjyaW_bs-tooltip-auto[data-popper-placement^="top"] .xwjyaW_tooltip-arrow {
  bottom: 0;
}

.xwjyaW_bs-tooltip-top .xwjyaW_tooltip-arrow:before, .xwjyaW_bs-tooltip-auto[data-popper-placement^="top"] .xwjyaW_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.xwjyaW_bs-tooltip-end .xwjyaW_tooltip-arrow, .xwjyaW_bs-tooltip-auto[data-popper-placement^="right"] .xwjyaW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.xwjyaW_bs-tooltip-end .xwjyaW_tooltip-arrow:before, .xwjyaW_bs-tooltip-auto[data-popper-placement^="right"] .xwjyaW_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.xwjyaW_bs-tooltip-bottom .xwjyaW_tooltip-arrow, .xwjyaW_bs-tooltip-auto[data-popper-placement^="bottom"] .xwjyaW_tooltip-arrow {
  top: 0;
}

.xwjyaW_bs-tooltip-bottom .xwjyaW_tooltip-arrow:before, .xwjyaW_bs-tooltip-auto[data-popper-placement^="bottom"] .xwjyaW_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.xwjyaW_bs-tooltip-start .xwjyaW_tooltip-arrow, .xwjyaW_bs-tooltip-auto[data-popper-placement^="left"] .xwjyaW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.xwjyaW_bs-tooltip-start .xwjyaW_tooltip-arrow:before, .xwjyaW_bs-tooltip-auto[data-popper-placement^="left"] .xwjyaW_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.xwjyaW_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.xwjyaW_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.xwjyaW_popover .xwjyaW_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.xwjyaW_popover .xwjyaW_popover-arrow:before, .xwjyaW_popover .xwjyaW_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.xwjyaW_bs-popover-top > .xwjyaW_popover-arrow, .xwjyaW_bs-popover-auto[data-popper-placement^="top"] > .xwjyaW_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.xwjyaW_bs-popover-top > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="top"] > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-top > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="top"] > .xwjyaW_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.xwjyaW_bs-popover-top > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="top"] > .xwjyaW_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.xwjyaW_bs-popover-top > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="top"] > .xwjyaW_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.xwjyaW_bs-popover-end > .xwjyaW_popover-arrow, .xwjyaW_bs-popover-auto[data-popper-placement^="right"] > .xwjyaW_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.xwjyaW_bs-popover-end > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="right"] > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-end > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="right"] > .xwjyaW_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.xwjyaW_bs-popover-end > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="right"] > .xwjyaW_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.xwjyaW_bs-popover-end > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="right"] > .xwjyaW_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.xwjyaW_bs-popover-bottom > .xwjyaW_popover-arrow, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] > .xwjyaW_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.xwjyaW_bs-popover-bottom > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-bottom > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] > .xwjyaW_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.xwjyaW_bs-popover-bottom > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] > .xwjyaW_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.xwjyaW_bs-popover-bottom > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] > .xwjyaW_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.xwjyaW_bs-popover-bottom .xwjyaW_popover-header:before, .xwjyaW_bs-popover-auto[data-popper-placement^="bottom"] .xwjyaW_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.xwjyaW_bs-popover-start > .xwjyaW_popover-arrow, .xwjyaW_bs-popover-auto[data-popper-placement^="left"] > .xwjyaW_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.xwjyaW_bs-popover-start > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="left"] > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-start > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="left"] > .xwjyaW_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.xwjyaW_bs-popover-start > .xwjyaW_popover-arrow:before, .xwjyaW_bs-popover-auto[data-popper-placement^="left"] > .xwjyaW_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.xwjyaW_bs-popover-start > .xwjyaW_popover-arrow:after, .xwjyaW_bs-popover-auto[data-popper-placement^="left"] > .xwjyaW_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.xwjyaW_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.xwjyaW_popover-header:empty {
  display: none;
}

.xwjyaW_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.xwjyaW_carousel {
  position: relative;
}

.xwjyaW_carousel.xwjyaW_pointer-event {
  touch-action: pan-y;
}

.xwjyaW_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.xwjyaW_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.xwjyaW_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_carousel-item {
    transition: none;
  }
}

.xwjyaW_carousel-item.xwjyaW_active, .xwjyaW_carousel-item-next, .xwjyaW_carousel-item-prev {
  display: block;
}

.xwjyaW_carousel-item-next:not(.xwjyaW_carousel-item-start), .xwjyaW_active.xwjyaW_carousel-item-end {
  transform: translateX(100%);
}

.xwjyaW_carousel-item-prev:not(.xwjyaW_carousel-item-end), .xwjyaW_active.xwjyaW_carousel-item-start {
  transform: translateX(-100%);
}

.xwjyaW_carousel-fade .xwjyaW_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.xwjyaW_carousel-fade .xwjyaW_carousel-item.xwjyaW_active, .xwjyaW_carousel-fade .xwjyaW_carousel-item-next.xwjyaW_carousel-item-start, .xwjyaW_carousel-fade .xwjyaW_carousel-item-prev.xwjyaW_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.xwjyaW_carousel-fade .xwjyaW_active.xwjyaW_carousel-item-start, .xwjyaW_carousel-fade .xwjyaW_active.xwjyaW_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_carousel-fade .xwjyaW_active.xwjyaW_carousel-item-start, .xwjyaW_carousel-fade .xwjyaW_active.xwjyaW_carousel-item-end {
    transition: none;
  }
}

.xwjyaW_carousel-control-prev, .xwjyaW_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_carousel-control-prev, .xwjyaW_carousel-control-next {
    transition: none;
  }
}

.xwjyaW_carousel-control-prev:hover, .xwjyaW_carousel-control-prev:focus, .xwjyaW_carousel-control-next:hover, .xwjyaW_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.xwjyaW_carousel-control-prev {
  left: 0;
}

.xwjyaW_carousel-control-next {
  right: 0;
}

.xwjyaW_carousel-control-prev-icon, .xwjyaW_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.xwjyaW_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.xwjyaW_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.xwjyaW_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.xwjyaW_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.xwjyaW_carousel-indicators .xwjyaW_active {
  opacity: 1;
}

.xwjyaW_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.xwjyaW_carousel-dark .xwjyaW_carousel-control-prev-icon, .xwjyaW_carousel-dark .xwjyaW_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.xwjyaW_carousel-dark .xwjyaW_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.xwjyaW_carousel-dark .xwjyaW_carousel-caption {
  color: #000;
}

.xwjyaW_spinner-grow, .xwjyaW_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes xwjyaW_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.xwjyaW_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.xwjyaW_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes xwjyaW_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.xwjyaW_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.xwjyaW_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_spinner-border, .xwjyaW_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.xwjyaW_offcanvas, .xwjyaW_offcanvas-xxl, .xwjyaW_offcanvas-xl, .xwjyaW_offcanvas-lg, .xwjyaW_offcanvas-md, .xwjyaW_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .xwjyaW_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .xwjyaW_offcanvas-sm.xwjyaW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .xwjyaW_offcanvas-sm.xwjyaW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .xwjyaW_offcanvas-sm.xwjyaW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .xwjyaW_offcanvas-sm.xwjyaW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .xwjyaW_offcanvas-sm.xwjyaW_showing, .xwjyaW_offcanvas-sm.xwjyaW_show:not(.xwjyaW_hiding) {
    transform: none;
  }

  .xwjyaW_offcanvas-sm.xwjyaW_showing, .xwjyaW_offcanvas-sm.xwjyaW_hiding, .xwjyaW_offcanvas-sm.xwjyaW_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .xwjyaW_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .xwjyaW_offcanvas-sm .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_offcanvas-sm .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .xwjyaW_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .xwjyaW_offcanvas-md.xwjyaW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .xwjyaW_offcanvas-md.xwjyaW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .xwjyaW_offcanvas-md.xwjyaW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .xwjyaW_offcanvas-md.xwjyaW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .xwjyaW_offcanvas-md.xwjyaW_showing, .xwjyaW_offcanvas-md.xwjyaW_show:not(.xwjyaW_hiding) {
    transform: none;
  }

  .xwjyaW_offcanvas-md.xwjyaW_showing, .xwjyaW_offcanvas-md.xwjyaW_hiding, .xwjyaW_offcanvas-md.xwjyaW_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .xwjyaW_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .xwjyaW_offcanvas-md .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_offcanvas-md .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .xwjyaW_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .xwjyaW_offcanvas-lg.xwjyaW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .xwjyaW_offcanvas-lg.xwjyaW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .xwjyaW_offcanvas-lg.xwjyaW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .xwjyaW_offcanvas-lg.xwjyaW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .xwjyaW_offcanvas-lg.xwjyaW_showing, .xwjyaW_offcanvas-lg.xwjyaW_show:not(.xwjyaW_hiding) {
    transform: none;
  }

  .xwjyaW_offcanvas-lg.xwjyaW_showing, .xwjyaW_offcanvas-lg.xwjyaW_hiding, .xwjyaW_offcanvas-lg.xwjyaW_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .xwjyaW_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .xwjyaW_offcanvas-lg .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_offcanvas-lg .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .xwjyaW_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .xwjyaW_offcanvas-xl.xwjyaW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .xwjyaW_offcanvas-xl.xwjyaW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .xwjyaW_offcanvas-xl.xwjyaW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .xwjyaW_offcanvas-xl.xwjyaW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .xwjyaW_offcanvas-xl.xwjyaW_showing, .xwjyaW_offcanvas-xl.xwjyaW_show:not(.xwjyaW_hiding) {
    transform: none;
  }

  .xwjyaW_offcanvas-xl.xwjyaW_showing, .xwjyaW_offcanvas-xl.xwjyaW_hiding, .xwjyaW_offcanvas-xl.xwjyaW_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .xwjyaW_offcanvas-xl .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_offcanvas-xl .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .xwjyaW_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .xwjyaW_offcanvas-xxl.xwjyaW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .xwjyaW_offcanvas-xxl.xwjyaW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .xwjyaW_offcanvas-xxl.xwjyaW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .xwjyaW_offcanvas-xxl.xwjyaW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .xwjyaW_offcanvas-xxl.xwjyaW_showing, .xwjyaW_offcanvas-xxl.xwjyaW_show:not(.xwjyaW_hiding) {
    transform: none;
  }

  .xwjyaW_offcanvas-xxl.xwjyaW_showing, .xwjyaW_offcanvas-xxl.xwjyaW_hiding, .xwjyaW_offcanvas-xxl.xwjyaW_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .xwjyaW_offcanvas-xxl .xwjyaW_offcanvas-header {
    display: none;
  }

  .xwjyaW_offcanvas-xxl .xwjyaW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.xwjyaW_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .xwjyaW_offcanvas {
    transition: none;
  }
}

.xwjyaW_offcanvas.xwjyaW_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.xwjyaW_offcanvas.xwjyaW_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.xwjyaW_offcanvas.xwjyaW_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.xwjyaW_offcanvas.xwjyaW_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.xwjyaW_offcanvas.xwjyaW_showing, .xwjyaW_offcanvas.xwjyaW_show:not(.xwjyaW_hiding) {
  transform: none;
}

.xwjyaW_offcanvas.xwjyaW_showing, .xwjyaW_offcanvas.xwjyaW_hiding, .xwjyaW_offcanvas.xwjyaW_show {
  visibility: visible;
}

.xwjyaW_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.xwjyaW_offcanvas-backdrop.xwjyaW_fade {
  opacity: 0;
}

.xwjyaW_offcanvas-backdrop.xwjyaW_show {
  opacity: .5;
}

.xwjyaW_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.xwjyaW_offcanvas-header .xwjyaW_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.xwjyaW_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.xwjyaW_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.xwjyaW_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.xwjyaW_placeholder.xwjyaW_btn:before {
  content: "";
  display: inline-block;
}

.xwjyaW_placeholder-xs {
  min-height: .6em;
}

.xwjyaW_placeholder-sm {
  min-height: .8em;
}

.xwjyaW_placeholder-lg {
  min-height: 1.2em;
}

.xwjyaW_placeholder-glow .xwjyaW_placeholder {
  animation: 2s ease-in-out infinite xwjyaW_placeholder-glow;
}

@keyframes xwjyaW_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.xwjyaW_placeholder-wave {
  animation: 2s linear infinite xwjyaW_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes xwjyaW_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.xwjyaW_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.xwjyaW_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.xwjyaW_link-primary {
  color: #4c469c !important;
}

.xwjyaW_link-primary:hover, .xwjyaW_link-primary:focus {
  color: #3d387d !important;
}

.xwjyaW_link-secondary {
  color: #6c757d !important;
}

.xwjyaW_link-secondary:hover, .xwjyaW_link-secondary:focus {
  color: #565e64 !important;
}

.xwjyaW_link-success {
  color: #198754 !important;
}

.xwjyaW_link-success:hover, .xwjyaW_link-success:focus {
  color: #146c43 !important;
}

.xwjyaW_link-info {
  color: #0dcaf0 !important;
}

.xwjyaW_link-info:hover, .xwjyaW_link-info:focus {
  color: #3dd5f3 !important;
}

.xwjyaW_link-warning {
  color: #ffc107 !important;
}

.xwjyaW_link-warning:hover, .xwjyaW_link-warning:focus {
  color: #ffcd39 !important;
}

.xwjyaW_link-danger {
  color: #dc3545 !important;
}

.xwjyaW_link-danger:hover, .xwjyaW_link-danger:focus {
  color: #b02a37 !important;
}

.xwjyaW_link-light {
  color: #f8f9fa !important;
}

.xwjyaW_link-light:hover, .xwjyaW_link-light:focus {
  color: #f9fafb !important;
}

.xwjyaW_link-dark {
  color: #212529 !important;
}

.xwjyaW_link-dark:hover, .xwjyaW_link-dark:focus {
  color: #1a1e21 !important;
}

.xwjyaW_ratio {
  width: 100%;
  position: relative;
}

.xwjyaW_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.xwjyaW_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.xwjyaW_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.xwjyaW_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.xwjyaW_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.xwjyaW_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.xwjyaW_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.xwjyaW_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.xwjyaW_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.xwjyaW_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .xwjyaW_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .xwjyaW_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .xwjyaW_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .xwjyaW_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .xwjyaW_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .xwjyaW_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .xwjyaW_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .xwjyaW_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.xwjyaW_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.xwjyaW_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.xwjyaW_visually-hidden, .xwjyaW_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.xwjyaW_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.xwjyaW_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.xwjyaW_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.xwjyaW_align-baseline {
  vertical-align: baseline !important;
}

.xwjyaW_align-top {
  vertical-align: top !important;
}

.xwjyaW_align-middle {
  vertical-align: middle !important;
}

.xwjyaW_align-bottom {
  vertical-align: bottom !important;
}

.xwjyaW_align-text-bottom {
  vertical-align: text-bottom !important;
}

.xwjyaW_align-text-top {
  vertical-align: text-top !important;
}

.xwjyaW_float-start {
  float: left !important;
}

.xwjyaW_float-end {
  float: right !important;
}

.xwjyaW_float-none {
  float: none !important;
}

.xwjyaW_opacity-0 {
  opacity: 0 !important;
}

.xwjyaW_opacity-25 {
  opacity: .25 !important;
}

.xwjyaW_opacity-50 {
  opacity: .5 !important;
}

.xwjyaW_opacity-75 {
  opacity: .75 !important;
}

.xwjyaW_opacity-100 {
  opacity: 1 !important;
}

.xwjyaW_overflow-auto {
  overflow: auto !important;
}

.xwjyaW_overflow-hidden {
  overflow: hidden !important;
}

.xwjyaW_overflow-visible {
  overflow: visible !important;
}

.xwjyaW_overflow-scroll {
  overflow: scroll !important;
}

.xwjyaW_d-inline {
  display: inline !important;
}

.xwjyaW_d-inline-block {
  display: inline-block !important;
}

.xwjyaW_d-block {
  display: block !important;
}

.xwjyaW_d-grid {
  display: grid !important;
}

.xwjyaW_d-table {
  display: table !important;
}

.xwjyaW_d-table-row {
  display: table-row !important;
}

.xwjyaW_d-table-cell {
  display: table-cell !important;
}

.xwjyaW_d-flex {
  display: flex !important;
}

.xwjyaW_d-inline-flex {
  display: inline-flex !important;
}

.xwjyaW_d-none {
  display: none !important;
}

.xwjyaW_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.xwjyaW_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.xwjyaW_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.xwjyaW_shadow-none {
  box-shadow: none !important;
}

.xwjyaW_position-static {
  position: static !important;
}

.xwjyaW_position-relative {
  position: relative !important;
}

.xwjyaW_position-absolute {
  position: absolute !important;
}

.xwjyaW_position-fixed {
  position: fixed !important;
}

.xwjyaW_position-sticky {
  position: sticky !important;
}

.xwjyaW_top-0 {
  top: 0 !important;
}

.xwjyaW_top-50 {
  top: 50% !important;
}

.xwjyaW_top-100 {
  top: 100% !important;
}

.xwjyaW_bottom-0 {
  bottom: 0 !important;
}

.xwjyaW_bottom-50 {
  bottom: 50% !important;
}

.xwjyaW_bottom-100 {
  bottom: 100% !important;
}

.xwjyaW_start-0 {
  left: 0 !important;
}

.xwjyaW_start-50 {
  left: 50% !important;
}

.xwjyaW_start-100 {
  left: 100% !important;
}

.xwjyaW_end-0 {
  right: 0 !important;
}

.xwjyaW_end-50 {
  right: 50% !important;
}

.xwjyaW_end-100 {
  right: 100% !important;
}

.xwjyaW_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.xwjyaW_translate-middle-x {
  transform: translateX(-50%) !important;
}

.xwjyaW_translate-middle-y {
  transform: translateY(-50%) !important;
}

.xwjyaW_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.xwjyaW_border-0 {
  border: 0 !important;
}

.xwjyaW_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.xwjyaW_border-top-0 {
  border-top: 0 !important;
}

.xwjyaW_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.xwjyaW_border-end-0 {
  border-right: 0 !important;
}

.xwjyaW_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.xwjyaW_border-bottom-0 {
  border-bottom: 0 !important;
}

.xwjyaW_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.xwjyaW_border-start-0 {
  border-left: 0 !important;
}

.xwjyaW_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.xwjyaW_border-1 {
  --bs-border-width: 1px;
}

.xwjyaW_border-2 {
  --bs-border-width: 2px;
}

.xwjyaW_border-3 {
  --bs-border-width: 3px;
}

.xwjyaW_border-4 {
  --bs-border-width: 4px;
}

.xwjyaW_border-5 {
  --bs-border-width: 5px;
}

.xwjyaW_border-opacity-10 {
  --bs-border-opacity: .1;
}

.xwjyaW_border-opacity-25 {
  --bs-border-opacity: .25;
}

.xwjyaW_border-opacity-50 {
  --bs-border-opacity: .5;
}

.xwjyaW_border-opacity-75 {
  --bs-border-opacity: .75;
}

.xwjyaW_border-opacity-100 {
  --bs-border-opacity: 1;
}

.xwjyaW_w-25 {
  width: 25% !important;
}

.xwjyaW_w-50 {
  width: 50% !important;
}

.xwjyaW_w-75 {
  width: 75% !important;
}

.xwjyaW_w-100 {
  width: 100% !important;
}

.xwjyaW_w-auto {
  width: auto !important;
}

.xwjyaW_mw-100 {
  max-width: 100% !important;
}

.xwjyaW_vw-100 {
  width: 100vw !important;
}

.xwjyaW_min-vw-100 {
  min-width: 100vw !important;
}

.xwjyaW_h-25 {
  height: 25% !important;
}

.xwjyaW_h-50 {
  height: 50% !important;
}

.xwjyaW_h-75 {
  height: 75% !important;
}

.xwjyaW_h-100 {
  height: 100% !important;
}

.xwjyaW_h-auto {
  height: auto !important;
}

.xwjyaW_mh-100 {
  max-height: 100% !important;
}

.xwjyaW_vh-100 {
  height: 100vh !important;
}

.xwjyaW_min-vh-100 {
  min-height: 100vh !important;
}

.xwjyaW_flex-fill {
  flex: auto !important;
}

.xwjyaW_flex-row {
  flex-direction: row !important;
}

.xwjyaW_flex-column {
  flex-direction: column !important;
}

.xwjyaW_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.xwjyaW_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.xwjyaW_flex-grow-0 {
  flex-grow: 0 !important;
}

.xwjyaW_flex-grow-1 {
  flex-grow: 1 !important;
}

.xwjyaW_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.xwjyaW_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.xwjyaW_flex-wrap {
  flex-wrap: wrap !important;
}

.xwjyaW_flex-nowrap {
  flex-wrap: nowrap !important;
}

.xwjyaW_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.xwjyaW_justify-content-start {
  justify-content: flex-start !important;
}

.xwjyaW_justify-content-end {
  justify-content: flex-end !important;
}

.xwjyaW_justify-content-center {
  justify-content: center !important;
}

.xwjyaW_justify-content-between {
  justify-content: space-between !important;
}

.xwjyaW_justify-content-around {
  justify-content: space-around !important;
}

.xwjyaW_justify-content-evenly {
  justify-content: space-evenly !important;
}

.xwjyaW_align-items-start {
  align-items: flex-start !important;
}

.xwjyaW_align-items-end {
  align-items: flex-end !important;
}

.xwjyaW_align-items-center {
  align-items: center !important;
}

.xwjyaW_align-items-baseline {
  align-items: baseline !important;
}

.xwjyaW_align-items-stretch {
  align-items: stretch !important;
}

.xwjyaW_align-content-start {
  align-content: flex-start !important;
}

.xwjyaW_align-content-end {
  align-content: flex-end !important;
}

.xwjyaW_align-content-center {
  align-content: center !important;
}

.xwjyaW_align-content-between {
  align-content: space-between !important;
}

.xwjyaW_align-content-around {
  align-content: space-around !important;
}

.xwjyaW_align-content-stretch {
  align-content: stretch !important;
}

.xwjyaW_align-self-auto {
  align-self: auto !important;
}

.xwjyaW_align-self-start {
  align-self: flex-start !important;
}

.xwjyaW_align-self-end {
  align-self: flex-end !important;
}

.xwjyaW_align-self-center {
  align-self: center !important;
}

.xwjyaW_align-self-baseline {
  align-self: baseline !important;
}

.xwjyaW_align-self-stretch {
  align-self: stretch !important;
}

.xwjyaW_order-first {
  order: -1 !important;
}

.xwjyaW_order-0 {
  order: 0 !important;
}

.xwjyaW_order-1 {
  order: 1 !important;
}

.xwjyaW_order-2 {
  order: 2 !important;
}

.xwjyaW_order-3 {
  order: 3 !important;
}

.xwjyaW_order-4 {
  order: 4 !important;
}

.xwjyaW_order-5 {
  order: 5 !important;
}

.xwjyaW_order-last {
  order: 6 !important;
}

.xwjyaW_m-0 {
  margin: 0 !important;
}

.xwjyaW_m-1 {
  margin: .25rem !important;
}

.xwjyaW_m-2 {
  margin: .5rem !important;
}

.xwjyaW_m-3 {
  margin: 1rem !important;
}

.xwjyaW_m-4 {
  margin: 1.5rem !important;
}

.xwjyaW_m-5 {
  margin: 3rem !important;
}

.xwjyaW_m-auto {
  margin: auto !important;
}

.xwjyaW_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.xwjyaW_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.xwjyaW_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.xwjyaW_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.xwjyaW_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.xwjyaW_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.xwjyaW_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.xwjyaW_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.xwjyaW_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.xwjyaW_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.xwjyaW_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.xwjyaW_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.xwjyaW_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.xwjyaW_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.xwjyaW_mt-0 {
  margin-top: 0 !important;
}

.xwjyaW_mt-1 {
  margin-top: .25rem !important;
}

.xwjyaW_mt-2 {
  margin-top: .5rem !important;
}

.xwjyaW_mt-3 {
  margin-top: 1rem !important;
}

.xwjyaW_mt-4 {
  margin-top: 1.5rem !important;
}

.xwjyaW_mt-5 {
  margin-top: 3rem !important;
}

.xwjyaW_mt-auto {
  margin-top: auto !important;
}

.xwjyaW_me-0 {
  margin-right: 0 !important;
}

.xwjyaW_me-1 {
  margin-right: .25rem !important;
}

.xwjyaW_me-2 {
  margin-right: .5rem !important;
}

.xwjyaW_me-3 {
  margin-right: 1rem !important;
}

.xwjyaW_me-4 {
  margin-right: 1.5rem !important;
}

.xwjyaW_me-5 {
  margin-right: 3rem !important;
}

.xwjyaW_me-auto {
  margin-right: auto !important;
}

.xwjyaW_mb-0 {
  margin-bottom: 0 !important;
}

.xwjyaW_mb-1 {
  margin-bottom: .25rem !important;
}

.xwjyaW_mb-2 {
  margin-bottom: .5rem !important;
}

.xwjyaW_mb-3 {
  margin-bottom: 1rem !important;
}

.xwjyaW_mb-4 {
  margin-bottom: 1.5rem !important;
}

.xwjyaW_mb-5 {
  margin-bottom: 3rem !important;
}

.xwjyaW_mb-auto {
  margin-bottom: auto !important;
}

.xwjyaW_ms-0 {
  margin-left: 0 !important;
}

.xwjyaW_ms-1 {
  margin-left: .25rem !important;
}

.xwjyaW_ms-2 {
  margin-left: .5rem !important;
}

.xwjyaW_ms-3 {
  margin-left: 1rem !important;
}

.xwjyaW_ms-4 {
  margin-left: 1.5rem !important;
}

.xwjyaW_ms-5 {
  margin-left: 3rem !important;
}

.xwjyaW_ms-auto {
  margin-left: auto !important;
}

.xwjyaW_p-0 {
  padding: 0 !important;
}

.xwjyaW_p-1 {
  padding: .25rem !important;
}

.xwjyaW_p-2 {
  padding: .5rem !important;
}

.xwjyaW_p-3 {
  padding: 1rem !important;
}

.xwjyaW_p-4 {
  padding: 1.5rem !important;
}

.xwjyaW_p-5 {
  padding: 3rem !important;
}

.xwjyaW_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.xwjyaW_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.xwjyaW_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.xwjyaW_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.xwjyaW_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.xwjyaW_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.xwjyaW_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.xwjyaW_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.xwjyaW_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.xwjyaW_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.xwjyaW_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.xwjyaW_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.xwjyaW_pt-0 {
  padding-top: 0 !important;
}

.xwjyaW_pt-1 {
  padding-top: .25rem !important;
}

.xwjyaW_pt-2 {
  padding-top: .5rem !important;
}

.xwjyaW_pt-3 {
  padding-top: 1rem !important;
}

.xwjyaW_pt-4 {
  padding-top: 1.5rem !important;
}

.xwjyaW_pt-5 {
  padding-top: 3rem !important;
}

.xwjyaW_pe-0 {
  padding-right: 0 !important;
}

.xwjyaW_pe-1 {
  padding-right: .25rem !important;
}

.xwjyaW_pe-2 {
  padding-right: .5rem !important;
}

.xwjyaW_pe-3 {
  padding-right: 1rem !important;
}

.xwjyaW_pe-4 {
  padding-right: 1.5rem !important;
}

.xwjyaW_pe-5 {
  padding-right: 3rem !important;
}

.xwjyaW_pb-0 {
  padding-bottom: 0 !important;
}

.xwjyaW_pb-1 {
  padding-bottom: .25rem !important;
}

.xwjyaW_pb-2 {
  padding-bottom: .5rem !important;
}

.xwjyaW_pb-3 {
  padding-bottom: 1rem !important;
}

.xwjyaW_pb-4 {
  padding-bottom: 1.5rem !important;
}

.xwjyaW_pb-5 {
  padding-bottom: 3rem !important;
}

.xwjyaW_ps-0 {
  padding-left: 0 !important;
}

.xwjyaW_ps-1 {
  padding-left: .25rem !important;
}

.xwjyaW_ps-2 {
  padding-left: .5rem !important;
}

.xwjyaW_ps-3 {
  padding-left: 1rem !important;
}

.xwjyaW_ps-4 {
  padding-left: 1.5rem !important;
}

.xwjyaW_ps-5 {
  padding-left: 3rem !important;
}

.xwjyaW_gap-0 {
  gap: 0 !important;
}

.xwjyaW_gap-1 {
  gap: .25rem !important;
}

.xwjyaW_gap-2 {
  gap: .5rem !important;
}

.xwjyaW_gap-3 {
  gap: 1rem !important;
}

.xwjyaW_gap-4 {
  gap: 1.5rem !important;
}

.xwjyaW_gap-5 {
  gap: 3rem !important;
}

.xwjyaW_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.xwjyaW_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.xwjyaW_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.xwjyaW_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.xwjyaW_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.xwjyaW_fs-5 {
  font-size: 1.25rem !important;
}

.xwjyaW_fs-6 {
  font-size: 1rem !important;
}

.xwjyaW_fst-italic {
  font-style: italic !important;
}

.xwjyaW_fst-normal {
  font-style: normal !important;
}

.xwjyaW_fw-light {
  font-weight: 300 !important;
}

.xwjyaW_fw-lighter {
  font-weight: lighter !important;
}

.xwjyaW_fw-normal {
  font-weight: 400 !important;
}

.xwjyaW_fw-bold {
  font-weight: 700 !important;
}

.xwjyaW_fw-semibold {
  font-weight: 600 !important;
}

.xwjyaW_fw-bolder {
  font-weight: bolder !important;
}

.xwjyaW_lh-1 {
  line-height: 1 !important;
}

.xwjyaW_lh-sm {
  line-height: 1.25 !important;
}

.xwjyaW_lh-base {
  line-height: 1.5 !important;
}

.xwjyaW_lh-lg {
  line-height: 2 !important;
}

.xwjyaW_text-start {
  text-align: left !important;
}

.xwjyaW_text-end {
  text-align: right !important;
}

.xwjyaW_text-center {
  text-align: center !important;
}

.xwjyaW_text-decoration-none {
  text-decoration: none !important;
}

.xwjyaW_text-decoration-underline {
  text-decoration: underline !important;
}

.xwjyaW_text-decoration-line-through {
  text-decoration: line-through !important;
}

.xwjyaW_text-lowercase {
  text-transform: lowercase !important;
}

.xwjyaW_text-uppercase {
  text-transform: uppercase !important;
}

.xwjyaW_text-capitalize {
  text-transform: capitalize !important;
}

.xwjyaW_text-wrap {
  white-space: normal !important;
}

.xwjyaW_text-nowrap {
  white-space: nowrap !important;
}

.xwjyaW_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.xwjyaW_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.xwjyaW_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.xwjyaW_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.xwjyaW_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.xwjyaW_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.xwjyaW_text-opacity-25 {
  --bs-text-opacity: .25;
}

.xwjyaW_text-opacity-50 {
  --bs-text-opacity: .5;
}

.xwjyaW_text-opacity-75 {
  --bs-text-opacity: .75;
}

.xwjyaW_text-opacity-100 {
  --bs-text-opacity: 1;
}

.xwjyaW_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.xwjyaW_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.xwjyaW_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.xwjyaW_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.xwjyaW_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.xwjyaW_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.xwjyaW_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.xwjyaW_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.xwjyaW_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.xwjyaW_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.xwjyaW_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.xwjyaW_pe-none {
  pointer-events: none !important;
}

.xwjyaW_pe-auto {
  pointer-events: auto !important;
}

.xwjyaW_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.xwjyaW_rounded-0 {
  border-radius: 0 !important;
}

.xwjyaW_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.xwjyaW_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.xwjyaW_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.xwjyaW_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.xwjyaW_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.xwjyaW_rounded-circle {
  border-radius: 50% !important;
}

.xwjyaW_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.xwjyaW_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.xwjyaW_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.xwjyaW_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.xwjyaW_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.xwjyaW_visible {
  visibility: visible !important;
}

.xwjyaW_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .xwjyaW_float-sm-start {
    float: left !important;
  }

  .xwjyaW_float-sm-end {
    float: right !important;
  }

  .xwjyaW_float-sm-none {
    float: none !important;
  }

  .xwjyaW_d-sm-inline {
    display: inline !important;
  }

  .xwjyaW_d-sm-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-sm-block {
    display: block !important;
  }

  .xwjyaW_d-sm-grid {
    display: grid !important;
  }

  .xwjyaW_d-sm-table {
    display: table !important;
  }

  .xwjyaW_d-sm-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-sm-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-sm-flex {
    display: flex !important;
  }

  .xwjyaW_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-sm-none {
    display: none !important;
  }

  .xwjyaW_flex-sm-fill {
    flex: auto !important;
  }

  .xwjyaW_flex-sm-row {
    flex-direction: row !important;
  }

  .xwjyaW_flex-sm-column {
    flex-direction: column !important;
  }

  .xwjyaW_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xwjyaW_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .xwjyaW_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .xwjyaW_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .xwjyaW_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xwjyaW_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .xwjyaW_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .xwjyaW_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .xwjyaW_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xwjyaW_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .xwjyaW_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .xwjyaW_justify-content-sm-center {
    justify-content: center !important;
  }

  .xwjyaW_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .xwjyaW_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .xwjyaW_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .xwjyaW_align-items-sm-start {
    align-items: flex-start !important;
  }

  .xwjyaW_align-items-sm-end {
    align-items: flex-end !important;
  }

  .xwjyaW_align-items-sm-center {
    align-items: center !important;
  }

  .xwjyaW_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .xwjyaW_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .xwjyaW_align-content-sm-start {
    align-content: flex-start !important;
  }

  .xwjyaW_align-content-sm-end {
    align-content: flex-end !important;
  }

  .xwjyaW_align-content-sm-center {
    align-content: center !important;
  }

  .xwjyaW_align-content-sm-between {
    align-content: space-between !important;
  }

  .xwjyaW_align-content-sm-around {
    align-content: space-around !important;
  }

  .xwjyaW_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .xwjyaW_align-self-sm-auto {
    align-self: auto !important;
  }

  .xwjyaW_align-self-sm-start {
    align-self: flex-start !important;
  }

  .xwjyaW_align-self-sm-end {
    align-self: flex-end !important;
  }

  .xwjyaW_align-self-sm-center {
    align-self: center !important;
  }

  .xwjyaW_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .xwjyaW_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .xwjyaW_order-sm-first {
    order: -1 !important;
  }

  .xwjyaW_order-sm-0 {
    order: 0 !important;
  }

  .xwjyaW_order-sm-1 {
    order: 1 !important;
  }

  .xwjyaW_order-sm-2 {
    order: 2 !important;
  }

  .xwjyaW_order-sm-3 {
    order: 3 !important;
  }

  .xwjyaW_order-sm-4 {
    order: 4 !important;
  }

  .xwjyaW_order-sm-5 {
    order: 5 !important;
  }

  .xwjyaW_order-sm-last {
    order: 6 !important;
  }

  .xwjyaW_m-sm-0 {
    margin: 0 !important;
  }

  .xwjyaW_m-sm-1 {
    margin: .25rem !important;
  }

  .xwjyaW_m-sm-2 {
    margin: .5rem !important;
  }

  .xwjyaW_m-sm-3 {
    margin: 1rem !important;
  }

  .xwjyaW_m-sm-4 {
    margin: 1.5rem !important;
  }

  .xwjyaW_m-sm-5 {
    margin: 3rem !important;
  }

  .xwjyaW_m-sm-auto {
    margin: auto !important;
  }

  .xwjyaW_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xwjyaW_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xwjyaW_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xwjyaW_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xwjyaW_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xwjyaW_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xwjyaW_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xwjyaW_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xwjyaW_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xwjyaW_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xwjyaW_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xwjyaW_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xwjyaW_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xwjyaW_mt-sm-0 {
    margin-top: 0 !important;
  }

  .xwjyaW_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .xwjyaW_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .xwjyaW_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .xwjyaW_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .xwjyaW_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .xwjyaW_mt-sm-auto {
    margin-top: auto !important;
  }

  .xwjyaW_me-sm-0 {
    margin-right: 0 !important;
  }

  .xwjyaW_me-sm-1 {
    margin-right: .25rem !important;
  }

  .xwjyaW_me-sm-2 {
    margin-right: .5rem !important;
  }

  .xwjyaW_me-sm-3 {
    margin-right: 1rem !important;
  }

  .xwjyaW_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .xwjyaW_me-sm-5 {
    margin-right: 3rem !important;
  }

  .xwjyaW_me-sm-auto {
    margin-right: auto !important;
  }

  .xwjyaW_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .xwjyaW_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .xwjyaW_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .xwjyaW_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .xwjyaW_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .xwjyaW_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .xwjyaW_ms-sm-0 {
    margin-left: 0 !important;
  }

  .xwjyaW_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .xwjyaW_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .xwjyaW_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .xwjyaW_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .xwjyaW_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .xwjyaW_ms-sm-auto {
    margin-left: auto !important;
  }

  .xwjyaW_p-sm-0 {
    padding: 0 !important;
  }

  .xwjyaW_p-sm-1 {
    padding: .25rem !important;
  }

  .xwjyaW_p-sm-2 {
    padding: .5rem !important;
  }

  .xwjyaW_p-sm-3 {
    padding: 1rem !important;
  }

  .xwjyaW_p-sm-4 {
    padding: 1.5rem !important;
  }

  .xwjyaW_p-sm-5 {
    padding: 3rem !important;
  }

  .xwjyaW_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xwjyaW_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xwjyaW_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xwjyaW_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xwjyaW_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xwjyaW_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xwjyaW_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xwjyaW_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xwjyaW_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xwjyaW_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xwjyaW_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xwjyaW_pt-sm-0 {
    padding-top: 0 !important;
  }

  .xwjyaW_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .xwjyaW_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .xwjyaW_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .xwjyaW_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .xwjyaW_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .xwjyaW_pe-sm-0 {
    padding-right: 0 !important;
  }

  .xwjyaW_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .xwjyaW_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .xwjyaW_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .xwjyaW_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .xwjyaW_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .xwjyaW_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .xwjyaW_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .xwjyaW_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .xwjyaW_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .xwjyaW_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .xwjyaW_ps-sm-0 {
    padding-left: 0 !important;
  }

  .xwjyaW_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .xwjyaW_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .xwjyaW_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .xwjyaW_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .xwjyaW_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .xwjyaW_gap-sm-0 {
    gap: 0 !important;
  }

  .xwjyaW_gap-sm-1 {
    gap: .25rem !important;
  }

  .xwjyaW_gap-sm-2 {
    gap: .5rem !important;
  }

  .xwjyaW_gap-sm-3 {
    gap: 1rem !important;
  }

  .xwjyaW_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .xwjyaW_gap-sm-5 {
    gap: 3rem !important;
  }

  .xwjyaW_text-sm-start {
    text-align: left !important;
  }

  .xwjyaW_text-sm-end {
    text-align: right !important;
  }

  .xwjyaW_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .xwjyaW_float-md-start {
    float: left !important;
  }

  .xwjyaW_float-md-end {
    float: right !important;
  }

  .xwjyaW_float-md-none {
    float: none !important;
  }

  .xwjyaW_d-md-inline {
    display: inline !important;
  }

  .xwjyaW_d-md-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-md-block {
    display: block !important;
  }

  .xwjyaW_d-md-grid {
    display: grid !important;
  }

  .xwjyaW_d-md-table {
    display: table !important;
  }

  .xwjyaW_d-md-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-md-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-md-flex {
    display: flex !important;
  }

  .xwjyaW_d-md-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-md-none {
    display: none !important;
  }

  .xwjyaW_flex-md-fill {
    flex: auto !important;
  }

  .xwjyaW_flex-md-row {
    flex-direction: row !important;
  }

  .xwjyaW_flex-md-column {
    flex-direction: column !important;
  }

  .xwjyaW_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xwjyaW_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .xwjyaW_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .xwjyaW_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .xwjyaW_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xwjyaW_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .xwjyaW_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .xwjyaW_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .xwjyaW_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xwjyaW_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .xwjyaW_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .xwjyaW_justify-content-md-center {
    justify-content: center !important;
  }

  .xwjyaW_justify-content-md-between {
    justify-content: space-between !important;
  }

  .xwjyaW_justify-content-md-around {
    justify-content: space-around !important;
  }

  .xwjyaW_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .xwjyaW_align-items-md-start {
    align-items: flex-start !important;
  }

  .xwjyaW_align-items-md-end {
    align-items: flex-end !important;
  }

  .xwjyaW_align-items-md-center {
    align-items: center !important;
  }

  .xwjyaW_align-items-md-baseline {
    align-items: baseline !important;
  }

  .xwjyaW_align-items-md-stretch {
    align-items: stretch !important;
  }

  .xwjyaW_align-content-md-start {
    align-content: flex-start !important;
  }

  .xwjyaW_align-content-md-end {
    align-content: flex-end !important;
  }

  .xwjyaW_align-content-md-center {
    align-content: center !important;
  }

  .xwjyaW_align-content-md-between {
    align-content: space-between !important;
  }

  .xwjyaW_align-content-md-around {
    align-content: space-around !important;
  }

  .xwjyaW_align-content-md-stretch {
    align-content: stretch !important;
  }

  .xwjyaW_align-self-md-auto {
    align-self: auto !important;
  }

  .xwjyaW_align-self-md-start {
    align-self: flex-start !important;
  }

  .xwjyaW_align-self-md-end {
    align-self: flex-end !important;
  }

  .xwjyaW_align-self-md-center {
    align-self: center !important;
  }

  .xwjyaW_align-self-md-baseline {
    align-self: baseline !important;
  }

  .xwjyaW_align-self-md-stretch {
    align-self: stretch !important;
  }

  .xwjyaW_order-md-first {
    order: -1 !important;
  }

  .xwjyaW_order-md-0 {
    order: 0 !important;
  }

  .xwjyaW_order-md-1 {
    order: 1 !important;
  }

  .xwjyaW_order-md-2 {
    order: 2 !important;
  }

  .xwjyaW_order-md-3 {
    order: 3 !important;
  }

  .xwjyaW_order-md-4 {
    order: 4 !important;
  }

  .xwjyaW_order-md-5 {
    order: 5 !important;
  }

  .xwjyaW_order-md-last {
    order: 6 !important;
  }

  .xwjyaW_m-md-0 {
    margin: 0 !important;
  }

  .xwjyaW_m-md-1 {
    margin: .25rem !important;
  }

  .xwjyaW_m-md-2 {
    margin: .5rem !important;
  }

  .xwjyaW_m-md-3 {
    margin: 1rem !important;
  }

  .xwjyaW_m-md-4 {
    margin: 1.5rem !important;
  }

  .xwjyaW_m-md-5 {
    margin: 3rem !important;
  }

  .xwjyaW_m-md-auto {
    margin: auto !important;
  }

  .xwjyaW_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xwjyaW_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xwjyaW_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xwjyaW_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xwjyaW_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xwjyaW_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xwjyaW_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xwjyaW_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xwjyaW_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xwjyaW_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xwjyaW_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xwjyaW_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xwjyaW_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xwjyaW_mt-md-0 {
    margin-top: 0 !important;
  }

  .xwjyaW_mt-md-1 {
    margin-top: .25rem !important;
  }

  .xwjyaW_mt-md-2 {
    margin-top: .5rem !important;
  }

  .xwjyaW_mt-md-3 {
    margin-top: 1rem !important;
  }

  .xwjyaW_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .xwjyaW_mt-md-5 {
    margin-top: 3rem !important;
  }

  .xwjyaW_mt-md-auto {
    margin-top: auto !important;
  }

  .xwjyaW_me-md-0 {
    margin-right: 0 !important;
  }

  .xwjyaW_me-md-1 {
    margin-right: .25rem !important;
  }

  .xwjyaW_me-md-2 {
    margin-right: .5rem !important;
  }

  .xwjyaW_me-md-3 {
    margin-right: 1rem !important;
  }

  .xwjyaW_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .xwjyaW_me-md-5 {
    margin-right: 3rem !important;
  }

  .xwjyaW_me-md-auto {
    margin-right: auto !important;
  }

  .xwjyaW_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .xwjyaW_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .xwjyaW_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .xwjyaW_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .xwjyaW_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .xwjyaW_mb-md-auto {
    margin-bottom: auto !important;
  }

  .xwjyaW_ms-md-0 {
    margin-left: 0 !important;
  }

  .xwjyaW_ms-md-1 {
    margin-left: .25rem !important;
  }

  .xwjyaW_ms-md-2 {
    margin-left: .5rem !important;
  }

  .xwjyaW_ms-md-3 {
    margin-left: 1rem !important;
  }

  .xwjyaW_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .xwjyaW_ms-md-5 {
    margin-left: 3rem !important;
  }

  .xwjyaW_ms-md-auto {
    margin-left: auto !important;
  }

  .xwjyaW_p-md-0 {
    padding: 0 !important;
  }

  .xwjyaW_p-md-1 {
    padding: .25rem !important;
  }

  .xwjyaW_p-md-2 {
    padding: .5rem !important;
  }

  .xwjyaW_p-md-3 {
    padding: 1rem !important;
  }

  .xwjyaW_p-md-4 {
    padding: 1.5rem !important;
  }

  .xwjyaW_p-md-5 {
    padding: 3rem !important;
  }

  .xwjyaW_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xwjyaW_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xwjyaW_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xwjyaW_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xwjyaW_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xwjyaW_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xwjyaW_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xwjyaW_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xwjyaW_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xwjyaW_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xwjyaW_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xwjyaW_pt-md-0 {
    padding-top: 0 !important;
  }

  .xwjyaW_pt-md-1 {
    padding-top: .25rem !important;
  }

  .xwjyaW_pt-md-2 {
    padding-top: .5rem !important;
  }

  .xwjyaW_pt-md-3 {
    padding-top: 1rem !important;
  }

  .xwjyaW_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .xwjyaW_pt-md-5 {
    padding-top: 3rem !important;
  }

  .xwjyaW_pe-md-0 {
    padding-right: 0 !important;
  }

  .xwjyaW_pe-md-1 {
    padding-right: .25rem !important;
  }

  .xwjyaW_pe-md-2 {
    padding-right: .5rem !important;
  }

  .xwjyaW_pe-md-3 {
    padding-right: 1rem !important;
  }

  .xwjyaW_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .xwjyaW_pe-md-5 {
    padding-right: 3rem !important;
  }

  .xwjyaW_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .xwjyaW_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .xwjyaW_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .xwjyaW_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .xwjyaW_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .xwjyaW_ps-md-0 {
    padding-left: 0 !important;
  }

  .xwjyaW_ps-md-1 {
    padding-left: .25rem !important;
  }

  .xwjyaW_ps-md-2 {
    padding-left: .5rem !important;
  }

  .xwjyaW_ps-md-3 {
    padding-left: 1rem !important;
  }

  .xwjyaW_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .xwjyaW_ps-md-5 {
    padding-left: 3rem !important;
  }

  .xwjyaW_gap-md-0 {
    gap: 0 !important;
  }

  .xwjyaW_gap-md-1 {
    gap: .25rem !important;
  }

  .xwjyaW_gap-md-2 {
    gap: .5rem !important;
  }

  .xwjyaW_gap-md-3 {
    gap: 1rem !important;
  }

  .xwjyaW_gap-md-4 {
    gap: 1.5rem !important;
  }

  .xwjyaW_gap-md-5 {
    gap: 3rem !important;
  }

  .xwjyaW_text-md-start {
    text-align: left !important;
  }

  .xwjyaW_text-md-end {
    text-align: right !important;
  }

  .xwjyaW_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .xwjyaW_float-lg-start {
    float: left !important;
  }

  .xwjyaW_float-lg-end {
    float: right !important;
  }

  .xwjyaW_float-lg-none {
    float: none !important;
  }

  .xwjyaW_d-lg-inline {
    display: inline !important;
  }

  .xwjyaW_d-lg-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-lg-block {
    display: block !important;
  }

  .xwjyaW_d-lg-grid {
    display: grid !important;
  }

  .xwjyaW_d-lg-table {
    display: table !important;
  }

  .xwjyaW_d-lg-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-lg-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-lg-flex {
    display: flex !important;
  }

  .xwjyaW_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-lg-none {
    display: none !important;
  }

  .xwjyaW_flex-lg-fill {
    flex: auto !important;
  }

  .xwjyaW_flex-lg-row {
    flex-direction: row !important;
  }

  .xwjyaW_flex-lg-column {
    flex-direction: column !important;
  }

  .xwjyaW_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xwjyaW_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .xwjyaW_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .xwjyaW_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .xwjyaW_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xwjyaW_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .xwjyaW_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .xwjyaW_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .xwjyaW_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xwjyaW_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .xwjyaW_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .xwjyaW_justify-content-lg-center {
    justify-content: center !important;
  }

  .xwjyaW_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .xwjyaW_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .xwjyaW_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .xwjyaW_align-items-lg-start {
    align-items: flex-start !important;
  }

  .xwjyaW_align-items-lg-end {
    align-items: flex-end !important;
  }

  .xwjyaW_align-items-lg-center {
    align-items: center !important;
  }

  .xwjyaW_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .xwjyaW_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .xwjyaW_align-content-lg-start {
    align-content: flex-start !important;
  }

  .xwjyaW_align-content-lg-end {
    align-content: flex-end !important;
  }

  .xwjyaW_align-content-lg-center {
    align-content: center !important;
  }

  .xwjyaW_align-content-lg-between {
    align-content: space-between !important;
  }

  .xwjyaW_align-content-lg-around {
    align-content: space-around !important;
  }

  .xwjyaW_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .xwjyaW_align-self-lg-auto {
    align-self: auto !important;
  }

  .xwjyaW_align-self-lg-start {
    align-self: flex-start !important;
  }

  .xwjyaW_align-self-lg-end {
    align-self: flex-end !important;
  }

  .xwjyaW_align-self-lg-center {
    align-self: center !important;
  }

  .xwjyaW_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .xwjyaW_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .xwjyaW_order-lg-first {
    order: -1 !important;
  }

  .xwjyaW_order-lg-0 {
    order: 0 !important;
  }

  .xwjyaW_order-lg-1 {
    order: 1 !important;
  }

  .xwjyaW_order-lg-2 {
    order: 2 !important;
  }

  .xwjyaW_order-lg-3 {
    order: 3 !important;
  }

  .xwjyaW_order-lg-4 {
    order: 4 !important;
  }

  .xwjyaW_order-lg-5 {
    order: 5 !important;
  }

  .xwjyaW_order-lg-last {
    order: 6 !important;
  }

  .xwjyaW_m-lg-0 {
    margin: 0 !important;
  }

  .xwjyaW_m-lg-1 {
    margin: .25rem !important;
  }

  .xwjyaW_m-lg-2 {
    margin: .5rem !important;
  }

  .xwjyaW_m-lg-3 {
    margin: 1rem !important;
  }

  .xwjyaW_m-lg-4 {
    margin: 1.5rem !important;
  }

  .xwjyaW_m-lg-5 {
    margin: 3rem !important;
  }

  .xwjyaW_m-lg-auto {
    margin: auto !important;
  }

  .xwjyaW_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xwjyaW_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xwjyaW_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xwjyaW_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xwjyaW_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xwjyaW_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xwjyaW_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xwjyaW_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xwjyaW_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xwjyaW_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xwjyaW_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xwjyaW_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xwjyaW_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xwjyaW_mt-lg-0 {
    margin-top: 0 !important;
  }

  .xwjyaW_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .xwjyaW_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .xwjyaW_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .xwjyaW_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .xwjyaW_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .xwjyaW_mt-lg-auto {
    margin-top: auto !important;
  }

  .xwjyaW_me-lg-0 {
    margin-right: 0 !important;
  }

  .xwjyaW_me-lg-1 {
    margin-right: .25rem !important;
  }

  .xwjyaW_me-lg-2 {
    margin-right: .5rem !important;
  }

  .xwjyaW_me-lg-3 {
    margin-right: 1rem !important;
  }

  .xwjyaW_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .xwjyaW_me-lg-5 {
    margin-right: 3rem !important;
  }

  .xwjyaW_me-lg-auto {
    margin-right: auto !important;
  }

  .xwjyaW_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .xwjyaW_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .xwjyaW_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .xwjyaW_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .xwjyaW_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .xwjyaW_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .xwjyaW_ms-lg-0 {
    margin-left: 0 !important;
  }

  .xwjyaW_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .xwjyaW_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .xwjyaW_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .xwjyaW_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .xwjyaW_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .xwjyaW_ms-lg-auto {
    margin-left: auto !important;
  }

  .xwjyaW_p-lg-0 {
    padding: 0 !important;
  }

  .xwjyaW_p-lg-1 {
    padding: .25rem !important;
  }

  .xwjyaW_p-lg-2 {
    padding: .5rem !important;
  }

  .xwjyaW_p-lg-3 {
    padding: 1rem !important;
  }

  .xwjyaW_p-lg-4 {
    padding: 1.5rem !important;
  }

  .xwjyaW_p-lg-5 {
    padding: 3rem !important;
  }

  .xwjyaW_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xwjyaW_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xwjyaW_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xwjyaW_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xwjyaW_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xwjyaW_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xwjyaW_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xwjyaW_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xwjyaW_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xwjyaW_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xwjyaW_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xwjyaW_pt-lg-0 {
    padding-top: 0 !important;
  }

  .xwjyaW_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .xwjyaW_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .xwjyaW_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .xwjyaW_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .xwjyaW_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .xwjyaW_pe-lg-0 {
    padding-right: 0 !important;
  }

  .xwjyaW_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .xwjyaW_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .xwjyaW_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .xwjyaW_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .xwjyaW_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .xwjyaW_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .xwjyaW_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .xwjyaW_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .xwjyaW_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .xwjyaW_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .xwjyaW_ps-lg-0 {
    padding-left: 0 !important;
  }

  .xwjyaW_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .xwjyaW_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .xwjyaW_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .xwjyaW_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .xwjyaW_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .xwjyaW_gap-lg-0 {
    gap: 0 !important;
  }

  .xwjyaW_gap-lg-1 {
    gap: .25rem !important;
  }

  .xwjyaW_gap-lg-2 {
    gap: .5rem !important;
  }

  .xwjyaW_gap-lg-3 {
    gap: 1rem !important;
  }

  .xwjyaW_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .xwjyaW_gap-lg-5 {
    gap: 3rem !important;
  }

  .xwjyaW_text-lg-start {
    text-align: left !important;
  }

  .xwjyaW_text-lg-end {
    text-align: right !important;
  }

  .xwjyaW_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_float-xl-start {
    float: left !important;
  }

  .xwjyaW_float-xl-end {
    float: right !important;
  }

  .xwjyaW_float-xl-none {
    float: none !important;
  }

  .xwjyaW_d-xl-inline {
    display: inline !important;
  }

  .xwjyaW_d-xl-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-xl-block {
    display: block !important;
  }

  .xwjyaW_d-xl-grid {
    display: grid !important;
  }

  .xwjyaW_d-xl-table {
    display: table !important;
  }

  .xwjyaW_d-xl-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-xl-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-xl-flex {
    display: flex !important;
  }

  .xwjyaW_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-xl-none {
    display: none !important;
  }

  .xwjyaW_flex-xl-fill {
    flex: auto !important;
  }

  .xwjyaW_flex-xl-row {
    flex-direction: row !important;
  }

  .xwjyaW_flex-xl-column {
    flex-direction: column !important;
  }

  .xwjyaW_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xwjyaW_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .xwjyaW_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .xwjyaW_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .xwjyaW_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xwjyaW_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .xwjyaW_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .xwjyaW_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .xwjyaW_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xwjyaW_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .xwjyaW_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .xwjyaW_justify-content-xl-center {
    justify-content: center !important;
  }

  .xwjyaW_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .xwjyaW_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .xwjyaW_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .xwjyaW_align-items-xl-start {
    align-items: flex-start !important;
  }

  .xwjyaW_align-items-xl-end {
    align-items: flex-end !important;
  }

  .xwjyaW_align-items-xl-center {
    align-items: center !important;
  }

  .xwjyaW_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .xwjyaW_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .xwjyaW_align-content-xl-start {
    align-content: flex-start !important;
  }

  .xwjyaW_align-content-xl-end {
    align-content: flex-end !important;
  }

  .xwjyaW_align-content-xl-center {
    align-content: center !important;
  }

  .xwjyaW_align-content-xl-between {
    align-content: space-between !important;
  }

  .xwjyaW_align-content-xl-around {
    align-content: space-around !important;
  }

  .xwjyaW_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .xwjyaW_align-self-xl-auto {
    align-self: auto !important;
  }

  .xwjyaW_align-self-xl-start {
    align-self: flex-start !important;
  }

  .xwjyaW_align-self-xl-end {
    align-self: flex-end !important;
  }

  .xwjyaW_align-self-xl-center {
    align-self: center !important;
  }

  .xwjyaW_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .xwjyaW_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .xwjyaW_order-xl-first {
    order: -1 !important;
  }

  .xwjyaW_order-xl-0 {
    order: 0 !important;
  }

  .xwjyaW_order-xl-1 {
    order: 1 !important;
  }

  .xwjyaW_order-xl-2 {
    order: 2 !important;
  }

  .xwjyaW_order-xl-3 {
    order: 3 !important;
  }

  .xwjyaW_order-xl-4 {
    order: 4 !important;
  }

  .xwjyaW_order-xl-5 {
    order: 5 !important;
  }

  .xwjyaW_order-xl-last {
    order: 6 !important;
  }

  .xwjyaW_m-xl-0 {
    margin: 0 !important;
  }

  .xwjyaW_m-xl-1 {
    margin: .25rem !important;
  }

  .xwjyaW_m-xl-2 {
    margin: .5rem !important;
  }

  .xwjyaW_m-xl-3 {
    margin: 1rem !important;
  }

  .xwjyaW_m-xl-4 {
    margin: 1.5rem !important;
  }

  .xwjyaW_m-xl-5 {
    margin: 3rem !important;
  }

  .xwjyaW_m-xl-auto {
    margin: auto !important;
  }

  .xwjyaW_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xwjyaW_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xwjyaW_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xwjyaW_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xwjyaW_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xwjyaW_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xwjyaW_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xwjyaW_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xwjyaW_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xwjyaW_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xwjyaW_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xwjyaW_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xwjyaW_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xwjyaW_mt-xl-0 {
    margin-top: 0 !important;
  }

  .xwjyaW_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .xwjyaW_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .xwjyaW_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .xwjyaW_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .xwjyaW_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .xwjyaW_mt-xl-auto {
    margin-top: auto !important;
  }

  .xwjyaW_me-xl-0 {
    margin-right: 0 !important;
  }

  .xwjyaW_me-xl-1 {
    margin-right: .25rem !important;
  }

  .xwjyaW_me-xl-2 {
    margin-right: .5rem !important;
  }

  .xwjyaW_me-xl-3 {
    margin-right: 1rem !important;
  }

  .xwjyaW_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .xwjyaW_me-xl-5 {
    margin-right: 3rem !important;
  }

  .xwjyaW_me-xl-auto {
    margin-right: auto !important;
  }

  .xwjyaW_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .xwjyaW_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .xwjyaW_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .xwjyaW_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .xwjyaW_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .xwjyaW_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .xwjyaW_ms-xl-0 {
    margin-left: 0 !important;
  }

  .xwjyaW_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .xwjyaW_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .xwjyaW_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .xwjyaW_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .xwjyaW_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .xwjyaW_ms-xl-auto {
    margin-left: auto !important;
  }

  .xwjyaW_p-xl-0 {
    padding: 0 !important;
  }

  .xwjyaW_p-xl-1 {
    padding: .25rem !important;
  }

  .xwjyaW_p-xl-2 {
    padding: .5rem !important;
  }

  .xwjyaW_p-xl-3 {
    padding: 1rem !important;
  }

  .xwjyaW_p-xl-4 {
    padding: 1.5rem !important;
  }

  .xwjyaW_p-xl-5 {
    padding: 3rem !important;
  }

  .xwjyaW_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xwjyaW_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xwjyaW_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xwjyaW_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xwjyaW_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xwjyaW_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xwjyaW_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xwjyaW_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xwjyaW_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xwjyaW_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xwjyaW_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xwjyaW_pt-xl-0 {
    padding-top: 0 !important;
  }

  .xwjyaW_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .xwjyaW_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .xwjyaW_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .xwjyaW_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .xwjyaW_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .xwjyaW_pe-xl-0 {
    padding-right: 0 !important;
  }

  .xwjyaW_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .xwjyaW_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .xwjyaW_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .xwjyaW_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .xwjyaW_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .xwjyaW_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .xwjyaW_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .xwjyaW_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .xwjyaW_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .xwjyaW_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .xwjyaW_ps-xl-0 {
    padding-left: 0 !important;
  }

  .xwjyaW_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .xwjyaW_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .xwjyaW_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .xwjyaW_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .xwjyaW_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .xwjyaW_gap-xl-0 {
    gap: 0 !important;
  }

  .xwjyaW_gap-xl-1 {
    gap: .25rem !important;
  }

  .xwjyaW_gap-xl-2 {
    gap: .5rem !important;
  }

  .xwjyaW_gap-xl-3 {
    gap: 1rem !important;
  }

  .xwjyaW_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .xwjyaW_gap-xl-5 {
    gap: 3rem !important;
  }

  .xwjyaW_text-xl-start {
    text-align: left !important;
  }

  .xwjyaW_text-xl-end {
    text-align: right !important;
  }

  .xwjyaW_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .xwjyaW_float-xxl-start {
    float: left !important;
  }

  .xwjyaW_float-xxl-end {
    float: right !important;
  }

  .xwjyaW_float-xxl-none {
    float: none !important;
  }

  .xwjyaW_d-xxl-inline {
    display: inline !important;
  }

  .xwjyaW_d-xxl-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-xxl-block {
    display: block !important;
  }

  .xwjyaW_d-xxl-grid {
    display: grid !important;
  }

  .xwjyaW_d-xxl-table {
    display: table !important;
  }

  .xwjyaW_d-xxl-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-xxl-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-xxl-flex {
    display: flex !important;
  }

  .xwjyaW_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-xxl-none {
    display: none !important;
  }

  .xwjyaW_flex-xxl-fill {
    flex: auto !important;
  }

  .xwjyaW_flex-xxl-row {
    flex-direction: row !important;
  }

  .xwjyaW_flex-xxl-column {
    flex-direction: column !important;
  }

  .xwjyaW_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xwjyaW_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .xwjyaW_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .xwjyaW_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .xwjyaW_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xwjyaW_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .xwjyaW_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .xwjyaW_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .xwjyaW_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xwjyaW_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .xwjyaW_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .xwjyaW_justify-content-xxl-center {
    justify-content: center !important;
  }

  .xwjyaW_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .xwjyaW_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .xwjyaW_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .xwjyaW_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .xwjyaW_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .xwjyaW_align-items-xxl-center {
    align-items: center !important;
  }

  .xwjyaW_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .xwjyaW_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .xwjyaW_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .xwjyaW_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .xwjyaW_align-content-xxl-center {
    align-content: center !important;
  }

  .xwjyaW_align-content-xxl-between {
    align-content: space-between !important;
  }

  .xwjyaW_align-content-xxl-around {
    align-content: space-around !important;
  }

  .xwjyaW_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .xwjyaW_align-self-xxl-auto {
    align-self: auto !important;
  }

  .xwjyaW_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .xwjyaW_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .xwjyaW_align-self-xxl-center {
    align-self: center !important;
  }

  .xwjyaW_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .xwjyaW_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .xwjyaW_order-xxl-first {
    order: -1 !important;
  }

  .xwjyaW_order-xxl-0 {
    order: 0 !important;
  }

  .xwjyaW_order-xxl-1 {
    order: 1 !important;
  }

  .xwjyaW_order-xxl-2 {
    order: 2 !important;
  }

  .xwjyaW_order-xxl-3 {
    order: 3 !important;
  }

  .xwjyaW_order-xxl-4 {
    order: 4 !important;
  }

  .xwjyaW_order-xxl-5 {
    order: 5 !important;
  }

  .xwjyaW_order-xxl-last {
    order: 6 !important;
  }

  .xwjyaW_m-xxl-0 {
    margin: 0 !important;
  }

  .xwjyaW_m-xxl-1 {
    margin: .25rem !important;
  }

  .xwjyaW_m-xxl-2 {
    margin: .5rem !important;
  }

  .xwjyaW_m-xxl-3 {
    margin: 1rem !important;
  }

  .xwjyaW_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .xwjyaW_m-xxl-5 {
    margin: 3rem !important;
  }

  .xwjyaW_m-xxl-auto {
    margin: auto !important;
  }

  .xwjyaW_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xwjyaW_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xwjyaW_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xwjyaW_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xwjyaW_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xwjyaW_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xwjyaW_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xwjyaW_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xwjyaW_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xwjyaW_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xwjyaW_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xwjyaW_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xwjyaW_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xwjyaW_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .xwjyaW_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .xwjyaW_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .xwjyaW_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .xwjyaW_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .xwjyaW_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .xwjyaW_mt-xxl-auto {
    margin-top: auto !important;
  }

  .xwjyaW_me-xxl-0 {
    margin-right: 0 !important;
  }

  .xwjyaW_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .xwjyaW_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .xwjyaW_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .xwjyaW_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .xwjyaW_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .xwjyaW_me-xxl-auto {
    margin-right: auto !important;
  }

  .xwjyaW_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .xwjyaW_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .xwjyaW_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .xwjyaW_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .xwjyaW_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .xwjyaW_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .xwjyaW_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .xwjyaW_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .xwjyaW_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .xwjyaW_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .xwjyaW_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .xwjyaW_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .xwjyaW_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .xwjyaW_ms-xxl-auto {
    margin-left: auto !important;
  }

  .xwjyaW_p-xxl-0 {
    padding: 0 !important;
  }

  .xwjyaW_p-xxl-1 {
    padding: .25rem !important;
  }

  .xwjyaW_p-xxl-2 {
    padding: .5rem !important;
  }

  .xwjyaW_p-xxl-3 {
    padding: 1rem !important;
  }

  .xwjyaW_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .xwjyaW_p-xxl-5 {
    padding: 3rem !important;
  }

  .xwjyaW_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xwjyaW_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xwjyaW_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xwjyaW_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xwjyaW_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xwjyaW_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xwjyaW_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xwjyaW_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xwjyaW_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xwjyaW_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xwjyaW_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xwjyaW_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .xwjyaW_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .xwjyaW_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .xwjyaW_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .xwjyaW_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .xwjyaW_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .xwjyaW_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .xwjyaW_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .xwjyaW_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .xwjyaW_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .xwjyaW_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .xwjyaW_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .xwjyaW_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .xwjyaW_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .xwjyaW_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .xwjyaW_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .xwjyaW_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .xwjyaW_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .xwjyaW_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .xwjyaW_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .xwjyaW_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .xwjyaW_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .xwjyaW_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .xwjyaW_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .xwjyaW_gap-xxl-0 {
    gap: 0 !important;
  }

  .xwjyaW_gap-xxl-1 {
    gap: .25rem !important;
  }

  .xwjyaW_gap-xxl-2 {
    gap: .5rem !important;
  }

  .xwjyaW_gap-xxl-3 {
    gap: 1rem !important;
  }

  .xwjyaW_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .xwjyaW_gap-xxl-5 {
    gap: 3rem !important;
  }

  .xwjyaW_text-xxl-start {
    text-align: left !important;
  }

  .xwjyaW_text-xxl-end {
    text-align: right !important;
  }

  .xwjyaW_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .xwjyaW_fs-1 {
    font-size: 2.5rem !important;
  }

  .xwjyaW_fs-2 {
    font-size: 2rem !important;
  }

  .xwjyaW_fs-3 {
    font-size: 1.75rem !important;
  }

  .xwjyaW_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .xwjyaW_d-print-inline {
    display: inline !important;
  }

  .xwjyaW_d-print-inline-block {
    display: inline-block !important;
  }

  .xwjyaW_d-print-block {
    display: block !important;
  }

  .xwjyaW_d-print-grid {
    display: grid !important;
  }

  .xwjyaW_d-print-table {
    display: table !important;
  }

  .xwjyaW_d-print-table-row {
    display: table-row !important;
  }

  .xwjyaW_d-print-table-cell {
    display: table-cell !important;
  }

  .xwjyaW_d-print-flex {
    display: flex !important;
  }

  .xwjyaW_d-print-inline-flex {
    display: inline-flex !important;
  }

  .xwjyaW_d-print-none {
    display: none !important;
  }
}

.xwjyaW_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.xwjyaW_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.xwjyaW_nav-tabs .xwjyaW_nav-item .xwjyaW_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.xwjyaW_nav-tabs .xwjyaW_nav-item .xwjyaW_nav-link.xwjyaW_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.xwjyaW_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#xwjyaW_root {
  height: 100%;
}

.xwjyaW_highcharts-credits {
  display: none !important;
}

h3, .xwjyaW_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.xwjyaW_modal .xwjyaW_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.xwjyaW_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.xwjyaW_table thead tr th.xwjyaW_col-actions {
  width: 110px !important;
}

table.xwjyaW_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.xwjyaW_table tbody tr.xwjyaW_deleting {
  background-color: #ff323233 !important;
}

.xwjyaW_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.xwjyaW_btn-sm, .xwjyaW_btn-group-sm > .xwjyaW_btn {
  font-size: .6rem !important;
}

.xwjyaW_modal {
  background-color: #0a0a0a4d;
}

.xwjyaW_btn:focus {
  box-shadow: none !important;
}

.xwjyaW_content-filter .xwjyaW_react-datepicker-wrapper {
  width: auto !important;
}

.xwjyaW_content-filter .xwjyaW_react-datepicker-wrapper .xwjyaW_form-control {
  font-size: .85rem !important;
}

.xwjyaW_content-filter .xwjyaW_input-group-text, .xwjyaW_content-filter .xwjyaW_btn {
  height: 2.15rem !important;
}

.xwjyaW_content-filter .xwjyaW_btn {
  border-color: #ccc;
}

.xwjyaW_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.xwjyaW_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.xwjyaW_table > :not(:first-child) {
  border-top: unset !important;
}

.xwjyaW_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.xwjyaW_highcharts-series.xwjyaW_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.xwjyaW_expandable {
  transition: all .5s;
}

.xwjyaW_expandable > .xwjyaW_expander-icon {
  cursor: pointer;
}

.xwjyaW_expandable > .xwjyaW_expander-icon:hover {
  color: #328cfa66;
}

.xwjyaW_expandable.xwjyaW_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .xwjyaW_modal .xwjyaW_modal-xl {
    width: 100% !important;
  }

  .xwjyaW_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.xwjyaW_d-none, .xwjyaW_d-block {
  transition: all .5s;
}

.xwjyaW_signup {
  min-width: 360px;
  flex-direction: column;
  gap: 10px;
  display: flex;
  position: relative;
}

.xwjyaW_signup p {
  text-align: center;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .Cx505a_h6, h5, .Cx505a_h5, h4, .Cx505a_h4, h3, .Cx505a_h3, h2, .Cx505a_h2, h1, .Cx505a_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .Cx505a_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .Cx505a_h1 {
    font-size: 2.5rem;
  }
}

h2, .Cx505a_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .Cx505a_h2 {
    font-size: 2rem;
  }
}

h3, .Cx505a_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .Cx505a_h3 {
    font-size: 1.75rem;
  }
}

h4, .Cx505a_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .Cx505a_h4 {
    font-size: 1.5rem;
  }
}

h5, .Cx505a_h5 {
  font-size: 1.25rem;
}

h6, .Cx505a_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .Cx505a_small {
  font-size: .875em;
}

mark, .Cx505a_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.Cx505a_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.Cx505a_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-1 {
    font-size: 5rem;
  }
}

.Cx505a_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-2 {
    font-size: 4.5rem;
  }
}

.Cx505a_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-3 {
    font-size: 4rem;
  }
}

.Cx505a_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-4 {
    font-size: 3.5rem;
  }
}

.Cx505a_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-5 {
    font-size: 3rem;
  }
}

.Cx505a_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Cx505a_display-6 {
    font-size: 2.5rem;
  }
}

.Cx505a_list-unstyled, .Cx505a_list-inline {
  padding-left: 0;
  list-style: none;
}

.Cx505a_list-inline-item {
  display: inline-block;
}

.Cx505a_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.Cx505a_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.Cx505a_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.Cx505a_blockquote > :last-child {
  margin-bottom: 0;
}

.Cx505a_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.Cx505a_blockquote-footer:before {
  content: "— ";
}

.Cx505a_img-fluid {
  max-width: 100%;
  height: auto;
}

.Cx505a_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.Cx505a_figure {
  display: inline-block;
}

.Cx505a_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.Cx505a_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.Cx505a_container, .Cx505a_container-fluid, .Cx505a_container-xxl, .Cx505a_container-xl, .Cx505a_container-lg, .Cx505a_container-md, .Cx505a_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .Cx505a_container-sm, .Cx505a_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Cx505a_container-md, .Cx505a_container-sm, .Cx505a_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Cx505a_container-lg, .Cx505a_container-md, .Cx505a_container-sm, .Cx505a_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .Cx505a_container-xl, .Cx505a_container-lg, .Cx505a_container-md, .Cx505a_container-sm, .Cx505a_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .Cx505a_container-xxl, .Cx505a_container-xl, .Cx505a_container-lg, .Cx505a_container-md, .Cx505a_container-sm, .Cx505a_container {
    max-width: 1320px;
  }
}

.Cx505a_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.Cx505a_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.Cx505a_col {
  flex: 1 0;
}

.Cx505a_row-cols-auto > * {
  width: auto;
  flex: none;
}

.Cx505a_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.Cx505a_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.Cx505a_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.Cx505a_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.Cx505a_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.Cx505a_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.Cx505a_col-auto {
  width: auto;
  flex: none;
}

.Cx505a_col-1 {
  width: 8.33333%;
  flex: none;
}

.Cx505a_col-2 {
  width: 16.6667%;
  flex: none;
}

.Cx505a_col-3 {
  width: 25%;
  flex: none;
}

.Cx505a_col-4 {
  width: 33.3333%;
  flex: none;
}

.Cx505a_col-5 {
  width: 41.6667%;
  flex: none;
}

.Cx505a_col-6 {
  width: 50%;
  flex: none;
}

.Cx505a_col-7 {
  width: 58.3333%;
  flex: none;
}

.Cx505a_col-8 {
  width: 66.6667%;
  flex: none;
}

.Cx505a_col-9 {
  width: 75%;
  flex: none;
}

.Cx505a_col-10 {
  width: 83.3333%;
  flex: none;
}

.Cx505a_col-11 {
  width: 91.6667%;
  flex: none;
}

.Cx505a_col-12 {
  width: 100%;
  flex: none;
}

.Cx505a_offset-1 {
  margin-left: 8.33333%;
}

.Cx505a_offset-2 {
  margin-left: 16.6667%;
}

.Cx505a_offset-3 {
  margin-left: 25%;
}

.Cx505a_offset-4 {
  margin-left: 33.3333%;
}

.Cx505a_offset-5 {
  margin-left: 41.6667%;
}

.Cx505a_offset-6 {
  margin-left: 50%;
}

.Cx505a_offset-7 {
  margin-left: 58.3333%;
}

.Cx505a_offset-8 {
  margin-left: 66.6667%;
}

.Cx505a_offset-9 {
  margin-left: 75%;
}

.Cx505a_offset-10 {
  margin-left: 83.3333%;
}

.Cx505a_offset-11 {
  margin-left: 91.6667%;
}

.Cx505a_g-0, .Cx505a_gx-0 {
  --bs-gutter-x: 0;
}

.Cx505a_g-0, .Cx505a_gy-0 {
  --bs-gutter-y: 0;
}

.Cx505a_g-1, .Cx505a_gx-1 {
  --bs-gutter-x: .25rem;
}

.Cx505a_g-1, .Cx505a_gy-1 {
  --bs-gutter-y: .25rem;
}

.Cx505a_g-2, .Cx505a_gx-2 {
  --bs-gutter-x: .5rem;
}

.Cx505a_g-2, .Cx505a_gy-2 {
  --bs-gutter-y: .5rem;
}

.Cx505a_g-3, .Cx505a_gx-3 {
  --bs-gutter-x: 1rem;
}

.Cx505a_g-3, .Cx505a_gy-3 {
  --bs-gutter-y: 1rem;
}

.Cx505a_g-4, .Cx505a_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.Cx505a_g-4, .Cx505a_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.Cx505a_g-5, .Cx505a_gx-5 {
  --bs-gutter-x: 3rem;
}

.Cx505a_g-5, .Cx505a_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .Cx505a_col-sm {
    flex: 1 0;
  }

  .Cx505a_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .Cx505a_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .Cx505a_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .Cx505a_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .Cx505a_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .Cx505a_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-sm-auto {
    width: auto;
    flex: none;
  }

  .Cx505a_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .Cx505a_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .Cx505a_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .Cx505a_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .Cx505a_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .Cx505a_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .Cx505a_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .Cx505a_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .Cx505a_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .Cx505a_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .Cx505a_offset-sm-0 {
    margin-left: 0;
  }

  .Cx505a_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .Cx505a_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .Cx505a_offset-sm-3 {
    margin-left: 25%;
  }

  .Cx505a_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .Cx505a_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .Cx505a_offset-sm-6 {
    margin-left: 50%;
  }

  .Cx505a_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .Cx505a_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .Cx505a_offset-sm-9 {
    margin-left: 75%;
  }

  .Cx505a_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .Cx505a_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .Cx505a_g-sm-0, .Cx505a_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .Cx505a_g-sm-0, .Cx505a_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .Cx505a_g-sm-1, .Cx505a_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .Cx505a_g-sm-1, .Cx505a_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .Cx505a_g-sm-2, .Cx505a_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .Cx505a_g-sm-2, .Cx505a_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .Cx505a_g-sm-3, .Cx505a_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .Cx505a_g-sm-3, .Cx505a_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .Cx505a_g-sm-4, .Cx505a_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Cx505a_g-sm-4, .Cx505a_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Cx505a_g-sm-5, .Cx505a_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .Cx505a_g-sm-5, .Cx505a_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .Cx505a_col-md {
    flex: 1 0;
  }

  .Cx505a_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .Cx505a_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .Cx505a_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .Cx505a_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .Cx505a_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .Cx505a_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-md-auto {
    width: auto;
    flex: none;
  }

  .Cx505a_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .Cx505a_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-md-3 {
    width: 25%;
    flex: none;
  }

  .Cx505a_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .Cx505a_col-md-6 {
    width: 50%;
    flex: none;
  }

  .Cx505a_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .Cx505a_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .Cx505a_col-md-9 {
    width: 75%;
    flex: none;
  }

  .Cx505a_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .Cx505a_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .Cx505a_col-md-12 {
    width: 100%;
    flex: none;
  }

  .Cx505a_offset-md-0 {
    margin-left: 0;
  }

  .Cx505a_offset-md-1 {
    margin-left: 8.33333%;
  }

  .Cx505a_offset-md-2 {
    margin-left: 16.6667%;
  }

  .Cx505a_offset-md-3 {
    margin-left: 25%;
  }

  .Cx505a_offset-md-4 {
    margin-left: 33.3333%;
  }

  .Cx505a_offset-md-5 {
    margin-left: 41.6667%;
  }

  .Cx505a_offset-md-6 {
    margin-left: 50%;
  }

  .Cx505a_offset-md-7 {
    margin-left: 58.3333%;
  }

  .Cx505a_offset-md-8 {
    margin-left: 66.6667%;
  }

  .Cx505a_offset-md-9 {
    margin-left: 75%;
  }

  .Cx505a_offset-md-10 {
    margin-left: 83.3333%;
  }

  .Cx505a_offset-md-11 {
    margin-left: 91.6667%;
  }

  .Cx505a_g-md-0, .Cx505a_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .Cx505a_g-md-0, .Cx505a_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .Cx505a_g-md-1, .Cx505a_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .Cx505a_g-md-1, .Cx505a_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .Cx505a_g-md-2, .Cx505a_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .Cx505a_g-md-2, .Cx505a_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .Cx505a_g-md-3, .Cx505a_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .Cx505a_g-md-3, .Cx505a_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .Cx505a_g-md-4, .Cx505a_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Cx505a_g-md-4, .Cx505a_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Cx505a_g-md-5, .Cx505a_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .Cx505a_g-md-5, .Cx505a_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .Cx505a_col-lg {
    flex: 1 0;
  }

  .Cx505a_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .Cx505a_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .Cx505a_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .Cx505a_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .Cx505a_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .Cx505a_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-lg-auto {
    width: auto;
    flex: none;
  }

  .Cx505a_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .Cx505a_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .Cx505a_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .Cx505a_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .Cx505a_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .Cx505a_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .Cx505a_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .Cx505a_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .Cx505a_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .Cx505a_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .Cx505a_offset-lg-0 {
    margin-left: 0;
  }

  .Cx505a_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .Cx505a_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .Cx505a_offset-lg-3 {
    margin-left: 25%;
  }

  .Cx505a_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .Cx505a_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .Cx505a_offset-lg-6 {
    margin-left: 50%;
  }

  .Cx505a_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .Cx505a_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .Cx505a_offset-lg-9 {
    margin-left: 75%;
  }

  .Cx505a_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .Cx505a_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .Cx505a_g-lg-0, .Cx505a_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .Cx505a_g-lg-0, .Cx505a_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .Cx505a_g-lg-1, .Cx505a_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .Cx505a_g-lg-1, .Cx505a_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .Cx505a_g-lg-2, .Cx505a_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .Cx505a_g-lg-2, .Cx505a_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .Cx505a_g-lg-3, .Cx505a_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .Cx505a_g-lg-3, .Cx505a_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .Cx505a_g-lg-4, .Cx505a_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Cx505a_g-lg-4, .Cx505a_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Cx505a_g-lg-5, .Cx505a_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .Cx505a_g-lg-5, .Cx505a_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .Cx505a_col-xl {
    flex: 1 0;
  }

  .Cx505a_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .Cx505a_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .Cx505a_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .Cx505a_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .Cx505a_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .Cx505a_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-xl-auto {
    width: auto;
    flex: none;
  }

  .Cx505a_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Cx505a_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .Cx505a_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Cx505a_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .Cx505a_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Cx505a_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Cx505a_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .Cx505a_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Cx505a_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Cx505a_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .Cx505a_offset-xl-0 {
    margin-left: 0;
  }

  .Cx505a_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .Cx505a_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .Cx505a_offset-xl-3 {
    margin-left: 25%;
  }

  .Cx505a_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .Cx505a_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .Cx505a_offset-xl-6 {
    margin-left: 50%;
  }

  .Cx505a_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .Cx505a_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .Cx505a_offset-xl-9 {
    margin-left: 75%;
  }

  .Cx505a_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .Cx505a_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .Cx505a_g-xl-0, .Cx505a_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .Cx505a_g-xl-0, .Cx505a_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .Cx505a_g-xl-1, .Cx505a_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .Cx505a_g-xl-1, .Cx505a_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .Cx505a_g-xl-2, .Cx505a_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .Cx505a_g-xl-2, .Cx505a_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .Cx505a_g-xl-3, .Cx505a_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .Cx505a_g-xl-3, .Cx505a_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .Cx505a_g-xl-4, .Cx505a_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Cx505a_g-xl-4, .Cx505a_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Cx505a_g-xl-5, .Cx505a_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .Cx505a_g-xl-5, .Cx505a_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .Cx505a_col-xxl {
    flex: 1 0;
  }

  .Cx505a_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .Cx505a_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .Cx505a_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .Cx505a_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .Cx505a_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .Cx505a_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .Cx505a_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Cx505a_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Cx505a_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .Cx505a_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Cx505a_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Cx505a_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .Cx505a_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Cx505a_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Cx505a_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .Cx505a_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Cx505a_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Cx505a_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .Cx505a_offset-xxl-0 {
    margin-left: 0;
  }

  .Cx505a_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .Cx505a_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .Cx505a_offset-xxl-3 {
    margin-left: 25%;
  }

  .Cx505a_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .Cx505a_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .Cx505a_offset-xxl-6 {
    margin-left: 50%;
  }

  .Cx505a_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .Cx505a_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .Cx505a_offset-xxl-9 {
    margin-left: 75%;
  }

  .Cx505a_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .Cx505a_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .Cx505a_g-xxl-0, .Cx505a_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .Cx505a_g-xxl-0, .Cx505a_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .Cx505a_g-xxl-1, .Cx505a_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .Cx505a_g-xxl-1, .Cx505a_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .Cx505a_g-xxl-2, .Cx505a_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .Cx505a_g-xxl-2, .Cx505a_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .Cx505a_g-xxl-3, .Cx505a_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .Cx505a_g-xxl-3, .Cx505a_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .Cx505a_g-xxl-4, .Cx505a_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Cx505a_g-xxl-4, .Cx505a_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Cx505a_g-xxl-5, .Cx505a_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .Cx505a_g-xxl-5, .Cx505a_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.Cx505a_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.Cx505a_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.Cx505a_table > tbody {
  vertical-align: inherit;
}

.Cx505a_table > thead {
  vertical-align: bottom;
}

.Cx505a_table-group-divider {
  border-top: 2px solid;
}

.Cx505a_caption-top {
  caption-side: top;
}

.Cx505a_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.Cx505a_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.Cx505a_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.Cx505a_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.Cx505a_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.Cx505a_table-striped > tbody > tr:nth-of-type(2n+1) > *, .Cx505a_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.Cx505a_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.Cx505a_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.Cx505a_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Cx505a_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .Cx505a_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .Cx505a_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .Cx505a_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .Cx505a_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .Cx505a_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.Cx505a_form-label {
  margin-bottom: .5rem;
}

.Cx505a_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.Cx505a_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.Cx505a_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.Cx505a_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.Cx505a_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-control {
    transition: none;
  }
}

.Cx505a_form-control[type="file"] {
  overflow: hidden;
}

.Cx505a_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Cx505a_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Cx505a_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.Cx505a_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.Cx505a_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.Cx505a_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-control::file-selector-button {
    transition: none;
  }
}

.Cx505a_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.Cx505a_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.Cx505a_form-control-plaintext:focus {
  outline: 0;
}

.Cx505a_form-control-plaintext.Cx505a_form-control-sm, .Cx505a_form-control-plaintext.Cx505a_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.Cx505a_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Cx505a_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.Cx505a_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Cx505a_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.Cx505a_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.Cx505a_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.Cx505a_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.Cx505a_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.Cx505a_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Cx505a_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.Cx505a_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.Cx505a_form-control-color.Cx505a_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.Cx505a_form-control-color.Cx505a_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.Cx505a_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-select {
    transition: none;
  }
}

.Cx505a_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Cx505a_form-select[multiple], .Cx505a_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.Cx505a_form-select:disabled {
  background-color: #e9ecef;
}

.Cx505a_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.Cx505a_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.Cx505a_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.Cx505a_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.Cx505a_form-check .Cx505a_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.Cx505a_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.Cx505a_form-check-reverse .Cx505a_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.Cx505a_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.Cx505a_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.Cx505a_form-check-input[type="radio"] {
  border-radius: 50%;
}

.Cx505a_form-check-input:active {
  filter: brightness(90%);
}

.Cx505a_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Cx505a_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.Cx505a_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.Cx505a_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.Cx505a_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.Cx505a_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.Cx505a_form-check-input[disabled] ~ .Cx505a_form-check-label, .Cx505a_form-check-input:disabled ~ .Cx505a_form-check-label {
  cursor: default;
  opacity: .5;
}

.Cx505a_form-switch {
  padding-left: 2.5em;
}

.Cx505a_form-switch .Cx505a_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-switch .Cx505a_form-check-input {
    transition: none;
  }
}

.Cx505a_form-switch .Cx505a_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.Cx505a_form-switch .Cx505a_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.Cx505a_form-switch.Cx505a_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.Cx505a_form-switch.Cx505a_form-check-reverse .Cx505a_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.Cx505a_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.Cx505a_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.Cx505a_btn-check[disabled] + .Cx505a_btn, .Cx505a_btn-check:disabled + .Cx505a_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.Cx505a_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.Cx505a_form-range:focus {
  outline: 0;
}

.Cx505a_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Cx505a_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Cx505a_form-range::-moz-focus-outer {
  border: 0;
}

.Cx505a_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.Cx505a_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.Cx505a_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Cx505a_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-range::-moz-range-thumb {
    transition: none;
  }
}

.Cx505a_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.Cx505a_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Cx505a_form-range:disabled {
  pointer-events: none;
}

.Cx505a_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.Cx505a_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.Cx505a_form-floating {
  position: relative;
}

.Cx505a_form-floating > .Cx505a_form-control, .Cx505a_form-floating > .Cx505a_form-control-plaintext, .Cx505a_form-floating > .Cx505a_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.Cx505a_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_form-floating > label {
    transition: none;
  }
}

.Cx505a_form-floating > .Cx505a_form-control, .Cx505a_form-floating > .Cx505a_form-control-plaintext {
  padding: 1rem .75rem;
}

.Cx505a_form-floating > .Cx505a_form-control::placeholder, .Cx505a_form-floating > .Cx505a_form-control-plaintext::placeholder {
  color: #0000;
}

.Cx505a_form-floating > .Cx505a_form-control:focus, .Cx505a_form-floating > .Cx505a_form-control:not(:placeholder-shown), .Cx505a_form-floating > .Cx505a_form-control-plaintext:focus, .Cx505a_form-floating > .Cx505a_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Cx505a_form-floating > .Cx505a_form-control:-webkit-autofill, .Cx505a_form-floating > .Cx505a_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Cx505a_form-floating > .Cx505a_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Cx505a_form-floating > .Cx505a_form-control:focus ~ label, .Cx505a_form-floating > .Cx505a_form-control:not(:placeholder-shown) ~ label, .Cx505a_form-floating > .Cx505a_form-control-plaintext ~ label, .Cx505a_form-floating > .Cx505a_form-select ~ label, .Cx505a_form-floating > .Cx505a_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.Cx505a_form-floating > .Cx505a_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.Cx505a_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.Cx505a_input-group > .Cx505a_form-control, .Cx505a_input-group > .Cx505a_form-select, .Cx505a_input-group > .Cx505a_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.Cx505a_input-group > .Cx505a_form-control:focus, .Cx505a_input-group > .Cx505a_form-select:focus, .Cx505a_input-group > .Cx505a_form-floating:focus-within {
  z-index: 5;
}

.Cx505a_input-group .Cx505a_btn {
  z-index: 2;
  position: relative;
}

.Cx505a_input-group .Cx505a_btn:focus {
  z-index: 5;
}

.Cx505a_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.Cx505a_input-group-lg > .Cx505a_form-control, .Cx505a_input-group-lg > .Cx505a_form-select, .Cx505a_input-group-lg > .Cx505a_input-group-text, .Cx505a_input-group-lg > .Cx505a_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Cx505a_input-group-sm > .Cx505a_form-control, .Cx505a_input-group-sm > .Cx505a_form-select, .Cx505a_input-group-sm > .Cx505a_input-group-text, .Cx505a_input-group-sm > .Cx505a_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Cx505a_input-group-lg > .Cx505a_form-select, .Cx505a_input-group-sm > .Cx505a_form-select {
  padding-right: 3rem;
}

.Cx505a_input-group:not(.Cx505a_has-validation) > :not(:last-child):not(.Cx505a_dropdown-toggle):not(.Cx505a_dropdown-menu):not(.Cx505a_form-floating), .Cx505a_input-group:not(.Cx505a_has-validation) > .Cx505a_dropdown-toggle:nth-last-child(n+3), .Cx505a_input-group:not(.Cx505a_has-validation) > .Cx505a_form-floating:not(:last-child) > .Cx505a_form-control, .Cx505a_input-group:not(.Cx505a_has-validation) > .Cx505a_form-floating:not(:last-child) > .Cx505a_form-select, .Cx505a_input-group.Cx505a_has-validation > :nth-last-child(n+3):not(.Cx505a_dropdown-toggle):not(.Cx505a_dropdown-menu):not(.Cx505a_form-floating), .Cx505a_input-group.Cx505a_has-validation > .Cx505a_dropdown-toggle:nth-last-child(n+4), .Cx505a_input-group.Cx505a_has-validation > .Cx505a_form-floating:nth-last-child(n+3) > .Cx505a_form-control, .Cx505a_input-group.Cx505a_has-validation > .Cx505a_form-floating:nth-last-child(n+3) > .Cx505a_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Cx505a_input-group > :not(:first-child):not(.Cx505a_dropdown-menu):not(.Cx505a_valid-tooltip):not(.Cx505a_valid-feedback):not(.Cx505a_invalid-tooltip):not(.Cx505a_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.Cx505a_input-group > .Cx505a_form-floating:not(:first-child) > .Cx505a_form-control, .Cx505a_input-group > .Cx505a_form-floating:not(:first-child) > .Cx505a_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Cx505a_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Cx505a_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Cx505a_was-validated :valid ~ .Cx505a_valid-feedback, .Cx505a_was-validated :valid ~ .Cx505a_valid-tooltip, .Cx505a_is-valid ~ .Cx505a_valid-feedback, .Cx505a_is-valid ~ .Cx505a_valid-tooltip {
  display: block;
}

.Cx505a_was-validated .Cx505a_form-control:valid, .Cx505a_form-control.Cx505a_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.Cx505a_was-validated .Cx505a_form-control:valid:focus, .Cx505a_form-control.Cx505a_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Cx505a_was-validated textarea.Cx505a_form-control:valid, textarea.Cx505a_form-control.Cx505a_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Cx505a_was-validated .Cx505a_form-select:valid, .Cx505a_form-select.Cx505a_is-valid {
  border-color: #198754;
}

.Cx505a_was-validated .Cx505a_form-select:valid:not([multiple]):not([size]), .Cx505a_was-validated .Cx505a_form-select:valid:not([multiple])[size="1"], .Cx505a_form-select.Cx505a_is-valid:not([multiple]):not([size]), .Cx505a_form-select.Cx505a_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Cx505a_was-validated .Cx505a_form-select:valid:focus, .Cx505a_form-select.Cx505a_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Cx505a_was-validated .Cx505a_form-control-color:valid, .Cx505a_form-control-color.Cx505a_is-valid {
  width: calc(1.5em + 3.75rem);
}

.Cx505a_was-validated .Cx505a_form-check-input:valid, .Cx505a_form-check-input.Cx505a_is-valid {
  border-color: #198754;
}

.Cx505a_was-validated .Cx505a_form-check-input:valid:checked, .Cx505a_form-check-input.Cx505a_is-valid:checked {
  background-color: #198754;
}

.Cx505a_was-validated .Cx505a_form-check-input:valid:focus, .Cx505a_form-check-input.Cx505a_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.Cx505a_was-validated .Cx505a_form-check-input:valid ~ .Cx505a_form-check-label, .Cx505a_form-check-input.Cx505a_is-valid ~ .Cx505a_form-check-label {
  color: #198754;
}

.Cx505a_form-check-inline .Cx505a_form-check-input ~ .Cx505a_valid-feedback {
  margin-left: .5em;
}

.Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-control:not(:focus):valid, .Cx505a_input-group > .Cx505a_form-control:not(:focus).Cx505a_is-valid, .Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-select:not(:focus):valid, .Cx505a_input-group > .Cx505a_form-select:not(:focus).Cx505a_is-valid, .Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-floating:not(:focus-within):valid, .Cx505a_input-group > .Cx505a_form-floating:not(:focus-within).Cx505a_is-valid {
  z-index: 3;
}

.Cx505a_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Cx505a_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Cx505a_was-validated :invalid ~ .Cx505a_invalid-feedback, .Cx505a_was-validated :invalid ~ .Cx505a_invalid-tooltip, .Cx505a_is-invalid ~ .Cx505a_invalid-feedback, .Cx505a_is-invalid ~ .Cx505a_invalid-tooltip {
  display: block;
}

.Cx505a_was-validated .Cx505a_form-control:invalid, .Cx505a_form-control.Cx505a_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.Cx505a_was-validated .Cx505a_form-control:invalid:focus, .Cx505a_form-control.Cx505a_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Cx505a_was-validated textarea.Cx505a_form-control:invalid, textarea.Cx505a_form-control.Cx505a_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Cx505a_was-validated .Cx505a_form-select:invalid, .Cx505a_form-select.Cx505a_is-invalid {
  border-color: #dc3545;
}

.Cx505a_was-validated .Cx505a_form-select:invalid:not([multiple]):not([size]), .Cx505a_was-validated .Cx505a_form-select:invalid:not([multiple])[size="1"], .Cx505a_form-select.Cx505a_is-invalid:not([multiple]):not([size]), .Cx505a_form-select.Cx505a_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Cx505a_was-validated .Cx505a_form-select:invalid:focus, .Cx505a_form-select.Cx505a_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Cx505a_was-validated .Cx505a_form-control-color:invalid, .Cx505a_form-control-color.Cx505a_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.Cx505a_was-validated .Cx505a_form-check-input:invalid, .Cx505a_form-check-input.Cx505a_is-invalid {
  border-color: #dc3545;
}

.Cx505a_was-validated .Cx505a_form-check-input:invalid:checked, .Cx505a_form-check-input.Cx505a_is-invalid:checked {
  background-color: #dc3545;
}

.Cx505a_was-validated .Cx505a_form-check-input:invalid:focus, .Cx505a_form-check-input.Cx505a_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Cx505a_was-validated .Cx505a_form-check-input:invalid ~ .Cx505a_form-check-label, .Cx505a_form-check-input.Cx505a_is-invalid ~ .Cx505a_form-check-label {
  color: #dc3545;
}

.Cx505a_form-check-inline .Cx505a_form-check-input ~ .Cx505a_invalid-feedback {
  margin-left: .5em;
}

.Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-control:not(:focus):invalid, .Cx505a_input-group > .Cx505a_form-control:not(:focus).Cx505a_is-invalid, .Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-select:not(:focus):invalid, .Cx505a_input-group > .Cx505a_form-select:not(:focus).Cx505a_is-invalid, .Cx505a_was-validated .Cx505a_input-group > .Cx505a_form-floating:not(:focus-within):invalid, .Cx505a_input-group > .Cx505a_form-floating:not(:focus-within).Cx505a_is-invalid {
  z-index: 4;
}

.Cx505a_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_btn {
    transition: none;
  }
}

.Cx505a_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.Cx505a_btn-check + .Cx505a_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.Cx505a_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Cx505a_btn-check:focus-visible + .Cx505a_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Cx505a_btn-check:checked + .Cx505a_btn, :not(.Cx505a_btn-check) + .Cx505a_btn:active, .Cx505a_btn:first-child:active, .Cx505a_btn.Cx505a_active, .Cx505a_btn.Cx505a_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.Cx505a_btn-check:checked + .Cx505a_btn:focus-visible, :not(.Cx505a_btn-check) + .Cx505a_btn:active:focus-visible, .Cx505a_btn:first-child:active:focus-visible, .Cx505a_btn.Cx505a_active:focus-visible, .Cx505a_btn.Cx505a_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.Cx505a_btn:disabled, .Cx505a_btn.Cx505a_disabled, fieldset:disabled .Cx505a_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.Cx505a_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.Cx505a_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.Cx505a_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.Cx505a_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.Cx505a_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.Cx505a_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.Cx505a_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.Cx505a_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.Cx505a_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.Cx505a_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.Cx505a_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.Cx505a_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.Cx505a_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.Cx505a_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.Cx505a_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.Cx505a_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.Cx505a_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.Cx505a_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.Cx505a_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.Cx505a_btn-lg, .Cx505a_btn-group-lg > .Cx505a_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.Cx505a_btn-sm, .Cx505a_btn-group-sm > .Cx505a_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.Cx505a_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_fade {
    transition: none;
  }
}

.Cx505a_fade:not(.Cx505a_show) {
  opacity: 0;
}

.Cx505a_collapse:not(.Cx505a_show) {
  display: none;
}

.Cx505a_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_collapsing {
    transition: none;
  }
}

.Cx505a_collapsing.Cx505a_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_collapsing.Cx505a_collapse-horizontal {
    transition: none;
  }
}

.Cx505a_dropup, .Cx505a_dropend, .Cx505a_dropdown, .Cx505a_dropstart, .Cx505a_dropup-center, .Cx505a_dropdown-center {
  position: relative;
}

.Cx505a_dropdown-toggle {
  white-space: nowrap;
}

.Cx505a_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.Cx505a_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Cx505a_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.Cx505a_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.Cx505a_dropdown-menu-start {
  --bs-position: start;
}

.Cx505a_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.Cx505a_dropdown-menu-end {
  --bs-position: end;
}

.Cx505a_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .Cx505a_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .Cx505a_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Cx505a_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .Cx505a_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .Cx505a_dropdown-menu-md-start {
    --bs-position: start;
  }

  .Cx505a_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Cx505a_dropdown-menu-md-end {
    --bs-position: end;
  }

  .Cx505a_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .Cx505a_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .Cx505a_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Cx505a_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .Cx505a_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .Cx505a_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .Cx505a_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Cx505a_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .Cx505a_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .Cx505a_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .Cx505a_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Cx505a_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .Cx505a_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.Cx505a_dropup .Cx505a_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.Cx505a_dropup .Cx505a_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.Cx505a_dropup .Cx505a_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Cx505a_dropend .Cx505a_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.Cx505a_dropend .Cx505a_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.Cx505a_dropend .Cx505a_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Cx505a_dropend .Cx505a_dropdown-toggle:after {
  vertical-align: 0;
}

.Cx505a_dropstart .Cx505a_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.Cx505a_dropstart .Cx505a_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.Cx505a_dropstart .Cx505a_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.Cx505a_dropstart .Cx505a_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Cx505a_dropstart .Cx505a_dropdown-toggle:before {
  vertical-align: 0;
}

.Cx505a_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.Cx505a_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.Cx505a_dropdown-item:hover, .Cx505a_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.Cx505a_dropdown-item.Cx505a_active, .Cx505a_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.Cx505a_dropdown-item.Cx505a_disabled, .Cx505a_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.Cx505a_dropdown-menu.Cx505a_show {
  display: block;
}

.Cx505a_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.Cx505a_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.Cx505a_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.Cx505a_btn-group, .Cx505a_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.Cx505a_btn-group > .Cx505a_btn, .Cx505a_btn-group-vertical > .Cx505a_btn {
  flex: auto;
  position: relative;
}

.Cx505a_btn-group > .Cx505a_btn-check:checked + .Cx505a_btn, .Cx505a_btn-group > .Cx505a_btn-check:focus + .Cx505a_btn, .Cx505a_btn-group > .Cx505a_btn:hover, .Cx505a_btn-group > .Cx505a_btn:focus, .Cx505a_btn-group > .Cx505a_btn:active, .Cx505a_btn-group > .Cx505a_btn.Cx505a_active, .Cx505a_btn-group-vertical > .Cx505a_btn-check:checked + .Cx505a_btn, .Cx505a_btn-group-vertical > .Cx505a_btn-check:focus + .Cx505a_btn, .Cx505a_btn-group-vertical > .Cx505a_btn:hover, .Cx505a_btn-group-vertical > .Cx505a_btn:focus, .Cx505a_btn-group-vertical > .Cx505a_btn:active, .Cx505a_btn-group-vertical > .Cx505a_btn.Cx505a_active {
  z-index: 1;
}

.Cx505a_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.Cx505a_btn-toolbar .Cx505a_input-group {
  width: auto;
}

.Cx505a_btn-group {
  border-radius: .375rem;
}

.Cx505a_btn-group > :not(.Cx505a_btn-check:first-child) + .Cx505a_btn, .Cx505a_btn-group > .Cx505a_btn-group:not(:first-child) {
  margin-left: -1px;
}

.Cx505a_btn-group > .Cx505a_btn:not(:last-child):not(.Cx505a_dropdown-toggle), .Cx505a_btn-group > .Cx505a_btn.Cx505a_dropdown-toggle-split:first-child, .Cx505a_btn-group > .Cx505a_btn-group:not(:last-child) > .Cx505a_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Cx505a_btn-group > .Cx505a_btn:nth-child(n+3), .Cx505a_btn-group > :not(.Cx505a_btn-check) + .Cx505a_btn, .Cx505a_btn-group > .Cx505a_btn-group:not(:first-child) > .Cx505a_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Cx505a_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.Cx505a_dropdown-toggle-split:after, .Cx505a_dropup .Cx505a_dropdown-toggle-split:after, .Cx505a_dropend .Cx505a_dropdown-toggle-split:after {
  margin-left: 0;
}

.Cx505a_dropstart .Cx505a_dropdown-toggle-split:before {
  margin-right: 0;
}

.Cx505a_btn-sm + .Cx505a_dropdown-toggle-split, .Cx505a_btn-group-sm > .Cx505a_btn + .Cx505a_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.Cx505a_btn-lg + .Cx505a_dropdown-toggle-split, .Cx505a_btn-group-lg > .Cx505a_btn + .Cx505a_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.Cx505a_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Cx505a_btn-group-vertical > .Cx505a_btn, .Cx505a_btn-group-vertical > .Cx505a_btn-group {
  width: 100%;
}

.Cx505a_btn-group-vertical > .Cx505a_btn:not(:first-child), .Cx505a_btn-group-vertical > .Cx505a_btn-group:not(:first-child) {
  margin-top: -1px;
}

.Cx505a_btn-group-vertical > .Cx505a_btn:not(:last-child):not(.Cx505a_dropdown-toggle), .Cx505a_btn-group-vertical > .Cx505a_btn-group:not(:last-child) > .Cx505a_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Cx505a_btn-group-vertical > .Cx505a_btn ~ .Cx505a_btn, .Cx505a_btn-group-vertical > .Cx505a_btn-group:not(:first-child) > .Cx505a_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Cx505a_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Cx505a_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_nav-link {
    transition: none;
  }
}

.Cx505a_nav-link:hover, .Cx505a_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.Cx505a_nav-link.Cx505a_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.Cx505a_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.Cx505a_nav-tabs .Cx505a_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.Cx505a_nav-tabs .Cx505a_nav-link:hover, .Cx505a_nav-tabs .Cx505a_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.Cx505a_nav-tabs .Cx505a_nav-link.Cx505a_disabled, .Cx505a_nav-tabs .Cx505a_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Cx505a_nav-tabs .Cx505a_nav-link.Cx505a_active, .Cx505a_nav-tabs .Cx505a_nav-item.Cx505a_show .Cx505a_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.Cx505a_nav-tabs .Cx505a_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Cx505a_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.Cx505a_nav-pills .Cx505a_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.Cx505a_nav-pills .Cx505a_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Cx505a_nav-pills .Cx505a_nav-link.Cx505a_active, .Cx505a_nav-pills .Cx505a_show > .Cx505a_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.Cx505a_nav-fill > .Cx505a_nav-link, .Cx505a_nav-fill .Cx505a_nav-item {
  text-align: center;
  flex: auto;
}

.Cx505a_nav-justified > .Cx505a_nav-link, .Cx505a_nav-justified .Cx505a_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.Cx505a_nav-fill .Cx505a_nav-item .Cx505a_nav-link, .Cx505a_nav-justified .Cx505a_nav-item .Cx505a_nav-link {
  width: 100%;
}

.Cx505a_tab-content > .Cx505a_tab-pane {
  display: none;
}

.Cx505a_tab-content > .Cx505a_active {
  display: block;
}

.Cx505a_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.Cx505a_navbar > .Cx505a_container, .Cx505a_navbar > .Cx505a_container-fluid, .Cx505a_navbar > .Cx505a_container-sm, .Cx505a_navbar > .Cx505a_container-md, .Cx505a_navbar > .Cx505a_container-lg, .Cx505a_navbar > .Cx505a_container-xl, .Cx505a_navbar > .Cx505a_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Cx505a_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.Cx505a_navbar-brand:hover, .Cx505a_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.Cx505a_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Cx505a_navbar-nav .Cx505a_show > .Cx505a_nav-link, .Cx505a_navbar-nav .Cx505a_nav-link.Cx505a_active {
  color: var(--bs-navbar-active-color);
}

.Cx505a_navbar-nav .Cx505a_dropdown-menu {
  position: static;
}

.Cx505a_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Cx505a_navbar-text a, .Cx505a_navbar-text a:hover, .Cx505a_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.Cx505a_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.Cx505a_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_navbar-toggler {
    transition: none;
  }
}

.Cx505a_navbar-toggler:hover {
  text-decoration: none;
}

.Cx505a_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.Cx505a_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.Cx505a_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .Cx505a_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-nav {
    flex-direction: row;
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-nav .Cx505a_dropdown-menu {
    position: absolute;
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-nav .Cx505a_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-nav-scroll {
    overflow: visible;
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Cx505a_navbar-expand-sm .Cx505a_navbar-toggler {
    display: none;
  }

  .Cx505a_navbar-expand-sm .Cx505a_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Cx505a_navbar-expand-sm .Cx505a_offcanvas .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_navbar-expand-sm .Cx505a_offcanvas .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .Cx505a_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-nav {
    flex-direction: row;
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-nav .Cx505a_dropdown-menu {
    position: absolute;
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-nav .Cx505a_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-nav-scroll {
    overflow: visible;
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Cx505a_navbar-expand-md .Cx505a_navbar-toggler {
    display: none;
  }

  .Cx505a_navbar-expand-md .Cx505a_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Cx505a_navbar-expand-md .Cx505a_offcanvas .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_navbar-expand-md .Cx505a_offcanvas .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .Cx505a_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-nav {
    flex-direction: row;
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-nav .Cx505a_dropdown-menu {
    position: absolute;
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-nav .Cx505a_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-nav-scroll {
    overflow: visible;
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Cx505a_navbar-expand-lg .Cx505a_navbar-toggler {
    display: none;
  }

  .Cx505a_navbar-expand-lg .Cx505a_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Cx505a_navbar-expand-lg .Cx505a_offcanvas .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_navbar-expand-lg .Cx505a_offcanvas .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .Cx505a_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-nav {
    flex-direction: row;
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-nav .Cx505a_dropdown-menu {
    position: absolute;
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-nav .Cx505a_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-nav-scroll {
    overflow: visible;
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Cx505a_navbar-expand-xl .Cx505a_navbar-toggler {
    display: none;
  }

  .Cx505a_navbar-expand-xl .Cx505a_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Cx505a_navbar-expand-xl .Cx505a_offcanvas .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_navbar-expand-xl .Cx505a_offcanvas .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .Cx505a_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-nav {
    flex-direction: row;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-nav .Cx505a_dropdown-menu {
    position: absolute;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-nav .Cx505a_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-nav-scroll {
    overflow: visible;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_navbar-toggler {
    display: none;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_offcanvas .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_navbar-expand-xxl .Cx505a_offcanvas .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.Cx505a_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.Cx505a_navbar-expand .Cx505a_navbar-nav {
  flex-direction: row;
}

.Cx505a_navbar-expand .Cx505a_navbar-nav .Cx505a_dropdown-menu {
  position: absolute;
}

.Cx505a_navbar-expand .Cx505a_navbar-nav .Cx505a_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.Cx505a_navbar-expand .Cx505a_navbar-nav-scroll {
  overflow: visible;
}

.Cx505a_navbar-expand .Cx505a_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.Cx505a_navbar-expand .Cx505a_navbar-toggler {
  display: none;
}

.Cx505a_navbar-expand .Cx505a_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.Cx505a_navbar-expand .Cx505a_offcanvas .Cx505a_offcanvas-header {
  display: none;
}

.Cx505a_navbar-expand .Cx505a_offcanvas .Cx505a_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.Cx505a_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.Cx505a_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Cx505a_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.Cx505a_card > .Cx505a_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.Cx505a_card > .Cx505a_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.Cx505a_card > .Cx505a_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.Cx505a_card > .Cx505a_card-header + .Cx505a_list-group, .Cx505a_card > .Cx505a_list-group + .Cx505a_card-footer {
  border-top: 0;
}

.Cx505a_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.Cx505a_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.Cx505a_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.Cx505a_card-text:last-child {
  margin-bottom: 0;
}

.Cx505a_card-link + .Cx505a_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.Cx505a_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.Cx505a_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.Cx505a_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.Cx505a_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.Cx505a_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.Cx505a_card-header-tabs .Cx505a_nav-link.Cx505a_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.Cx505a_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.Cx505a_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.Cx505a_card-img, .Cx505a_card-img-top, .Cx505a_card-img-bottom {
  width: 100%;
}

.Cx505a_card-img, .Cx505a_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.Cx505a_card-img, .Cx505a_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.Cx505a_card-group > .Cx505a_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .Cx505a_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .Cx505a_card-group > .Cx505a_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .Cx505a_card-group > .Cx505a_card + .Cx505a_card {
    border-left: 0;
    margin-left: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:last-child) .Cx505a_card-img-top, .Cx505a_card-group > .Cx505a_card:not(:last-child) .Cx505a_card-header {
    border-top-right-radius: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:last-child) .Cx505a_card-img-bottom, .Cx505a_card-group > .Cx505a_card:not(:last-child) .Cx505a_card-footer {
    border-bottom-right-radius: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:first-child) .Cx505a_card-img-top, .Cx505a_card-group > .Cx505a_card:not(:first-child) .Cx505a_card-header {
    border-top-left-radius: 0;
  }

  .Cx505a_card-group > .Cx505a_card:not(:first-child) .Cx505a_card-img-bottom, .Cx505a_card-group > .Cx505a_card:not(:first-child) .Cx505a_card-footer {
    border-bottom-left-radius: 0;
  }
}

.Cx505a_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.Cx505a_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_accordion-button {
    transition: none;
  }
}

.Cx505a_accordion-button:not(.Cx505a_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.Cx505a_accordion-button:not(.Cx505a_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.Cx505a_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_accordion-button:after {
    transition: none;
  }
}

.Cx505a_accordion-button:hover {
  z-index: 2;
}

.Cx505a_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.Cx505a_accordion-header {
  margin-bottom: 0;
}

.Cx505a_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.Cx505a_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.Cx505a_accordion-item:first-of-type .Cx505a_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.Cx505a_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.Cx505a_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Cx505a_accordion-item:last-of-type .Cx505a_accordion-button.Cx505a_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.Cx505a_accordion-item:last-of-type .Cx505a_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Cx505a_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.Cx505a_accordion-flush .Cx505a_accordion-collapse {
  border-width: 0;
}

.Cx505a_accordion-flush .Cx505a_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.Cx505a_accordion-flush .Cx505a_accordion-item:first-child {
  border-top: 0;
}

.Cx505a_accordion-flush .Cx505a_accordion-item:last-child {
  border-bottom: 0;
}

.Cx505a_accordion-flush .Cx505a_accordion-item .Cx505a_accordion-button, .Cx505a_accordion-flush .Cx505a_accordion-item .Cx505a_accordion-button.Cx505a_collapsed {
  border-radius: 0;
}

.Cx505a_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.Cx505a_breadcrumb-item + .Cx505a_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.Cx505a_breadcrumb-item + .Cx505a_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.Cx505a_breadcrumb-item.Cx505a_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.Cx505a_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Cx505a_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_page-link {
    transition: none;
  }
}

.Cx505a_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.Cx505a_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.Cx505a_page-link.Cx505a_active, .Cx505a_active > .Cx505a_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.Cx505a_page-link.Cx505a_disabled, .Cx505a_disabled > .Cx505a_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.Cx505a_page-item:not(:first-child) .Cx505a_page-link {
  margin-left: -1px;
}

.Cx505a_page-item:first-child .Cx505a_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.Cx505a_page-item:last-child .Cx505a_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.Cx505a_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.Cx505a_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.Cx505a_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.Cx505a_badge:empty {
  display: none;
}

.Cx505a_btn .Cx505a_badge {
  position: relative;
  top: -1px;
}

.Cx505a_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.Cx505a_alert-heading {
  color: inherit;
}

.Cx505a_alert-link {
  font-weight: 700;
}

.Cx505a_alert-dismissible {
  padding-right: 3rem;
}

.Cx505a_alert-dismissible .Cx505a_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.Cx505a_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.Cx505a_alert-primary .Cx505a_alert-link {
  color: #25224b;
}

.Cx505a_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.Cx505a_alert-secondary .Cx505a_alert-link {
  color: #34383c;
}

.Cx505a_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.Cx505a_alert-success .Cx505a_alert-link {
  color: #0c4128;
}

.Cx505a_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.Cx505a_alert-info .Cx505a_alert-link {
  color: #04414d;
}

.Cx505a_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.Cx505a_alert-warning .Cx505a_alert-link {
  color: #523e02;
}

.Cx505a_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.Cx505a_alert-danger .Cx505a_alert-link {
  color: #6a1a21;
}

.Cx505a_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.Cx505a_alert-light .Cx505a_alert-link {
  color: #4f5050;
}

.Cx505a_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.Cx505a_alert-dark .Cx505a_alert-link {
  color: #101214;
}

@keyframes Cx505a_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.Cx505a_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.Cx505a_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_progress-bar {
    transition: none;
  }
}

.Cx505a_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.Cx505a_progress-bar-animated {
  animation: 1s linear infinite Cx505a_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_progress-bar-animated {
    animation: none;
  }
}

.Cx505a_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.Cx505a_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.Cx505a_list-group-numbered > .Cx505a_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.Cx505a_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.Cx505a_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.Cx505a_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.Cx505a_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.Cx505a_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.Cx505a_list-group-item.Cx505a_disabled, .Cx505a_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.Cx505a_list-group-item.Cx505a_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.Cx505a_list-group-item + .Cx505a_list-group-item {
  border-top-width: 0;
}

.Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.Cx505a_list-group-horizontal {
  flex-direction: row;
}

.Cx505a_list-group-horizontal > .Cx505a_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.Cx505a_list-group-horizontal > .Cx505a_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.Cx505a_list-group-horizontal > .Cx505a_list-group-item.Cx505a_active {
  margin-top: 0;
}

.Cx505a_list-group-horizontal > .Cx505a_list-group-item + .Cx505a_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.Cx505a_list-group-horizontal > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .Cx505a_list-group-horizontal-sm {
    flex-direction: row;
  }

  .Cx505a_list-group-horizontal-sm > .Cx505a_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Cx505a_list-group-horizontal-sm > .Cx505a_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Cx505a_list-group-horizontal-sm > .Cx505a_list-group-item.Cx505a_active {
    margin-top: 0;
  }

  .Cx505a_list-group-horizontal-sm > .Cx505a_list-group-item + .Cx505a_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Cx505a_list-group-horizontal-sm > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .Cx505a_list-group-horizontal-md {
    flex-direction: row;
  }

  .Cx505a_list-group-horizontal-md > .Cx505a_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Cx505a_list-group-horizontal-md > .Cx505a_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Cx505a_list-group-horizontal-md > .Cx505a_list-group-item.Cx505a_active {
    margin-top: 0;
  }

  .Cx505a_list-group-horizontal-md > .Cx505a_list-group-item + .Cx505a_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Cx505a_list-group-horizontal-md > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .Cx505a_list-group-horizontal-lg {
    flex-direction: row;
  }

  .Cx505a_list-group-horizontal-lg > .Cx505a_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Cx505a_list-group-horizontal-lg > .Cx505a_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Cx505a_list-group-horizontal-lg > .Cx505a_list-group-item.Cx505a_active {
    margin-top: 0;
  }

  .Cx505a_list-group-horizontal-lg > .Cx505a_list-group-item + .Cx505a_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Cx505a_list-group-horizontal-lg > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .Cx505a_list-group-horizontal-xl {
    flex-direction: row;
  }

  .Cx505a_list-group-horizontal-xl > .Cx505a_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Cx505a_list-group-horizontal-xl > .Cx505a_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Cx505a_list-group-horizontal-xl > .Cx505a_list-group-item.Cx505a_active {
    margin-top: 0;
  }

  .Cx505a_list-group-horizontal-xl > .Cx505a_list-group-item + .Cx505a_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Cx505a_list-group-horizontal-xl > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .Cx505a_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .Cx505a_list-group-horizontal-xxl > .Cx505a_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Cx505a_list-group-horizontal-xxl > .Cx505a_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Cx505a_list-group-horizontal-xxl > .Cx505a_list-group-item.Cx505a_active {
    margin-top: 0;
  }

  .Cx505a_list-group-horizontal-xxl > .Cx505a_list-group-item + .Cx505a_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Cx505a_list-group-horizontal-xxl > .Cx505a_list-group-item + .Cx505a_list-group-item.Cx505a_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.Cx505a_list-group-flush {
  border-radius: 0;
}

.Cx505a_list-group-flush > .Cx505a_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.Cx505a_list-group-flush > .Cx505a_list-group-item:last-child {
  border-bottom-width: 0;
}

.Cx505a_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.Cx505a_list-group-item-primary.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-primary.Cx505a_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.Cx505a_list-group-item-primary.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.Cx505a_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.Cx505a_list-group-item-secondary.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-secondary.Cx505a_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.Cx505a_list-group-item-secondary.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.Cx505a_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.Cx505a_list-group-item-success.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-success.Cx505a_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.Cx505a_list-group-item-success.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.Cx505a_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.Cx505a_list-group-item-info.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-info.Cx505a_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.Cx505a_list-group-item-info.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.Cx505a_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.Cx505a_list-group-item-warning.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-warning.Cx505a_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.Cx505a_list-group-item-warning.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.Cx505a_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.Cx505a_list-group-item-danger.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-danger.Cx505a_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.Cx505a_list-group-item-danger.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.Cx505a_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.Cx505a_list-group-item-light.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-light.Cx505a_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.Cx505a_list-group-item-light.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.Cx505a_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.Cx505a_list-group-item-dark.Cx505a_list-group-item-action:hover, .Cx505a_list-group-item-dark.Cx505a_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.Cx505a_list-group-item-dark.Cx505a_list-group-item-action.Cx505a_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.Cx505a_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.Cx505a_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.Cx505a_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Cx505a_btn-close:disabled, .Cx505a_btn-close.Cx505a_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.Cx505a_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.Cx505a_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.Cx505a_toast.Cx505a_showing {
  opacity: 0;
}

.Cx505a_toast:not(.Cx505a_show) {
  display: none;
}

.Cx505a_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.Cx505a_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.Cx505a_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.Cx505a_toast-header .Cx505a_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.Cx505a_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.Cx505a_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.Cx505a_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.Cx505a_modal.Cx505a_fade .Cx505a_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_modal.Cx505a_fade .Cx505a_modal-dialog {
    transition: none;
  }
}

.Cx505a_modal.Cx505a_show .Cx505a_modal-dialog {
  transform: none;
}

.Cx505a_modal.Cx505a_modal-static .Cx505a_modal-dialog {
  transform: scale(1.02);
}

.Cx505a_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.Cx505a_modal-dialog-scrollable .Cx505a_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.Cx505a_modal-dialog-scrollable .Cx505a_modal-body {
  overflow-y: auto;
}

.Cx505a_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.Cx505a_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Cx505a_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.Cx505a_modal-backdrop.Cx505a_fade {
  opacity: 0;
}

.Cx505a_modal-backdrop.Cx505a_show {
  opacity: var(--bs-backdrop-opacity);
}

.Cx505a_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Cx505a_modal-header .Cx505a_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.Cx505a_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.Cx505a_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.Cx505a_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Cx505a_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .Cx505a_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .Cx505a_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .Cx505a_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .Cx505a_modal-lg, .Cx505a_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .Cx505a_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.Cx505a_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.Cx505a_modal-fullscreen .Cx505a_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.Cx505a_modal-fullscreen .Cx505a_modal-header, .Cx505a_modal-fullscreen .Cx505a_modal-footer {
  border-radius: 0;
}

.Cx505a_modal-fullscreen .Cx505a_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .Cx505a_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Cx505a_modal-fullscreen-sm-down .Cx505a_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-sm-down .Cx505a_modal-header, .Cx505a_modal-fullscreen-sm-down .Cx505a_modal-footer {
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-sm-down .Cx505a_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .Cx505a_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Cx505a_modal-fullscreen-md-down .Cx505a_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-md-down .Cx505a_modal-header, .Cx505a_modal-fullscreen-md-down .Cx505a_modal-footer {
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-md-down .Cx505a_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .Cx505a_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Cx505a_modal-fullscreen-lg-down .Cx505a_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-lg-down .Cx505a_modal-header, .Cx505a_modal-fullscreen-lg-down .Cx505a_modal-footer {
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-lg-down .Cx505a_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .Cx505a_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Cx505a_modal-fullscreen-xl-down .Cx505a_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-xl-down .Cx505a_modal-header, .Cx505a_modal-fullscreen-xl-down .Cx505a_modal-footer {
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-xl-down .Cx505a_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .Cx505a_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Cx505a_modal-fullscreen-xxl-down .Cx505a_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-xxl-down .Cx505a_modal-header, .Cx505a_modal-fullscreen-xxl-down .Cx505a_modal-footer {
    border-radius: 0;
  }

  .Cx505a_modal-fullscreen-xxl-down .Cx505a_modal-body {
    overflow-y: auto;
  }
}

.Cx505a_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.Cx505a_tooltip.Cx505a_show {
  opacity: var(--bs-tooltip-opacity);
}

.Cx505a_tooltip .Cx505a_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.Cx505a_tooltip .Cx505a_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.Cx505a_bs-tooltip-top .Cx505a_tooltip-arrow, .Cx505a_bs-tooltip-auto[data-popper-placement^="top"] .Cx505a_tooltip-arrow {
  bottom: 0;
}

.Cx505a_bs-tooltip-top .Cx505a_tooltip-arrow:before, .Cx505a_bs-tooltip-auto[data-popper-placement^="top"] .Cx505a_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.Cx505a_bs-tooltip-end .Cx505a_tooltip-arrow, .Cx505a_bs-tooltip-auto[data-popper-placement^="right"] .Cx505a_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.Cx505a_bs-tooltip-end .Cx505a_tooltip-arrow:before, .Cx505a_bs-tooltip-auto[data-popper-placement^="right"] .Cx505a_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.Cx505a_bs-tooltip-bottom .Cx505a_tooltip-arrow, .Cx505a_bs-tooltip-auto[data-popper-placement^="bottom"] .Cx505a_tooltip-arrow {
  top: 0;
}

.Cx505a_bs-tooltip-bottom .Cx505a_tooltip-arrow:before, .Cx505a_bs-tooltip-auto[data-popper-placement^="bottom"] .Cx505a_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.Cx505a_bs-tooltip-start .Cx505a_tooltip-arrow, .Cx505a_bs-tooltip-auto[data-popper-placement^="left"] .Cx505a_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.Cx505a_bs-tooltip-start .Cx505a_tooltip-arrow:before, .Cx505a_bs-tooltip-auto[data-popper-placement^="left"] .Cx505a_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.Cx505a_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.Cx505a_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.Cx505a_popover .Cx505a_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.Cx505a_popover .Cx505a_popover-arrow:before, .Cx505a_popover .Cx505a_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.Cx505a_bs-popover-top > .Cx505a_popover-arrow, .Cx505a_bs-popover-auto[data-popper-placement^="top"] > .Cx505a_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Cx505a_bs-popover-top > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="top"] > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-top > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="top"] > .Cx505a_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Cx505a_bs-popover-top > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="top"] > .Cx505a_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.Cx505a_bs-popover-top > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="top"] > .Cx505a_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.Cx505a_bs-popover-end > .Cx505a_popover-arrow, .Cx505a_bs-popover-auto[data-popper-placement^="right"] > .Cx505a_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Cx505a_bs-popover-end > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="right"] > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-end > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="right"] > .Cx505a_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Cx505a_bs-popover-end > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="right"] > .Cx505a_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.Cx505a_bs-popover-end > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="right"] > .Cx505a_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.Cx505a_bs-popover-bottom > .Cx505a_popover-arrow, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] > .Cx505a_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Cx505a_bs-popover-bottom > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-bottom > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] > .Cx505a_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Cx505a_bs-popover-bottom > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] > .Cx505a_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.Cx505a_bs-popover-bottom > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] > .Cx505a_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.Cx505a_bs-popover-bottom .Cx505a_popover-header:before, .Cx505a_bs-popover-auto[data-popper-placement^="bottom"] .Cx505a_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.Cx505a_bs-popover-start > .Cx505a_popover-arrow, .Cx505a_bs-popover-auto[data-popper-placement^="left"] > .Cx505a_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Cx505a_bs-popover-start > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="left"] > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-start > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="left"] > .Cx505a_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Cx505a_bs-popover-start > .Cx505a_popover-arrow:before, .Cx505a_bs-popover-auto[data-popper-placement^="left"] > .Cx505a_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.Cx505a_bs-popover-start > .Cx505a_popover-arrow:after, .Cx505a_bs-popover-auto[data-popper-placement^="left"] > .Cx505a_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.Cx505a_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.Cx505a_popover-header:empty {
  display: none;
}

.Cx505a_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.Cx505a_carousel {
  position: relative;
}

.Cx505a_carousel.Cx505a_pointer-event {
  touch-action: pan-y;
}

.Cx505a_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Cx505a_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.Cx505a_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_carousel-item {
    transition: none;
  }
}

.Cx505a_carousel-item.Cx505a_active, .Cx505a_carousel-item-next, .Cx505a_carousel-item-prev {
  display: block;
}

.Cx505a_carousel-item-next:not(.Cx505a_carousel-item-start), .Cx505a_active.Cx505a_carousel-item-end {
  transform: translateX(100%);
}

.Cx505a_carousel-item-prev:not(.Cx505a_carousel-item-end), .Cx505a_active.Cx505a_carousel-item-start {
  transform: translateX(-100%);
}

.Cx505a_carousel-fade .Cx505a_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.Cx505a_carousel-fade .Cx505a_carousel-item.Cx505a_active, .Cx505a_carousel-fade .Cx505a_carousel-item-next.Cx505a_carousel-item-start, .Cx505a_carousel-fade .Cx505a_carousel-item-prev.Cx505a_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.Cx505a_carousel-fade .Cx505a_active.Cx505a_carousel-item-start, .Cx505a_carousel-fade .Cx505a_active.Cx505a_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_carousel-fade .Cx505a_active.Cx505a_carousel-item-start, .Cx505a_carousel-fade .Cx505a_active.Cx505a_carousel-item-end {
    transition: none;
  }
}

.Cx505a_carousel-control-prev, .Cx505a_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_carousel-control-prev, .Cx505a_carousel-control-next {
    transition: none;
  }
}

.Cx505a_carousel-control-prev:hover, .Cx505a_carousel-control-prev:focus, .Cx505a_carousel-control-next:hover, .Cx505a_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.Cx505a_carousel-control-prev {
  left: 0;
}

.Cx505a_carousel-control-next {
  right: 0;
}

.Cx505a_carousel-control-prev-icon, .Cx505a_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Cx505a_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.Cx505a_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.Cx505a_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Cx505a_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.Cx505a_carousel-indicators .Cx505a_active {
  opacity: 1;
}

.Cx505a_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.Cx505a_carousel-dark .Cx505a_carousel-control-prev-icon, .Cx505a_carousel-dark .Cx505a_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.Cx505a_carousel-dark .Cx505a_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.Cx505a_carousel-dark .Cx505a_carousel-caption {
  color: #000;
}

.Cx505a_spinner-grow, .Cx505a_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes Cx505a_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.Cx505a_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.Cx505a_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes Cx505a_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.Cx505a_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.Cx505a_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_spinner-border, .Cx505a_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.Cx505a_offcanvas, .Cx505a_offcanvas-xxl, .Cx505a_offcanvas-xl, .Cx505a_offcanvas-lg, .Cx505a_offcanvas-md, .Cx505a_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .Cx505a_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .Cx505a_offcanvas-sm.Cx505a_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Cx505a_offcanvas-sm.Cx505a_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Cx505a_offcanvas-sm.Cx505a_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Cx505a_offcanvas-sm.Cx505a_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Cx505a_offcanvas-sm.Cx505a_showing, .Cx505a_offcanvas-sm.Cx505a_show:not(.Cx505a_hiding) {
    transform: none;
  }

  .Cx505a_offcanvas-sm.Cx505a_showing, .Cx505a_offcanvas-sm.Cx505a_hiding, .Cx505a_offcanvas-sm.Cx505a_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .Cx505a_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Cx505a_offcanvas-sm .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_offcanvas-sm .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .Cx505a_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .Cx505a_offcanvas-md.Cx505a_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Cx505a_offcanvas-md.Cx505a_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Cx505a_offcanvas-md.Cx505a_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Cx505a_offcanvas-md.Cx505a_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Cx505a_offcanvas-md.Cx505a_showing, .Cx505a_offcanvas-md.Cx505a_show:not(.Cx505a_hiding) {
    transform: none;
  }

  .Cx505a_offcanvas-md.Cx505a_showing, .Cx505a_offcanvas-md.Cx505a_hiding, .Cx505a_offcanvas-md.Cx505a_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .Cx505a_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Cx505a_offcanvas-md .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_offcanvas-md .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .Cx505a_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .Cx505a_offcanvas-lg.Cx505a_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Cx505a_offcanvas-lg.Cx505a_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Cx505a_offcanvas-lg.Cx505a_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Cx505a_offcanvas-lg.Cx505a_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Cx505a_offcanvas-lg.Cx505a_showing, .Cx505a_offcanvas-lg.Cx505a_show:not(.Cx505a_hiding) {
    transform: none;
  }

  .Cx505a_offcanvas-lg.Cx505a_showing, .Cx505a_offcanvas-lg.Cx505a_hiding, .Cx505a_offcanvas-lg.Cx505a_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .Cx505a_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Cx505a_offcanvas-lg .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_offcanvas-lg .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .Cx505a_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .Cx505a_offcanvas-xl.Cx505a_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Cx505a_offcanvas-xl.Cx505a_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Cx505a_offcanvas-xl.Cx505a_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Cx505a_offcanvas-xl.Cx505a_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Cx505a_offcanvas-xl.Cx505a_showing, .Cx505a_offcanvas-xl.Cx505a_show:not(.Cx505a_hiding) {
    transform: none;
  }

  .Cx505a_offcanvas-xl.Cx505a_showing, .Cx505a_offcanvas-xl.Cx505a_hiding, .Cx505a_offcanvas-xl.Cx505a_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .Cx505a_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Cx505a_offcanvas-xl .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_offcanvas-xl .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .Cx505a_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .Cx505a_offcanvas-xxl.Cx505a_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Cx505a_offcanvas-xxl.Cx505a_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Cx505a_offcanvas-xxl.Cx505a_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Cx505a_offcanvas-xxl.Cx505a_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Cx505a_offcanvas-xxl.Cx505a_showing, .Cx505a_offcanvas-xxl.Cx505a_show:not(.Cx505a_hiding) {
    transform: none;
  }

  .Cx505a_offcanvas-xxl.Cx505a_showing, .Cx505a_offcanvas-xxl.Cx505a_hiding, .Cx505a_offcanvas-xxl.Cx505a_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .Cx505a_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Cx505a_offcanvas-xxl .Cx505a_offcanvas-header {
    display: none;
  }

  .Cx505a_offcanvas-xxl .Cx505a_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.Cx505a_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Cx505a_offcanvas {
    transition: none;
  }
}

.Cx505a_offcanvas.Cx505a_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.Cx505a_offcanvas.Cx505a_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.Cx505a_offcanvas.Cx505a_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.Cx505a_offcanvas.Cx505a_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.Cx505a_offcanvas.Cx505a_showing, .Cx505a_offcanvas.Cx505a_show:not(.Cx505a_hiding) {
  transform: none;
}

.Cx505a_offcanvas.Cx505a_showing, .Cx505a_offcanvas.Cx505a_hiding, .Cx505a_offcanvas.Cx505a_show {
  visibility: visible;
}

.Cx505a_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.Cx505a_offcanvas-backdrop.Cx505a_fade {
  opacity: 0;
}

.Cx505a_offcanvas-backdrop.Cx505a_show {
  opacity: .5;
}

.Cx505a_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Cx505a_offcanvas-header .Cx505a_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.Cx505a_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.Cx505a_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.Cx505a_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.Cx505a_placeholder.Cx505a_btn:before {
  content: "";
  display: inline-block;
}

.Cx505a_placeholder-xs {
  min-height: .6em;
}

.Cx505a_placeholder-sm {
  min-height: .8em;
}

.Cx505a_placeholder-lg {
  min-height: 1.2em;
}

.Cx505a_placeholder-glow .Cx505a_placeholder {
  animation: 2s ease-in-out infinite Cx505a_placeholder-glow;
}

@keyframes Cx505a_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.Cx505a_placeholder-wave {
  animation: 2s linear infinite Cx505a_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes Cx505a_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.Cx505a_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.Cx505a_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.Cx505a_link-primary {
  color: #4c469c !important;
}

.Cx505a_link-primary:hover, .Cx505a_link-primary:focus {
  color: #3d387d !important;
}

.Cx505a_link-secondary {
  color: #6c757d !important;
}

.Cx505a_link-secondary:hover, .Cx505a_link-secondary:focus {
  color: #565e64 !important;
}

.Cx505a_link-success {
  color: #198754 !important;
}

.Cx505a_link-success:hover, .Cx505a_link-success:focus {
  color: #146c43 !important;
}

.Cx505a_link-info {
  color: #0dcaf0 !important;
}

.Cx505a_link-info:hover, .Cx505a_link-info:focus {
  color: #3dd5f3 !important;
}

.Cx505a_link-warning {
  color: #ffc107 !important;
}

.Cx505a_link-warning:hover, .Cx505a_link-warning:focus {
  color: #ffcd39 !important;
}

.Cx505a_link-danger {
  color: #dc3545 !important;
}

.Cx505a_link-danger:hover, .Cx505a_link-danger:focus {
  color: #b02a37 !important;
}

.Cx505a_link-light {
  color: #f8f9fa !important;
}

.Cx505a_link-light:hover, .Cx505a_link-light:focus {
  color: #f9fafb !important;
}

.Cx505a_link-dark {
  color: #212529 !important;
}

.Cx505a_link-dark:hover, .Cx505a_link-dark:focus {
  color: #1a1e21 !important;
}

.Cx505a_ratio {
  width: 100%;
  position: relative;
}

.Cx505a_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.Cx505a_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Cx505a_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.Cx505a_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.Cx505a_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.Cx505a_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.Cx505a_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Cx505a_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.Cx505a_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.Cx505a_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .Cx505a_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Cx505a_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .Cx505a_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Cx505a_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .Cx505a_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Cx505a_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .Cx505a_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Cx505a_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .Cx505a_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Cx505a_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.Cx505a_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.Cx505a_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.Cx505a_visually-hidden, .Cx505a_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Cx505a_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.Cx505a_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Cx505a_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.Cx505a_align-baseline {
  vertical-align: baseline !important;
}

.Cx505a_align-top {
  vertical-align: top !important;
}

.Cx505a_align-middle {
  vertical-align: middle !important;
}

.Cx505a_align-bottom {
  vertical-align: bottom !important;
}

.Cx505a_align-text-bottom {
  vertical-align: text-bottom !important;
}

.Cx505a_align-text-top {
  vertical-align: text-top !important;
}

.Cx505a_float-start {
  float: left !important;
}

.Cx505a_float-end {
  float: right !important;
}

.Cx505a_float-none {
  float: none !important;
}

.Cx505a_opacity-0 {
  opacity: 0 !important;
}

.Cx505a_opacity-25 {
  opacity: .25 !important;
}

.Cx505a_opacity-50 {
  opacity: .5 !important;
}

.Cx505a_opacity-75 {
  opacity: .75 !important;
}

.Cx505a_opacity-100 {
  opacity: 1 !important;
}

.Cx505a_overflow-auto {
  overflow: auto !important;
}

.Cx505a_overflow-hidden {
  overflow: hidden !important;
}

.Cx505a_overflow-visible {
  overflow: visible !important;
}

.Cx505a_overflow-scroll {
  overflow: scroll !important;
}

.Cx505a_d-inline {
  display: inline !important;
}

.Cx505a_d-inline-block {
  display: inline-block !important;
}

.Cx505a_d-block {
  display: block !important;
}

.Cx505a_d-grid {
  display: grid !important;
}

.Cx505a_d-table {
  display: table !important;
}

.Cx505a_d-table-row {
  display: table-row !important;
}

.Cx505a_d-table-cell {
  display: table-cell !important;
}

.Cx505a_d-flex {
  display: flex !important;
}

.Cx505a_d-inline-flex {
  display: inline-flex !important;
}

.Cx505a_d-none {
  display: none !important;
}

.Cx505a_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.Cx505a_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.Cx505a_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.Cx505a_shadow-none {
  box-shadow: none !important;
}

.Cx505a_position-static {
  position: static !important;
}

.Cx505a_position-relative {
  position: relative !important;
}

.Cx505a_position-absolute {
  position: absolute !important;
}

.Cx505a_position-fixed {
  position: fixed !important;
}

.Cx505a_position-sticky {
  position: sticky !important;
}

.Cx505a_top-0 {
  top: 0 !important;
}

.Cx505a_top-50 {
  top: 50% !important;
}

.Cx505a_top-100 {
  top: 100% !important;
}

.Cx505a_bottom-0 {
  bottom: 0 !important;
}

.Cx505a_bottom-50 {
  bottom: 50% !important;
}

.Cx505a_bottom-100 {
  bottom: 100% !important;
}

.Cx505a_start-0 {
  left: 0 !important;
}

.Cx505a_start-50 {
  left: 50% !important;
}

.Cx505a_start-100 {
  left: 100% !important;
}

.Cx505a_end-0 {
  right: 0 !important;
}

.Cx505a_end-50 {
  right: 50% !important;
}

.Cx505a_end-100 {
  right: 100% !important;
}

.Cx505a_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.Cx505a_translate-middle-x {
  transform: translateX(-50%) !important;
}

.Cx505a_translate-middle-y {
  transform: translateY(-50%) !important;
}

.Cx505a_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Cx505a_border-0 {
  border: 0 !important;
}

.Cx505a_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Cx505a_border-top-0 {
  border-top: 0 !important;
}

.Cx505a_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Cx505a_border-end-0 {
  border-right: 0 !important;
}

.Cx505a_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Cx505a_border-bottom-0 {
  border-bottom: 0 !important;
}

.Cx505a_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Cx505a_border-start-0 {
  border-left: 0 !important;
}

.Cx505a_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.Cx505a_border-1 {
  --bs-border-width: 1px;
}

.Cx505a_border-2 {
  --bs-border-width: 2px;
}

.Cx505a_border-3 {
  --bs-border-width: 3px;
}

.Cx505a_border-4 {
  --bs-border-width: 4px;
}

.Cx505a_border-5 {
  --bs-border-width: 5px;
}

.Cx505a_border-opacity-10 {
  --bs-border-opacity: .1;
}

.Cx505a_border-opacity-25 {
  --bs-border-opacity: .25;
}

.Cx505a_border-opacity-50 {
  --bs-border-opacity: .5;
}

.Cx505a_border-opacity-75 {
  --bs-border-opacity: .75;
}

.Cx505a_border-opacity-100 {
  --bs-border-opacity: 1;
}

.Cx505a_w-25 {
  width: 25% !important;
}

.Cx505a_w-50 {
  width: 50% !important;
}

.Cx505a_w-75 {
  width: 75% !important;
}

.Cx505a_w-100 {
  width: 100% !important;
}

.Cx505a_w-auto {
  width: auto !important;
}

.Cx505a_mw-100 {
  max-width: 100% !important;
}

.Cx505a_vw-100 {
  width: 100vw !important;
}

.Cx505a_min-vw-100 {
  min-width: 100vw !important;
}

.Cx505a_h-25 {
  height: 25% !important;
}

.Cx505a_h-50 {
  height: 50% !important;
}

.Cx505a_h-75 {
  height: 75% !important;
}

.Cx505a_h-100 {
  height: 100% !important;
}

.Cx505a_h-auto {
  height: auto !important;
}

.Cx505a_mh-100 {
  max-height: 100% !important;
}

.Cx505a_vh-100 {
  height: 100vh !important;
}

.Cx505a_min-vh-100 {
  min-height: 100vh !important;
}

.Cx505a_flex-fill {
  flex: auto !important;
}

.Cx505a_flex-row {
  flex-direction: row !important;
}

.Cx505a_flex-column {
  flex-direction: column !important;
}

.Cx505a_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.Cx505a_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.Cx505a_flex-grow-0 {
  flex-grow: 0 !important;
}

.Cx505a_flex-grow-1 {
  flex-grow: 1 !important;
}

.Cx505a_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.Cx505a_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.Cx505a_flex-wrap {
  flex-wrap: wrap !important;
}

.Cx505a_flex-nowrap {
  flex-wrap: nowrap !important;
}

.Cx505a_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.Cx505a_justify-content-start {
  justify-content: flex-start !important;
}

.Cx505a_justify-content-end {
  justify-content: flex-end !important;
}

.Cx505a_justify-content-center {
  justify-content: center !important;
}

.Cx505a_justify-content-between {
  justify-content: space-between !important;
}

.Cx505a_justify-content-around {
  justify-content: space-around !important;
}

.Cx505a_justify-content-evenly {
  justify-content: space-evenly !important;
}

.Cx505a_align-items-start {
  align-items: flex-start !important;
}

.Cx505a_align-items-end {
  align-items: flex-end !important;
}

.Cx505a_align-items-center {
  align-items: center !important;
}

.Cx505a_align-items-baseline {
  align-items: baseline !important;
}

.Cx505a_align-items-stretch {
  align-items: stretch !important;
}

.Cx505a_align-content-start {
  align-content: flex-start !important;
}

.Cx505a_align-content-end {
  align-content: flex-end !important;
}

.Cx505a_align-content-center {
  align-content: center !important;
}

.Cx505a_align-content-between {
  align-content: space-between !important;
}

.Cx505a_align-content-around {
  align-content: space-around !important;
}

.Cx505a_align-content-stretch {
  align-content: stretch !important;
}

.Cx505a_align-self-auto {
  align-self: auto !important;
}

.Cx505a_align-self-start {
  align-self: flex-start !important;
}

.Cx505a_align-self-end {
  align-self: flex-end !important;
}

.Cx505a_align-self-center {
  align-self: center !important;
}

.Cx505a_align-self-baseline {
  align-self: baseline !important;
}

.Cx505a_align-self-stretch {
  align-self: stretch !important;
}

.Cx505a_order-first {
  order: -1 !important;
}

.Cx505a_order-0 {
  order: 0 !important;
}

.Cx505a_order-1 {
  order: 1 !important;
}

.Cx505a_order-2 {
  order: 2 !important;
}

.Cx505a_order-3 {
  order: 3 !important;
}

.Cx505a_order-4 {
  order: 4 !important;
}

.Cx505a_order-5 {
  order: 5 !important;
}

.Cx505a_order-last {
  order: 6 !important;
}

.Cx505a_m-0 {
  margin: 0 !important;
}

.Cx505a_m-1 {
  margin: .25rem !important;
}

.Cx505a_m-2 {
  margin: .5rem !important;
}

.Cx505a_m-3 {
  margin: 1rem !important;
}

.Cx505a_m-4 {
  margin: 1.5rem !important;
}

.Cx505a_m-5 {
  margin: 3rem !important;
}

.Cx505a_m-auto {
  margin: auto !important;
}

.Cx505a_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.Cx505a_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.Cx505a_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.Cx505a_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.Cx505a_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.Cx505a_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.Cx505a_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.Cx505a_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Cx505a_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.Cx505a_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.Cx505a_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.Cx505a_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.Cx505a_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.Cx505a_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.Cx505a_mt-0 {
  margin-top: 0 !important;
}

.Cx505a_mt-1 {
  margin-top: .25rem !important;
}

.Cx505a_mt-2 {
  margin-top: .5rem !important;
}

.Cx505a_mt-3 {
  margin-top: 1rem !important;
}

.Cx505a_mt-4 {
  margin-top: 1.5rem !important;
}

.Cx505a_mt-5 {
  margin-top: 3rem !important;
}

.Cx505a_mt-auto {
  margin-top: auto !important;
}

.Cx505a_me-0 {
  margin-right: 0 !important;
}

.Cx505a_me-1 {
  margin-right: .25rem !important;
}

.Cx505a_me-2 {
  margin-right: .5rem !important;
}

.Cx505a_me-3 {
  margin-right: 1rem !important;
}

.Cx505a_me-4 {
  margin-right: 1.5rem !important;
}

.Cx505a_me-5 {
  margin-right: 3rem !important;
}

.Cx505a_me-auto {
  margin-right: auto !important;
}

.Cx505a_mb-0 {
  margin-bottom: 0 !important;
}

.Cx505a_mb-1 {
  margin-bottom: .25rem !important;
}

.Cx505a_mb-2 {
  margin-bottom: .5rem !important;
}

.Cx505a_mb-3 {
  margin-bottom: 1rem !important;
}

.Cx505a_mb-4 {
  margin-bottom: 1.5rem !important;
}

.Cx505a_mb-5 {
  margin-bottom: 3rem !important;
}

.Cx505a_mb-auto {
  margin-bottom: auto !important;
}

.Cx505a_ms-0 {
  margin-left: 0 !important;
}

.Cx505a_ms-1 {
  margin-left: .25rem !important;
}

.Cx505a_ms-2 {
  margin-left: .5rem !important;
}

.Cx505a_ms-3 {
  margin-left: 1rem !important;
}

.Cx505a_ms-4 {
  margin-left: 1.5rem !important;
}

.Cx505a_ms-5 {
  margin-left: 3rem !important;
}

.Cx505a_ms-auto {
  margin-left: auto !important;
}

.Cx505a_p-0 {
  padding: 0 !important;
}

.Cx505a_p-1 {
  padding: .25rem !important;
}

.Cx505a_p-2 {
  padding: .5rem !important;
}

.Cx505a_p-3 {
  padding: 1rem !important;
}

.Cx505a_p-4 {
  padding: 1.5rem !important;
}

.Cx505a_p-5 {
  padding: 3rem !important;
}

.Cx505a_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Cx505a_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.Cx505a_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.Cx505a_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.Cx505a_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.Cx505a_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.Cx505a_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Cx505a_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.Cx505a_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.Cx505a_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.Cx505a_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.Cx505a_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.Cx505a_pt-0 {
  padding-top: 0 !important;
}

.Cx505a_pt-1 {
  padding-top: .25rem !important;
}

.Cx505a_pt-2 {
  padding-top: .5rem !important;
}

.Cx505a_pt-3 {
  padding-top: 1rem !important;
}

.Cx505a_pt-4 {
  padding-top: 1.5rem !important;
}

.Cx505a_pt-5 {
  padding-top: 3rem !important;
}

.Cx505a_pe-0 {
  padding-right: 0 !important;
}

.Cx505a_pe-1 {
  padding-right: .25rem !important;
}

.Cx505a_pe-2 {
  padding-right: .5rem !important;
}

.Cx505a_pe-3 {
  padding-right: 1rem !important;
}

.Cx505a_pe-4 {
  padding-right: 1.5rem !important;
}

.Cx505a_pe-5 {
  padding-right: 3rem !important;
}

.Cx505a_pb-0 {
  padding-bottom: 0 !important;
}

.Cx505a_pb-1 {
  padding-bottom: .25rem !important;
}

.Cx505a_pb-2 {
  padding-bottom: .5rem !important;
}

.Cx505a_pb-3 {
  padding-bottom: 1rem !important;
}

.Cx505a_pb-4 {
  padding-bottom: 1.5rem !important;
}

.Cx505a_pb-5 {
  padding-bottom: 3rem !important;
}

.Cx505a_ps-0 {
  padding-left: 0 !important;
}

.Cx505a_ps-1 {
  padding-left: .25rem !important;
}

.Cx505a_ps-2 {
  padding-left: .5rem !important;
}

.Cx505a_ps-3 {
  padding-left: 1rem !important;
}

.Cx505a_ps-4 {
  padding-left: 1.5rem !important;
}

.Cx505a_ps-5 {
  padding-left: 3rem !important;
}

.Cx505a_gap-0 {
  gap: 0 !important;
}

.Cx505a_gap-1 {
  gap: .25rem !important;
}

.Cx505a_gap-2 {
  gap: .5rem !important;
}

.Cx505a_gap-3 {
  gap: 1rem !important;
}

.Cx505a_gap-4 {
  gap: 1.5rem !important;
}

.Cx505a_gap-5 {
  gap: 3rem !important;
}

.Cx505a_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.Cx505a_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.Cx505a_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.Cx505a_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.Cx505a_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.Cx505a_fs-5 {
  font-size: 1.25rem !important;
}

.Cx505a_fs-6 {
  font-size: 1rem !important;
}

.Cx505a_fst-italic {
  font-style: italic !important;
}

.Cx505a_fst-normal {
  font-style: normal !important;
}

.Cx505a_fw-light {
  font-weight: 300 !important;
}

.Cx505a_fw-lighter {
  font-weight: lighter !important;
}

.Cx505a_fw-normal {
  font-weight: 400 !important;
}

.Cx505a_fw-bold {
  font-weight: 700 !important;
}

.Cx505a_fw-semibold {
  font-weight: 600 !important;
}

.Cx505a_fw-bolder {
  font-weight: bolder !important;
}

.Cx505a_lh-1 {
  line-height: 1 !important;
}

.Cx505a_lh-sm {
  line-height: 1.25 !important;
}

.Cx505a_lh-base {
  line-height: 1.5 !important;
}

.Cx505a_lh-lg {
  line-height: 2 !important;
}

.Cx505a_text-start {
  text-align: left !important;
}

.Cx505a_text-end {
  text-align: right !important;
}

.Cx505a_text-center {
  text-align: center !important;
}

.Cx505a_text-decoration-none {
  text-decoration: none !important;
}

.Cx505a_text-decoration-underline {
  text-decoration: underline !important;
}

.Cx505a_text-decoration-line-through {
  text-decoration: line-through !important;
}

.Cx505a_text-lowercase {
  text-transform: lowercase !important;
}

.Cx505a_text-uppercase {
  text-transform: uppercase !important;
}

.Cx505a_text-capitalize {
  text-transform: capitalize !important;
}

.Cx505a_text-wrap {
  white-space: normal !important;
}

.Cx505a_text-nowrap {
  white-space: nowrap !important;
}

.Cx505a_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.Cx505a_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.Cx505a_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.Cx505a_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.Cx505a_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.Cx505a_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.Cx505a_text-opacity-25 {
  --bs-text-opacity: .25;
}

.Cx505a_text-opacity-50 {
  --bs-text-opacity: .5;
}

.Cx505a_text-opacity-75 {
  --bs-text-opacity: .75;
}

.Cx505a_text-opacity-100 {
  --bs-text-opacity: 1;
}

.Cx505a_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.Cx505a_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.Cx505a_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.Cx505a_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.Cx505a_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.Cx505a_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.Cx505a_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.Cx505a_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.Cx505a_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.Cx505a_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.Cx505a_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.Cx505a_pe-none {
  pointer-events: none !important;
}

.Cx505a_pe-auto {
  pointer-events: auto !important;
}

.Cx505a_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.Cx505a_rounded-0 {
  border-radius: 0 !important;
}

.Cx505a_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.Cx505a_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.Cx505a_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.Cx505a_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.Cx505a_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.Cx505a_rounded-circle {
  border-radius: 50% !important;
}

.Cx505a_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.Cx505a_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.Cx505a_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.Cx505a_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.Cx505a_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.Cx505a_visible {
  visibility: visible !important;
}

.Cx505a_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .Cx505a_float-sm-start {
    float: left !important;
  }

  .Cx505a_float-sm-end {
    float: right !important;
  }

  .Cx505a_float-sm-none {
    float: none !important;
  }

  .Cx505a_d-sm-inline {
    display: inline !important;
  }

  .Cx505a_d-sm-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-sm-block {
    display: block !important;
  }

  .Cx505a_d-sm-grid {
    display: grid !important;
  }

  .Cx505a_d-sm-table {
    display: table !important;
  }

  .Cx505a_d-sm-table-row {
    display: table-row !important;
  }

  .Cx505a_d-sm-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-sm-flex {
    display: flex !important;
  }

  .Cx505a_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-sm-none {
    display: none !important;
  }

  .Cx505a_flex-sm-fill {
    flex: auto !important;
  }

  .Cx505a_flex-sm-row {
    flex-direction: row !important;
  }

  .Cx505a_flex-sm-column {
    flex-direction: column !important;
  }

  .Cx505a_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Cx505a_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Cx505a_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .Cx505a_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .Cx505a_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Cx505a_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Cx505a_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .Cx505a_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .Cx505a_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Cx505a_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .Cx505a_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .Cx505a_justify-content-sm-center {
    justify-content: center !important;
  }

  .Cx505a_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .Cx505a_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .Cx505a_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .Cx505a_align-items-sm-start {
    align-items: flex-start !important;
  }

  .Cx505a_align-items-sm-end {
    align-items: flex-end !important;
  }

  .Cx505a_align-items-sm-center {
    align-items: center !important;
  }

  .Cx505a_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .Cx505a_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .Cx505a_align-content-sm-start {
    align-content: flex-start !important;
  }

  .Cx505a_align-content-sm-end {
    align-content: flex-end !important;
  }

  .Cx505a_align-content-sm-center {
    align-content: center !important;
  }

  .Cx505a_align-content-sm-between {
    align-content: space-between !important;
  }

  .Cx505a_align-content-sm-around {
    align-content: space-around !important;
  }

  .Cx505a_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .Cx505a_align-self-sm-auto {
    align-self: auto !important;
  }

  .Cx505a_align-self-sm-start {
    align-self: flex-start !important;
  }

  .Cx505a_align-self-sm-end {
    align-self: flex-end !important;
  }

  .Cx505a_align-self-sm-center {
    align-self: center !important;
  }

  .Cx505a_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .Cx505a_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .Cx505a_order-sm-first {
    order: -1 !important;
  }

  .Cx505a_order-sm-0 {
    order: 0 !important;
  }

  .Cx505a_order-sm-1 {
    order: 1 !important;
  }

  .Cx505a_order-sm-2 {
    order: 2 !important;
  }

  .Cx505a_order-sm-3 {
    order: 3 !important;
  }

  .Cx505a_order-sm-4 {
    order: 4 !important;
  }

  .Cx505a_order-sm-5 {
    order: 5 !important;
  }

  .Cx505a_order-sm-last {
    order: 6 !important;
  }

  .Cx505a_m-sm-0 {
    margin: 0 !important;
  }

  .Cx505a_m-sm-1 {
    margin: .25rem !important;
  }

  .Cx505a_m-sm-2 {
    margin: .5rem !important;
  }

  .Cx505a_m-sm-3 {
    margin: 1rem !important;
  }

  .Cx505a_m-sm-4 {
    margin: 1.5rem !important;
  }

  .Cx505a_m-sm-5 {
    margin: 3rem !important;
  }

  .Cx505a_m-sm-auto {
    margin: auto !important;
  }

  .Cx505a_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Cx505a_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Cx505a_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Cx505a_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Cx505a_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Cx505a_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Cx505a_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Cx505a_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Cx505a_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Cx505a_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Cx505a_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Cx505a_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Cx505a_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Cx505a_mt-sm-0 {
    margin-top: 0 !important;
  }

  .Cx505a_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .Cx505a_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .Cx505a_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .Cx505a_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .Cx505a_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .Cx505a_mt-sm-auto {
    margin-top: auto !important;
  }

  .Cx505a_me-sm-0 {
    margin-right: 0 !important;
  }

  .Cx505a_me-sm-1 {
    margin-right: .25rem !important;
  }

  .Cx505a_me-sm-2 {
    margin-right: .5rem !important;
  }

  .Cx505a_me-sm-3 {
    margin-right: 1rem !important;
  }

  .Cx505a_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .Cx505a_me-sm-5 {
    margin-right: 3rem !important;
  }

  .Cx505a_me-sm-auto {
    margin-right: auto !important;
  }

  .Cx505a_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .Cx505a_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .Cx505a_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .Cx505a_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .Cx505a_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .Cx505a_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .Cx505a_ms-sm-0 {
    margin-left: 0 !important;
  }

  .Cx505a_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .Cx505a_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .Cx505a_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .Cx505a_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .Cx505a_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .Cx505a_ms-sm-auto {
    margin-left: auto !important;
  }

  .Cx505a_p-sm-0 {
    padding: 0 !important;
  }

  .Cx505a_p-sm-1 {
    padding: .25rem !important;
  }

  .Cx505a_p-sm-2 {
    padding: .5rem !important;
  }

  .Cx505a_p-sm-3 {
    padding: 1rem !important;
  }

  .Cx505a_p-sm-4 {
    padding: 1.5rem !important;
  }

  .Cx505a_p-sm-5 {
    padding: 3rem !important;
  }

  .Cx505a_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Cx505a_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Cx505a_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Cx505a_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Cx505a_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Cx505a_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Cx505a_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Cx505a_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Cx505a_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Cx505a_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Cx505a_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Cx505a_pt-sm-0 {
    padding-top: 0 !important;
  }

  .Cx505a_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .Cx505a_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .Cx505a_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .Cx505a_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .Cx505a_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .Cx505a_pe-sm-0 {
    padding-right: 0 !important;
  }

  .Cx505a_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .Cx505a_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .Cx505a_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .Cx505a_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .Cx505a_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .Cx505a_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .Cx505a_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .Cx505a_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .Cx505a_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .Cx505a_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .Cx505a_ps-sm-0 {
    padding-left: 0 !important;
  }

  .Cx505a_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .Cx505a_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .Cx505a_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .Cx505a_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .Cx505a_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .Cx505a_gap-sm-0 {
    gap: 0 !important;
  }

  .Cx505a_gap-sm-1 {
    gap: .25rem !important;
  }

  .Cx505a_gap-sm-2 {
    gap: .5rem !important;
  }

  .Cx505a_gap-sm-3 {
    gap: 1rem !important;
  }

  .Cx505a_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .Cx505a_gap-sm-5 {
    gap: 3rem !important;
  }

  .Cx505a_text-sm-start {
    text-align: left !important;
  }

  .Cx505a_text-sm-end {
    text-align: right !important;
  }

  .Cx505a_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .Cx505a_float-md-start {
    float: left !important;
  }

  .Cx505a_float-md-end {
    float: right !important;
  }

  .Cx505a_float-md-none {
    float: none !important;
  }

  .Cx505a_d-md-inline {
    display: inline !important;
  }

  .Cx505a_d-md-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-md-block {
    display: block !important;
  }

  .Cx505a_d-md-grid {
    display: grid !important;
  }

  .Cx505a_d-md-table {
    display: table !important;
  }

  .Cx505a_d-md-table-row {
    display: table-row !important;
  }

  .Cx505a_d-md-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-md-flex {
    display: flex !important;
  }

  .Cx505a_d-md-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-md-none {
    display: none !important;
  }

  .Cx505a_flex-md-fill {
    flex: auto !important;
  }

  .Cx505a_flex-md-row {
    flex-direction: row !important;
  }

  .Cx505a_flex-md-column {
    flex-direction: column !important;
  }

  .Cx505a_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Cx505a_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Cx505a_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .Cx505a_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .Cx505a_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Cx505a_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Cx505a_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .Cx505a_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .Cx505a_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Cx505a_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .Cx505a_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .Cx505a_justify-content-md-center {
    justify-content: center !important;
  }

  .Cx505a_justify-content-md-between {
    justify-content: space-between !important;
  }

  .Cx505a_justify-content-md-around {
    justify-content: space-around !important;
  }

  .Cx505a_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .Cx505a_align-items-md-start {
    align-items: flex-start !important;
  }

  .Cx505a_align-items-md-end {
    align-items: flex-end !important;
  }

  .Cx505a_align-items-md-center {
    align-items: center !important;
  }

  .Cx505a_align-items-md-baseline {
    align-items: baseline !important;
  }

  .Cx505a_align-items-md-stretch {
    align-items: stretch !important;
  }

  .Cx505a_align-content-md-start {
    align-content: flex-start !important;
  }

  .Cx505a_align-content-md-end {
    align-content: flex-end !important;
  }

  .Cx505a_align-content-md-center {
    align-content: center !important;
  }

  .Cx505a_align-content-md-between {
    align-content: space-between !important;
  }

  .Cx505a_align-content-md-around {
    align-content: space-around !important;
  }

  .Cx505a_align-content-md-stretch {
    align-content: stretch !important;
  }

  .Cx505a_align-self-md-auto {
    align-self: auto !important;
  }

  .Cx505a_align-self-md-start {
    align-self: flex-start !important;
  }

  .Cx505a_align-self-md-end {
    align-self: flex-end !important;
  }

  .Cx505a_align-self-md-center {
    align-self: center !important;
  }

  .Cx505a_align-self-md-baseline {
    align-self: baseline !important;
  }

  .Cx505a_align-self-md-stretch {
    align-self: stretch !important;
  }

  .Cx505a_order-md-first {
    order: -1 !important;
  }

  .Cx505a_order-md-0 {
    order: 0 !important;
  }

  .Cx505a_order-md-1 {
    order: 1 !important;
  }

  .Cx505a_order-md-2 {
    order: 2 !important;
  }

  .Cx505a_order-md-3 {
    order: 3 !important;
  }

  .Cx505a_order-md-4 {
    order: 4 !important;
  }

  .Cx505a_order-md-5 {
    order: 5 !important;
  }

  .Cx505a_order-md-last {
    order: 6 !important;
  }

  .Cx505a_m-md-0 {
    margin: 0 !important;
  }

  .Cx505a_m-md-1 {
    margin: .25rem !important;
  }

  .Cx505a_m-md-2 {
    margin: .5rem !important;
  }

  .Cx505a_m-md-3 {
    margin: 1rem !important;
  }

  .Cx505a_m-md-4 {
    margin: 1.5rem !important;
  }

  .Cx505a_m-md-5 {
    margin: 3rem !important;
  }

  .Cx505a_m-md-auto {
    margin: auto !important;
  }

  .Cx505a_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Cx505a_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Cx505a_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Cx505a_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Cx505a_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Cx505a_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Cx505a_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Cx505a_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Cx505a_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Cx505a_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Cx505a_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Cx505a_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Cx505a_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Cx505a_mt-md-0 {
    margin-top: 0 !important;
  }

  .Cx505a_mt-md-1 {
    margin-top: .25rem !important;
  }

  .Cx505a_mt-md-2 {
    margin-top: .5rem !important;
  }

  .Cx505a_mt-md-3 {
    margin-top: 1rem !important;
  }

  .Cx505a_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .Cx505a_mt-md-5 {
    margin-top: 3rem !important;
  }

  .Cx505a_mt-md-auto {
    margin-top: auto !important;
  }

  .Cx505a_me-md-0 {
    margin-right: 0 !important;
  }

  .Cx505a_me-md-1 {
    margin-right: .25rem !important;
  }

  .Cx505a_me-md-2 {
    margin-right: .5rem !important;
  }

  .Cx505a_me-md-3 {
    margin-right: 1rem !important;
  }

  .Cx505a_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .Cx505a_me-md-5 {
    margin-right: 3rem !important;
  }

  .Cx505a_me-md-auto {
    margin-right: auto !important;
  }

  .Cx505a_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .Cx505a_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .Cx505a_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .Cx505a_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .Cx505a_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .Cx505a_mb-md-auto {
    margin-bottom: auto !important;
  }

  .Cx505a_ms-md-0 {
    margin-left: 0 !important;
  }

  .Cx505a_ms-md-1 {
    margin-left: .25rem !important;
  }

  .Cx505a_ms-md-2 {
    margin-left: .5rem !important;
  }

  .Cx505a_ms-md-3 {
    margin-left: 1rem !important;
  }

  .Cx505a_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .Cx505a_ms-md-5 {
    margin-left: 3rem !important;
  }

  .Cx505a_ms-md-auto {
    margin-left: auto !important;
  }

  .Cx505a_p-md-0 {
    padding: 0 !important;
  }

  .Cx505a_p-md-1 {
    padding: .25rem !important;
  }

  .Cx505a_p-md-2 {
    padding: .5rem !important;
  }

  .Cx505a_p-md-3 {
    padding: 1rem !important;
  }

  .Cx505a_p-md-4 {
    padding: 1.5rem !important;
  }

  .Cx505a_p-md-5 {
    padding: 3rem !important;
  }

  .Cx505a_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Cx505a_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Cx505a_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Cx505a_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Cx505a_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Cx505a_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Cx505a_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Cx505a_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Cx505a_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Cx505a_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Cx505a_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Cx505a_pt-md-0 {
    padding-top: 0 !important;
  }

  .Cx505a_pt-md-1 {
    padding-top: .25rem !important;
  }

  .Cx505a_pt-md-2 {
    padding-top: .5rem !important;
  }

  .Cx505a_pt-md-3 {
    padding-top: 1rem !important;
  }

  .Cx505a_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .Cx505a_pt-md-5 {
    padding-top: 3rem !important;
  }

  .Cx505a_pe-md-0 {
    padding-right: 0 !important;
  }

  .Cx505a_pe-md-1 {
    padding-right: .25rem !important;
  }

  .Cx505a_pe-md-2 {
    padding-right: .5rem !important;
  }

  .Cx505a_pe-md-3 {
    padding-right: 1rem !important;
  }

  .Cx505a_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .Cx505a_pe-md-5 {
    padding-right: 3rem !important;
  }

  .Cx505a_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .Cx505a_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .Cx505a_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .Cx505a_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .Cx505a_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .Cx505a_ps-md-0 {
    padding-left: 0 !important;
  }

  .Cx505a_ps-md-1 {
    padding-left: .25rem !important;
  }

  .Cx505a_ps-md-2 {
    padding-left: .5rem !important;
  }

  .Cx505a_ps-md-3 {
    padding-left: 1rem !important;
  }

  .Cx505a_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .Cx505a_ps-md-5 {
    padding-left: 3rem !important;
  }

  .Cx505a_gap-md-0 {
    gap: 0 !important;
  }

  .Cx505a_gap-md-1 {
    gap: .25rem !important;
  }

  .Cx505a_gap-md-2 {
    gap: .5rem !important;
  }

  .Cx505a_gap-md-3 {
    gap: 1rem !important;
  }

  .Cx505a_gap-md-4 {
    gap: 1.5rem !important;
  }

  .Cx505a_gap-md-5 {
    gap: 3rem !important;
  }

  .Cx505a_text-md-start {
    text-align: left !important;
  }

  .Cx505a_text-md-end {
    text-align: right !important;
  }

  .Cx505a_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .Cx505a_float-lg-start {
    float: left !important;
  }

  .Cx505a_float-lg-end {
    float: right !important;
  }

  .Cx505a_float-lg-none {
    float: none !important;
  }

  .Cx505a_d-lg-inline {
    display: inline !important;
  }

  .Cx505a_d-lg-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-lg-block {
    display: block !important;
  }

  .Cx505a_d-lg-grid {
    display: grid !important;
  }

  .Cx505a_d-lg-table {
    display: table !important;
  }

  .Cx505a_d-lg-table-row {
    display: table-row !important;
  }

  .Cx505a_d-lg-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-lg-flex {
    display: flex !important;
  }

  .Cx505a_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-lg-none {
    display: none !important;
  }

  .Cx505a_flex-lg-fill {
    flex: auto !important;
  }

  .Cx505a_flex-lg-row {
    flex-direction: row !important;
  }

  .Cx505a_flex-lg-column {
    flex-direction: column !important;
  }

  .Cx505a_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Cx505a_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Cx505a_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .Cx505a_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .Cx505a_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Cx505a_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Cx505a_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .Cx505a_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .Cx505a_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Cx505a_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .Cx505a_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .Cx505a_justify-content-lg-center {
    justify-content: center !important;
  }

  .Cx505a_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .Cx505a_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .Cx505a_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .Cx505a_align-items-lg-start {
    align-items: flex-start !important;
  }

  .Cx505a_align-items-lg-end {
    align-items: flex-end !important;
  }

  .Cx505a_align-items-lg-center {
    align-items: center !important;
  }

  .Cx505a_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .Cx505a_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .Cx505a_align-content-lg-start {
    align-content: flex-start !important;
  }

  .Cx505a_align-content-lg-end {
    align-content: flex-end !important;
  }

  .Cx505a_align-content-lg-center {
    align-content: center !important;
  }

  .Cx505a_align-content-lg-between {
    align-content: space-between !important;
  }

  .Cx505a_align-content-lg-around {
    align-content: space-around !important;
  }

  .Cx505a_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .Cx505a_align-self-lg-auto {
    align-self: auto !important;
  }

  .Cx505a_align-self-lg-start {
    align-self: flex-start !important;
  }

  .Cx505a_align-self-lg-end {
    align-self: flex-end !important;
  }

  .Cx505a_align-self-lg-center {
    align-self: center !important;
  }

  .Cx505a_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .Cx505a_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .Cx505a_order-lg-first {
    order: -1 !important;
  }

  .Cx505a_order-lg-0 {
    order: 0 !important;
  }

  .Cx505a_order-lg-1 {
    order: 1 !important;
  }

  .Cx505a_order-lg-2 {
    order: 2 !important;
  }

  .Cx505a_order-lg-3 {
    order: 3 !important;
  }

  .Cx505a_order-lg-4 {
    order: 4 !important;
  }

  .Cx505a_order-lg-5 {
    order: 5 !important;
  }

  .Cx505a_order-lg-last {
    order: 6 !important;
  }

  .Cx505a_m-lg-0 {
    margin: 0 !important;
  }

  .Cx505a_m-lg-1 {
    margin: .25rem !important;
  }

  .Cx505a_m-lg-2 {
    margin: .5rem !important;
  }

  .Cx505a_m-lg-3 {
    margin: 1rem !important;
  }

  .Cx505a_m-lg-4 {
    margin: 1.5rem !important;
  }

  .Cx505a_m-lg-5 {
    margin: 3rem !important;
  }

  .Cx505a_m-lg-auto {
    margin: auto !important;
  }

  .Cx505a_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Cx505a_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Cx505a_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Cx505a_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Cx505a_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Cx505a_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Cx505a_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Cx505a_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Cx505a_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Cx505a_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Cx505a_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Cx505a_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Cx505a_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Cx505a_mt-lg-0 {
    margin-top: 0 !important;
  }

  .Cx505a_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .Cx505a_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .Cx505a_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .Cx505a_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .Cx505a_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .Cx505a_mt-lg-auto {
    margin-top: auto !important;
  }

  .Cx505a_me-lg-0 {
    margin-right: 0 !important;
  }

  .Cx505a_me-lg-1 {
    margin-right: .25rem !important;
  }

  .Cx505a_me-lg-2 {
    margin-right: .5rem !important;
  }

  .Cx505a_me-lg-3 {
    margin-right: 1rem !important;
  }

  .Cx505a_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .Cx505a_me-lg-5 {
    margin-right: 3rem !important;
  }

  .Cx505a_me-lg-auto {
    margin-right: auto !important;
  }

  .Cx505a_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .Cx505a_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .Cx505a_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .Cx505a_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .Cx505a_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .Cx505a_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .Cx505a_ms-lg-0 {
    margin-left: 0 !important;
  }

  .Cx505a_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .Cx505a_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .Cx505a_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .Cx505a_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .Cx505a_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .Cx505a_ms-lg-auto {
    margin-left: auto !important;
  }

  .Cx505a_p-lg-0 {
    padding: 0 !important;
  }

  .Cx505a_p-lg-1 {
    padding: .25rem !important;
  }

  .Cx505a_p-lg-2 {
    padding: .5rem !important;
  }

  .Cx505a_p-lg-3 {
    padding: 1rem !important;
  }

  .Cx505a_p-lg-4 {
    padding: 1.5rem !important;
  }

  .Cx505a_p-lg-5 {
    padding: 3rem !important;
  }

  .Cx505a_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Cx505a_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Cx505a_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Cx505a_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Cx505a_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Cx505a_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Cx505a_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Cx505a_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Cx505a_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Cx505a_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Cx505a_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Cx505a_pt-lg-0 {
    padding-top: 0 !important;
  }

  .Cx505a_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .Cx505a_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .Cx505a_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .Cx505a_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .Cx505a_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .Cx505a_pe-lg-0 {
    padding-right: 0 !important;
  }

  .Cx505a_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .Cx505a_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .Cx505a_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .Cx505a_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .Cx505a_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .Cx505a_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .Cx505a_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .Cx505a_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .Cx505a_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .Cx505a_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .Cx505a_ps-lg-0 {
    padding-left: 0 !important;
  }

  .Cx505a_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .Cx505a_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .Cx505a_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .Cx505a_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .Cx505a_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .Cx505a_gap-lg-0 {
    gap: 0 !important;
  }

  .Cx505a_gap-lg-1 {
    gap: .25rem !important;
  }

  .Cx505a_gap-lg-2 {
    gap: .5rem !important;
  }

  .Cx505a_gap-lg-3 {
    gap: 1rem !important;
  }

  .Cx505a_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .Cx505a_gap-lg-5 {
    gap: 3rem !important;
  }

  .Cx505a_text-lg-start {
    text-align: left !important;
  }

  .Cx505a_text-lg-end {
    text-align: right !important;
  }

  .Cx505a_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Cx505a_float-xl-start {
    float: left !important;
  }

  .Cx505a_float-xl-end {
    float: right !important;
  }

  .Cx505a_float-xl-none {
    float: none !important;
  }

  .Cx505a_d-xl-inline {
    display: inline !important;
  }

  .Cx505a_d-xl-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-xl-block {
    display: block !important;
  }

  .Cx505a_d-xl-grid {
    display: grid !important;
  }

  .Cx505a_d-xl-table {
    display: table !important;
  }

  .Cx505a_d-xl-table-row {
    display: table-row !important;
  }

  .Cx505a_d-xl-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-xl-flex {
    display: flex !important;
  }

  .Cx505a_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-xl-none {
    display: none !important;
  }

  .Cx505a_flex-xl-fill {
    flex: auto !important;
  }

  .Cx505a_flex-xl-row {
    flex-direction: row !important;
  }

  .Cx505a_flex-xl-column {
    flex-direction: column !important;
  }

  .Cx505a_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Cx505a_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Cx505a_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .Cx505a_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .Cx505a_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Cx505a_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Cx505a_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .Cx505a_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Cx505a_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Cx505a_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .Cx505a_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .Cx505a_justify-content-xl-center {
    justify-content: center !important;
  }

  .Cx505a_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .Cx505a_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .Cx505a_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .Cx505a_align-items-xl-start {
    align-items: flex-start !important;
  }

  .Cx505a_align-items-xl-end {
    align-items: flex-end !important;
  }

  .Cx505a_align-items-xl-center {
    align-items: center !important;
  }

  .Cx505a_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .Cx505a_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .Cx505a_align-content-xl-start {
    align-content: flex-start !important;
  }

  .Cx505a_align-content-xl-end {
    align-content: flex-end !important;
  }

  .Cx505a_align-content-xl-center {
    align-content: center !important;
  }

  .Cx505a_align-content-xl-between {
    align-content: space-between !important;
  }

  .Cx505a_align-content-xl-around {
    align-content: space-around !important;
  }

  .Cx505a_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .Cx505a_align-self-xl-auto {
    align-self: auto !important;
  }

  .Cx505a_align-self-xl-start {
    align-self: flex-start !important;
  }

  .Cx505a_align-self-xl-end {
    align-self: flex-end !important;
  }

  .Cx505a_align-self-xl-center {
    align-self: center !important;
  }

  .Cx505a_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .Cx505a_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .Cx505a_order-xl-first {
    order: -1 !important;
  }

  .Cx505a_order-xl-0 {
    order: 0 !important;
  }

  .Cx505a_order-xl-1 {
    order: 1 !important;
  }

  .Cx505a_order-xl-2 {
    order: 2 !important;
  }

  .Cx505a_order-xl-3 {
    order: 3 !important;
  }

  .Cx505a_order-xl-4 {
    order: 4 !important;
  }

  .Cx505a_order-xl-5 {
    order: 5 !important;
  }

  .Cx505a_order-xl-last {
    order: 6 !important;
  }

  .Cx505a_m-xl-0 {
    margin: 0 !important;
  }

  .Cx505a_m-xl-1 {
    margin: .25rem !important;
  }

  .Cx505a_m-xl-2 {
    margin: .5rem !important;
  }

  .Cx505a_m-xl-3 {
    margin: 1rem !important;
  }

  .Cx505a_m-xl-4 {
    margin: 1.5rem !important;
  }

  .Cx505a_m-xl-5 {
    margin: 3rem !important;
  }

  .Cx505a_m-xl-auto {
    margin: auto !important;
  }

  .Cx505a_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Cx505a_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Cx505a_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Cx505a_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Cx505a_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Cx505a_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Cx505a_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Cx505a_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Cx505a_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Cx505a_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Cx505a_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Cx505a_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Cx505a_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Cx505a_mt-xl-0 {
    margin-top: 0 !important;
  }

  .Cx505a_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .Cx505a_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .Cx505a_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .Cx505a_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .Cx505a_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .Cx505a_mt-xl-auto {
    margin-top: auto !important;
  }

  .Cx505a_me-xl-0 {
    margin-right: 0 !important;
  }

  .Cx505a_me-xl-1 {
    margin-right: .25rem !important;
  }

  .Cx505a_me-xl-2 {
    margin-right: .5rem !important;
  }

  .Cx505a_me-xl-3 {
    margin-right: 1rem !important;
  }

  .Cx505a_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .Cx505a_me-xl-5 {
    margin-right: 3rem !important;
  }

  .Cx505a_me-xl-auto {
    margin-right: auto !important;
  }

  .Cx505a_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .Cx505a_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .Cx505a_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .Cx505a_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .Cx505a_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .Cx505a_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .Cx505a_ms-xl-0 {
    margin-left: 0 !important;
  }

  .Cx505a_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .Cx505a_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .Cx505a_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .Cx505a_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .Cx505a_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .Cx505a_ms-xl-auto {
    margin-left: auto !important;
  }

  .Cx505a_p-xl-0 {
    padding: 0 !important;
  }

  .Cx505a_p-xl-1 {
    padding: .25rem !important;
  }

  .Cx505a_p-xl-2 {
    padding: .5rem !important;
  }

  .Cx505a_p-xl-3 {
    padding: 1rem !important;
  }

  .Cx505a_p-xl-4 {
    padding: 1.5rem !important;
  }

  .Cx505a_p-xl-5 {
    padding: 3rem !important;
  }

  .Cx505a_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Cx505a_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Cx505a_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Cx505a_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Cx505a_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Cx505a_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Cx505a_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Cx505a_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Cx505a_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Cx505a_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Cx505a_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Cx505a_pt-xl-0 {
    padding-top: 0 !important;
  }

  .Cx505a_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .Cx505a_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .Cx505a_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .Cx505a_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .Cx505a_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .Cx505a_pe-xl-0 {
    padding-right: 0 !important;
  }

  .Cx505a_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .Cx505a_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .Cx505a_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .Cx505a_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .Cx505a_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .Cx505a_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .Cx505a_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .Cx505a_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .Cx505a_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .Cx505a_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .Cx505a_ps-xl-0 {
    padding-left: 0 !important;
  }

  .Cx505a_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .Cx505a_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .Cx505a_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .Cx505a_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .Cx505a_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .Cx505a_gap-xl-0 {
    gap: 0 !important;
  }

  .Cx505a_gap-xl-1 {
    gap: .25rem !important;
  }

  .Cx505a_gap-xl-2 {
    gap: .5rem !important;
  }

  .Cx505a_gap-xl-3 {
    gap: 1rem !important;
  }

  .Cx505a_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .Cx505a_gap-xl-5 {
    gap: 3rem !important;
  }

  .Cx505a_text-xl-start {
    text-align: left !important;
  }

  .Cx505a_text-xl-end {
    text-align: right !important;
  }

  .Cx505a_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .Cx505a_float-xxl-start {
    float: left !important;
  }

  .Cx505a_float-xxl-end {
    float: right !important;
  }

  .Cx505a_float-xxl-none {
    float: none !important;
  }

  .Cx505a_d-xxl-inline {
    display: inline !important;
  }

  .Cx505a_d-xxl-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-xxl-block {
    display: block !important;
  }

  .Cx505a_d-xxl-grid {
    display: grid !important;
  }

  .Cx505a_d-xxl-table {
    display: table !important;
  }

  .Cx505a_d-xxl-table-row {
    display: table-row !important;
  }

  .Cx505a_d-xxl-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-xxl-flex {
    display: flex !important;
  }

  .Cx505a_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-xxl-none {
    display: none !important;
  }

  .Cx505a_flex-xxl-fill {
    flex: auto !important;
  }

  .Cx505a_flex-xxl-row {
    flex-direction: row !important;
  }

  .Cx505a_flex-xxl-column {
    flex-direction: column !important;
  }

  .Cx505a_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Cx505a_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Cx505a_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .Cx505a_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .Cx505a_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Cx505a_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Cx505a_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .Cx505a_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Cx505a_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Cx505a_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .Cx505a_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .Cx505a_justify-content-xxl-center {
    justify-content: center !important;
  }

  .Cx505a_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .Cx505a_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .Cx505a_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .Cx505a_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .Cx505a_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .Cx505a_align-items-xxl-center {
    align-items: center !important;
  }

  .Cx505a_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .Cx505a_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .Cx505a_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .Cx505a_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .Cx505a_align-content-xxl-center {
    align-content: center !important;
  }

  .Cx505a_align-content-xxl-between {
    align-content: space-between !important;
  }

  .Cx505a_align-content-xxl-around {
    align-content: space-around !important;
  }

  .Cx505a_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .Cx505a_align-self-xxl-auto {
    align-self: auto !important;
  }

  .Cx505a_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .Cx505a_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .Cx505a_align-self-xxl-center {
    align-self: center !important;
  }

  .Cx505a_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .Cx505a_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .Cx505a_order-xxl-first {
    order: -1 !important;
  }

  .Cx505a_order-xxl-0 {
    order: 0 !important;
  }

  .Cx505a_order-xxl-1 {
    order: 1 !important;
  }

  .Cx505a_order-xxl-2 {
    order: 2 !important;
  }

  .Cx505a_order-xxl-3 {
    order: 3 !important;
  }

  .Cx505a_order-xxl-4 {
    order: 4 !important;
  }

  .Cx505a_order-xxl-5 {
    order: 5 !important;
  }

  .Cx505a_order-xxl-last {
    order: 6 !important;
  }

  .Cx505a_m-xxl-0 {
    margin: 0 !important;
  }

  .Cx505a_m-xxl-1 {
    margin: .25rem !important;
  }

  .Cx505a_m-xxl-2 {
    margin: .5rem !important;
  }

  .Cx505a_m-xxl-3 {
    margin: 1rem !important;
  }

  .Cx505a_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .Cx505a_m-xxl-5 {
    margin: 3rem !important;
  }

  .Cx505a_m-xxl-auto {
    margin: auto !important;
  }

  .Cx505a_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Cx505a_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Cx505a_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Cx505a_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Cx505a_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Cx505a_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Cx505a_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Cx505a_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Cx505a_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Cx505a_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Cx505a_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Cx505a_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Cx505a_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Cx505a_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .Cx505a_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .Cx505a_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .Cx505a_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .Cx505a_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .Cx505a_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .Cx505a_mt-xxl-auto {
    margin-top: auto !important;
  }

  .Cx505a_me-xxl-0 {
    margin-right: 0 !important;
  }

  .Cx505a_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .Cx505a_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .Cx505a_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .Cx505a_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .Cx505a_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .Cx505a_me-xxl-auto {
    margin-right: auto !important;
  }

  .Cx505a_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .Cx505a_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .Cx505a_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .Cx505a_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .Cx505a_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Cx505a_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .Cx505a_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .Cx505a_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .Cx505a_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .Cx505a_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .Cx505a_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .Cx505a_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .Cx505a_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .Cx505a_ms-xxl-auto {
    margin-left: auto !important;
  }

  .Cx505a_p-xxl-0 {
    padding: 0 !important;
  }

  .Cx505a_p-xxl-1 {
    padding: .25rem !important;
  }

  .Cx505a_p-xxl-2 {
    padding: .5rem !important;
  }

  .Cx505a_p-xxl-3 {
    padding: 1rem !important;
  }

  .Cx505a_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .Cx505a_p-xxl-5 {
    padding: 3rem !important;
  }

  .Cx505a_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Cx505a_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Cx505a_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Cx505a_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Cx505a_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Cx505a_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Cx505a_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Cx505a_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Cx505a_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Cx505a_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Cx505a_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Cx505a_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .Cx505a_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .Cx505a_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .Cx505a_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .Cx505a_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .Cx505a_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .Cx505a_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .Cx505a_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .Cx505a_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .Cx505a_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .Cx505a_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .Cx505a_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .Cx505a_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .Cx505a_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .Cx505a_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .Cx505a_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .Cx505a_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Cx505a_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .Cx505a_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .Cx505a_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .Cx505a_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .Cx505a_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .Cx505a_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .Cx505a_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .Cx505a_gap-xxl-0 {
    gap: 0 !important;
  }

  .Cx505a_gap-xxl-1 {
    gap: .25rem !important;
  }

  .Cx505a_gap-xxl-2 {
    gap: .5rem !important;
  }

  .Cx505a_gap-xxl-3 {
    gap: 1rem !important;
  }

  .Cx505a_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .Cx505a_gap-xxl-5 {
    gap: 3rem !important;
  }

  .Cx505a_text-xxl-start {
    text-align: left !important;
  }

  .Cx505a_text-xxl-end {
    text-align: right !important;
  }

  .Cx505a_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Cx505a_fs-1 {
    font-size: 2.5rem !important;
  }

  .Cx505a_fs-2 {
    font-size: 2rem !important;
  }

  .Cx505a_fs-3 {
    font-size: 1.75rem !important;
  }

  .Cx505a_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .Cx505a_d-print-inline {
    display: inline !important;
  }

  .Cx505a_d-print-inline-block {
    display: inline-block !important;
  }

  .Cx505a_d-print-block {
    display: block !important;
  }

  .Cx505a_d-print-grid {
    display: grid !important;
  }

  .Cx505a_d-print-table {
    display: table !important;
  }

  .Cx505a_d-print-table-row {
    display: table-row !important;
  }

  .Cx505a_d-print-table-cell {
    display: table-cell !important;
  }

  .Cx505a_d-print-flex {
    display: flex !important;
  }

  .Cx505a_d-print-inline-flex {
    display: inline-flex !important;
  }

  .Cx505a_d-print-none {
    display: none !important;
  }
}

.Cx505a_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.Cx505a_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.Cx505a_nav-tabs .Cx505a_nav-item .Cx505a_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.Cx505a_nav-tabs .Cx505a_nav-item .Cx505a_nav-link.Cx505a_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.Cx505a_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#Cx505a_root {
  height: 100%;
}

.Cx505a_highcharts-credits {
  display: none !important;
}

h3, .Cx505a_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.Cx505a_modal .Cx505a_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.Cx505a_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Cx505a_table thead tr th.Cx505a_col-actions {
  width: 110px !important;
}

table.Cx505a_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Cx505a_table tbody tr.Cx505a_deleting {
  background-color: #ff323233 !important;
}

.Cx505a_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.Cx505a_btn-sm, .Cx505a_btn-group-sm > .Cx505a_btn {
  font-size: .6rem !important;
}

.Cx505a_modal {
  background-color: #0a0a0a4d;
}

.Cx505a_btn:focus {
  box-shadow: none !important;
}

.Cx505a_content-filter .Cx505a_react-datepicker-wrapper {
  width: auto !important;
}

.Cx505a_content-filter .Cx505a_react-datepicker-wrapper .Cx505a_form-control {
  font-size: .85rem !important;
}

.Cx505a_content-filter .Cx505a_input-group-text, .Cx505a_content-filter .Cx505a_btn {
  height: 2.15rem !important;
}

.Cx505a_content-filter .Cx505a_btn {
  border-color: #ccc;
}

.Cx505a_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.Cx505a_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.Cx505a_table > :not(:first-child) {
  border-top: unset !important;
}

.Cx505a_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.Cx505a_highcharts-series.Cx505a_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.Cx505a_expandable {
  transition: all .5s;
}

.Cx505a_expandable > .Cx505a_expander-icon {
  cursor: pointer;
}

.Cx505a_expandable > .Cx505a_expander-icon:hover {
  color: #328cfa66;
}

.Cx505a_expandable.Cx505a_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .Cx505a_modal .Cx505a_modal-xl {
    width: 100% !important;
  }

  .Cx505a_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.Cx505a_d-none, .Cx505a_d-block {
  transition: all .5s;
}

.Cx505a_wrapper {
  height: 100vh;
  flex-direction: row;
  place-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Cx505a_card {
  text-align: center;
  background: #fff;
  border-radius: 24px;
  padding: 36px;
}

.Cx505a_content {
  max-width: 450px;
  width: 90%;
  margin: 50px auto;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, ._7F6L5G_h6, h5, ._7F6L5G_h5, h4, ._7F6L5G_h4, h3, ._7F6L5G_h3, h2, ._7F6L5G_h2, h1, ._7F6L5G_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, ._7F6L5G_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, ._7F6L5G_h1 {
    font-size: 2.5rem;
  }
}

h2, ._7F6L5G_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, ._7F6L5G_h2 {
    font-size: 2rem;
  }
}

h3, ._7F6L5G_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, ._7F6L5G_h3 {
    font-size: 1.75rem;
  }
}

h4, ._7F6L5G_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, ._7F6L5G_h4 {
    font-size: 1.5rem;
  }
}

h5, ._7F6L5G_h5 {
  font-size: 1.25rem;
}

h6, ._7F6L5G_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, ._7F6L5G_small {
  font-size: .875em;
}

mark, ._7F6L5G_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

._7F6L5G_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

._7F6L5G_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-1 {
    font-size: 5rem;
  }
}

._7F6L5G_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-2 {
    font-size: 4.5rem;
  }
}

._7F6L5G_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-3 {
    font-size: 4rem;
  }
}

._7F6L5G_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-4 {
    font-size: 3.5rem;
  }
}

._7F6L5G_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-5 {
    font-size: 3rem;
  }
}

._7F6L5G_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._7F6L5G_display-6 {
    font-size: 2.5rem;
  }
}

._7F6L5G_list-unstyled, ._7F6L5G_list-inline {
  padding-left: 0;
  list-style: none;
}

._7F6L5G_list-inline-item {
  display: inline-block;
}

._7F6L5G_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

._7F6L5G_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

._7F6L5G_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

._7F6L5G_blockquote > :last-child {
  margin-bottom: 0;
}

._7F6L5G_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

._7F6L5G_blockquote-footer:before {
  content: "— ";
}

._7F6L5G_img-fluid {
  max-width: 100%;
  height: auto;
}

._7F6L5G_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

._7F6L5G_figure {
  display: inline-block;
}

._7F6L5G_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

._7F6L5G_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

._7F6L5G_container, ._7F6L5G_container-fluid, ._7F6L5G_container-xxl, ._7F6L5G_container-xl, ._7F6L5G_container-lg, ._7F6L5G_container-md, ._7F6L5G_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  ._7F6L5G_container-sm, ._7F6L5G_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_container-md, ._7F6L5G_container-sm, ._7F6L5G_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_container-lg, ._7F6L5G_container-md, ._7F6L5G_container-sm, ._7F6L5G_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_container-xl, ._7F6L5G_container-lg, ._7F6L5G_container-md, ._7F6L5G_container-sm, ._7F6L5G_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_container-xxl, ._7F6L5G_container-xl, ._7F6L5G_container-lg, ._7F6L5G_container-md, ._7F6L5G_container-sm, ._7F6L5G_container {
    max-width: 1320px;
  }
}

._7F6L5G_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

._7F6L5G_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

._7F6L5G_col {
  flex: 1 0;
}

._7F6L5G_row-cols-auto > * {
  width: auto;
  flex: none;
}

._7F6L5G_row-cols-1 > * {
  width: 100%;
  flex: none;
}

._7F6L5G_row-cols-2 > * {
  width: 50%;
  flex: none;
}

._7F6L5G_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

._7F6L5G_row-cols-4 > * {
  width: 25%;
  flex: none;
}

._7F6L5G_row-cols-5 > * {
  width: 20%;
  flex: none;
}

._7F6L5G_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

._7F6L5G_col-auto {
  width: auto;
  flex: none;
}

._7F6L5G_col-1 {
  width: 8.33333%;
  flex: none;
}

._7F6L5G_col-2 {
  width: 16.6667%;
  flex: none;
}

._7F6L5G_col-3 {
  width: 25%;
  flex: none;
}

._7F6L5G_col-4 {
  width: 33.3333%;
  flex: none;
}

._7F6L5G_col-5 {
  width: 41.6667%;
  flex: none;
}

._7F6L5G_col-6 {
  width: 50%;
  flex: none;
}

._7F6L5G_col-7 {
  width: 58.3333%;
  flex: none;
}

._7F6L5G_col-8 {
  width: 66.6667%;
  flex: none;
}

._7F6L5G_col-9 {
  width: 75%;
  flex: none;
}

._7F6L5G_col-10 {
  width: 83.3333%;
  flex: none;
}

._7F6L5G_col-11 {
  width: 91.6667%;
  flex: none;
}

._7F6L5G_col-12 {
  width: 100%;
  flex: none;
}

._7F6L5G_offset-1 {
  margin-left: 8.33333%;
}

._7F6L5G_offset-2 {
  margin-left: 16.6667%;
}

._7F6L5G_offset-3 {
  margin-left: 25%;
}

._7F6L5G_offset-4 {
  margin-left: 33.3333%;
}

._7F6L5G_offset-5 {
  margin-left: 41.6667%;
}

._7F6L5G_offset-6 {
  margin-left: 50%;
}

._7F6L5G_offset-7 {
  margin-left: 58.3333%;
}

._7F6L5G_offset-8 {
  margin-left: 66.6667%;
}

._7F6L5G_offset-9 {
  margin-left: 75%;
}

._7F6L5G_offset-10 {
  margin-left: 83.3333%;
}

._7F6L5G_offset-11 {
  margin-left: 91.6667%;
}

._7F6L5G_g-0, ._7F6L5G_gx-0 {
  --bs-gutter-x: 0;
}

._7F6L5G_g-0, ._7F6L5G_gy-0 {
  --bs-gutter-y: 0;
}

._7F6L5G_g-1, ._7F6L5G_gx-1 {
  --bs-gutter-x: .25rem;
}

._7F6L5G_g-1, ._7F6L5G_gy-1 {
  --bs-gutter-y: .25rem;
}

._7F6L5G_g-2, ._7F6L5G_gx-2 {
  --bs-gutter-x: .5rem;
}

._7F6L5G_g-2, ._7F6L5G_gy-2 {
  --bs-gutter-y: .5rem;
}

._7F6L5G_g-3, ._7F6L5G_gx-3 {
  --bs-gutter-x: 1rem;
}

._7F6L5G_g-3, ._7F6L5G_gy-3 {
  --bs-gutter-y: 1rem;
}

._7F6L5G_g-4, ._7F6L5G_gx-4 {
  --bs-gutter-x: 1.5rem;
}

._7F6L5G_g-4, ._7F6L5G_gy-4 {
  --bs-gutter-y: 1.5rem;
}

._7F6L5G_g-5, ._7F6L5G_gx-5 {
  --bs-gutter-x: 3rem;
}

._7F6L5G_g-5, ._7F6L5G_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  ._7F6L5G_col-sm {
    flex: 1 0;
  }

  ._7F6L5G_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  ._7F6L5G_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-sm-auto {
    width: auto;
    flex: none;
  }

  ._7F6L5G_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  ._7F6L5G_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-sm-3 {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  ._7F6L5G_col-sm-6 {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  ._7F6L5G_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  ._7F6L5G_col-sm-9 {
    width: 75%;
    flex: none;
  }

  ._7F6L5G_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  ._7F6L5G_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  ._7F6L5G_col-sm-12 {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_offset-sm-0 {
    margin-left: 0;
  }

  ._7F6L5G_offset-sm-1 {
    margin-left: 8.33333%;
  }

  ._7F6L5G_offset-sm-2 {
    margin-left: 16.6667%;
  }

  ._7F6L5G_offset-sm-3 {
    margin-left: 25%;
  }

  ._7F6L5G_offset-sm-4 {
    margin-left: 33.3333%;
  }

  ._7F6L5G_offset-sm-5 {
    margin-left: 41.6667%;
  }

  ._7F6L5G_offset-sm-6 {
    margin-left: 50%;
  }

  ._7F6L5G_offset-sm-7 {
    margin-left: 58.3333%;
  }

  ._7F6L5G_offset-sm-8 {
    margin-left: 66.6667%;
  }

  ._7F6L5G_offset-sm-9 {
    margin-left: 75%;
  }

  ._7F6L5G_offset-sm-10 {
    margin-left: 83.3333%;
  }

  ._7F6L5G_offset-sm-11 {
    margin-left: 91.6667%;
  }

  ._7F6L5G_g-sm-0, ._7F6L5G_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  ._7F6L5G_g-sm-0, ._7F6L5G_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  ._7F6L5G_g-sm-1, ._7F6L5G_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  ._7F6L5G_g-sm-1, ._7F6L5G_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  ._7F6L5G_g-sm-2, ._7F6L5G_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  ._7F6L5G_g-sm-2, ._7F6L5G_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  ._7F6L5G_g-sm-3, ._7F6L5G_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  ._7F6L5G_g-sm-3, ._7F6L5G_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  ._7F6L5G_g-sm-4, ._7F6L5G_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._7F6L5G_g-sm-4, ._7F6L5G_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._7F6L5G_g-sm-5, ._7F6L5G_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  ._7F6L5G_g-sm-5, ._7F6L5G_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_col-md {
    flex: 1 0;
  }

  ._7F6L5G_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  ._7F6L5G_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  ._7F6L5G_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-md-auto {
    width: auto;
    flex: none;
  }

  ._7F6L5G_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  ._7F6L5G_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-md-3 {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  ._7F6L5G_col-md-6 {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  ._7F6L5G_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  ._7F6L5G_col-md-9 {
    width: 75%;
    flex: none;
  }

  ._7F6L5G_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  ._7F6L5G_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  ._7F6L5G_col-md-12 {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_offset-md-0 {
    margin-left: 0;
  }

  ._7F6L5G_offset-md-1 {
    margin-left: 8.33333%;
  }

  ._7F6L5G_offset-md-2 {
    margin-left: 16.6667%;
  }

  ._7F6L5G_offset-md-3 {
    margin-left: 25%;
  }

  ._7F6L5G_offset-md-4 {
    margin-left: 33.3333%;
  }

  ._7F6L5G_offset-md-5 {
    margin-left: 41.6667%;
  }

  ._7F6L5G_offset-md-6 {
    margin-left: 50%;
  }

  ._7F6L5G_offset-md-7 {
    margin-left: 58.3333%;
  }

  ._7F6L5G_offset-md-8 {
    margin-left: 66.6667%;
  }

  ._7F6L5G_offset-md-9 {
    margin-left: 75%;
  }

  ._7F6L5G_offset-md-10 {
    margin-left: 83.3333%;
  }

  ._7F6L5G_offset-md-11 {
    margin-left: 91.6667%;
  }

  ._7F6L5G_g-md-0, ._7F6L5G_gx-md-0 {
    --bs-gutter-x: 0;
  }

  ._7F6L5G_g-md-0, ._7F6L5G_gy-md-0 {
    --bs-gutter-y: 0;
  }

  ._7F6L5G_g-md-1, ._7F6L5G_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  ._7F6L5G_g-md-1, ._7F6L5G_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  ._7F6L5G_g-md-2, ._7F6L5G_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  ._7F6L5G_g-md-2, ._7F6L5G_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  ._7F6L5G_g-md-3, ._7F6L5G_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  ._7F6L5G_g-md-3, ._7F6L5G_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  ._7F6L5G_g-md-4, ._7F6L5G_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._7F6L5G_g-md-4, ._7F6L5G_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._7F6L5G_g-md-5, ._7F6L5G_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  ._7F6L5G_g-md-5, ._7F6L5G_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_col-lg {
    flex: 1 0;
  }

  ._7F6L5G_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  ._7F6L5G_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-lg-auto {
    width: auto;
    flex: none;
  }

  ._7F6L5G_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  ._7F6L5G_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-lg-3 {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  ._7F6L5G_col-lg-6 {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  ._7F6L5G_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  ._7F6L5G_col-lg-9 {
    width: 75%;
    flex: none;
  }

  ._7F6L5G_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  ._7F6L5G_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  ._7F6L5G_col-lg-12 {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_offset-lg-0 {
    margin-left: 0;
  }

  ._7F6L5G_offset-lg-1 {
    margin-left: 8.33333%;
  }

  ._7F6L5G_offset-lg-2 {
    margin-left: 16.6667%;
  }

  ._7F6L5G_offset-lg-3 {
    margin-left: 25%;
  }

  ._7F6L5G_offset-lg-4 {
    margin-left: 33.3333%;
  }

  ._7F6L5G_offset-lg-5 {
    margin-left: 41.6667%;
  }

  ._7F6L5G_offset-lg-6 {
    margin-left: 50%;
  }

  ._7F6L5G_offset-lg-7 {
    margin-left: 58.3333%;
  }

  ._7F6L5G_offset-lg-8 {
    margin-left: 66.6667%;
  }

  ._7F6L5G_offset-lg-9 {
    margin-left: 75%;
  }

  ._7F6L5G_offset-lg-10 {
    margin-left: 83.3333%;
  }

  ._7F6L5G_offset-lg-11 {
    margin-left: 91.6667%;
  }

  ._7F6L5G_g-lg-0, ._7F6L5G_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  ._7F6L5G_g-lg-0, ._7F6L5G_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  ._7F6L5G_g-lg-1, ._7F6L5G_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  ._7F6L5G_g-lg-1, ._7F6L5G_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  ._7F6L5G_g-lg-2, ._7F6L5G_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  ._7F6L5G_g-lg-2, ._7F6L5G_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  ._7F6L5G_g-lg-3, ._7F6L5G_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  ._7F6L5G_g-lg-3, ._7F6L5G_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  ._7F6L5G_g-lg-4, ._7F6L5G_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._7F6L5G_g-lg-4, ._7F6L5G_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._7F6L5G_g-lg-5, ._7F6L5G_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  ._7F6L5G_g-lg-5, ._7F6L5G_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_col-xl {
    flex: 1 0;
  }

  ._7F6L5G_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  ._7F6L5G_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-xl-auto {
    width: auto;
    flex: none;
  }

  ._7F6L5G_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._7F6L5G_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-xl-3 {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._7F6L5G_col-xl-6 {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._7F6L5G_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._7F6L5G_col-xl-9 {
    width: 75%;
    flex: none;
  }

  ._7F6L5G_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._7F6L5G_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._7F6L5G_col-xl-12 {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_offset-xl-0 {
    margin-left: 0;
  }

  ._7F6L5G_offset-xl-1 {
    margin-left: 8.33333%;
  }

  ._7F6L5G_offset-xl-2 {
    margin-left: 16.6667%;
  }

  ._7F6L5G_offset-xl-3 {
    margin-left: 25%;
  }

  ._7F6L5G_offset-xl-4 {
    margin-left: 33.3333%;
  }

  ._7F6L5G_offset-xl-5 {
    margin-left: 41.6667%;
  }

  ._7F6L5G_offset-xl-6 {
    margin-left: 50%;
  }

  ._7F6L5G_offset-xl-7 {
    margin-left: 58.3333%;
  }

  ._7F6L5G_offset-xl-8 {
    margin-left: 66.6667%;
  }

  ._7F6L5G_offset-xl-9 {
    margin-left: 75%;
  }

  ._7F6L5G_offset-xl-10 {
    margin-left: 83.3333%;
  }

  ._7F6L5G_offset-xl-11 {
    margin-left: 91.6667%;
  }

  ._7F6L5G_g-xl-0, ._7F6L5G_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  ._7F6L5G_g-xl-0, ._7F6L5G_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  ._7F6L5G_g-xl-1, ._7F6L5G_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  ._7F6L5G_g-xl-1, ._7F6L5G_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  ._7F6L5G_g-xl-2, ._7F6L5G_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  ._7F6L5G_g-xl-2, ._7F6L5G_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  ._7F6L5G_g-xl-3, ._7F6L5G_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  ._7F6L5G_g-xl-3, ._7F6L5G_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  ._7F6L5G_g-xl-4, ._7F6L5G_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._7F6L5G_g-xl-4, ._7F6L5G_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._7F6L5G_g-xl-5, ._7F6L5G_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  ._7F6L5G_g-xl-5, ._7F6L5G_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_col-xxl {
    flex: 1 0;
  }

  ._7F6L5G_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  ._7F6L5G_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-xxl-auto {
    width: auto;
    flex: none;
  }

  ._7F6L5G_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._7F6L5G_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._7F6L5G_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  ._7F6L5G_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._7F6L5G_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._7F6L5G_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  ._7F6L5G_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._7F6L5G_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._7F6L5G_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  ._7F6L5G_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._7F6L5G_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._7F6L5G_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  ._7F6L5G_offset-xxl-0 {
    margin-left: 0;
  }

  ._7F6L5G_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  ._7F6L5G_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  ._7F6L5G_offset-xxl-3 {
    margin-left: 25%;
  }

  ._7F6L5G_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  ._7F6L5G_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  ._7F6L5G_offset-xxl-6 {
    margin-left: 50%;
  }

  ._7F6L5G_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  ._7F6L5G_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  ._7F6L5G_offset-xxl-9 {
    margin-left: 75%;
  }

  ._7F6L5G_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  ._7F6L5G_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  ._7F6L5G_g-xxl-0, ._7F6L5G_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  ._7F6L5G_g-xxl-0, ._7F6L5G_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  ._7F6L5G_g-xxl-1, ._7F6L5G_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  ._7F6L5G_g-xxl-1, ._7F6L5G_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  ._7F6L5G_g-xxl-2, ._7F6L5G_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  ._7F6L5G_g-xxl-2, ._7F6L5G_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  ._7F6L5G_g-xxl-3, ._7F6L5G_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  ._7F6L5G_g-xxl-3, ._7F6L5G_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  ._7F6L5G_g-xxl-4, ._7F6L5G_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._7F6L5G_g-xxl-4, ._7F6L5G_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._7F6L5G_g-xxl-5, ._7F6L5G_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  ._7F6L5G_g-xxl-5, ._7F6L5G_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

._7F6L5G_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

._7F6L5G_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

._7F6L5G_table > tbody {
  vertical-align: inherit;
}

._7F6L5G_table > thead {
  vertical-align: bottom;
}

._7F6L5G_table-group-divider {
  border-top: 2px solid;
}

._7F6L5G_caption-top {
  caption-side: top;
}

._7F6L5G_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

._7F6L5G_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

._7F6L5G_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

._7F6L5G_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

._7F6L5G_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

._7F6L5G_table-striped > tbody > tr:nth-of-type(2n+1) > *, ._7F6L5G_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

._7F6L5G_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

._7F6L5G_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

._7F6L5G_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._7F6L5G_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  ._7F6L5G_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  ._7F6L5G_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  ._7F6L5G_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  ._7F6L5G_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  ._7F6L5G_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

._7F6L5G_form-label {
  margin-bottom: .5rem;
}

._7F6L5G_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

._7F6L5G_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

._7F6L5G_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

._7F6L5G_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

._7F6L5G_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-control {
    transition: none;
  }
}

._7F6L5G_form-control[type="file"] {
  overflow: hidden;
}

._7F6L5G_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._7F6L5G_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._7F6L5G_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

._7F6L5G_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

._7F6L5G_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

._7F6L5G_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-control::file-selector-button {
    transition: none;
  }
}

._7F6L5G_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

._7F6L5G_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

._7F6L5G_form-control-plaintext:focus {
  outline: 0;
}

._7F6L5G_form-control-plaintext._7F6L5G_form-control-sm, ._7F6L5G_form-control-plaintext._7F6L5G_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

._7F6L5G_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._7F6L5G_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

._7F6L5G_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._7F6L5G_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea._7F6L5G_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea._7F6L5G_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea._7F6L5G_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

._7F6L5G_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

._7F6L5G_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._7F6L5G_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

._7F6L5G_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

._7F6L5G_form-control-color._7F6L5G_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

._7F6L5G_form-control-color._7F6L5G_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

._7F6L5G_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-select {
    transition: none;
  }
}

._7F6L5G_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._7F6L5G_form-select[multiple], ._7F6L5G_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

._7F6L5G_form-select:disabled {
  background-color: #e9ecef;
}

._7F6L5G_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

._7F6L5G_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

._7F6L5G_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

._7F6L5G_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

._7F6L5G_form-check ._7F6L5G_form-check-input {
  float: left;
  margin-left: -1.5em;
}

._7F6L5G_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

._7F6L5G_form-check-reverse ._7F6L5G_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

._7F6L5G_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

._7F6L5G_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

._7F6L5G_form-check-input[type="radio"] {
  border-radius: 50%;
}

._7F6L5G_form-check-input:active {
  filter: brightness(90%);
}

._7F6L5G_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._7F6L5G_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

._7F6L5G_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

._7F6L5G_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

._7F6L5G_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

._7F6L5G_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

._7F6L5G_form-check-input[disabled] ~ ._7F6L5G_form-check-label, ._7F6L5G_form-check-input:disabled ~ ._7F6L5G_form-check-label {
  cursor: default;
  opacity: .5;
}

._7F6L5G_form-switch {
  padding-left: 2.5em;
}

._7F6L5G_form-switch ._7F6L5G_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-switch ._7F6L5G_form-check-input {
    transition: none;
  }
}

._7F6L5G_form-switch ._7F6L5G_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

._7F6L5G_form-switch ._7F6L5G_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

._7F6L5G_form-switch._7F6L5G_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

._7F6L5G_form-switch._7F6L5G_form-check-reverse ._7F6L5G_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

._7F6L5G_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

._7F6L5G_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

._7F6L5G_btn-check[disabled] + ._7F6L5G_btn, ._7F6L5G_btn-check:disabled + ._7F6L5G_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

._7F6L5G_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

._7F6L5G_form-range:focus {
  outline: 0;
}

._7F6L5G_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

._7F6L5G_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

._7F6L5G_form-range::-moz-focus-outer {
  border: 0;
}

._7F6L5G_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

._7F6L5G_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

._7F6L5G_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._7F6L5G_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-range::-moz-range-thumb {
    transition: none;
  }
}

._7F6L5G_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

._7F6L5G_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._7F6L5G_form-range:disabled {
  pointer-events: none;
}

._7F6L5G_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

._7F6L5G_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

._7F6L5G_form-floating {
  position: relative;
}

._7F6L5G_form-floating > ._7F6L5G_form-control, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext, ._7F6L5G_form-floating > ._7F6L5G_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

._7F6L5G_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_form-floating > label {
    transition: none;
  }
}

._7F6L5G_form-floating > ._7F6L5G_form-control, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext {
  padding: 1rem .75rem;
}

._7F6L5G_form-floating > ._7F6L5G_form-control::placeholder, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext::placeholder {
  color: #0000;
}

._7F6L5G_form-floating > ._7F6L5G_form-control:focus, ._7F6L5G_form-floating > ._7F6L5G_form-control:not(:placeholder-shown), ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext:focus, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._7F6L5G_form-floating > ._7F6L5G_form-control:-webkit-autofill, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._7F6L5G_form-floating > ._7F6L5G_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._7F6L5G_form-floating > ._7F6L5G_form-control:focus ~ label, ._7F6L5G_form-floating > ._7F6L5G_form-control:not(:placeholder-shown) ~ label, ._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext ~ label, ._7F6L5G_form-floating > ._7F6L5G_form-select ~ label, ._7F6L5G_form-floating > ._7F6L5G_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

._7F6L5G_form-floating > ._7F6L5G_form-control-plaintext ~ label {
  border-width: 1px 0;
}

._7F6L5G_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

._7F6L5G_input-group > ._7F6L5G_form-control, ._7F6L5G_input-group > ._7F6L5G_form-select, ._7F6L5G_input-group > ._7F6L5G_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

._7F6L5G_input-group > ._7F6L5G_form-control:focus, ._7F6L5G_input-group > ._7F6L5G_form-select:focus, ._7F6L5G_input-group > ._7F6L5G_form-floating:focus-within {
  z-index: 5;
}

._7F6L5G_input-group ._7F6L5G_btn {
  z-index: 2;
  position: relative;
}

._7F6L5G_input-group ._7F6L5G_btn:focus {
  z-index: 5;
}

._7F6L5G_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

._7F6L5G_input-group-lg > ._7F6L5G_form-control, ._7F6L5G_input-group-lg > ._7F6L5G_form-select, ._7F6L5G_input-group-lg > ._7F6L5G_input-group-text, ._7F6L5G_input-group-lg > ._7F6L5G_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._7F6L5G_input-group-sm > ._7F6L5G_form-control, ._7F6L5G_input-group-sm > ._7F6L5G_form-select, ._7F6L5G_input-group-sm > ._7F6L5G_input-group-text, ._7F6L5G_input-group-sm > ._7F6L5G_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._7F6L5G_input-group-lg > ._7F6L5G_form-select, ._7F6L5G_input-group-sm > ._7F6L5G_form-select {
  padding-right: 3rem;
}

._7F6L5G_input-group:not(._7F6L5G_has-validation) > :not(:last-child):not(._7F6L5G_dropdown-toggle):not(._7F6L5G_dropdown-menu):not(._7F6L5G_form-floating), ._7F6L5G_input-group:not(._7F6L5G_has-validation) > ._7F6L5G_dropdown-toggle:nth-last-child(n+3), ._7F6L5G_input-group:not(._7F6L5G_has-validation) > ._7F6L5G_form-floating:not(:last-child) > ._7F6L5G_form-control, ._7F6L5G_input-group:not(._7F6L5G_has-validation) > ._7F6L5G_form-floating:not(:last-child) > ._7F6L5G_form-select, ._7F6L5G_input-group._7F6L5G_has-validation > :nth-last-child(n+3):not(._7F6L5G_dropdown-toggle):not(._7F6L5G_dropdown-menu):not(._7F6L5G_form-floating), ._7F6L5G_input-group._7F6L5G_has-validation > ._7F6L5G_dropdown-toggle:nth-last-child(n+4), ._7F6L5G_input-group._7F6L5G_has-validation > ._7F6L5G_form-floating:nth-last-child(n+3) > ._7F6L5G_form-control, ._7F6L5G_input-group._7F6L5G_has-validation > ._7F6L5G_form-floating:nth-last-child(n+3) > ._7F6L5G_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._7F6L5G_input-group > :not(:first-child):not(._7F6L5G_dropdown-menu):not(._7F6L5G_valid-tooltip):not(._7F6L5G_valid-feedback):not(._7F6L5G_invalid-tooltip):not(._7F6L5G_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

._7F6L5G_input-group > ._7F6L5G_form-floating:not(:first-child) > ._7F6L5G_form-control, ._7F6L5G_input-group > ._7F6L5G_form-floating:not(:first-child) > ._7F6L5G_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._7F6L5G_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._7F6L5G_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._7F6L5G_was-validated :valid ~ ._7F6L5G_valid-feedback, ._7F6L5G_was-validated :valid ~ ._7F6L5G_valid-tooltip, ._7F6L5G_is-valid ~ ._7F6L5G_valid-feedback, ._7F6L5G_is-valid ~ ._7F6L5G_valid-tooltip {
  display: block;
}

._7F6L5G_was-validated ._7F6L5G_form-control:valid, ._7F6L5G_form-control._7F6L5G_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-control:valid:focus, ._7F6L5G_form-control._7F6L5G_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._7F6L5G_was-validated textarea._7F6L5G_form-control:valid, textarea._7F6L5G_form-control._7F6L5G_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-select:valid, ._7F6L5G_form-select._7F6L5G_is-valid {
  border-color: #198754;
}

._7F6L5G_was-validated ._7F6L5G_form-select:valid:not([multiple]):not([size]), ._7F6L5G_was-validated ._7F6L5G_form-select:valid:not([multiple])[size="1"], ._7F6L5G_form-select._7F6L5G_is-valid:not([multiple]):not([size]), ._7F6L5G_form-select._7F6L5G_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._7F6L5G_was-validated ._7F6L5G_form-select:valid:focus, ._7F6L5G_form-select._7F6L5G_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._7F6L5G_was-validated ._7F6L5G_form-control-color:valid, ._7F6L5G_form-control-color._7F6L5G_is-valid {
  width: calc(1.5em + 3.75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:valid, ._7F6L5G_form-check-input._7F6L5G_is-valid {
  border-color: #198754;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:valid:checked, ._7F6L5G_form-check-input._7F6L5G_is-valid:checked {
  background-color: #198754;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:valid:focus, ._7F6L5G_form-check-input._7F6L5G_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:valid ~ ._7F6L5G_form-check-label, ._7F6L5G_form-check-input._7F6L5G_is-valid ~ ._7F6L5G_form-check-label {
  color: #198754;
}

._7F6L5G_form-check-inline ._7F6L5G_form-check-input ~ ._7F6L5G_valid-feedback {
  margin-left: .5em;
}

._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-control:not(:focus):valid, ._7F6L5G_input-group > ._7F6L5G_form-control:not(:focus)._7F6L5G_is-valid, ._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-select:not(:focus):valid, ._7F6L5G_input-group > ._7F6L5G_form-select:not(:focus)._7F6L5G_is-valid, ._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-floating:not(:focus-within):valid, ._7F6L5G_input-group > ._7F6L5G_form-floating:not(:focus-within)._7F6L5G_is-valid {
  z-index: 3;
}

._7F6L5G_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._7F6L5G_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._7F6L5G_was-validated :invalid ~ ._7F6L5G_invalid-feedback, ._7F6L5G_was-validated :invalid ~ ._7F6L5G_invalid-tooltip, ._7F6L5G_is-invalid ~ ._7F6L5G_invalid-feedback, ._7F6L5G_is-invalid ~ ._7F6L5G_invalid-tooltip {
  display: block;
}

._7F6L5G_was-validated ._7F6L5G_form-control:invalid, ._7F6L5G_form-control._7F6L5G_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-control:invalid:focus, ._7F6L5G_form-control._7F6L5G_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._7F6L5G_was-validated textarea._7F6L5G_form-control:invalid, textarea._7F6L5G_form-control._7F6L5G_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-select:invalid, ._7F6L5G_form-select._7F6L5G_is-invalid {
  border-color: #dc3545;
}

._7F6L5G_was-validated ._7F6L5G_form-select:invalid:not([multiple]):not([size]), ._7F6L5G_was-validated ._7F6L5G_form-select:invalid:not([multiple])[size="1"], ._7F6L5G_form-select._7F6L5G_is-invalid:not([multiple]):not([size]), ._7F6L5G_form-select._7F6L5G_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._7F6L5G_was-validated ._7F6L5G_form-select:invalid:focus, ._7F6L5G_form-select._7F6L5G_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._7F6L5G_was-validated ._7F6L5G_form-control-color:invalid, ._7F6L5G_form-control-color._7F6L5G_is-invalid {
  width: calc(1.5em + 3.75rem);
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:invalid, ._7F6L5G_form-check-input._7F6L5G_is-invalid {
  border-color: #dc3545;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:invalid:checked, ._7F6L5G_form-check-input._7F6L5G_is-invalid:checked {
  background-color: #dc3545;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:invalid:focus, ._7F6L5G_form-check-input._7F6L5G_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

._7F6L5G_was-validated ._7F6L5G_form-check-input:invalid ~ ._7F6L5G_form-check-label, ._7F6L5G_form-check-input._7F6L5G_is-invalid ~ ._7F6L5G_form-check-label {
  color: #dc3545;
}

._7F6L5G_form-check-inline ._7F6L5G_form-check-input ~ ._7F6L5G_invalid-feedback {
  margin-left: .5em;
}

._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-control:not(:focus):invalid, ._7F6L5G_input-group > ._7F6L5G_form-control:not(:focus)._7F6L5G_is-invalid, ._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-select:not(:focus):invalid, ._7F6L5G_input-group > ._7F6L5G_form-select:not(:focus)._7F6L5G_is-invalid, ._7F6L5G_was-validated ._7F6L5G_input-group > ._7F6L5G_form-floating:not(:focus-within):invalid, ._7F6L5G_input-group > ._7F6L5G_form-floating:not(:focus-within)._7F6L5G_is-invalid {
  z-index: 4;
}

._7F6L5G_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_btn {
    transition: none;
  }
}

._7F6L5G_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

._7F6L5G_btn-check + ._7F6L5G_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

._7F6L5G_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._7F6L5G_btn-check:focus-visible + ._7F6L5G_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._7F6L5G_btn-check:checked + ._7F6L5G_btn, :not(._7F6L5G_btn-check) + ._7F6L5G_btn:active, ._7F6L5G_btn:first-child:active, ._7F6L5G_btn._7F6L5G_active, ._7F6L5G_btn._7F6L5G_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

._7F6L5G_btn-check:checked + ._7F6L5G_btn:focus-visible, :not(._7F6L5G_btn-check) + ._7F6L5G_btn:active:focus-visible, ._7F6L5G_btn:first-child:active:focus-visible, ._7F6L5G_btn._7F6L5G_active:focus-visible, ._7F6L5G_btn._7F6L5G_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

._7F6L5G_btn:disabled, ._7F6L5G_btn._7F6L5G_disabled, fieldset:disabled ._7F6L5G_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

._7F6L5G_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

._7F6L5G_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

._7F6L5G_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

._7F6L5G_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

._7F6L5G_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

._7F6L5G_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

._7F6L5G_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

._7F6L5G_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

._7F6L5G_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

._7F6L5G_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

._7F6L5G_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

._7F6L5G_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

._7F6L5G_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

._7F6L5G_btn-lg, ._7F6L5G_btn-group-lg > ._7F6L5G_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

._7F6L5G_btn-sm, ._7F6L5G_btn-group-sm > ._7F6L5G_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

._7F6L5G_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_fade {
    transition: none;
  }
}

._7F6L5G_fade:not(._7F6L5G_show) {
  opacity: 0;
}

._7F6L5G_collapse:not(._7F6L5G_show) {
  display: none;
}

._7F6L5G_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_collapsing {
    transition: none;
  }
}

._7F6L5G_collapsing._7F6L5G_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_collapsing._7F6L5G_collapse-horizontal {
    transition: none;
  }
}

._7F6L5G_dropup, ._7F6L5G_dropend, ._7F6L5G_dropdown, ._7F6L5G_dropstart, ._7F6L5G_dropup-center, ._7F6L5G_dropdown-center {
  position: relative;
}

._7F6L5G_dropdown-toggle {
  white-space: nowrap;
}

._7F6L5G_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

._7F6L5G_dropdown-toggle:empty:after {
  margin-left: 0;
}

._7F6L5G_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

._7F6L5G_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

._7F6L5G_dropdown-menu-start {
  --bs-position: start;
}

._7F6L5G_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

._7F6L5G_dropdown-menu-end {
  --bs-position: end;
}

._7F6L5G_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  ._7F6L5G_dropdown-menu-sm-start {
    --bs-position: start;
  }

  ._7F6L5G_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._7F6L5G_dropdown-menu-sm-end {
    --bs-position: end;
  }

  ._7F6L5G_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_dropdown-menu-md-start {
    --bs-position: start;
  }

  ._7F6L5G_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._7F6L5G_dropdown-menu-md-end {
    --bs-position: end;
  }

  ._7F6L5G_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_dropdown-menu-lg-start {
    --bs-position: start;
  }

  ._7F6L5G_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._7F6L5G_dropdown-menu-lg-end {
    --bs-position: end;
  }

  ._7F6L5G_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_dropdown-menu-xl-start {
    --bs-position: start;
  }

  ._7F6L5G_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._7F6L5G_dropdown-menu-xl-end {
    --bs-position: end;
  }

  ._7F6L5G_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  ._7F6L5G_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._7F6L5G_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  ._7F6L5G_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

._7F6L5G_dropup ._7F6L5G_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

._7F6L5G_dropup ._7F6L5G_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

._7F6L5G_dropup ._7F6L5G_dropdown-toggle:empty:after {
  margin-left: 0;
}

._7F6L5G_dropend ._7F6L5G_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

._7F6L5G_dropend ._7F6L5G_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

._7F6L5G_dropend ._7F6L5G_dropdown-toggle:empty:after {
  margin-left: 0;
}

._7F6L5G_dropend ._7F6L5G_dropdown-toggle:after {
  vertical-align: 0;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-toggle:empty:after {
  margin-left: 0;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-toggle:before {
  vertical-align: 0;
}

._7F6L5G_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

._7F6L5G_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

._7F6L5G_dropdown-item:hover, ._7F6L5G_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

._7F6L5G_dropdown-item._7F6L5G_active, ._7F6L5G_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

._7F6L5G_dropdown-item._7F6L5G_disabled, ._7F6L5G_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

._7F6L5G_dropdown-menu._7F6L5G_show {
  display: block;
}

._7F6L5G_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

._7F6L5G_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

._7F6L5G_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

._7F6L5G_btn-group, ._7F6L5G_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

._7F6L5G_btn-group > ._7F6L5G_btn, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn {
  flex: auto;
  position: relative;
}

._7F6L5G_btn-group > ._7F6L5G_btn-check:checked + ._7F6L5G_btn, ._7F6L5G_btn-group > ._7F6L5G_btn-check:focus + ._7F6L5G_btn, ._7F6L5G_btn-group > ._7F6L5G_btn:hover, ._7F6L5G_btn-group > ._7F6L5G_btn:focus, ._7F6L5G_btn-group > ._7F6L5G_btn:active, ._7F6L5G_btn-group > ._7F6L5G_btn._7F6L5G_active, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-check:checked + ._7F6L5G_btn, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-check:focus + ._7F6L5G_btn, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn:hover, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn:focus, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn:active, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn._7F6L5G_active {
  z-index: 1;
}

._7F6L5G_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

._7F6L5G_btn-toolbar ._7F6L5G_input-group {
  width: auto;
}

._7F6L5G_btn-group {
  border-radius: .375rem;
}

._7F6L5G_btn-group > :not(._7F6L5G_btn-check:first-child) + ._7F6L5G_btn, ._7F6L5G_btn-group > ._7F6L5G_btn-group:not(:first-child) {
  margin-left: -1px;
}

._7F6L5G_btn-group > ._7F6L5G_btn:not(:last-child):not(._7F6L5G_dropdown-toggle), ._7F6L5G_btn-group > ._7F6L5G_btn._7F6L5G_dropdown-toggle-split:first-child, ._7F6L5G_btn-group > ._7F6L5G_btn-group:not(:last-child) > ._7F6L5G_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._7F6L5G_btn-group > ._7F6L5G_btn:nth-child(n+3), ._7F6L5G_btn-group > :not(._7F6L5G_btn-check) + ._7F6L5G_btn, ._7F6L5G_btn-group > ._7F6L5G_btn-group:not(:first-child) > ._7F6L5G_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._7F6L5G_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

._7F6L5G_dropdown-toggle-split:after, ._7F6L5G_dropup ._7F6L5G_dropdown-toggle-split:after, ._7F6L5G_dropend ._7F6L5G_dropdown-toggle-split:after {
  margin-left: 0;
}

._7F6L5G_dropstart ._7F6L5G_dropdown-toggle-split:before {
  margin-right: 0;
}

._7F6L5G_btn-sm + ._7F6L5G_dropdown-toggle-split, ._7F6L5G_btn-group-sm > ._7F6L5G_btn + ._7F6L5G_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

._7F6L5G_btn-lg + ._7F6L5G_dropdown-toggle-split, ._7F6L5G_btn-group-lg > ._7F6L5G_btn + ._7F6L5G_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

._7F6L5G_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

._7F6L5G_btn-group-vertical > ._7F6L5G_btn, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-group {
  width: 100%;
}

._7F6L5G_btn-group-vertical > ._7F6L5G_btn:not(:first-child), ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-group:not(:first-child) {
  margin-top: -1px;
}

._7F6L5G_btn-group-vertical > ._7F6L5G_btn:not(:last-child):not(._7F6L5G_dropdown-toggle), ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-group:not(:last-child) > ._7F6L5G_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

._7F6L5G_btn-group-vertical > ._7F6L5G_btn ~ ._7F6L5G_btn, ._7F6L5G_btn-group-vertical > ._7F6L5G_btn-group:not(:first-child) > ._7F6L5G_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._7F6L5G_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._7F6L5G_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_nav-link {
    transition: none;
  }
}

._7F6L5G_nav-link:hover, ._7F6L5G_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

._7F6L5G_nav-link._7F6L5G_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

._7F6L5G_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

._7F6L5G_nav-tabs ._7F6L5G_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

._7F6L5G_nav-tabs ._7F6L5G_nav-link:hover, ._7F6L5G_nav-tabs ._7F6L5G_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

._7F6L5G_nav-tabs ._7F6L5G_nav-link._7F6L5G_disabled, ._7F6L5G_nav-tabs ._7F6L5G_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._7F6L5G_nav-tabs ._7F6L5G_nav-link._7F6L5G_active, ._7F6L5G_nav-tabs ._7F6L5G_nav-item._7F6L5G_show ._7F6L5G_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

._7F6L5G_nav-tabs ._7F6L5G_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._7F6L5G_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

._7F6L5G_nav-pills ._7F6L5G_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

._7F6L5G_nav-pills ._7F6L5G_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._7F6L5G_nav-pills ._7F6L5G_nav-link._7F6L5G_active, ._7F6L5G_nav-pills ._7F6L5G_show > ._7F6L5G_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

._7F6L5G_nav-fill > ._7F6L5G_nav-link, ._7F6L5G_nav-fill ._7F6L5G_nav-item {
  text-align: center;
  flex: auto;
}

._7F6L5G_nav-justified > ._7F6L5G_nav-link, ._7F6L5G_nav-justified ._7F6L5G_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

._7F6L5G_nav-fill ._7F6L5G_nav-item ._7F6L5G_nav-link, ._7F6L5G_nav-justified ._7F6L5G_nav-item ._7F6L5G_nav-link {
  width: 100%;
}

._7F6L5G_tab-content > ._7F6L5G_tab-pane {
  display: none;
}

._7F6L5G_tab-content > ._7F6L5G_active {
  display: block;
}

._7F6L5G_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

._7F6L5G_navbar > ._7F6L5G_container, ._7F6L5G_navbar > ._7F6L5G_container-fluid, ._7F6L5G_navbar > ._7F6L5G_container-sm, ._7F6L5G_navbar > ._7F6L5G_container-md, ._7F6L5G_navbar > ._7F6L5G_container-lg, ._7F6L5G_navbar > ._7F6L5G_container-xl, ._7F6L5G_navbar > ._7F6L5G_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._7F6L5G_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

._7F6L5G_navbar-brand:hover, ._7F6L5G_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

._7F6L5G_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._7F6L5G_navbar-nav ._7F6L5G_show > ._7F6L5G_nav-link, ._7F6L5G_navbar-nav ._7F6L5G_nav-link._7F6L5G_active {
  color: var(--bs-navbar-active-color);
}

._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
  position: static;
}

._7F6L5G_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

._7F6L5G_navbar-text a, ._7F6L5G_navbar-text a:hover, ._7F6L5G_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

._7F6L5G_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

._7F6L5G_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_navbar-toggler {
    transition: none;
  }
}

._7F6L5G_navbar-toggler:hover {
  text-decoration: none;
}

._7F6L5G_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

._7F6L5G_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

._7F6L5G_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  ._7F6L5G_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-nav {
    flex-direction: row;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
    position: absolute;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-nav-scroll {
    overflow: visible;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_navbar-toggler {
    display: none;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_navbar-expand-sm ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-nav {
    flex-direction: row;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
    position: absolute;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-nav-scroll {
    overflow: visible;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_navbar-toggler {
    display: none;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_navbar-expand-md ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-nav {
    flex-direction: row;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
    position: absolute;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-nav-scroll {
    overflow: visible;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_navbar-toggler {
    display: none;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_navbar-expand-lg ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-nav {
    flex-direction: row;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
    position: absolute;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-nav-scroll {
    overflow: visible;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_navbar-toggler {
    display: none;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_navbar-expand-xl ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-nav {
    flex-direction: row;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
    position: absolute;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-nav-scroll {
    overflow: visible;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_navbar-toggler {
    display: none;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_navbar-expand-xxl ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

._7F6L5G_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-nav {
  flex-direction: row;
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-nav ._7F6L5G_dropdown-menu {
  position: absolute;
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-nav ._7F6L5G_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-nav-scroll {
  overflow: visible;
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

._7F6L5G_navbar-expand ._7F6L5G_navbar-toggler {
  display: none;
}

._7F6L5G_navbar-expand ._7F6L5G_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

._7F6L5G_navbar-expand ._7F6L5G_offcanvas ._7F6L5G_offcanvas-header {
  display: none;
}

._7F6L5G_navbar-expand ._7F6L5G_offcanvas ._7F6L5G_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

._7F6L5G_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

._7F6L5G_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

._7F6L5G_card > hr {
  margin-left: 0;
  margin-right: 0;
}

._7F6L5G_card > ._7F6L5G_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

._7F6L5G_card > ._7F6L5G_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

._7F6L5G_card > ._7F6L5G_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

._7F6L5G_card > ._7F6L5G_card-header + ._7F6L5G_list-group, ._7F6L5G_card > ._7F6L5G_list-group + ._7F6L5G_card-footer {
  border-top: 0;
}

._7F6L5G_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

._7F6L5G_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

._7F6L5G_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

._7F6L5G_card-text:last-child {
  margin-bottom: 0;
}

._7F6L5G_card-link + ._7F6L5G_card-link {
  margin-left: var(--bs-card-spacer-x);
}

._7F6L5G_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

._7F6L5G_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

._7F6L5G_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

._7F6L5G_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

._7F6L5G_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

._7F6L5G_card-header-tabs ._7F6L5G_nav-link._7F6L5G_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

._7F6L5G_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

._7F6L5G_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

._7F6L5G_card-img, ._7F6L5G_card-img-top, ._7F6L5G_card-img-bottom {
  width: 100%;
}

._7F6L5G_card-img, ._7F6L5G_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

._7F6L5G_card-img, ._7F6L5G_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

._7F6L5G_card-group > ._7F6L5G_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  ._7F6L5G_card-group {
    flex-flow: wrap;
    display: flex;
  }

  ._7F6L5G_card-group > ._7F6L5G_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card + ._7F6L5G_card {
    border-left: 0;
    margin-left: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:last-child) ._7F6L5G_card-img-top, ._7F6L5G_card-group > ._7F6L5G_card:not(:last-child) ._7F6L5G_card-header {
    border-top-right-radius: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:last-child) ._7F6L5G_card-img-bottom, ._7F6L5G_card-group > ._7F6L5G_card:not(:last-child) ._7F6L5G_card-footer {
    border-bottom-right-radius: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:first-child) ._7F6L5G_card-img-top, ._7F6L5G_card-group > ._7F6L5G_card:not(:first-child) ._7F6L5G_card-header {
    border-top-left-radius: 0;
  }

  ._7F6L5G_card-group > ._7F6L5G_card:not(:first-child) ._7F6L5G_card-img-bottom, ._7F6L5G_card-group > ._7F6L5G_card:not(:first-child) ._7F6L5G_card-footer {
    border-bottom-left-radius: 0;
  }
}

._7F6L5G_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

._7F6L5G_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_accordion-button {
    transition: none;
  }
}

._7F6L5G_accordion-button:not(._7F6L5G_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

._7F6L5G_accordion-button:not(._7F6L5G_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

._7F6L5G_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_accordion-button:after {
    transition: none;
  }
}

._7F6L5G_accordion-button:hover {
  z-index: 2;
}

._7F6L5G_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

._7F6L5G_accordion-header {
  margin-bottom: 0;
}

._7F6L5G_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

._7F6L5G_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

._7F6L5G_accordion-item:first-of-type ._7F6L5G_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

._7F6L5G_accordion-item:not(:first-of-type) {
  border-top: 0;
}

._7F6L5G_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._7F6L5G_accordion-item:last-of-type ._7F6L5G_accordion-button._7F6L5G_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

._7F6L5G_accordion-item:last-of-type ._7F6L5G_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._7F6L5G_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

._7F6L5G_accordion-flush ._7F6L5G_accordion-collapse {
  border-width: 0;
}

._7F6L5G_accordion-flush ._7F6L5G_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

._7F6L5G_accordion-flush ._7F6L5G_accordion-item:first-child {
  border-top: 0;
}

._7F6L5G_accordion-flush ._7F6L5G_accordion-item:last-child {
  border-bottom: 0;
}

._7F6L5G_accordion-flush ._7F6L5G_accordion-item ._7F6L5G_accordion-button, ._7F6L5G_accordion-flush ._7F6L5G_accordion-item ._7F6L5G_accordion-button._7F6L5G_collapsed {
  border-radius: 0;
}

._7F6L5G_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

._7F6L5G_breadcrumb-item + ._7F6L5G_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

._7F6L5G_breadcrumb-item + ._7F6L5G_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

._7F6L5G_breadcrumb-item._7F6L5G_active {
  color: var(--bs-breadcrumb-item-active-color);
}

._7F6L5G_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._7F6L5G_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_page-link {
    transition: none;
  }
}

._7F6L5G_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

._7F6L5G_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

._7F6L5G_page-link._7F6L5G_active, ._7F6L5G_active > ._7F6L5G_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

._7F6L5G_page-link._7F6L5G_disabled, ._7F6L5G_disabled > ._7F6L5G_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

._7F6L5G_page-item:not(:first-child) ._7F6L5G_page-link {
  margin-left: -1px;
}

._7F6L5G_page-item:first-child ._7F6L5G_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

._7F6L5G_page-item:last-child ._7F6L5G_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

._7F6L5G_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

._7F6L5G_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

._7F6L5G_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

._7F6L5G_badge:empty {
  display: none;
}

._7F6L5G_btn ._7F6L5G_badge {
  position: relative;
  top: -1px;
}

._7F6L5G_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

._7F6L5G_alert-heading {
  color: inherit;
}

._7F6L5G_alert-link {
  font-weight: 700;
}

._7F6L5G_alert-dismissible {
  padding-right: 3rem;
}

._7F6L5G_alert-dismissible ._7F6L5G_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

._7F6L5G_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

._7F6L5G_alert-primary ._7F6L5G_alert-link {
  color: #25224b;
}

._7F6L5G_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

._7F6L5G_alert-secondary ._7F6L5G_alert-link {
  color: #34383c;
}

._7F6L5G_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

._7F6L5G_alert-success ._7F6L5G_alert-link {
  color: #0c4128;
}

._7F6L5G_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

._7F6L5G_alert-info ._7F6L5G_alert-link {
  color: #04414d;
}

._7F6L5G_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

._7F6L5G_alert-warning ._7F6L5G_alert-link {
  color: #523e02;
}

._7F6L5G_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

._7F6L5G_alert-danger ._7F6L5G_alert-link {
  color: #6a1a21;
}

._7F6L5G_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

._7F6L5G_alert-light ._7F6L5G_alert-link {
  color: #4f5050;
}

._7F6L5G_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

._7F6L5G_alert-dark ._7F6L5G_alert-link {
  color: #101214;
}

@keyframes _7F6L5G_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

._7F6L5G_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

._7F6L5G_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_progress-bar {
    transition: none;
  }
}

._7F6L5G_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

._7F6L5G_progress-bar-animated {
  animation: 1s linear infinite _7F6L5G_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_progress-bar-animated {
    animation: none;
  }
}

._7F6L5G_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

._7F6L5G_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

._7F6L5G_list-group-numbered > ._7F6L5G_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

._7F6L5G_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

._7F6L5G_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

._7F6L5G_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

._7F6L5G_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

._7F6L5G_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

._7F6L5G_list-group-item._7F6L5G_disabled, ._7F6L5G_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

._7F6L5G_list-group-item._7F6L5G_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
  border-top-width: 0;
}

._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

._7F6L5G_list-group-horizontal {
  flex-direction: row;
}

._7F6L5G_list-group-horizontal > ._7F6L5G_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

._7F6L5G_list-group-horizontal > ._7F6L5G_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

._7F6L5G_list-group-horizontal > ._7F6L5G_list-group-item._7F6L5G_active {
  margin-top: 0;
}

._7F6L5G_list-group-horizontal > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

._7F6L5G_list-group-horizontal > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  ._7F6L5G_list-group-horizontal-sm {
    flex-direction: row;
  }

  ._7F6L5G_list-group-horizontal-sm > ._7F6L5G_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-sm > ._7F6L5G_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-sm > ._7F6L5G_list-group-item._7F6L5G_active {
    margin-top: 0;
  }

  ._7F6L5G_list-group-horizontal-sm > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._7F6L5G_list-group-horizontal-sm > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  ._7F6L5G_list-group-horizontal-md {
    flex-direction: row;
  }

  ._7F6L5G_list-group-horizontal-md > ._7F6L5G_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-md > ._7F6L5G_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-md > ._7F6L5G_list-group-item._7F6L5G_active {
    margin-top: 0;
  }

  ._7F6L5G_list-group-horizontal-md > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._7F6L5G_list-group-horizontal-md > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  ._7F6L5G_list-group-horizontal-lg {
    flex-direction: row;
  }

  ._7F6L5G_list-group-horizontal-lg > ._7F6L5G_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-lg > ._7F6L5G_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-lg > ._7F6L5G_list-group-item._7F6L5G_active {
    margin-top: 0;
  }

  ._7F6L5G_list-group-horizontal-lg > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._7F6L5G_list-group-horizontal-lg > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_list-group-horizontal-xl {
    flex-direction: row;
  }

  ._7F6L5G_list-group-horizontal-xl > ._7F6L5G_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-xl > ._7F6L5G_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-xl > ._7F6L5G_list-group-item._7F6L5G_active {
    margin-top: 0;
  }

  ._7F6L5G_list-group-horizontal-xl > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._7F6L5G_list-group-horizontal-xl > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_list-group-horizontal-xxl {
    flex-direction: row;
  }

  ._7F6L5G_list-group-horizontal-xxl > ._7F6L5G_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-xxl > ._7F6L5G_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._7F6L5G_list-group-horizontal-xxl > ._7F6L5G_list-group-item._7F6L5G_active {
    margin-top: 0;
  }

  ._7F6L5G_list-group-horizontal-xxl > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._7F6L5G_list-group-horizontal-xxl > ._7F6L5G_list-group-item + ._7F6L5G_list-group-item._7F6L5G_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

._7F6L5G_list-group-flush {
  border-radius: 0;
}

._7F6L5G_list-group-flush > ._7F6L5G_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

._7F6L5G_list-group-flush > ._7F6L5G_list-group-item:last-child {
  border-bottom-width: 0;
}

._7F6L5G_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

._7F6L5G_list-group-item-primary._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-primary._7F6L5G_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

._7F6L5G_list-group-item-primary._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

._7F6L5G_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

._7F6L5G_list-group-item-secondary._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-secondary._7F6L5G_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

._7F6L5G_list-group-item-secondary._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

._7F6L5G_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

._7F6L5G_list-group-item-success._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-success._7F6L5G_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

._7F6L5G_list-group-item-success._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

._7F6L5G_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

._7F6L5G_list-group-item-info._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-info._7F6L5G_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

._7F6L5G_list-group-item-info._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

._7F6L5G_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

._7F6L5G_list-group-item-warning._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-warning._7F6L5G_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

._7F6L5G_list-group-item-warning._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

._7F6L5G_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

._7F6L5G_list-group-item-danger._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-danger._7F6L5G_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

._7F6L5G_list-group-item-danger._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

._7F6L5G_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

._7F6L5G_list-group-item-light._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-light._7F6L5G_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

._7F6L5G_list-group-item-light._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

._7F6L5G_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

._7F6L5G_list-group-item-dark._7F6L5G_list-group-item-action:hover, ._7F6L5G_list-group-item-dark._7F6L5G_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

._7F6L5G_list-group-item-dark._7F6L5G_list-group-item-action._7F6L5G_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

._7F6L5G_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

._7F6L5G_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

._7F6L5G_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

._7F6L5G_btn-close:disabled, ._7F6L5G_btn-close._7F6L5G_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

._7F6L5G_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

._7F6L5G_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

._7F6L5G_toast._7F6L5G_showing {
  opacity: 0;
}

._7F6L5G_toast:not(._7F6L5G_show) {
  display: none;
}

._7F6L5G_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

._7F6L5G_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

._7F6L5G_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

._7F6L5G_toast-header ._7F6L5G_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

._7F6L5G_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

._7F6L5G_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

._7F6L5G_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

._7F6L5G_modal._7F6L5G_fade ._7F6L5G_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_modal._7F6L5G_fade ._7F6L5G_modal-dialog {
    transition: none;
  }
}

._7F6L5G_modal._7F6L5G_show ._7F6L5G_modal-dialog {
  transform: none;
}

._7F6L5G_modal._7F6L5G_modal-static ._7F6L5G_modal-dialog {
  transform: scale(1.02);
}

._7F6L5G_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

._7F6L5G_modal-dialog-scrollable ._7F6L5G_modal-content {
  max-height: 100%;
  overflow: hidden;
}

._7F6L5G_modal-dialog-scrollable ._7F6L5G_modal-body {
  overflow-y: auto;
}

._7F6L5G_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

._7F6L5G_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

._7F6L5G_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

._7F6L5G_modal-backdrop._7F6L5G_fade {
  opacity: 0;
}

._7F6L5G_modal-backdrop._7F6L5G_show {
  opacity: var(--bs-backdrop-opacity);
}

._7F6L5G_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._7F6L5G_modal-header ._7F6L5G_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

._7F6L5G_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

._7F6L5G_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

._7F6L5G_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._7F6L5G_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  ._7F6L5G_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  ._7F6L5G_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  ._7F6L5G_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_modal-lg, ._7F6L5G_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_modal-xl {
    --bs-modal-width: 1140px;
  }
}

._7F6L5G_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

._7F6L5G_modal-fullscreen ._7F6L5G_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

._7F6L5G_modal-fullscreen ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen ._7F6L5G_modal-footer {
  border-radius: 0;
}

._7F6L5G_modal-fullscreen ._7F6L5G_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  ._7F6L5G_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._7F6L5G_modal-fullscreen-sm-down ._7F6L5G_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-sm-down ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen-sm-down ._7F6L5G_modal-footer {
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-sm-down ._7F6L5G_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  ._7F6L5G_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._7F6L5G_modal-fullscreen-md-down ._7F6L5G_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-md-down ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen-md-down ._7F6L5G_modal-footer {
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-md-down ._7F6L5G_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  ._7F6L5G_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._7F6L5G_modal-fullscreen-lg-down ._7F6L5G_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-lg-down ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen-lg-down ._7F6L5G_modal-footer {
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-lg-down ._7F6L5G_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  ._7F6L5G_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._7F6L5G_modal-fullscreen-xl-down ._7F6L5G_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-xl-down ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen-xl-down ._7F6L5G_modal-footer {
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-xl-down ._7F6L5G_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  ._7F6L5G_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._7F6L5G_modal-fullscreen-xxl-down ._7F6L5G_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-xxl-down ._7F6L5G_modal-header, ._7F6L5G_modal-fullscreen-xxl-down ._7F6L5G_modal-footer {
    border-radius: 0;
  }

  ._7F6L5G_modal-fullscreen-xxl-down ._7F6L5G_modal-body {
    overflow-y: auto;
  }
}

._7F6L5G_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

._7F6L5G_tooltip._7F6L5G_show {
  opacity: var(--bs-tooltip-opacity);
}

._7F6L5G_tooltip ._7F6L5G_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

._7F6L5G_tooltip ._7F6L5G_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

._7F6L5G_bs-tooltip-top ._7F6L5G_tooltip-arrow, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="top"] ._7F6L5G_tooltip-arrow {
  bottom: 0;
}

._7F6L5G_bs-tooltip-top ._7F6L5G_tooltip-arrow:before, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="top"] ._7F6L5G_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

._7F6L5G_bs-tooltip-end ._7F6L5G_tooltip-arrow, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="right"] ._7F6L5G_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

._7F6L5G_bs-tooltip-end ._7F6L5G_tooltip-arrow:before, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="right"] ._7F6L5G_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

._7F6L5G_bs-tooltip-bottom ._7F6L5G_tooltip-arrow, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="bottom"] ._7F6L5G_tooltip-arrow {
  top: 0;
}

._7F6L5G_bs-tooltip-bottom ._7F6L5G_tooltip-arrow:before, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="bottom"] ._7F6L5G_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

._7F6L5G_bs-tooltip-start ._7F6L5G_tooltip-arrow, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="left"] ._7F6L5G_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

._7F6L5G_bs-tooltip-start ._7F6L5G_tooltip-arrow:before, ._7F6L5G_bs-tooltip-auto[data-popper-placement^="left"] ._7F6L5G_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

._7F6L5G_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

._7F6L5G_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

._7F6L5G_popover ._7F6L5G_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

._7F6L5G_popover ._7F6L5G_popover-arrow:before, ._7F6L5G_popover ._7F6L5G_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

._7F6L5G_bs-popover-top > ._7F6L5G_popover-arrow, ._7F6L5G_bs-popover-auto[data-popper-placement^="top"] > ._7F6L5G_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._7F6L5G_bs-popover-top > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="top"] > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-top > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="top"] > ._7F6L5G_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._7F6L5G_bs-popover-top > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="top"] > ._7F6L5G_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

._7F6L5G_bs-popover-top > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="top"] > ._7F6L5G_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

._7F6L5G_bs-popover-end > ._7F6L5G_popover-arrow, ._7F6L5G_bs-popover-auto[data-popper-placement^="right"] > ._7F6L5G_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._7F6L5G_bs-popover-end > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="right"] > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-end > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="right"] > ._7F6L5G_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._7F6L5G_bs-popover-end > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="right"] > ._7F6L5G_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

._7F6L5G_bs-popover-end > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="right"] > ._7F6L5G_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

._7F6L5G_bs-popover-bottom > ._7F6L5G_popover-arrow, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] > ._7F6L5G_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._7F6L5G_bs-popover-bottom > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-bottom > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] > ._7F6L5G_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._7F6L5G_bs-popover-bottom > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] > ._7F6L5G_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

._7F6L5G_bs-popover-bottom > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] > ._7F6L5G_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

._7F6L5G_bs-popover-bottom ._7F6L5G_popover-header:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="bottom"] ._7F6L5G_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

._7F6L5G_bs-popover-start > ._7F6L5G_popover-arrow, ._7F6L5G_bs-popover-auto[data-popper-placement^="left"] > ._7F6L5G_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._7F6L5G_bs-popover-start > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="left"] > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-start > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="left"] > ._7F6L5G_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._7F6L5G_bs-popover-start > ._7F6L5G_popover-arrow:before, ._7F6L5G_bs-popover-auto[data-popper-placement^="left"] > ._7F6L5G_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

._7F6L5G_bs-popover-start > ._7F6L5G_popover-arrow:after, ._7F6L5G_bs-popover-auto[data-popper-placement^="left"] > ._7F6L5G_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

._7F6L5G_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

._7F6L5G_popover-header:empty {
  display: none;
}

._7F6L5G_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

._7F6L5G_carousel {
  position: relative;
}

._7F6L5G_carousel._7F6L5G_pointer-event {
  touch-action: pan-y;
}

._7F6L5G_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

._7F6L5G_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

._7F6L5G_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_carousel-item {
    transition: none;
  }
}

._7F6L5G_carousel-item._7F6L5G_active, ._7F6L5G_carousel-item-next, ._7F6L5G_carousel-item-prev {
  display: block;
}

._7F6L5G_carousel-item-next:not(._7F6L5G_carousel-item-start), ._7F6L5G_active._7F6L5G_carousel-item-end {
  transform: translateX(100%);
}

._7F6L5G_carousel-item-prev:not(._7F6L5G_carousel-item-end), ._7F6L5G_active._7F6L5G_carousel-item-start {
  transform: translateX(-100%);
}

._7F6L5G_carousel-fade ._7F6L5G_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

._7F6L5G_carousel-fade ._7F6L5G_carousel-item._7F6L5G_active, ._7F6L5G_carousel-fade ._7F6L5G_carousel-item-next._7F6L5G_carousel-item-start, ._7F6L5G_carousel-fade ._7F6L5G_carousel-item-prev._7F6L5G_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

._7F6L5G_carousel-fade ._7F6L5G_active._7F6L5G_carousel-item-start, ._7F6L5G_carousel-fade ._7F6L5G_active._7F6L5G_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_carousel-fade ._7F6L5G_active._7F6L5G_carousel-item-start, ._7F6L5G_carousel-fade ._7F6L5G_active._7F6L5G_carousel-item-end {
    transition: none;
  }
}

._7F6L5G_carousel-control-prev, ._7F6L5G_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_carousel-control-prev, ._7F6L5G_carousel-control-next {
    transition: none;
  }
}

._7F6L5G_carousel-control-prev:hover, ._7F6L5G_carousel-control-prev:focus, ._7F6L5G_carousel-control-next:hover, ._7F6L5G_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

._7F6L5G_carousel-control-prev {
  left: 0;
}

._7F6L5G_carousel-control-next {
  right: 0;
}

._7F6L5G_carousel-control-prev-icon, ._7F6L5G_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

._7F6L5G_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

._7F6L5G_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

._7F6L5G_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._7F6L5G_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

._7F6L5G_carousel-indicators ._7F6L5G_active {
  opacity: 1;
}

._7F6L5G_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

._7F6L5G_carousel-dark ._7F6L5G_carousel-control-prev-icon, ._7F6L5G_carousel-dark ._7F6L5G_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

._7F6L5G_carousel-dark ._7F6L5G_carousel-indicators [data-bs-target] {
  background-color: #000;
}

._7F6L5G_carousel-dark ._7F6L5G_carousel-caption {
  color: #000;
}

._7F6L5G_spinner-grow, ._7F6L5G_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes _7F6L5G_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

._7F6L5G_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

._7F6L5G_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes _7F6L5G_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

._7F6L5G_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

._7F6L5G_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_spinner-border, ._7F6L5G_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

._7F6L5G_offcanvas, ._7F6L5G_offcanvas-xxl, ._7F6L5G_offcanvas-xl, ._7F6L5G_offcanvas-lg, ._7F6L5G_offcanvas-md, ._7F6L5G_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  ._7F6L5G_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  ._7F6L5G_offcanvas-sm._7F6L5G_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._7F6L5G_offcanvas-sm._7F6L5G_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._7F6L5G_offcanvas-sm._7F6L5G_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._7F6L5G_offcanvas-sm._7F6L5G_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._7F6L5G_offcanvas-sm._7F6L5G_showing, ._7F6L5G_offcanvas-sm._7F6L5G_show:not(._7F6L5G_hiding) {
    transform: none;
  }

  ._7F6L5G_offcanvas-sm._7F6L5G_showing, ._7F6L5G_offcanvas-sm._7F6L5G_hiding, ._7F6L5G_offcanvas-sm._7F6L5G_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  ._7F6L5G_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._7F6L5G_offcanvas-sm ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_offcanvas-sm ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  ._7F6L5G_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  ._7F6L5G_offcanvas-md._7F6L5G_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._7F6L5G_offcanvas-md._7F6L5G_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._7F6L5G_offcanvas-md._7F6L5G_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._7F6L5G_offcanvas-md._7F6L5G_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._7F6L5G_offcanvas-md._7F6L5G_showing, ._7F6L5G_offcanvas-md._7F6L5G_show:not(._7F6L5G_hiding) {
    transform: none;
  }

  ._7F6L5G_offcanvas-md._7F6L5G_showing, ._7F6L5G_offcanvas-md._7F6L5G_hiding, ._7F6L5G_offcanvas-md._7F6L5G_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._7F6L5G_offcanvas-md ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_offcanvas-md ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  ._7F6L5G_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  ._7F6L5G_offcanvas-lg._7F6L5G_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._7F6L5G_offcanvas-lg._7F6L5G_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._7F6L5G_offcanvas-lg._7F6L5G_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._7F6L5G_offcanvas-lg._7F6L5G_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._7F6L5G_offcanvas-lg._7F6L5G_showing, ._7F6L5G_offcanvas-lg._7F6L5G_show:not(._7F6L5G_hiding) {
    transform: none;
  }

  ._7F6L5G_offcanvas-lg._7F6L5G_showing, ._7F6L5G_offcanvas-lg._7F6L5G_hiding, ._7F6L5G_offcanvas-lg._7F6L5G_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._7F6L5G_offcanvas-lg ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_offcanvas-lg ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  ._7F6L5G_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  ._7F6L5G_offcanvas-xl._7F6L5G_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._7F6L5G_offcanvas-xl._7F6L5G_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._7F6L5G_offcanvas-xl._7F6L5G_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._7F6L5G_offcanvas-xl._7F6L5G_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._7F6L5G_offcanvas-xl._7F6L5G_showing, ._7F6L5G_offcanvas-xl._7F6L5G_show:not(._7F6L5G_hiding) {
    transform: none;
  }

  ._7F6L5G_offcanvas-xl._7F6L5G_showing, ._7F6L5G_offcanvas-xl._7F6L5G_hiding, ._7F6L5G_offcanvas-xl._7F6L5G_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._7F6L5G_offcanvas-xl ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_offcanvas-xl ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  ._7F6L5G_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  ._7F6L5G_offcanvas-xxl._7F6L5G_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._7F6L5G_offcanvas-xxl._7F6L5G_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._7F6L5G_offcanvas-xxl._7F6L5G_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._7F6L5G_offcanvas-xxl._7F6L5G_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._7F6L5G_offcanvas-xxl._7F6L5G_showing, ._7F6L5G_offcanvas-xxl._7F6L5G_show:not(._7F6L5G_hiding) {
    transform: none;
  }

  ._7F6L5G_offcanvas-xxl._7F6L5G_showing, ._7F6L5G_offcanvas-xxl._7F6L5G_hiding, ._7F6L5G_offcanvas-xxl._7F6L5G_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._7F6L5G_offcanvas-xxl ._7F6L5G_offcanvas-header {
    display: none;
  }

  ._7F6L5G_offcanvas-xxl ._7F6L5G_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

._7F6L5G_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._7F6L5G_offcanvas {
    transition: none;
  }
}

._7F6L5G_offcanvas._7F6L5G_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

._7F6L5G_offcanvas._7F6L5G_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

._7F6L5G_offcanvas._7F6L5G_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

._7F6L5G_offcanvas._7F6L5G_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

._7F6L5G_offcanvas._7F6L5G_showing, ._7F6L5G_offcanvas._7F6L5G_show:not(._7F6L5G_hiding) {
  transform: none;
}

._7F6L5G_offcanvas._7F6L5G_showing, ._7F6L5G_offcanvas._7F6L5G_hiding, ._7F6L5G_offcanvas._7F6L5G_show {
  visibility: visible;
}

._7F6L5G_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

._7F6L5G_offcanvas-backdrop._7F6L5G_fade {
  opacity: 0;
}

._7F6L5G_offcanvas-backdrop._7F6L5G_show {
  opacity: .5;
}

._7F6L5G_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._7F6L5G_offcanvas-header ._7F6L5G_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

._7F6L5G_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

._7F6L5G_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

._7F6L5G_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

._7F6L5G_placeholder._7F6L5G_btn:before {
  content: "";
  display: inline-block;
}

._7F6L5G_placeholder-xs {
  min-height: .6em;
}

._7F6L5G_placeholder-sm {
  min-height: .8em;
}

._7F6L5G_placeholder-lg {
  min-height: 1.2em;
}

._7F6L5G_placeholder-glow ._7F6L5G_placeholder {
  animation: 2s ease-in-out infinite _7F6L5G_placeholder-glow;
}

@keyframes _7F6L5G_placeholder-glow {
  50% {
    opacity: .2;
  }
}

._7F6L5G_placeholder-wave {
  animation: 2s linear infinite _7F6L5G_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes _7F6L5G_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

._7F6L5G_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

._7F6L5G_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

._7F6L5G_link-primary {
  color: #4c469c !important;
}

._7F6L5G_link-primary:hover, ._7F6L5G_link-primary:focus {
  color: #3d387d !important;
}

._7F6L5G_link-secondary {
  color: #6c757d !important;
}

._7F6L5G_link-secondary:hover, ._7F6L5G_link-secondary:focus {
  color: #565e64 !important;
}

._7F6L5G_link-success {
  color: #198754 !important;
}

._7F6L5G_link-success:hover, ._7F6L5G_link-success:focus {
  color: #146c43 !important;
}

._7F6L5G_link-info {
  color: #0dcaf0 !important;
}

._7F6L5G_link-info:hover, ._7F6L5G_link-info:focus {
  color: #3dd5f3 !important;
}

._7F6L5G_link-warning {
  color: #ffc107 !important;
}

._7F6L5G_link-warning:hover, ._7F6L5G_link-warning:focus {
  color: #ffcd39 !important;
}

._7F6L5G_link-danger {
  color: #dc3545 !important;
}

._7F6L5G_link-danger:hover, ._7F6L5G_link-danger:focus {
  color: #b02a37 !important;
}

._7F6L5G_link-light {
  color: #f8f9fa !important;
}

._7F6L5G_link-light:hover, ._7F6L5G_link-light:focus {
  color: #f9fafb !important;
}

._7F6L5G_link-dark {
  color: #212529 !important;
}

._7F6L5G_link-dark:hover, ._7F6L5G_link-dark:focus {
  color: #1a1e21 !important;
}

._7F6L5G_ratio {
  width: 100%;
  position: relative;
}

._7F6L5G_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

._7F6L5G_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._7F6L5G_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

._7F6L5G_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

._7F6L5G_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

._7F6L5G_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

._7F6L5G_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._7F6L5G_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

._7F6L5G_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

._7F6L5G_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  ._7F6L5G_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._7F6L5G_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._7F6L5G_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._7F6L5G_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._7F6L5G_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._7F6L5G_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

._7F6L5G_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

._7F6L5G_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

._7F6L5G_visually-hidden, ._7F6L5G_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

._7F6L5G_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

._7F6L5G_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._7F6L5G_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

._7F6L5G_align-baseline {
  vertical-align: baseline !important;
}

._7F6L5G_align-top {
  vertical-align: top !important;
}

._7F6L5G_align-middle {
  vertical-align: middle !important;
}

._7F6L5G_align-bottom {
  vertical-align: bottom !important;
}

._7F6L5G_align-text-bottom {
  vertical-align: text-bottom !important;
}

._7F6L5G_align-text-top {
  vertical-align: text-top !important;
}

._7F6L5G_float-start {
  float: left !important;
}

._7F6L5G_float-end {
  float: right !important;
}

._7F6L5G_float-none {
  float: none !important;
}

._7F6L5G_opacity-0 {
  opacity: 0 !important;
}

._7F6L5G_opacity-25 {
  opacity: .25 !important;
}

._7F6L5G_opacity-50 {
  opacity: .5 !important;
}

._7F6L5G_opacity-75 {
  opacity: .75 !important;
}

._7F6L5G_opacity-100 {
  opacity: 1 !important;
}

._7F6L5G_overflow-auto {
  overflow: auto !important;
}

._7F6L5G_overflow-hidden {
  overflow: hidden !important;
}

._7F6L5G_overflow-visible {
  overflow: visible !important;
}

._7F6L5G_overflow-scroll {
  overflow: scroll !important;
}

._7F6L5G_d-inline {
  display: inline !important;
}

._7F6L5G_d-inline-block {
  display: inline-block !important;
}

._7F6L5G_d-block {
  display: block !important;
}

._7F6L5G_d-grid {
  display: grid !important;
}

._7F6L5G_d-table {
  display: table !important;
}

._7F6L5G_d-table-row {
  display: table-row !important;
}

._7F6L5G_d-table-cell {
  display: table-cell !important;
}

._7F6L5G_d-flex {
  display: flex !important;
}

._7F6L5G_d-inline-flex {
  display: inline-flex !important;
}

._7F6L5G_d-none {
  display: none !important;
}

._7F6L5G_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

._7F6L5G_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

._7F6L5G_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

._7F6L5G_shadow-none {
  box-shadow: none !important;
}

._7F6L5G_position-static {
  position: static !important;
}

._7F6L5G_position-relative {
  position: relative !important;
}

._7F6L5G_position-absolute {
  position: absolute !important;
}

._7F6L5G_position-fixed {
  position: fixed !important;
}

._7F6L5G_position-sticky {
  position: sticky !important;
}

._7F6L5G_top-0 {
  top: 0 !important;
}

._7F6L5G_top-50 {
  top: 50% !important;
}

._7F6L5G_top-100 {
  top: 100% !important;
}

._7F6L5G_bottom-0 {
  bottom: 0 !important;
}

._7F6L5G_bottom-50 {
  bottom: 50% !important;
}

._7F6L5G_bottom-100 {
  bottom: 100% !important;
}

._7F6L5G_start-0 {
  left: 0 !important;
}

._7F6L5G_start-50 {
  left: 50% !important;
}

._7F6L5G_start-100 {
  left: 100% !important;
}

._7F6L5G_end-0 {
  right: 0 !important;
}

._7F6L5G_end-50 {
  right: 50% !important;
}

._7F6L5G_end-100 {
  right: 100% !important;
}

._7F6L5G_translate-middle {
  transform: translate(-50%, -50%) !important;
}

._7F6L5G_translate-middle-x {
  transform: translateX(-50%) !important;
}

._7F6L5G_translate-middle-y {
  transform: translateY(-50%) !important;
}

._7F6L5G_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._7F6L5G_border-0 {
  border: 0 !important;
}

._7F6L5G_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._7F6L5G_border-top-0 {
  border-top: 0 !important;
}

._7F6L5G_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._7F6L5G_border-end-0 {
  border-right: 0 !important;
}

._7F6L5G_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._7F6L5G_border-bottom-0 {
  border-bottom: 0 !important;
}

._7F6L5G_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._7F6L5G_border-start-0 {
  border-left: 0 !important;
}

._7F6L5G_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

._7F6L5G_border-1 {
  --bs-border-width: 1px;
}

._7F6L5G_border-2 {
  --bs-border-width: 2px;
}

._7F6L5G_border-3 {
  --bs-border-width: 3px;
}

._7F6L5G_border-4 {
  --bs-border-width: 4px;
}

._7F6L5G_border-5 {
  --bs-border-width: 5px;
}

._7F6L5G_border-opacity-10 {
  --bs-border-opacity: .1;
}

._7F6L5G_border-opacity-25 {
  --bs-border-opacity: .25;
}

._7F6L5G_border-opacity-50 {
  --bs-border-opacity: .5;
}

._7F6L5G_border-opacity-75 {
  --bs-border-opacity: .75;
}

._7F6L5G_border-opacity-100 {
  --bs-border-opacity: 1;
}

._7F6L5G_w-25 {
  width: 25% !important;
}

._7F6L5G_w-50 {
  width: 50% !important;
}

._7F6L5G_w-75 {
  width: 75% !important;
}

._7F6L5G_w-100 {
  width: 100% !important;
}

._7F6L5G_w-auto {
  width: auto !important;
}

._7F6L5G_mw-100 {
  max-width: 100% !important;
}

._7F6L5G_vw-100 {
  width: 100vw !important;
}

._7F6L5G_min-vw-100 {
  min-width: 100vw !important;
}

._7F6L5G_h-25 {
  height: 25% !important;
}

._7F6L5G_h-50 {
  height: 50% !important;
}

._7F6L5G_h-75 {
  height: 75% !important;
}

._7F6L5G_h-100 {
  height: 100% !important;
}

._7F6L5G_h-auto {
  height: auto !important;
}

._7F6L5G_mh-100 {
  max-height: 100% !important;
}

._7F6L5G_vh-100 {
  height: 100vh !important;
}

._7F6L5G_min-vh-100 {
  min-height: 100vh !important;
}

._7F6L5G_flex-fill {
  flex: auto !important;
}

._7F6L5G_flex-row {
  flex-direction: row !important;
}

._7F6L5G_flex-column {
  flex-direction: column !important;
}

._7F6L5G_flex-row-reverse {
  flex-direction: row-reverse !important;
}

._7F6L5G_flex-column-reverse {
  flex-direction: column-reverse !important;
}

._7F6L5G_flex-grow-0 {
  flex-grow: 0 !important;
}

._7F6L5G_flex-grow-1 {
  flex-grow: 1 !important;
}

._7F6L5G_flex-shrink-0 {
  flex-shrink: 0 !important;
}

._7F6L5G_flex-shrink-1 {
  flex-shrink: 1 !important;
}

._7F6L5G_flex-wrap {
  flex-wrap: wrap !important;
}

._7F6L5G_flex-nowrap {
  flex-wrap: nowrap !important;
}

._7F6L5G_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._7F6L5G_justify-content-start {
  justify-content: flex-start !important;
}

._7F6L5G_justify-content-end {
  justify-content: flex-end !important;
}

._7F6L5G_justify-content-center {
  justify-content: center !important;
}

._7F6L5G_justify-content-between {
  justify-content: space-between !important;
}

._7F6L5G_justify-content-around {
  justify-content: space-around !important;
}

._7F6L5G_justify-content-evenly {
  justify-content: space-evenly !important;
}

._7F6L5G_align-items-start {
  align-items: flex-start !important;
}

._7F6L5G_align-items-end {
  align-items: flex-end !important;
}

._7F6L5G_align-items-center {
  align-items: center !important;
}

._7F6L5G_align-items-baseline {
  align-items: baseline !important;
}

._7F6L5G_align-items-stretch {
  align-items: stretch !important;
}

._7F6L5G_align-content-start {
  align-content: flex-start !important;
}

._7F6L5G_align-content-end {
  align-content: flex-end !important;
}

._7F6L5G_align-content-center {
  align-content: center !important;
}

._7F6L5G_align-content-between {
  align-content: space-between !important;
}

._7F6L5G_align-content-around {
  align-content: space-around !important;
}

._7F6L5G_align-content-stretch {
  align-content: stretch !important;
}

._7F6L5G_align-self-auto {
  align-self: auto !important;
}

._7F6L5G_align-self-start {
  align-self: flex-start !important;
}

._7F6L5G_align-self-end {
  align-self: flex-end !important;
}

._7F6L5G_align-self-center {
  align-self: center !important;
}

._7F6L5G_align-self-baseline {
  align-self: baseline !important;
}

._7F6L5G_align-self-stretch {
  align-self: stretch !important;
}

._7F6L5G_order-first {
  order: -1 !important;
}

._7F6L5G_order-0 {
  order: 0 !important;
}

._7F6L5G_order-1 {
  order: 1 !important;
}

._7F6L5G_order-2 {
  order: 2 !important;
}

._7F6L5G_order-3 {
  order: 3 !important;
}

._7F6L5G_order-4 {
  order: 4 !important;
}

._7F6L5G_order-5 {
  order: 5 !important;
}

._7F6L5G_order-last {
  order: 6 !important;
}

._7F6L5G_m-0 {
  margin: 0 !important;
}

._7F6L5G_m-1 {
  margin: .25rem !important;
}

._7F6L5G_m-2 {
  margin: .5rem !important;
}

._7F6L5G_m-3 {
  margin: 1rem !important;
}

._7F6L5G_m-4 {
  margin: 1.5rem !important;
}

._7F6L5G_m-5 {
  margin: 3rem !important;
}

._7F6L5G_m-auto {
  margin: auto !important;
}

._7F6L5G_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

._7F6L5G_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

._7F6L5G_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

._7F6L5G_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

._7F6L5G_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

._7F6L5G_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

._7F6L5G_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

._7F6L5G_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

._7F6L5G_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

._7F6L5G_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

._7F6L5G_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

._7F6L5G_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

._7F6L5G_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

._7F6L5G_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

._7F6L5G_mt-0 {
  margin-top: 0 !important;
}

._7F6L5G_mt-1 {
  margin-top: .25rem !important;
}

._7F6L5G_mt-2 {
  margin-top: .5rem !important;
}

._7F6L5G_mt-3 {
  margin-top: 1rem !important;
}

._7F6L5G_mt-4 {
  margin-top: 1.5rem !important;
}

._7F6L5G_mt-5 {
  margin-top: 3rem !important;
}

._7F6L5G_mt-auto {
  margin-top: auto !important;
}

._7F6L5G_me-0 {
  margin-right: 0 !important;
}

._7F6L5G_me-1 {
  margin-right: .25rem !important;
}

._7F6L5G_me-2 {
  margin-right: .5rem !important;
}

._7F6L5G_me-3 {
  margin-right: 1rem !important;
}

._7F6L5G_me-4 {
  margin-right: 1.5rem !important;
}

._7F6L5G_me-5 {
  margin-right: 3rem !important;
}

._7F6L5G_me-auto {
  margin-right: auto !important;
}

._7F6L5G_mb-0 {
  margin-bottom: 0 !important;
}

._7F6L5G_mb-1 {
  margin-bottom: .25rem !important;
}

._7F6L5G_mb-2 {
  margin-bottom: .5rem !important;
}

._7F6L5G_mb-3 {
  margin-bottom: 1rem !important;
}

._7F6L5G_mb-4 {
  margin-bottom: 1.5rem !important;
}

._7F6L5G_mb-5 {
  margin-bottom: 3rem !important;
}

._7F6L5G_mb-auto {
  margin-bottom: auto !important;
}

._7F6L5G_ms-0 {
  margin-left: 0 !important;
}

._7F6L5G_ms-1 {
  margin-left: .25rem !important;
}

._7F6L5G_ms-2 {
  margin-left: .5rem !important;
}

._7F6L5G_ms-3 {
  margin-left: 1rem !important;
}

._7F6L5G_ms-4 {
  margin-left: 1.5rem !important;
}

._7F6L5G_ms-5 {
  margin-left: 3rem !important;
}

._7F6L5G_ms-auto {
  margin-left: auto !important;
}

._7F6L5G_p-0 {
  padding: 0 !important;
}

._7F6L5G_p-1 {
  padding: .25rem !important;
}

._7F6L5G_p-2 {
  padding: .5rem !important;
}

._7F6L5G_p-3 {
  padding: 1rem !important;
}

._7F6L5G_p-4 {
  padding: 1.5rem !important;
}

._7F6L5G_p-5 {
  padding: 3rem !important;
}

._7F6L5G_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._7F6L5G_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

._7F6L5G_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

._7F6L5G_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

._7F6L5G_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

._7F6L5G_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

._7F6L5G_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

._7F6L5G_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

._7F6L5G_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

._7F6L5G_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

._7F6L5G_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

._7F6L5G_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

._7F6L5G_pt-0 {
  padding-top: 0 !important;
}

._7F6L5G_pt-1 {
  padding-top: .25rem !important;
}

._7F6L5G_pt-2 {
  padding-top: .5rem !important;
}

._7F6L5G_pt-3 {
  padding-top: 1rem !important;
}

._7F6L5G_pt-4 {
  padding-top: 1.5rem !important;
}

._7F6L5G_pt-5 {
  padding-top: 3rem !important;
}

._7F6L5G_pe-0 {
  padding-right: 0 !important;
}

._7F6L5G_pe-1 {
  padding-right: .25rem !important;
}

._7F6L5G_pe-2 {
  padding-right: .5rem !important;
}

._7F6L5G_pe-3 {
  padding-right: 1rem !important;
}

._7F6L5G_pe-4 {
  padding-right: 1.5rem !important;
}

._7F6L5G_pe-5 {
  padding-right: 3rem !important;
}

._7F6L5G_pb-0 {
  padding-bottom: 0 !important;
}

._7F6L5G_pb-1 {
  padding-bottom: .25rem !important;
}

._7F6L5G_pb-2 {
  padding-bottom: .5rem !important;
}

._7F6L5G_pb-3 {
  padding-bottom: 1rem !important;
}

._7F6L5G_pb-4 {
  padding-bottom: 1.5rem !important;
}

._7F6L5G_pb-5 {
  padding-bottom: 3rem !important;
}

._7F6L5G_ps-0 {
  padding-left: 0 !important;
}

._7F6L5G_ps-1 {
  padding-left: .25rem !important;
}

._7F6L5G_ps-2 {
  padding-left: .5rem !important;
}

._7F6L5G_ps-3 {
  padding-left: 1rem !important;
}

._7F6L5G_ps-4 {
  padding-left: 1.5rem !important;
}

._7F6L5G_ps-5 {
  padding-left: 3rem !important;
}

._7F6L5G_gap-0 {
  gap: 0 !important;
}

._7F6L5G_gap-1 {
  gap: .25rem !important;
}

._7F6L5G_gap-2 {
  gap: .5rem !important;
}

._7F6L5G_gap-3 {
  gap: 1rem !important;
}

._7F6L5G_gap-4 {
  gap: 1.5rem !important;
}

._7F6L5G_gap-5 {
  gap: 3rem !important;
}

._7F6L5G_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

._7F6L5G_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

._7F6L5G_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

._7F6L5G_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

._7F6L5G_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

._7F6L5G_fs-5 {
  font-size: 1.25rem !important;
}

._7F6L5G_fs-6 {
  font-size: 1rem !important;
}

._7F6L5G_fst-italic {
  font-style: italic !important;
}

._7F6L5G_fst-normal {
  font-style: normal !important;
}

._7F6L5G_fw-light {
  font-weight: 300 !important;
}

._7F6L5G_fw-lighter {
  font-weight: lighter !important;
}

._7F6L5G_fw-normal {
  font-weight: 400 !important;
}

._7F6L5G_fw-bold {
  font-weight: 700 !important;
}

._7F6L5G_fw-semibold {
  font-weight: 600 !important;
}

._7F6L5G_fw-bolder {
  font-weight: bolder !important;
}

._7F6L5G_lh-1 {
  line-height: 1 !important;
}

._7F6L5G_lh-sm {
  line-height: 1.25 !important;
}

._7F6L5G_lh-base {
  line-height: 1.5 !important;
}

._7F6L5G_lh-lg {
  line-height: 2 !important;
}

._7F6L5G_text-start {
  text-align: left !important;
}

._7F6L5G_text-end {
  text-align: right !important;
}

._7F6L5G_text-center {
  text-align: center !important;
}

._7F6L5G_text-decoration-none {
  text-decoration: none !important;
}

._7F6L5G_text-decoration-underline {
  text-decoration: underline !important;
}

._7F6L5G_text-decoration-line-through {
  text-decoration: line-through !important;
}

._7F6L5G_text-lowercase {
  text-transform: lowercase !important;
}

._7F6L5G_text-uppercase {
  text-transform: uppercase !important;
}

._7F6L5G_text-capitalize {
  text-transform: capitalize !important;
}

._7F6L5G_text-wrap {
  white-space: normal !important;
}

._7F6L5G_text-nowrap {
  white-space: nowrap !important;
}

._7F6L5G_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

._7F6L5G_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

._7F6L5G_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

._7F6L5G_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

._7F6L5G_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

._7F6L5G_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

._7F6L5G_text-opacity-25 {
  --bs-text-opacity: .25;
}

._7F6L5G_text-opacity-50 {
  --bs-text-opacity: .5;
}

._7F6L5G_text-opacity-75 {
  --bs-text-opacity: .75;
}

._7F6L5G_text-opacity-100 {
  --bs-text-opacity: 1;
}

._7F6L5G_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

._7F6L5G_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

._7F6L5G_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

._7F6L5G_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

._7F6L5G_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

._7F6L5G_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

._7F6L5G_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

._7F6L5G_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

._7F6L5G_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

._7F6L5G_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

._7F6L5G_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

._7F6L5G_pe-none {
  pointer-events: none !important;
}

._7F6L5G_pe-auto {
  pointer-events: auto !important;
}

._7F6L5G_rounded {
  border-radius: var(--bs-border-radius) !important;
}

._7F6L5G_rounded-0 {
  border-radius: 0 !important;
}

._7F6L5G_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

._7F6L5G_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

._7F6L5G_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

._7F6L5G_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

._7F6L5G_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

._7F6L5G_rounded-circle {
  border-radius: 50% !important;
}

._7F6L5G_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

._7F6L5G_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

._7F6L5G_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

._7F6L5G_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

._7F6L5G_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

._7F6L5G_visible {
  visibility: visible !important;
}

._7F6L5G_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  ._7F6L5G_float-sm-start {
    float: left !important;
  }

  ._7F6L5G_float-sm-end {
    float: right !important;
  }

  ._7F6L5G_float-sm-none {
    float: none !important;
  }

  ._7F6L5G_d-sm-inline {
    display: inline !important;
  }

  ._7F6L5G_d-sm-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-sm-block {
    display: block !important;
  }

  ._7F6L5G_d-sm-grid {
    display: grid !important;
  }

  ._7F6L5G_d-sm-table {
    display: table !important;
  }

  ._7F6L5G_d-sm-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-sm-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-sm-flex {
    display: flex !important;
  }

  ._7F6L5G_d-sm-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-sm-none {
    display: none !important;
  }

  ._7F6L5G_flex-sm-fill {
    flex: auto !important;
  }

  ._7F6L5G_flex-sm-row {
    flex-direction: row !important;
  }

  ._7F6L5G_flex-sm-column {
    flex-direction: column !important;
  }

  ._7F6L5G_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._7F6L5G_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._7F6L5G_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  ._7F6L5G_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  ._7F6L5G_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._7F6L5G_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._7F6L5G_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  ._7F6L5G_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  ._7F6L5G_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._7F6L5G_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  ._7F6L5G_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  ._7F6L5G_justify-content-sm-center {
    justify-content: center !important;
  }

  ._7F6L5G_justify-content-sm-between {
    justify-content: space-between !important;
  }

  ._7F6L5G_justify-content-sm-around {
    justify-content: space-around !important;
  }

  ._7F6L5G_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  ._7F6L5G_align-items-sm-start {
    align-items: flex-start !important;
  }

  ._7F6L5G_align-items-sm-end {
    align-items: flex-end !important;
  }

  ._7F6L5G_align-items-sm-center {
    align-items: center !important;
  }

  ._7F6L5G_align-items-sm-baseline {
    align-items: baseline !important;
  }

  ._7F6L5G_align-items-sm-stretch {
    align-items: stretch !important;
  }

  ._7F6L5G_align-content-sm-start {
    align-content: flex-start !important;
  }

  ._7F6L5G_align-content-sm-end {
    align-content: flex-end !important;
  }

  ._7F6L5G_align-content-sm-center {
    align-content: center !important;
  }

  ._7F6L5G_align-content-sm-between {
    align-content: space-between !important;
  }

  ._7F6L5G_align-content-sm-around {
    align-content: space-around !important;
  }

  ._7F6L5G_align-content-sm-stretch {
    align-content: stretch !important;
  }

  ._7F6L5G_align-self-sm-auto {
    align-self: auto !important;
  }

  ._7F6L5G_align-self-sm-start {
    align-self: flex-start !important;
  }

  ._7F6L5G_align-self-sm-end {
    align-self: flex-end !important;
  }

  ._7F6L5G_align-self-sm-center {
    align-self: center !important;
  }

  ._7F6L5G_align-self-sm-baseline {
    align-self: baseline !important;
  }

  ._7F6L5G_align-self-sm-stretch {
    align-self: stretch !important;
  }

  ._7F6L5G_order-sm-first {
    order: -1 !important;
  }

  ._7F6L5G_order-sm-0 {
    order: 0 !important;
  }

  ._7F6L5G_order-sm-1 {
    order: 1 !important;
  }

  ._7F6L5G_order-sm-2 {
    order: 2 !important;
  }

  ._7F6L5G_order-sm-3 {
    order: 3 !important;
  }

  ._7F6L5G_order-sm-4 {
    order: 4 !important;
  }

  ._7F6L5G_order-sm-5 {
    order: 5 !important;
  }

  ._7F6L5G_order-sm-last {
    order: 6 !important;
  }

  ._7F6L5G_m-sm-0 {
    margin: 0 !important;
  }

  ._7F6L5G_m-sm-1 {
    margin: .25rem !important;
  }

  ._7F6L5G_m-sm-2 {
    margin: .5rem !important;
  }

  ._7F6L5G_m-sm-3 {
    margin: 1rem !important;
  }

  ._7F6L5G_m-sm-4 {
    margin: 1.5rem !important;
  }

  ._7F6L5G_m-sm-5 {
    margin: 3rem !important;
  }

  ._7F6L5G_m-sm-auto {
    margin: auto !important;
  }

  ._7F6L5G_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._7F6L5G_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._7F6L5G_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._7F6L5G_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._7F6L5G_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._7F6L5G_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._7F6L5G_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._7F6L5G_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._7F6L5G_mt-sm-0 {
    margin-top: 0 !important;
  }

  ._7F6L5G_mt-sm-1 {
    margin-top: .25rem !important;
  }

  ._7F6L5G_mt-sm-2 {
    margin-top: .5rem !important;
  }

  ._7F6L5G_mt-sm-3 {
    margin-top: 1rem !important;
  }

  ._7F6L5G_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  ._7F6L5G_mt-sm-5 {
    margin-top: 3rem !important;
  }

  ._7F6L5G_mt-sm-auto {
    margin-top: auto !important;
  }

  ._7F6L5G_me-sm-0 {
    margin-right: 0 !important;
  }

  ._7F6L5G_me-sm-1 {
    margin-right: .25rem !important;
  }

  ._7F6L5G_me-sm-2 {
    margin-right: .5rem !important;
  }

  ._7F6L5G_me-sm-3 {
    margin-right: 1rem !important;
  }

  ._7F6L5G_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_me-sm-5 {
    margin-right: 3rem !important;
  }

  ._7F6L5G_me-sm-auto {
    margin-right: auto !important;
  }

  ._7F6L5G_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  ._7F6L5G_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_mb-sm-auto {
    margin-bottom: auto !important;
  }

  ._7F6L5G_ms-sm-0 {
    margin-left: 0 !important;
  }

  ._7F6L5G_ms-sm-1 {
    margin-left: .25rem !important;
  }

  ._7F6L5G_ms-sm-2 {
    margin-left: .5rem !important;
  }

  ._7F6L5G_ms-sm-3 {
    margin-left: 1rem !important;
  }

  ._7F6L5G_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  ._7F6L5G_ms-sm-5 {
    margin-left: 3rem !important;
  }

  ._7F6L5G_ms-sm-auto {
    margin-left: auto !important;
  }

  ._7F6L5G_p-sm-0 {
    padding: 0 !important;
  }

  ._7F6L5G_p-sm-1 {
    padding: .25rem !important;
  }

  ._7F6L5G_p-sm-2 {
    padding: .5rem !important;
  }

  ._7F6L5G_p-sm-3 {
    padding: 1rem !important;
  }

  ._7F6L5G_p-sm-4 {
    padding: 1.5rem !important;
  }

  ._7F6L5G_p-sm-5 {
    padding: 3rem !important;
  }

  ._7F6L5G_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._7F6L5G_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._7F6L5G_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._7F6L5G_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._7F6L5G_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._7F6L5G_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._7F6L5G_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_pt-sm-0 {
    padding-top: 0 !important;
  }

  ._7F6L5G_pt-sm-1 {
    padding-top: .25rem !important;
  }

  ._7F6L5G_pt-sm-2 {
    padding-top: .5rem !important;
  }

  ._7F6L5G_pt-sm-3 {
    padding-top: 1rem !important;
  }

  ._7F6L5G_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  ._7F6L5G_pt-sm-5 {
    padding-top: 3rem !important;
  }

  ._7F6L5G_pe-sm-0 {
    padding-right: 0 !important;
  }

  ._7F6L5G_pe-sm-1 {
    padding-right: .25rem !important;
  }

  ._7F6L5G_pe-sm-2 {
    padding-right: .5rem !important;
  }

  ._7F6L5G_pe-sm-3 {
    padding-right: 1rem !important;
  }

  ._7F6L5G_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_pe-sm-5 {
    padding-right: 3rem !important;
  }

  ._7F6L5G_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  ._7F6L5G_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_ps-sm-0 {
    padding-left: 0 !important;
  }

  ._7F6L5G_ps-sm-1 {
    padding-left: .25rem !important;
  }

  ._7F6L5G_ps-sm-2 {
    padding-left: .5rem !important;
  }

  ._7F6L5G_ps-sm-3 {
    padding-left: 1rem !important;
  }

  ._7F6L5G_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  ._7F6L5G_ps-sm-5 {
    padding-left: 3rem !important;
  }

  ._7F6L5G_gap-sm-0 {
    gap: 0 !important;
  }

  ._7F6L5G_gap-sm-1 {
    gap: .25rem !important;
  }

  ._7F6L5G_gap-sm-2 {
    gap: .5rem !important;
  }

  ._7F6L5G_gap-sm-3 {
    gap: 1rem !important;
  }

  ._7F6L5G_gap-sm-4 {
    gap: 1.5rem !important;
  }

  ._7F6L5G_gap-sm-5 {
    gap: 3rem !important;
  }

  ._7F6L5G_text-sm-start {
    text-align: left !important;
  }

  ._7F6L5G_text-sm-end {
    text-align: right !important;
  }

  ._7F6L5G_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  ._7F6L5G_float-md-start {
    float: left !important;
  }

  ._7F6L5G_float-md-end {
    float: right !important;
  }

  ._7F6L5G_float-md-none {
    float: none !important;
  }

  ._7F6L5G_d-md-inline {
    display: inline !important;
  }

  ._7F6L5G_d-md-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-md-block {
    display: block !important;
  }

  ._7F6L5G_d-md-grid {
    display: grid !important;
  }

  ._7F6L5G_d-md-table {
    display: table !important;
  }

  ._7F6L5G_d-md-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-md-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-md-flex {
    display: flex !important;
  }

  ._7F6L5G_d-md-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-md-none {
    display: none !important;
  }

  ._7F6L5G_flex-md-fill {
    flex: auto !important;
  }

  ._7F6L5G_flex-md-row {
    flex-direction: row !important;
  }

  ._7F6L5G_flex-md-column {
    flex-direction: column !important;
  }

  ._7F6L5G_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._7F6L5G_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._7F6L5G_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  ._7F6L5G_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  ._7F6L5G_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._7F6L5G_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._7F6L5G_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  ._7F6L5G_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  ._7F6L5G_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._7F6L5G_justify-content-md-start {
    justify-content: flex-start !important;
  }

  ._7F6L5G_justify-content-md-end {
    justify-content: flex-end !important;
  }

  ._7F6L5G_justify-content-md-center {
    justify-content: center !important;
  }

  ._7F6L5G_justify-content-md-between {
    justify-content: space-between !important;
  }

  ._7F6L5G_justify-content-md-around {
    justify-content: space-around !important;
  }

  ._7F6L5G_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  ._7F6L5G_align-items-md-start {
    align-items: flex-start !important;
  }

  ._7F6L5G_align-items-md-end {
    align-items: flex-end !important;
  }

  ._7F6L5G_align-items-md-center {
    align-items: center !important;
  }

  ._7F6L5G_align-items-md-baseline {
    align-items: baseline !important;
  }

  ._7F6L5G_align-items-md-stretch {
    align-items: stretch !important;
  }

  ._7F6L5G_align-content-md-start {
    align-content: flex-start !important;
  }

  ._7F6L5G_align-content-md-end {
    align-content: flex-end !important;
  }

  ._7F6L5G_align-content-md-center {
    align-content: center !important;
  }

  ._7F6L5G_align-content-md-between {
    align-content: space-between !important;
  }

  ._7F6L5G_align-content-md-around {
    align-content: space-around !important;
  }

  ._7F6L5G_align-content-md-stretch {
    align-content: stretch !important;
  }

  ._7F6L5G_align-self-md-auto {
    align-self: auto !important;
  }

  ._7F6L5G_align-self-md-start {
    align-self: flex-start !important;
  }

  ._7F6L5G_align-self-md-end {
    align-self: flex-end !important;
  }

  ._7F6L5G_align-self-md-center {
    align-self: center !important;
  }

  ._7F6L5G_align-self-md-baseline {
    align-self: baseline !important;
  }

  ._7F6L5G_align-self-md-stretch {
    align-self: stretch !important;
  }

  ._7F6L5G_order-md-first {
    order: -1 !important;
  }

  ._7F6L5G_order-md-0 {
    order: 0 !important;
  }

  ._7F6L5G_order-md-1 {
    order: 1 !important;
  }

  ._7F6L5G_order-md-2 {
    order: 2 !important;
  }

  ._7F6L5G_order-md-3 {
    order: 3 !important;
  }

  ._7F6L5G_order-md-4 {
    order: 4 !important;
  }

  ._7F6L5G_order-md-5 {
    order: 5 !important;
  }

  ._7F6L5G_order-md-last {
    order: 6 !important;
  }

  ._7F6L5G_m-md-0 {
    margin: 0 !important;
  }

  ._7F6L5G_m-md-1 {
    margin: .25rem !important;
  }

  ._7F6L5G_m-md-2 {
    margin: .5rem !important;
  }

  ._7F6L5G_m-md-3 {
    margin: 1rem !important;
  }

  ._7F6L5G_m-md-4 {
    margin: 1.5rem !important;
  }

  ._7F6L5G_m-md-5 {
    margin: 3rem !important;
  }

  ._7F6L5G_m-md-auto {
    margin: auto !important;
  }

  ._7F6L5G_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._7F6L5G_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._7F6L5G_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._7F6L5G_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._7F6L5G_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._7F6L5G_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._7F6L5G_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._7F6L5G_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._7F6L5G_mt-md-0 {
    margin-top: 0 !important;
  }

  ._7F6L5G_mt-md-1 {
    margin-top: .25rem !important;
  }

  ._7F6L5G_mt-md-2 {
    margin-top: .5rem !important;
  }

  ._7F6L5G_mt-md-3 {
    margin-top: 1rem !important;
  }

  ._7F6L5G_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  ._7F6L5G_mt-md-5 {
    margin-top: 3rem !important;
  }

  ._7F6L5G_mt-md-auto {
    margin-top: auto !important;
  }

  ._7F6L5G_me-md-0 {
    margin-right: 0 !important;
  }

  ._7F6L5G_me-md-1 {
    margin-right: .25rem !important;
  }

  ._7F6L5G_me-md-2 {
    margin-right: .5rem !important;
  }

  ._7F6L5G_me-md-3 {
    margin-right: 1rem !important;
  }

  ._7F6L5G_me-md-4 {
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_me-md-5 {
    margin-right: 3rem !important;
  }

  ._7F6L5G_me-md-auto {
    margin-right: auto !important;
  }

  ._7F6L5G_mb-md-0 {
    margin-bottom: 0 !important;
  }

  ._7F6L5G_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_mb-md-auto {
    margin-bottom: auto !important;
  }

  ._7F6L5G_ms-md-0 {
    margin-left: 0 !important;
  }

  ._7F6L5G_ms-md-1 {
    margin-left: .25rem !important;
  }

  ._7F6L5G_ms-md-2 {
    margin-left: .5rem !important;
  }

  ._7F6L5G_ms-md-3 {
    margin-left: 1rem !important;
  }

  ._7F6L5G_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  ._7F6L5G_ms-md-5 {
    margin-left: 3rem !important;
  }

  ._7F6L5G_ms-md-auto {
    margin-left: auto !important;
  }

  ._7F6L5G_p-md-0 {
    padding: 0 !important;
  }

  ._7F6L5G_p-md-1 {
    padding: .25rem !important;
  }

  ._7F6L5G_p-md-2 {
    padding: .5rem !important;
  }

  ._7F6L5G_p-md-3 {
    padding: 1rem !important;
  }

  ._7F6L5G_p-md-4 {
    padding: 1.5rem !important;
  }

  ._7F6L5G_p-md-5 {
    padding: 3rem !important;
  }

  ._7F6L5G_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._7F6L5G_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._7F6L5G_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._7F6L5G_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._7F6L5G_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._7F6L5G_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._7F6L5G_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_pt-md-0 {
    padding-top: 0 !important;
  }

  ._7F6L5G_pt-md-1 {
    padding-top: .25rem !important;
  }

  ._7F6L5G_pt-md-2 {
    padding-top: .5rem !important;
  }

  ._7F6L5G_pt-md-3 {
    padding-top: 1rem !important;
  }

  ._7F6L5G_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  ._7F6L5G_pt-md-5 {
    padding-top: 3rem !important;
  }

  ._7F6L5G_pe-md-0 {
    padding-right: 0 !important;
  }

  ._7F6L5G_pe-md-1 {
    padding-right: .25rem !important;
  }

  ._7F6L5G_pe-md-2 {
    padding-right: .5rem !important;
  }

  ._7F6L5G_pe-md-3 {
    padding-right: 1rem !important;
  }

  ._7F6L5G_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_pe-md-5 {
    padding-right: 3rem !important;
  }

  ._7F6L5G_pb-md-0 {
    padding-bottom: 0 !important;
  }

  ._7F6L5G_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_ps-md-0 {
    padding-left: 0 !important;
  }

  ._7F6L5G_ps-md-1 {
    padding-left: .25rem !important;
  }

  ._7F6L5G_ps-md-2 {
    padding-left: .5rem !important;
  }

  ._7F6L5G_ps-md-3 {
    padding-left: 1rem !important;
  }

  ._7F6L5G_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  ._7F6L5G_ps-md-5 {
    padding-left: 3rem !important;
  }

  ._7F6L5G_gap-md-0 {
    gap: 0 !important;
  }

  ._7F6L5G_gap-md-1 {
    gap: .25rem !important;
  }

  ._7F6L5G_gap-md-2 {
    gap: .5rem !important;
  }

  ._7F6L5G_gap-md-3 {
    gap: 1rem !important;
  }

  ._7F6L5G_gap-md-4 {
    gap: 1.5rem !important;
  }

  ._7F6L5G_gap-md-5 {
    gap: 3rem !important;
  }

  ._7F6L5G_text-md-start {
    text-align: left !important;
  }

  ._7F6L5G_text-md-end {
    text-align: right !important;
  }

  ._7F6L5G_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  ._7F6L5G_float-lg-start {
    float: left !important;
  }

  ._7F6L5G_float-lg-end {
    float: right !important;
  }

  ._7F6L5G_float-lg-none {
    float: none !important;
  }

  ._7F6L5G_d-lg-inline {
    display: inline !important;
  }

  ._7F6L5G_d-lg-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-lg-block {
    display: block !important;
  }

  ._7F6L5G_d-lg-grid {
    display: grid !important;
  }

  ._7F6L5G_d-lg-table {
    display: table !important;
  }

  ._7F6L5G_d-lg-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-lg-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-lg-flex {
    display: flex !important;
  }

  ._7F6L5G_d-lg-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-lg-none {
    display: none !important;
  }

  ._7F6L5G_flex-lg-fill {
    flex: auto !important;
  }

  ._7F6L5G_flex-lg-row {
    flex-direction: row !important;
  }

  ._7F6L5G_flex-lg-column {
    flex-direction: column !important;
  }

  ._7F6L5G_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._7F6L5G_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._7F6L5G_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  ._7F6L5G_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  ._7F6L5G_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._7F6L5G_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._7F6L5G_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  ._7F6L5G_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  ._7F6L5G_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._7F6L5G_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  ._7F6L5G_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  ._7F6L5G_justify-content-lg-center {
    justify-content: center !important;
  }

  ._7F6L5G_justify-content-lg-between {
    justify-content: space-between !important;
  }

  ._7F6L5G_justify-content-lg-around {
    justify-content: space-around !important;
  }

  ._7F6L5G_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  ._7F6L5G_align-items-lg-start {
    align-items: flex-start !important;
  }

  ._7F6L5G_align-items-lg-end {
    align-items: flex-end !important;
  }

  ._7F6L5G_align-items-lg-center {
    align-items: center !important;
  }

  ._7F6L5G_align-items-lg-baseline {
    align-items: baseline !important;
  }

  ._7F6L5G_align-items-lg-stretch {
    align-items: stretch !important;
  }

  ._7F6L5G_align-content-lg-start {
    align-content: flex-start !important;
  }

  ._7F6L5G_align-content-lg-end {
    align-content: flex-end !important;
  }

  ._7F6L5G_align-content-lg-center {
    align-content: center !important;
  }

  ._7F6L5G_align-content-lg-between {
    align-content: space-between !important;
  }

  ._7F6L5G_align-content-lg-around {
    align-content: space-around !important;
  }

  ._7F6L5G_align-content-lg-stretch {
    align-content: stretch !important;
  }

  ._7F6L5G_align-self-lg-auto {
    align-self: auto !important;
  }

  ._7F6L5G_align-self-lg-start {
    align-self: flex-start !important;
  }

  ._7F6L5G_align-self-lg-end {
    align-self: flex-end !important;
  }

  ._7F6L5G_align-self-lg-center {
    align-self: center !important;
  }

  ._7F6L5G_align-self-lg-baseline {
    align-self: baseline !important;
  }

  ._7F6L5G_align-self-lg-stretch {
    align-self: stretch !important;
  }

  ._7F6L5G_order-lg-first {
    order: -1 !important;
  }

  ._7F6L5G_order-lg-0 {
    order: 0 !important;
  }

  ._7F6L5G_order-lg-1 {
    order: 1 !important;
  }

  ._7F6L5G_order-lg-2 {
    order: 2 !important;
  }

  ._7F6L5G_order-lg-3 {
    order: 3 !important;
  }

  ._7F6L5G_order-lg-4 {
    order: 4 !important;
  }

  ._7F6L5G_order-lg-5 {
    order: 5 !important;
  }

  ._7F6L5G_order-lg-last {
    order: 6 !important;
  }

  ._7F6L5G_m-lg-0 {
    margin: 0 !important;
  }

  ._7F6L5G_m-lg-1 {
    margin: .25rem !important;
  }

  ._7F6L5G_m-lg-2 {
    margin: .5rem !important;
  }

  ._7F6L5G_m-lg-3 {
    margin: 1rem !important;
  }

  ._7F6L5G_m-lg-4 {
    margin: 1.5rem !important;
  }

  ._7F6L5G_m-lg-5 {
    margin: 3rem !important;
  }

  ._7F6L5G_m-lg-auto {
    margin: auto !important;
  }

  ._7F6L5G_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._7F6L5G_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._7F6L5G_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._7F6L5G_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._7F6L5G_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._7F6L5G_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._7F6L5G_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._7F6L5G_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._7F6L5G_mt-lg-0 {
    margin-top: 0 !important;
  }

  ._7F6L5G_mt-lg-1 {
    margin-top: .25rem !important;
  }

  ._7F6L5G_mt-lg-2 {
    margin-top: .5rem !important;
  }

  ._7F6L5G_mt-lg-3 {
    margin-top: 1rem !important;
  }

  ._7F6L5G_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  ._7F6L5G_mt-lg-5 {
    margin-top: 3rem !important;
  }

  ._7F6L5G_mt-lg-auto {
    margin-top: auto !important;
  }

  ._7F6L5G_me-lg-0 {
    margin-right: 0 !important;
  }

  ._7F6L5G_me-lg-1 {
    margin-right: .25rem !important;
  }

  ._7F6L5G_me-lg-2 {
    margin-right: .5rem !important;
  }

  ._7F6L5G_me-lg-3 {
    margin-right: 1rem !important;
  }

  ._7F6L5G_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_me-lg-5 {
    margin-right: 3rem !important;
  }

  ._7F6L5G_me-lg-auto {
    margin-right: auto !important;
  }

  ._7F6L5G_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  ._7F6L5G_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_mb-lg-auto {
    margin-bottom: auto !important;
  }

  ._7F6L5G_ms-lg-0 {
    margin-left: 0 !important;
  }

  ._7F6L5G_ms-lg-1 {
    margin-left: .25rem !important;
  }

  ._7F6L5G_ms-lg-2 {
    margin-left: .5rem !important;
  }

  ._7F6L5G_ms-lg-3 {
    margin-left: 1rem !important;
  }

  ._7F6L5G_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  ._7F6L5G_ms-lg-5 {
    margin-left: 3rem !important;
  }

  ._7F6L5G_ms-lg-auto {
    margin-left: auto !important;
  }

  ._7F6L5G_p-lg-0 {
    padding: 0 !important;
  }

  ._7F6L5G_p-lg-1 {
    padding: .25rem !important;
  }

  ._7F6L5G_p-lg-2 {
    padding: .5rem !important;
  }

  ._7F6L5G_p-lg-3 {
    padding: 1rem !important;
  }

  ._7F6L5G_p-lg-4 {
    padding: 1.5rem !important;
  }

  ._7F6L5G_p-lg-5 {
    padding: 3rem !important;
  }

  ._7F6L5G_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._7F6L5G_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._7F6L5G_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._7F6L5G_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._7F6L5G_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._7F6L5G_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._7F6L5G_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_pt-lg-0 {
    padding-top: 0 !important;
  }

  ._7F6L5G_pt-lg-1 {
    padding-top: .25rem !important;
  }

  ._7F6L5G_pt-lg-2 {
    padding-top: .5rem !important;
  }

  ._7F6L5G_pt-lg-3 {
    padding-top: 1rem !important;
  }

  ._7F6L5G_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  ._7F6L5G_pt-lg-5 {
    padding-top: 3rem !important;
  }

  ._7F6L5G_pe-lg-0 {
    padding-right: 0 !important;
  }

  ._7F6L5G_pe-lg-1 {
    padding-right: .25rem !important;
  }

  ._7F6L5G_pe-lg-2 {
    padding-right: .5rem !important;
  }

  ._7F6L5G_pe-lg-3 {
    padding-right: 1rem !important;
  }

  ._7F6L5G_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_pe-lg-5 {
    padding-right: 3rem !important;
  }

  ._7F6L5G_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  ._7F6L5G_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_ps-lg-0 {
    padding-left: 0 !important;
  }

  ._7F6L5G_ps-lg-1 {
    padding-left: .25rem !important;
  }

  ._7F6L5G_ps-lg-2 {
    padding-left: .5rem !important;
  }

  ._7F6L5G_ps-lg-3 {
    padding-left: 1rem !important;
  }

  ._7F6L5G_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  ._7F6L5G_ps-lg-5 {
    padding-left: 3rem !important;
  }

  ._7F6L5G_gap-lg-0 {
    gap: 0 !important;
  }

  ._7F6L5G_gap-lg-1 {
    gap: .25rem !important;
  }

  ._7F6L5G_gap-lg-2 {
    gap: .5rem !important;
  }

  ._7F6L5G_gap-lg-3 {
    gap: 1rem !important;
  }

  ._7F6L5G_gap-lg-4 {
    gap: 1.5rem !important;
  }

  ._7F6L5G_gap-lg-5 {
    gap: 3rem !important;
  }

  ._7F6L5G_text-lg-start {
    text-align: left !important;
  }

  ._7F6L5G_text-lg-end {
    text-align: right !important;
  }

  ._7F6L5G_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_float-xl-start {
    float: left !important;
  }

  ._7F6L5G_float-xl-end {
    float: right !important;
  }

  ._7F6L5G_float-xl-none {
    float: none !important;
  }

  ._7F6L5G_d-xl-inline {
    display: inline !important;
  }

  ._7F6L5G_d-xl-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-xl-block {
    display: block !important;
  }

  ._7F6L5G_d-xl-grid {
    display: grid !important;
  }

  ._7F6L5G_d-xl-table {
    display: table !important;
  }

  ._7F6L5G_d-xl-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-xl-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-xl-flex {
    display: flex !important;
  }

  ._7F6L5G_d-xl-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-xl-none {
    display: none !important;
  }

  ._7F6L5G_flex-xl-fill {
    flex: auto !important;
  }

  ._7F6L5G_flex-xl-row {
    flex-direction: row !important;
  }

  ._7F6L5G_flex-xl-column {
    flex-direction: column !important;
  }

  ._7F6L5G_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._7F6L5G_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._7F6L5G_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  ._7F6L5G_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  ._7F6L5G_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._7F6L5G_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._7F6L5G_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  ._7F6L5G_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._7F6L5G_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._7F6L5G_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  ._7F6L5G_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  ._7F6L5G_justify-content-xl-center {
    justify-content: center !important;
  }

  ._7F6L5G_justify-content-xl-between {
    justify-content: space-between !important;
  }

  ._7F6L5G_justify-content-xl-around {
    justify-content: space-around !important;
  }

  ._7F6L5G_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  ._7F6L5G_align-items-xl-start {
    align-items: flex-start !important;
  }

  ._7F6L5G_align-items-xl-end {
    align-items: flex-end !important;
  }

  ._7F6L5G_align-items-xl-center {
    align-items: center !important;
  }

  ._7F6L5G_align-items-xl-baseline {
    align-items: baseline !important;
  }

  ._7F6L5G_align-items-xl-stretch {
    align-items: stretch !important;
  }

  ._7F6L5G_align-content-xl-start {
    align-content: flex-start !important;
  }

  ._7F6L5G_align-content-xl-end {
    align-content: flex-end !important;
  }

  ._7F6L5G_align-content-xl-center {
    align-content: center !important;
  }

  ._7F6L5G_align-content-xl-between {
    align-content: space-between !important;
  }

  ._7F6L5G_align-content-xl-around {
    align-content: space-around !important;
  }

  ._7F6L5G_align-content-xl-stretch {
    align-content: stretch !important;
  }

  ._7F6L5G_align-self-xl-auto {
    align-self: auto !important;
  }

  ._7F6L5G_align-self-xl-start {
    align-self: flex-start !important;
  }

  ._7F6L5G_align-self-xl-end {
    align-self: flex-end !important;
  }

  ._7F6L5G_align-self-xl-center {
    align-self: center !important;
  }

  ._7F6L5G_align-self-xl-baseline {
    align-self: baseline !important;
  }

  ._7F6L5G_align-self-xl-stretch {
    align-self: stretch !important;
  }

  ._7F6L5G_order-xl-first {
    order: -1 !important;
  }

  ._7F6L5G_order-xl-0 {
    order: 0 !important;
  }

  ._7F6L5G_order-xl-1 {
    order: 1 !important;
  }

  ._7F6L5G_order-xl-2 {
    order: 2 !important;
  }

  ._7F6L5G_order-xl-3 {
    order: 3 !important;
  }

  ._7F6L5G_order-xl-4 {
    order: 4 !important;
  }

  ._7F6L5G_order-xl-5 {
    order: 5 !important;
  }

  ._7F6L5G_order-xl-last {
    order: 6 !important;
  }

  ._7F6L5G_m-xl-0 {
    margin: 0 !important;
  }

  ._7F6L5G_m-xl-1 {
    margin: .25rem !important;
  }

  ._7F6L5G_m-xl-2 {
    margin: .5rem !important;
  }

  ._7F6L5G_m-xl-3 {
    margin: 1rem !important;
  }

  ._7F6L5G_m-xl-4 {
    margin: 1.5rem !important;
  }

  ._7F6L5G_m-xl-5 {
    margin: 3rem !important;
  }

  ._7F6L5G_m-xl-auto {
    margin: auto !important;
  }

  ._7F6L5G_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._7F6L5G_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._7F6L5G_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._7F6L5G_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._7F6L5G_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._7F6L5G_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._7F6L5G_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._7F6L5G_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._7F6L5G_mt-xl-0 {
    margin-top: 0 !important;
  }

  ._7F6L5G_mt-xl-1 {
    margin-top: .25rem !important;
  }

  ._7F6L5G_mt-xl-2 {
    margin-top: .5rem !important;
  }

  ._7F6L5G_mt-xl-3 {
    margin-top: 1rem !important;
  }

  ._7F6L5G_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  ._7F6L5G_mt-xl-5 {
    margin-top: 3rem !important;
  }

  ._7F6L5G_mt-xl-auto {
    margin-top: auto !important;
  }

  ._7F6L5G_me-xl-0 {
    margin-right: 0 !important;
  }

  ._7F6L5G_me-xl-1 {
    margin-right: .25rem !important;
  }

  ._7F6L5G_me-xl-2 {
    margin-right: .5rem !important;
  }

  ._7F6L5G_me-xl-3 {
    margin-right: 1rem !important;
  }

  ._7F6L5G_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_me-xl-5 {
    margin-right: 3rem !important;
  }

  ._7F6L5G_me-xl-auto {
    margin-right: auto !important;
  }

  ._7F6L5G_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  ._7F6L5G_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_mb-xl-auto {
    margin-bottom: auto !important;
  }

  ._7F6L5G_ms-xl-0 {
    margin-left: 0 !important;
  }

  ._7F6L5G_ms-xl-1 {
    margin-left: .25rem !important;
  }

  ._7F6L5G_ms-xl-2 {
    margin-left: .5rem !important;
  }

  ._7F6L5G_ms-xl-3 {
    margin-left: 1rem !important;
  }

  ._7F6L5G_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  ._7F6L5G_ms-xl-5 {
    margin-left: 3rem !important;
  }

  ._7F6L5G_ms-xl-auto {
    margin-left: auto !important;
  }

  ._7F6L5G_p-xl-0 {
    padding: 0 !important;
  }

  ._7F6L5G_p-xl-1 {
    padding: .25rem !important;
  }

  ._7F6L5G_p-xl-2 {
    padding: .5rem !important;
  }

  ._7F6L5G_p-xl-3 {
    padding: 1rem !important;
  }

  ._7F6L5G_p-xl-4 {
    padding: 1.5rem !important;
  }

  ._7F6L5G_p-xl-5 {
    padding: 3rem !important;
  }

  ._7F6L5G_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._7F6L5G_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._7F6L5G_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._7F6L5G_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._7F6L5G_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._7F6L5G_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._7F6L5G_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_pt-xl-0 {
    padding-top: 0 !important;
  }

  ._7F6L5G_pt-xl-1 {
    padding-top: .25rem !important;
  }

  ._7F6L5G_pt-xl-2 {
    padding-top: .5rem !important;
  }

  ._7F6L5G_pt-xl-3 {
    padding-top: 1rem !important;
  }

  ._7F6L5G_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  ._7F6L5G_pt-xl-5 {
    padding-top: 3rem !important;
  }

  ._7F6L5G_pe-xl-0 {
    padding-right: 0 !important;
  }

  ._7F6L5G_pe-xl-1 {
    padding-right: .25rem !important;
  }

  ._7F6L5G_pe-xl-2 {
    padding-right: .5rem !important;
  }

  ._7F6L5G_pe-xl-3 {
    padding-right: 1rem !important;
  }

  ._7F6L5G_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_pe-xl-5 {
    padding-right: 3rem !important;
  }

  ._7F6L5G_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  ._7F6L5G_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_ps-xl-0 {
    padding-left: 0 !important;
  }

  ._7F6L5G_ps-xl-1 {
    padding-left: .25rem !important;
  }

  ._7F6L5G_ps-xl-2 {
    padding-left: .5rem !important;
  }

  ._7F6L5G_ps-xl-3 {
    padding-left: 1rem !important;
  }

  ._7F6L5G_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  ._7F6L5G_ps-xl-5 {
    padding-left: 3rem !important;
  }

  ._7F6L5G_gap-xl-0 {
    gap: 0 !important;
  }

  ._7F6L5G_gap-xl-1 {
    gap: .25rem !important;
  }

  ._7F6L5G_gap-xl-2 {
    gap: .5rem !important;
  }

  ._7F6L5G_gap-xl-3 {
    gap: 1rem !important;
  }

  ._7F6L5G_gap-xl-4 {
    gap: 1.5rem !important;
  }

  ._7F6L5G_gap-xl-5 {
    gap: 3rem !important;
  }

  ._7F6L5G_text-xl-start {
    text-align: left !important;
  }

  ._7F6L5G_text-xl-end {
    text-align: right !important;
  }

  ._7F6L5G_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  ._7F6L5G_float-xxl-start {
    float: left !important;
  }

  ._7F6L5G_float-xxl-end {
    float: right !important;
  }

  ._7F6L5G_float-xxl-none {
    float: none !important;
  }

  ._7F6L5G_d-xxl-inline {
    display: inline !important;
  }

  ._7F6L5G_d-xxl-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-xxl-block {
    display: block !important;
  }

  ._7F6L5G_d-xxl-grid {
    display: grid !important;
  }

  ._7F6L5G_d-xxl-table {
    display: table !important;
  }

  ._7F6L5G_d-xxl-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-xxl-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-xxl-flex {
    display: flex !important;
  }

  ._7F6L5G_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-xxl-none {
    display: none !important;
  }

  ._7F6L5G_flex-xxl-fill {
    flex: auto !important;
  }

  ._7F6L5G_flex-xxl-row {
    flex-direction: row !important;
  }

  ._7F6L5G_flex-xxl-column {
    flex-direction: column !important;
  }

  ._7F6L5G_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._7F6L5G_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._7F6L5G_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  ._7F6L5G_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  ._7F6L5G_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._7F6L5G_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._7F6L5G_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  ._7F6L5G_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._7F6L5G_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._7F6L5G_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  ._7F6L5G_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  ._7F6L5G_justify-content-xxl-center {
    justify-content: center !important;
  }

  ._7F6L5G_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  ._7F6L5G_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  ._7F6L5G_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  ._7F6L5G_align-items-xxl-start {
    align-items: flex-start !important;
  }

  ._7F6L5G_align-items-xxl-end {
    align-items: flex-end !important;
  }

  ._7F6L5G_align-items-xxl-center {
    align-items: center !important;
  }

  ._7F6L5G_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  ._7F6L5G_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  ._7F6L5G_align-content-xxl-start {
    align-content: flex-start !important;
  }

  ._7F6L5G_align-content-xxl-end {
    align-content: flex-end !important;
  }

  ._7F6L5G_align-content-xxl-center {
    align-content: center !important;
  }

  ._7F6L5G_align-content-xxl-between {
    align-content: space-between !important;
  }

  ._7F6L5G_align-content-xxl-around {
    align-content: space-around !important;
  }

  ._7F6L5G_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  ._7F6L5G_align-self-xxl-auto {
    align-self: auto !important;
  }

  ._7F6L5G_align-self-xxl-start {
    align-self: flex-start !important;
  }

  ._7F6L5G_align-self-xxl-end {
    align-self: flex-end !important;
  }

  ._7F6L5G_align-self-xxl-center {
    align-self: center !important;
  }

  ._7F6L5G_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  ._7F6L5G_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  ._7F6L5G_order-xxl-first {
    order: -1 !important;
  }

  ._7F6L5G_order-xxl-0 {
    order: 0 !important;
  }

  ._7F6L5G_order-xxl-1 {
    order: 1 !important;
  }

  ._7F6L5G_order-xxl-2 {
    order: 2 !important;
  }

  ._7F6L5G_order-xxl-3 {
    order: 3 !important;
  }

  ._7F6L5G_order-xxl-4 {
    order: 4 !important;
  }

  ._7F6L5G_order-xxl-5 {
    order: 5 !important;
  }

  ._7F6L5G_order-xxl-last {
    order: 6 !important;
  }

  ._7F6L5G_m-xxl-0 {
    margin: 0 !important;
  }

  ._7F6L5G_m-xxl-1 {
    margin: .25rem !important;
  }

  ._7F6L5G_m-xxl-2 {
    margin: .5rem !important;
  }

  ._7F6L5G_m-xxl-3 {
    margin: 1rem !important;
  }

  ._7F6L5G_m-xxl-4 {
    margin: 1.5rem !important;
  }

  ._7F6L5G_m-xxl-5 {
    margin: 3rem !important;
  }

  ._7F6L5G_m-xxl-auto {
    margin: auto !important;
  }

  ._7F6L5G_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._7F6L5G_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._7F6L5G_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._7F6L5G_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._7F6L5G_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._7F6L5G_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._7F6L5G_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._7F6L5G_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._7F6L5G_mt-xxl-0 {
    margin-top: 0 !important;
  }

  ._7F6L5G_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  ._7F6L5G_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  ._7F6L5G_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  ._7F6L5G_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  ._7F6L5G_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  ._7F6L5G_mt-xxl-auto {
    margin-top: auto !important;
  }

  ._7F6L5G_me-xxl-0 {
    margin-right: 0 !important;
  }

  ._7F6L5G_me-xxl-1 {
    margin-right: .25rem !important;
  }

  ._7F6L5G_me-xxl-2 {
    margin-right: .5rem !important;
  }

  ._7F6L5G_me-xxl-3 {
    margin-right: 1rem !important;
  }

  ._7F6L5G_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  ._7F6L5G_me-xxl-5 {
    margin-right: 3rem !important;
  }

  ._7F6L5G_me-xxl-auto {
    margin-right: auto !important;
  }

  ._7F6L5G_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  ._7F6L5G_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  ._7F6L5G_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  ._7F6L5G_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  ._7F6L5G_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._7F6L5G_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  ._7F6L5G_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  ._7F6L5G_ms-xxl-0 {
    margin-left: 0 !important;
  }

  ._7F6L5G_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  ._7F6L5G_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  ._7F6L5G_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  ._7F6L5G_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  ._7F6L5G_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  ._7F6L5G_ms-xxl-auto {
    margin-left: auto !important;
  }

  ._7F6L5G_p-xxl-0 {
    padding: 0 !important;
  }

  ._7F6L5G_p-xxl-1 {
    padding: .25rem !important;
  }

  ._7F6L5G_p-xxl-2 {
    padding: .5rem !important;
  }

  ._7F6L5G_p-xxl-3 {
    padding: 1rem !important;
  }

  ._7F6L5G_p-xxl-4 {
    padding: 1.5rem !important;
  }

  ._7F6L5G_p-xxl-5 {
    padding: 3rem !important;
  }

  ._7F6L5G_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._7F6L5G_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._7F6L5G_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._7F6L5G_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._7F6L5G_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._7F6L5G_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._7F6L5G_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_pt-xxl-0 {
    padding-top: 0 !important;
  }

  ._7F6L5G_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  ._7F6L5G_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  ._7F6L5G_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  ._7F6L5G_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  ._7F6L5G_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  ._7F6L5G_pe-xxl-0 {
    padding-right: 0 !important;
  }

  ._7F6L5G_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  ._7F6L5G_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  ._7F6L5G_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  ._7F6L5G_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  ._7F6L5G_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  ._7F6L5G_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  ._7F6L5G_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  ._7F6L5G_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  ._7F6L5G_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  ._7F6L5G_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._7F6L5G_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  ._7F6L5G_ps-xxl-0 {
    padding-left: 0 !important;
  }

  ._7F6L5G_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  ._7F6L5G_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  ._7F6L5G_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  ._7F6L5G_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  ._7F6L5G_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  ._7F6L5G_gap-xxl-0 {
    gap: 0 !important;
  }

  ._7F6L5G_gap-xxl-1 {
    gap: .25rem !important;
  }

  ._7F6L5G_gap-xxl-2 {
    gap: .5rem !important;
  }

  ._7F6L5G_gap-xxl-3 {
    gap: 1rem !important;
  }

  ._7F6L5G_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  ._7F6L5G_gap-xxl-5 {
    gap: 3rem !important;
  }

  ._7F6L5G_text-xxl-start {
    text-align: left !important;
  }

  ._7F6L5G_text-xxl-end {
    text-align: right !important;
  }

  ._7F6L5G_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._7F6L5G_fs-1 {
    font-size: 2.5rem !important;
  }

  ._7F6L5G_fs-2 {
    font-size: 2rem !important;
  }

  ._7F6L5G_fs-3 {
    font-size: 1.75rem !important;
  }

  ._7F6L5G_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  ._7F6L5G_d-print-inline {
    display: inline !important;
  }

  ._7F6L5G_d-print-inline-block {
    display: inline-block !important;
  }

  ._7F6L5G_d-print-block {
    display: block !important;
  }

  ._7F6L5G_d-print-grid {
    display: grid !important;
  }

  ._7F6L5G_d-print-table {
    display: table !important;
  }

  ._7F6L5G_d-print-table-row {
    display: table-row !important;
  }

  ._7F6L5G_d-print-table-cell {
    display: table-cell !important;
  }

  ._7F6L5G_d-print-flex {
    display: flex !important;
  }

  ._7F6L5G_d-print-inline-flex {
    display: inline-flex !important;
  }

  ._7F6L5G_d-print-none {
    display: none !important;
  }
}

._7F6L5G_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

._7F6L5G_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

._7F6L5G_nav-tabs ._7F6L5G_nav-item ._7F6L5G_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

._7F6L5G_nav-tabs ._7F6L5G_nav-item ._7F6L5G_nav-link._7F6L5G_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

._7F6L5G_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#_7F6L5G_root {
  height: 100%;
}

._7F6L5G_highcharts-credits {
  display: none !important;
}

h3, ._7F6L5G_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

._7F6L5G_modal ._7F6L5G_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table._7F6L5G_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table._7F6L5G_table thead tr th._7F6L5G_col-actions {
  width: 110px !important;
}

table._7F6L5G_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table._7F6L5G_table tbody tr._7F6L5G_deleting {
  background-color: #ff323233 !important;
}

._7F6L5G_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

._7F6L5G_btn-sm, ._7F6L5G_btn-group-sm > ._7F6L5G_btn {
  font-size: .6rem !important;
}

._7F6L5G_modal {
  background-color: #0a0a0a4d;
}

._7F6L5G_btn:focus {
  box-shadow: none !important;
}

._7F6L5G_content-filter ._7F6L5G_react-datepicker-wrapper {
  width: auto !important;
}

._7F6L5G_content-filter ._7F6L5G_react-datepicker-wrapper ._7F6L5G_form-control {
  font-size: .85rem !important;
}

._7F6L5G_content-filter ._7F6L5G_input-group-text, ._7F6L5G_content-filter ._7F6L5G_btn {
  height: 2.15rem !important;
}

._7F6L5G_content-filter ._7F6L5G_btn {
  border-color: #ccc;
}

._7F6L5G_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

._7F6L5G_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

._7F6L5G_table > :not(:first-child) {
  border-top: unset !important;
}

._7F6L5G_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

._7F6L5G_highcharts-series._7F6L5G_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

._7F6L5G_expandable {
  transition: all .5s;
}

._7F6L5G_expandable > ._7F6L5G_expander-icon {
  cursor: pointer;
}

._7F6L5G_expandable > ._7F6L5G_expander-icon:hover {
  color: #328cfa66;
}

._7F6L5G_expandable._7F6L5G_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  ._7F6L5G_modal ._7F6L5G_modal-xl {
    width: 100% !important;
  }

  ._7F6L5G_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

._7F6L5G_d-none, ._7F6L5G_d-block {
  transition: all .5s;
}

._7F6L5G_card {
  background: #fff !important;
  border-radius: 12px !important;
}

._7F6L5G_icon {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  display: flex;
}

._7F6L5G_title {
  color: #616371;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

._7F6L5G_value {
  color: #1e2034;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.h7iXXq_ldsBars {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.h7iXXq_ldsBars div {
  width: 16px;
  background: #bbb;
  animation: 1.2s cubic-bezier(0, .5, .5, 1) infinite h7iXXq_lds-bars;
  display: inline-block;
  position: absolute;
  left: 8px;
}

.h7iXXq_ldsBars div:nth-child(1) {
  animation-delay: -.24s;
  left: 8px;
}

.h7iXXq_ldsBars div:nth-child(2) {
  animation-delay: -.12s;
  left: 32px;
}

.h7iXXq_ldsBars div:nth-child(3) {
  animation-delay: 0s;
  left: 56px;
}

@keyframes h7iXXq_lds-bars {
  0% {
    height: 64px;
    top: 8px;
  }

  50%, 100% {
    height: 32px;
    top: 24px;
  }
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .vUmmzq_h6, h5, .vUmmzq_h5, h4, .vUmmzq_h4, h3, .vUmmzq_h3, h2, .vUmmzq_h2, h1, .vUmmzq_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .vUmmzq_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .vUmmzq_h1 {
    font-size: 2.5rem;
  }
}

h2, .vUmmzq_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .vUmmzq_h2 {
    font-size: 2rem;
  }
}

h3, .vUmmzq_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .vUmmzq_h3 {
    font-size: 1.75rem;
  }
}

h4, .vUmmzq_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .vUmmzq_h4 {
    font-size: 1.5rem;
  }
}

h5, .vUmmzq_h5 {
  font-size: 1.25rem;
}

h6, .vUmmzq_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .vUmmzq_small {
  font-size: .875em;
}

mark, .vUmmzq_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.vUmmzq_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.vUmmzq_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-1 {
    font-size: 5rem;
  }
}

.vUmmzq_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-2 {
    font-size: 4.5rem;
  }
}

.vUmmzq_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-3 {
    font-size: 4rem;
  }
}

.vUmmzq_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-4 {
    font-size: 3.5rem;
  }
}

.vUmmzq_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-5 {
    font-size: 3rem;
  }
}

.vUmmzq_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .vUmmzq_display-6 {
    font-size: 2.5rem;
  }
}

.vUmmzq_list-unstyled, .vUmmzq_list-inline {
  padding-left: 0;
  list-style: none;
}

.vUmmzq_list-inline-item {
  display: inline-block;
}

.vUmmzq_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.vUmmzq_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.vUmmzq_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.vUmmzq_blockquote > :last-child {
  margin-bottom: 0;
}

.vUmmzq_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.vUmmzq_blockquote-footer:before {
  content: "— ";
}

.vUmmzq_img-fluid {
  max-width: 100%;
  height: auto;
}

.vUmmzq_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.vUmmzq_figure {
  display: inline-block;
}

.vUmmzq_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.vUmmzq_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.vUmmzq_container, .vUmmzq_container-fluid, .vUmmzq_container-xxl, .vUmmzq_container-xl, .vUmmzq_container-lg, .vUmmzq_container-md, .vUmmzq_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .vUmmzq_container-sm, .vUmmzq_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .vUmmzq_container-md, .vUmmzq_container-sm, .vUmmzq_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .vUmmzq_container-lg, .vUmmzq_container-md, .vUmmzq_container-sm, .vUmmzq_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_container-xl, .vUmmzq_container-lg, .vUmmzq_container-md, .vUmmzq_container-sm, .vUmmzq_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_container-xxl, .vUmmzq_container-xl, .vUmmzq_container-lg, .vUmmzq_container-md, .vUmmzq_container-sm, .vUmmzq_container {
    max-width: 1320px;
  }
}

.vUmmzq_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.vUmmzq_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.vUmmzq_col {
  flex: 1 0;
}

.vUmmzq_row-cols-auto > * {
  width: auto;
  flex: none;
}

.vUmmzq_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.vUmmzq_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.vUmmzq_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.vUmmzq_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.vUmmzq_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.vUmmzq_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.vUmmzq_col-auto {
  width: auto;
  flex: none;
}

.vUmmzq_col-1 {
  width: 8.33333%;
  flex: none;
}

.vUmmzq_col-2 {
  width: 16.6667%;
  flex: none;
}

.vUmmzq_col-3 {
  width: 25%;
  flex: none;
}

.vUmmzq_col-4 {
  width: 33.3333%;
  flex: none;
}

.vUmmzq_col-5 {
  width: 41.6667%;
  flex: none;
}

.vUmmzq_col-6 {
  width: 50%;
  flex: none;
}

.vUmmzq_col-7 {
  width: 58.3333%;
  flex: none;
}

.vUmmzq_col-8 {
  width: 66.6667%;
  flex: none;
}

.vUmmzq_col-9 {
  width: 75%;
  flex: none;
}

.vUmmzq_col-10 {
  width: 83.3333%;
  flex: none;
}

.vUmmzq_col-11 {
  width: 91.6667%;
  flex: none;
}

.vUmmzq_col-12 {
  width: 100%;
  flex: none;
}

.vUmmzq_offset-1 {
  margin-left: 8.33333%;
}

.vUmmzq_offset-2 {
  margin-left: 16.6667%;
}

.vUmmzq_offset-3 {
  margin-left: 25%;
}

.vUmmzq_offset-4 {
  margin-left: 33.3333%;
}

.vUmmzq_offset-5 {
  margin-left: 41.6667%;
}

.vUmmzq_offset-6 {
  margin-left: 50%;
}

.vUmmzq_offset-7 {
  margin-left: 58.3333%;
}

.vUmmzq_offset-8 {
  margin-left: 66.6667%;
}

.vUmmzq_offset-9 {
  margin-left: 75%;
}

.vUmmzq_offset-10 {
  margin-left: 83.3333%;
}

.vUmmzq_offset-11 {
  margin-left: 91.6667%;
}

.vUmmzq_g-0, .vUmmzq_gx-0 {
  --bs-gutter-x: 0;
}

.vUmmzq_g-0, .vUmmzq_gy-0 {
  --bs-gutter-y: 0;
}

.vUmmzq_g-1, .vUmmzq_gx-1 {
  --bs-gutter-x: .25rem;
}

.vUmmzq_g-1, .vUmmzq_gy-1 {
  --bs-gutter-y: .25rem;
}

.vUmmzq_g-2, .vUmmzq_gx-2 {
  --bs-gutter-x: .5rem;
}

.vUmmzq_g-2, .vUmmzq_gy-2 {
  --bs-gutter-y: .5rem;
}

.vUmmzq_g-3, .vUmmzq_gx-3 {
  --bs-gutter-x: 1rem;
}

.vUmmzq_g-3, .vUmmzq_gy-3 {
  --bs-gutter-y: 1rem;
}

.vUmmzq_g-4, .vUmmzq_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.vUmmzq_g-4, .vUmmzq_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.vUmmzq_g-5, .vUmmzq_gx-5 {
  --bs-gutter-x: 3rem;
}

.vUmmzq_g-5, .vUmmzq_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .vUmmzq_col-sm {
    flex: 1 0;
  }

  .vUmmzq_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .vUmmzq_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .vUmmzq_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .vUmmzq_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .vUmmzq_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .vUmmzq_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-sm-auto {
    width: auto;
    flex: none;
  }

  .vUmmzq_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .vUmmzq_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .vUmmzq_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .vUmmzq_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .vUmmzq_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .vUmmzq_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .vUmmzq_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .vUmmzq_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .vUmmzq_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .vUmmzq_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .vUmmzq_offset-sm-0 {
    margin-left: 0;
  }

  .vUmmzq_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .vUmmzq_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .vUmmzq_offset-sm-3 {
    margin-left: 25%;
  }

  .vUmmzq_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .vUmmzq_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .vUmmzq_offset-sm-6 {
    margin-left: 50%;
  }

  .vUmmzq_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .vUmmzq_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .vUmmzq_offset-sm-9 {
    margin-left: 75%;
  }

  .vUmmzq_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .vUmmzq_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .vUmmzq_g-sm-0, .vUmmzq_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .vUmmzq_g-sm-0, .vUmmzq_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .vUmmzq_g-sm-1, .vUmmzq_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .vUmmzq_g-sm-1, .vUmmzq_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .vUmmzq_g-sm-2, .vUmmzq_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .vUmmzq_g-sm-2, .vUmmzq_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .vUmmzq_g-sm-3, .vUmmzq_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .vUmmzq_g-sm-3, .vUmmzq_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .vUmmzq_g-sm-4, .vUmmzq_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .vUmmzq_g-sm-4, .vUmmzq_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .vUmmzq_g-sm-5, .vUmmzq_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .vUmmzq_g-sm-5, .vUmmzq_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .vUmmzq_col-md {
    flex: 1 0;
  }

  .vUmmzq_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .vUmmzq_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .vUmmzq_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .vUmmzq_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .vUmmzq_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .vUmmzq_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-md-auto {
    width: auto;
    flex: none;
  }

  .vUmmzq_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .vUmmzq_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-md-3 {
    width: 25%;
    flex: none;
  }

  .vUmmzq_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .vUmmzq_col-md-6 {
    width: 50%;
    flex: none;
  }

  .vUmmzq_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .vUmmzq_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .vUmmzq_col-md-9 {
    width: 75%;
    flex: none;
  }

  .vUmmzq_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .vUmmzq_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .vUmmzq_col-md-12 {
    width: 100%;
    flex: none;
  }

  .vUmmzq_offset-md-0 {
    margin-left: 0;
  }

  .vUmmzq_offset-md-1 {
    margin-left: 8.33333%;
  }

  .vUmmzq_offset-md-2 {
    margin-left: 16.6667%;
  }

  .vUmmzq_offset-md-3 {
    margin-left: 25%;
  }

  .vUmmzq_offset-md-4 {
    margin-left: 33.3333%;
  }

  .vUmmzq_offset-md-5 {
    margin-left: 41.6667%;
  }

  .vUmmzq_offset-md-6 {
    margin-left: 50%;
  }

  .vUmmzq_offset-md-7 {
    margin-left: 58.3333%;
  }

  .vUmmzq_offset-md-8 {
    margin-left: 66.6667%;
  }

  .vUmmzq_offset-md-9 {
    margin-left: 75%;
  }

  .vUmmzq_offset-md-10 {
    margin-left: 83.3333%;
  }

  .vUmmzq_offset-md-11 {
    margin-left: 91.6667%;
  }

  .vUmmzq_g-md-0, .vUmmzq_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .vUmmzq_g-md-0, .vUmmzq_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .vUmmzq_g-md-1, .vUmmzq_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .vUmmzq_g-md-1, .vUmmzq_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .vUmmzq_g-md-2, .vUmmzq_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .vUmmzq_g-md-2, .vUmmzq_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .vUmmzq_g-md-3, .vUmmzq_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .vUmmzq_g-md-3, .vUmmzq_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .vUmmzq_g-md-4, .vUmmzq_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .vUmmzq_g-md-4, .vUmmzq_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .vUmmzq_g-md-5, .vUmmzq_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .vUmmzq_g-md-5, .vUmmzq_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .vUmmzq_col-lg {
    flex: 1 0;
  }

  .vUmmzq_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .vUmmzq_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .vUmmzq_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .vUmmzq_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .vUmmzq_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .vUmmzq_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-lg-auto {
    width: auto;
    flex: none;
  }

  .vUmmzq_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .vUmmzq_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .vUmmzq_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .vUmmzq_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .vUmmzq_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .vUmmzq_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .vUmmzq_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .vUmmzq_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .vUmmzq_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .vUmmzq_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .vUmmzq_offset-lg-0 {
    margin-left: 0;
  }

  .vUmmzq_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .vUmmzq_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .vUmmzq_offset-lg-3 {
    margin-left: 25%;
  }

  .vUmmzq_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .vUmmzq_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .vUmmzq_offset-lg-6 {
    margin-left: 50%;
  }

  .vUmmzq_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .vUmmzq_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .vUmmzq_offset-lg-9 {
    margin-left: 75%;
  }

  .vUmmzq_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .vUmmzq_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .vUmmzq_g-lg-0, .vUmmzq_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .vUmmzq_g-lg-0, .vUmmzq_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .vUmmzq_g-lg-1, .vUmmzq_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .vUmmzq_g-lg-1, .vUmmzq_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .vUmmzq_g-lg-2, .vUmmzq_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .vUmmzq_g-lg-2, .vUmmzq_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .vUmmzq_g-lg-3, .vUmmzq_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .vUmmzq_g-lg-3, .vUmmzq_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .vUmmzq_g-lg-4, .vUmmzq_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .vUmmzq_g-lg-4, .vUmmzq_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .vUmmzq_g-lg-5, .vUmmzq_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .vUmmzq_g-lg-5, .vUmmzq_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_col-xl {
    flex: 1 0;
  }

  .vUmmzq_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .vUmmzq_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .vUmmzq_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .vUmmzq_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .vUmmzq_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .vUmmzq_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-xl-auto {
    width: auto;
    flex: none;
  }

  .vUmmzq_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .vUmmzq_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .vUmmzq_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .vUmmzq_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .vUmmzq_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .vUmmzq_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .vUmmzq_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .vUmmzq_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .vUmmzq_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .vUmmzq_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .vUmmzq_offset-xl-0 {
    margin-left: 0;
  }

  .vUmmzq_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .vUmmzq_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .vUmmzq_offset-xl-3 {
    margin-left: 25%;
  }

  .vUmmzq_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .vUmmzq_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .vUmmzq_offset-xl-6 {
    margin-left: 50%;
  }

  .vUmmzq_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .vUmmzq_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .vUmmzq_offset-xl-9 {
    margin-left: 75%;
  }

  .vUmmzq_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .vUmmzq_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .vUmmzq_g-xl-0, .vUmmzq_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .vUmmzq_g-xl-0, .vUmmzq_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .vUmmzq_g-xl-1, .vUmmzq_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .vUmmzq_g-xl-1, .vUmmzq_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .vUmmzq_g-xl-2, .vUmmzq_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .vUmmzq_g-xl-2, .vUmmzq_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .vUmmzq_g-xl-3, .vUmmzq_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .vUmmzq_g-xl-3, .vUmmzq_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .vUmmzq_g-xl-4, .vUmmzq_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .vUmmzq_g-xl-4, .vUmmzq_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .vUmmzq_g-xl-5, .vUmmzq_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .vUmmzq_g-xl-5, .vUmmzq_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_col-xxl {
    flex: 1 0;
  }

  .vUmmzq_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .vUmmzq_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .vUmmzq_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .vUmmzq_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .vUmmzq_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .vUmmzq_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .vUmmzq_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .vUmmzq_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .vUmmzq_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .vUmmzq_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .vUmmzq_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .vUmmzq_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .vUmmzq_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .vUmmzq_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .vUmmzq_offset-xxl-0 {
    margin-left: 0;
  }

  .vUmmzq_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .vUmmzq_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .vUmmzq_offset-xxl-3 {
    margin-left: 25%;
  }

  .vUmmzq_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .vUmmzq_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .vUmmzq_offset-xxl-6 {
    margin-left: 50%;
  }

  .vUmmzq_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .vUmmzq_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .vUmmzq_offset-xxl-9 {
    margin-left: 75%;
  }

  .vUmmzq_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .vUmmzq_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .vUmmzq_g-xxl-0, .vUmmzq_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .vUmmzq_g-xxl-0, .vUmmzq_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .vUmmzq_g-xxl-1, .vUmmzq_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .vUmmzq_g-xxl-1, .vUmmzq_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .vUmmzq_g-xxl-2, .vUmmzq_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .vUmmzq_g-xxl-2, .vUmmzq_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .vUmmzq_g-xxl-3, .vUmmzq_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .vUmmzq_g-xxl-3, .vUmmzq_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .vUmmzq_g-xxl-4, .vUmmzq_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .vUmmzq_g-xxl-4, .vUmmzq_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .vUmmzq_g-xxl-5, .vUmmzq_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .vUmmzq_g-xxl-5, .vUmmzq_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.vUmmzq_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.vUmmzq_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.vUmmzq_table > tbody {
  vertical-align: inherit;
}

.vUmmzq_table > thead {
  vertical-align: bottom;
}

.vUmmzq_table-group-divider {
  border-top: 2px solid;
}

.vUmmzq_caption-top {
  caption-side: top;
}

.vUmmzq_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.vUmmzq_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.vUmmzq_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.vUmmzq_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.vUmmzq_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.vUmmzq_table-striped > tbody > tr:nth-of-type(2n+1) > *, .vUmmzq_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.vUmmzq_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.vUmmzq_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.vUmmzq_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.vUmmzq_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .vUmmzq_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .vUmmzq_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .vUmmzq_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .vUmmzq_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .vUmmzq_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.vUmmzq_form-label {
  margin-bottom: .5rem;
}

.vUmmzq_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.vUmmzq_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.vUmmzq_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.vUmmzq_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.vUmmzq_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-control {
    transition: none;
  }
}

.vUmmzq_form-control[type="file"] {
  overflow: hidden;
}

.vUmmzq_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.vUmmzq_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.vUmmzq_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.vUmmzq_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.vUmmzq_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.vUmmzq_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-control::file-selector-button {
    transition: none;
  }
}

.vUmmzq_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.vUmmzq_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.vUmmzq_form-control-plaintext:focus {
  outline: 0;
}

.vUmmzq_form-control-plaintext.vUmmzq_form-control-sm, .vUmmzq_form-control-plaintext.vUmmzq_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.vUmmzq_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.vUmmzq_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.vUmmzq_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.vUmmzq_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.vUmmzq_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.vUmmzq_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.vUmmzq_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.vUmmzq_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.vUmmzq_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.vUmmzq_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.vUmmzq_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.vUmmzq_form-control-color.vUmmzq_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.vUmmzq_form-control-color.vUmmzq_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.vUmmzq_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-select {
    transition: none;
  }
}

.vUmmzq_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.vUmmzq_form-select[multiple], .vUmmzq_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.vUmmzq_form-select:disabled {
  background-color: #e9ecef;
}

.vUmmzq_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.vUmmzq_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.vUmmzq_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.vUmmzq_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.vUmmzq_form-check .vUmmzq_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.vUmmzq_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.vUmmzq_form-check-reverse .vUmmzq_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.vUmmzq_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.vUmmzq_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.vUmmzq_form-check-input[type="radio"] {
  border-radius: 50%;
}

.vUmmzq_form-check-input:active {
  filter: brightness(90%);
}

.vUmmzq_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.vUmmzq_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.vUmmzq_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.vUmmzq_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.vUmmzq_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.vUmmzq_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.vUmmzq_form-check-input[disabled] ~ .vUmmzq_form-check-label, .vUmmzq_form-check-input:disabled ~ .vUmmzq_form-check-label {
  cursor: default;
  opacity: .5;
}

.vUmmzq_form-switch {
  padding-left: 2.5em;
}

.vUmmzq_form-switch .vUmmzq_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-switch .vUmmzq_form-check-input {
    transition: none;
  }
}

.vUmmzq_form-switch .vUmmzq_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.vUmmzq_form-switch .vUmmzq_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.vUmmzq_form-switch.vUmmzq_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.vUmmzq_form-switch.vUmmzq_form-check-reverse .vUmmzq_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.vUmmzq_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.vUmmzq_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.vUmmzq_btn-check[disabled] + .vUmmzq_btn, .vUmmzq_btn-check:disabled + .vUmmzq_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.vUmmzq_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.vUmmzq_form-range:focus {
  outline: 0;
}

.vUmmzq_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.vUmmzq_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.vUmmzq_form-range::-moz-focus-outer {
  border: 0;
}

.vUmmzq_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.vUmmzq_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.vUmmzq_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.vUmmzq_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-range::-moz-range-thumb {
    transition: none;
  }
}

.vUmmzq_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.vUmmzq_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.vUmmzq_form-range:disabled {
  pointer-events: none;
}

.vUmmzq_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.vUmmzq_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.vUmmzq_form-floating {
  position: relative;
}

.vUmmzq_form-floating > .vUmmzq_form-control, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext, .vUmmzq_form-floating > .vUmmzq_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.vUmmzq_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_form-floating > label {
    transition: none;
  }
}

.vUmmzq_form-floating > .vUmmzq_form-control, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext {
  padding: 1rem .75rem;
}

.vUmmzq_form-floating > .vUmmzq_form-control::placeholder, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext::placeholder {
  color: #0000;
}

.vUmmzq_form-floating > .vUmmzq_form-control:focus, .vUmmzq_form-floating > .vUmmzq_form-control:not(:placeholder-shown), .vUmmzq_form-floating > .vUmmzq_form-control-plaintext:focus, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.vUmmzq_form-floating > .vUmmzq_form-control:-webkit-autofill, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.vUmmzq_form-floating > .vUmmzq_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.vUmmzq_form-floating > .vUmmzq_form-control:focus ~ label, .vUmmzq_form-floating > .vUmmzq_form-control:not(:placeholder-shown) ~ label, .vUmmzq_form-floating > .vUmmzq_form-control-plaintext ~ label, .vUmmzq_form-floating > .vUmmzq_form-select ~ label, .vUmmzq_form-floating > .vUmmzq_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.vUmmzq_form-floating > .vUmmzq_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.vUmmzq_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.vUmmzq_input-group > .vUmmzq_form-control, .vUmmzq_input-group > .vUmmzq_form-select, .vUmmzq_input-group > .vUmmzq_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.vUmmzq_input-group > .vUmmzq_form-control:focus, .vUmmzq_input-group > .vUmmzq_form-select:focus, .vUmmzq_input-group > .vUmmzq_form-floating:focus-within {
  z-index: 5;
}

.vUmmzq_input-group .vUmmzq_btn {
  z-index: 2;
  position: relative;
}

.vUmmzq_input-group .vUmmzq_btn:focus {
  z-index: 5;
}

.vUmmzq_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.vUmmzq_input-group-lg > .vUmmzq_form-control, .vUmmzq_input-group-lg > .vUmmzq_form-select, .vUmmzq_input-group-lg > .vUmmzq_input-group-text, .vUmmzq_input-group-lg > .vUmmzq_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.vUmmzq_input-group-sm > .vUmmzq_form-control, .vUmmzq_input-group-sm > .vUmmzq_form-select, .vUmmzq_input-group-sm > .vUmmzq_input-group-text, .vUmmzq_input-group-sm > .vUmmzq_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.vUmmzq_input-group-lg > .vUmmzq_form-select, .vUmmzq_input-group-sm > .vUmmzq_form-select {
  padding-right: 3rem;
}

.vUmmzq_input-group:not(.vUmmzq_has-validation) > :not(:last-child):not(.vUmmzq_dropdown-toggle):not(.vUmmzq_dropdown-menu):not(.vUmmzq_form-floating), .vUmmzq_input-group:not(.vUmmzq_has-validation) > .vUmmzq_dropdown-toggle:nth-last-child(n+3), .vUmmzq_input-group:not(.vUmmzq_has-validation) > .vUmmzq_form-floating:not(:last-child) > .vUmmzq_form-control, .vUmmzq_input-group:not(.vUmmzq_has-validation) > .vUmmzq_form-floating:not(:last-child) > .vUmmzq_form-select, .vUmmzq_input-group.vUmmzq_has-validation > :nth-last-child(n+3):not(.vUmmzq_dropdown-toggle):not(.vUmmzq_dropdown-menu):not(.vUmmzq_form-floating), .vUmmzq_input-group.vUmmzq_has-validation > .vUmmzq_dropdown-toggle:nth-last-child(n+4), .vUmmzq_input-group.vUmmzq_has-validation > .vUmmzq_form-floating:nth-last-child(n+3) > .vUmmzq_form-control, .vUmmzq_input-group.vUmmzq_has-validation > .vUmmzq_form-floating:nth-last-child(n+3) > .vUmmzq_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.vUmmzq_input-group > :not(:first-child):not(.vUmmzq_dropdown-menu):not(.vUmmzq_valid-tooltip):not(.vUmmzq_valid-feedback):not(.vUmmzq_invalid-tooltip):not(.vUmmzq_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.vUmmzq_input-group > .vUmmzq_form-floating:not(:first-child) > .vUmmzq_form-control, .vUmmzq_input-group > .vUmmzq_form-floating:not(:first-child) > .vUmmzq_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.vUmmzq_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.vUmmzq_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.vUmmzq_was-validated :valid ~ .vUmmzq_valid-feedback, .vUmmzq_was-validated :valid ~ .vUmmzq_valid-tooltip, .vUmmzq_is-valid ~ .vUmmzq_valid-feedback, .vUmmzq_is-valid ~ .vUmmzq_valid-tooltip {
  display: block;
}

.vUmmzq_was-validated .vUmmzq_form-control:valid, .vUmmzq_form-control.vUmmzq_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.vUmmzq_was-validated .vUmmzq_form-control:valid:focus, .vUmmzq_form-control.vUmmzq_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.vUmmzq_was-validated textarea.vUmmzq_form-control:valid, textarea.vUmmzq_form-control.vUmmzq_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.vUmmzq_was-validated .vUmmzq_form-select:valid, .vUmmzq_form-select.vUmmzq_is-valid {
  border-color: #198754;
}

.vUmmzq_was-validated .vUmmzq_form-select:valid:not([multiple]):not([size]), .vUmmzq_was-validated .vUmmzq_form-select:valid:not([multiple])[size="1"], .vUmmzq_form-select.vUmmzq_is-valid:not([multiple]):not([size]), .vUmmzq_form-select.vUmmzq_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.vUmmzq_was-validated .vUmmzq_form-select:valid:focus, .vUmmzq_form-select.vUmmzq_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.vUmmzq_was-validated .vUmmzq_form-control-color:valid, .vUmmzq_form-control-color.vUmmzq_is-valid {
  width: calc(1.5em + 3.75rem);
}

.vUmmzq_was-validated .vUmmzq_form-check-input:valid, .vUmmzq_form-check-input.vUmmzq_is-valid {
  border-color: #198754;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:valid:checked, .vUmmzq_form-check-input.vUmmzq_is-valid:checked {
  background-color: #198754;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:valid:focus, .vUmmzq_form-check-input.vUmmzq_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:valid ~ .vUmmzq_form-check-label, .vUmmzq_form-check-input.vUmmzq_is-valid ~ .vUmmzq_form-check-label {
  color: #198754;
}

.vUmmzq_form-check-inline .vUmmzq_form-check-input ~ .vUmmzq_valid-feedback {
  margin-left: .5em;
}

.vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-control:not(:focus):valid, .vUmmzq_input-group > .vUmmzq_form-control:not(:focus).vUmmzq_is-valid, .vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-select:not(:focus):valid, .vUmmzq_input-group > .vUmmzq_form-select:not(:focus).vUmmzq_is-valid, .vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-floating:not(:focus-within):valid, .vUmmzq_input-group > .vUmmzq_form-floating:not(:focus-within).vUmmzq_is-valid {
  z-index: 3;
}

.vUmmzq_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.vUmmzq_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.vUmmzq_was-validated :invalid ~ .vUmmzq_invalid-feedback, .vUmmzq_was-validated :invalid ~ .vUmmzq_invalid-tooltip, .vUmmzq_is-invalid ~ .vUmmzq_invalid-feedback, .vUmmzq_is-invalid ~ .vUmmzq_invalid-tooltip {
  display: block;
}

.vUmmzq_was-validated .vUmmzq_form-control:invalid, .vUmmzq_form-control.vUmmzq_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.vUmmzq_was-validated .vUmmzq_form-control:invalid:focus, .vUmmzq_form-control.vUmmzq_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.vUmmzq_was-validated textarea.vUmmzq_form-control:invalid, textarea.vUmmzq_form-control.vUmmzq_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.vUmmzq_was-validated .vUmmzq_form-select:invalid, .vUmmzq_form-select.vUmmzq_is-invalid {
  border-color: #dc3545;
}

.vUmmzq_was-validated .vUmmzq_form-select:invalid:not([multiple]):not([size]), .vUmmzq_was-validated .vUmmzq_form-select:invalid:not([multiple])[size="1"], .vUmmzq_form-select.vUmmzq_is-invalid:not([multiple]):not([size]), .vUmmzq_form-select.vUmmzq_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.vUmmzq_was-validated .vUmmzq_form-select:invalid:focus, .vUmmzq_form-select.vUmmzq_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.vUmmzq_was-validated .vUmmzq_form-control-color:invalid, .vUmmzq_form-control-color.vUmmzq_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.vUmmzq_was-validated .vUmmzq_form-check-input:invalid, .vUmmzq_form-check-input.vUmmzq_is-invalid {
  border-color: #dc3545;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:invalid:checked, .vUmmzq_form-check-input.vUmmzq_is-invalid:checked {
  background-color: #dc3545;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:invalid:focus, .vUmmzq_form-check-input.vUmmzq_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.vUmmzq_was-validated .vUmmzq_form-check-input:invalid ~ .vUmmzq_form-check-label, .vUmmzq_form-check-input.vUmmzq_is-invalid ~ .vUmmzq_form-check-label {
  color: #dc3545;
}

.vUmmzq_form-check-inline .vUmmzq_form-check-input ~ .vUmmzq_invalid-feedback {
  margin-left: .5em;
}

.vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-control:not(:focus):invalid, .vUmmzq_input-group > .vUmmzq_form-control:not(:focus).vUmmzq_is-invalid, .vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-select:not(:focus):invalid, .vUmmzq_input-group > .vUmmzq_form-select:not(:focus).vUmmzq_is-invalid, .vUmmzq_was-validated .vUmmzq_input-group > .vUmmzq_form-floating:not(:focus-within):invalid, .vUmmzq_input-group > .vUmmzq_form-floating:not(:focus-within).vUmmzq_is-invalid {
  z-index: 4;
}

.vUmmzq_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_btn {
    transition: none;
  }
}

.vUmmzq_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.vUmmzq_btn-check + .vUmmzq_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.vUmmzq_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.vUmmzq_btn-check:focus-visible + .vUmmzq_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.vUmmzq_btn-check:checked + .vUmmzq_btn, :not(.vUmmzq_btn-check) + .vUmmzq_btn:active, .vUmmzq_btn:first-child:active, .vUmmzq_btn.vUmmzq_active, .vUmmzq_btn.vUmmzq_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.vUmmzq_btn-check:checked + .vUmmzq_btn:focus-visible, :not(.vUmmzq_btn-check) + .vUmmzq_btn:active:focus-visible, .vUmmzq_btn:first-child:active:focus-visible, .vUmmzq_btn.vUmmzq_active:focus-visible, .vUmmzq_btn.vUmmzq_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.vUmmzq_btn:disabled, .vUmmzq_btn.vUmmzq_disabled, fieldset:disabled .vUmmzq_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.vUmmzq_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.vUmmzq_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.vUmmzq_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.vUmmzq_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.vUmmzq_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.vUmmzq_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.vUmmzq_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.vUmmzq_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.vUmmzq_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.vUmmzq_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.vUmmzq_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.vUmmzq_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.vUmmzq_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.vUmmzq_btn-lg, .vUmmzq_btn-group-lg > .vUmmzq_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.vUmmzq_btn-sm, .vUmmzq_btn-group-sm > .vUmmzq_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.vUmmzq_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_fade {
    transition: none;
  }
}

.vUmmzq_fade:not(.vUmmzq_show) {
  opacity: 0;
}

.vUmmzq_collapse:not(.vUmmzq_show) {
  display: none;
}

.vUmmzq_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_collapsing {
    transition: none;
  }
}

.vUmmzq_collapsing.vUmmzq_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_collapsing.vUmmzq_collapse-horizontal {
    transition: none;
  }
}

.vUmmzq_dropup, .vUmmzq_dropend, .vUmmzq_dropdown, .vUmmzq_dropstart, .vUmmzq_dropup-center, .vUmmzq_dropdown-center {
  position: relative;
}

.vUmmzq_dropdown-toggle {
  white-space: nowrap;
}

.vUmmzq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.vUmmzq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.vUmmzq_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.vUmmzq_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.vUmmzq_dropdown-menu-start {
  --bs-position: start;
}

.vUmmzq_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.vUmmzq_dropdown-menu-end {
  --bs-position: end;
}

.vUmmzq_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .vUmmzq_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .vUmmzq_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .vUmmzq_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .vUmmzq_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .vUmmzq_dropdown-menu-md-start {
    --bs-position: start;
  }

  .vUmmzq_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .vUmmzq_dropdown-menu-md-end {
    --bs-position: end;
  }

  .vUmmzq_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .vUmmzq_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .vUmmzq_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .vUmmzq_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .vUmmzq_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .vUmmzq_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .vUmmzq_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .vUmmzq_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .vUmmzq_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .vUmmzq_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .vUmmzq_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.vUmmzq_dropup .vUmmzq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.vUmmzq_dropup .vUmmzq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.vUmmzq_dropup .vUmmzq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.vUmmzq_dropend .vUmmzq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.vUmmzq_dropend .vUmmzq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.vUmmzq_dropend .vUmmzq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.vUmmzq_dropend .vUmmzq_dropdown-toggle:after {
  vertical-align: 0;
}

.vUmmzq_dropstart .vUmmzq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.vUmmzq_dropstart .vUmmzq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.vUmmzq_dropstart .vUmmzq_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.vUmmzq_dropstart .vUmmzq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.vUmmzq_dropstart .vUmmzq_dropdown-toggle:before {
  vertical-align: 0;
}

.vUmmzq_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.vUmmzq_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.vUmmzq_dropdown-item:hover, .vUmmzq_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.vUmmzq_dropdown-item.vUmmzq_active, .vUmmzq_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.vUmmzq_dropdown-item.vUmmzq_disabled, .vUmmzq_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.vUmmzq_dropdown-menu.vUmmzq_show {
  display: block;
}

.vUmmzq_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.vUmmzq_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.vUmmzq_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.vUmmzq_btn-group, .vUmmzq_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.vUmmzq_btn-group > .vUmmzq_btn, .vUmmzq_btn-group-vertical > .vUmmzq_btn {
  flex: auto;
  position: relative;
}

.vUmmzq_btn-group > .vUmmzq_btn-check:checked + .vUmmzq_btn, .vUmmzq_btn-group > .vUmmzq_btn-check:focus + .vUmmzq_btn, .vUmmzq_btn-group > .vUmmzq_btn:hover, .vUmmzq_btn-group > .vUmmzq_btn:focus, .vUmmzq_btn-group > .vUmmzq_btn:active, .vUmmzq_btn-group > .vUmmzq_btn.vUmmzq_active, .vUmmzq_btn-group-vertical > .vUmmzq_btn-check:checked + .vUmmzq_btn, .vUmmzq_btn-group-vertical > .vUmmzq_btn-check:focus + .vUmmzq_btn, .vUmmzq_btn-group-vertical > .vUmmzq_btn:hover, .vUmmzq_btn-group-vertical > .vUmmzq_btn:focus, .vUmmzq_btn-group-vertical > .vUmmzq_btn:active, .vUmmzq_btn-group-vertical > .vUmmzq_btn.vUmmzq_active {
  z-index: 1;
}

.vUmmzq_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.vUmmzq_btn-toolbar .vUmmzq_input-group {
  width: auto;
}

.vUmmzq_btn-group {
  border-radius: .375rem;
}

.vUmmzq_btn-group > :not(.vUmmzq_btn-check:first-child) + .vUmmzq_btn, .vUmmzq_btn-group > .vUmmzq_btn-group:not(:first-child) {
  margin-left: -1px;
}

.vUmmzq_btn-group > .vUmmzq_btn:not(:last-child):not(.vUmmzq_dropdown-toggle), .vUmmzq_btn-group > .vUmmzq_btn.vUmmzq_dropdown-toggle-split:first-child, .vUmmzq_btn-group > .vUmmzq_btn-group:not(:last-child) > .vUmmzq_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.vUmmzq_btn-group > .vUmmzq_btn:nth-child(n+3), .vUmmzq_btn-group > :not(.vUmmzq_btn-check) + .vUmmzq_btn, .vUmmzq_btn-group > .vUmmzq_btn-group:not(:first-child) > .vUmmzq_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.vUmmzq_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.vUmmzq_dropdown-toggle-split:after, .vUmmzq_dropup .vUmmzq_dropdown-toggle-split:after, .vUmmzq_dropend .vUmmzq_dropdown-toggle-split:after {
  margin-left: 0;
}

.vUmmzq_dropstart .vUmmzq_dropdown-toggle-split:before {
  margin-right: 0;
}

.vUmmzq_btn-sm + .vUmmzq_dropdown-toggle-split, .vUmmzq_btn-group-sm > .vUmmzq_btn + .vUmmzq_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.vUmmzq_btn-lg + .vUmmzq_dropdown-toggle-split, .vUmmzq_btn-group-lg > .vUmmzq_btn + .vUmmzq_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.vUmmzq_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.vUmmzq_btn-group-vertical > .vUmmzq_btn, .vUmmzq_btn-group-vertical > .vUmmzq_btn-group {
  width: 100%;
}

.vUmmzq_btn-group-vertical > .vUmmzq_btn:not(:first-child), .vUmmzq_btn-group-vertical > .vUmmzq_btn-group:not(:first-child) {
  margin-top: -1px;
}

.vUmmzq_btn-group-vertical > .vUmmzq_btn:not(:last-child):not(.vUmmzq_dropdown-toggle), .vUmmzq_btn-group-vertical > .vUmmzq_btn-group:not(:last-child) > .vUmmzq_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.vUmmzq_btn-group-vertical > .vUmmzq_btn ~ .vUmmzq_btn, .vUmmzq_btn-group-vertical > .vUmmzq_btn-group:not(:first-child) > .vUmmzq_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vUmmzq_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.vUmmzq_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_nav-link {
    transition: none;
  }
}

.vUmmzq_nav-link:hover, .vUmmzq_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.vUmmzq_nav-link.vUmmzq_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.vUmmzq_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.vUmmzq_nav-tabs .vUmmzq_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.vUmmzq_nav-tabs .vUmmzq_nav-link:hover, .vUmmzq_nav-tabs .vUmmzq_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.vUmmzq_nav-tabs .vUmmzq_nav-link.vUmmzq_disabled, .vUmmzq_nav-tabs .vUmmzq_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.vUmmzq_nav-tabs .vUmmzq_nav-link.vUmmzq_active, .vUmmzq_nav-tabs .vUmmzq_nav-item.vUmmzq_show .vUmmzq_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.vUmmzq_nav-tabs .vUmmzq_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vUmmzq_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.vUmmzq_nav-pills .vUmmzq_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.vUmmzq_nav-pills .vUmmzq_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.vUmmzq_nav-pills .vUmmzq_nav-link.vUmmzq_active, .vUmmzq_nav-pills .vUmmzq_show > .vUmmzq_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.vUmmzq_nav-fill > .vUmmzq_nav-link, .vUmmzq_nav-fill .vUmmzq_nav-item {
  text-align: center;
  flex: auto;
}

.vUmmzq_nav-justified > .vUmmzq_nav-link, .vUmmzq_nav-justified .vUmmzq_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.vUmmzq_nav-fill .vUmmzq_nav-item .vUmmzq_nav-link, .vUmmzq_nav-justified .vUmmzq_nav-item .vUmmzq_nav-link {
  width: 100%;
}

.vUmmzq_tab-content > .vUmmzq_tab-pane {
  display: none;
}

.vUmmzq_tab-content > .vUmmzq_active {
  display: block;
}

.vUmmzq_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.vUmmzq_navbar > .vUmmzq_container, .vUmmzq_navbar > .vUmmzq_container-fluid, .vUmmzq_navbar > .vUmmzq_container-sm, .vUmmzq_navbar > .vUmmzq_container-md, .vUmmzq_navbar > .vUmmzq_container-lg, .vUmmzq_navbar > .vUmmzq_container-xl, .vUmmzq_navbar > .vUmmzq_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vUmmzq_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.vUmmzq_navbar-brand:hover, .vUmmzq_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.vUmmzq_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.vUmmzq_navbar-nav .vUmmzq_show > .vUmmzq_nav-link, .vUmmzq_navbar-nav .vUmmzq_nav-link.vUmmzq_active {
  color: var(--bs-navbar-active-color);
}

.vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
  position: static;
}

.vUmmzq_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.vUmmzq_navbar-text a, .vUmmzq_navbar-text a:hover, .vUmmzq_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.vUmmzq_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.vUmmzq_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_navbar-toggler {
    transition: none;
  }
}

.vUmmzq_navbar-toggler:hover {
  text-decoration: none;
}

.vUmmzq_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.vUmmzq_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.vUmmzq_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .vUmmzq_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-nav {
    flex-direction: row;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
    position: absolute;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-nav .vUmmzq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-nav-scroll {
    overflow: visible;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_navbar-toggler {
    display: none;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_navbar-expand-sm .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .vUmmzq_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-nav {
    flex-direction: row;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
    position: absolute;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-nav .vUmmzq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-nav-scroll {
    overflow: visible;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_navbar-toggler {
    display: none;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_navbar-expand-md .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .vUmmzq_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-nav {
    flex-direction: row;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
    position: absolute;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-nav .vUmmzq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-nav-scroll {
    overflow: visible;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_navbar-toggler {
    display: none;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_navbar-expand-lg .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-nav {
    flex-direction: row;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
    position: absolute;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-nav .vUmmzq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-nav-scroll {
    overflow: visible;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_navbar-toggler {
    display: none;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_navbar-expand-xl .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-nav {
    flex-direction: row;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
    position: absolute;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-nav .vUmmzq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-nav-scroll {
    overflow: visible;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_navbar-toggler {
    display: none;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_navbar-expand-xxl .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.vUmmzq_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.vUmmzq_navbar-expand .vUmmzq_navbar-nav {
  flex-direction: row;
}

.vUmmzq_navbar-expand .vUmmzq_navbar-nav .vUmmzq_dropdown-menu {
  position: absolute;
}

.vUmmzq_navbar-expand .vUmmzq_navbar-nav .vUmmzq_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.vUmmzq_navbar-expand .vUmmzq_navbar-nav-scroll {
  overflow: visible;
}

.vUmmzq_navbar-expand .vUmmzq_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.vUmmzq_navbar-expand .vUmmzq_navbar-toggler {
  display: none;
}

.vUmmzq_navbar-expand .vUmmzq_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.vUmmzq_navbar-expand .vUmmzq_offcanvas .vUmmzq_offcanvas-header {
  display: none;
}

.vUmmzq_navbar-expand .vUmmzq_offcanvas .vUmmzq_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.vUmmzq_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.vUmmzq_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.vUmmzq_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.vUmmzq_card > .vUmmzq_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.vUmmzq_card > .vUmmzq_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.vUmmzq_card > .vUmmzq_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.vUmmzq_card > .vUmmzq_card-header + .vUmmzq_list-group, .vUmmzq_card > .vUmmzq_list-group + .vUmmzq_card-footer {
  border-top: 0;
}

.vUmmzq_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.vUmmzq_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.vUmmzq_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.vUmmzq_card-text:last-child {
  margin-bottom: 0;
}

.vUmmzq_card-link + .vUmmzq_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.vUmmzq_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.vUmmzq_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.vUmmzq_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.vUmmzq_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.vUmmzq_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.vUmmzq_card-header-tabs .vUmmzq_nav-link.vUmmzq_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.vUmmzq_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.vUmmzq_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.vUmmzq_card-img, .vUmmzq_card-img-top, .vUmmzq_card-img-bottom {
  width: 100%;
}

.vUmmzq_card-img, .vUmmzq_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.vUmmzq_card-img, .vUmmzq_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.vUmmzq_card-group > .vUmmzq_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .vUmmzq_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .vUmmzq_card-group > .vUmmzq_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card + .vUmmzq_card {
    border-left: 0;
    margin-left: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:last-child) .vUmmzq_card-img-top, .vUmmzq_card-group > .vUmmzq_card:not(:last-child) .vUmmzq_card-header {
    border-top-right-radius: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:last-child) .vUmmzq_card-img-bottom, .vUmmzq_card-group > .vUmmzq_card:not(:last-child) .vUmmzq_card-footer {
    border-bottom-right-radius: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:first-child) .vUmmzq_card-img-top, .vUmmzq_card-group > .vUmmzq_card:not(:first-child) .vUmmzq_card-header {
    border-top-left-radius: 0;
  }

  .vUmmzq_card-group > .vUmmzq_card:not(:first-child) .vUmmzq_card-img-bottom, .vUmmzq_card-group > .vUmmzq_card:not(:first-child) .vUmmzq_card-footer {
    border-bottom-left-radius: 0;
  }
}

.vUmmzq_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.vUmmzq_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_accordion-button {
    transition: none;
  }
}

.vUmmzq_accordion-button:not(.vUmmzq_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.vUmmzq_accordion-button:not(.vUmmzq_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.vUmmzq_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_accordion-button:after {
    transition: none;
  }
}

.vUmmzq_accordion-button:hover {
  z-index: 2;
}

.vUmmzq_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.vUmmzq_accordion-header {
  margin-bottom: 0;
}

.vUmmzq_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.vUmmzq_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.vUmmzq_accordion-item:first-of-type .vUmmzq_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.vUmmzq_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.vUmmzq_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.vUmmzq_accordion-item:last-of-type .vUmmzq_accordion-button.vUmmzq_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.vUmmzq_accordion-item:last-of-type .vUmmzq_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.vUmmzq_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.vUmmzq_accordion-flush .vUmmzq_accordion-collapse {
  border-width: 0;
}

.vUmmzq_accordion-flush .vUmmzq_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.vUmmzq_accordion-flush .vUmmzq_accordion-item:first-child {
  border-top: 0;
}

.vUmmzq_accordion-flush .vUmmzq_accordion-item:last-child {
  border-bottom: 0;
}

.vUmmzq_accordion-flush .vUmmzq_accordion-item .vUmmzq_accordion-button, .vUmmzq_accordion-flush .vUmmzq_accordion-item .vUmmzq_accordion-button.vUmmzq_collapsed {
  border-radius: 0;
}

.vUmmzq_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.vUmmzq_breadcrumb-item + .vUmmzq_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.vUmmzq_breadcrumb-item + .vUmmzq_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.vUmmzq_breadcrumb-item.vUmmzq_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.vUmmzq_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.vUmmzq_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_page-link {
    transition: none;
  }
}

.vUmmzq_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.vUmmzq_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.vUmmzq_page-link.vUmmzq_active, .vUmmzq_active > .vUmmzq_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.vUmmzq_page-link.vUmmzq_disabled, .vUmmzq_disabled > .vUmmzq_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.vUmmzq_page-item:not(:first-child) .vUmmzq_page-link {
  margin-left: -1px;
}

.vUmmzq_page-item:first-child .vUmmzq_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.vUmmzq_page-item:last-child .vUmmzq_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.vUmmzq_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.vUmmzq_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.vUmmzq_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.vUmmzq_badge:empty {
  display: none;
}

.vUmmzq_btn .vUmmzq_badge {
  position: relative;
  top: -1px;
}

.vUmmzq_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.vUmmzq_alert-heading {
  color: inherit;
}

.vUmmzq_alert-link {
  font-weight: 700;
}

.vUmmzq_alert-dismissible {
  padding-right: 3rem;
}

.vUmmzq_alert-dismissible .vUmmzq_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.vUmmzq_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.vUmmzq_alert-primary .vUmmzq_alert-link {
  color: #25224b;
}

.vUmmzq_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.vUmmzq_alert-secondary .vUmmzq_alert-link {
  color: #34383c;
}

.vUmmzq_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.vUmmzq_alert-success .vUmmzq_alert-link {
  color: #0c4128;
}

.vUmmzq_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.vUmmzq_alert-info .vUmmzq_alert-link {
  color: #04414d;
}

.vUmmzq_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.vUmmzq_alert-warning .vUmmzq_alert-link {
  color: #523e02;
}

.vUmmzq_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.vUmmzq_alert-danger .vUmmzq_alert-link {
  color: #6a1a21;
}

.vUmmzq_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.vUmmzq_alert-light .vUmmzq_alert-link {
  color: #4f5050;
}

.vUmmzq_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.vUmmzq_alert-dark .vUmmzq_alert-link {
  color: #101214;
}

@keyframes vUmmzq_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.vUmmzq_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.vUmmzq_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_progress-bar {
    transition: none;
  }
}

.vUmmzq_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.vUmmzq_progress-bar-animated {
  animation: 1s linear infinite vUmmzq_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_progress-bar-animated {
    animation: none;
  }
}

.vUmmzq_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.vUmmzq_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.vUmmzq_list-group-numbered > .vUmmzq_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.vUmmzq_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.vUmmzq_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.vUmmzq_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.vUmmzq_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.vUmmzq_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.vUmmzq_list-group-item.vUmmzq_disabled, .vUmmzq_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.vUmmzq_list-group-item.vUmmzq_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.vUmmzq_list-group-item + .vUmmzq_list-group-item {
  border-top-width: 0;
}

.vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.vUmmzq_list-group-horizontal {
  flex-direction: row;
}

.vUmmzq_list-group-horizontal > .vUmmzq_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.vUmmzq_list-group-horizontal > .vUmmzq_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.vUmmzq_list-group-horizontal > .vUmmzq_list-group-item.vUmmzq_active {
  margin-top: 0;
}

.vUmmzq_list-group-horizontal > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.vUmmzq_list-group-horizontal > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .vUmmzq_list-group-horizontal-sm {
    flex-direction: row;
  }

  .vUmmzq_list-group-horizontal-sm > .vUmmzq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .vUmmzq_list-group-horizontal-sm > .vUmmzq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .vUmmzq_list-group-horizontal-sm > .vUmmzq_list-group-item.vUmmzq_active {
    margin-top: 0;
  }

  .vUmmzq_list-group-horizontal-sm > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .vUmmzq_list-group-horizontal-sm > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .vUmmzq_list-group-horizontal-md {
    flex-direction: row;
  }

  .vUmmzq_list-group-horizontal-md > .vUmmzq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .vUmmzq_list-group-horizontal-md > .vUmmzq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .vUmmzq_list-group-horizontal-md > .vUmmzq_list-group-item.vUmmzq_active {
    margin-top: 0;
  }

  .vUmmzq_list-group-horizontal-md > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .vUmmzq_list-group-horizontal-md > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .vUmmzq_list-group-horizontal-lg {
    flex-direction: row;
  }

  .vUmmzq_list-group-horizontal-lg > .vUmmzq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .vUmmzq_list-group-horizontal-lg > .vUmmzq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .vUmmzq_list-group-horizontal-lg > .vUmmzq_list-group-item.vUmmzq_active {
    margin-top: 0;
  }

  .vUmmzq_list-group-horizontal-lg > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .vUmmzq_list-group-horizontal-lg > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .vUmmzq_list-group-horizontal-xl {
    flex-direction: row;
  }

  .vUmmzq_list-group-horizontal-xl > .vUmmzq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .vUmmzq_list-group-horizontal-xl > .vUmmzq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .vUmmzq_list-group-horizontal-xl > .vUmmzq_list-group-item.vUmmzq_active {
    margin-top: 0;
  }

  .vUmmzq_list-group-horizontal-xl > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .vUmmzq_list-group-horizontal-xl > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .vUmmzq_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .vUmmzq_list-group-horizontal-xxl > .vUmmzq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .vUmmzq_list-group-horizontal-xxl > .vUmmzq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .vUmmzq_list-group-horizontal-xxl > .vUmmzq_list-group-item.vUmmzq_active {
    margin-top: 0;
  }

  .vUmmzq_list-group-horizontal-xxl > .vUmmzq_list-group-item + .vUmmzq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .vUmmzq_list-group-horizontal-xxl > .vUmmzq_list-group-item + .vUmmzq_list-group-item.vUmmzq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.vUmmzq_list-group-flush {
  border-radius: 0;
}

.vUmmzq_list-group-flush > .vUmmzq_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.vUmmzq_list-group-flush > .vUmmzq_list-group-item:last-child {
  border-bottom-width: 0;
}

.vUmmzq_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.vUmmzq_list-group-item-primary.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-primary.vUmmzq_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.vUmmzq_list-group-item-primary.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.vUmmzq_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.vUmmzq_list-group-item-secondary.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-secondary.vUmmzq_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.vUmmzq_list-group-item-secondary.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.vUmmzq_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.vUmmzq_list-group-item-success.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-success.vUmmzq_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.vUmmzq_list-group-item-success.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.vUmmzq_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.vUmmzq_list-group-item-info.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-info.vUmmzq_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.vUmmzq_list-group-item-info.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.vUmmzq_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.vUmmzq_list-group-item-warning.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-warning.vUmmzq_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.vUmmzq_list-group-item-warning.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.vUmmzq_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.vUmmzq_list-group-item-danger.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-danger.vUmmzq_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.vUmmzq_list-group-item-danger.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.vUmmzq_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.vUmmzq_list-group-item-light.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-light.vUmmzq_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.vUmmzq_list-group-item-light.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.vUmmzq_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.vUmmzq_list-group-item-dark.vUmmzq_list-group-item-action:hover, .vUmmzq_list-group-item-dark.vUmmzq_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.vUmmzq_list-group-item-dark.vUmmzq_list-group-item-action.vUmmzq_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.vUmmzq_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.vUmmzq_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.vUmmzq_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.vUmmzq_btn-close:disabled, .vUmmzq_btn-close.vUmmzq_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.vUmmzq_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.vUmmzq_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.vUmmzq_toast.vUmmzq_showing {
  opacity: 0;
}

.vUmmzq_toast:not(.vUmmzq_show) {
  display: none;
}

.vUmmzq_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.vUmmzq_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.vUmmzq_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.vUmmzq_toast-header .vUmmzq_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.vUmmzq_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.vUmmzq_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.vUmmzq_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.vUmmzq_modal.vUmmzq_fade .vUmmzq_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_modal.vUmmzq_fade .vUmmzq_modal-dialog {
    transition: none;
  }
}

.vUmmzq_modal.vUmmzq_show .vUmmzq_modal-dialog {
  transform: none;
}

.vUmmzq_modal.vUmmzq_modal-static .vUmmzq_modal-dialog {
  transform: scale(1.02);
}

.vUmmzq_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.vUmmzq_modal-dialog-scrollable .vUmmzq_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.vUmmzq_modal-dialog-scrollable .vUmmzq_modal-body {
  overflow-y: auto;
}

.vUmmzq_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.vUmmzq_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.vUmmzq_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.vUmmzq_modal-backdrop.vUmmzq_fade {
  opacity: 0;
}

.vUmmzq_modal-backdrop.vUmmzq_show {
  opacity: var(--bs-backdrop-opacity);
}

.vUmmzq_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vUmmzq_modal-header .vUmmzq_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.vUmmzq_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.vUmmzq_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.vUmmzq_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.vUmmzq_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .vUmmzq_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .vUmmzq_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .vUmmzq_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .vUmmzq_modal-lg, .vUmmzq_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.vUmmzq_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.vUmmzq_modal-fullscreen .vUmmzq_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.vUmmzq_modal-fullscreen .vUmmzq_modal-header, .vUmmzq_modal-fullscreen .vUmmzq_modal-footer {
  border-radius: 0;
}

.vUmmzq_modal-fullscreen .vUmmzq_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .vUmmzq_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .vUmmzq_modal-fullscreen-sm-down .vUmmzq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-sm-down .vUmmzq_modal-header, .vUmmzq_modal-fullscreen-sm-down .vUmmzq_modal-footer {
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-sm-down .vUmmzq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .vUmmzq_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .vUmmzq_modal-fullscreen-md-down .vUmmzq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-md-down .vUmmzq_modal-header, .vUmmzq_modal-fullscreen-md-down .vUmmzq_modal-footer {
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-md-down .vUmmzq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .vUmmzq_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .vUmmzq_modal-fullscreen-lg-down .vUmmzq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-lg-down .vUmmzq_modal-header, .vUmmzq_modal-fullscreen-lg-down .vUmmzq_modal-footer {
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-lg-down .vUmmzq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .vUmmzq_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .vUmmzq_modal-fullscreen-xl-down .vUmmzq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-xl-down .vUmmzq_modal-header, .vUmmzq_modal-fullscreen-xl-down .vUmmzq_modal-footer {
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-xl-down .vUmmzq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .vUmmzq_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .vUmmzq_modal-fullscreen-xxl-down .vUmmzq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-xxl-down .vUmmzq_modal-header, .vUmmzq_modal-fullscreen-xxl-down .vUmmzq_modal-footer {
    border-radius: 0;
  }

  .vUmmzq_modal-fullscreen-xxl-down .vUmmzq_modal-body {
    overflow-y: auto;
  }
}

.vUmmzq_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.vUmmzq_tooltip.vUmmzq_show {
  opacity: var(--bs-tooltip-opacity);
}

.vUmmzq_tooltip .vUmmzq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.vUmmzq_tooltip .vUmmzq_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.vUmmzq_bs-tooltip-top .vUmmzq_tooltip-arrow, .vUmmzq_bs-tooltip-auto[data-popper-placement^="top"] .vUmmzq_tooltip-arrow {
  bottom: 0;
}

.vUmmzq_bs-tooltip-top .vUmmzq_tooltip-arrow:before, .vUmmzq_bs-tooltip-auto[data-popper-placement^="top"] .vUmmzq_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.vUmmzq_bs-tooltip-end .vUmmzq_tooltip-arrow, .vUmmzq_bs-tooltip-auto[data-popper-placement^="right"] .vUmmzq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.vUmmzq_bs-tooltip-end .vUmmzq_tooltip-arrow:before, .vUmmzq_bs-tooltip-auto[data-popper-placement^="right"] .vUmmzq_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.vUmmzq_bs-tooltip-bottom .vUmmzq_tooltip-arrow, .vUmmzq_bs-tooltip-auto[data-popper-placement^="bottom"] .vUmmzq_tooltip-arrow {
  top: 0;
}

.vUmmzq_bs-tooltip-bottom .vUmmzq_tooltip-arrow:before, .vUmmzq_bs-tooltip-auto[data-popper-placement^="bottom"] .vUmmzq_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.vUmmzq_bs-tooltip-start .vUmmzq_tooltip-arrow, .vUmmzq_bs-tooltip-auto[data-popper-placement^="left"] .vUmmzq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.vUmmzq_bs-tooltip-start .vUmmzq_tooltip-arrow:before, .vUmmzq_bs-tooltip-auto[data-popper-placement^="left"] .vUmmzq_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.vUmmzq_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.vUmmzq_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.vUmmzq_popover .vUmmzq_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.vUmmzq_popover .vUmmzq_popover-arrow:before, .vUmmzq_popover .vUmmzq_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.vUmmzq_bs-popover-top > .vUmmzq_popover-arrow, .vUmmzq_bs-popover-auto[data-popper-placement^="top"] > .vUmmzq_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.vUmmzq_bs-popover-top > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="top"] > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-top > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="top"] > .vUmmzq_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.vUmmzq_bs-popover-top > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="top"] > .vUmmzq_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.vUmmzq_bs-popover-top > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="top"] > .vUmmzq_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.vUmmzq_bs-popover-end > .vUmmzq_popover-arrow, .vUmmzq_bs-popover-auto[data-popper-placement^="right"] > .vUmmzq_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.vUmmzq_bs-popover-end > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="right"] > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-end > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="right"] > .vUmmzq_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.vUmmzq_bs-popover-end > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="right"] > .vUmmzq_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.vUmmzq_bs-popover-end > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="right"] > .vUmmzq_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.vUmmzq_bs-popover-bottom > .vUmmzq_popover-arrow, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] > .vUmmzq_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.vUmmzq_bs-popover-bottom > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-bottom > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] > .vUmmzq_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.vUmmzq_bs-popover-bottom > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] > .vUmmzq_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.vUmmzq_bs-popover-bottom > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] > .vUmmzq_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.vUmmzq_bs-popover-bottom .vUmmzq_popover-header:before, .vUmmzq_bs-popover-auto[data-popper-placement^="bottom"] .vUmmzq_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.vUmmzq_bs-popover-start > .vUmmzq_popover-arrow, .vUmmzq_bs-popover-auto[data-popper-placement^="left"] > .vUmmzq_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.vUmmzq_bs-popover-start > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="left"] > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-start > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="left"] > .vUmmzq_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.vUmmzq_bs-popover-start > .vUmmzq_popover-arrow:before, .vUmmzq_bs-popover-auto[data-popper-placement^="left"] > .vUmmzq_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.vUmmzq_bs-popover-start > .vUmmzq_popover-arrow:after, .vUmmzq_bs-popover-auto[data-popper-placement^="left"] > .vUmmzq_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.vUmmzq_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.vUmmzq_popover-header:empty {
  display: none;
}

.vUmmzq_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.vUmmzq_carousel {
  position: relative;
}

.vUmmzq_carousel.vUmmzq_pointer-event {
  touch-action: pan-y;
}

.vUmmzq_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.vUmmzq_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.vUmmzq_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_carousel-item {
    transition: none;
  }
}

.vUmmzq_carousel-item.vUmmzq_active, .vUmmzq_carousel-item-next, .vUmmzq_carousel-item-prev {
  display: block;
}

.vUmmzq_carousel-item-next:not(.vUmmzq_carousel-item-start), .vUmmzq_active.vUmmzq_carousel-item-end {
  transform: translateX(100%);
}

.vUmmzq_carousel-item-prev:not(.vUmmzq_carousel-item-end), .vUmmzq_active.vUmmzq_carousel-item-start {
  transform: translateX(-100%);
}

.vUmmzq_carousel-fade .vUmmzq_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.vUmmzq_carousel-fade .vUmmzq_carousel-item.vUmmzq_active, .vUmmzq_carousel-fade .vUmmzq_carousel-item-next.vUmmzq_carousel-item-start, .vUmmzq_carousel-fade .vUmmzq_carousel-item-prev.vUmmzq_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.vUmmzq_carousel-fade .vUmmzq_active.vUmmzq_carousel-item-start, .vUmmzq_carousel-fade .vUmmzq_active.vUmmzq_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_carousel-fade .vUmmzq_active.vUmmzq_carousel-item-start, .vUmmzq_carousel-fade .vUmmzq_active.vUmmzq_carousel-item-end {
    transition: none;
  }
}

.vUmmzq_carousel-control-prev, .vUmmzq_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_carousel-control-prev, .vUmmzq_carousel-control-next {
    transition: none;
  }
}

.vUmmzq_carousel-control-prev:hover, .vUmmzq_carousel-control-prev:focus, .vUmmzq_carousel-control-next:hover, .vUmmzq_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.vUmmzq_carousel-control-prev {
  left: 0;
}

.vUmmzq_carousel-control-next {
  right: 0;
}

.vUmmzq_carousel-control-prev-icon, .vUmmzq_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.vUmmzq_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.vUmmzq_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.vUmmzq_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.vUmmzq_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.vUmmzq_carousel-indicators .vUmmzq_active {
  opacity: 1;
}

.vUmmzq_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.vUmmzq_carousel-dark .vUmmzq_carousel-control-prev-icon, .vUmmzq_carousel-dark .vUmmzq_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.vUmmzq_carousel-dark .vUmmzq_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.vUmmzq_carousel-dark .vUmmzq_carousel-caption {
  color: #000;
}

.vUmmzq_spinner-grow, .vUmmzq_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes vUmmzq_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.vUmmzq_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.vUmmzq_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes vUmmzq_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.vUmmzq_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.vUmmzq_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_spinner-border, .vUmmzq_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.vUmmzq_offcanvas, .vUmmzq_offcanvas-xxl, .vUmmzq_offcanvas-xl, .vUmmzq_offcanvas-lg, .vUmmzq_offcanvas-md, .vUmmzq_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .vUmmzq_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .vUmmzq_offcanvas-sm.vUmmzq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .vUmmzq_offcanvas-sm.vUmmzq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .vUmmzq_offcanvas-sm.vUmmzq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vUmmzq_offcanvas-sm.vUmmzq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .vUmmzq_offcanvas-sm.vUmmzq_showing, .vUmmzq_offcanvas-sm.vUmmzq_show:not(.vUmmzq_hiding) {
    transform: none;
  }

  .vUmmzq_offcanvas-sm.vUmmzq_showing, .vUmmzq_offcanvas-sm.vUmmzq_hiding, .vUmmzq_offcanvas-sm.vUmmzq_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .vUmmzq_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .vUmmzq_offcanvas-sm .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_offcanvas-sm .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .vUmmzq_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .vUmmzq_offcanvas-md.vUmmzq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .vUmmzq_offcanvas-md.vUmmzq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .vUmmzq_offcanvas-md.vUmmzq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vUmmzq_offcanvas-md.vUmmzq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .vUmmzq_offcanvas-md.vUmmzq_showing, .vUmmzq_offcanvas-md.vUmmzq_show:not(.vUmmzq_hiding) {
    transform: none;
  }

  .vUmmzq_offcanvas-md.vUmmzq_showing, .vUmmzq_offcanvas-md.vUmmzq_hiding, .vUmmzq_offcanvas-md.vUmmzq_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .vUmmzq_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .vUmmzq_offcanvas-md .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_offcanvas-md .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .vUmmzq_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .vUmmzq_offcanvas-lg.vUmmzq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .vUmmzq_offcanvas-lg.vUmmzq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .vUmmzq_offcanvas-lg.vUmmzq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vUmmzq_offcanvas-lg.vUmmzq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .vUmmzq_offcanvas-lg.vUmmzq_showing, .vUmmzq_offcanvas-lg.vUmmzq_show:not(.vUmmzq_hiding) {
    transform: none;
  }

  .vUmmzq_offcanvas-lg.vUmmzq_showing, .vUmmzq_offcanvas-lg.vUmmzq_hiding, .vUmmzq_offcanvas-lg.vUmmzq_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .vUmmzq_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .vUmmzq_offcanvas-lg .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_offcanvas-lg .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .vUmmzq_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .vUmmzq_offcanvas-xl.vUmmzq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .vUmmzq_offcanvas-xl.vUmmzq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .vUmmzq_offcanvas-xl.vUmmzq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vUmmzq_offcanvas-xl.vUmmzq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .vUmmzq_offcanvas-xl.vUmmzq_showing, .vUmmzq_offcanvas-xl.vUmmzq_show:not(.vUmmzq_hiding) {
    transform: none;
  }

  .vUmmzq_offcanvas-xl.vUmmzq_showing, .vUmmzq_offcanvas-xl.vUmmzq_hiding, .vUmmzq_offcanvas-xl.vUmmzq_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .vUmmzq_offcanvas-xl .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_offcanvas-xl .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .vUmmzq_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .vUmmzq_offcanvas-xxl.vUmmzq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .vUmmzq_offcanvas-xxl.vUmmzq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .vUmmzq_offcanvas-xxl.vUmmzq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vUmmzq_offcanvas-xxl.vUmmzq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .vUmmzq_offcanvas-xxl.vUmmzq_showing, .vUmmzq_offcanvas-xxl.vUmmzq_show:not(.vUmmzq_hiding) {
    transform: none;
  }

  .vUmmzq_offcanvas-xxl.vUmmzq_showing, .vUmmzq_offcanvas-xxl.vUmmzq_hiding, .vUmmzq_offcanvas-xxl.vUmmzq_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .vUmmzq_offcanvas-xxl .vUmmzq_offcanvas-header {
    display: none;
  }

  .vUmmzq_offcanvas-xxl .vUmmzq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.vUmmzq_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .vUmmzq_offcanvas {
    transition: none;
  }
}

.vUmmzq_offcanvas.vUmmzq_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.vUmmzq_offcanvas.vUmmzq_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.vUmmzq_offcanvas.vUmmzq_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.vUmmzq_offcanvas.vUmmzq_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.vUmmzq_offcanvas.vUmmzq_showing, .vUmmzq_offcanvas.vUmmzq_show:not(.vUmmzq_hiding) {
  transform: none;
}

.vUmmzq_offcanvas.vUmmzq_showing, .vUmmzq_offcanvas.vUmmzq_hiding, .vUmmzq_offcanvas.vUmmzq_show {
  visibility: visible;
}

.vUmmzq_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.vUmmzq_offcanvas-backdrop.vUmmzq_fade {
  opacity: 0;
}

.vUmmzq_offcanvas-backdrop.vUmmzq_show {
  opacity: .5;
}

.vUmmzq_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vUmmzq_offcanvas-header .vUmmzq_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.vUmmzq_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.vUmmzq_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.vUmmzq_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.vUmmzq_placeholder.vUmmzq_btn:before {
  content: "";
  display: inline-block;
}

.vUmmzq_placeholder-xs {
  min-height: .6em;
}

.vUmmzq_placeholder-sm {
  min-height: .8em;
}

.vUmmzq_placeholder-lg {
  min-height: 1.2em;
}

.vUmmzq_placeholder-glow .vUmmzq_placeholder {
  animation: 2s ease-in-out infinite vUmmzq_placeholder-glow;
}

@keyframes vUmmzq_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.vUmmzq_placeholder-wave {
  animation: 2s linear infinite vUmmzq_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes vUmmzq_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.vUmmzq_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.vUmmzq_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.vUmmzq_link-primary {
  color: #4c469c !important;
}

.vUmmzq_link-primary:hover, .vUmmzq_link-primary:focus {
  color: #3d387d !important;
}

.vUmmzq_link-secondary {
  color: #6c757d !important;
}

.vUmmzq_link-secondary:hover, .vUmmzq_link-secondary:focus {
  color: #565e64 !important;
}

.vUmmzq_link-success {
  color: #198754 !important;
}

.vUmmzq_link-success:hover, .vUmmzq_link-success:focus {
  color: #146c43 !important;
}

.vUmmzq_link-info {
  color: #0dcaf0 !important;
}

.vUmmzq_link-info:hover, .vUmmzq_link-info:focus {
  color: #3dd5f3 !important;
}

.vUmmzq_link-warning {
  color: #ffc107 !important;
}

.vUmmzq_link-warning:hover, .vUmmzq_link-warning:focus {
  color: #ffcd39 !important;
}

.vUmmzq_link-danger {
  color: #dc3545 !important;
}

.vUmmzq_link-danger:hover, .vUmmzq_link-danger:focus {
  color: #b02a37 !important;
}

.vUmmzq_link-light {
  color: #f8f9fa !important;
}

.vUmmzq_link-light:hover, .vUmmzq_link-light:focus {
  color: #f9fafb !important;
}

.vUmmzq_link-dark {
  color: #212529 !important;
}

.vUmmzq_link-dark:hover, .vUmmzq_link-dark:focus {
  color: #1a1e21 !important;
}

.vUmmzq_ratio {
  width: 100%;
  position: relative;
}

.vUmmzq_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.vUmmzq_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vUmmzq_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.vUmmzq_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.vUmmzq_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.vUmmzq_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.vUmmzq_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.vUmmzq_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.vUmmzq_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.vUmmzq_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .vUmmzq_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .vUmmzq_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .vUmmzq_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .vUmmzq_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .vUmmzq_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .vUmmzq_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .vUmmzq_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .vUmmzq_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.vUmmzq_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vUmmzq_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.vUmmzq_visually-hidden, .vUmmzq_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.vUmmzq_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.vUmmzq_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vUmmzq_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.vUmmzq_align-baseline {
  vertical-align: baseline !important;
}

.vUmmzq_align-top {
  vertical-align: top !important;
}

.vUmmzq_align-middle {
  vertical-align: middle !important;
}

.vUmmzq_align-bottom {
  vertical-align: bottom !important;
}

.vUmmzq_align-text-bottom {
  vertical-align: text-bottom !important;
}

.vUmmzq_align-text-top {
  vertical-align: text-top !important;
}

.vUmmzq_float-start {
  float: left !important;
}

.vUmmzq_float-end {
  float: right !important;
}

.vUmmzq_float-none {
  float: none !important;
}

.vUmmzq_opacity-0 {
  opacity: 0 !important;
}

.vUmmzq_opacity-25 {
  opacity: .25 !important;
}

.vUmmzq_opacity-50 {
  opacity: .5 !important;
}

.vUmmzq_opacity-75 {
  opacity: .75 !important;
}

.vUmmzq_opacity-100 {
  opacity: 1 !important;
}

.vUmmzq_overflow-auto {
  overflow: auto !important;
}

.vUmmzq_overflow-hidden {
  overflow: hidden !important;
}

.vUmmzq_overflow-visible {
  overflow: visible !important;
}

.vUmmzq_overflow-scroll {
  overflow: scroll !important;
}

.vUmmzq_d-inline {
  display: inline !important;
}

.vUmmzq_d-inline-block {
  display: inline-block !important;
}

.vUmmzq_d-block {
  display: block !important;
}

.vUmmzq_d-grid {
  display: grid !important;
}

.vUmmzq_d-table {
  display: table !important;
}

.vUmmzq_d-table-row {
  display: table-row !important;
}

.vUmmzq_d-table-cell {
  display: table-cell !important;
}

.vUmmzq_d-flex {
  display: flex !important;
}

.vUmmzq_d-inline-flex {
  display: inline-flex !important;
}

.vUmmzq_d-none {
  display: none !important;
}

.vUmmzq_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.vUmmzq_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.vUmmzq_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.vUmmzq_shadow-none {
  box-shadow: none !important;
}

.vUmmzq_position-static {
  position: static !important;
}

.vUmmzq_position-relative {
  position: relative !important;
}

.vUmmzq_position-absolute {
  position: absolute !important;
}

.vUmmzq_position-fixed {
  position: fixed !important;
}

.vUmmzq_position-sticky {
  position: sticky !important;
}

.vUmmzq_top-0 {
  top: 0 !important;
}

.vUmmzq_top-50 {
  top: 50% !important;
}

.vUmmzq_top-100 {
  top: 100% !important;
}

.vUmmzq_bottom-0 {
  bottom: 0 !important;
}

.vUmmzq_bottom-50 {
  bottom: 50% !important;
}

.vUmmzq_bottom-100 {
  bottom: 100% !important;
}

.vUmmzq_start-0 {
  left: 0 !important;
}

.vUmmzq_start-50 {
  left: 50% !important;
}

.vUmmzq_start-100 {
  left: 100% !important;
}

.vUmmzq_end-0 {
  right: 0 !important;
}

.vUmmzq_end-50 {
  right: 50% !important;
}

.vUmmzq_end-100 {
  right: 100% !important;
}

.vUmmzq_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.vUmmzq_translate-middle-x {
  transform: translateX(-50%) !important;
}

.vUmmzq_translate-middle-y {
  transform: translateY(-50%) !important;
}

.vUmmzq_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.vUmmzq_border-0 {
  border: 0 !important;
}

.vUmmzq_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.vUmmzq_border-top-0 {
  border-top: 0 !important;
}

.vUmmzq_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.vUmmzq_border-end-0 {
  border-right: 0 !important;
}

.vUmmzq_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.vUmmzq_border-bottom-0 {
  border-bottom: 0 !important;
}

.vUmmzq_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.vUmmzq_border-start-0 {
  border-left: 0 !important;
}

.vUmmzq_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.vUmmzq_border-1 {
  --bs-border-width: 1px;
}

.vUmmzq_border-2 {
  --bs-border-width: 2px;
}

.vUmmzq_border-3 {
  --bs-border-width: 3px;
}

.vUmmzq_border-4 {
  --bs-border-width: 4px;
}

.vUmmzq_border-5 {
  --bs-border-width: 5px;
}

.vUmmzq_border-opacity-10 {
  --bs-border-opacity: .1;
}

.vUmmzq_border-opacity-25 {
  --bs-border-opacity: .25;
}

.vUmmzq_border-opacity-50 {
  --bs-border-opacity: .5;
}

.vUmmzq_border-opacity-75 {
  --bs-border-opacity: .75;
}

.vUmmzq_border-opacity-100 {
  --bs-border-opacity: 1;
}

.vUmmzq_w-25 {
  width: 25% !important;
}

.vUmmzq_w-50 {
  width: 50% !important;
}

.vUmmzq_w-75 {
  width: 75% !important;
}

.vUmmzq_w-100 {
  width: 100% !important;
}

.vUmmzq_w-auto {
  width: auto !important;
}

.vUmmzq_mw-100 {
  max-width: 100% !important;
}

.vUmmzq_vw-100 {
  width: 100vw !important;
}

.vUmmzq_min-vw-100 {
  min-width: 100vw !important;
}

.vUmmzq_h-25 {
  height: 25% !important;
}

.vUmmzq_h-50 {
  height: 50% !important;
}

.vUmmzq_h-75 {
  height: 75% !important;
}

.vUmmzq_h-100 {
  height: 100% !important;
}

.vUmmzq_h-auto {
  height: auto !important;
}

.vUmmzq_mh-100 {
  max-height: 100% !important;
}

.vUmmzq_vh-100 {
  height: 100vh !important;
}

.vUmmzq_min-vh-100 {
  min-height: 100vh !important;
}

.vUmmzq_flex-fill {
  flex: auto !important;
}

.vUmmzq_flex-row {
  flex-direction: row !important;
}

.vUmmzq_flex-column {
  flex-direction: column !important;
}

.vUmmzq_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.vUmmzq_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.vUmmzq_flex-grow-0 {
  flex-grow: 0 !important;
}

.vUmmzq_flex-grow-1 {
  flex-grow: 1 !important;
}

.vUmmzq_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.vUmmzq_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.vUmmzq_flex-wrap {
  flex-wrap: wrap !important;
}

.vUmmzq_flex-nowrap {
  flex-wrap: nowrap !important;
}

.vUmmzq_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.vUmmzq_justify-content-start {
  justify-content: flex-start !important;
}

.vUmmzq_justify-content-end {
  justify-content: flex-end !important;
}

.vUmmzq_justify-content-center {
  justify-content: center !important;
}

.vUmmzq_justify-content-between {
  justify-content: space-between !important;
}

.vUmmzq_justify-content-around {
  justify-content: space-around !important;
}

.vUmmzq_justify-content-evenly {
  justify-content: space-evenly !important;
}

.vUmmzq_align-items-start {
  align-items: flex-start !important;
}

.vUmmzq_align-items-end {
  align-items: flex-end !important;
}

.vUmmzq_align-items-center {
  align-items: center !important;
}

.vUmmzq_align-items-baseline {
  align-items: baseline !important;
}

.vUmmzq_align-items-stretch {
  align-items: stretch !important;
}

.vUmmzq_align-content-start {
  align-content: flex-start !important;
}

.vUmmzq_align-content-end {
  align-content: flex-end !important;
}

.vUmmzq_align-content-center {
  align-content: center !important;
}

.vUmmzq_align-content-between {
  align-content: space-between !important;
}

.vUmmzq_align-content-around {
  align-content: space-around !important;
}

.vUmmzq_align-content-stretch {
  align-content: stretch !important;
}

.vUmmzq_align-self-auto {
  align-self: auto !important;
}

.vUmmzq_align-self-start {
  align-self: flex-start !important;
}

.vUmmzq_align-self-end {
  align-self: flex-end !important;
}

.vUmmzq_align-self-center {
  align-self: center !important;
}

.vUmmzq_align-self-baseline {
  align-self: baseline !important;
}

.vUmmzq_align-self-stretch {
  align-self: stretch !important;
}

.vUmmzq_order-first {
  order: -1 !important;
}

.vUmmzq_order-0 {
  order: 0 !important;
}

.vUmmzq_order-1 {
  order: 1 !important;
}

.vUmmzq_order-2 {
  order: 2 !important;
}

.vUmmzq_order-3 {
  order: 3 !important;
}

.vUmmzq_order-4 {
  order: 4 !important;
}

.vUmmzq_order-5 {
  order: 5 !important;
}

.vUmmzq_order-last {
  order: 6 !important;
}

.vUmmzq_m-0 {
  margin: 0 !important;
}

.vUmmzq_m-1 {
  margin: .25rem !important;
}

.vUmmzq_m-2 {
  margin: .5rem !important;
}

.vUmmzq_m-3 {
  margin: 1rem !important;
}

.vUmmzq_m-4 {
  margin: 1.5rem !important;
}

.vUmmzq_m-5 {
  margin: 3rem !important;
}

.vUmmzq_m-auto {
  margin: auto !important;
}

.vUmmzq_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.vUmmzq_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.vUmmzq_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.vUmmzq_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.vUmmzq_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.vUmmzq_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.vUmmzq_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.vUmmzq_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.vUmmzq_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.vUmmzq_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.vUmmzq_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.vUmmzq_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.vUmmzq_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.vUmmzq_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.vUmmzq_mt-0 {
  margin-top: 0 !important;
}

.vUmmzq_mt-1 {
  margin-top: .25rem !important;
}

.vUmmzq_mt-2 {
  margin-top: .5rem !important;
}

.vUmmzq_mt-3 {
  margin-top: 1rem !important;
}

.vUmmzq_mt-4 {
  margin-top: 1.5rem !important;
}

.vUmmzq_mt-5 {
  margin-top: 3rem !important;
}

.vUmmzq_mt-auto {
  margin-top: auto !important;
}

.vUmmzq_me-0 {
  margin-right: 0 !important;
}

.vUmmzq_me-1 {
  margin-right: .25rem !important;
}

.vUmmzq_me-2 {
  margin-right: .5rem !important;
}

.vUmmzq_me-3 {
  margin-right: 1rem !important;
}

.vUmmzq_me-4 {
  margin-right: 1.5rem !important;
}

.vUmmzq_me-5 {
  margin-right: 3rem !important;
}

.vUmmzq_me-auto {
  margin-right: auto !important;
}

.vUmmzq_mb-0 {
  margin-bottom: 0 !important;
}

.vUmmzq_mb-1 {
  margin-bottom: .25rem !important;
}

.vUmmzq_mb-2 {
  margin-bottom: .5rem !important;
}

.vUmmzq_mb-3 {
  margin-bottom: 1rem !important;
}

.vUmmzq_mb-4 {
  margin-bottom: 1.5rem !important;
}

.vUmmzq_mb-5 {
  margin-bottom: 3rem !important;
}

.vUmmzq_mb-auto {
  margin-bottom: auto !important;
}

.vUmmzq_ms-0 {
  margin-left: 0 !important;
}

.vUmmzq_ms-1 {
  margin-left: .25rem !important;
}

.vUmmzq_ms-2 {
  margin-left: .5rem !important;
}

.vUmmzq_ms-3 {
  margin-left: 1rem !important;
}

.vUmmzq_ms-4 {
  margin-left: 1.5rem !important;
}

.vUmmzq_ms-5 {
  margin-left: 3rem !important;
}

.vUmmzq_ms-auto {
  margin-left: auto !important;
}

.vUmmzq_p-0 {
  padding: 0 !important;
}

.vUmmzq_p-1 {
  padding: .25rem !important;
}

.vUmmzq_p-2 {
  padding: .5rem !important;
}

.vUmmzq_p-3 {
  padding: 1rem !important;
}

.vUmmzq_p-4 {
  padding: 1.5rem !important;
}

.vUmmzq_p-5 {
  padding: 3rem !important;
}

.vUmmzq_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.vUmmzq_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.vUmmzq_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.vUmmzq_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.vUmmzq_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.vUmmzq_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.vUmmzq_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vUmmzq_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.vUmmzq_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.vUmmzq_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.vUmmzq_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.vUmmzq_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.vUmmzq_pt-0 {
  padding-top: 0 !important;
}

.vUmmzq_pt-1 {
  padding-top: .25rem !important;
}

.vUmmzq_pt-2 {
  padding-top: .5rem !important;
}

.vUmmzq_pt-3 {
  padding-top: 1rem !important;
}

.vUmmzq_pt-4 {
  padding-top: 1.5rem !important;
}

.vUmmzq_pt-5 {
  padding-top: 3rem !important;
}

.vUmmzq_pe-0 {
  padding-right: 0 !important;
}

.vUmmzq_pe-1 {
  padding-right: .25rem !important;
}

.vUmmzq_pe-2 {
  padding-right: .5rem !important;
}

.vUmmzq_pe-3 {
  padding-right: 1rem !important;
}

.vUmmzq_pe-4 {
  padding-right: 1.5rem !important;
}

.vUmmzq_pe-5 {
  padding-right: 3rem !important;
}

.vUmmzq_pb-0 {
  padding-bottom: 0 !important;
}

.vUmmzq_pb-1 {
  padding-bottom: .25rem !important;
}

.vUmmzq_pb-2 {
  padding-bottom: .5rem !important;
}

.vUmmzq_pb-3 {
  padding-bottom: 1rem !important;
}

.vUmmzq_pb-4 {
  padding-bottom: 1.5rem !important;
}

.vUmmzq_pb-5 {
  padding-bottom: 3rem !important;
}

.vUmmzq_ps-0 {
  padding-left: 0 !important;
}

.vUmmzq_ps-1 {
  padding-left: .25rem !important;
}

.vUmmzq_ps-2 {
  padding-left: .5rem !important;
}

.vUmmzq_ps-3 {
  padding-left: 1rem !important;
}

.vUmmzq_ps-4 {
  padding-left: 1.5rem !important;
}

.vUmmzq_ps-5 {
  padding-left: 3rem !important;
}

.vUmmzq_gap-0 {
  gap: 0 !important;
}

.vUmmzq_gap-1 {
  gap: .25rem !important;
}

.vUmmzq_gap-2 {
  gap: .5rem !important;
}

.vUmmzq_gap-3 {
  gap: 1rem !important;
}

.vUmmzq_gap-4 {
  gap: 1.5rem !important;
}

.vUmmzq_gap-5 {
  gap: 3rem !important;
}

.vUmmzq_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.vUmmzq_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.vUmmzq_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.vUmmzq_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.vUmmzq_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.vUmmzq_fs-5 {
  font-size: 1.25rem !important;
}

.vUmmzq_fs-6 {
  font-size: 1rem !important;
}

.vUmmzq_fst-italic {
  font-style: italic !important;
}

.vUmmzq_fst-normal {
  font-style: normal !important;
}

.vUmmzq_fw-light {
  font-weight: 300 !important;
}

.vUmmzq_fw-lighter {
  font-weight: lighter !important;
}

.vUmmzq_fw-normal {
  font-weight: 400 !important;
}

.vUmmzq_fw-bold {
  font-weight: 700 !important;
}

.vUmmzq_fw-semibold {
  font-weight: 600 !important;
}

.vUmmzq_fw-bolder {
  font-weight: bolder !important;
}

.vUmmzq_lh-1 {
  line-height: 1 !important;
}

.vUmmzq_lh-sm {
  line-height: 1.25 !important;
}

.vUmmzq_lh-base {
  line-height: 1.5 !important;
}

.vUmmzq_lh-lg {
  line-height: 2 !important;
}

.vUmmzq_text-start {
  text-align: left !important;
}

.vUmmzq_text-end {
  text-align: right !important;
}

.vUmmzq_text-center {
  text-align: center !important;
}

.vUmmzq_text-decoration-none {
  text-decoration: none !important;
}

.vUmmzq_text-decoration-underline {
  text-decoration: underline !important;
}

.vUmmzq_text-decoration-line-through {
  text-decoration: line-through !important;
}

.vUmmzq_text-lowercase {
  text-transform: lowercase !important;
}

.vUmmzq_text-uppercase {
  text-transform: uppercase !important;
}

.vUmmzq_text-capitalize {
  text-transform: capitalize !important;
}

.vUmmzq_text-wrap {
  white-space: normal !important;
}

.vUmmzq_text-nowrap {
  white-space: nowrap !important;
}

.vUmmzq_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.vUmmzq_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.vUmmzq_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.vUmmzq_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.vUmmzq_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.vUmmzq_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.vUmmzq_text-opacity-25 {
  --bs-text-opacity: .25;
}

.vUmmzq_text-opacity-50 {
  --bs-text-opacity: .5;
}

.vUmmzq_text-opacity-75 {
  --bs-text-opacity: .75;
}

.vUmmzq_text-opacity-100 {
  --bs-text-opacity: 1;
}

.vUmmzq_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.vUmmzq_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.vUmmzq_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.vUmmzq_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.vUmmzq_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.vUmmzq_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.vUmmzq_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.vUmmzq_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.vUmmzq_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.vUmmzq_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.vUmmzq_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.vUmmzq_pe-none {
  pointer-events: none !important;
}

.vUmmzq_pe-auto {
  pointer-events: auto !important;
}

.vUmmzq_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.vUmmzq_rounded-0 {
  border-radius: 0 !important;
}

.vUmmzq_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.vUmmzq_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.vUmmzq_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.vUmmzq_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.vUmmzq_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.vUmmzq_rounded-circle {
  border-radius: 50% !important;
}

.vUmmzq_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.vUmmzq_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.vUmmzq_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.vUmmzq_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.vUmmzq_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.vUmmzq_visible {
  visibility: visible !important;
}

.vUmmzq_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .vUmmzq_float-sm-start {
    float: left !important;
  }

  .vUmmzq_float-sm-end {
    float: right !important;
  }

  .vUmmzq_float-sm-none {
    float: none !important;
  }

  .vUmmzq_d-sm-inline {
    display: inline !important;
  }

  .vUmmzq_d-sm-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-sm-block {
    display: block !important;
  }

  .vUmmzq_d-sm-grid {
    display: grid !important;
  }

  .vUmmzq_d-sm-table {
    display: table !important;
  }

  .vUmmzq_d-sm-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-sm-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-sm-flex {
    display: flex !important;
  }

  .vUmmzq_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-sm-none {
    display: none !important;
  }

  .vUmmzq_flex-sm-fill {
    flex: auto !important;
  }

  .vUmmzq_flex-sm-row {
    flex-direction: row !important;
  }

  .vUmmzq_flex-sm-column {
    flex-direction: column !important;
  }

  .vUmmzq_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .vUmmzq_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .vUmmzq_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .vUmmzq_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .vUmmzq_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .vUmmzq_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .vUmmzq_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .vUmmzq_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .vUmmzq_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .vUmmzq_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .vUmmzq_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .vUmmzq_justify-content-sm-center {
    justify-content: center !important;
  }

  .vUmmzq_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .vUmmzq_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .vUmmzq_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .vUmmzq_align-items-sm-start {
    align-items: flex-start !important;
  }

  .vUmmzq_align-items-sm-end {
    align-items: flex-end !important;
  }

  .vUmmzq_align-items-sm-center {
    align-items: center !important;
  }

  .vUmmzq_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .vUmmzq_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .vUmmzq_align-content-sm-start {
    align-content: flex-start !important;
  }

  .vUmmzq_align-content-sm-end {
    align-content: flex-end !important;
  }

  .vUmmzq_align-content-sm-center {
    align-content: center !important;
  }

  .vUmmzq_align-content-sm-between {
    align-content: space-between !important;
  }

  .vUmmzq_align-content-sm-around {
    align-content: space-around !important;
  }

  .vUmmzq_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .vUmmzq_align-self-sm-auto {
    align-self: auto !important;
  }

  .vUmmzq_align-self-sm-start {
    align-self: flex-start !important;
  }

  .vUmmzq_align-self-sm-end {
    align-self: flex-end !important;
  }

  .vUmmzq_align-self-sm-center {
    align-self: center !important;
  }

  .vUmmzq_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .vUmmzq_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .vUmmzq_order-sm-first {
    order: -1 !important;
  }

  .vUmmzq_order-sm-0 {
    order: 0 !important;
  }

  .vUmmzq_order-sm-1 {
    order: 1 !important;
  }

  .vUmmzq_order-sm-2 {
    order: 2 !important;
  }

  .vUmmzq_order-sm-3 {
    order: 3 !important;
  }

  .vUmmzq_order-sm-4 {
    order: 4 !important;
  }

  .vUmmzq_order-sm-5 {
    order: 5 !important;
  }

  .vUmmzq_order-sm-last {
    order: 6 !important;
  }

  .vUmmzq_m-sm-0 {
    margin: 0 !important;
  }

  .vUmmzq_m-sm-1 {
    margin: .25rem !important;
  }

  .vUmmzq_m-sm-2 {
    margin: .5rem !important;
  }

  .vUmmzq_m-sm-3 {
    margin: 1rem !important;
  }

  .vUmmzq_m-sm-4 {
    margin: 1.5rem !important;
  }

  .vUmmzq_m-sm-5 {
    margin: 3rem !important;
  }

  .vUmmzq_m-sm-auto {
    margin: auto !important;
  }

  .vUmmzq_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .vUmmzq_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .vUmmzq_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .vUmmzq_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .vUmmzq_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .vUmmzq_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .vUmmzq_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .vUmmzq_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .vUmmzq_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .vUmmzq_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .vUmmzq_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .vUmmzq_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .vUmmzq_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .vUmmzq_mt-sm-0 {
    margin-top: 0 !important;
  }

  .vUmmzq_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .vUmmzq_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .vUmmzq_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .vUmmzq_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .vUmmzq_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .vUmmzq_mt-sm-auto {
    margin-top: auto !important;
  }

  .vUmmzq_me-sm-0 {
    margin-right: 0 !important;
  }

  .vUmmzq_me-sm-1 {
    margin-right: .25rem !important;
  }

  .vUmmzq_me-sm-2 {
    margin-right: .5rem !important;
  }

  .vUmmzq_me-sm-3 {
    margin-right: 1rem !important;
  }

  .vUmmzq_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .vUmmzq_me-sm-5 {
    margin-right: 3rem !important;
  }

  .vUmmzq_me-sm-auto {
    margin-right: auto !important;
  }

  .vUmmzq_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .vUmmzq_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .vUmmzq_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .vUmmzq_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .vUmmzq_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .vUmmzq_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .vUmmzq_ms-sm-0 {
    margin-left: 0 !important;
  }

  .vUmmzq_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .vUmmzq_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .vUmmzq_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .vUmmzq_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .vUmmzq_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .vUmmzq_ms-sm-auto {
    margin-left: auto !important;
  }

  .vUmmzq_p-sm-0 {
    padding: 0 !important;
  }

  .vUmmzq_p-sm-1 {
    padding: .25rem !important;
  }

  .vUmmzq_p-sm-2 {
    padding: .5rem !important;
  }

  .vUmmzq_p-sm-3 {
    padding: 1rem !important;
  }

  .vUmmzq_p-sm-4 {
    padding: 1.5rem !important;
  }

  .vUmmzq_p-sm-5 {
    padding: 3rem !important;
  }

  .vUmmzq_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vUmmzq_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .vUmmzq_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .vUmmzq_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .vUmmzq_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .vUmmzq_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .vUmmzq_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vUmmzq_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .vUmmzq_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .vUmmzq_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .vUmmzq_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .vUmmzq_pt-sm-0 {
    padding-top: 0 !important;
  }

  .vUmmzq_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .vUmmzq_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .vUmmzq_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .vUmmzq_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .vUmmzq_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .vUmmzq_pe-sm-0 {
    padding-right: 0 !important;
  }

  .vUmmzq_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .vUmmzq_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .vUmmzq_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .vUmmzq_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .vUmmzq_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .vUmmzq_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .vUmmzq_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .vUmmzq_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .vUmmzq_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .vUmmzq_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .vUmmzq_ps-sm-0 {
    padding-left: 0 !important;
  }

  .vUmmzq_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .vUmmzq_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .vUmmzq_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .vUmmzq_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .vUmmzq_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .vUmmzq_gap-sm-0 {
    gap: 0 !important;
  }

  .vUmmzq_gap-sm-1 {
    gap: .25rem !important;
  }

  .vUmmzq_gap-sm-2 {
    gap: .5rem !important;
  }

  .vUmmzq_gap-sm-3 {
    gap: 1rem !important;
  }

  .vUmmzq_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .vUmmzq_gap-sm-5 {
    gap: 3rem !important;
  }

  .vUmmzq_text-sm-start {
    text-align: left !important;
  }

  .vUmmzq_text-sm-end {
    text-align: right !important;
  }

  .vUmmzq_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .vUmmzq_float-md-start {
    float: left !important;
  }

  .vUmmzq_float-md-end {
    float: right !important;
  }

  .vUmmzq_float-md-none {
    float: none !important;
  }

  .vUmmzq_d-md-inline {
    display: inline !important;
  }

  .vUmmzq_d-md-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-md-block {
    display: block !important;
  }

  .vUmmzq_d-md-grid {
    display: grid !important;
  }

  .vUmmzq_d-md-table {
    display: table !important;
  }

  .vUmmzq_d-md-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-md-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-md-flex {
    display: flex !important;
  }

  .vUmmzq_d-md-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-md-none {
    display: none !important;
  }

  .vUmmzq_flex-md-fill {
    flex: auto !important;
  }

  .vUmmzq_flex-md-row {
    flex-direction: row !important;
  }

  .vUmmzq_flex-md-column {
    flex-direction: column !important;
  }

  .vUmmzq_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .vUmmzq_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .vUmmzq_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .vUmmzq_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .vUmmzq_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .vUmmzq_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .vUmmzq_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .vUmmzq_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .vUmmzq_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .vUmmzq_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .vUmmzq_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .vUmmzq_justify-content-md-center {
    justify-content: center !important;
  }

  .vUmmzq_justify-content-md-between {
    justify-content: space-between !important;
  }

  .vUmmzq_justify-content-md-around {
    justify-content: space-around !important;
  }

  .vUmmzq_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .vUmmzq_align-items-md-start {
    align-items: flex-start !important;
  }

  .vUmmzq_align-items-md-end {
    align-items: flex-end !important;
  }

  .vUmmzq_align-items-md-center {
    align-items: center !important;
  }

  .vUmmzq_align-items-md-baseline {
    align-items: baseline !important;
  }

  .vUmmzq_align-items-md-stretch {
    align-items: stretch !important;
  }

  .vUmmzq_align-content-md-start {
    align-content: flex-start !important;
  }

  .vUmmzq_align-content-md-end {
    align-content: flex-end !important;
  }

  .vUmmzq_align-content-md-center {
    align-content: center !important;
  }

  .vUmmzq_align-content-md-between {
    align-content: space-between !important;
  }

  .vUmmzq_align-content-md-around {
    align-content: space-around !important;
  }

  .vUmmzq_align-content-md-stretch {
    align-content: stretch !important;
  }

  .vUmmzq_align-self-md-auto {
    align-self: auto !important;
  }

  .vUmmzq_align-self-md-start {
    align-self: flex-start !important;
  }

  .vUmmzq_align-self-md-end {
    align-self: flex-end !important;
  }

  .vUmmzq_align-self-md-center {
    align-self: center !important;
  }

  .vUmmzq_align-self-md-baseline {
    align-self: baseline !important;
  }

  .vUmmzq_align-self-md-stretch {
    align-self: stretch !important;
  }

  .vUmmzq_order-md-first {
    order: -1 !important;
  }

  .vUmmzq_order-md-0 {
    order: 0 !important;
  }

  .vUmmzq_order-md-1 {
    order: 1 !important;
  }

  .vUmmzq_order-md-2 {
    order: 2 !important;
  }

  .vUmmzq_order-md-3 {
    order: 3 !important;
  }

  .vUmmzq_order-md-4 {
    order: 4 !important;
  }

  .vUmmzq_order-md-5 {
    order: 5 !important;
  }

  .vUmmzq_order-md-last {
    order: 6 !important;
  }

  .vUmmzq_m-md-0 {
    margin: 0 !important;
  }

  .vUmmzq_m-md-1 {
    margin: .25rem !important;
  }

  .vUmmzq_m-md-2 {
    margin: .5rem !important;
  }

  .vUmmzq_m-md-3 {
    margin: 1rem !important;
  }

  .vUmmzq_m-md-4 {
    margin: 1.5rem !important;
  }

  .vUmmzq_m-md-5 {
    margin: 3rem !important;
  }

  .vUmmzq_m-md-auto {
    margin: auto !important;
  }

  .vUmmzq_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .vUmmzq_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .vUmmzq_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .vUmmzq_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .vUmmzq_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .vUmmzq_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .vUmmzq_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .vUmmzq_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .vUmmzq_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .vUmmzq_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .vUmmzq_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .vUmmzq_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .vUmmzq_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .vUmmzq_mt-md-0 {
    margin-top: 0 !important;
  }

  .vUmmzq_mt-md-1 {
    margin-top: .25rem !important;
  }

  .vUmmzq_mt-md-2 {
    margin-top: .5rem !important;
  }

  .vUmmzq_mt-md-3 {
    margin-top: 1rem !important;
  }

  .vUmmzq_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .vUmmzq_mt-md-5 {
    margin-top: 3rem !important;
  }

  .vUmmzq_mt-md-auto {
    margin-top: auto !important;
  }

  .vUmmzq_me-md-0 {
    margin-right: 0 !important;
  }

  .vUmmzq_me-md-1 {
    margin-right: .25rem !important;
  }

  .vUmmzq_me-md-2 {
    margin-right: .5rem !important;
  }

  .vUmmzq_me-md-3 {
    margin-right: 1rem !important;
  }

  .vUmmzq_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .vUmmzq_me-md-5 {
    margin-right: 3rem !important;
  }

  .vUmmzq_me-md-auto {
    margin-right: auto !important;
  }

  .vUmmzq_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .vUmmzq_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .vUmmzq_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .vUmmzq_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .vUmmzq_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .vUmmzq_mb-md-auto {
    margin-bottom: auto !important;
  }

  .vUmmzq_ms-md-0 {
    margin-left: 0 !important;
  }

  .vUmmzq_ms-md-1 {
    margin-left: .25rem !important;
  }

  .vUmmzq_ms-md-2 {
    margin-left: .5rem !important;
  }

  .vUmmzq_ms-md-3 {
    margin-left: 1rem !important;
  }

  .vUmmzq_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .vUmmzq_ms-md-5 {
    margin-left: 3rem !important;
  }

  .vUmmzq_ms-md-auto {
    margin-left: auto !important;
  }

  .vUmmzq_p-md-0 {
    padding: 0 !important;
  }

  .vUmmzq_p-md-1 {
    padding: .25rem !important;
  }

  .vUmmzq_p-md-2 {
    padding: .5rem !important;
  }

  .vUmmzq_p-md-3 {
    padding: 1rem !important;
  }

  .vUmmzq_p-md-4 {
    padding: 1.5rem !important;
  }

  .vUmmzq_p-md-5 {
    padding: 3rem !important;
  }

  .vUmmzq_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vUmmzq_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .vUmmzq_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .vUmmzq_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .vUmmzq_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .vUmmzq_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .vUmmzq_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vUmmzq_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .vUmmzq_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .vUmmzq_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .vUmmzq_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .vUmmzq_pt-md-0 {
    padding-top: 0 !important;
  }

  .vUmmzq_pt-md-1 {
    padding-top: .25rem !important;
  }

  .vUmmzq_pt-md-2 {
    padding-top: .5rem !important;
  }

  .vUmmzq_pt-md-3 {
    padding-top: 1rem !important;
  }

  .vUmmzq_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .vUmmzq_pt-md-5 {
    padding-top: 3rem !important;
  }

  .vUmmzq_pe-md-0 {
    padding-right: 0 !important;
  }

  .vUmmzq_pe-md-1 {
    padding-right: .25rem !important;
  }

  .vUmmzq_pe-md-2 {
    padding-right: .5rem !important;
  }

  .vUmmzq_pe-md-3 {
    padding-right: 1rem !important;
  }

  .vUmmzq_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .vUmmzq_pe-md-5 {
    padding-right: 3rem !important;
  }

  .vUmmzq_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .vUmmzq_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .vUmmzq_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .vUmmzq_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .vUmmzq_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .vUmmzq_ps-md-0 {
    padding-left: 0 !important;
  }

  .vUmmzq_ps-md-1 {
    padding-left: .25rem !important;
  }

  .vUmmzq_ps-md-2 {
    padding-left: .5rem !important;
  }

  .vUmmzq_ps-md-3 {
    padding-left: 1rem !important;
  }

  .vUmmzq_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .vUmmzq_ps-md-5 {
    padding-left: 3rem !important;
  }

  .vUmmzq_gap-md-0 {
    gap: 0 !important;
  }

  .vUmmzq_gap-md-1 {
    gap: .25rem !important;
  }

  .vUmmzq_gap-md-2 {
    gap: .5rem !important;
  }

  .vUmmzq_gap-md-3 {
    gap: 1rem !important;
  }

  .vUmmzq_gap-md-4 {
    gap: 1.5rem !important;
  }

  .vUmmzq_gap-md-5 {
    gap: 3rem !important;
  }

  .vUmmzq_text-md-start {
    text-align: left !important;
  }

  .vUmmzq_text-md-end {
    text-align: right !important;
  }

  .vUmmzq_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .vUmmzq_float-lg-start {
    float: left !important;
  }

  .vUmmzq_float-lg-end {
    float: right !important;
  }

  .vUmmzq_float-lg-none {
    float: none !important;
  }

  .vUmmzq_d-lg-inline {
    display: inline !important;
  }

  .vUmmzq_d-lg-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-lg-block {
    display: block !important;
  }

  .vUmmzq_d-lg-grid {
    display: grid !important;
  }

  .vUmmzq_d-lg-table {
    display: table !important;
  }

  .vUmmzq_d-lg-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-lg-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-lg-flex {
    display: flex !important;
  }

  .vUmmzq_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-lg-none {
    display: none !important;
  }

  .vUmmzq_flex-lg-fill {
    flex: auto !important;
  }

  .vUmmzq_flex-lg-row {
    flex-direction: row !important;
  }

  .vUmmzq_flex-lg-column {
    flex-direction: column !important;
  }

  .vUmmzq_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .vUmmzq_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .vUmmzq_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .vUmmzq_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .vUmmzq_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .vUmmzq_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .vUmmzq_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .vUmmzq_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .vUmmzq_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .vUmmzq_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .vUmmzq_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .vUmmzq_justify-content-lg-center {
    justify-content: center !important;
  }

  .vUmmzq_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .vUmmzq_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .vUmmzq_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .vUmmzq_align-items-lg-start {
    align-items: flex-start !important;
  }

  .vUmmzq_align-items-lg-end {
    align-items: flex-end !important;
  }

  .vUmmzq_align-items-lg-center {
    align-items: center !important;
  }

  .vUmmzq_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .vUmmzq_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .vUmmzq_align-content-lg-start {
    align-content: flex-start !important;
  }

  .vUmmzq_align-content-lg-end {
    align-content: flex-end !important;
  }

  .vUmmzq_align-content-lg-center {
    align-content: center !important;
  }

  .vUmmzq_align-content-lg-between {
    align-content: space-between !important;
  }

  .vUmmzq_align-content-lg-around {
    align-content: space-around !important;
  }

  .vUmmzq_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .vUmmzq_align-self-lg-auto {
    align-self: auto !important;
  }

  .vUmmzq_align-self-lg-start {
    align-self: flex-start !important;
  }

  .vUmmzq_align-self-lg-end {
    align-self: flex-end !important;
  }

  .vUmmzq_align-self-lg-center {
    align-self: center !important;
  }

  .vUmmzq_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .vUmmzq_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .vUmmzq_order-lg-first {
    order: -1 !important;
  }

  .vUmmzq_order-lg-0 {
    order: 0 !important;
  }

  .vUmmzq_order-lg-1 {
    order: 1 !important;
  }

  .vUmmzq_order-lg-2 {
    order: 2 !important;
  }

  .vUmmzq_order-lg-3 {
    order: 3 !important;
  }

  .vUmmzq_order-lg-4 {
    order: 4 !important;
  }

  .vUmmzq_order-lg-5 {
    order: 5 !important;
  }

  .vUmmzq_order-lg-last {
    order: 6 !important;
  }

  .vUmmzq_m-lg-0 {
    margin: 0 !important;
  }

  .vUmmzq_m-lg-1 {
    margin: .25rem !important;
  }

  .vUmmzq_m-lg-2 {
    margin: .5rem !important;
  }

  .vUmmzq_m-lg-3 {
    margin: 1rem !important;
  }

  .vUmmzq_m-lg-4 {
    margin: 1.5rem !important;
  }

  .vUmmzq_m-lg-5 {
    margin: 3rem !important;
  }

  .vUmmzq_m-lg-auto {
    margin: auto !important;
  }

  .vUmmzq_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .vUmmzq_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .vUmmzq_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .vUmmzq_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .vUmmzq_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .vUmmzq_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .vUmmzq_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .vUmmzq_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .vUmmzq_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .vUmmzq_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .vUmmzq_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .vUmmzq_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .vUmmzq_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .vUmmzq_mt-lg-0 {
    margin-top: 0 !important;
  }

  .vUmmzq_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .vUmmzq_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .vUmmzq_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .vUmmzq_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .vUmmzq_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .vUmmzq_mt-lg-auto {
    margin-top: auto !important;
  }

  .vUmmzq_me-lg-0 {
    margin-right: 0 !important;
  }

  .vUmmzq_me-lg-1 {
    margin-right: .25rem !important;
  }

  .vUmmzq_me-lg-2 {
    margin-right: .5rem !important;
  }

  .vUmmzq_me-lg-3 {
    margin-right: 1rem !important;
  }

  .vUmmzq_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .vUmmzq_me-lg-5 {
    margin-right: 3rem !important;
  }

  .vUmmzq_me-lg-auto {
    margin-right: auto !important;
  }

  .vUmmzq_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .vUmmzq_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .vUmmzq_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .vUmmzq_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .vUmmzq_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .vUmmzq_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .vUmmzq_ms-lg-0 {
    margin-left: 0 !important;
  }

  .vUmmzq_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .vUmmzq_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .vUmmzq_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .vUmmzq_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .vUmmzq_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .vUmmzq_ms-lg-auto {
    margin-left: auto !important;
  }

  .vUmmzq_p-lg-0 {
    padding: 0 !important;
  }

  .vUmmzq_p-lg-1 {
    padding: .25rem !important;
  }

  .vUmmzq_p-lg-2 {
    padding: .5rem !important;
  }

  .vUmmzq_p-lg-3 {
    padding: 1rem !important;
  }

  .vUmmzq_p-lg-4 {
    padding: 1.5rem !important;
  }

  .vUmmzq_p-lg-5 {
    padding: 3rem !important;
  }

  .vUmmzq_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vUmmzq_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .vUmmzq_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .vUmmzq_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .vUmmzq_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .vUmmzq_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .vUmmzq_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vUmmzq_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .vUmmzq_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .vUmmzq_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .vUmmzq_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .vUmmzq_pt-lg-0 {
    padding-top: 0 !important;
  }

  .vUmmzq_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .vUmmzq_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .vUmmzq_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .vUmmzq_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .vUmmzq_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .vUmmzq_pe-lg-0 {
    padding-right: 0 !important;
  }

  .vUmmzq_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .vUmmzq_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .vUmmzq_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .vUmmzq_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .vUmmzq_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .vUmmzq_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .vUmmzq_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .vUmmzq_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .vUmmzq_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .vUmmzq_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .vUmmzq_ps-lg-0 {
    padding-left: 0 !important;
  }

  .vUmmzq_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .vUmmzq_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .vUmmzq_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .vUmmzq_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .vUmmzq_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .vUmmzq_gap-lg-0 {
    gap: 0 !important;
  }

  .vUmmzq_gap-lg-1 {
    gap: .25rem !important;
  }

  .vUmmzq_gap-lg-2 {
    gap: .5rem !important;
  }

  .vUmmzq_gap-lg-3 {
    gap: 1rem !important;
  }

  .vUmmzq_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .vUmmzq_gap-lg-5 {
    gap: 3rem !important;
  }

  .vUmmzq_text-lg-start {
    text-align: left !important;
  }

  .vUmmzq_text-lg-end {
    text-align: right !important;
  }

  .vUmmzq_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_float-xl-start {
    float: left !important;
  }

  .vUmmzq_float-xl-end {
    float: right !important;
  }

  .vUmmzq_float-xl-none {
    float: none !important;
  }

  .vUmmzq_d-xl-inline {
    display: inline !important;
  }

  .vUmmzq_d-xl-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-xl-block {
    display: block !important;
  }

  .vUmmzq_d-xl-grid {
    display: grid !important;
  }

  .vUmmzq_d-xl-table {
    display: table !important;
  }

  .vUmmzq_d-xl-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-xl-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-xl-flex {
    display: flex !important;
  }

  .vUmmzq_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-xl-none {
    display: none !important;
  }

  .vUmmzq_flex-xl-fill {
    flex: auto !important;
  }

  .vUmmzq_flex-xl-row {
    flex-direction: row !important;
  }

  .vUmmzq_flex-xl-column {
    flex-direction: column !important;
  }

  .vUmmzq_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .vUmmzq_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .vUmmzq_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .vUmmzq_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .vUmmzq_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .vUmmzq_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .vUmmzq_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .vUmmzq_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .vUmmzq_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .vUmmzq_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .vUmmzq_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .vUmmzq_justify-content-xl-center {
    justify-content: center !important;
  }

  .vUmmzq_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .vUmmzq_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .vUmmzq_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .vUmmzq_align-items-xl-start {
    align-items: flex-start !important;
  }

  .vUmmzq_align-items-xl-end {
    align-items: flex-end !important;
  }

  .vUmmzq_align-items-xl-center {
    align-items: center !important;
  }

  .vUmmzq_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .vUmmzq_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .vUmmzq_align-content-xl-start {
    align-content: flex-start !important;
  }

  .vUmmzq_align-content-xl-end {
    align-content: flex-end !important;
  }

  .vUmmzq_align-content-xl-center {
    align-content: center !important;
  }

  .vUmmzq_align-content-xl-between {
    align-content: space-between !important;
  }

  .vUmmzq_align-content-xl-around {
    align-content: space-around !important;
  }

  .vUmmzq_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .vUmmzq_align-self-xl-auto {
    align-self: auto !important;
  }

  .vUmmzq_align-self-xl-start {
    align-self: flex-start !important;
  }

  .vUmmzq_align-self-xl-end {
    align-self: flex-end !important;
  }

  .vUmmzq_align-self-xl-center {
    align-self: center !important;
  }

  .vUmmzq_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .vUmmzq_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .vUmmzq_order-xl-first {
    order: -1 !important;
  }

  .vUmmzq_order-xl-0 {
    order: 0 !important;
  }

  .vUmmzq_order-xl-1 {
    order: 1 !important;
  }

  .vUmmzq_order-xl-2 {
    order: 2 !important;
  }

  .vUmmzq_order-xl-3 {
    order: 3 !important;
  }

  .vUmmzq_order-xl-4 {
    order: 4 !important;
  }

  .vUmmzq_order-xl-5 {
    order: 5 !important;
  }

  .vUmmzq_order-xl-last {
    order: 6 !important;
  }

  .vUmmzq_m-xl-0 {
    margin: 0 !important;
  }

  .vUmmzq_m-xl-1 {
    margin: .25rem !important;
  }

  .vUmmzq_m-xl-2 {
    margin: .5rem !important;
  }

  .vUmmzq_m-xl-3 {
    margin: 1rem !important;
  }

  .vUmmzq_m-xl-4 {
    margin: 1.5rem !important;
  }

  .vUmmzq_m-xl-5 {
    margin: 3rem !important;
  }

  .vUmmzq_m-xl-auto {
    margin: auto !important;
  }

  .vUmmzq_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .vUmmzq_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .vUmmzq_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .vUmmzq_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .vUmmzq_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .vUmmzq_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .vUmmzq_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .vUmmzq_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .vUmmzq_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .vUmmzq_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .vUmmzq_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .vUmmzq_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .vUmmzq_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .vUmmzq_mt-xl-0 {
    margin-top: 0 !important;
  }

  .vUmmzq_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .vUmmzq_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .vUmmzq_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .vUmmzq_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .vUmmzq_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .vUmmzq_mt-xl-auto {
    margin-top: auto !important;
  }

  .vUmmzq_me-xl-0 {
    margin-right: 0 !important;
  }

  .vUmmzq_me-xl-1 {
    margin-right: .25rem !important;
  }

  .vUmmzq_me-xl-2 {
    margin-right: .5rem !important;
  }

  .vUmmzq_me-xl-3 {
    margin-right: 1rem !important;
  }

  .vUmmzq_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .vUmmzq_me-xl-5 {
    margin-right: 3rem !important;
  }

  .vUmmzq_me-xl-auto {
    margin-right: auto !important;
  }

  .vUmmzq_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .vUmmzq_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .vUmmzq_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .vUmmzq_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .vUmmzq_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .vUmmzq_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .vUmmzq_ms-xl-0 {
    margin-left: 0 !important;
  }

  .vUmmzq_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .vUmmzq_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .vUmmzq_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .vUmmzq_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .vUmmzq_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .vUmmzq_ms-xl-auto {
    margin-left: auto !important;
  }

  .vUmmzq_p-xl-0 {
    padding: 0 !important;
  }

  .vUmmzq_p-xl-1 {
    padding: .25rem !important;
  }

  .vUmmzq_p-xl-2 {
    padding: .5rem !important;
  }

  .vUmmzq_p-xl-3 {
    padding: 1rem !important;
  }

  .vUmmzq_p-xl-4 {
    padding: 1.5rem !important;
  }

  .vUmmzq_p-xl-5 {
    padding: 3rem !important;
  }

  .vUmmzq_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vUmmzq_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .vUmmzq_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .vUmmzq_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .vUmmzq_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .vUmmzq_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .vUmmzq_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vUmmzq_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .vUmmzq_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .vUmmzq_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .vUmmzq_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .vUmmzq_pt-xl-0 {
    padding-top: 0 !important;
  }

  .vUmmzq_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .vUmmzq_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .vUmmzq_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .vUmmzq_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .vUmmzq_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .vUmmzq_pe-xl-0 {
    padding-right: 0 !important;
  }

  .vUmmzq_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .vUmmzq_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .vUmmzq_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .vUmmzq_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .vUmmzq_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .vUmmzq_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .vUmmzq_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .vUmmzq_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .vUmmzq_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .vUmmzq_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .vUmmzq_ps-xl-0 {
    padding-left: 0 !important;
  }

  .vUmmzq_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .vUmmzq_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .vUmmzq_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .vUmmzq_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .vUmmzq_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .vUmmzq_gap-xl-0 {
    gap: 0 !important;
  }

  .vUmmzq_gap-xl-1 {
    gap: .25rem !important;
  }

  .vUmmzq_gap-xl-2 {
    gap: .5rem !important;
  }

  .vUmmzq_gap-xl-3 {
    gap: 1rem !important;
  }

  .vUmmzq_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .vUmmzq_gap-xl-5 {
    gap: 3rem !important;
  }

  .vUmmzq_text-xl-start {
    text-align: left !important;
  }

  .vUmmzq_text-xl-end {
    text-align: right !important;
  }

  .vUmmzq_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .vUmmzq_float-xxl-start {
    float: left !important;
  }

  .vUmmzq_float-xxl-end {
    float: right !important;
  }

  .vUmmzq_float-xxl-none {
    float: none !important;
  }

  .vUmmzq_d-xxl-inline {
    display: inline !important;
  }

  .vUmmzq_d-xxl-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-xxl-block {
    display: block !important;
  }

  .vUmmzq_d-xxl-grid {
    display: grid !important;
  }

  .vUmmzq_d-xxl-table {
    display: table !important;
  }

  .vUmmzq_d-xxl-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-xxl-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-xxl-flex {
    display: flex !important;
  }

  .vUmmzq_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-xxl-none {
    display: none !important;
  }

  .vUmmzq_flex-xxl-fill {
    flex: auto !important;
  }

  .vUmmzq_flex-xxl-row {
    flex-direction: row !important;
  }

  .vUmmzq_flex-xxl-column {
    flex-direction: column !important;
  }

  .vUmmzq_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .vUmmzq_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .vUmmzq_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .vUmmzq_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .vUmmzq_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .vUmmzq_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .vUmmzq_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .vUmmzq_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .vUmmzq_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .vUmmzq_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .vUmmzq_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .vUmmzq_justify-content-xxl-center {
    justify-content: center !important;
  }

  .vUmmzq_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .vUmmzq_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .vUmmzq_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .vUmmzq_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .vUmmzq_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .vUmmzq_align-items-xxl-center {
    align-items: center !important;
  }

  .vUmmzq_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .vUmmzq_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .vUmmzq_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .vUmmzq_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .vUmmzq_align-content-xxl-center {
    align-content: center !important;
  }

  .vUmmzq_align-content-xxl-between {
    align-content: space-between !important;
  }

  .vUmmzq_align-content-xxl-around {
    align-content: space-around !important;
  }

  .vUmmzq_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .vUmmzq_align-self-xxl-auto {
    align-self: auto !important;
  }

  .vUmmzq_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .vUmmzq_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .vUmmzq_align-self-xxl-center {
    align-self: center !important;
  }

  .vUmmzq_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .vUmmzq_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .vUmmzq_order-xxl-first {
    order: -1 !important;
  }

  .vUmmzq_order-xxl-0 {
    order: 0 !important;
  }

  .vUmmzq_order-xxl-1 {
    order: 1 !important;
  }

  .vUmmzq_order-xxl-2 {
    order: 2 !important;
  }

  .vUmmzq_order-xxl-3 {
    order: 3 !important;
  }

  .vUmmzq_order-xxl-4 {
    order: 4 !important;
  }

  .vUmmzq_order-xxl-5 {
    order: 5 !important;
  }

  .vUmmzq_order-xxl-last {
    order: 6 !important;
  }

  .vUmmzq_m-xxl-0 {
    margin: 0 !important;
  }

  .vUmmzq_m-xxl-1 {
    margin: .25rem !important;
  }

  .vUmmzq_m-xxl-2 {
    margin: .5rem !important;
  }

  .vUmmzq_m-xxl-3 {
    margin: 1rem !important;
  }

  .vUmmzq_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .vUmmzq_m-xxl-5 {
    margin: 3rem !important;
  }

  .vUmmzq_m-xxl-auto {
    margin: auto !important;
  }

  .vUmmzq_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .vUmmzq_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .vUmmzq_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .vUmmzq_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .vUmmzq_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .vUmmzq_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .vUmmzq_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .vUmmzq_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .vUmmzq_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .vUmmzq_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .vUmmzq_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .vUmmzq_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .vUmmzq_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .vUmmzq_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .vUmmzq_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .vUmmzq_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .vUmmzq_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .vUmmzq_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .vUmmzq_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .vUmmzq_mt-xxl-auto {
    margin-top: auto !important;
  }

  .vUmmzq_me-xxl-0 {
    margin-right: 0 !important;
  }

  .vUmmzq_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .vUmmzq_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .vUmmzq_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .vUmmzq_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .vUmmzq_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .vUmmzq_me-xxl-auto {
    margin-right: auto !important;
  }

  .vUmmzq_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .vUmmzq_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .vUmmzq_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .vUmmzq_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .vUmmzq_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .vUmmzq_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .vUmmzq_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .vUmmzq_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .vUmmzq_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .vUmmzq_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .vUmmzq_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .vUmmzq_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .vUmmzq_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .vUmmzq_ms-xxl-auto {
    margin-left: auto !important;
  }

  .vUmmzq_p-xxl-0 {
    padding: 0 !important;
  }

  .vUmmzq_p-xxl-1 {
    padding: .25rem !important;
  }

  .vUmmzq_p-xxl-2 {
    padding: .5rem !important;
  }

  .vUmmzq_p-xxl-3 {
    padding: 1rem !important;
  }

  .vUmmzq_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .vUmmzq_p-xxl-5 {
    padding: 3rem !important;
  }

  .vUmmzq_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vUmmzq_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .vUmmzq_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .vUmmzq_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .vUmmzq_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .vUmmzq_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .vUmmzq_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vUmmzq_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .vUmmzq_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .vUmmzq_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .vUmmzq_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .vUmmzq_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .vUmmzq_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .vUmmzq_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .vUmmzq_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .vUmmzq_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .vUmmzq_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .vUmmzq_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .vUmmzq_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .vUmmzq_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .vUmmzq_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .vUmmzq_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .vUmmzq_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .vUmmzq_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .vUmmzq_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .vUmmzq_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .vUmmzq_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .vUmmzq_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .vUmmzq_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .vUmmzq_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .vUmmzq_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .vUmmzq_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .vUmmzq_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .vUmmzq_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .vUmmzq_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .vUmmzq_gap-xxl-0 {
    gap: 0 !important;
  }

  .vUmmzq_gap-xxl-1 {
    gap: .25rem !important;
  }

  .vUmmzq_gap-xxl-2 {
    gap: .5rem !important;
  }

  .vUmmzq_gap-xxl-3 {
    gap: 1rem !important;
  }

  .vUmmzq_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .vUmmzq_gap-xxl-5 {
    gap: 3rem !important;
  }

  .vUmmzq_text-xxl-start {
    text-align: left !important;
  }

  .vUmmzq_text-xxl-end {
    text-align: right !important;
  }

  .vUmmzq_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .vUmmzq_fs-1 {
    font-size: 2.5rem !important;
  }

  .vUmmzq_fs-2 {
    font-size: 2rem !important;
  }

  .vUmmzq_fs-3 {
    font-size: 1.75rem !important;
  }

  .vUmmzq_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .vUmmzq_d-print-inline {
    display: inline !important;
  }

  .vUmmzq_d-print-inline-block {
    display: inline-block !important;
  }

  .vUmmzq_d-print-block {
    display: block !important;
  }

  .vUmmzq_d-print-grid {
    display: grid !important;
  }

  .vUmmzq_d-print-table {
    display: table !important;
  }

  .vUmmzq_d-print-table-row {
    display: table-row !important;
  }

  .vUmmzq_d-print-table-cell {
    display: table-cell !important;
  }

  .vUmmzq_d-print-flex {
    display: flex !important;
  }

  .vUmmzq_d-print-inline-flex {
    display: inline-flex !important;
  }

  .vUmmzq_d-print-none {
    display: none !important;
  }
}

.vUmmzq_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.vUmmzq_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.vUmmzq_nav-tabs .vUmmzq_nav-item .vUmmzq_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.vUmmzq_nav-tabs .vUmmzq_nav-item .vUmmzq_nav-link.vUmmzq_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.vUmmzq_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#vUmmzq_root {
  height: 100%;
}

.vUmmzq_highcharts-credits {
  display: none !important;
}

h3, .vUmmzq_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.vUmmzq_modal .vUmmzq_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.vUmmzq_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.vUmmzq_table thead tr th.vUmmzq_col-actions {
  width: 110px !important;
}

table.vUmmzq_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.vUmmzq_table tbody tr.vUmmzq_deleting {
  background-color: #ff323233 !important;
}

.vUmmzq_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.vUmmzq_btn-sm, .vUmmzq_btn-group-sm > .vUmmzq_btn {
  font-size: .6rem !important;
}

.vUmmzq_modal {
  background-color: #0a0a0a4d;
}

.vUmmzq_btn:focus {
  box-shadow: none !important;
}

.vUmmzq_content-filter .vUmmzq_react-datepicker-wrapper {
  width: auto !important;
}

.vUmmzq_content-filter .vUmmzq_react-datepicker-wrapper .vUmmzq_form-control {
  font-size: .85rem !important;
}

.vUmmzq_content-filter .vUmmzq_input-group-text, .vUmmzq_content-filter .vUmmzq_btn {
  height: 2.15rem !important;
}

.vUmmzq_content-filter .vUmmzq_btn {
  border-color: #ccc;
}

.vUmmzq_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.vUmmzq_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.vUmmzq_table > :not(:first-child) {
  border-top: unset !important;
}

.vUmmzq_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.vUmmzq_highcharts-series.vUmmzq_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.vUmmzq_expandable {
  transition: all .5s;
}

.vUmmzq_expandable > .vUmmzq_expander-icon {
  cursor: pointer;
}

.vUmmzq_expandable > .vUmmzq_expander-icon:hover {
  color: #328cfa66;
}

.vUmmzq_expandable.vUmmzq_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .vUmmzq_modal .vUmmzq_modal-xl {
    width: 100% !important;
  }

  .vUmmzq_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.vUmmzq_d-none, .vUmmzq_d-block {
  transition: all .5s;
}

.vUmmzq_viewButton {
  width: 24px;
  height: 24px;
  background-color: #f2fff9;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vUmmzq_editButton {
  width: 24px;
  height: 24px;
  background-color: #ecf4ff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vUmmzq_deleteButton {
  all: unset;
  width: 24px;
  height: 24px;
  background-color: #fff5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vUmmzq_addButton {
  all: unset;
  color: #fff;
  background: #4c469c;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.vUmmzq_addButton:hover {
  cursor: pointer;
  color: #fff;
}

.HEZtia_errorPage {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: row;
  place-content: center;
  align-items: center;
  display: flex;
}

.HEZtia_errorPage .HEZtia_errorContent {
  min-width: 50%;
  min-height: 50%;
  background-color: #fefefe;
  border-radius: 1rem;
  padding: 5rem;
}

.HEZtia_errorPage .HEZtia_errorContent h3 {
  color: #272727;
  font-size: 30px;
}

.HEZtia_errorPage .HEZtia_errorContent h5 {
  color: #272727;
  font-size: 24px;
}

.HEZtia_errorPage .HEZtia_errorContent img {
  max-height: 100px;
}

.HEZtia_fullPage {
  height: 100vh !important;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .lhHEiG_h6, h5, .lhHEiG_h5, h4, .lhHEiG_h4, h3, .lhHEiG_h3, h2, .lhHEiG_h2, h1, .lhHEiG_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .lhHEiG_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .lhHEiG_h1 {
    font-size: 2.5rem;
  }
}

h2, .lhHEiG_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .lhHEiG_h2 {
    font-size: 2rem;
  }
}

h3, .lhHEiG_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .lhHEiG_h3 {
    font-size: 1.75rem;
  }
}

h4, .lhHEiG_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .lhHEiG_h4 {
    font-size: 1.5rem;
  }
}

h5, .lhHEiG_h5 {
  font-size: 1.25rem;
}

h6, .lhHEiG_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .lhHEiG_small {
  font-size: .875em;
}

mark, .lhHEiG_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lhHEiG_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.lhHEiG_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-1 {
    font-size: 5rem;
  }
}

.lhHEiG_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-2 {
    font-size: 4.5rem;
  }
}

.lhHEiG_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-3 {
    font-size: 4rem;
  }
}

.lhHEiG_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-4 {
    font-size: 3.5rem;
  }
}

.lhHEiG_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-5 {
    font-size: 3rem;
  }
}

.lhHEiG_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .lhHEiG_display-6 {
    font-size: 2.5rem;
  }
}

.lhHEiG_list-unstyled, .lhHEiG_list-inline {
  padding-left: 0;
  list-style: none;
}

.lhHEiG_list-inline-item {
  display: inline-block;
}

.lhHEiG_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.lhHEiG_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.lhHEiG_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.lhHEiG_blockquote > :last-child {
  margin-bottom: 0;
}

.lhHEiG_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.lhHEiG_blockquote-footer:before {
  content: "— ";
}

.lhHEiG_img-fluid {
  max-width: 100%;
  height: auto;
}

.lhHEiG_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.lhHEiG_figure {
  display: inline-block;
}

.lhHEiG_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.lhHEiG_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.lhHEiG_container, .lhHEiG_container-fluid, .lhHEiG_container-xxl, .lhHEiG_container-xl, .lhHEiG_container-lg, .lhHEiG_container-md, .lhHEiG_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .lhHEiG_container-sm, .lhHEiG_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .lhHEiG_container-md, .lhHEiG_container-sm, .lhHEiG_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .lhHEiG_container-lg, .lhHEiG_container-md, .lhHEiG_container-sm, .lhHEiG_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_container-xl, .lhHEiG_container-lg, .lhHEiG_container-md, .lhHEiG_container-sm, .lhHEiG_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_container-xxl, .lhHEiG_container-xl, .lhHEiG_container-lg, .lhHEiG_container-md, .lhHEiG_container-sm, .lhHEiG_container {
    max-width: 1320px;
  }
}

.lhHEiG_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.lhHEiG_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.lhHEiG_col {
  flex: 1 0;
}

.lhHEiG_row-cols-auto > * {
  width: auto;
  flex: none;
}

.lhHEiG_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.lhHEiG_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.lhHEiG_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.lhHEiG_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.lhHEiG_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.lhHEiG_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.lhHEiG_col-auto {
  width: auto;
  flex: none;
}

.lhHEiG_col-1 {
  width: 8.33333%;
  flex: none;
}

.lhHEiG_col-2 {
  width: 16.6667%;
  flex: none;
}

.lhHEiG_col-3 {
  width: 25%;
  flex: none;
}

.lhHEiG_col-4 {
  width: 33.3333%;
  flex: none;
}

.lhHEiG_col-5 {
  width: 41.6667%;
  flex: none;
}

.lhHEiG_col-6 {
  width: 50%;
  flex: none;
}

.lhHEiG_col-7 {
  width: 58.3333%;
  flex: none;
}

.lhHEiG_col-8 {
  width: 66.6667%;
  flex: none;
}

.lhHEiG_col-9 {
  width: 75%;
  flex: none;
}

.lhHEiG_col-10 {
  width: 83.3333%;
  flex: none;
}

.lhHEiG_col-11 {
  width: 91.6667%;
  flex: none;
}

.lhHEiG_col-12 {
  width: 100%;
  flex: none;
}

.lhHEiG_offset-1 {
  margin-left: 8.33333%;
}

.lhHEiG_offset-2 {
  margin-left: 16.6667%;
}

.lhHEiG_offset-3 {
  margin-left: 25%;
}

.lhHEiG_offset-4 {
  margin-left: 33.3333%;
}

.lhHEiG_offset-5 {
  margin-left: 41.6667%;
}

.lhHEiG_offset-6 {
  margin-left: 50%;
}

.lhHEiG_offset-7 {
  margin-left: 58.3333%;
}

.lhHEiG_offset-8 {
  margin-left: 66.6667%;
}

.lhHEiG_offset-9 {
  margin-left: 75%;
}

.lhHEiG_offset-10 {
  margin-left: 83.3333%;
}

.lhHEiG_offset-11 {
  margin-left: 91.6667%;
}

.lhHEiG_g-0, .lhHEiG_gx-0 {
  --bs-gutter-x: 0;
}

.lhHEiG_g-0, .lhHEiG_gy-0 {
  --bs-gutter-y: 0;
}

.lhHEiG_g-1, .lhHEiG_gx-1 {
  --bs-gutter-x: .25rem;
}

.lhHEiG_g-1, .lhHEiG_gy-1 {
  --bs-gutter-y: .25rem;
}

.lhHEiG_g-2, .lhHEiG_gx-2 {
  --bs-gutter-x: .5rem;
}

.lhHEiG_g-2, .lhHEiG_gy-2 {
  --bs-gutter-y: .5rem;
}

.lhHEiG_g-3, .lhHEiG_gx-3 {
  --bs-gutter-x: 1rem;
}

.lhHEiG_g-3, .lhHEiG_gy-3 {
  --bs-gutter-y: 1rem;
}

.lhHEiG_g-4, .lhHEiG_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.lhHEiG_g-4, .lhHEiG_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.lhHEiG_g-5, .lhHEiG_gx-5 {
  --bs-gutter-x: 3rem;
}

.lhHEiG_g-5, .lhHEiG_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .lhHEiG_col-sm {
    flex: 1 0;
  }

  .lhHEiG_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .lhHEiG_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .lhHEiG_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .lhHEiG_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .lhHEiG_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .lhHEiG_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-sm-auto {
    width: auto;
    flex: none;
  }

  .lhHEiG_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .lhHEiG_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .lhHEiG_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .lhHEiG_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .lhHEiG_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .lhHEiG_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .lhHEiG_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .lhHEiG_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .lhHEiG_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .lhHEiG_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .lhHEiG_offset-sm-0 {
    margin-left: 0;
  }

  .lhHEiG_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .lhHEiG_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .lhHEiG_offset-sm-3 {
    margin-left: 25%;
  }

  .lhHEiG_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .lhHEiG_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .lhHEiG_offset-sm-6 {
    margin-left: 50%;
  }

  .lhHEiG_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .lhHEiG_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .lhHEiG_offset-sm-9 {
    margin-left: 75%;
  }

  .lhHEiG_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .lhHEiG_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .lhHEiG_g-sm-0, .lhHEiG_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .lhHEiG_g-sm-0, .lhHEiG_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .lhHEiG_g-sm-1, .lhHEiG_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .lhHEiG_g-sm-1, .lhHEiG_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .lhHEiG_g-sm-2, .lhHEiG_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .lhHEiG_g-sm-2, .lhHEiG_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .lhHEiG_g-sm-3, .lhHEiG_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .lhHEiG_g-sm-3, .lhHEiG_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .lhHEiG_g-sm-4, .lhHEiG_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .lhHEiG_g-sm-4, .lhHEiG_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .lhHEiG_g-sm-5, .lhHEiG_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .lhHEiG_g-sm-5, .lhHEiG_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .lhHEiG_col-md {
    flex: 1 0;
  }

  .lhHEiG_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .lhHEiG_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .lhHEiG_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .lhHEiG_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .lhHEiG_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .lhHEiG_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-md-auto {
    width: auto;
    flex: none;
  }

  .lhHEiG_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .lhHEiG_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-md-3 {
    width: 25%;
    flex: none;
  }

  .lhHEiG_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .lhHEiG_col-md-6 {
    width: 50%;
    flex: none;
  }

  .lhHEiG_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .lhHEiG_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .lhHEiG_col-md-9 {
    width: 75%;
    flex: none;
  }

  .lhHEiG_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .lhHEiG_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .lhHEiG_col-md-12 {
    width: 100%;
    flex: none;
  }

  .lhHEiG_offset-md-0 {
    margin-left: 0;
  }

  .lhHEiG_offset-md-1 {
    margin-left: 8.33333%;
  }

  .lhHEiG_offset-md-2 {
    margin-left: 16.6667%;
  }

  .lhHEiG_offset-md-3 {
    margin-left: 25%;
  }

  .lhHEiG_offset-md-4 {
    margin-left: 33.3333%;
  }

  .lhHEiG_offset-md-5 {
    margin-left: 41.6667%;
  }

  .lhHEiG_offset-md-6 {
    margin-left: 50%;
  }

  .lhHEiG_offset-md-7 {
    margin-left: 58.3333%;
  }

  .lhHEiG_offset-md-8 {
    margin-left: 66.6667%;
  }

  .lhHEiG_offset-md-9 {
    margin-left: 75%;
  }

  .lhHEiG_offset-md-10 {
    margin-left: 83.3333%;
  }

  .lhHEiG_offset-md-11 {
    margin-left: 91.6667%;
  }

  .lhHEiG_g-md-0, .lhHEiG_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .lhHEiG_g-md-0, .lhHEiG_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .lhHEiG_g-md-1, .lhHEiG_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .lhHEiG_g-md-1, .lhHEiG_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .lhHEiG_g-md-2, .lhHEiG_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .lhHEiG_g-md-2, .lhHEiG_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .lhHEiG_g-md-3, .lhHEiG_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .lhHEiG_g-md-3, .lhHEiG_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .lhHEiG_g-md-4, .lhHEiG_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .lhHEiG_g-md-4, .lhHEiG_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .lhHEiG_g-md-5, .lhHEiG_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .lhHEiG_g-md-5, .lhHEiG_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .lhHEiG_col-lg {
    flex: 1 0;
  }

  .lhHEiG_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .lhHEiG_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .lhHEiG_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .lhHEiG_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .lhHEiG_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .lhHEiG_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-lg-auto {
    width: auto;
    flex: none;
  }

  .lhHEiG_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .lhHEiG_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .lhHEiG_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .lhHEiG_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .lhHEiG_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .lhHEiG_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .lhHEiG_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .lhHEiG_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .lhHEiG_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .lhHEiG_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .lhHEiG_offset-lg-0 {
    margin-left: 0;
  }

  .lhHEiG_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .lhHEiG_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .lhHEiG_offset-lg-3 {
    margin-left: 25%;
  }

  .lhHEiG_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .lhHEiG_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .lhHEiG_offset-lg-6 {
    margin-left: 50%;
  }

  .lhHEiG_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .lhHEiG_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .lhHEiG_offset-lg-9 {
    margin-left: 75%;
  }

  .lhHEiG_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .lhHEiG_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .lhHEiG_g-lg-0, .lhHEiG_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .lhHEiG_g-lg-0, .lhHEiG_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .lhHEiG_g-lg-1, .lhHEiG_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .lhHEiG_g-lg-1, .lhHEiG_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .lhHEiG_g-lg-2, .lhHEiG_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .lhHEiG_g-lg-2, .lhHEiG_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .lhHEiG_g-lg-3, .lhHEiG_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .lhHEiG_g-lg-3, .lhHEiG_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .lhHEiG_g-lg-4, .lhHEiG_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .lhHEiG_g-lg-4, .lhHEiG_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .lhHEiG_g-lg-5, .lhHEiG_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .lhHEiG_g-lg-5, .lhHEiG_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_col-xl {
    flex: 1 0;
  }

  .lhHEiG_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .lhHEiG_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .lhHEiG_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .lhHEiG_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .lhHEiG_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .lhHEiG_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-xl-auto {
    width: auto;
    flex: none;
  }

  .lhHEiG_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .lhHEiG_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .lhHEiG_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .lhHEiG_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .lhHEiG_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .lhHEiG_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .lhHEiG_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .lhHEiG_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .lhHEiG_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .lhHEiG_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .lhHEiG_offset-xl-0 {
    margin-left: 0;
  }

  .lhHEiG_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .lhHEiG_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .lhHEiG_offset-xl-3 {
    margin-left: 25%;
  }

  .lhHEiG_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .lhHEiG_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .lhHEiG_offset-xl-6 {
    margin-left: 50%;
  }

  .lhHEiG_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .lhHEiG_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .lhHEiG_offset-xl-9 {
    margin-left: 75%;
  }

  .lhHEiG_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .lhHEiG_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .lhHEiG_g-xl-0, .lhHEiG_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .lhHEiG_g-xl-0, .lhHEiG_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .lhHEiG_g-xl-1, .lhHEiG_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .lhHEiG_g-xl-1, .lhHEiG_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .lhHEiG_g-xl-2, .lhHEiG_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .lhHEiG_g-xl-2, .lhHEiG_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .lhHEiG_g-xl-3, .lhHEiG_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .lhHEiG_g-xl-3, .lhHEiG_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .lhHEiG_g-xl-4, .lhHEiG_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .lhHEiG_g-xl-4, .lhHEiG_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .lhHEiG_g-xl-5, .lhHEiG_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .lhHEiG_g-xl-5, .lhHEiG_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_col-xxl {
    flex: 1 0;
  }

  .lhHEiG_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .lhHEiG_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .lhHEiG_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .lhHEiG_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .lhHEiG_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .lhHEiG_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .lhHEiG_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .lhHEiG_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .lhHEiG_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .lhHEiG_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .lhHEiG_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .lhHEiG_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .lhHEiG_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .lhHEiG_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .lhHEiG_offset-xxl-0 {
    margin-left: 0;
  }

  .lhHEiG_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .lhHEiG_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .lhHEiG_offset-xxl-3 {
    margin-left: 25%;
  }

  .lhHEiG_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .lhHEiG_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .lhHEiG_offset-xxl-6 {
    margin-left: 50%;
  }

  .lhHEiG_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .lhHEiG_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .lhHEiG_offset-xxl-9 {
    margin-left: 75%;
  }

  .lhHEiG_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .lhHEiG_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .lhHEiG_g-xxl-0, .lhHEiG_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .lhHEiG_g-xxl-0, .lhHEiG_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .lhHEiG_g-xxl-1, .lhHEiG_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .lhHEiG_g-xxl-1, .lhHEiG_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .lhHEiG_g-xxl-2, .lhHEiG_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .lhHEiG_g-xxl-2, .lhHEiG_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .lhHEiG_g-xxl-3, .lhHEiG_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .lhHEiG_g-xxl-3, .lhHEiG_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .lhHEiG_g-xxl-4, .lhHEiG_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .lhHEiG_g-xxl-4, .lhHEiG_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .lhHEiG_g-xxl-5, .lhHEiG_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .lhHEiG_g-xxl-5, .lhHEiG_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.lhHEiG_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.lhHEiG_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.lhHEiG_table > tbody {
  vertical-align: inherit;
}

.lhHEiG_table > thead {
  vertical-align: bottom;
}

.lhHEiG_table-group-divider {
  border-top: 2px solid;
}

.lhHEiG_caption-top {
  caption-side: top;
}

.lhHEiG_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.lhHEiG_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.lhHEiG_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.lhHEiG_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.lhHEiG_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.lhHEiG_table-striped > tbody > tr:nth-of-type(2n+1) > *, .lhHEiG_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.lhHEiG_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.lhHEiG_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.lhHEiG_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.lhHEiG_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .lhHEiG_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .lhHEiG_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .lhHEiG_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .lhHEiG_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .lhHEiG_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.lhHEiG_form-label {
  margin-bottom: .5rem;
}

.lhHEiG_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.lhHEiG_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.lhHEiG_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.lhHEiG_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.lhHEiG_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-control {
    transition: none;
  }
}

.lhHEiG_form-control[type="file"] {
  overflow: hidden;
}

.lhHEiG_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.lhHEiG_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.lhHEiG_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.lhHEiG_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.lhHEiG_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.lhHEiG_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-control::file-selector-button {
    transition: none;
  }
}

.lhHEiG_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.lhHEiG_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.lhHEiG_form-control-plaintext:focus {
  outline: 0;
}

.lhHEiG_form-control-plaintext.lhHEiG_form-control-sm, .lhHEiG_form-control-plaintext.lhHEiG_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.lhHEiG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.lhHEiG_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.lhHEiG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.lhHEiG_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.lhHEiG_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.lhHEiG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.lhHEiG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.lhHEiG_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.lhHEiG_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.lhHEiG_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.lhHEiG_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.lhHEiG_form-control-color.lhHEiG_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.lhHEiG_form-control-color.lhHEiG_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.lhHEiG_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-select {
    transition: none;
  }
}

.lhHEiG_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.lhHEiG_form-select[multiple], .lhHEiG_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.lhHEiG_form-select:disabled {
  background-color: #e9ecef;
}

.lhHEiG_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.lhHEiG_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.lhHEiG_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.lhHEiG_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.lhHEiG_form-check .lhHEiG_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.lhHEiG_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.lhHEiG_form-check-reverse .lhHEiG_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.lhHEiG_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.lhHEiG_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.lhHEiG_form-check-input[type="radio"] {
  border-radius: 50%;
}

.lhHEiG_form-check-input:active {
  filter: brightness(90%);
}

.lhHEiG_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.lhHEiG_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.lhHEiG_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.lhHEiG_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.lhHEiG_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.lhHEiG_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.lhHEiG_form-check-input[disabled] ~ .lhHEiG_form-check-label, .lhHEiG_form-check-input:disabled ~ .lhHEiG_form-check-label {
  cursor: default;
  opacity: .5;
}

.lhHEiG_form-switch {
  padding-left: 2.5em;
}

.lhHEiG_form-switch .lhHEiG_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-switch .lhHEiG_form-check-input {
    transition: none;
  }
}

.lhHEiG_form-switch .lhHEiG_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.lhHEiG_form-switch .lhHEiG_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.lhHEiG_form-switch.lhHEiG_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.lhHEiG_form-switch.lhHEiG_form-check-reverse .lhHEiG_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.lhHEiG_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.lhHEiG_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.lhHEiG_btn-check[disabled] + .lhHEiG_btn, .lhHEiG_btn-check:disabled + .lhHEiG_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.lhHEiG_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.lhHEiG_form-range:focus {
  outline: 0;
}

.lhHEiG_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.lhHEiG_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.lhHEiG_form-range::-moz-focus-outer {
  border: 0;
}

.lhHEiG_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.lhHEiG_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.lhHEiG_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.lhHEiG_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-range::-moz-range-thumb {
    transition: none;
  }
}

.lhHEiG_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.lhHEiG_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.lhHEiG_form-range:disabled {
  pointer-events: none;
}

.lhHEiG_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.lhHEiG_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.lhHEiG_form-floating {
  position: relative;
}

.lhHEiG_form-floating > .lhHEiG_form-control, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext, .lhHEiG_form-floating > .lhHEiG_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.lhHEiG_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_form-floating > label {
    transition: none;
  }
}

.lhHEiG_form-floating > .lhHEiG_form-control, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext {
  padding: 1rem .75rem;
}

.lhHEiG_form-floating > .lhHEiG_form-control::placeholder, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext::placeholder {
  color: #0000;
}

.lhHEiG_form-floating > .lhHEiG_form-control:focus, .lhHEiG_form-floating > .lhHEiG_form-control:not(:placeholder-shown), .lhHEiG_form-floating > .lhHEiG_form-control-plaintext:focus, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.lhHEiG_form-floating > .lhHEiG_form-control:-webkit-autofill, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.lhHEiG_form-floating > .lhHEiG_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.lhHEiG_form-floating > .lhHEiG_form-control:focus ~ label, .lhHEiG_form-floating > .lhHEiG_form-control:not(:placeholder-shown) ~ label, .lhHEiG_form-floating > .lhHEiG_form-control-plaintext ~ label, .lhHEiG_form-floating > .lhHEiG_form-select ~ label, .lhHEiG_form-floating > .lhHEiG_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.lhHEiG_form-floating > .lhHEiG_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.lhHEiG_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.lhHEiG_input-group > .lhHEiG_form-control, .lhHEiG_input-group > .lhHEiG_form-select, .lhHEiG_input-group > .lhHEiG_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.lhHEiG_input-group > .lhHEiG_form-control:focus, .lhHEiG_input-group > .lhHEiG_form-select:focus, .lhHEiG_input-group > .lhHEiG_form-floating:focus-within {
  z-index: 5;
}

.lhHEiG_input-group .lhHEiG_btn {
  z-index: 2;
  position: relative;
}

.lhHEiG_input-group .lhHEiG_btn:focus {
  z-index: 5;
}

.lhHEiG_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.lhHEiG_input-group-lg > .lhHEiG_form-control, .lhHEiG_input-group-lg > .lhHEiG_form-select, .lhHEiG_input-group-lg > .lhHEiG_input-group-text, .lhHEiG_input-group-lg > .lhHEiG_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.lhHEiG_input-group-sm > .lhHEiG_form-control, .lhHEiG_input-group-sm > .lhHEiG_form-select, .lhHEiG_input-group-sm > .lhHEiG_input-group-text, .lhHEiG_input-group-sm > .lhHEiG_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.lhHEiG_input-group-lg > .lhHEiG_form-select, .lhHEiG_input-group-sm > .lhHEiG_form-select {
  padding-right: 3rem;
}

.lhHEiG_input-group:not(.lhHEiG_has-validation) > :not(:last-child):not(.lhHEiG_dropdown-toggle):not(.lhHEiG_dropdown-menu):not(.lhHEiG_form-floating), .lhHEiG_input-group:not(.lhHEiG_has-validation) > .lhHEiG_dropdown-toggle:nth-last-child(n+3), .lhHEiG_input-group:not(.lhHEiG_has-validation) > .lhHEiG_form-floating:not(:last-child) > .lhHEiG_form-control, .lhHEiG_input-group:not(.lhHEiG_has-validation) > .lhHEiG_form-floating:not(:last-child) > .lhHEiG_form-select, .lhHEiG_input-group.lhHEiG_has-validation > :nth-last-child(n+3):not(.lhHEiG_dropdown-toggle):not(.lhHEiG_dropdown-menu):not(.lhHEiG_form-floating), .lhHEiG_input-group.lhHEiG_has-validation > .lhHEiG_dropdown-toggle:nth-last-child(n+4), .lhHEiG_input-group.lhHEiG_has-validation > .lhHEiG_form-floating:nth-last-child(n+3) > .lhHEiG_form-control, .lhHEiG_input-group.lhHEiG_has-validation > .lhHEiG_form-floating:nth-last-child(n+3) > .lhHEiG_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.lhHEiG_input-group > :not(:first-child):not(.lhHEiG_dropdown-menu):not(.lhHEiG_valid-tooltip):not(.lhHEiG_valid-feedback):not(.lhHEiG_invalid-tooltip):not(.lhHEiG_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.lhHEiG_input-group > .lhHEiG_form-floating:not(:first-child) > .lhHEiG_form-control, .lhHEiG_input-group > .lhHEiG_form-floating:not(:first-child) > .lhHEiG_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.lhHEiG_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.lhHEiG_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.lhHEiG_was-validated :valid ~ .lhHEiG_valid-feedback, .lhHEiG_was-validated :valid ~ .lhHEiG_valid-tooltip, .lhHEiG_is-valid ~ .lhHEiG_valid-feedback, .lhHEiG_is-valid ~ .lhHEiG_valid-tooltip {
  display: block;
}

.lhHEiG_was-validated .lhHEiG_form-control:valid, .lhHEiG_form-control.lhHEiG_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.lhHEiG_was-validated .lhHEiG_form-control:valid:focus, .lhHEiG_form-control.lhHEiG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.lhHEiG_was-validated textarea.lhHEiG_form-control:valid, textarea.lhHEiG_form-control.lhHEiG_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.lhHEiG_was-validated .lhHEiG_form-select:valid, .lhHEiG_form-select.lhHEiG_is-valid {
  border-color: #198754;
}

.lhHEiG_was-validated .lhHEiG_form-select:valid:not([multiple]):not([size]), .lhHEiG_was-validated .lhHEiG_form-select:valid:not([multiple])[size="1"], .lhHEiG_form-select.lhHEiG_is-valid:not([multiple]):not([size]), .lhHEiG_form-select.lhHEiG_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.lhHEiG_was-validated .lhHEiG_form-select:valid:focus, .lhHEiG_form-select.lhHEiG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.lhHEiG_was-validated .lhHEiG_form-control-color:valid, .lhHEiG_form-control-color.lhHEiG_is-valid {
  width: calc(1.5em + 3.75rem);
}

.lhHEiG_was-validated .lhHEiG_form-check-input:valid, .lhHEiG_form-check-input.lhHEiG_is-valid {
  border-color: #198754;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:valid:checked, .lhHEiG_form-check-input.lhHEiG_is-valid:checked {
  background-color: #198754;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:valid:focus, .lhHEiG_form-check-input.lhHEiG_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:valid ~ .lhHEiG_form-check-label, .lhHEiG_form-check-input.lhHEiG_is-valid ~ .lhHEiG_form-check-label {
  color: #198754;
}

.lhHEiG_form-check-inline .lhHEiG_form-check-input ~ .lhHEiG_valid-feedback {
  margin-left: .5em;
}

.lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-control:not(:focus):valid, .lhHEiG_input-group > .lhHEiG_form-control:not(:focus).lhHEiG_is-valid, .lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-select:not(:focus):valid, .lhHEiG_input-group > .lhHEiG_form-select:not(:focus).lhHEiG_is-valid, .lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-floating:not(:focus-within):valid, .lhHEiG_input-group > .lhHEiG_form-floating:not(:focus-within).lhHEiG_is-valid {
  z-index: 3;
}

.lhHEiG_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.lhHEiG_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.lhHEiG_was-validated :invalid ~ .lhHEiG_invalid-feedback, .lhHEiG_was-validated :invalid ~ .lhHEiG_invalid-tooltip, .lhHEiG_is-invalid ~ .lhHEiG_invalid-feedback, .lhHEiG_is-invalid ~ .lhHEiG_invalid-tooltip {
  display: block;
}

.lhHEiG_was-validated .lhHEiG_form-control:invalid, .lhHEiG_form-control.lhHEiG_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.lhHEiG_was-validated .lhHEiG_form-control:invalid:focus, .lhHEiG_form-control.lhHEiG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.lhHEiG_was-validated textarea.lhHEiG_form-control:invalid, textarea.lhHEiG_form-control.lhHEiG_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.lhHEiG_was-validated .lhHEiG_form-select:invalid, .lhHEiG_form-select.lhHEiG_is-invalid {
  border-color: #dc3545;
}

.lhHEiG_was-validated .lhHEiG_form-select:invalid:not([multiple]):not([size]), .lhHEiG_was-validated .lhHEiG_form-select:invalid:not([multiple])[size="1"], .lhHEiG_form-select.lhHEiG_is-invalid:not([multiple]):not([size]), .lhHEiG_form-select.lhHEiG_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.lhHEiG_was-validated .lhHEiG_form-select:invalid:focus, .lhHEiG_form-select.lhHEiG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.lhHEiG_was-validated .lhHEiG_form-control-color:invalid, .lhHEiG_form-control-color.lhHEiG_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.lhHEiG_was-validated .lhHEiG_form-check-input:invalid, .lhHEiG_form-check-input.lhHEiG_is-invalid {
  border-color: #dc3545;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:invalid:checked, .lhHEiG_form-check-input.lhHEiG_is-invalid:checked {
  background-color: #dc3545;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:invalid:focus, .lhHEiG_form-check-input.lhHEiG_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.lhHEiG_was-validated .lhHEiG_form-check-input:invalid ~ .lhHEiG_form-check-label, .lhHEiG_form-check-input.lhHEiG_is-invalid ~ .lhHEiG_form-check-label {
  color: #dc3545;
}

.lhHEiG_form-check-inline .lhHEiG_form-check-input ~ .lhHEiG_invalid-feedback {
  margin-left: .5em;
}

.lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-control:not(:focus):invalid, .lhHEiG_input-group > .lhHEiG_form-control:not(:focus).lhHEiG_is-invalid, .lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-select:not(:focus):invalid, .lhHEiG_input-group > .lhHEiG_form-select:not(:focus).lhHEiG_is-invalid, .lhHEiG_was-validated .lhHEiG_input-group > .lhHEiG_form-floating:not(:focus-within):invalid, .lhHEiG_input-group > .lhHEiG_form-floating:not(:focus-within).lhHEiG_is-invalid {
  z-index: 4;
}

.lhHEiG_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_btn {
    transition: none;
  }
}

.lhHEiG_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.lhHEiG_btn-check + .lhHEiG_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.lhHEiG_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.lhHEiG_btn-check:focus-visible + .lhHEiG_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.lhHEiG_btn-check:checked + .lhHEiG_btn, :not(.lhHEiG_btn-check) + .lhHEiG_btn:active, .lhHEiG_btn:first-child:active, .lhHEiG_btn.lhHEiG_active, .lhHEiG_btn.lhHEiG_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.lhHEiG_btn-check:checked + .lhHEiG_btn:focus-visible, :not(.lhHEiG_btn-check) + .lhHEiG_btn:active:focus-visible, .lhHEiG_btn:first-child:active:focus-visible, .lhHEiG_btn.lhHEiG_active:focus-visible, .lhHEiG_btn.lhHEiG_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.lhHEiG_btn:disabled, .lhHEiG_btn.lhHEiG_disabled, fieldset:disabled .lhHEiG_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.lhHEiG_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.lhHEiG_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.lhHEiG_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.lhHEiG_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.lhHEiG_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.lhHEiG_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.lhHEiG_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.lhHEiG_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.lhHEiG_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.lhHEiG_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.lhHEiG_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.lhHEiG_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.lhHEiG_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.lhHEiG_btn-lg, .lhHEiG_btn-group-lg > .lhHEiG_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.lhHEiG_btn-sm, .lhHEiG_btn-group-sm > .lhHEiG_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.lhHEiG_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_fade {
    transition: none;
  }
}

.lhHEiG_fade:not(.lhHEiG_show) {
  opacity: 0;
}

.lhHEiG_collapse:not(.lhHEiG_show) {
  display: none;
}

.lhHEiG_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_collapsing {
    transition: none;
  }
}

.lhHEiG_collapsing.lhHEiG_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_collapsing.lhHEiG_collapse-horizontal {
    transition: none;
  }
}

.lhHEiG_dropup, .lhHEiG_dropend, .lhHEiG_dropdown, .lhHEiG_dropstart, .lhHEiG_dropup-center, .lhHEiG_dropdown-center {
  position: relative;
}

.lhHEiG_dropdown-toggle {
  white-space: nowrap;
}

.lhHEiG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.lhHEiG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.lhHEiG_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.lhHEiG_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.lhHEiG_dropdown-menu-start {
  --bs-position: start;
}

.lhHEiG_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.lhHEiG_dropdown-menu-end {
  --bs-position: end;
}

.lhHEiG_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .lhHEiG_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .lhHEiG_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .lhHEiG_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .lhHEiG_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .lhHEiG_dropdown-menu-md-start {
    --bs-position: start;
  }

  .lhHEiG_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .lhHEiG_dropdown-menu-md-end {
    --bs-position: end;
  }

  .lhHEiG_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .lhHEiG_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .lhHEiG_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .lhHEiG_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .lhHEiG_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .lhHEiG_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .lhHEiG_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .lhHEiG_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .lhHEiG_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .lhHEiG_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .lhHEiG_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.lhHEiG_dropup .lhHEiG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.lhHEiG_dropup .lhHEiG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.lhHEiG_dropup .lhHEiG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.lhHEiG_dropend .lhHEiG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.lhHEiG_dropend .lhHEiG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.lhHEiG_dropend .lhHEiG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.lhHEiG_dropend .lhHEiG_dropdown-toggle:after {
  vertical-align: 0;
}

.lhHEiG_dropstart .lhHEiG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.lhHEiG_dropstart .lhHEiG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.lhHEiG_dropstart .lhHEiG_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.lhHEiG_dropstart .lhHEiG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.lhHEiG_dropstart .lhHEiG_dropdown-toggle:before {
  vertical-align: 0;
}

.lhHEiG_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.lhHEiG_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.lhHEiG_dropdown-item:hover, .lhHEiG_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.lhHEiG_dropdown-item.lhHEiG_active, .lhHEiG_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.lhHEiG_dropdown-item.lhHEiG_disabled, .lhHEiG_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.lhHEiG_dropdown-menu.lhHEiG_show {
  display: block;
}

.lhHEiG_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.lhHEiG_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.lhHEiG_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.lhHEiG_btn-group, .lhHEiG_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.lhHEiG_btn-group > .lhHEiG_btn, .lhHEiG_btn-group-vertical > .lhHEiG_btn {
  flex: auto;
  position: relative;
}

.lhHEiG_btn-group > .lhHEiG_btn-check:checked + .lhHEiG_btn, .lhHEiG_btn-group > .lhHEiG_btn-check:focus + .lhHEiG_btn, .lhHEiG_btn-group > .lhHEiG_btn:hover, .lhHEiG_btn-group > .lhHEiG_btn:focus, .lhHEiG_btn-group > .lhHEiG_btn:active, .lhHEiG_btn-group > .lhHEiG_btn.lhHEiG_active, .lhHEiG_btn-group-vertical > .lhHEiG_btn-check:checked + .lhHEiG_btn, .lhHEiG_btn-group-vertical > .lhHEiG_btn-check:focus + .lhHEiG_btn, .lhHEiG_btn-group-vertical > .lhHEiG_btn:hover, .lhHEiG_btn-group-vertical > .lhHEiG_btn:focus, .lhHEiG_btn-group-vertical > .lhHEiG_btn:active, .lhHEiG_btn-group-vertical > .lhHEiG_btn.lhHEiG_active {
  z-index: 1;
}

.lhHEiG_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.lhHEiG_btn-toolbar .lhHEiG_input-group {
  width: auto;
}

.lhHEiG_btn-group {
  border-radius: .375rem;
}

.lhHEiG_btn-group > :not(.lhHEiG_btn-check:first-child) + .lhHEiG_btn, .lhHEiG_btn-group > .lhHEiG_btn-group:not(:first-child) {
  margin-left: -1px;
}

.lhHEiG_btn-group > .lhHEiG_btn:not(:last-child):not(.lhHEiG_dropdown-toggle), .lhHEiG_btn-group > .lhHEiG_btn.lhHEiG_dropdown-toggle-split:first-child, .lhHEiG_btn-group > .lhHEiG_btn-group:not(:last-child) > .lhHEiG_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.lhHEiG_btn-group > .lhHEiG_btn:nth-child(n+3), .lhHEiG_btn-group > :not(.lhHEiG_btn-check) + .lhHEiG_btn, .lhHEiG_btn-group > .lhHEiG_btn-group:not(:first-child) > .lhHEiG_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.lhHEiG_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.lhHEiG_dropdown-toggle-split:after, .lhHEiG_dropup .lhHEiG_dropdown-toggle-split:after, .lhHEiG_dropend .lhHEiG_dropdown-toggle-split:after {
  margin-left: 0;
}

.lhHEiG_dropstart .lhHEiG_dropdown-toggle-split:before {
  margin-right: 0;
}

.lhHEiG_btn-sm + .lhHEiG_dropdown-toggle-split, .lhHEiG_btn-group-sm > .lhHEiG_btn + .lhHEiG_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.lhHEiG_btn-lg + .lhHEiG_dropdown-toggle-split, .lhHEiG_btn-group-lg > .lhHEiG_btn + .lhHEiG_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.lhHEiG_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.lhHEiG_btn-group-vertical > .lhHEiG_btn, .lhHEiG_btn-group-vertical > .lhHEiG_btn-group {
  width: 100%;
}

.lhHEiG_btn-group-vertical > .lhHEiG_btn:not(:first-child), .lhHEiG_btn-group-vertical > .lhHEiG_btn-group:not(:first-child) {
  margin-top: -1px;
}

.lhHEiG_btn-group-vertical > .lhHEiG_btn:not(:last-child):not(.lhHEiG_dropdown-toggle), .lhHEiG_btn-group-vertical > .lhHEiG_btn-group:not(:last-child) > .lhHEiG_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.lhHEiG_btn-group-vertical > .lhHEiG_btn ~ .lhHEiG_btn, .lhHEiG_btn-group-vertical > .lhHEiG_btn-group:not(:first-child) > .lhHEiG_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.lhHEiG_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.lhHEiG_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_nav-link {
    transition: none;
  }
}

.lhHEiG_nav-link:hover, .lhHEiG_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.lhHEiG_nav-link.lhHEiG_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.lhHEiG_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.lhHEiG_nav-tabs .lhHEiG_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.lhHEiG_nav-tabs .lhHEiG_nav-link:hover, .lhHEiG_nav-tabs .lhHEiG_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.lhHEiG_nav-tabs .lhHEiG_nav-link.lhHEiG_disabled, .lhHEiG_nav-tabs .lhHEiG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.lhHEiG_nav-tabs .lhHEiG_nav-link.lhHEiG_active, .lhHEiG_nav-tabs .lhHEiG_nav-item.lhHEiG_show .lhHEiG_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.lhHEiG_nav-tabs .lhHEiG_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.lhHEiG_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.lhHEiG_nav-pills .lhHEiG_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.lhHEiG_nav-pills .lhHEiG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.lhHEiG_nav-pills .lhHEiG_nav-link.lhHEiG_active, .lhHEiG_nav-pills .lhHEiG_show > .lhHEiG_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.lhHEiG_nav-fill > .lhHEiG_nav-link, .lhHEiG_nav-fill .lhHEiG_nav-item {
  text-align: center;
  flex: auto;
}

.lhHEiG_nav-justified > .lhHEiG_nav-link, .lhHEiG_nav-justified .lhHEiG_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.lhHEiG_nav-fill .lhHEiG_nav-item .lhHEiG_nav-link, .lhHEiG_nav-justified .lhHEiG_nav-item .lhHEiG_nav-link {
  width: 100%;
}

.lhHEiG_tab-content > .lhHEiG_tab-pane {
  display: none;
}

.lhHEiG_tab-content > .lhHEiG_active {
  display: block;
}

.lhHEiG_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.lhHEiG_navbar > .lhHEiG_container, .lhHEiG_navbar > .lhHEiG_container-fluid, .lhHEiG_navbar > .lhHEiG_container-sm, .lhHEiG_navbar > .lhHEiG_container-md, .lhHEiG_navbar > .lhHEiG_container-lg, .lhHEiG_navbar > .lhHEiG_container-xl, .lhHEiG_navbar > .lhHEiG_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.lhHEiG_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.lhHEiG_navbar-brand:hover, .lhHEiG_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.lhHEiG_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.lhHEiG_navbar-nav .lhHEiG_show > .lhHEiG_nav-link, .lhHEiG_navbar-nav .lhHEiG_nav-link.lhHEiG_active {
  color: var(--bs-navbar-active-color);
}

.lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
  position: static;
}

.lhHEiG_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.lhHEiG_navbar-text a, .lhHEiG_navbar-text a:hover, .lhHEiG_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.lhHEiG_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.lhHEiG_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_navbar-toggler {
    transition: none;
  }
}

.lhHEiG_navbar-toggler:hover {
  text-decoration: none;
}

.lhHEiG_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.lhHEiG_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.lhHEiG_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .lhHEiG_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-nav {
    flex-direction: row;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
    position: absolute;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-nav .lhHEiG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-nav-scroll {
    overflow: visible;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_navbar-toggler {
    display: none;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_navbar-expand-sm .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .lhHEiG_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-nav {
    flex-direction: row;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
    position: absolute;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-nav .lhHEiG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-nav-scroll {
    overflow: visible;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_navbar-toggler {
    display: none;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_navbar-expand-md .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .lhHEiG_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-nav {
    flex-direction: row;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
    position: absolute;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-nav .lhHEiG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-nav-scroll {
    overflow: visible;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_navbar-toggler {
    display: none;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_navbar-expand-lg .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-nav {
    flex-direction: row;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
    position: absolute;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-nav .lhHEiG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-nav-scroll {
    overflow: visible;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_navbar-toggler {
    display: none;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_navbar-expand-xl .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-nav {
    flex-direction: row;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
    position: absolute;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-nav .lhHEiG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-nav-scroll {
    overflow: visible;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_navbar-toggler {
    display: none;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_navbar-expand-xxl .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.lhHEiG_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.lhHEiG_navbar-expand .lhHEiG_navbar-nav {
  flex-direction: row;
}

.lhHEiG_navbar-expand .lhHEiG_navbar-nav .lhHEiG_dropdown-menu {
  position: absolute;
}

.lhHEiG_navbar-expand .lhHEiG_navbar-nav .lhHEiG_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.lhHEiG_navbar-expand .lhHEiG_navbar-nav-scroll {
  overflow: visible;
}

.lhHEiG_navbar-expand .lhHEiG_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.lhHEiG_navbar-expand .lhHEiG_navbar-toggler {
  display: none;
}

.lhHEiG_navbar-expand .lhHEiG_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.lhHEiG_navbar-expand .lhHEiG_offcanvas .lhHEiG_offcanvas-header {
  display: none;
}

.lhHEiG_navbar-expand .lhHEiG_offcanvas .lhHEiG_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.lhHEiG_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.lhHEiG_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.lhHEiG_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.lhHEiG_card > .lhHEiG_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.lhHEiG_card > .lhHEiG_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.lhHEiG_card > .lhHEiG_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.lhHEiG_card > .lhHEiG_card-header + .lhHEiG_list-group, .lhHEiG_card > .lhHEiG_list-group + .lhHEiG_card-footer {
  border-top: 0;
}

.lhHEiG_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.lhHEiG_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.lhHEiG_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.lhHEiG_card-text:last-child {
  margin-bottom: 0;
}

.lhHEiG_card-link + .lhHEiG_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.lhHEiG_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.lhHEiG_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.lhHEiG_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.lhHEiG_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.lhHEiG_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.lhHEiG_card-header-tabs .lhHEiG_nav-link.lhHEiG_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.lhHEiG_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.lhHEiG_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.lhHEiG_card-img, .lhHEiG_card-img-top, .lhHEiG_card-img-bottom {
  width: 100%;
}

.lhHEiG_card-img, .lhHEiG_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.lhHEiG_card-img, .lhHEiG_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.lhHEiG_card-group > .lhHEiG_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .lhHEiG_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .lhHEiG_card-group > .lhHEiG_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card + .lhHEiG_card {
    border-left: 0;
    margin-left: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:last-child) .lhHEiG_card-img-top, .lhHEiG_card-group > .lhHEiG_card:not(:last-child) .lhHEiG_card-header {
    border-top-right-radius: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:last-child) .lhHEiG_card-img-bottom, .lhHEiG_card-group > .lhHEiG_card:not(:last-child) .lhHEiG_card-footer {
    border-bottom-right-radius: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:first-child) .lhHEiG_card-img-top, .lhHEiG_card-group > .lhHEiG_card:not(:first-child) .lhHEiG_card-header {
    border-top-left-radius: 0;
  }

  .lhHEiG_card-group > .lhHEiG_card:not(:first-child) .lhHEiG_card-img-bottom, .lhHEiG_card-group > .lhHEiG_card:not(:first-child) .lhHEiG_card-footer {
    border-bottom-left-radius: 0;
  }
}

.lhHEiG_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.lhHEiG_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_accordion-button {
    transition: none;
  }
}

.lhHEiG_accordion-button:not(.lhHEiG_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.lhHEiG_accordion-button:not(.lhHEiG_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.lhHEiG_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_accordion-button:after {
    transition: none;
  }
}

.lhHEiG_accordion-button:hover {
  z-index: 2;
}

.lhHEiG_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.lhHEiG_accordion-header {
  margin-bottom: 0;
}

.lhHEiG_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.lhHEiG_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.lhHEiG_accordion-item:first-of-type .lhHEiG_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.lhHEiG_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.lhHEiG_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.lhHEiG_accordion-item:last-of-type .lhHEiG_accordion-button.lhHEiG_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.lhHEiG_accordion-item:last-of-type .lhHEiG_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.lhHEiG_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.lhHEiG_accordion-flush .lhHEiG_accordion-collapse {
  border-width: 0;
}

.lhHEiG_accordion-flush .lhHEiG_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.lhHEiG_accordion-flush .lhHEiG_accordion-item:first-child {
  border-top: 0;
}

.lhHEiG_accordion-flush .lhHEiG_accordion-item:last-child {
  border-bottom: 0;
}

.lhHEiG_accordion-flush .lhHEiG_accordion-item .lhHEiG_accordion-button, .lhHEiG_accordion-flush .lhHEiG_accordion-item .lhHEiG_accordion-button.lhHEiG_collapsed {
  border-radius: 0;
}

.lhHEiG_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.lhHEiG_breadcrumb-item + .lhHEiG_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.lhHEiG_breadcrumb-item + .lhHEiG_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.lhHEiG_breadcrumb-item.lhHEiG_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.lhHEiG_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.lhHEiG_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_page-link {
    transition: none;
  }
}

.lhHEiG_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.lhHEiG_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.lhHEiG_page-link.lhHEiG_active, .lhHEiG_active > .lhHEiG_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.lhHEiG_page-link.lhHEiG_disabled, .lhHEiG_disabled > .lhHEiG_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.lhHEiG_page-item:not(:first-child) .lhHEiG_page-link {
  margin-left: -1px;
}

.lhHEiG_page-item:first-child .lhHEiG_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.lhHEiG_page-item:last-child .lhHEiG_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.lhHEiG_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.lhHEiG_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.lhHEiG_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.lhHEiG_badge:empty {
  display: none;
}

.lhHEiG_btn .lhHEiG_badge {
  position: relative;
  top: -1px;
}

.lhHEiG_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.lhHEiG_alert-heading {
  color: inherit;
}

.lhHEiG_alert-link {
  font-weight: 700;
}

.lhHEiG_alert-dismissible {
  padding-right: 3rem;
}

.lhHEiG_alert-dismissible .lhHEiG_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.lhHEiG_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.lhHEiG_alert-primary .lhHEiG_alert-link {
  color: #25224b;
}

.lhHEiG_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.lhHEiG_alert-secondary .lhHEiG_alert-link {
  color: #34383c;
}

.lhHEiG_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.lhHEiG_alert-success .lhHEiG_alert-link {
  color: #0c4128;
}

.lhHEiG_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.lhHEiG_alert-info .lhHEiG_alert-link {
  color: #04414d;
}

.lhHEiG_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.lhHEiG_alert-warning .lhHEiG_alert-link {
  color: #523e02;
}

.lhHEiG_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.lhHEiG_alert-danger .lhHEiG_alert-link {
  color: #6a1a21;
}

.lhHEiG_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.lhHEiG_alert-light .lhHEiG_alert-link {
  color: #4f5050;
}

.lhHEiG_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.lhHEiG_alert-dark .lhHEiG_alert-link {
  color: #101214;
}

@keyframes lhHEiG_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.lhHEiG_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.lhHEiG_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_progress-bar {
    transition: none;
  }
}

.lhHEiG_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.lhHEiG_progress-bar-animated {
  animation: 1s linear infinite lhHEiG_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_progress-bar-animated {
    animation: none;
  }
}

.lhHEiG_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.lhHEiG_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.lhHEiG_list-group-numbered > .lhHEiG_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.lhHEiG_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.lhHEiG_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.lhHEiG_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.lhHEiG_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.lhHEiG_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.lhHEiG_list-group-item.lhHEiG_disabled, .lhHEiG_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.lhHEiG_list-group-item.lhHEiG_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.lhHEiG_list-group-item + .lhHEiG_list-group-item {
  border-top-width: 0;
}

.lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.lhHEiG_list-group-horizontal {
  flex-direction: row;
}

.lhHEiG_list-group-horizontal > .lhHEiG_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.lhHEiG_list-group-horizontal > .lhHEiG_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.lhHEiG_list-group-horizontal > .lhHEiG_list-group-item.lhHEiG_active {
  margin-top: 0;
}

.lhHEiG_list-group-horizontal > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.lhHEiG_list-group-horizontal > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .lhHEiG_list-group-horizontal-sm {
    flex-direction: row;
  }

  .lhHEiG_list-group-horizontal-sm > .lhHEiG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .lhHEiG_list-group-horizontal-sm > .lhHEiG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .lhHEiG_list-group-horizontal-sm > .lhHEiG_list-group-item.lhHEiG_active {
    margin-top: 0;
  }

  .lhHEiG_list-group-horizontal-sm > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .lhHEiG_list-group-horizontal-sm > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .lhHEiG_list-group-horizontal-md {
    flex-direction: row;
  }

  .lhHEiG_list-group-horizontal-md > .lhHEiG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .lhHEiG_list-group-horizontal-md > .lhHEiG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .lhHEiG_list-group-horizontal-md > .lhHEiG_list-group-item.lhHEiG_active {
    margin-top: 0;
  }

  .lhHEiG_list-group-horizontal-md > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .lhHEiG_list-group-horizontal-md > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .lhHEiG_list-group-horizontal-lg {
    flex-direction: row;
  }

  .lhHEiG_list-group-horizontal-lg > .lhHEiG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .lhHEiG_list-group-horizontal-lg > .lhHEiG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .lhHEiG_list-group-horizontal-lg > .lhHEiG_list-group-item.lhHEiG_active {
    margin-top: 0;
  }

  .lhHEiG_list-group-horizontal-lg > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .lhHEiG_list-group-horizontal-lg > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .lhHEiG_list-group-horizontal-xl {
    flex-direction: row;
  }

  .lhHEiG_list-group-horizontal-xl > .lhHEiG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .lhHEiG_list-group-horizontal-xl > .lhHEiG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .lhHEiG_list-group-horizontal-xl > .lhHEiG_list-group-item.lhHEiG_active {
    margin-top: 0;
  }

  .lhHEiG_list-group-horizontal-xl > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .lhHEiG_list-group-horizontal-xl > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .lhHEiG_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .lhHEiG_list-group-horizontal-xxl > .lhHEiG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .lhHEiG_list-group-horizontal-xxl > .lhHEiG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .lhHEiG_list-group-horizontal-xxl > .lhHEiG_list-group-item.lhHEiG_active {
    margin-top: 0;
  }

  .lhHEiG_list-group-horizontal-xxl > .lhHEiG_list-group-item + .lhHEiG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .lhHEiG_list-group-horizontal-xxl > .lhHEiG_list-group-item + .lhHEiG_list-group-item.lhHEiG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.lhHEiG_list-group-flush {
  border-radius: 0;
}

.lhHEiG_list-group-flush > .lhHEiG_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.lhHEiG_list-group-flush > .lhHEiG_list-group-item:last-child {
  border-bottom-width: 0;
}

.lhHEiG_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.lhHEiG_list-group-item-primary.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-primary.lhHEiG_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.lhHEiG_list-group-item-primary.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.lhHEiG_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.lhHEiG_list-group-item-secondary.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-secondary.lhHEiG_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.lhHEiG_list-group-item-secondary.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.lhHEiG_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.lhHEiG_list-group-item-success.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-success.lhHEiG_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.lhHEiG_list-group-item-success.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.lhHEiG_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.lhHEiG_list-group-item-info.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-info.lhHEiG_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.lhHEiG_list-group-item-info.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.lhHEiG_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.lhHEiG_list-group-item-warning.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-warning.lhHEiG_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.lhHEiG_list-group-item-warning.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.lhHEiG_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.lhHEiG_list-group-item-danger.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-danger.lhHEiG_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.lhHEiG_list-group-item-danger.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.lhHEiG_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.lhHEiG_list-group-item-light.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-light.lhHEiG_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.lhHEiG_list-group-item-light.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.lhHEiG_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.lhHEiG_list-group-item-dark.lhHEiG_list-group-item-action:hover, .lhHEiG_list-group-item-dark.lhHEiG_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.lhHEiG_list-group-item-dark.lhHEiG_list-group-item-action.lhHEiG_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.lhHEiG_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.lhHEiG_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.lhHEiG_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.lhHEiG_btn-close:disabled, .lhHEiG_btn-close.lhHEiG_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.lhHEiG_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.lhHEiG_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.lhHEiG_toast.lhHEiG_showing {
  opacity: 0;
}

.lhHEiG_toast:not(.lhHEiG_show) {
  display: none;
}

.lhHEiG_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.lhHEiG_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.lhHEiG_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.lhHEiG_toast-header .lhHEiG_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.lhHEiG_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.lhHEiG_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.lhHEiG_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.lhHEiG_modal.lhHEiG_fade .lhHEiG_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_modal.lhHEiG_fade .lhHEiG_modal-dialog {
    transition: none;
  }
}

.lhHEiG_modal.lhHEiG_show .lhHEiG_modal-dialog {
  transform: none;
}

.lhHEiG_modal.lhHEiG_modal-static .lhHEiG_modal-dialog {
  transform: scale(1.02);
}

.lhHEiG_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.lhHEiG_modal-dialog-scrollable .lhHEiG_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.lhHEiG_modal-dialog-scrollable .lhHEiG_modal-body {
  overflow-y: auto;
}

.lhHEiG_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.lhHEiG_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.lhHEiG_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.lhHEiG_modal-backdrop.lhHEiG_fade {
  opacity: 0;
}

.lhHEiG_modal-backdrop.lhHEiG_show {
  opacity: var(--bs-backdrop-opacity);
}

.lhHEiG_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.lhHEiG_modal-header .lhHEiG_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.lhHEiG_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.lhHEiG_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.lhHEiG_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.lhHEiG_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .lhHEiG_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .lhHEiG_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .lhHEiG_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .lhHEiG_modal-lg, .lhHEiG_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.lhHEiG_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.lhHEiG_modal-fullscreen .lhHEiG_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.lhHEiG_modal-fullscreen .lhHEiG_modal-header, .lhHEiG_modal-fullscreen .lhHEiG_modal-footer {
  border-radius: 0;
}

.lhHEiG_modal-fullscreen .lhHEiG_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .lhHEiG_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .lhHEiG_modal-fullscreen-sm-down .lhHEiG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-sm-down .lhHEiG_modal-header, .lhHEiG_modal-fullscreen-sm-down .lhHEiG_modal-footer {
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-sm-down .lhHEiG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .lhHEiG_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .lhHEiG_modal-fullscreen-md-down .lhHEiG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-md-down .lhHEiG_modal-header, .lhHEiG_modal-fullscreen-md-down .lhHEiG_modal-footer {
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-md-down .lhHEiG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .lhHEiG_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .lhHEiG_modal-fullscreen-lg-down .lhHEiG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-lg-down .lhHEiG_modal-header, .lhHEiG_modal-fullscreen-lg-down .lhHEiG_modal-footer {
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-lg-down .lhHEiG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .lhHEiG_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .lhHEiG_modal-fullscreen-xl-down .lhHEiG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-xl-down .lhHEiG_modal-header, .lhHEiG_modal-fullscreen-xl-down .lhHEiG_modal-footer {
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-xl-down .lhHEiG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .lhHEiG_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .lhHEiG_modal-fullscreen-xxl-down .lhHEiG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-xxl-down .lhHEiG_modal-header, .lhHEiG_modal-fullscreen-xxl-down .lhHEiG_modal-footer {
    border-radius: 0;
  }

  .lhHEiG_modal-fullscreen-xxl-down .lhHEiG_modal-body {
    overflow-y: auto;
  }
}

.lhHEiG_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.lhHEiG_tooltip.lhHEiG_show {
  opacity: var(--bs-tooltip-opacity);
}

.lhHEiG_tooltip .lhHEiG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.lhHEiG_tooltip .lhHEiG_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.lhHEiG_bs-tooltip-top .lhHEiG_tooltip-arrow, .lhHEiG_bs-tooltip-auto[data-popper-placement^="top"] .lhHEiG_tooltip-arrow {
  bottom: 0;
}

.lhHEiG_bs-tooltip-top .lhHEiG_tooltip-arrow:before, .lhHEiG_bs-tooltip-auto[data-popper-placement^="top"] .lhHEiG_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.lhHEiG_bs-tooltip-end .lhHEiG_tooltip-arrow, .lhHEiG_bs-tooltip-auto[data-popper-placement^="right"] .lhHEiG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.lhHEiG_bs-tooltip-end .lhHEiG_tooltip-arrow:before, .lhHEiG_bs-tooltip-auto[data-popper-placement^="right"] .lhHEiG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.lhHEiG_bs-tooltip-bottom .lhHEiG_tooltip-arrow, .lhHEiG_bs-tooltip-auto[data-popper-placement^="bottom"] .lhHEiG_tooltip-arrow {
  top: 0;
}

.lhHEiG_bs-tooltip-bottom .lhHEiG_tooltip-arrow:before, .lhHEiG_bs-tooltip-auto[data-popper-placement^="bottom"] .lhHEiG_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.lhHEiG_bs-tooltip-start .lhHEiG_tooltip-arrow, .lhHEiG_bs-tooltip-auto[data-popper-placement^="left"] .lhHEiG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.lhHEiG_bs-tooltip-start .lhHEiG_tooltip-arrow:before, .lhHEiG_bs-tooltip-auto[data-popper-placement^="left"] .lhHEiG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.lhHEiG_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.lhHEiG_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.lhHEiG_popover .lhHEiG_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.lhHEiG_popover .lhHEiG_popover-arrow:before, .lhHEiG_popover .lhHEiG_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.lhHEiG_bs-popover-top > .lhHEiG_popover-arrow, .lhHEiG_bs-popover-auto[data-popper-placement^="top"] > .lhHEiG_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.lhHEiG_bs-popover-top > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="top"] > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-top > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="top"] > .lhHEiG_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.lhHEiG_bs-popover-top > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="top"] > .lhHEiG_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.lhHEiG_bs-popover-top > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="top"] > .lhHEiG_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.lhHEiG_bs-popover-end > .lhHEiG_popover-arrow, .lhHEiG_bs-popover-auto[data-popper-placement^="right"] > .lhHEiG_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.lhHEiG_bs-popover-end > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="right"] > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-end > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="right"] > .lhHEiG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.lhHEiG_bs-popover-end > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="right"] > .lhHEiG_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.lhHEiG_bs-popover-end > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="right"] > .lhHEiG_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.lhHEiG_bs-popover-bottom > .lhHEiG_popover-arrow, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] > .lhHEiG_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.lhHEiG_bs-popover-bottom > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-bottom > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] > .lhHEiG_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.lhHEiG_bs-popover-bottom > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] > .lhHEiG_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.lhHEiG_bs-popover-bottom > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] > .lhHEiG_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.lhHEiG_bs-popover-bottom .lhHEiG_popover-header:before, .lhHEiG_bs-popover-auto[data-popper-placement^="bottom"] .lhHEiG_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.lhHEiG_bs-popover-start > .lhHEiG_popover-arrow, .lhHEiG_bs-popover-auto[data-popper-placement^="left"] > .lhHEiG_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.lhHEiG_bs-popover-start > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="left"] > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-start > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="left"] > .lhHEiG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.lhHEiG_bs-popover-start > .lhHEiG_popover-arrow:before, .lhHEiG_bs-popover-auto[data-popper-placement^="left"] > .lhHEiG_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.lhHEiG_bs-popover-start > .lhHEiG_popover-arrow:after, .lhHEiG_bs-popover-auto[data-popper-placement^="left"] > .lhHEiG_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.lhHEiG_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.lhHEiG_popover-header:empty {
  display: none;
}

.lhHEiG_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.lhHEiG_carousel {
  position: relative;
}

.lhHEiG_carousel.lhHEiG_pointer-event {
  touch-action: pan-y;
}

.lhHEiG_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.lhHEiG_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.lhHEiG_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_carousel-item {
    transition: none;
  }
}

.lhHEiG_carousel-item.lhHEiG_active, .lhHEiG_carousel-item-next, .lhHEiG_carousel-item-prev {
  display: block;
}

.lhHEiG_carousel-item-next:not(.lhHEiG_carousel-item-start), .lhHEiG_active.lhHEiG_carousel-item-end {
  transform: translateX(100%);
}

.lhHEiG_carousel-item-prev:not(.lhHEiG_carousel-item-end), .lhHEiG_active.lhHEiG_carousel-item-start {
  transform: translateX(-100%);
}

.lhHEiG_carousel-fade .lhHEiG_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.lhHEiG_carousel-fade .lhHEiG_carousel-item.lhHEiG_active, .lhHEiG_carousel-fade .lhHEiG_carousel-item-next.lhHEiG_carousel-item-start, .lhHEiG_carousel-fade .lhHEiG_carousel-item-prev.lhHEiG_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.lhHEiG_carousel-fade .lhHEiG_active.lhHEiG_carousel-item-start, .lhHEiG_carousel-fade .lhHEiG_active.lhHEiG_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_carousel-fade .lhHEiG_active.lhHEiG_carousel-item-start, .lhHEiG_carousel-fade .lhHEiG_active.lhHEiG_carousel-item-end {
    transition: none;
  }
}

.lhHEiG_carousel-control-prev, .lhHEiG_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_carousel-control-prev, .lhHEiG_carousel-control-next {
    transition: none;
  }
}

.lhHEiG_carousel-control-prev:hover, .lhHEiG_carousel-control-prev:focus, .lhHEiG_carousel-control-next:hover, .lhHEiG_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.lhHEiG_carousel-control-prev {
  left: 0;
}

.lhHEiG_carousel-control-next {
  right: 0;
}

.lhHEiG_carousel-control-prev-icon, .lhHEiG_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.lhHEiG_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.lhHEiG_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.lhHEiG_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lhHEiG_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.lhHEiG_carousel-indicators .lhHEiG_active {
  opacity: 1;
}

.lhHEiG_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.lhHEiG_carousel-dark .lhHEiG_carousel-control-prev-icon, .lhHEiG_carousel-dark .lhHEiG_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.lhHEiG_carousel-dark .lhHEiG_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.lhHEiG_carousel-dark .lhHEiG_carousel-caption {
  color: #000;
}

.lhHEiG_spinner-grow, .lhHEiG_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes lhHEiG_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.lhHEiG_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.lhHEiG_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes lhHEiG_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.lhHEiG_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.lhHEiG_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_spinner-border, .lhHEiG_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.lhHEiG_offcanvas, .lhHEiG_offcanvas-xxl, .lhHEiG_offcanvas-xl, .lhHEiG_offcanvas-lg, .lhHEiG_offcanvas-md, .lhHEiG_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .lhHEiG_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .lhHEiG_offcanvas-sm.lhHEiG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .lhHEiG_offcanvas-sm.lhHEiG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .lhHEiG_offcanvas-sm.lhHEiG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .lhHEiG_offcanvas-sm.lhHEiG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lhHEiG_offcanvas-sm.lhHEiG_showing, .lhHEiG_offcanvas-sm.lhHEiG_show:not(.lhHEiG_hiding) {
    transform: none;
  }

  .lhHEiG_offcanvas-sm.lhHEiG_showing, .lhHEiG_offcanvas-sm.lhHEiG_hiding, .lhHEiG_offcanvas-sm.lhHEiG_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .lhHEiG_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .lhHEiG_offcanvas-sm .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_offcanvas-sm .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .lhHEiG_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .lhHEiG_offcanvas-md.lhHEiG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .lhHEiG_offcanvas-md.lhHEiG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .lhHEiG_offcanvas-md.lhHEiG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .lhHEiG_offcanvas-md.lhHEiG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lhHEiG_offcanvas-md.lhHEiG_showing, .lhHEiG_offcanvas-md.lhHEiG_show:not(.lhHEiG_hiding) {
    transform: none;
  }

  .lhHEiG_offcanvas-md.lhHEiG_showing, .lhHEiG_offcanvas-md.lhHEiG_hiding, .lhHEiG_offcanvas-md.lhHEiG_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .lhHEiG_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .lhHEiG_offcanvas-md .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_offcanvas-md .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .lhHEiG_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .lhHEiG_offcanvas-lg.lhHEiG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .lhHEiG_offcanvas-lg.lhHEiG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .lhHEiG_offcanvas-lg.lhHEiG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .lhHEiG_offcanvas-lg.lhHEiG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lhHEiG_offcanvas-lg.lhHEiG_showing, .lhHEiG_offcanvas-lg.lhHEiG_show:not(.lhHEiG_hiding) {
    transform: none;
  }

  .lhHEiG_offcanvas-lg.lhHEiG_showing, .lhHEiG_offcanvas-lg.lhHEiG_hiding, .lhHEiG_offcanvas-lg.lhHEiG_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .lhHEiG_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .lhHEiG_offcanvas-lg .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_offcanvas-lg .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .lhHEiG_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .lhHEiG_offcanvas-xl.lhHEiG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .lhHEiG_offcanvas-xl.lhHEiG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .lhHEiG_offcanvas-xl.lhHEiG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .lhHEiG_offcanvas-xl.lhHEiG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lhHEiG_offcanvas-xl.lhHEiG_showing, .lhHEiG_offcanvas-xl.lhHEiG_show:not(.lhHEiG_hiding) {
    transform: none;
  }

  .lhHEiG_offcanvas-xl.lhHEiG_showing, .lhHEiG_offcanvas-xl.lhHEiG_hiding, .lhHEiG_offcanvas-xl.lhHEiG_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .lhHEiG_offcanvas-xl .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_offcanvas-xl .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .lhHEiG_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .lhHEiG_offcanvas-xxl.lhHEiG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .lhHEiG_offcanvas-xxl.lhHEiG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .lhHEiG_offcanvas-xxl.lhHEiG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .lhHEiG_offcanvas-xxl.lhHEiG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .lhHEiG_offcanvas-xxl.lhHEiG_showing, .lhHEiG_offcanvas-xxl.lhHEiG_show:not(.lhHEiG_hiding) {
    transform: none;
  }

  .lhHEiG_offcanvas-xxl.lhHEiG_showing, .lhHEiG_offcanvas-xxl.lhHEiG_hiding, .lhHEiG_offcanvas-xxl.lhHEiG_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .lhHEiG_offcanvas-xxl .lhHEiG_offcanvas-header {
    display: none;
  }

  .lhHEiG_offcanvas-xxl .lhHEiG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.lhHEiG_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .lhHEiG_offcanvas {
    transition: none;
  }
}

.lhHEiG_offcanvas.lhHEiG_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.lhHEiG_offcanvas.lhHEiG_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.lhHEiG_offcanvas.lhHEiG_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.lhHEiG_offcanvas.lhHEiG_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.lhHEiG_offcanvas.lhHEiG_showing, .lhHEiG_offcanvas.lhHEiG_show:not(.lhHEiG_hiding) {
  transform: none;
}

.lhHEiG_offcanvas.lhHEiG_showing, .lhHEiG_offcanvas.lhHEiG_hiding, .lhHEiG_offcanvas.lhHEiG_show {
  visibility: visible;
}

.lhHEiG_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.lhHEiG_offcanvas-backdrop.lhHEiG_fade {
  opacity: 0;
}

.lhHEiG_offcanvas-backdrop.lhHEiG_show {
  opacity: .5;
}

.lhHEiG_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.lhHEiG_offcanvas-header .lhHEiG_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.lhHEiG_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.lhHEiG_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.lhHEiG_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.lhHEiG_placeholder.lhHEiG_btn:before {
  content: "";
  display: inline-block;
}

.lhHEiG_placeholder-xs {
  min-height: .6em;
}

.lhHEiG_placeholder-sm {
  min-height: .8em;
}

.lhHEiG_placeholder-lg {
  min-height: 1.2em;
}

.lhHEiG_placeholder-glow .lhHEiG_placeholder {
  animation: 2s ease-in-out infinite lhHEiG_placeholder-glow;
}

@keyframes lhHEiG_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.lhHEiG_placeholder-wave {
  animation: 2s linear infinite lhHEiG_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes lhHEiG_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.lhHEiG_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.lhHEiG_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.lhHEiG_link-primary {
  color: #4c469c !important;
}

.lhHEiG_link-primary:hover, .lhHEiG_link-primary:focus {
  color: #3d387d !important;
}

.lhHEiG_link-secondary {
  color: #6c757d !important;
}

.lhHEiG_link-secondary:hover, .lhHEiG_link-secondary:focus {
  color: #565e64 !important;
}

.lhHEiG_link-success {
  color: #198754 !important;
}

.lhHEiG_link-success:hover, .lhHEiG_link-success:focus {
  color: #146c43 !important;
}

.lhHEiG_link-info {
  color: #0dcaf0 !important;
}

.lhHEiG_link-info:hover, .lhHEiG_link-info:focus {
  color: #3dd5f3 !important;
}

.lhHEiG_link-warning {
  color: #ffc107 !important;
}

.lhHEiG_link-warning:hover, .lhHEiG_link-warning:focus {
  color: #ffcd39 !important;
}

.lhHEiG_link-danger {
  color: #dc3545 !important;
}

.lhHEiG_link-danger:hover, .lhHEiG_link-danger:focus {
  color: #b02a37 !important;
}

.lhHEiG_link-light {
  color: #f8f9fa !important;
}

.lhHEiG_link-light:hover, .lhHEiG_link-light:focus {
  color: #f9fafb !important;
}

.lhHEiG_link-dark {
  color: #212529 !important;
}

.lhHEiG_link-dark:hover, .lhHEiG_link-dark:focus {
  color: #1a1e21 !important;
}

.lhHEiG_ratio {
  width: 100%;
  position: relative;
}

.lhHEiG_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.lhHEiG_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lhHEiG_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.lhHEiG_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.lhHEiG_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.lhHEiG_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.lhHEiG_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.lhHEiG_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.lhHEiG_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.lhHEiG_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .lhHEiG_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .lhHEiG_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .lhHEiG_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .lhHEiG_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .lhHEiG_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .lhHEiG_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .lhHEiG_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .lhHEiG_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.lhHEiG_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.lhHEiG_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.lhHEiG_visually-hidden, .lhHEiG_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.lhHEiG_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.lhHEiG_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lhHEiG_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.lhHEiG_align-baseline {
  vertical-align: baseline !important;
}

.lhHEiG_align-top {
  vertical-align: top !important;
}

.lhHEiG_align-middle {
  vertical-align: middle !important;
}

.lhHEiG_align-bottom {
  vertical-align: bottom !important;
}

.lhHEiG_align-text-bottom {
  vertical-align: text-bottom !important;
}

.lhHEiG_align-text-top {
  vertical-align: text-top !important;
}

.lhHEiG_float-start {
  float: left !important;
}

.lhHEiG_float-end {
  float: right !important;
}

.lhHEiG_float-none {
  float: none !important;
}

.lhHEiG_opacity-0 {
  opacity: 0 !important;
}

.lhHEiG_opacity-25 {
  opacity: .25 !important;
}

.lhHEiG_opacity-50 {
  opacity: .5 !important;
}

.lhHEiG_opacity-75 {
  opacity: .75 !important;
}

.lhHEiG_opacity-100 {
  opacity: 1 !important;
}

.lhHEiG_overflow-auto {
  overflow: auto !important;
}

.lhHEiG_overflow-hidden {
  overflow: hidden !important;
}

.lhHEiG_overflow-visible {
  overflow: visible !important;
}

.lhHEiG_overflow-scroll {
  overflow: scroll !important;
}

.lhHEiG_d-inline {
  display: inline !important;
}

.lhHEiG_d-inline-block {
  display: inline-block !important;
}

.lhHEiG_d-block {
  display: block !important;
}

.lhHEiG_d-grid {
  display: grid !important;
}

.lhHEiG_d-table {
  display: table !important;
}

.lhHEiG_d-table-row {
  display: table-row !important;
}

.lhHEiG_d-table-cell {
  display: table-cell !important;
}

.lhHEiG_d-flex {
  display: flex !important;
}

.lhHEiG_d-inline-flex {
  display: inline-flex !important;
}

.lhHEiG_d-none {
  display: none !important;
}

.lhHEiG_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.lhHEiG_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.lhHEiG_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.lhHEiG_shadow-none {
  box-shadow: none !important;
}

.lhHEiG_position-static {
  position: static !important;
}

.lhHEiG_position-relative {
  position: relative !important;
}

.lhHEiG_position-absolute {
  position: absolute !important;
}

.lhHEiG_position-fixed {
  position: fixed !important;
}

.lhHEiG_position-sticky {
  position: sticky !important;
}

.lhHEiG_top-0 {
  top: 0 !important;
}

.lhHEiG_top-50 {
  top: 50% !important;
}

.lhHEiG_top-100 {
  top: 100% !important;
}

.lhHEiG_bottom-0 {
  bottom: 0 !important;
}

.lhHEiG_bottom-50 {
  bottom: 50% !important;
}

.lhHEiG_bottom-100 {
  bottom: 100% !important;
}

.lhHEiG_start-0 {
  left: 0 !important;
}

.lhHEiG_start-50 {
  left: 50% !important;
}

.lhHEiG_start-100 {
  left: 100% !important;
}

.lhHEiG_end-0 {
  right: 0 !important;
}

.lhHEiG_end-50 {
  right: 50% !important;
}

.lhHEiG_end-100 {
  right: 100% !important;
}

.lhHEiG_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.lhHEiG_translate-middle-x {
  transform: translateX(-50%) !important;
}

.lhHEiG_translate-middle-y {
  transform: translateY(-50%) !important;
}

.lhHEiG_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lhHEiG_border-0 {
  border: 0 !important;
}

.lhHEiG_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lhHEiG_border-top-0 {
  border-top: 0 !important;
}

.lhHEiG_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lhHEiG_border-end-0 {
  border-right: 0 !important;
}

.lhHEiG_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lhHEiG_border-bottom-0 {
  border-bottom: 0 !important;
}

.lhHEiG_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lhHEiG_border-start-0 {
  border-left: 0 !important;
}

.lhHEiG_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.lhHEiG_border-1 {
  --bs-border-width: 1px;
}

.lhHEiG_border-2 {
  --bs-border-width: 2px;
}

.lhHEiG_border-3 {
  --bs-border-width: 3px;
}

.lhHEiG_border-4 {
  --bs-border-width: 4px;
}

.lhHEiG_border-5 {
  --bs-border-width: 5px;
}

.lhHEiG_border-opacity-10 {
  --bs-border-opacity: .1;
}

.lhHEiG_border-opacity-25 {
  --bs-border-opacity: .25;
}

.lhHEiG_border-opacity-50 {
  --bs-border-opacity: .5;
}

.lhHEiG_border-opacity-75 {
  --bs-border-opacity: .75;
}

.lhHEiG_border-opacity-100 {
  --bs-border-opacity: 1;
}

.lhHEiG_w-25 {
  width: 25% !important;
}

.lhHEiG_w-50 {
  width: 50% !important;
}

.lhHEiG_w-75 {
  width: 75% !important;
}

.lhHEiG_w-100 {
  width: 100% !important;
}

.lhHEiG_w-auto {
  width: auto !important;
}

.lhHEiG_mw-100 {
  max-width: 100% !important;
}

.lhHEiG_vw-100 {
  width: 100vw !important;
}

.lhHEiG_min-vw-100 {
  min-width: 100vw !important;
}

.lhHEiG_h-25 {
  height: 25% !important;
}

.lhHEiG_h-50 {
  height: 50% !important;
}

.lhHEiG_h-75 {
  height: 75% !important;
}

.lhHEiG_h-100 {
  height: 100% !important;
}

.lhHEiG_h-auto {
  height: auto !important;
}

.lhHEiG_mh-100 {
  max-height: 100% !important;
}

.lhHEiG_vh-100 {
  height: 100vh !important;
}

.lhHEiG_min-vh-100 {
  min-height: 100vh !important;
}

.lhHEiG_flex-fill {
  flex: auto !important;
}

.lhHEiG_flex-row {
  flex-direction: row !important;
}

.lhHEiG_flex-column {
  flex-direction: column !important;
}

.lhHEiG_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.lhHEiG_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.lhHEiG_flex-grow-0 {
  flex-grow: 0 !important;
}

.lhHEiG_flex-grow-1 {
  flex-grow: 1 !important;
}

.lhHEiG_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.lhHEiG_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.lhHEiG_flex-wrap {
  flex-wrap: wrap !important;
}

.lhHEiG_flex-nowrap {
  flex-wrap: nowrap !important;
}

.lhHEiG_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.lhHEiG_justify-content-start {
  justify-content: flex-start !important;
}

.lhHEiG_justify-content-end {
  justify-content: flex-end !important;
}

.lhHEiG_justify-content-center {
  justify-content: center !important;
}

.lhHEiG_justify-content-between {
  justify-content: space-between !important;
}

.lhHEiG_justify-content-around {
  justify-content: space-around !important;
}

.lhHEiG_justify-content-evenly {
  justify-content: space-evenly !important;
}

.lhHEiG_align-items-start {
  align-items: flex-start !important;
}

.lhHEiG_align-items-end {
  align-items: flex-end !important;
}

.lhHEiG_align-items-center {
  align-items: center !important;
}

.lhHEiG_align-items-baseline {
  align-items: baseline !important;
}

.lhHEiG_align-items-stretch {
  align-items: stretch !important;
}

.lhHEiG_align-content-start {
  align-content: flex-start !important;
}

.lhHEiG_align-content-end {
  align-content: flex-end !important;
}

.lhHEiG_align-content-center {
  align-content: center !important;
}

.lhHEiG_align-content-between {
  align-content: space-between !important;
}

.lhHEiG_align-content-around {
  align-content: space-around !important;
}

.lhHEiG_align-content-stretch {
  align-content: stretch !important;
}

.lhHEiG_align-self-auto {
  align-self: auto !important;
}

.lhHEiG_align-self-start {
  align-self: flex-start !important;
}

.lhHEiG_align-self-end {
  align-self: flex-end !important;
}

.lhHEiG_align-self-center {
  align-self: center !important;
}

.lhHEiG_align-self-baseline {
  align-self: baseline !important;
}

.lhHEiG_align-self-stretch {
  align-self: stretch !important;
}

.lhHEiG_order-first {
  order: -1 !important;
}

.lhHEiG_order-0 {
  order: 0 !important;
}

.lhHEiG_order-1 {
  order: 1 !important;
}

.lhHEiG_order-2 {
  order: 2 !important;
}

.lhHEiG_order-3 {
  order: 3 !important;
}

.lhHEiG_order-4 {
  order: 4 !important;
}

.lhHEiG_order-5 {
  order: 5 !important;
}

.lhHEiG_order-last {
  order: 6 !important;
}

.lhHEiG_m-0 {
  margin: 0 !important;
}

.lhHEiG_m-1 {
  margin: .25rem !important;
}

.lhHEiG_m-2 {
  margin: .5rem !important;
}

.lhHEiG_m-3 {
  margin: 1rem !important;
}

.lhHEiG_m-4 {
  margin: 1.5rem !important;
}

.lhHEiG_m-5 {
  margin: 3rem !important;
}

.lhHEiG_m-auto {
  margin: auto !important;
}

.lhHEiG_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.lhHEiG_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.lhHEiG_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.lhHEiG_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.lhHEiG_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.lhHEiG_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.lhHEiG_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.lhHEiG_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.lhHEiG_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.lhHEiG_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.lhHEiG_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.lhHEiG_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.lhHEiG_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.lhHEiG_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.lhHEiG_mt-0 {
  margin-top: 0 !important;
}

.lhHEiG_mt-1 {
  margin-top: .25rem !important;
}

.lhHEiG_mt-2 {
  margin-top: .5rem !important;
}

.lhHEiG_mt-3 {
  margin-top: 1rem !important;
}

.lhHEiG_mt-4 {
  margin-top: 1.5rem !important;
}

.lhHEiG_mt-5 {
  margin-top: 3rem !important;
}

.lhHEiG_mt-auto {
  margin-top: auto !important;
}

.lhHEiG_me-0 {
  margin-right: 0 !important;
}

.lhHEiG_me-1 {
  margin-right: .25rem !important;
}

.lhHEiG_me-2 {
  margin-right: .5rem !important;
}

.lhHEiG_me-3 {
  margin-right: 1rem !important;
}

.lhHEiG_me-4 {
  margin-right: 1.5rem !important;
}

.lhHEiG_me-5 {
  margin-right: 3rem !important;
}

.lhHEiG_me-auto {
  margin-right: auto !important;
}

.lhHEiG_mb-0 {
  margin-bottom: 0 !important;
}

.lhHEiG_mb-1 {
  margin-bottom: .25rem !important;
}

.lhHEiG_mb-2 {
  margin-bottom: .5rem !important;
}

.lhHEiG_mb-3 {
  margin-bottom: 1rem !important;
}

.lhHEiG_mb-4 {
  margin-bottom: 1.5rem !important;
}

.lhHEiG_mb-5 {
  margin-bottom: 3rem !important;
}

.lhHEiG_mb-auto {
  margin-bottom: auto !important;
}

.lhHEiG_ms-0 {
  margin-left: 0 !important;
}

.lhHEiG_ms-1 {
  margin-left: .25rem !important;
}

.lhHEiG_ms-2 {
  margin-left: .5rem !important;
}

.lhHEiG_ms-3 {
  margin-left: 1rem !important;
}

.lhHEiG_ms-4 {
  margin-left: 1.5rem !important;
}

.lhHEiG_ms-5 {
  margin-left: 3rem !important;
}

.lhHEiG_ms-auto {
  margin-left: auto !important;
}

.lhHEiG_p-0 {
  padding: 0 !important;
}

.lhHEiG_p-1 {
  padding: .25rem !important;
}

.lhHEiG_p-2 {
  padding: .5rem !important;
}

.lhHEiG_p-3 {
  padding: 1rem !important;
}

.lhHEiG_p-4 {
  padding: 1.5rem !important;
}

.lhHEiG_p-5 {
  padding: 3rem !important;
}

.lhHEiG_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.lhHEiG_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.lhHEiG_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.lhHEiG_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.lhHEiG_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.lhHEiG_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.lhHEiG_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.lhHEiG_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.lhHEiG_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.lhHEiG_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.lhHEiG_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.lhHEiG_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.lhHEiG_pt-0 {
  padding-top: 0 !important;
}

.lhHEiG_pt-1 {
  padding-top: .25rem !important;
}

.lhHEiG_pt-2 {
  padding-top: .5rem !important;
}

.lhHEiG_pt-3 {
  padding-top: 1rem !important;
}

.lhHEiG_pt-4 {
  padding-top: 1.5rem !important;
}

.lhHEiG_pt-5 {
  padding-top: 3rem !important;
}

.lhHEiG_pe-0 {
  padding-right: 0 !important;
}

.lhHEiG_pe-1 {
  padding-right: .25rem !important;
}

.lhHEiG_pe-2 {
  padding-right: .5rem !important;
}

.lhHEiG_pe-3 {
  padding-right: 1rem !important;
}

.lhHEiG_pe-4 {
  padding-right: 1.5rem !important;
}

.lhHEiG_pe-5 {
  padding-right: 3rem !important;
}

.lhHEiG_pb-0 {
  padding-bottom: 0 !important;
}

.lhHEiG_pb-1 {
  padding-bottom: .25rem !important;
}

.lhHEiG_pb-2 {
  padding-bottom: .5rem !important;
}

.lhHEiG_pb-3 {
  padding-bottom: 1rem !important;
}

.lhHEiG_pb-4 {
  padding-bottom: 1.5rem !important;
}

.lhHEiG_pb-5 {
  padding-bottom: 3rem !important;
}

.lhHEiG_ps-0 {
  padding-left: 0 !important;
}

.lhHEiG_ps-1 {
  padding-left: .25rem !important;
}

.lhHEiG_ps-2 {
  padding-left: .5rem !important;
}

.lhHEiG_ps-3 {
  padding-left: 1rem !important;
}

.lhHEiG_ps-4 {
  padding-left: 1.5rem !important;
}

.lhHEiG_ps-5 {
  padding-left: 3rem !important;
}

.lhHEiG_gap-0 {
  gap: 0 !important;
}

.lhHEiG_gap-1 {
  gap: .25rem !important;
}

.lhHEiG_gap-2 {
  gap: .5rem !important;
}

.lhHEiG_gap-3 {
  gap: 1rem !important;
}

.lhHEiG_gap-4 {
  gap: 1.5rem !important;
}

.lhHEiG_gap-5 {
  gap: 3rem !important;
}

.lhHEiG_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.lhHEiG_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.lhHEiG_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.lhHEiG_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.lhHEiG_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.lhHEiG_fs-5 {
  font-size: 1.25rem !important;
}

.lhHEiG_fs-6 {
  font-size: 1rem !important;
}

.lhHEiG_fst-italic {
  font-style: italic !important;
}

.lhHEiG_fst-normal {
  font-style: normal !important;
}

.lhHEiG_fw-light {
  font-weight: 300 !important;
}

.lhHEiG_fw-lighter {
  font-weight: lighter !important;
}

.lhHEiG_fw-normal {
  font-weight: 400 !important;
}

.lhHEiG_fw-bold {
  font-weight: 700 !important;
}

.lhHEiG_fw-semibold {
  font-weight: 600 !important;
}

.lhHEiG_fw-bolder {
  font-weight: bolder !important;
}

.lhHEiG_lh-1 {
  line-height: 1 !important;
}

.lhHEiG_lh-sm {
  line-height: 1.25 !important;
}

.lhHEiG_lh-base {
  line-height: 1.5 !important;
}

.lhHEiG_lh-lg {
  line-height: 2 !important;
}

.lhHEiG_text-start {
  text-align: left !important;
}

.lhHEiG_text-end {
  text-align: right !important;
}

.lhHEiG_text-center {
  text-align: center !important;
}

.lhHEiG_text-decoration-none {
  text-decoration: none !important;
}

.lhHEiG_text-decoration-underline {
  text-decoration: underline !important;
}

.lhHEiG_text-decoration-line-through {
  text-decoration: line-through !important;
}

.lhHEiG_text-lowercase {
  text-transform: lowercase !important;
}

.lhHEiG_text-uppercase {
  text-transform: uppercase !important;
}

.lhHEiG_text-capitalize {
  text-transform: capitalize !important;
}

.lhHEiG_text-wrap {
  white-space: normal !important;
}

.lhHEiG_text-nowrap {
  white-space: nowrap !important;
}

.lhHEiG_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.lhHEiG_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.lhHEiG_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.lhHEiG_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.lhHEiG_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.lhHEiG_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.lhHEiG_text-opacity-25 {
  --bs-text-opacity: .25;
}

.lhHEiG_text-opacity-50 {
  --bs-text-opacity: .5;
}

.lhHEiG_text-opacity-75 {
  --bs-text-opacity: .75;
}

.lhHEiG_text-opacity-100 {
  --bs-text-opacity: 1;
}

.lhHEiG_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.lhHEiG_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.lhHEiG_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.lhHEiG_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.lhHEiG_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.lhHEiG_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.lhHEiG_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.lhHEiG_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.lhHEiG_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.lhHEiG_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.lhHEiG_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.lhHEiG_pe-none {
  pointer-events: none !important;
}

.lhHEiG_pe-auto {
  pointer-events: auto !important;
}

.lhHEiG_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.lhHEiG_rounded-0 {
  border-radius: 0 !important;
}

.lhHEiG_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.lhHEiG_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.lhHEiG_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.lhHEiG_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.lhHEiG_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.lhHEiG_rounded-circle {
  border-radius: 50% !important;
}

.lhHEiG_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.lhHEiG_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.lhHEiG_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.lhHEiG_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.lhHEiG_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.lhHEiG_visible {
  visibility: visible !important;
}

.lhHEiG_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .lhHEiG_float-sm-start {
    float: left !important;
  }

  .lhHEiG_float-sm-end {
    float: right !important;
  }

  .lhHEiG_float-sm-none {
    float: none !important;
  }

  .lhHEiG_d-sm-inline {
    display: inline !important;
  }

  .lhHEiG_d-sm-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-sm-block {
    display: block !important;
  }

  .lhHEiG_d-sm-grid {
    display: grid !important;
  }

  .lhHEiG_d-sm-table {
    display: table !important;
  }

  .lhHEiG_d-sm-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-sm-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-sm-flex {
    display: flex !important;
  }

  .lhHEiG_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-sm-none {
    display: none !important;
  }

  .lhHEiG_flex-sm-fill {
    flex: auto !important;
  }

  .lhHEiG_flex-sm-row {
    flex-direction: row !important;
  }

  .lhHEiG_flex-sm-column {
    flex-direction: column !important;
  }

  .lhHEiG_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lhHEiG_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .lhHEiG_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .lhHEiG_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .lhHEiG_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lhHEiG_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .lhHEiG_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .lhHEiG_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .lhHEiG_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lhHEiG_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .lhHEiG_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .lhHEiG_justify-content-sm-center {
    justify-content: center !important;
  }

  .lhHEiG_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .lhHEiG_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .lhHEiG_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .lhHEiG_align-items-sm-start {
    align-items: flex-start !important;
  }

  .lhHEiG_align-items-sm-end {
    align-items: flex-end !important;
  }

  .lhHEiG_align-items-sm-center {
    align-items: center !important;
  }

  .lhHEiG_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .lhHEiG_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .lhHEiG_align-content-sm-start {
    align-content: flex-start !important;
  }

  .lhHEiG_align-content-sm-end {
    align-content: flex-end !important;
  }

  .lhHEiG_align-content-sm-center {
    align-content: center !important;
  }

  .lhHEiG_align-content-sm-between {
    align-content: space-between !important;
  }

  .lhHEiG_align-content-sm-around {
    align-content: space-around !important;
  }

  .lhHEiG_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .lhHEiG_align-self-sm-auto {
    align-self: auto !important;
  }

  .lhHEiG_align-self-sm-start {
    align-self: flex-start !important;
  }

  .lhHEiG_align-self-sm-end {
    align-self: flex-end !important;
  }

  .lhHEiG_align-self-sm-center {
    align-self: center !important;
  }

  .lhHEiG_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .lhHEiG_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .lhHEiG_order-sm-first {
    order: -1 !important;
  }

  .lhHEiG_order-sm-0 {
    order: 0 !important;
  }

  .lhHEiG_order-sm-1 {
    order: 1 !important;
  }

  .lhHEiG_order-sm-2 {
    order: 2 !important;
  }

  .lhHEiG_order-sm-3 {
    order: 3 !important;
  }

  .lhHEiG_order-sm-4 {
    order: 4 !important;
  }

  .lhHEiG_order-sm-5 {
    order: 5 !important;
  }

  .lhHEiG_order-sm-last {
    order: 6 !important;
  }

  .lhHEiG_m-sm-0 {
    margin: 0 !important;
  }

  .lhHEiG_m-sm-1 {
    margin: .25rem !important;
  }

  .lhHEiG_m-sm-2 {
    margin: .5rem !important;
  }

  .lhHEiG_m-sm-3 {
    margin: 1rem !important;
  }

  .lhHEiG_m-sm-4 {
    margin: 1.5rem !important;
  }

  .lhHEiG_m-sm-5 {
    margin: 3rem !important;
  }

  .lhHEiG_m-sm-auto {
    margin: auto !important;
  }

  .lhHEiG_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lhHEiG_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lhHEiG_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lhHEiG_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lhHEiG_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lhHEiG_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lhHEiG_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lhHEiG_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lhHEiG_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lhHEiG_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lhHEiG_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lhHEiG_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lhHEiG_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lhHEiG_mt-sm-0 {
    margin-top: 0 !important;
  }

  .lhHEiG_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .lhHEiG_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .lhHEiG_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .lhHEiG_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .lhHEiG_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .lhHEiG_mt-sm-auto {
    margin-top: auto !important;
  }

  .lhHEiG_me-sm-0 {
    margin-right: 0 !important;
  }

  .lhHEiG_me-sm-1 {
    margin-right: .25rem !important;
  }

  .lhHEiG_me-sm-2 {
    margin-right: .5rem !important;
  }

  .lhHEiG_me-sm-3 {
    margin-right: 1rem !important;
  }

  .lhHEiG_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .lhHEiG_me-sm-5 {
    margin-right: 3rem !important;
  }

  .lhHEiG_me-sm-auto {
    margin-right: auto !important;
  }

  .lhHEiG_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .lhHEiG_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .lhHEiG_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .lhHEiG_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .lhHEiG_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .lhHEiG_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .lhHEiG_ms-sm-0 {
    margin-left: 0 !important;
  }

  .lhHEiG_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .lhHEiG_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .lhHEiG_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .lhHEiG_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .lhHEiG_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .lhHEiG_ms-sm-auto {
    margin-left: auto !important;
  }

  .lhHEiG_p-sm-0 {
    padding: 0 !important;
  }

  .lhHEiG_p-sm-1 {
    padding: .25rem !important;
  }

  .lhHEiG_p-sm-2 {
    padding: .5rem !important;
  }

  .lhHEiG_p-sm-3 {
    padding: 1rem !important;
  }

  .lhHEiG_p-sm-4 {
    padding: 1.5rem !important;
  }

  .lhHEiG_p-sm-5 {
    padding: 3rem !important;
  }

  .lhHEiG_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lhHEiG_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lhHEiG_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lhHEiG_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lhHEiG_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lhHEiG_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lhHEiG_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lhHEiG_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lhHEiG_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lhHEiG_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lhHEiG_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lhHEiG_pt-sm-0 {
    padding-top: 0 !important;
  }

  .lhHEiG_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .lhHEiG_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .lhHEiG_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .lhHEiG_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .lhHEiG_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .lhHEiG_pe-sm-0 {
    padding-right: 0 !important;
  }

  .lhHEiG_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .lhHEiG_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .lhHEiG_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .lhHEiG_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .lhHEiG_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .lhHEiG_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .lhHEiG_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .lhHEiG_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .lhHEiG_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .lhHEiG_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .lhHEiG_ps-sm-0 {
    padding-left: 0 !important;
  }

  .lhHEiG_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .lhHEiG_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .lhHEiG_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .lhHEiG_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .lhHEiG_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .lhHEiG_gap-sm-0 {
    gap: 0 !important;
  }

  .lhHEiG_gap-sm-1 {
    gap: .25rem !important;
  }

  .lhHEiG_gap-sm-2 {
    gap: .5rem !important;
  }

  .lhHEiG_gap-sm-3 {
    gap: 1rem !important;
  }

  .lhHEiG_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .lhHEiG_gap-sm-5 {
    gap: 3rem !important;
  }

  .lhHEiG_text-sm-start {
    text-align: left !important;
  }

  .lhHEiG_text-sm-end {
    text-align: right !important;
  }

  .lhHEiG_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .lhHEiG_float-md-start {
    float: left !important;
  }

  .lhHEiG_float-md-end {
    float: right !important;
  }

  .lhHEiG_float-md-none {
    float: none !important;
  }

  .lhHEiG_d-md-inline {
    display: inline !important;
  }

  .lhHEiG_d-md-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-md-block {
    display: block !important;
  }

  .lhHEiG_d-md-grid {
    display: grid !important;
  }

  .lhHEiG_d-md-table {
    display: table !important;
  }

  .lhHEiG_d-md-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-md-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-md-flex {
    display: flex !important;
  }

  .lhHEiG_d-md-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-md-none {
    display: none !important;
  }

  .lhHEiG_flex-md-fill {
    flex: auto !important;
  }

  .lhHEiG_flex-md-row {
    flex-direction: row !important;
  }

  .lhHEiG_flex-md-column {
    flex-direction: column !important;
  }

  .lhHEiG_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lhHEiG_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .lhHEiG_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .lhHEiG_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .lhHEiG_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lhHEiG_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .lhHEiG_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .lhHEiG_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .lhHEiG_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lhHEiG_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .lhHEiG_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .lhHEiG_justify-content-md-center {
    justify-content: center !important;
  }

  .lhHEiG_justify-content-md-between {
    justify-content: space-between !important;
  }

  .lhHEiG_justify-content-md-around {
    justify-content: space-around !important;
  }

  .lhHEiG_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .lhHEiG_align-items-md-start {
    align-items: flex-start !important;
  }

  .lhHEiG_align-items-md-end {
    align-items: flex-end !important;
  }

  .lhHEiG_align-items-md-center {
    align-items: center !important;
  }

  .lhHEiG_align-items-md-baseline {
    align-items: baseline !important;
  }

  .lhHEiG_align-items-md-stretch {
    align-items: stretch !important;
  }

  .lhHEiG_align-content-md-start {
    align-content: flex-start !important;
  }

  .lhHEiG_align-content-md-end {
    align-content: flex-end !important;
  }

  .lhHEiG_align-content-md-center {
    align-content: center !important;
  }

  .lhHEiG_align-content-md-between {
    align-content: space-between !important;
  }

  .lhHEiG_align-content-md-around {
    align-content: space-around !important;
  }

  .lhHEiG_align-content-md-stretch {
    align-content: stretch !important;
  }

  .lhHEiG_align-self-md-auto {
    align-self: auto !important;
  }

  .lhHEiG_align-self-md-start {
    align-self: flex-start !important;
  }

  .lhHEiG_align-self-md-end {
    align-self: flex-end !important;
  }

  .lhHEiG_align-self-md-center {
    align-self: center !important;
  }

  .lhHEiG_align-self-md-baseline {
    align-self: baseline !important;
  }

  .lhHEiG_align-self-md-stretch {
    align-self: stretch !important;
  }

  .lhHEiG_order-md-first {
    order: -1 !important;
  }

  .lhHEiG_order-md-0 {
    order: 0 !important;
  }

  .lhHEiG_order-md-1 {
    order: 1 !important;
  }

  .lhHEiG_order-md-2 {
    order: 2 !important;
  }

  .lhHEiG_order-md-3 {
    order: 3 !important;
  }

  .lhHEiG_order-md-4 {
    order: 4 !important;
  }

  .lhHEiG_order-md-5 {
    order: 5 !important;
  }

  .lhHEiG_order-md-last {
    order: 6 !important;
  }

  .lhHEiG_m-md-0 {
    margin: 0 !important;
  }

  .lhHEiG_m-md-1 {
    margin: .25rem !important;
  }

  .lhHEiG_m-md-2 {
    margin: .5rem !important;
  }

  .lhHEiG_m-md-3 {
    margin: 1rem !important;
  }

  .lhHEiG_m-md-4 {
    margin: 1.5rem !important;
  }

  .lhHEiG_m-md-5 {
    margin: 3rem !important;
  }

  .lhHEiG_m-md-auto {
    margin: auto !important;
  }

  .lhHEiG_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lhHEiG_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lhHEiG_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lhHEiG_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lhHEiG_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lhHEiG_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lhHEiG_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lhHEiG_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lhHEiG_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lhHEiG_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lhHEiG_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lhHEiG_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lhHEiG_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lhHEiG_mt-md-0 {
    margin-top: 0 !important;
  }

  .lhHEiG_mt-md-1 {
    margin-top: .25rem !important;
  }

  .lhHEiG_mt-md-2 {
    margin-top: .5rem !important;
  }

  .lhHEiG_mt-md-3 {
    margin-top: 1rem !important;
  }

  .lhHEiG_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .lhHEiG_mt-md-5 {
    margin-top: 3rem !important;
  }

  .lhHEiG_mt-md-auto {
    margin-top: auto !important;
  }

  .lhHEiG_me-md-0 {
    margin-right: 0 !important;
  }

  .lhHEiG_me-md-1 {
    margin-right: .25rem !important;
  }

  .lhHEiG_me-md-2 {
    margin-right: .5rem !important;
  }

  .lhHEiG_me-md-3 {
    margin-right: 1rem !important;
  }

  .lhHEiG_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .lhHEiG_me-md-5 {
    margin-right: 3rem !important;
  }

  .lhHEiG_me-md-auto {
    margin-right: auto !important;
  }

  .lhHEiG_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .lhHEiG_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .lhHEiG_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .lhHEiG_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .lhHEiG_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .lhHEiG_mb-md-auto {
    margin-bottom: auto !important;
  }

  .lhHEiG_ms-md-0 {
    margin-left: 0 !important;
  }

  .lhHEiG_ms-md-1 {
    margin-left: .25rem !important;
  }

  .lhHEiG_ms-md-2 {
    margin-left: .5rem !important;
  }

  .lhHEiG_ms-md-3 {
    margin-left: 1rem !important;
  }

  .lhHEiG_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .lhHEiG_ms-md-5 {
    margin-left: 3rem !important;
  }

  .lhHEiG_ms-md-auto {
    margin-left: auto !important;
  }

  .lhHEiG_p-md-0 {
    padding: 0 !important;
  }

  .lhHEiG_p-md-1 {
    padding: .25rem !important;
  }

  .lhHEiG_p-md-2 {
    padding: .5rem !important;
  }

  .lhHEiG_p-md-3 {
    padding: 1rem !important;
  }

  .lhHEiG_p-md-4 {
    padding: 1.5rem !important;
  }

  .lhHEiG_p-md-5 {
    padding: 3rem !important;
  }

  .lhHEiG_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lhHEiG_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lhHEiG_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lhHEiG_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lhHEiG_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lhHEiG_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lhHEiG_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lhHEiG_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lhHEiG_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lhHEiG_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lhHEiG_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lhHEiG_pt-md-0 {
    padding-top: 0 !important;
  }

  .lhHEiG_pt-md-1 {
    padding-top: .25rem !important;
  }

  .lhHEiG_pt-md-2 {
    padding-top: .5rem !important;
  }

  .lhHEiG_pt-md-3 {
    padding-top: 1rem !important;
  }

  .lhHEiG_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .lhHEiG_pt-md-5 {
    padding-top: 3rem !important;
  }

  .lhHEiG_pe-md-0 {
    padding-right: 0 !important;
  }

  .lhHEiG_pe-md-1 {
    padding-right: .25rem !important;
  }

  .lhHEiG_pe-md-2 {
    padding-right: .5rem !important;
  }

  .lhHEiG_pe-md-3 {
    padding-right: 1rem !important;
  }

  .lhHEiG_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .lhHEiG_pe-md-5 {
    padding-right: 3rem !important;
  }

  .lhHEiG_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .lhHEiG_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .lhHEiG_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .lhHEiG_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .lhHEiG_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .lhHEiG_ps-md-0 {
    padding-left: 0 !important;
  }

  .lhHEiG_ps-md-1 {
    padding-left: .25rem !important;
  }

  .lhHEiG_ps-md-2 {
    padding-left: .5rem !important;
  }

  .lhHEiG_ps-md-3 {
    padding-left: 1rem !important;
  }

  .lhHEiG_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .lhHEiG_ps-md-5 {
    padding-left: 3rem !important;
  }

  .lhHEiG_gap-md-0 {
    gap: 0 !important;
  }

  .lhHEiG_gap-md-1 {
    gap: .25rem !important;
  }

  .lhHEiG_gap-md-2 {
    gap: .5rem !important;
  }

  .lhHEiG_gap-md-3 {
    gap: 1rem !important;
  }

  .lhHEiG_gap-md-4 {
    gap: 1.5rem !important;
  }

  .lhHEiG_gap-md-5 {
    gap: 3rem !important;
  }

  .lhHEiG_text-md-start {
    text-align: left !important;
  }

  .lhHEiG_text-md-end {
    text-align: right !important;
  }

  .lhHEiG_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .lhHEiG_float-lg-start {
    float: left !important;
  }

  .lhHEiG_float-lg-end {
    float: right !important;
  }

  .lhHEiG_float-lg-none {
    float: none !important;
  }

  .lhHEiG_d-lg-inline {
    display: inline !important;
  }

  .lhHEiG_d-lg-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-lg-block {
    display: block !important;
  }

  .lhHEiG_d-lg-grid {
    display: grid !important;
  }

  .lhHEiG_d-lg-table {
    display: table !important;
  }

  .lhHEiG_d-lg-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-lg-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-lg-flex {
    display: flex !important;
  }

  .lhHEiG_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-lg-none {
    display: none !important;
  }

  .lhHEiG_flex-lg-fill {
    flex: auto !important;
  }

  .lhHEiG_flex-lg-row {
    flex-direction: row !important;
  }

  .lhHEiG_flex-lg-column {
    flex-direction: column !important;
  }

  .lhHEiG_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lhHEiG_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .lhHEiG_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .lhHEiG_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .lhHEiG_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lhHEiG_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .lhHEiG_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .lhHEiG_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .lhHEiG_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lhHEiG_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .lhHEiG_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .lhHEiG_justify-content-lg-center {
    justify-content: center !important;
  }

  .lhHEiG_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .lhHEiG_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .lhHEiG_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .lhHEiG_align-items-lg-start {
    align-items: flex-start !important;
  }

  .lhHEiG_align-items-lg-end {
    align-items: flex-end !important;
  }

  .lhHEiG_align-items-lg-center {
    align-items: center !important;
  }

  .lhHEiG_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .lhHEiG_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .lhHEiG_align-content-lg-start {
    align-content: flex-start !important;
  }

  .lhHEiG_align-content-lg-end {
    align-content: flex-end !important;
  }

  .lhHEiG_align-content-lg-center {
    align-content: center !important;
  }

  .lhHEiG_align-content-lg-between {
    align-content: space-between !important;
  }

  .lhHEiG_align-content-lg-around {
    align-content: space-around !important;
  }

  .lhHEiG_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .lhHEiG_align-self-lg-auto {
    align-self: auto !important;
  }

  .lhHEiG_align-self-lg-start {
    align-self: flex-start !important;
  }

  .lhHEiG_align-self-lg-end {
    align-self: flex-end !important;
  }

  .lhHEiG_align-self-lg-center {
    align-self: center !important;
  }

  .lhHEiG_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .lhHEiG_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .lhHEiG_order-lg-first {
    order: -1 !important;
  }

  .lhHEiG_order-lg-0 {
    order: 0 !important;
  }

  .lhHEiG_order-lg-1 {
    order: 1 !important;
  }

  .lhHEiG_order-lg-2 {
    order: 2 !important;
  }

  .lhHEiG_order-lg-3 {
    order: 3 !important;
  }

  .lhHEiG_order-lg-4 {
    order: 4 !important;
  }

  .lhHEiG_order-lg-5 {
    order: 5 !important;
  }

  .lhHEiG_order-lg-last {
    order: 6 !important;
  }

  .lhHEiG_m-lg-0 {
    margin: 0 !important;
  }

  .lhHEiG_m-lg-1 {
    margin: .25rem !important;
  }

  .lhHEiG_m-lg-2 {
    margin: .5rem !important;
  }

  .lhHEiG_m-lg-3 {
    margin: 1rem !important;
  }

  .lhHEiG_m-lg-4 {
    margin: 1.5rem !important;
  }

  .lhHEiG_m-lg-5 {
    margin: 3rem !important;
  }

  .lhHEiG_m-lg-auto {
    margin: auto !important;
  }

  .lhHEiG_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lhHEiG_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lhHEiG_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lhHEiG_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lhHEiG_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lhHEiG_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lhHEiG_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lhHEiG_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lhHEiG_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lhHEiG_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lhHEiG_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lhHEiG_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lhHEiG_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lhHEiG_mt-lg-0 {
    margin-top: 0 !important;
  }

  .lhHEiG_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .lhHEiG_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .lhHEiG_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .lhHEiG_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .lhHEiG_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .lhHEiG_mt-lg-auto {
    margin-top: auto !important;
  }

  .lhHEiG_me-lg-0 {
    margin-right: 0 !important;
  }

  .lhHEiG_me-lg-1 {
    margin-right: .25rem !important;
  }

  .lhHEiG_me-lg-2 {
    margin-right: .5rem !important;
  }

  .lhHEiG_me-lg-3 {
    margin-right: 1rem !important;
  }

  .lhHEiG_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .lhHEiG_me-lg-5 {
    margin-right: 3rem !important;
  }

  .lhHEiG_me-lg-auto {
    margin-right: auto !important;
  }

  .lhHEiG_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .lhHEiG_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .lhHEiG_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .lhHEiG_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .lhHEiG_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .lhHEiG_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .lhHEiG_ms-lg-0 {
    margin-left: 0 !important;
  }

  .lhHEiG_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .lhHEiG_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .lhHEiG_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .lhHEiG_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .lhHEiG_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .lhHEiG_ms-lg-auto {
    margin-left: auto !important;
  }

  .lhHEiG_p-lg-0 {
    padding: 0 !important;
  }

  .lhHEiG_p-lg-1 {
    padding: .25rem !important;
  }

  .lhHEiG_p-lg-2 {
    padding: .5rem !important;
  }

  .lhHEiG_p-lg-3 {
    padding: 1rem !important;
  }

  .lhHEiG_p-lg-4 {
    padding: 1.5rem !important;
  }

  .lhHEiG_p-lg-5 {
    padding: 3rem !important;
  }

  .lhHEiG_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lhHEiG_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lhHEiG_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lhHEiG_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lhHEiG_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lhHEiG_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lhHEiG_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lhHEiG_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lhHEiG_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lhHEiG_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lhHEiG_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lhHEiG_pt-lg-0 {
    padding-top: 0 !important;
  }

  .lhHEiG_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .lhHEiG_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .lhHEiG_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .lhHEiG_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .lhHEiG_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .lhHEiG_pe-lg-0 {
    padding-right: 0 !important;
  }

  .lhHEiG_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .lhHEiG_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .lhHEiG_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .lhHEiG_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .lhHEiG_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .lhHEiG_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .lhHEiG_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .lhHEiG_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .lhHEiG_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .lhHEiG_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .lhHEiG_ps-lg-0 {
    padding-left: 0 !important;
  }

  .lhHEiG_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .lhHEiG_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .lhHEiG_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .lhHEiG_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .lhHEiG_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .lhHEiG_gap-lg-0 {
    gap: 0 !important;
  }

  .lhHEiG_gap-lg-1 {
    gap: .25rem !important;
  }

  .lhHEiG_gap-lg-2 {
    gap: .5rem !important;
  }

  .lhHEiG_gap-lg-3 {
    gap: 1rem !important;
  }

  .lhHEiG_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .lhHEiG_gap-lg-5 {
    gap: 3rem !important;
  }

  .lhHEiG_text-lg-start {
    text-align: left !important;
  }

  .lhHEiG_text-lg-end {
    text-align: right !important;
  }

  .lhHEiG_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_float-xl-start {
    float: left !important;
  }

  .lhHEiG_float-xl-end {
    float: right !important;
  }

  .lhHEiG_float-xl-none {
    float: none !important;
  }

  .lhHEiG_d-xl-inline {
    display: inline !important;
  }

  .lhHEiG_d-xl-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-xl-block {
    display: block !important;
  }

  .lhHEiG_d-xl-grid {
    display: grid !important;
  }

  .lhHEiG_d-xl-table {
    display: table !important;
  }

  .lhHEiG_d-xl-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-xl-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-xl-flex {
    display: flex !important;
  }

  .lhHEiG_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-xl-none {
    display: none !important;
  }

  .lhHEiG_flex-xl-fill {
    flex: auto !important;
  }

  .lhHEiG_flex-xl-row {
    flex-direction: row !important;
  }

  .lhHEiG_flex-xl-column {
    flex-direction: column !important;
  }

  .lhHEiG_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lhHEiG_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .lhHEiG_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .lhHEiG_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .lhHEiG_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lhHEiG_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .lhHEiG_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .lhHEiG_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .lhHEiG_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lhHEiG_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .lhHEiG_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .lhHEiG_justify-content-xl-center {
    justify-content: center !important;
  }

  .lhHEiG_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .lhHEiG_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .lhHEiG_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .lhHEiG_align-items-xl-start {
    align-items: flex-start !important;
  }

  .lhHEiG_align-items-xl-end {
    align-items: flex-end !important;
  }

  .lhHEiG_align-items-xl-center {
    align-items: center !important;
  }

  .lhHEiG_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .lhHEiG_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .lhHEiG_align-content-xl-start {
    align-content: flex-start !important;
  }

  .lhHEiG_align-content-xl-end {
    align-content: flex-end !important;
  }

  .lhHEiG_align-content-xl-center {
    align-content: center !important;
  }

  .lhHEiG_align-content-xl-between {
    align-content: space-between !important;
  }

  .lhHEiG_align-content-xl-around {
    align-content: space-around !important;
  }

  .lhHEiG_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .lhHEiG_align-self-xl-auto {
    align-self: auto !important;
  }

  .lhHEiG_align-self-xl-start {
    align-self: flex-start !important;
  }

  .lhHEiG_align-self-xl-end {
    align-self: flex-end !important;
  }

  .lhHEiG_align-self-xl-center {
    align-self: center !important;
  }

  .lhHEiG_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .lhHEiG_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .lhHEiG_order-xl-first {
    order: -1 !important;
  }

  .lhHEiG_order-xl-0 {
    order: 0 !important;
  }

  .lhHEiG_order-xl-1 {
    order: 1 !important;
  }

  .lhHEiG_order-xl-2 {
    order: 2 !important;
  }

  .lhHEiG_order-xl-3 {
    order: 3 !important;
  }

  .lhHEiG_order-xl-4 {
    order: 4 !important;
  }

  .lhHEiG_order-xl-5 {
    order: 5 !important;
  }

  .lhHEiG_order-xl-last {
    order: 6 !important;
  }

  .lhHEiG_m-xl-0 {
    margin: 0 !important;
  }

  .lhHEiG_m-xl-1 {
    margin: .25rem !important;
  }

  .lhHEiG_m-xl-2 {
    margin: .5rem !important;
  }

  .lhHEiG_m-xl-3 {
    margin: 1rem !important;
  }

  .lhHEiG_m-xl-4 {
    margin: 1.5rem !important;
  }

  .lhHEiG_m-xl-5 {
    margin: 3rem !important;
  }

  .lhHEiG_m-xl-auto {
    margin: auto !important;
  }

  .lhHEiG_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lhHEiG_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lhHEiG_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lhHEiG_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lhHEiG_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lhHEiG_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lhHEiG_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lhHEiG_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lhHEiG_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lhHEiG_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lhHEiG_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lhHEiG_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lhHEiG_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lhHEiG_mt-xl-0 {
    margin-top: 0 !important;
  }

  .lhHEiG_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .lhHEiG_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .lhHEiG_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .lhHEiG_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .lhHEiG_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .lhHEiG_mt-xl-auto {
    margin-top: auto !important;
  }

  .lhHEiG_me-xl-0 {
    margin-right: 0 !important;
  }

  .lhHEiG_me-xl-1 {
    margin-right: .25rem !important;
  }

  .lhHEiG_me-xl-2 {
    margin-right: .5rem !important;
  }

  .lhHEiG_me-xl-3 {
    margin-right: 1rem !important;
  }

  .lhHEiG_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .lhHEiG_me-xl-5 {
    margin-right: 3rem !important;
  }

  .lhHEiG_me-xl-auto {
    margin-right: auto !important;
  }

  .lhHEiG_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .lhHEiG_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .lhHEiG_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .lhHEiG_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .lhHEiG_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .lhHEiG_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .lhHEiG_ms-xl-0 {
    margin-left: 0 !important;
  }

  .lhHEiG_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .lhHEiG_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .lhHEiG_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .lhHEiG_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .lhHEiG_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .lhHEiG_ms-xl-auto {
    margin-left: auto !important;
  }

  .lhHEiG_p-xl-0 {
    padding: 0 !important;
  }

  .lhHEiG_p-xl-1 {
    padding: .25rem !important;
  }

  .lhHEiG_p-xl-2 {
    padding: .5rem !important;
  }

  .lhHEiG_p-xl-3 {
    padding: 1rem !important;
  }

  .lhHEiG_p-xl-4 {
    padding: 1.5rem !important;
  }

  .lhHEiG_p-xl-5 {
    padding: 3rem !important;
  }

  .lhHEiG_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lhHEiG_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lhHEiG_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lhHEiG_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lhHEiG_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lhHEiG_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lhHEiG_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lhHEiG_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lhHEiG_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lhHEiG_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lhHEiG_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lhHEiG_pt-xl-0 {
    padding-top: 0 !important;
  }

  .lhHEiG_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .lhHEiG_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .lhHEiG_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .lhHEiG_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .lhHEiG_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .lhHEiG_pe-xl-0 {
    padding-right: 0 !important;
  }

  .lhHEiG_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .lhHEiG_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .lhHEiG_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .lhHEiG_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .lhHEiG_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .lhHEiG_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .lhHEiG_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .lhHEiG_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .lhHEiG_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .lhHEiG_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .lhHEiG_ps-xl-0 {
    padding-left: 0 !important;
  }

  .lhHEiG_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .lhHEiG_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .lhHEiG_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .lhHEiG_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .lhHEiG_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .lhHEiG_gap-xl-0 {
    gap: 0 !important;
  }

  .lhHEiG_gap-xl-1 {
    gap: .25rem !important;
  }

  .lhHEiG_gap-xl-2 {
    gap: .5rem !important;
  }

  .lhHEiG_gap-xl-3 {
    gap: 1rem !important;
  }

  .lhHEiG_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .lhHEiG_gap-xl-5 {
    gap: 3rem !important;
  }

  .lhHEiG_text-xl-start {
    text-align: left !important;
  }

  .lhHEiG_text-xl-end {
    text-align: right !important;
  }

  .lhHEiG_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .lhHEiG_float-xxl-start {
    float: left !important;
  }

  .lhHEiG_float-xxl-end {
    float: right !important;
  }

  .lhHEiG_float-xxl-none {
    float: none !important;
  }

  .lhHEiG_d-xxl-inline {
    display: inline !important;
  }

  .lhHEiG_d-xxl-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-xxl-block {
    display: block !important;
  }

  .lhHEiG_d-xxl-grid {
    display: grid !important;
  }

  .lhHEiG_d-xxl-table {
    display: table !important;
  }

  .lhHEiG_d-xxl-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-xxl-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-xxl-flex {
    display: flex !important;
  }

  .lhHEiG_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-xxl-none {
    display: none !important;
  }

  .lhHEiG_flex-xxl-fill {
    flex: auto !important;
  }

  .lhHEiG_flex-xxl-row {
    flex-direction: row !important;
  }

  .lhHEiG_flex-xxl-column {
    flex-direction: column !important;
  }

  .lhHEiG_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lhHEiG_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .lhHEiG_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .lhHEiG_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .lhHEiG_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lhHEiG_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .lhHEiG_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .lhHEiG_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .lhHEiG_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lhHEiG_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .lhHEiG_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .lhHEiG_justify-content-xxl-center {
    justify-content: center !important;
  }

  .lhHEiG_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .lhHEiG_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .lhHEiG_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .lhHEiG_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .lhHEiG_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .lhHEiG_align-items-xxl-center {
    align-items: center !important;
  }

  .lhHEiG_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .lhHEiG_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .lhHEiG_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .lhHEiG_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .lhHEiG_align-content-xxl-center {
    align-content: center !important;
  }

  .lhHEiG_align-content-xxl-between {
    align-content: space-between !important;
  }

  .lhHEiG_align-content-xxl-around {
    align-content: space-around !important;
  }

  .lhHEiG_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .lhHEiG_align-self-xxl-auto {
    align-self: auto !important;
  }

  .lhHEiG_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .lhHEiG_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .lhHEiG_align-self-xxl-center {
    align-self: center !important;
  }

  .lhHEiG_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .lhHEiG_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .lhHEiG_order-xxl-first {
    order: -1 !important;
  }

  .lhHEiG_order-xxl-0 {
    order: 0 !important;
  }

  .lhHEiG_order-xxl-1 {
    order: 1 !important;
  }

  .lhHEiG_order-xxl-2 {
    order: 2 !important;
  }

  .lhHEiG_order-xxl-3 {
    order: 3 !important;
  }

  .lhHEiG_order-xxl-4 {
    order: 4 !important;
  }

  .lhHEiG_order-xxl-5 {
    order: 5 !important;
  }

  .lhHEiG_order-xxl-last {
    order: 6 !important;
  }

  .lhHEiG_m-xxl-0 {
    margin: 0 !important;
  }

  .lhHEiG_m-xxl-1 {
    margin: .25rem !important;
  }

  .lhHEiG_m-xxl-2 {
    margin: .5rem !important;
  }

  .lhHEiG_m-xxl-3 {
    margin: 1rem !important;
  }

  .lhHEiG_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .lhHEiG_m-xxl-5 {
    margin: 3rem !important;
  }

  .lhHEiG_m-xxl-auto {
    margin: auto !important;
  }

  .lhHEiG_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lhHEiG_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lhHEiG_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lhHEiG_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lhHEiG_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lhHEiG_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lhHEiG_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lhHEiG_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lhHEiG_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lhHEiG_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lhHEiG_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lhHEiG_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lhHEiG_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lhHEiG_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .lhHEiG_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .lhHEiG_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .lhHEiG_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .lhHEiG_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .lhHEiG_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .lhHEiG_mt-xxl-auto {
    margin-top: auto !important;
  }

  .lhHEiG_me-xxl-0 {
    margin-right: 0 !important;
  }

  .lhHEiG_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .lhHEiG_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .lhHEiG_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .lhHEiG_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .lhHEiG_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .lhHEiG_me-xxl-auto {
    margin-right: auto !important;
  }

  .lhHEiG_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .lhHEiG_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .lhHEiG_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .lhHEiG_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .lhHEiG_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .lhHEiG_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .lhHEiG_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .lhHEiG_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .lhHEiG_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .lhHEiG_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .lhHEiG_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .lhHEiG_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .lhHEiG_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .lhHEiG_ms-xxl-auto {
    margin-left: auto !important;
  }

  .lhHEiG_p-xxl-0 {
    padding: 0 !important;
  }

  .lhHEiG_p-xxl-1 {
    padding: .25rem !important;
  }

  .lhHEiG_p-xxl-2 {
    padding: .5rem !important;
  }

  .lhHEiG_p-xxl-3 {
    padding: 1rem !important;
  }

  .lhHEiG_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .lhHEiG_p-xxl-5 {
    padding: 3rem !important;
  }

  .lhHEiG_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lhHEiG_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lhHEiG_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lhHEiG_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lhHEiG_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lhHEiG_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lhHEiG_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lhHEiG_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lhHEiG_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lhHEiG_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lhHEiG_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lhHEiG_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .lhHEiG_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .lhHEiG_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .lhHEiG_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .lhHEiG_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .lhHEiG_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .lhHEiG_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .lhHEiG_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .lhHEiG_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .lhHEiG_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .lhHEiG_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .lhHEiG_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .lhHEiG_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .lhHEiG_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .lhHEiG_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .lhHEiG_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .lhHEiG_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .lhHEiG_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .lhHEiG_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .lhHEiG_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .lhHEiG_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .lhHEiG_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .lhHEiG_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .lhHEiG_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .lhHEiG_gap-xxl-0 {
    gap: 0 !important;
  }

  .lhHEiG_gap-xxl-1 {
    gap: .25rem !important;
  }

  .lhHEiG_gap-xxl-2 {
    gap: .5rem !important;
  }

  .lhHEiG_gap-xxl-3 {
    gap: 1rem !important;
  }

  .lhHEiG_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .lhHEiG_gap-xxl-5 {
    gap: 3rem !important;
  }

  .lhHEiG_text-xxl-start {
    text-align: left !important;
  }

  .lhHEiG_text-xxl-end {
    text-align: right !important;
  }

  .lhHEiG_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .lhHEiG_fs-1 {
    font-size: 2.5rem !important;
  }

  .lhHEiG_fs-2 {
    font-size: 2rem !important;
  }

  .lhHEiG_fs-3 {
    font-size: 1.75rem !important;
  }

  .lhHEiG_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .lhHEiG_d-print-inline {
    display: inline !important;
  }

  .lhHEiG_d-print-inline-block {
    display: inline-block !important;
  }

  .lhHEiG_d-print-block {
    display: block !important;
  }

  .lhHEiG_d-print-grid {
    display: grid !important;
  }

  .lhHEiG_d-print-table {
    display: table !important;
  }

  .lhHEiG_d-print-table-row {
    display: table-row !important;
  }

  .lhHEiG_d-print-table-cell {
    display: table-cell !important;
  }

  .lhHEiG_d-print-flex {
    display: flex !important;
  }

  .lhHEiG_d-print-inline-flex {
    display: inline-flex !important;
  }

  .lhHEiG_d-print-none {
    display: none !important;
  }
}

.lhHEiG_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.lhHEiG_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.lhHEiG_nav-tabs .lhHEiG_nav-item .lhHEiG_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.lhHEiG_nav-tabs .lhHEiG_nav-item .lhHEiG_nav-link.lhHEiG_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.lhHEiG_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#lhHEiG_root {
  height: 100%;
}

.lhHEiG_highcharts-credits {
  display: none !important;
}

h3, .lhHEiG_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.lhHEiG_modal .lhHEiG_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.lhHEiG_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.lhHEiG_table thead tr th.lhHEiG_col-actions {
  width: 110px !important;
}

table.lhHEiG_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.lhHEiG_table tbody tr.lhHEiG_deleting {
  background-color: #ff323233 !important;
}

.lhHEiG_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.lhHEiG_btn-sm, .lhHEiG_btn-group-sm > .lhHEiG_btn {
  font-size: .6rem !important;
}

.lhHEiG_modal {
  background-color: #0a0a0a4d;
}

.lhHEiG_btn:focus {
  box-shadow: none !important;
}

.lhHEiG_content-filter .lhHEiG_react-datepicker-wrapper {
  width: auto !important;
}

.lhHEiG_content-filter .lhHEiG_react-datepicker-wrapper .lhHEiG_form-control {
  font-size: .85rem !important;
}

.lhHEiG_content-filter .lhHEiG_input-group-text, .lhHEiG_content-filter .lhHEiG_btn {
  height: 2.15rem !important;
}

.lhHEiG_content-filter .lhHEiG_btn {
  border-color: #ccc;
}

.lhHEiG_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.lhHEiG_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.lhHEiG_table > :not(:first-child) {
  border-top: unset !important;
}

.lhHEiG_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.lhHEiG_highcharts-series.lhHEiG_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.lhHEiG_expandable {
  transition: all .5s;
}

.lhHEiG_expandable > .lhHEiG_expander-icon {
  cursor: pointer;
}

.lhHEiG_expandable > .lhHEiG_expander-icon:hover {
  color: #328cfa66;
}

.lhHEiG_expandable.lhHEiG_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .lhHEiG_modal .lhHEiG_modal-xl {
    width: 100% !important;
  }

  .lhHEiG_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.lhHEiG_d-none, .lhHEiG_d-block {
  transition: all .5s;
}

.lhHEiG_navWrapper {
  height: 100vh;
  width: 100%;
  color: #fff;
  background-color: #191b1f;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.lhHEiG_navWrapper .lhHEiG_navHeader {
  text-align: center;
  width: 100%;
  z-index: 1;
  background-color: #191b1f;
  border-bottom: 1.5px solid #ededf5;
  position: relative;
  height: 70px !important;
}

.lhHEiG_navWrapper .lhHEiG_navHeader img {
  height: 100%;
  object-fit: contain;
}

.lhHEiG_groupMenuTitle {
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  text-transform: uppercase;
  color: #ededf5;
  font-size: 11px;
  font-weight: 600;
}

.lhHEiG_itemWrapper {
  z-index: 0;
  align-items: center;
  margin-top: 10px;
  padding: 10px 18px;
  font-size: 14px;
  display: flex;
  position: relative;
}

.lhHEiG_itemWrapper:hover {
  cursor: pointer;
  color: #a5a2ce;
}

.lhHEiG_itemWrapper:hover a, .lhHEiG_itemWrapper:hover .lhHEiG_inactiveColor, .lhHEiG_itemWrapper:hover .lhHEiG_itemChevron {
  color: #a5a2ce !important;
}

.lhHEiG_itemWrapper.lhHEiG_active {
  background-color: #ededf5;
  border-radius: 8px;
}

.lhHEiG_itemTitle {
  color: #ededf5;
  flex: 1;
}

.lhHEiG_itemTitle a {
  all: unset;
}

.lhHEiG_itemTitle a.lhHEiG_active {
  color: #fff !important;
}

.lhHEiG_itemTitle.lhHEiG_active {
  color: #4c469c;
}

.lhHEiG_inactiveColor {
  color: #ededf5;
}

.lhHEiG_inactiveColor.lhHEiG_active {
  color: #4c469c;
}

.lhHEiG_itemChevron {
  color: #ededf5;
  transition: all .3s ease-in;
}

.lhHEiG_itemChevron.lhHEiG_active {
  color: #fff;
  transition: all .3s ease-out;
  transform: rotate(180deg);
}

.lhHEiG_itemChildren {
  transition: all .3s ease-in;
  overflow: hidden;
}

.lhHEiG_marginRight {
  margin-right: 9px;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .Os7gCG_h6, h5, .Os7gCG_h5, h4, .Os7gCG_h4, h3, .Os7gCG_h3, h2, .Os7gCG_h2, h1, .Os7gCG_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .Os7gCG_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .Os7gCG_h1 {
    font-size: 2.5rem;
  }
}

h2, .Os7gCG_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .Os7gCG_h2 {
    font-size: 2rem;
  }
}

h3, .Os7gCG_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .Os7gCG_h3 {
    font-size: 1.75rem;
  }
}

h4, .Os7gCG_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .Os7gCG_h4 {
    font-size: 1.5rem;
  }
}

h5, .Os7gCG_h5 {
  font-size: 1.25rem;
}

h6, .Os7gCG_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .Os7gCG_small {
  font-size: .875em;
}

mark, .Os7gCG_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.Os7gCG_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.Os7gCG_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-1 {
    font-size: 5rem;
  }
}

.Os7gCG_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-2 {
    font-size: 4.5rem;
  }
}

.Os7gCG_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-3 {
    font-size: 4rem;
  }
}

.Os7gCG_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-4 {
    font-size: 3.5rem;
  }
}

.Os7gCG_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-5 {
    font-size: 3rem;
  }
}

.Os7gCG_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Os7gCG_display-6 {
    font-size: 2.5rem;
  }
}

.Os7gCG_list-unstyled, .Os7gCG_list-inline {
  padding-left: 0;
  list-style: none;
}

.Os7gCG_list-inline-item {
  display: inline-block;
}

.Os7gCG_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.Os7gCG_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.Os7gCG_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.Os7gCG_blockquote > :last-child {
  margin-bottom: 0;
}

.Os7gCG_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.Os7gCG_blockquote-footer:before {
  content: "— ";
}

.Os7gCG_img-fluid {
  max-width: 100%;
  height: auto;
}

.Os7gCG_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.Os7gCG_figure {
  display: inline-block;
}

.Os7gCG_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.Os7gCG_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.Os7gCG_container, .Os7gCG_container-fluid, .Os7gCG_container-xxl, .Os7gCG_container-xl, .Os7gCG_container-lg, .Os7gCG_container-md, .Os7gCG_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .Os7gCG_container-sm, .Os7gCG_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Os7gCG_container-md, .Os7gCG_container-sm, .Os7gCG_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Os7gCG_container-lg, .Os7gCG_container-md, .Os7gCG_container-sm, .Os7gCG_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_container-xl, .Os7gCG_container-lg, .Os7gCG_container-md, .Os7gCG_container-sm, .Os7gCG_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_container-xxl, .Os7gCG_container-xl, .Os7gCG_container-lg, .Os7gCG_container-md, .Os7gCG_container-sm, .Os7gCG_container {
    max-width: 1320px;
  }
}

.Os7gCG_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.Os7gCG_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.Os7gCG_col {
  flex: 1 0;
}

.Os7gCG_row-cols-auto > * {
  width: auto;
  flex: none;
}

.Os7gCG_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.Os7gCG_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.Os7gCG_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.Os7gCG_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.Os7gCG_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.Os7gCG_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.Os7gCG_col-auto {
  width: auto;
  flex: none;
}

.Os7gCG_col-1 {
  width: 8.33333%;
  flex: none;
}

.Os7gCG_col-2 {
  width: 16.6667%;
  flex: none;
}

.Os7gCG_col-3 {
  width: 25%;
  flex: none;
}

.Os7gCG_col-4 {
  width: 33.3333%;
  flex: none;
}

.Os7gCG_col-5 {
  width: 41.6667%;
  flex: none;
}

.Os7gCG_col-6 {
  width: 50%;
  flex: none;
}

.Os7gCG_col-7 {
  width: 58.3333%;
  flex: none;
}

.Os7gCG_col-8 {
  width: 66.6667%;
  flex: none;
}

.Os7gCG_col-9 {
  width: 75%;
  flex: none;
}

.Os7gCG_col-10 {
  width: 83.3333%;
  flex: none;
}

.Os7gCG_col-11 {
  width: 91.6667%;
  flex: none;
}

.Os7gCG_col-12 {
  width: 100%;
  flex: none;
}

.Os7gCG_offset-1 {
  margin-left: 8.33333%;
}

.Os7gCG_offset-2 {
  margin-left: 16.6667%;
}

.Os7gCG_offset-3 {
  margin-left: 25%;
}

.Os7gCG_offset-4 {
  margin-left: 33.3333%;
}

.Os7gCG_offset-5 {
  margin-left: 41.6667%;
}

.Os7gCG_offset-6 {
  margin-left: 50%;
}

.Os7gCG_offset-7 {
  margin-left: 58.3333%;
}

.Os7gCG_offset-8 {
  margin-left: 66.6667%;
}

.Os7gCG_offset-9 {
  margin-left: 75%;
}

.Os7gCG_offset-10 {
  margin-left: 83.3333%;
}

.Os7gCG_offset-11 {
  margin-left: 91.6667%;
}

.Os7gCG_g-0, .Os7gCG_gx-0 {
  --bs-gutter-x: 0;
}

.Os7gCG_g-0, .Os7gCG_gy-0 {
  --bs-gutter-y: 0;
}

.Os7gCG_g-1, .Os7gCG_gx-1 {
  --bs-gutter-x: .25rem;
}

.Os7gCG_g-1, .Os7gCG_gy-1 {
  --bs-gutter-y: .25rem;
}

.Os7gCG_g-2, .Os7gCG_gx-2 {
  --bs-gutter-x: .5rem;
}

.Os7gCG_g-2, .Os7gCG_gy-2 {
  --bs-gutter-y: .5rem;
}

.Os7gCG_g-3, .Os7gCG_gx-3 {
  --bs-gutter-x: 1rem;
}

.Os7gCG_g-3, .Os7gCG_gy-3 {
  --bs-gutter-y: 1rem;
}

.Os7gCG_g-4, .Os7gCG_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.Os7gCG_g-4, .Os7gCG_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.Os7gCG_g-5, .Os7gCG_gx-5 {
  --bs-gutter-x: 3rem;
}

.Os7gCG_g-5, .Os7gCG_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .Os7gCG_col-sm {
    flex: 1 0;
  }

  .Os7gCG_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .Os7gCG_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .Os7gCG_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .Os7gCG_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .Os7gCG_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .Os7gCG_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-sm-auto {
    width: auto;
    flex: none;
  }

  .Os7gCG_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .Os7gCG_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .Os7gCG_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .Os7gCG_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .Os7gCG_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .Os7gCG_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .Os7gCG_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .Os7gCG_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .Os7gCG_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .Os7gCG_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .Os7gCG_offset-sm-0 {
    margin-left: 0;
  }

  .Os7gCG_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .Os7gCG_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .Os7gCG_offset-sm-3 {
    margin-left: 25%;
  }

  .Os7gCG_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .Os7gCG_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .Os7gCG_offset-sm-6 {
    margin-left: 50%;
  }

  .Os7gCG_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .Os7gCG_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .Os7gCG_offset-sm-9 {
    margin-left: 75%;
  }

  .Os7gCG_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .Os7gCG_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .Os7gCG_g-sm-0, .Os7gCG_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .Os7gCG_g-sm-0, .Os7gCG_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .Os7gCG_g-sm-1, .Os7gCG_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .Os7gCG_g-sm-1, .Os7gCG_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .Os7gCG_g-sm-2, .Os7gCG_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .Os7gCG_g-sm-2, .Os7gCG_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .Os7gCG_g-sm-3, .Os7gCG_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .Os7gCG_g-sm-3, .Os7gCG_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .Os7gCG_g-sm-4, .Os7gCG_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Os7gCG_g-sm-4, .Os7gCG_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Os7gCG_g-sm-5, .Os7gCG_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .Os7gCG_g-sm-5, .Os7gCG_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .Os7gCG_col-md {
    flex: 1 0;
  }

  .Os7gCG_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .Os7gCG_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .Os7gCG_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .Os7gCG_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .Os7gCG_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .Os7gCG_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-md-auto {
    width: auto;
    flex: none;
  }

  .Os7gCG_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .Os7gCG_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-md-3 {
    width: 25%;
    flex: none;
  }

  .Os7gCG_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .Os7gCG_col-md-6 {
    width: 50%;
    flex: none;
  }

  .Os7gCG_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .Os7gCG_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .Os7gCG_col-md-9 {
    width: 75%;
    flex: none;
  }

  .Os7gCG_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .Os7gCG_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .Os7gCG_col-md-12 {
    width: 100%;
    flex: none;
  }

  .Os7gCG_offset-md-0 {
    margin-left: 0;
  }

  .Os7gCG_offset-md-1 {
    margin-left: 8.33333%;
  }

  .Os7gCG_offset-md-2 {
    margin-left: 16.6667%;
  }

  .Os7gCG_offset-md-3 {
    margin-left: 25%;
  }

  .Os7gCG_offset-md-4 {
    margin-left: 33.3333%;
  }

  .Os7gCG_offset-md-5 {
    margin-left: 41.6667%;
  }

  .Os7gCG_offset-md-6 {
    margin-left: 50%;
  }

  .Os7gCG_offset-md-7 {
    margin-left: 58.3333%;
  }

  .Os7gCG_offset-md-8 {
    margin-left: 66.6667%;
  }

  .Os7gCG_offset-md-9 {
    margin-left: 75%;
  }

  .Os7gCG_offset-md-10 {
    margin-left: 83.3333%;
  }

  .Os7gCG_offset-md-11 {
    margin-left: 91.6667%;
  }

  .Os7gCG_g-md-0, .Os7gCG_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .Os7gCG_g-md-0, .Os7gCG_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .Os7gCG_g-md-1, .Os7gCG_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .Os7gCG_g-md-1, .Os7gCG_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .Os7gCG_g-md-2, .Os7gCG_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .Os7gCG_g-md-2, .Os7gCG_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .Os7gCG_g-md-3, .Os7gCG_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .Os7gCG_g-md-3, .Os7gCG_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .Os7gCG_g-md-4, .Os7gCG_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Os7gCG_g-md-4, .Os7gCG_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Os7gCG_g-md-5, .Os7gCG_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .Os7gCG_g-md-5, .Os7gCG_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .Os7gCG_col-lg {
    flex: 1 0;
  }

  .Os7gCG_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .Os7gCG_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .Os7gCG_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .Os7gCG_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .Os7gCG_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .Os7gCG_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-lg-auto {
    width: auto;
    flex: none;
  }

  .Os7gCG_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .Os7gCG_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .Os7gCG_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .Os7gCG_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .Os7gCG_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .Os7gCG_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .Os7gCG_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .Os7gCG_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .Os7gCG_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .Os7gCG_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .Os7gCG_offset-lg-0 {
    margin-left: 0;
  }

  .Os7gCG_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .Os7gCG_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .Os7gCG_offset-lg-3 {
    margin-left: 25%;
  }

  .Os7gCG_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .Os7gCG_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .Os7gCG_offset-lg-6 {
    margin-left: 50%;
  }

  .Os7gCG_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .Os7gCG_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .Os7gCG_offset-lg-9 {
    margin-left: 75%;
  }

  .Os7gCG_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .Os7gCG_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .Os7gCG_g-lg-0, .Os7gCG_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .Os7gCG_g-lg-0, .Os7gCG_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .Os7gCG_g-lg-1, .Os7gCG_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .Os7gCG_g-lg-1, .Os7gCG_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .Os7gCG_g-lg-2, .Os7gCG_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .Os7gCG_g-lg-2, .Os7gCG_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .Os7gCG_g-lg-3, .Os7gCG_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .Os7gCG_g-lg-3, .Os7gCG_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .Os7gCG_g-lg-4, .Os7gCG_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Os7gCG_g-lg-4, .Os7gCG_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Os7gCG_g-lg-5, .Os7gCG_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .Os7gCG_g-lg-5, .Os7gCG_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_col-xl {
    flex: 1 0;
  }

  .Os7gCG_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .Os7gCG_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .Os7gCG_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .Os7gCG_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .Os7gCG_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .Os7gCG_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-xl-auto {
    width: auto;
    flex: none;
  }

  .Os7gCG_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Os7gCG_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .Os7gCG_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Os7gCG_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .Os7gCG_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Os7gCG_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Os7gCG_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .Os7gCG_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Os7gCG_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Os7gCG_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .Os7gCG_offset-xl-0 {
    margin-left: 0;
  }

  .Os7gCG_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .Os7gCG_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .Os7gCG_offset-xl-3 {
    margin-left: 25%;
  }

  .Os7gCG_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .Os7gCG_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .Os7gCG_offset-xl-6 {
    margin-left: 50%;
  }

  .Os7gCG_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .Os7gCG_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .Os7gCG_offset-xl-9 {
    margin-left: 75%;
  }

  .Os7gCG_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .Os7gCG_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .Os7gCG_g-xl-0, .Os7gCG_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .Os7gCG_g-xl-0, .Os7gCG_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .Os7gCG_g-xl-1, .Os7gCG_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .Os7gCG_g-xl-1, .Os7gCG_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .Os7gCG_g-xl-2, .Os7gCG_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .Os7gCG_g-xl-2, .Os7gCG_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .Os7gCG_g-xl-3, .Os7gCG_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .Os7gCG_g-xl-3, .Os7gCG_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .Os7gCG_g-xl-4, .Os7gCG_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Os7gCG_g-xl-4, .Os7gCG_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Os7gCG_g-xl-5, .Os7gCG_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .Os7gCG_g-xl-5, .Os7gCG_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_col-xxl {
    flex: 1 0;
  }

  .Os7gCG_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .Os7gCG_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .Os7gCG_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Os7gCG_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Os7gCG_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .Os7gCG_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Os7gCG_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Os7gCG_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .Os7gCG_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Os7gCG_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Os7gCG_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .Os7gCG_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Os7gCG_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Os7gCG_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .Os7gCG_offset-xxl-0 {
    margin-left: 0;
  }

  .Os7gCG_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .Os7gCG_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .Os7gCG_offset-xxl-3 {
    margin-left: 25%;
  }

  .Os7gCG_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .Os7gCG_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .Os7gCG_offset-xxl-6 {
    margin-left: 50%;
  }

  .Os7gCG_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .Os7gCG_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .Os7gCG_offset-xxl-9 {
    margin-left: 75%;
  }

  .Os7gCG_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .Os7gCG_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .Os7gCG_g-xxl-0, .Os7gCG_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .Os7gCG_g-xxl-0, .Os7gCG_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .Os7gCG_g-xxl-1, .Os7gCG_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .Os7gCG_g-xxl-1, .Os7gCG_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .Os7gCG_g-xxl-2, .Os7gCG_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .Os7gCG_g-xxl-2, .Os7gCG_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .Os7gCG_g-xxl-3, .Os7gCG_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .Os7gCG_g-xxl-3, .Os7gCG_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .Os7gCG_g-xxl-4, .Os7gCG_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Os7gCG_g-xxl-4, .Os7gCG_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Os7gCG_g-xxl-5, .Os7gCG_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .Os7gCG_g-xxl-5, .Os7gCG_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.Os7gCG_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.Os7gCG_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.Os7gCG_table > tbody {
  vertical-align: inherit;
}

.Os7gCG_table > thead {
  vertical-align: bottom;
}

.Os7gCG_table-group-divider {
  border-top: 2px solid;
}

.Os7gCG_caption-top {
  caption-side: top;
}

.Os7gCG_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.Os7gCG_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.Os7gCG_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.Os7gCG_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.Os7gCG_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.Os7gCG_table-striped > tbody > tr:nth-of-type(2n+1) > *, .Os7gCG_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.Os7gCG_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.Os7gCG_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.Os7gCG_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Os7gCG_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .Os7gCG_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .Os7gCG_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .Os7gCG_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .Os7gCG_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .Os7gCG_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.Os7gCG_form-label {
  margin-bottom: .5rem;
}

.Os7gCG_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.Os7gCG_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.Os7gCG_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.Os7gCG_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.Os7gCG_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-control {
    transition: none;
  }
}

.Os7gCG_form-control[type="file"] {
  overflow: hidden;
}

.Os7gCG_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Os7gCG_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Os7gCG_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.Os7gCG_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.Os7gCG_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.Os7gCG_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-control::file-selector-button {
    transition: none;
  }
}

.Os7gCG_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.Os7gCG_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.Os7gCG_form-control-plaintext:focus {
  outline: 0;
}

.Os7gCG_form-control-plaintext.Os7gCG_form-control-sm, .Os7gCG_form-control-plaintext.Os7gCG_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.Os7gCG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Os7gCG_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.Os7gCG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Os7gCG_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.Os7gCG_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.Os7gCG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.Os7gCG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.Os7gCG_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.Os7gCG_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Os7gCG_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.Os7gCG_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.Os7gCG_form-control-color.Os7gCG_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.Os7gCG_form-control-color.Os7gCG_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.Os7gCG_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-select {
    transition: none;
  }
}

.Os7gCG_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Os7gCG_form-select[multiple], .Os7gCG_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.Os7gCG_form-select:disabled {
  background-color: #e9ecef;
}

.Os7gCG_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.Os7gCG_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.Os7gCG_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.Os7gCG_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.Os7gCG_form-check .Os7gCG_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.Os7gCG_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.Os7gCG_form-check-reverse .Os7gCG_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.Os7gCG_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.Os7gCG_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.Os7gCG_form-check-input[type="radio"] {
  border-radius: 50%;
}

.Os7gCG_form-check-input:active {
  filter: brightness(90%);
}

.Os7gCG_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Os7gCG_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.Os7gCG_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.Os7gCG_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.Os7gCG_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.Os7gCG_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.Os7gCG_form-check-input[disabled] ~ .Os7gCG_form-check-label, .Os7gCG_form-check-input:disabled ~ .Os7gCG_form-check-label {
  cursor: default;
  opacity: .5;
}

.Os7gCG_form-switch {
  padding-left: 2.5em;
}

.Os7gCG_form-switch .Os7gCG_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-switch .Os7gCG_form-check-input {
    transition: none;
  }
}

.Os7gCG_form-switch .Os7gCG_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.Os7gCG_form-switch .Os7gCG_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.Os7gCG_form-switch.Os7gCG_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.Os7gCG_form-switch.Os7gCG_form-check-reverse .Os7gCG_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.Os7gCG_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.Os7gCG_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.Os7gCG_btn-check[disabled] + .Os7gCG_btn, .Os7gCG_btn-check:disabled + .Os7gCG_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.Os7gCG_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.Os7gCG_form-range:focus {
  outline: 0;
}

.Os7gCG_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Os7gCG_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Os7gCG_form-range::-moz-focus-outer {
  border: 0;
}

.Os7gCG_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.Os7gCG_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.Os7gCG_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Os7gCG_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-range::-moz-range-thumb {
    transition: none;
  }
}

.Os7gCG_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.Os7gCG_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Os7gCG_form-range:disabled {
  pointer-events: none;
}

.Os7gCG_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.Os7gCG_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.Os7gCG_form-floating {
  position: relative;
}

.Os7gCG_form-floating > .Os7gCG_form-control, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext, .Os7gCG_form-floating > .Os7gCG_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.Os7gCG_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_form-floating > label {
    transition: none;
  }
}

.Os7gCG_form-floating > .Os7gCG_form-control, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext {
  padding: 1rem .75rem;
}

.Os7gCG_form-floating > .Os7gCG_form-control::placeholder, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext::placeholder {
  color: #0000;
}

.Os7gCG_form-floating > .Os7gCG_form-control:focus, .Os7gCG_form-floating > .Os7gCG_form-control:not(:placeholder-shown), .Os7gCG_form-floating > .Os7gCG_form-control-plaintext:focus, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Os7gCG_form-floating > .Os7gCG_form-control:-webkit-autofill, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Os7gCG_form-floating > .Os7gCG_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Os7gCG_form-floating > .Os7gCG_form-control:focus ~ label, .Os7gCG_form-floating > .Os7gCG_form-control:not(:placeholder-shown) ~ label, .Os7gCG_form-floating > .Os7gCG_form-control-plaintext ~ label, .Os7gCG_form-floating > .Os7gCG_form-select ~ label, .Os7gCG_form-floating > .Os7gCG_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.Os7gCG_form-floating > .Os7gCG_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.Os7gCG_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.Os7gCG_input-group > .Os7gCG_form-control, .Os7gCG_input-group > .Os7gCG_form-select, .Os7gCG_input-group > .Os7gCG_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.Os7gCG_input-group > .Os7gCG_form-control:focus, .Os7gCG_input-group > .Os7gCG_form-select:focus, .Os7gCG_input-group > .Os7gCG_form-floating:focus-within {
  z-index: 5;
}

.Os7gCG_input-group .Os7gCG_btn {
  z-index: 2;
  position: relative;
}

.Os7gCG_input-group .Os7gCG_btn:focus {
  z-index: 5;
}

.Os7gCG_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.Os7gCG_input-group-lg > .Os7gCG_form-control, .Os7gCG_input-group-lg > .Os7gCG_form-select, .Os7gCG_input-group-lg > .Os7gCG_input-group-text, .Os7gCG_input-group-lg > .Os7gCG_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Os7gCG_input-group-sm > .Os7gCG_form-control, .Os7gCG_input-group-sm > .Os7gCG_form-select, .Os7gCG_input-group-sm > .Os7gCG_input-group-text, .Os7gCG_input-group-sm > .Os7gCG_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Os7gCG_input-group-lg > .Os7gCG_form-select, .Os7gCG_input-group-sm > .Os7gCG_form-select {
  padding-right: 3rem;
}

.Os7gCG_input-group:not(.Os7gCG_has-validation) > :not(:last-child):not(.Os7gCG_dropdown-toggle):not(.Os7gCG_dropdown-menu):not(.Os7gCG_form-floating), .Os7gCG_input-group:not(.Os7gCG_has-validation) > .Os7gCG_dropdown-toggle:nth-last-child(n+3), .Os7gCG_input-group:not(.Os7gCG_has-validation) > .Os7gCG_form-floating:not(:last-child) > .Os7gCG_form-control, .Os7gCG_input-group:not(.Os7gCG_has-validation) > .Os7gCG_form-floating:not(:last-child) > .Os7gCG_form-select, .Os7gCG_input-group.Os7gCG_has-validation > :nth-last-child(n+3):not(.Os7gCG_dropdown-toggle):not(.Os7gCG_dropdown-menu):not(.Os7gCG_form-floating), .Os7gCG_input-group.Os7gCG_has-validation > .Os7gCG_dropdown-toggle:nth-last-child(n+4), .Os7gCG_input-group.Os7gCG_has-validation > .Os7gCG_form-floating:nth-last-child(n+3) > .Os7gCG_form-control, .Os7gCG_input-group.Os7gCG_has-validation > .Os7gCG_form-floating:nth-last-child(n+3) > .Os7gCG_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Os7gCG_input-group > :not(:first-child):not(.Os7gCG_dropdown-menu):not(.Os7gCG_valid-tooltip):not(.Os7gCG_valid-feedback):not(.Os7gCG_invalid-tooltip):not(.Os7gCG_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.Os7gCG_input-group > .Os7gCG_form-floating:not(:first-child) > .Os7gCG_form-control, .Os7gCG_input-group > .Os7gCG_form-floating:not(:first-child) > .Os7gCG_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Os7gCG_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Os7gCG_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Os7gCG_was-validated :valid ~ .Os7gCG_valid-feedback, .Os7gCG_was-validated :valid ~ .Os7gCG_valid-tooltip, .Os7gCG_is-valid ~ .Os7gCG_valid-feedback, .Os7gCG_is-valid ~ .Os7gCG_valid-tooltip {
  display: block;
}

.Os7gCG_was-validated .Os7gCG_form-control:valid, .Os7gCG_form-control.Os7gCG_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.Os7gCG_was-validated .Os7gCG_form-control:valid:focus, .Os7gCG_form-control.Os7gCG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Os7gCG_was-validated textarea.Os7gCG_form-control:valid, textarea.Os7gCG_form-control.Os7gCG_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Os7gCG_was-validated .Os7gCG_form-select:valid, .Os7gCG_form-select.Os7gCG_is-valid {
  border-color: #198754;
}

.Os7gCG_was-validated .Os7gCG_form-select:valid:not([multiple]):not([size]), .Os7gCG_was-validated .Os7gCG_form-select:valid:not([multiple])[size="1"], .Os7gCG_form-select.Os7gCG_is-valid:not([multiple]):not([size]), .Os7gCG_form-select.Os7gCG_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Os7gCG_was-validated .Os7gCG_form-select:valid:focus, .Os7gCG_form-select.Os7gCG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Os7gCG_was-validated .Os7gCG_form-control-color:valid, .Os7gCG_form-control-color.Os7gCG_is-valid {
  width: calc(1.5em + 3.75rem);
}

.Os7gCG_was-validated .Os7gCG_form-check-input:valid, .Os7gCG_form-check-input.Os7gCG_is-valid {
  border-color: #198754;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:valid:checked, .Os7gCG_form-check-input.Os7gCG_is-valid:checked {
  background-color: #198754;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:valid:focus, .Os7gCG_form-check-input.Os7gCG_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:valid ~ .Os7gCG_form-check-label, .Os7gCG_form-check-input.Os7gCG_is-valid ~ .Os7gCG_form-check-label {
  color: #198754;
}

.Os7gCG_form-check-inline .Os7gCG_form-check-input ~ .Os7gCG_valid-feedback {
  margin-left: .5em;
}

.Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-control:not(:focus):valid, .Os7gCG_input-group > .Os7gCG_form-control:not(:focus).Os7gCG_is-valid, .Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-select:not(:focus):valid, .Os7gCG_input-group > .Os7gCG_form-select:not(:focus).Os7gCG_is-valid, .Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-floating:not(:focus-within):valid, .Os7gCG_input-group > .Os7gCG_form-floating:not(:focus-within).Os7gCG_is-valid {
  z-index: 3;
}

.Os7gCG_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Os7gCG_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Os7gCG_was-validated :invalid ~ .Os7gCG_invalid-feedback, .Os7gCG_was-validated :invalid ~ .Os7gCG_invalid-tooltip, .Os7gCG_is-invalid ~ .Os7gCG_invalid-feedback, .Os7gCG_is-invalid ~ .Os7gCG_invalid-tooltip {
  display: block;
}

.Os7gCG_was-validated .Os7gCG_form-control:invalid, .Os7gCG_form-control.Os7gCG_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.Os7gCG_was-validated .Os7gCG_form-control:invalid:focus, .Os7gCG_form-control.Os7gCG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Os7gCG_was-validated textarea.Os7gCG_form-control:invalid, textarea.Os7gCG_form-control.Os7gCG_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Os7gCG_was-validated .Os7gCG_form-select:invalid, .Os7gCG_form-select.Os7gCG_is-invalid {
  border-color: #dc3545;
}

.Os7gCG_was-validated .Os7gCG_form-select:invalid:not([multiple]):not([size]), .Os7gCG_was-validated .Os7gCG_form-select:invalid:not([multiple])[size="1"], .Os7gCG_form-select.Os7gCG_is-invalid:not([multiple]):not([size]), .Os7gCG_form-select.Os7gCG_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Os7gCG_was-validated .Os7gCG_form-select:invalid:focus, .Os7gCG_form-select.Os7gCG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Os7gCG_was-validated .Os7gCG_form-control-color:invalid, .Os7gCG_form-control-color.Os7gCG_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.Os7gCG_was-validated .Os7gCG_form-check-input:invalid, .Os7gCG_form-check-input.Os7gCG_is-invalid {
  border-color: #dc3545;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:invalid:checked, .Os7gCG_form-check-input.Os7gCG_is-invalid:checked {
  background-color: #dc3545;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:invalid:focus, .Os7gCG_form-check-input.Os7gCG_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Os7gCG_was-validated .Os7gCG_form-check-input:invalid ~ .Os7gCG_form-check-label, .Os7gCG_form-check-input.Os7gCG_is-invalid ~ .Os7gCG_form-check-label {
  color: #dc3545;
}

.Os7gCG_form-check-inline .Os7gCG_form-check-input ~ .Os7gCG_invalid-feedback {
  margin-left: .5em;
}

.Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-control:not(:focus):invalid, .Os7gCG_input-group > .Os7gCG_form-control:not(:focus).Os7gCG_is-invalid, .Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-select:not(:focus):invalid, .Os7gCG_input-group > .Os7gCG_form-select:not(:focus).Os7gCG_is-invalid, .Os7gCG_was-validated .Os7gCG_input-group > .Os7gCG_form-floating:not(:focus-within):invalid, .Os7gCG_input-group > .Os7gCG_form-floating:not(:focus-within).Os7gCG_is-invalid {
  z-index: 4;
}

.Os7gCG_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_btn {
    transition: none;
  }
}

.Os7gCG_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.Os7gCG_btn-check + .Os7gCG_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.Os7gCG_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Os7gCG_btn-check:focus-visible + .Os7gCG_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Os7gCG_btn-check:checked + .Os7gCG_btn, :not(.Os7gCG_btn-check) + .Os7gCG_btn:active, .Os7gCG_btn:first-child:active, .Os7gCG_btn.Os7gCG_active, .Os7gCG_btn.Os7gCG_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.Os7gCG_btn-check:checked + .Os7gCG_btn:focus-visible, :not(.Os7gCG_btn-check) + .Os7gCG_btn:active:focus-visible, .Os7gCG_btn:first-child:active:focus-visible, .Os7gCG_btn.Os7gCG_active:focus-visible, .Os7gCG_btn.Os7gCG_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.Os7gCG_btn:disabled, .Os7gCG_btn.Os7gCG_disabled, fieldset:disabled .Os7gCG_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.Os7gCG_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.Os7gCG_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.Os7gCG_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.Os7gCG_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.Os7gCG_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.Os7gCG_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.Os7gCG_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.Os7gCG_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.Os7gCG_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.Os7gCG_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.Os7gCG_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.Os7gCG_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.Os7gCG_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.Os7gCG_btn-lg, .Os7gCG_btn-group-lg > .Os7gCG_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.Os7gCG_btn-sm, .Os7gCG_btn-group-sm > .Os7gCG_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.Os7gCG_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_fade {
    transition: none;
  }
}

.Os7gCG_fade:not(.Os7gCG_show) {
  opacity: 0;
}

.Os7gCG_collapse:not(.Os7gCG_show) {
  display: none;
}

.Os7gCG_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_collapsing {
    transition: none;
  }
}

.Os7gCG_collapsing.Os7gCG_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_collapsing.Os7gCG_collapse-horizontal {
    transition: none;
  }
}

.Os7gCG_dropup, .Os7gCG_dropend, .Os7gCG_dropdown, .Os7gCG_dropstart, .Os7gCG_dropup-center, .Os7gCG_dropdown-center {
  position: relative;
}

.Os7gCG_dropdown-toggle {
  white-space: nowrap;
}

.Os7gCG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.Os7gCG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Os7gCG_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.Os7gCG_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.Os7gCG_dropdown-menu-start {
  --bs-position: start;
}

.Os7gCG_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.Os7gCG_dropdown-menu-end {
  --bs-position: end;
}

.Os7gCG_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .Os7gCG_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .Os7gCG_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Os7gCG_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .Os7gCG_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .Os7gCG_dropdown-menu-md-start {
    --bs-position: start;
  }

  .Os7gCG_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Os7gCG_dropdown-menu-md-end {
    --bs-position: end;
  }

  .Os7gCG_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .Os7gCG_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .Os7gCG_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Os7gCG_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .Os7gCG_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .Os7gCG_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Os7gCG_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .Os7gCG_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .Os7gCG_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Os7gCG_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .Os7gCG_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.Os7gCG_dropup .Os7gCG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.Os7gCG_dropup .Os7gCG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.Os7gCG_dropup .Os7gCG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Os7gCG_dropend .Os7gCG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.Os7gCG_dropend .Os7gCG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.Os7gCG_dropend .Os7gCG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Os7gCG_dropend .Os7gCG_dropdown-toggle:after {
  vertical-align: 0;
}

.Os7gCG_dropstart .Os7gCG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.Os7gCG_dropstart .Os7gCG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.Os7gCG_dropstart .Os7gCG_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.Os7gCG_dropstart .Os7gCG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Os7gCG_dropstart .Os7gCG_dropdown-toggle:before {
  vertical-align: 0;
}

.Os7gCG_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.Os7gCG_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.Os7gCG_dropdown-item:hover, .Os7gCG_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.Os7gCG_dropdown-item.Os7gCG_active, .Os7gCG_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.Os7gCG_dropdown-item.Os7gCG_disabled, .Os7gCG_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.Os7gCG_dropdown-menu.Os7gCG_show {
  display: block;
}

.Os7gCG_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.Os7gCG_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.Os7gCG_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.Os7gCG_btn-group, .Os7gCG_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.Os7gCG_btn-group > .Os7gCG_btn, .Os7gCG_btn-group-vertical > .Os7gCG_btn {
  flex: auto;
  position: relative;
}

.Os7gCG_btn-group > .Os7gCG_btn-check:checked + .Os7gCG_btn, .Os7gCG_btn-group > .Os7gCG_btn-check:focus + .Os7gCG_btn, .Os7gCG_btn-group > .Os7gCG_btn:hover, .Os7gCG_btn-group > .Os7gCG_btn:focus, .Os7gCG_btn-group > .Os7gCG_btn:active, .Os7gCG_btn-group > .Os7gCG_btn.Os7gCG_active, .Os7gCG_btn-group-vertical > .Os7gCG_btn-check:checked + .Os7gCG_btn, .Os7gCG_btn-group-vertical > .Os7gCG_btn-check:focus + .Os7gCG_btn, .Os7gCG_btn-group-vertical > .Os7gCG_btn:hover, .Os7gCG_btn-group-vertical > .Os7gCG_btn:focus, .Os7gCG_btn-group-vertical > .Os7gCG_btn:active, .Os7gCG_btn-group-vertical > .Os7gCG_btn.Os7gCG_active {
  z-index: 1;
}

.Os7gCG_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.Os7gCG_btn-toolbar .Os7gCG_input-group {
  width: auto;
}

.Os7gCG_btn-group {
  border-radius: .375rem;
}

.Os7gCG_btn-group > :not(.Os7gCG_btn-check:first-child) + .Os7gCG_btn, .Os7gCG_btn-group > .Os7gCG_btn-group:not(:first-child) {
  margin-left: -1px;
}

.Os7gCG_btn-group > .Os7gCG_btn:not(:last-child):not(.Os7gCG_dropdown-toggle), .Os7gCG_btn-group > .Os7gCG_btn.Os7gCG_dropdown-toggle-split:first-child, .Os7gCG_btn-group > .Os7gCG_btn-group:not(:last-child) > .Os7gCG_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Os7gCG_btn-group > .Os7gCG_btn:nth-child(n+3), .Os7gCG_btn-group > :not(.Os7gCG_btn-check) + .Os7gCG_btn, .Os7gCG_btn-group > .Os7gCG_btn-group:not(:first-child) > .Os7gCG_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Os7gCG_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.Os7gCG_dropdown-toggle-split:after, .Os7gCG_dropup .Os7gCG_dropdown-toggle-split:after, .Os7gCG_dropend .Os7gCG_dropdown-toggle-split:after {
  margin-left: 0;
}

.Os7gCG_dropstart .Os7gCG_dropdown-toggle-split:before {
  margin-right: 0;
}

.Os7gCG_btn-sm + .Os7gCG_dropdown-toggle-split, .Os7gCG_btn-group-sm > .Os7gCG_btn + .Os7gCG_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.Os7gCG_btn-lg + .Os7gCG_dropdown-toggle-split, .Os7gCG_btn-group-lg > .Os7gCG_btn + .Os7gCG_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.Os7gCG_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Os7gCG_btn-group-vertical > .Os7gCG_btn, .Os7gCG_btn-group-vertical > .Os7gCG_btn-group {
  width: 100%;
}

.Os7gCG_btn-group-vertical > .Os7gCG_btn:not(:first-child), .Os7gCG_btn-group-vertical > .Os7gCG_btn-group:not(:first-child) {
  margin-top: -1px;
}

.Os7gCG_btn-group-vertical > .Os7gCG_btn:not(:last-child):not(.Os7gCG_dropdown-toggle), .Os7gCG_btn-group-vertical > .Os7gCG_btn-group:not(:last-child) > .Os7gCG_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Os7gCG_btn-group-vertical > .Os7gCG_btn ~ .Os7gCG_btn, .Os7gCG_btn-group-vertical > .Os7gCG_btn-group:not(:first-child) > .Os7gCG_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Os7gCG_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Os7gCG_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_nav-link {
    transition: none;
  }
}

.Os7gCG_nav-link:hover, .Os7gCG_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.Os7gCG_nav-link.Os7gCG_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.Os7gCG_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.Os7gCG_nav-tabs .Os7gCG_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.Os7gCG_nav-tabs .Os7gCG_nav-link:hover, .Os7gCG_nav-tabs .Os7gCG_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.Os7gCG_nav-tabs .Os7gCG_nav-link.Os7gCG_disabled, .Os7gCG_nav-tabs .Os7gCG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Os7gCG_nav-tabs .Os7gCG_nav-link.Os7gCG_active, .Os7gCG_nav-tabs .Os7gCG_nav-item.Os7gCG_show .Os7gCG_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.Os7gCG_nav-tabs .Os7gCG_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Os7gCG_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.Os7gCG_nav-pills .Os7gCG_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.Os7gCG_nav-pills .Os7gCG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Os7gCG_nav-pills .Os7gCG_nav-link.Os7gCG_active, .Os7gCG_nav-pills .Os7gCG_show > .Os7gCG_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.Os7gCG_nav-fill > .Os7gCG_nav-link, .Os7gCG_nav-fill .Os7gCG_nav-item {
  text-align: center;
  flex: auto;
}

.Os7gCG_nav-justified > .Os7gCG_nav-link, .Os7gCG_nav-justified .Os7gCG_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.Os7gCG_nav-fill .Os7gCG_nav-item .Os7gCG_nav-link, .Os7gCG_nav-justified .Os7gCG_nav-item .Os7gCG_nav-link {
  width: 100%;
}

.Os7gCG_tab-content > .Os7gCG_tab-pane {
  display: none;
}

.Os7gCG_tab-content > .Os7gCG_active {
  display: block;
}

.Os7gCG_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.Os7gCG_navbar > .Os7gCG_container, .Os7gCG_navbar > .Os7gCG_container-fluid, .Os7gCG_navbar > .Os7gCG_container-sm, .Os7gCG_navbar > .Os7gCG_container-md, .Os7gCG_navbar > .Os7gCG_container-lg, .Os7gCG_navbar > .Os7gCG_container-xl, .Os7gCG_navbar > .Os7gCG_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Os7gCG_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.Os7gCG_navbar-brand:hover, .Os7gCG_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.Os7gCG_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Os7gCG_navbar-nav .Os7gCG_show > .Os7gCG_nav-link, .Os7gCG_navbar-nav .Os7gCG_nav-link.Os7gCG_active {
  color: var(--bs-navbar-active-color);
}

.Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
  position: static;
}

.Os7gCG_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Os7gCG_navbar-text a, .Os7gCG_navbar-text a:hover, .Os7gCG_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.Os7gCG_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.Os7gCG_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_navbar-toggler {
    transition: none;
  }
}

.Os7gCG_navbar-toggler:hover {
  text-decoration: none;
}

.Os7gCG_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.Os7gCG_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.Os7gCG_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .Os7gCG_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-nav {
    flex-direction: row;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
    position: absolute;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-nav .Os7gCG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-nav-scroll {
    overflow: visible;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_navbar-toggler {
    display: none;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_navbar-expand-sm .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .Os7gCG_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-nav {
    flex-direction: row;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
    position: absolute;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-nav .Os7gCG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-nav-scroll {
    overflow: visible;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_navbar-toggler {
    display: none;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_navbar-expand-md .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .Os7gCG_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-nav {
    flex-direction: row;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
    position: absolute;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-nav .Os7gCG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-nav-scroll {
    overflow: visible;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_navbar-toggler {
    display: none;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_navbar-expand-lg .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-nav {
    flex-direction: row;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
    position: absolute;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-nav .Os7gCG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-nav-scroll {
    overflow: visible;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_navbar-toggler {
    display: none;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_navbar-expand-xl .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-nav {
    flex-direction: row;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
    position: absolute;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-nav .Os7gCG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-nav-scroll {
    overflow: visible;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_navbar-toggler {
    display: none;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_navbar-expand-xxl .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.Os7gCG_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.Os7gCG_navbar-expand .Os7gCG_navbar-nav {
  flex-direction: row;
}

.Os7gCG_navbar-expand .Os7gCG_navbar-nav .Os7gCG_dropdown-menu {
  position: absolute;
}

.Os7gCG_navbar-expand .Os7gCG_navbar-nav .Os7gCG_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.Os7gCG_navbar-expand .Os7gCG_navbar-nav-scroll {
  overflow: visible;
}

.Os7gCG_navbar-expand .Os7gCG_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.Os7gCG_navbar-expand .Os7gCG_navbar-toggler {
  display: none;
}

.Os7gCG_navbar-expand .Os7gCG_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.Os7gCG_navbar-expand .Os7gCG_offcanvas .Os7gCG_offcanvas-header {
  display: none;
}

.Os7gCG_navbar-expand .Os7gCG_offcanvas .Os7gCG_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.Os7gCG_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.Os7gCG_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Os7gCG_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.Os7gCG_card > .Os7gCG_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.Os7gCG_card > .Os7gCG_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.Os7gCG_card > .Os7gCG_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.Os7gCG_card > .Os7gCG_card-header + .Os7gCG_list-group, .Os7gCG_card > .Os7gCG_list-group + .Os7gCG_card-footer {
  border-top: 0;
}

.Os7gCG_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.Os7gCG_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.Os7gCG_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.Os7gCG_card-text:last-child {
  margin-bottom: 0;
}

.Os7gCG_card-link + .Os7gCG_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.Os7gCG_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.Os7gCG_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.Os7gCG_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.Os7gCG_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.Os7gCG_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.Os7gCG_card-header-tabs .Os7gCG_nav-link.Os7gCG_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.Os7gCG_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.Os7gCG_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.Os7gCG_card-img, .Os7gCG_card-img-top, .Os7gCG_card-img-bottom {
  width: 100%;
}

.Os7gCG_card-img, .Os7gCG_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.Os7gCG_card-img, .Os7gCG_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.Os7gCG_card-group > .Os7gCG_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .Os7gCG_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .Os7gCG_card-group > .Os7gCG_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card + .Os7gCG_card {
    border-left: 0;
    margin-left: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:last-child) .Os7gCG_card-img-top, .Os7gCG_card-group > .Os7gCG_card:not(:last-child) .Os7gCG_card-header {
    border-top-right-radius: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:last-child) .Os7gCG_card-img-bottom, .Os7gCG_card-group > .Os7gCG_card:not(:last-child) .Os7gCG_card-footer {
    border-bottom-right-radius: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:first-child) .Os7gCG_card-img-top, .Os7gCG_card-group > .Os7gCG_card:not(:first-child) .Os7gCG_card-header {
    border-top-left-radius: 0;
  }

  .Os7gCG_card-group > .Os7gCG_card:not(:first-child) .Os7gCG_card-img-bottom, .Os7gCG_card-group > .Os7gCG_card:not(:first-child) .Os7gCG_card-footer {
    border-bottom-left-radius: 0;
  }
}

.Os7gCG_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.Os7gCG_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_accordion-button {
    transition: none;
  }
}

.Os7gCG_accordion-button:not(.Os7gCG_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.Os7gCG_accordion-button:not(.Os7gCG_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.Os7gCG_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_accordion-button:after {
    transition: none;
  }
}

.Os7gCG_accordion-button:hover {
  z-index: 2;
}

.Os7gCG_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.Os7gCG_accordion-header {
  margin-bottom: 0;
}

.Os7gCG_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.Os7gCG_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.Os7gCG_accordion-item:first-of-type .Os7gCG_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.Os7gCG_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.Os7gCG_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Os7gCG_accordion-item:last-of-type .Os7gCG_accordion-button.Os7gCG_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.Os7gCG_accordion-item:last-of-type .Os7gCG_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Os7gCG_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.Os7gCG_accordion-flush .Os7gCG_accordion-collapse {
  border-width: 0;
}

.Os7gCG_accordion-flush .Os7gCG_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.Os7gCG_accordion-flush .Os7gCG_accordion-item:first-child {
  border-top: 0;
}

.Os7gCG_accordion-flush .Os7gCG_accordion-item:last-child {
  border-bottom: 0;
}

.Os7gCG_accordion-flush .Os7gCG_accordion-item .Os7gCG_accordion-button, .Os7gCG_accordion-flush .Os7gCG_accordion-item .Os7gCG_accordion-button.Os7gCG_collapsed {
  border-radius: 0;
}

.Os7gCG_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.Os7gCG_breadcrumb-item + .Os7gCG_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.Os7gCG_breadcrumb-item + .Os7gCG_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.Os7gCG_breadcrumb-item.Os7gCG_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.Os7gCG_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Os7gCG_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_page-link {
    transition: none;
  }
}

.Os7gCG_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.Os7gCG_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.Os7gCG_page-link.Os7gCG_active, .Os7gCG_active > .Os7gCG_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.Os7gCG_page-link.Os7gCG_disabled, .Os7gCG_disabled > .Os7gCG_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.Os7gCG_page-item:not(:first-child) .Os7gCG_page-link {
  margin-left: -1px;
}

.Os7gCG_page-item:first-child .Os7gCG_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.Os7gCG_page-item:last-child .Os7gCG_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.Os7gCG_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.Os7gCG_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.Os7gCG_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.Os7gCG_badge:empty {
  display: none;
}

.Os7gCG_btn .Os7gCG_badge {
  position: relative;
  top: -1px;
}

.Os7gCG_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.Os7gCG_alert-heading {
  color: inherit;
}

.Os7gCG_alert-link {
  font-weight: 700;
}

.Os7gCG_alert-dismissible {
  padding-right: 3rem;
}

.Os7gCG_alert-dismissible .Os7gCG_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.Os7gCG_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.Os7gCG_alert-primary .Os7gCG_alert-link {
  color: #25224b;
}

.Os7gCG_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.Os7gCG_alert-secondary .Os7gCG_alert-link {
  color: #34383c;
}

.Os7gCG_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.Os7gCG_alert-success .Os7gCG_alert-link {
  color: #0c4128;
}

.Os7gCG_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.Os7gCG_alert-info .Os7gCG_alert-link {
  color: #04414d;
}

.Os7gCG_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.Os7gCG_alert-warning .Os7gCG_alert-link {
  color: #523e02;
}

.Os7gCG_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.Os7gCG_alert-danger .Os7gCG_alert-link {
  color: #6a1a21;
}

.Os7gCG_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.Os7gCG_alert-light .Os7gCG_alert-link {
  color: #4f5050;
}

.Os7gCG_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.Os7gCG_alert-dark .Os7gCG_alert-link {
  color: #101214;
}

@keyframes Os7gCG_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.Os7gCG_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.Os7gCG_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_progress-bar {
    transition: none;
  }
}

.Os7gCG_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.Os7gCG_progress-bar-animated {
  animation: 1s linear infinite Os7gCG_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_progress-bar-animated {
    animation: none;
  }
}

.Os7gCG_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.Os7gCG_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.Os7gCG_list-group-numbered > .Os7gCG_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.Os7gCG_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.Os7gCG_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.Os7gCG_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.Os7gCG_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.Os7gCG_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.Os7gCG_list-group-item.Os7gCG_disabled, .Os7gCG_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.Os7gCG_list-group-item.Os7gCG_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.Os7gCG_list-group-item + .Os7gCG_list-group-item {
  border-top-width: 0;
}

.Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.Os7gCG_list-group-horizontal {
  flex-direction: row;
}

.Os7gCG_list-group-horizontal > .Os7gCG_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.Os7gCG_list-group-horizontal > .Os7gCG_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.Os7gCG_list-group-horizontal > .Os7gCG_list-group-item.Os7gCG_active {
  margin-top: 0;
}

.Os7gCG_list-group-horizontal > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.Os7gCG_list-group-horizontal > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .Os7gCG_list-group-horizontal-sm {
    flex-direction: row;
  }

  .Os7gCG_list-group-horizontal-sm > .Os7gCG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Os7gCG_list-group-horizontal-sm > .Os7gCG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Os7gCG_list-group-horizontal-sm > .Os7gCG_list-group-item.Os7gCG_active {
    margin-top: 0;
  }

  .Os7gCG_list-group-horizontal-sm > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Os7gCG_list-group-horizontal-sm > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .Os7gCG_list-group-horizontal-md {
    flex-direction: row;
  }

  .Os7gCG_list-group-horizontal-md > .Os7gCG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Os7gCG_list-group-horizontal-md > .Os7gCG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Os7gCG_list-group-horizontal-md > .Os7gCG_list-group-item.Os7gCG_active {
    margin-top: 0;
  }

  .Os7gCG_list-group-horizontal-md > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Os7gCG_list-group-horizontal-md > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .Os7gCG_list-group-horizontal-lg {
    flex-direction: row;
  }

  .Os7gCG_list-group-horizontal-lg > .Os7gCG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Os7gCG_list-group-horizontal-lg > .Os7gCG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Os7gCG_list-group-horizontal-lg > .Os7gCG_list-group-item.Os7gCG_active {
    margin-top: 0;
  }

  .Os7gCG_list-group-horizontal-lg > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Os7gCG_list-group-horizontal-lg > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .Os7gCG_list-group-horizontal-xl {
    flex-direction: row;
  }

  .Os7gCG_list-group-horizontal-xl > .Os7gCG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Os7gCG_list-group-horizontal-xl > .Os7gCG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Os7gCG_list-group-horizontal-xl > .Os7gCG_list-group-item.Os7gCG_active {
    margin-top: 0;
  }

  .Os7gCG_list-group-horizontal-xl > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Os7gCG_list-group-horizontal-xl > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .Os7gCG_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .Os7gCG_list-group-horizontal-xxl > .Os7gCG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Os7gCG_list-group-horizontal-xxl > .Os7gCG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Os7gCG_list-group-horizontal-xxl > .Os7gCG_list-group-item.Os7gCG_active {
    margin-top: 0;
  }

  .Os7gCG_list-group-horizontal-xxl > .Os7gCG_list-group-item + .Os7gCG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Os7gCG_list-group-horizontal-xxl > .Os7gCG_list-group-item + .Os7gCG_list-group-item.Os7gCG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.Os7gCG_list-group-flush {
  border-radius: 0;
}

.Os7gCG_list-group-flush > .Os7gCG_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.Os7gCG_list-group-flush > .Os7gCG_list-group-item:last-child {
  border-bottom-width: 0;
}

.Os7gCG_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.Os7gCG_list-group-item-primary.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-primary.Os7gCG_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.Os7gCG_list-group-item-primary.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.Os7gCG_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.Os7gCG_list-group-item-secondary.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-secondary.Os7gCG_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.Os7gCG_list-group-item-secondary.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.Os7gCG_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.Os7gCG_list-group-item-success.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-success.Os7gCG_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.Os7gCG_list-group-item-success.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.Os7gCG_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.Os7gCG_list-group-item-info.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-info.Os7gCG_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.Os7gCG_list-group-item-info.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.Os7gCG_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.Os7gCG_list-group-item-warning.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-warning.Os7gCG_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.Os7gCG_list-group-item-warning.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.Os7gCG_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.Os7gCG_list-group-item-danger.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-danger.Os7gCG_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.Os7gCG_list-group-item-danger.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.Os7gCG_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.Os7gCG_list-group-item-light.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-light.Os7gCG_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.Os7gCG_list-group-item-light.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.Os7gCG_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.Os7gCG_list-group-item-dark.Os7gCG_list-group-item-action:hover, .Os7gCG_list-group-item-dark.Os7gCG_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.Os7gCG_list-group-item-dark.Os7gCG_list-group-item-action.Os7gCG_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.Os7gCG_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.Os7gCG_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.Os7gCG_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Os7gCG_btn-close:disabled, .Os7gCG_btn-close.Os7gCG_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.Os7gCG_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.Os7gCG_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.Os7gCG_toast.Os7gCG_showing {
  opacity: 0;
}

.Os7gCG_toast:not(.Os7gCG_show) {
  display: none;
}

.Os7gCG_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.Os7gCG_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.Os7gCG_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.Os7gCG_toast-header .Os7gCG_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.Os7gCG_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.Os7gCG_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.Os7gCG_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.Os7gCG_modal.Os7gCG_fade .Os7gCG_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_modal.Os7gCG_fade .Os7gCG_modal-dialog {
    transition: none;
  }
}

.Os7gCG_modal.Os7gCG_show .Os7gCG_modal-dialog {
  transform: none;
}

.Os7gCG_modal.Os7gCG_modal-static .Os7gCG_modal-dialog {
  transform: scale(1.02);
}

.Os7gCG_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.Os7gCG_modal-dialog-scrollable .Os7gCG_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.Os7gCG_modal-dialog-scrollable .Os7gCG_modal-body {
  overflow-y: auto;
}

.Os7gCG_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.Os7gCG_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Os7gCG_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.Os7gCG_modal-backdrop.Os7gCG_fade {
  opacity: 0;
}

.Os7gCG_modal-backdrop.Os7gCG_show {
  opacity: var(--bs-backdrop-opacity);
}

.Os7gCG_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Os7gCG_modal-header .Os7gCG_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.Os7gCG_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.Os7gCG_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.Os7gCG_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Os7gCG_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .Os7gCG_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .Os7gCG_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .Os7gCG_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .Os7gCG_modal-lg, .Os7gCG_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.Os7gCG_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.Os7gCG_modal-fullscreen .Os7gCG_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.Os7gCG_modal-fullscreen .Os7gCG_modal-header, .Os7gCG_modal-fullscreen .Os7gCG_modal-footer {
  border-radius: 0;
}

.Os7gCG_modal-fullscreen .Os7gCG_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .Os7gCG_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Os7gCG_modal-fullscreen-sm-down .Os7gCG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-sm-down .Os7gCG_modal-header, .Os7gCG_modal-fullscreen-sm-down .Os7gCG_modal-footer {
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-sm-down .Os7gCG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .Os7gCG_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Os7gCG_modal-fullscreen-md-down .Os7gCG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-md-down .Os7gCG_modal-header, .Os7gCG_modal-fullscreen-md-down .Os7gCG_modal-footer {
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-md-down .Os7gCG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .Os7gCG_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Os7gCG_modal-fullscreen-lg-down .Os7gCG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-lg-down .Os7gCG_modal-header, .Os7gCG_modal-fullscreen-lg-down .Os7gCG_modal-footer {
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-lg-down .Os7gCG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .Os7gCG_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Os7gCG_modal-fullscreen-xl-down .Os7gCG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-xl-down .Os7gCG_modal-header, .Os7gCG_modal-fullscreen-xl-down .Os7gCG_modal-footer {
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-xl-down .Os7gCG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .Os7gCG_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Os7gCG_modal-fullscreen-xxl-down .Os7gCG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-xxl-down .Os7gCG_modal-header, .Os7gCG_modal-fullscreen-xxl-down .Os7gCG_modal-footer {
    border-radius: 0;
  }

  .Os7gCG_modal-fullscreen-xxl-down .Os7gCG_modal-body {
    overflow-y: auto;
  }
}

.Os7gCG_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.Os7gCG_tooltip.Os7gCG_show {
  opacity: var(--bs-tooltip-opacity);
}

.Os7gCG_tooltip .Os7gCG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.Os7gCG_tooltip .Os7gCG_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.Os7gCG_bs-tooltip-top .Os7gCG_tooltip-arrow, .Os7gCG_bs-tooltip-auto[data-popper-placement^="top"] .Os7gCG_tooltip-arrow {
  bottom: 0;
}

.Os7gCG_bs-tooltip-top .Os7gCG_tooltip-arrow:before, .Os7gCG_bs-tooltip-auto[data-popper-placement^="top"] .Os7gCG_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.Os7gCG_bs-tooltip-end .Os7gCG_tooltip-arrow, .Os7gCG_bs-tooltip-auto[data-popper-placement^="right"] .Os7gCG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.Os7gCG_bs-tooltip-end .Os7gCG_tooltip-arrow:before, .Os7gCG_bs-tooltip-auto[data-popper-placement^="right"] .Os7gCG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.Os7gCG_bs-tooltip-bottom .Os7gCG_tooltip-arrow, .Os7gCG_bs-tooltip-auto[data-popper-placement^="bottom"] .Os7gCG_tooltip-arrow {
  top: 0;
}

.Os7gCG_bs-tooltip-bottom .Os7gCG_tooltip-arrow:before, .Os7gCG_bs-tooltip-auto[data-popper-placement^="bottom"] .Os7gCG_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.Os7gCG_bs-tooltip-start .Os7gCG_tooltip-arrow, .Os7gCG_bs-tooltip-auto[data-popper-placement^="left"] .Os7gCG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.Os7gCG_bs-tooltip-start .Os7gCG_tooltip-arrow:before, .Os7gCG_bs-tooltip-auto[data-popper-placement^="left"] .Os7gCG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.Os7gCG_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.Os7gCG_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.Os7gCG_popover .Os7gCG_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.Os7gCG_popover .Os7gCG_popover-arrow:before, .Os7gCG_popover .Os7gCG_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.Os7gCG_bs-popover-top > .Os7gCG_popover-arrow, .Os7gCG_bs-popover-auto[data-popper-placement^="top"] > .Os7gCG_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Os7gCG_bs-popover-top > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="top"] > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-top > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="top"] > .Os7gCG_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Os7gCG_bs-popover-top > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="top"] > .Os7gCG_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.Os7gCG_bs-popover-top > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="top"] > .Os7gCG_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.Os7gCG_bs-popover-end > .Os7gCG_popover-arrow, .Os7gCG_bs-popover-auto[data-popper-placement^="right"] > .Os7gCG_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Os7gCG_bs-popover-end > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="right"] > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-end > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="right"] > .Os7gCG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Os7gCG_bs-popover-end > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="right"] > .Os7gCG_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.Os7gCG_bs-popover-end > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="right"] > .Os7gCG_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.Os7gCG_bs-popover-bottom > .Os7gCG_popover-arrow, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] > .Os7gCG_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Os7gCG_bs-popover-bottom > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-bottom > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] > .Os7gCG_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Os7gCG_bs-popover-bottom > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] > .Os7gCG_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.Os7gCG_bs-popover-bottom > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] > .Os7gCG_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.Os7gCG_bs-popover-bottom .Os7gCG_popover-header:before, .Os7gCG_bs-popover-auto[data-popper-placement^="bottom"] .Os7gCG_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.Os7gCG_bs-popover-start > .Os7gCG_popover-arrow, .Os7gCG_bs-popover-auto[data-popper-placement^="left"] > .Os7gCG_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Os7gCG_bs-popover-start > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="left"] > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-start > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="left"] > .Os7gCG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Os7gCG_bs-popover-start > .Os7gCG_popover-arrow:before, .Os7gCG_bs-popover-auto[data-popper-placement^="left"] > .Os7gCG_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.Os7gCG_bs-popover-start > .Os7gCG_popover-arrow:after, .Os7gCG_bs-popover-auto[data-popper-placement^="left"] > .Os7gCG_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.Os7gCG_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.Os7gCG_popover-header:empty {
  display: none;
}

.Os7gCG_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.Os7gCG_carousel {
  position: relative;
}

.Os7gCG_carousel.Os7gCG_pointer-event {
  touch-action: pan-y;
}

.Os7gCG_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Os7gCG_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.Os7gCG_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_carousel-item {
    transition: none;
  }
}

.Os7gCG_carousel-item.Os7gCG_active, .Os7gCG_carousel-item-next, .Os7gCG_carousel-item-prev {
  display: block;
}

.Os7gCG_carousel-item-next:not(.Os7gCG_carousel-item-start), .Os7gCG_active.Os7gCG_carousel-item-end {
  transform: translateX(100%);
}

.Os7gCG_carousel-item-prev:not(.Os7gCG_carousel-item-end), .Os7gCG_active.Os7gCG_carousel-item-start {
  transform: translateX(-100%);
}

.Os7gCG_carousel-fade .Os7gCG_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.Os7gCG_carousel-fade .Os7gCG_carousel-item.Os7gCG_active, .Os7gCG_carousel-fade .Os7gCG_carousel-item-next.Os7gCG_carousel-item-start, .Os7gCG_carousel-fade .Os7gCG_carousel-item-prev.Os7gCG_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.Os7gCG_carousel-fade .Os7gCG_active.Os7gCG_carousel-item-start, .Os7gCG_carousel-fade .Os7gCG_active.Os7gCG_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_carousel-fade .Os7gCG_active.Os7gCG_carousel-item-start, .Os7gCG_carousel-fade .Os7gCG_active.Os7gCG_carousel-item-end {
    transition: none;
  }
}

.Os7gCG_carousel-control-prev, .Os7gCG_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_carousel-control-prev, .Os7gCG_carousel-control-next {
    transition: none;
  }
}

.Os7gCG_carousel-control-prev:hover, .Os7gCG_carousel-control-prev:focus, .Os7gCG_carousel-control-next:hover, .Os7gCG_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.Os7gCG_carousel-control-prev {
  left: 0;
}

.Os7gCG_carousel-control-next {
  right: 0;
}

.Os7gCG_carousel-control-prev-icon, .Os7gCG_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Os7gCG_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.Os7gCG_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.Os7gCG_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Os7gCG_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.Os7gCG_carousel-indicators .Os7gCG_active {
  opacity: 1;
}

.Os7gCG_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.Os7gCG_carousel-dark .Os7gCG_carousel-control-prev-icon, .Os7gCG_carousel-dark .Os7gCG_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.Os7gCG_carousel-dark .Os7gCG_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.Os7gCG_carousel-dark .Os7gCG_carousel-caption {
  color: #000;
}

.Os7gCG_spinner-grow, .Os7gCG_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes Os7gCG_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.Os7gCG_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.Os7gCG_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes Os7gCG_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.Os7gCG_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.Os7gCG_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_spinner-border, .Os7gCG_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.Os7gCG_offcanvas, .Os7gCG_offcanvas-xxl, .Os7gCG_offcanvas-xl, .Os7gCG_offcanvas-lg, .Os7gCG_offcanvas-md, .Os7gCG_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .Os7gCG_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .Os7gCG_offcanvas-sm.Os7gCG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Os7gCG_offcanvas-sm.Os7gCG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Os7gCG_offcanvas-sm.Os7gCG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Os7gCG_offcanvas-sm.Os7gCG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Os7gCG_offcanvas-sm.Os7gCG_showing, .Os7gCG_offcanvas-sm.Os7gCG_show:not(.Os7gCG_hiding) {
    transform: none;
  }

  .Os7gCG_offcanvas-sm.Os7gCG_showing, .Os7gCG_offcanvas-sm.Os7gCG_hiding, .Os7gCG_offcanvas-sm.Os7gCG_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .Os7gCG_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Os7gCG_offcanvas-sm .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_offcanvas-sm .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .Os7gCG_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .Os7gCG_offcanvas-md.Os7gCG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Os7gCG_offcanvas-md.Os7gCG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Os7gCG_offcanvas-md.Os7gCG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Os7gCG_offcanvas-md.Os7gCG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Os7gCG_offcanvas-md.Os7gCG_showing, .Os7gCG_offcanvas-md.Os7gCG_show:not(.Os7gCG_hiding) {
    transform: none;
  }

  .Os7gCG_offcanvas-md.Os7gCG_showing, .Os7gCG_offcanvas-md.Os7gCG_hiding, .Os7gCG_offcanvas-md.Os7gCG_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .Os7gCG_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Os7gCG_offcanvas-md .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_offcanvas-md .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .Os7gCG_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .Os7gCG_offcanvas-lg.Os7gCG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Os7gCG_offcanvas-lg.Os7gCG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Os7gCG_offcanvas-lg.Os7gCG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Os7gCG_offcanvas-lg.Os7gCG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Os7gCG_offcanvas-lg.Os7gCG_showing, .Os7gCG_offcanvas-lg.Os7gCG_show:not(.Os7gCG_hiding) {
    transform: none;
  }

  .Os7gCG_offcanvas-lg.Os7gCG_showing, .Os7gCG_offcanvas-lg.Os7gCG_hiding, .Os7gCG_offcanvas-lg.Os7gCG_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .Os7gCG_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Os7gCG_offcanvas-lg .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_offcanvas-lg .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .Os7gCG_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .Os7gCG_offcanvas-xl.Os7gCG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Os7gCG_offcanvas-xl.Os7gCG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Os7gCG_offcanvas-xl.Os7gCG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Os7gCG_offcanvas-xl.Os7gCG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Os7gCG_offcanvas-xl.Os7gCG_showing, .Os7gCG_offcanvas-xl.Os7gCG_show:not(.Os7gCG_hiding) {
    transform: none;
  }

  .Os7gCG_offcanvas-xl.Os7gCG_showing, .Os7gCG_offcanvas-xl.Os7gCG_hiding, .Os7gCG_offcanvas-xl.Os7gCG_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Os7gCG_offcanvas-xl .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_offcanvas-xl .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .Os7gCG_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .Os7gCG_offcanvas-xxl.Os7gCG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Os7gCG_offcanvas-xxl.Os7gCG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Os7gCG_offcanvas-xxl.Os7gCG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Os7gCG_offcanvas-xxl.Os7gCG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Os7gCG_offcanvas-xxl.Os7gCG_showing, .Os7gCG_offcanvas-xxl.Os7gCG_show:not(.Os7gCG_hiding) {
    transform: none;
  }

  .Os7gCG_offcanvas-xxl.Os7gCG_showing, .Os7gCG_offcanvas-xxl.Os7gCG_hiding, .Os7gCG_offcanvas-xxl.Os7gCG_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Os7gCG_offcanvas-xxl .Os7gCG_offcanvas-header {
    display: none;
  }

  .Os7gCG_offcanvas-xxl .Os7gCG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.Os7gCG_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Os7gCG_offcanvas {
    transition: none;
  }
}

.Os7gCG_offcanvas.Os7gCG_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.Os7gCG_offcanvas.Os7gCG_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.Os7gCG_offcanvas.Os7gCG_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.Os7gCG_offcanvas.Os7gCG_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.Os7gCG_offcanvas.Os7gCG_showing, .Os7gCG_offcanvas.Os7gCG_show:not(.Os7gCG_hiding) {
  transform: none;
}

.Os7gCG_offcanvas.Os7gCG_showing, .Os7gCG_offcanvas.Os7gCG_hiding, .Os7gCG_offcanvas.Os7gCG_show {
  visibility: visible;
}

.Os7gCG_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.Os7gCG_offcanvas-backdrop.Os7gCG_fade {
  opacity: 0;
}

.Os7gCG_offcanvas-backdrop.Os7gCG_show {
  opacity: .5;
}

.Os7gCG_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Os7gCG_offcanvas-header .Os7gCG_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.Os7gCG_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.Os7gCG_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.Os7gCG_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.Os7gCG_placeholder.Os7gCG_btn:before {
  content: "";
  display: inline-block;
}

.Os7gCG_placeholder-xs {
  min-height: .6em;
}

.Os7gCG_placeholder-sm {
  min-height: .8em;
}

.Os7gCG_placeholder-lg {
  min-height: 1.2em;
}

.Os7gCG_placeholder-glow .Os7gCG_placeholder {
  animation: 2s ease-in-out infinite Os7gCG_placeholder-glow;
}

@keyframes Os7gCG_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.Os7gCG_placeholder-wave {
  animation: 2s linear infinite Os7gCG_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes Os7gCG_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.Os7gCG_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.Os7gCG_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.Os7gCG_link-primary {
  color: #4c469c !important;
}

.Os7gCG_link-primary:hover, .Os7gCG_link-primary:focus {
  color: #3d387d !important;
}

.Os7gCG_link-secondary {
  color: #6c757d !important;
}

.Os7gCG_link-secondary:hover, .Os7gCG_link-secondary:focus {
  color: #565e64 !important;
}

.Os7gCG_link-success {
  color: #198754 !important;
}

.Os7gCG_link-success:hover, .Os7gCG_link-success:focus {
  color: #146c43 !important;
}

.Os7gCG_link-info {
  color: #0dcaf0 !important;
}

.Os7gCG_link-info:hover, .Os7gCG_link-info:focus {
  color: #3dd5f3 !important;
}

.Os7gCG_link-warning {
  color: #ffc107 !important;
}

.Os7gCG_link-warning:hover, .Os7gCG_link-warning:focus {
  color: #ffcd39 !important;
}

.Os7gCG_link-danger {
  color: #dc3545 !important;
}

.Os7gCG_link-danger:hover, .Os7gCG_link-danger:focus {
  color: #b02a37 !important;
}

.Os7gCG_link-light {
  color: #f8f9fa !important;
}

.Os7gCG_link-light:hover, .Os7gCG_link-light:focus {
  color: #f9fafb !important;
}

.Os7gCG_link-dark {
  color: #212529 !important;
}

.Os7gCG_link-dark:hover, .Os7gCG_link-dark:focus {
  color: #1a1e21 !important;
}

.Os7gCG_ratio {
  width: 100%;
  position: relative;
}

.Os7gCG_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.Os7gCG_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Os7gCG_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.Os7gCG_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.Os7gCG_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.Os7gCG_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.Os7gCG_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Os7gCG_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.Os7gCG_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.Os7gCG_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .Os7gCG_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Os7gCG_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .Os7gCG_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Os7gCG_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .Os7gCG_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Os7gCG_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Os7gCG_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Os7gCG_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.Os7gCG_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.Os7gCG_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.Os7gCG_visually-hidden, .Os7gCG_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Os7gCG_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.Os7gCG_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Os7gCG_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.Os7gCG_align-baseline {
  vertical-align: baseline !important;
}

.Os7gCG_align-top {
  vertical-align: top !important;
}

.Os7gCG_align-middle {
  vertical-align: middle !important;
}

.Os7gCG_align-bottom {
  vertical-align: bottom !important;
}

.Os7gCG_align-text-bottom {
  vertical-align: text-bottom !important;
}

.Os7gCG_align-text-top {
  vertical-align: text-top !important;
}

.Os7gCG_float-start {
  float: left !important;
}

.Os7gCG_float-end {
  float: right !important;
}

.Os7gCG_float-none {
  float: none !important;
}

.Os7gCG_opacity-0 {
  opacity: 0 !important;
}

.Os7gCG_opacity-25 {
  opacity: .25 !important;
}

.Os7gCG_opacity-50 {
  opacity: .5 !important;
}

.Os7gCG_opacity-75 {
  opacity: .75 !important;
}

.Os7gCG_opacity-100 {
  opacity: 1 !important;
}

.Os7gCG_overflow-auto {
  overflow: auto !important;
}

.Os7gCG_overflow-hidden {
  overflow: hidden !important;
}

.Os7gCG_overflow-visible {
  overflow: visible !important;
}

.Os7gCG_overflow-scroll {
  overflow: scroll !important;
}

.Os7gCG_d-inline {
  display: inline !important;
}

.Os7gCG_d-inline-block {
  display: inline-block !important;
}

.Os7gCG_d-block {
  display: block !important;
}

.Os7gCG_d-grid {
  display: grid !important;
}

.Os7gCG_d-table {
  display: table !important;
}

.Os7gCG_d-table-row {
  display: table-row !important;
}

.Os7gCG_d-table-cell {
  display: table-cell !important;
}

.Os7gCG_d-flex {
  display: flex !important;
}

.Os7gCG_d-inline-flex {
  display: inline-flex !important;
}

.Os7gCG_d-none {
  display: none !important;
}

.Os7gCG_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.Os7gCG_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.Os7gCG_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.Os7gCG_shadow-none {
  box-shadow: none !important;
}

.Os7gCG_position-static {
  position: static !important;
}

.Os7gCG_position-relative {
  position: relative !important;
}

.Os7gCG_position-absolute {
  position: absolute !important;
}

.Os7gCG_position-fixed {
  position: fixed !important;
}

.Os7gCG_position-sticky {
  position: sticky !important;
}

.Os7gCG_top-0 {
  top: 0 !important;
}

.Os7gCG_top-50 {
  top: 50% !important;
}

.Os7gCG_top-100 {
  top: 100% !important;
}

.Os7gCG_bottom-0 {
  bottom: 0 !important;
}

.Os7gCG_bottom-50 {
  bottom: 50% !important;
}

.Os7gCG_bottom-100 {
  bottom: 100% !important;
}

.Os7gCG_start-0 {
  left: 0 !important;
}

.Os7gCG_start-50 {
  left: 50% !important;
}

.Os7gCG_start-100 {
  left: 100% !important;
}

.Os7gCG_end-0 {
  right: 0 !important;
}

.Os7gCG_end-50 {
  right: 50% !important;
}

.Os7gCG_end-100 {
  right: 100% !important;
}

.Os7gCG_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.Os7gCG_translate-middle-x {
  transform: translateX(-50%) !important;
}

.Os7gCG_translate-middle-y {
  transform: translateY(-50%) !important;
}

.Os7gCG_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Os7gCG_border-0 {
  border: 0 !important;
}

.Os7gCG_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Os7gCG_border-top-0 {
  border-top: 0 !important;
}

.Os7gCG_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Os7gCG_border-end-0 {
  border-right: 0 !important;
}

.Os7gCG_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Os7gCG_border-bottom-0 {
  border-bottom: 0 !important;
}

.Os7gCG_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Os7gCG_border-start-0 {
  border-left: 0 !important;
}

.Os7gCG_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.Os7gCG_border-1 {
  --bs-border-width: 1px;
}

.Os7gCG_border-2 {
  --bs-border-width: 2px;
}

.Os7gCG_border-3 {
  --bs-border-width: 3px;
}

.Os7gCG_border-4 {
  --bs-border-width: 4px;
}

.Os7gCG_border-5 {
  --bs-border-width: 5px;
}

.Os7gCG_border-opacity-10 {
  --bs-border-opacity: .1;
}

.Os7gCG_border-opacity-25 {
  --bs-border-opacity: .25;
}

.Os7gCG_border-opacity-50 {
  --bs-border-opacity: .5;
}

.Os7gCG_border-opacity-75 {
  --bs-border-opacity: .75;
}

.Os7gCG_border-opacity-100 {
  --bs-border-opacity: 1;
}

.Os7gCG_w-25 {
  width: 25% !important;
}

.Os7gCG_w-50 {
  width: 50% !important;
}

.Os7gCG_w-75 {
  width: 75% !important;
}

.Os7gCG_w-100 {
  width: 100% !important;
}

.Os7gCG_w-auto {
  width: auto !important;
}

.Os7gCG_mw-100 {
  max-width: 100% !important;
}

.Os7gCG_vw-100 {
  width: 100vw !important;
}

.Os7gCG_min-vw-100 {
  min-width: 100vw !important;
}

.Os7gCG_h-25 {
  height: 25% !important;
}

.Os7gCG_h-50 {
  height: 50% !important;
}

.Os7gCG_h-75 {
  height: 75% !important;
}

.Os7gCG_h-100 {
  height: 100% !important;
}

.Os7gCG_h-auto {
  height: auto !important;
}

.Os7gCG_mh-100 {
  max-height: 100% !important;
}

.Os7gCG_vh-100 {
  height: 100vh !important;
}

.Os7gCG_min-vh-100 {
  min-height: 100vh !important;
}

.Os7gCG_flex-fill {
  flex: auto !important;
}

.Os7gCG_flex-row {
  flex-direction: row !important;
}

.Os7gCG_flex-column {
  flex-direction: column !important;
}

.Os7gCG_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.Os7gCG_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.Os7gCG_flex-grow-0 {
  flex-grow: 0 !important;
}

.Os7gCG_flex-grow-1 {
  flex-grow: 1 !important;
}

.Os7gCG_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.Os7gCG_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.Os7gCG_flex-wrap {
  flex-wrap: wrap !important;
}

.Os7gCG_flex-nowrap {
  flex-wrap: nowrap !important;
}

.Os7gCG_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.Os7gCG_justify-content-start {
  justify-content: flex-start !important;
}

.Os7gCG_justify-content-end {
  justify-content: flex-end !important;
}

.Os7gCG_justify-content-center {
  justify-content: center !important;
}

.Os7gCG_justify-content-between {
  justify-content: space-between !important;
}

.Os7gCG_justify-content-around {
  justify-content: space-around !important;
}

.Os7gCG_justify-content-evenly {
  justify-content: space-evenly !important;
}

.Os7gCG_align-items-start {
  align-items: flex-start !important;
}

.Os7gCG_align-items-end {
  align-items: flex-end !important;
}

.Os7gCG_align-items-center {
  align-items: center !important;
}

.Os7gCG_align-items-baseline {
  align-items: baseline !important;
}

.Os7gCG_align-items-stretch {
  align-items: stretch !important;
}

.Os7gCG_align-content-start {
  align-content: flex-start !important;
}

.Os7gCG_align-content-end {
  align-content: flex-end !important;
}

.Os7gCG_align-content-center {
  align-content: center !important;
}

.Os7gCG_align-content-between {
  align-content: space-between !important;
}

.Os7gCG_align-content-around {
  align-content: space-around !important;
}

.Os7gCG_align-content-stretch {
  align-content: stretch !important;
}

.Os7gCG_align-self-auto {
  align-self: auto !important;
}

.Os7gCG_align-self-start {
  align-self: flex-start !important;
}

.Os7gCG_align-self-end {
  align-self: flex-end !important;
}

.Os7gCG_align-self-center {
  align-self: center !important;
}

.Os7gCG_align-self-baseline {
  align-self: baseline !important;
}

.Os7gCG_align-self-stretch {
  align-self: stretch !important;
}

.Os7gCG_order-first {
  order: -1 !important;
}

.Os7gCG_order-0 {
  order: 0 !important;
}

.Os7gCG_order-1 {
  order: 1 !important;
}

.Os7gCG_order-2 {
  order: 2 !important;
}

.Os7gCG_order-3 {
  order: 3 !important;
}

.Os7gCG_order-4 {
  order: 4 !important;
}

.Os7gCG_order-5 {
  order: 5 !important;
}

.Os7gCG_order-last {
  order: 6 !important;
}

.Os7gCG_m-0 {
  margin: 0 !important;
}

.Os7gCG_m-1 {
  margin: .25rem !important;
}

.Os7gCG_m-2 {
  margin: .5rem !important;
}

.Os7gCG_m-3 {
  margin: 1rem !important;
}

.Os7gCG_m-4 {
  margin: 1.5rem !important;
}

.Os7gCG_m-5 {
  margin: 3rem !important;
}

.Os7gCG_m-auto {
  margin: auto !important;
}

.Os7gCG_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.Os7gCG_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.Os7gCG_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.Os7gCG_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.Os7gCG_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.Os7gCG_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.Os7gCG_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.Os7gCG_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Os7gCG_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.Os7gCG_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.Os7gCG_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.Os7gCG_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.Os7gCG_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.Os7gCG_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.Os7gCG_mt-0 {
  margin-top: 0 !important;
}

.Os7gCG_mt-1 {
  margin-top: .25rem !important;
}

.Os7gCG_mt-2 {
  margin-top: .5rem !important;
}

.Os7gCG_mt-3 {
  margin-top: 1rem !important;
}

.Os7gCG_mt-4 {
  margin-top: 1.5rem !important;
}

.Os7gCG_mt-5 {
  margin-top: 3rem !important;
}

.Os7gCG_mt-auto {
  margin-top: auto !important;
}

.Os7gCG_me-0 {
  margin-right: 0 !important;
}

.Os7gCG_me-1 {
  margin-right: .25rem !important;
}

.Os7gCG_me-2 {
  margin-right: .5rem !important;
}

.Os7gCG_me-3 {
  margin-right: 1rem !important;
}

.Os7gCG_me-4 {
  margin-right: 1.5rem !important;
}

.Os7gCG_me-5 {
  margin-right: 3rem !important;
}

.Os7gCG_me-auto {
  margin-right: auto !important;
}

.Os7gCG_mb-0 {
  margin-bottom: 0 !important;
}

.Os7gCG_mb-1 {
  margin-bottom: .25rem !important;
}

.Os7gCG_mb-2 {
  margin-bottom: .5rem !important;
}

.Os7gCG_mb-3 {
  margin-bottom: 1rem !important;
}

.Os7gCG_mb-4 {
  margin-bottom: 1.5rem !important;
}

.Os7gCG_mb-5 {
  margin-bottom: 3rem !important;
}

.Os7gCG_mb-auto {
  margin-bottom: auto !important;
}

.Os7gCG_ms-0 {
  margin-left: 0 !important;
}

.Os7gCG_ms-1 {
  margin-left: .25rem !important;
}

.Os7gCG_ms-2 {
  margin-left: .5rem !important;
}

.Os7gCG_ms-3 {
  margin-left: 1rem !important;
}

.Os7gCG_ms-4 {
  margin-left: 1.5rem !important;
}

.Os7gCG_ms-5 {
  margin-left: 3rem !important;
}

.Os7gCG_ms-auto {
  margin-left: auto !important;
}

.Os7gCG_p-0 {
  padding: 0 !important;
}

.Os7gCG_p-1 {
  padding: .25rem !important;
}

.Os7gCG_p-2 {
  padding: .5rem !important;
}

.Os7gCG_p-3 {
  padding: 1rem !important;
}

.Os7gCG_p-4 {
  padding: 1.5rem !important;
}

.Os7gCG_p-5 {
  padding: 3rem !important;
}

.Os7gCG_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Os7gCG_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.Os7gCG_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.Os7gCG_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.Os7gCG_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.Os7gCG_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.Os7gCG_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Os7gCG_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.Os7gCG_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.Os7gCG_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.Os7gCG_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.Os7gCG_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.Os7gCG_pt-0 {
  padding-top: 0 !important;
}

.Os7gCG_pt-1 {
  padding-top: .25rem !important;
}

.Os7gCG_pt-2 {
  padding-top: .5rem !important;
}

.Os7gCG_pt-3 {
  padding-top: 1rem !important;
}

.Os7gCG_pt-4 {
  padding-top: 1.5rem !important;
}

.Os7gCG_pt-5 {
  padding-top: 3rem !important;
}

.Os7gCG_pe-0 {
  padding-right: 0 !important;
}

.Os7gCG_pe-1 {
  padding-right: .25rem !important;
}

.Os7gCG_pe-2 {
  padding-right: .5rem !important;
}

.Os7gCG_pe-3 {
  padding-right: 1rem !important;
}

.Os7gCG_pe-4 {
  padding-right: 1.5rem !important;
}

.Os7gCG_pe-5 {
  padding-right: 3rem !important;
}

.Os7gCG_pb-0 {
  padding-bottom: 0 !important;
}

.Os7gCG_pb-1 {
  padding-bottom: .25rem !important;
}

.Os7gCG_pb-2 {
  padding-bottom: .5rem !important;
}

.Os7gCG_pb-3 {
  padding-bottom: 1rem !important;
}

.Os7gCG_pb-4 {
  padding-bottom: 1.5rem !important;
}

.Os7gCG_pb-5 {
  padding-bottom: 3rem !important;
}

.Os7gCG_ps-0 {
  padding-left: 0 !important;
}

.Os7gCG_ps-1 {
  padding-left: .25rem !important;
}

.Os7gCG_ps-2 {
  padding-left: .5rem !important;
}

.Os7gCG_ps-3 {
  padding-left: 1rem !important;
}

.Os7gCG_ps-4 {
  padding-left: 1.5rem !important;
}

.Os7gCG_ps-5 {
  padding-left: 3rem !important;
}

.Os7gCG_gap-0 {
  gap: 0 !important;
}

.Os7gCG_gap-1 {
  gap: .25rem !important;
}

.Os7gCG_gap-2 {
  gap: .5rem !important;
}

.Os7gCG_gap-3 {
  gap: 1rem !important;
}

.Os7gCG_gap-4 {
  gap: 1.5rem !important;
}

.Os7gCG_gap-5 {
  gap: 3rem !important;
}

.Os7gCG_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.Os7gCG_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.Os7gCG_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.Os7gCG_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.Os7gCG_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.Os7gCG_fs-5 {
  font-size: 1.25rem !important;
}

.Os7gCG_fs-6 {
  font-size: 1rem !important;
}

.Os7gCG_fst-italic {
  font-style: italic !important;
}

.Os7gCG_fst-normal {
  font-style: normal !important;
}

.Os7gCG_fw-light {
  font-weight: 300 !important;
}

.Os7gCG_fw-lighter {
  font-weight: lighter !important;
}

.Os7gCG_fw-normal {
  font-weight: 400 !important;
}

.Os7gCG_fw-bold {
  font-weight: 700 !important;
}

.Os7gCG_fw-semibold {
  font-weight: 600 !important;
}

.Os7gCG_fw-bolder {
  font-weight: bolder !important;
}

.Os7gCG_lh-1 {
  line-height: 1 !important;
}

.Os7gCG_lh-sm {
  line-height: 1.25 !important;
}

.Os7gCG_lh-base {
  line-height: 1.5 !important;
}

.Os7gCG_lh-lg {
  line-height: 2 !important;
}

.Os7gCG_text-start {
  text-align: left !important;
}

.Os7gCG_text-end {
  text-align: right !important;
}

.Os7gCG_text-center {
  text-align: center !important;
}

.Os7gCG_text-decoration-none {
  text-decoration: none !important;
}

.Os7gCG_text-decoration-underline {
  text-decoration: underline !important;
}

.Os7gCG_text-decoration-line-through {
  text-decoration: line-through !important;
}

.Os7gCG_text-lowercase {
  text-transform: lowercase !important;
}

.Os7gCG_text-uppercase {
  text-transform: uppercase !important;
}

.Os7gCG_text-capitalize {
  text-transform: capitalize !important;
}

.Os7gCG_text-wrap {
  white-space: normal !important;
}

.Os7gCG_text-nowrap {
  white-space: nowrap !important;
}

.Os7gCG_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.Os7gCG_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.Os7gCG_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.Os7gCG_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.Os7gCG_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.Os7gCG_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.Os7gCG_text-opacity-25 {
  --bs-text-opacity: .25;
}

.Os7gCG_text-opacity-50 {
  --bs-text-opacity: .5;
}

.Os7gCG_text-opacity-75 {
  --bs-text-opacity: .75;
}

.Os7gCG_text-opacity-100 {
  --bs-text-opacity: 1;
}

.Os7gCG_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.Os7gCG_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.Os7gCG_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.Os7gCG_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.Os7gCG_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.Os7gCG_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.Os7gCG_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.Os7gCG_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.Os7gCG_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.Os7gCG_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.Os7gCG_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.Os7gCG_pe-none {
  pointer-events: none !important;
}

.Os7gCG_pe-auto {
  pointer-events: auto !important;
}

.Os7gCG_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.Os7gCG_rounded-0 {
  border-radius: 0 !important;
}

.Os7gCG_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.Os7gCG_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.Os7gCG_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.Os7gCG_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.Os7gCG_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.Os7gCG_rounded-circle {
  border-radius: 50% !important;
}

.Os7gCG_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.Os7gCG_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.Os7gCG_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.Os7gCG_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.Os7gCG_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.Os7gCG_visible {
  visibility: visible !important;
}

.Os7gCG_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .Os7gCG_float-sm-start {
    float: left !important;
  }

  .Os7gCG_float-sm-end {
    float: right !important;
  }

  .Os7gCG_float-sm-none {
    float: none !important;
  }

  .Os7gCG_d-sm-inline {
    display: inline !important;
  }

  .Os7gCG_d-sm-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-sm-block {
    display: block !important;
  }

  .Os7gCG_d-sm-grid {
    display: grid !important;
  }

  .Os7gCG_d-sm-table {
    display: table !important;
  }

  .Os7gCG_d-sm-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-sm-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-sm-flex {
    display: flex !important;
  }

  .Os7gCG_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-sm-none {
    display: none !important;
  }

  .Os7gCG_flex-sm-fill {
    flex: auto !important;
  }

  .Os7gCG_flex-sm-row {
    flex-direction: row !important;
  }

  .Os7gCG_flex-sm-column {
    flex-direction: column !important;
  }

  .Os7gCG_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Os7gCG_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Os7gCG_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .Os7gCG_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .Os7gCG_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Os7gCG_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Os7gCG_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .Os7gCG_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .Os7gCG_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Os7gCG_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .Os7gCG_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .Os7gCG_justify-content-sm-center {
    justify-content: center !important;
  }

  .Os7gCG_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .Os7gCG_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .Os7gCG_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .Os7gCG_align-items-sm-start {
    align-items: flex-start !important;
  }

  .Os7gCG_align-items-sm-end {
    align-items: flex-end !important;
  }

  .Os7gCG_align-items-sm-center {
    align-items: center !important;
  }

  .Os7gCG_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .Os7gCG_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .Os7gCG_align-content-sm-start {
    align-content: flex-start !important;
  }

  .Os7gCG_align-content-sm-end {
    align-content: flex-end !important;
  }

  .Os7gCG_align-content-sm-center {
    align-content: center !important;
  }

  .Os7gCG_align-content-sm-between {
    align-content: space-between !important;
  }

  .Os7gCG_align-content-sm-around {
    align-content: space-around !important;
  }

  .Os7gCG_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .Os7gCG_align-self-sm-auto {
    align-self: auto !important;
  }

  .Os7gCG_align-self-sm-start {
    align-self: flex-start !important;
  }

  .Os7gCG_align-self-sm-end {
    align-self: flex-end !important;
  }

  .Os7gCG_align-self-sm-center {
    align-self: center !important;
  }

  .Os7gCG_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .Os7gCG_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .Os7gCG_order-sm-first {
    order: -1 !important;
  }

  .Os7gCG_order-sm-0 {
    order: 0 !important;
  }

  .Os7gCG_order-sm-1 {
    order: 1 !important;
  }

  .Os7gCG_order-sm-2 {
    order: 2 !important;
  }

  .Os7gCG_order-sm-3 {
    order: 3 !important;
  }

  .Os7gCG_order-sm-4 {
    order: 4 !important;
  }

  .Os7gCG_order-sm-5 {
    order: 5 !important;
  }

  .Os7gCG_order-sm-last {
    order: 6 !important;
  }

  .Os7gCG_m-sm-0 {
    margin: 0 !important;
  }

  .Os7gCG_m-sm-1 {
    margin: .25rem !important;
  }

  .Os7gCG_m-sm-2 {
    margin: .5rem !important;
  }

  .Os7gCG_m-sm-3 {
    margin: 1rem !important;
  }

  .Os7gCG_m-sm-4 {
    margin: 1.5rem !important;
  }

  .Os7gCG_m-sm-5 {
    margin: 3rem !important;
  }

  .Os7gCG_m-sm-auto {
    margin: auto !important;
  }

  .Os7gCG_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Os7gCG_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Os7gCG_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Os7gCG_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Os7gCG_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Os7gCG_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Os7gCG_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Os7gCG_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Os7gCG_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Os7gCG_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Os7gCG_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Os7gCG_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Os7gCG_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Os7gCG_mt-sm-0 {
    margin-top: 0 !important;
  }

  .Os7gCG_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .Os7gCG_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .Os7gCG_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .Os7gCG_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .Os7gCG_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .Os7gCG_mt-sm-auto {
    margin-top: auto !important;
  }

  .Os7gCG_me-sm-0 {
    margin-right: 0 !important;
  }

  .Os7gCG_me-sm-1 {
    margin-right: .25rem !important;
  }

  .Os7gCG_me-sm-2 {
    margin-right: .5rem !important;
  }

  .Os7gCG_me-sm-3 {
    margin-right: 1rem !important;
  }

  .Os7gCG_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .Os7gCG_me-sm-5 {
    margin-right: 3rem !important;
  }

  .Os7gCG_me-sm-auto {
    margin-right: auto !important;
  }

  .Os7gCG_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .Os7gCG_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .Os7gCG_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .Os7gCG_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .Os7gCG_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .Os7gCG_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .Os7gCG_ms-sm-0 {
    margin-left: 0 !important;
  }

  .Os7gCG_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .Os7gCG_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .Os7gCG_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .Os7gCG_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .Os7gCG_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .Os7gCG_ms-sm-auto {
    margin-left: auto !important;
  }

  .Os7gCG_p-sm-0 {
    padding: 0 !important;
  }

  .Os7gCG_p-sm-1 {
    padding: .25rem !important;
  }

  .Os7gCG_p-sm-2 {
    padding: .5rem !important;
  }

  .Os7gCG_p-sm-3 {
    padding: 1rem !important;
  }

  .Os7gCG_p-sm-4 {
    padding: 1.5rem !important;
  }

  .Os7gCG_p-sm-5 {
    padding: 3rem !important;
  }

  .Os7gCG_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Os7gCG_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Os7gCG_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Os7gCG_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Os7gCG_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Os7gCG_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Os7gCG_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Os7gCG_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Os7gCG_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Os7gCG_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Os7gCG_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Os7gCG_pt-sm-0 {
    padding-top: 0 !important;
  }

  .Os7gCG_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .Os7gCG_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .Os7gCG_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .Os7gCG_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .Os7gCG_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .Os7gCG_pe-sm-0 {
    padding-right: 0 !important;
  }

  .Os7gCG_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .Os7gCG_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .Os7gCG_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .Os7gCG_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .Os7gCG_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .Os7gCG_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .Os7gCG_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .Os7gCG_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .Os7gCG_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .Os7gCG_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .Os7gCG_ps-sm-0 {
    padding-left: 0 !important;
  }

  .Os7gCG_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .Os7gCG_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .Os7gCG_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .Os7gCG_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .Os7gCG_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .Os7gCG_gap-sm-0 {
    gap: 0 !important;
  }

  .Os7gCG_gap-sm-1 {
    gap: .25rem !important;
  }

  .Os7gCG_gap-sm-2 {
    gap: .5rem !important;
  }

  .Os7gCG_gap-sm-3 {
    gap: 1rem !important;
  }

  .Os7gCG_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .Os7gCG_gap-sm-5 {
    gap: 3rem !important;
  }

  .Os7gCG_text-sm-start {
    text-align: left !important;
  }

  .Os7gCG_text-sm-end {
    text-align: right !important;
  }

  .Os7gCG_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .Os7gCG_float-md-start {
    float: left !important;
  }

  .Os7gCG_float-md-end {
    float: right !important;
  }

  .Os7gCG_float-md-none {
    float: none !important;
  }

  .Os7gCG_d-md-inline {
    display: inline !important;
  }

  .Os7gCG_d-md-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-md-block {
    display: block !important;
  }

  .Os7gCG_d-md-grid {
    display: grid !important;
  }

  .Os7gCG_d-md-table {
    display: table !important;
  }

  .Os7gCG_d-md-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-md-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-md-flex {
    display: flex !important;
  }

  .Os7gCG_d-md-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-md-none {
    display: none !important;
  }

  .Os7gCG_flex-md-fill {
    flex: auto !important;
  }

  .Os7gCG_flex-md-row {
    flex-direction: row !important;
  }

  .Os7gCG_flex-md-column {
    flex-direction: column !important;
  }

  .Os7gCG_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Os7gCG_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Os7gCG_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .Os7gCG_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .Os7gCG_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Os7gCG_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Os7gCG_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .Os7gCG_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .Os7gCG_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Os7gCG_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .Os7gCG_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .Os7gCG_justify-content-md-center {
    justify-content: center !important;
  }

  .Os7gCG_justify-content-md-between {
    justify-content: space-between !important;
  }

  .Os7gCG_justify-content-md-around {
    justify-content: space-around !important;
  }

  .Os7gCG_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .Os7gCG_align-items-md-start {
    align-items: flex-start !important;
  }

  .Os7gCG_align-items-md-end {
    align-items: flex-end !important;
  }

  .Os7gCG_align-items-md-center {
    align-items: center !important;
  }

  .Os7gCG_align-items-md-baseline {
    align-items: baseline !important;
  }

  .Os7gCG_align-items-md-stretch {
    align-items: stretch !important;
  }

  .Os7gCG_align-content-md-start {
    align-content: flex-start !important;
  }

  .Os7gCG_align-content-md-end {
    align-content: flex-end !important;
  }

  .Os7gCG_align-content-md-center {
    align-content: center !important;
  }

  .Os7gCG_align-content-md-between {
    align-content: space-between !important;
  }

  .Os7gCG_align-content-md-around {
    align-content: space-around !important;
  }

  .Os7gCG_align-content-md-stretch {
    align-content: stretch !important;
  }

  .Os7gCG_align-self-md-auto {
    align-self: auto !important;
  }

  .Os7gCG_align-self-md-start {
    align-self: flex-start !important;
  }

  .Os7gCG_align-self-md-end {
    align-self: flex-end !important;
  }

  .Os7gCG_align-self-md-center {
    align-self: center !important;
  }

  .Os7gCG_align-self-md-baseline {
    align-self: baseline !important;
  }

  .Os7gCG_align-self-md-stretch {
    align-self: stretch !important;
  }

  .Os7gCG_order-md-first {
    order: -1 !important;
  }

  .Os7gCG_order-md-0 {
    order: 0 !important;
  }

  .Os7gCG_order-md-1 {
    order: 1 !important;
  }

  .Os7gCG_order-md-2 {
    order: 2 !important;
  }

  .Os7gCG_order-md-3 {
    order: 3 !important;
  }

  .Os7gCG_order-md-4 {
    order: 4 !important;
  }

  .Os7gCG_order-md-5 {
    order: 5 !important;
  }

  .Os7gCG_order-md-last {
    order: 6 !important;
  }

  .Os7gCG_m-md-0 {
    margin: 0 !important;
  }

  .Os7gCG_m-md-1 {
    margin: .25rem !important;
  }

  .Os7gCG_m-md-2 {
    margin: .5rem !important;
  }

  .Os7gCG_m-md-3 {
    margin: 1rem !important;
  }

  .Os7gCG_m-md-4 {
    margin: 1.5rem !important;
  }

  .Os7gCG_m-md-5 {
    margin: 3rem !important;
  }

  .Os7gCG_m-md-auto {
    margin: auto !important;
  }

  .Os7gCG_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Os7gCG_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Os7gCG_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Os7gCG_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Os7gCG_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Os7gCG_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Os7gCG_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Os7gCG_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Os7gCG_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Os7gCG_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Os7gCG_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Os7gCG_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Os7gCG_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Os7gCG_mt-md-0 {
    margin-top: 0 !important;
  }

  .Os7gCG_mt-md-1 {
    margin-top: .25rem !important;
  }

  .Os7gCG_mt-md-2 {
    margin-top: .5rem !important;
  }

  .Os7gCG_mt-md-3 {
    margin-top: 1rem !important;
  }

  .Os7gCG_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .Os7gCG_mt-md-5 {
    margin-top: 3rem !important;
  }

  .Os7gCG_mt-md-auto {
    margin-top: auto !important;
  }

  .Os7gCG_me-md-0 {
    margin-right: 0 !important;
  }

  .Os7gCG_me-md-1 {
    margin-right: .25rem !important;
  }

  .Os7gCG_me-md-2 {
    margin-right: .5rem !important;
  }

  .Os7gCG_me-md-3 {
    margin-right: 1rem !important;
  }

  .Os7gCG_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .Os7gCG_me-md-5 {
    margin-right: 3rem !important;
  }

  .Os7gCG_me-md-auto {
    margin-right: auto !important;
  }

  .Os7gCG_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .Os7gCG_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .Os7gCG_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .Os7gCG_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .Os7gCG_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .Os7gCG_mb-md-auto {
    margin-bottom: auto !important;
  }

  .Os7gCG_ms-md-0 {
    margin-left: 0 !important;
  }

  .Os7gCG_ms-md-1 {
    margin-left: .25rem !important;
  }

  .Os7gCG_ms-md-2 {
    margin-left: .5rem !important;
  }

  .Os7gCG_ms-md-3 {
    margin-left: 1rem !important;
  }

  .Os7gCG_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .Os7gCG_ms-md-5 {
    margin-left: 3rem !important;
  }

  .Os7gCG_ms-md-auto {
    margin-left: auto !important;
  }

  .Os7gCG_p-md-0 {
    padding: 0 !important;
  }

  .Os7gCG_p-md-1 {
    padding: .25rem !important;
  }

  .Os7gCG_p-md-2 {
    padding: .5rem !important;
  }

  .Os7gCG_p-md-3 {
    padding: 1rem !important;
  }

  .Os7gCG_p-md-4 {
    padding: 1.5rem !important;
  }

  .Os7gCG_p-md-5 {
    padding: 3rem !important;
  }

  .Os7gCG_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Os7gCG_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Os7gCG_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Os7gCG_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Os7gCG_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Os7gCG_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Os7gCG_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Os7gCG_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Os7gCG_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Os7gCG_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Os7gCG_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Os7gCG_pt-md-0 {
    padding-top: 0 !important;
  }

  .Os7gCG_pt-md-1 {
    padding-top: .25rem !important;
  }

  .Os7gCG_pt-md-2 {
    padding-top: .5rem !important;
  }

  .Os7gCG_pt-md-3 {
    padding-top: 1rem !important;
  }

  .Os7gCG_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .Os7gCG_pt-md-5 {
    padding-top: 3rem !important;
  }

  .Os7gCG_pe-md-0 {
    padding-right: 0 !important;
  }

  .Os7gCG_pe-md-1 {
    padding-right: .25rem !important;
  }

  .Os7gCG_pe-md-2 {
    padding-right: .5rem !important;
  }

  .Os7gCG_pe-md-3 {
    padding-right: 1rem !important;
  }

  .Os7gCG_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .Os7gCG_pe-md-5 {
    padding-right: 3rem !important;
  }

  .Os7gCG_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .Os7gCG_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .Os7gCG_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .Os7gCG_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .Os7gCG_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .Os7gCG_ps-md-0 {
    padding-left: 0 !important;
  }

  .Os7gCG_ps-md-1 {
    padding-left: .25rem !important;
  }

  .Os7gCG_ps-md-2 {
    padding-left: .5rem !important;
  }

  .Os7gCG_ps-md-3 {
    padding-left: 1rem !important;
  }

  .Os7gCG_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .Os7gCG_ps-md-5 {
    padding-left: 3rem !important;
  }

  .Os7gCG_gap-md-0 {
    gap: 0 !important;
  }

  .Os7gCG_gap-md-1 {
    gap: .25rem !important;
  }

  .Os7gCG_gap-md-2 {
    gap: .5rem !important;
  }

  .Os7gCG_gap-md-3 {
    gap: 1rem !important;
  }

  .Os7gCG_gap-md-4 {
    gap: 1.5rem !important;
  }

  .Os7gCG_gap-md-5 {
    gap: 3rem !important;
  }

  .Os7gCG_text-md-start {
    text-align: left !important;
  }

  .Os7gCG_text-md-end {
    text-align: right !important;
  }

  .Os7gCG_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .Os7gCG_float-lg-start {
    float: left !important;
  }

  .Os7gCG_float-lg-end {
    float: right !important;
  }

  .Os7gCG_float-lg-none {
    float: none !important;
  }

  .Os7gCG_d-lg-inline {
    display: inline !important;
  }

  .Os7gCG_d-lg-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-lg-block {
    display: block !important;
  }

  .Os7gCG_d-lg-grid {
    display: grid !important;
  }

  .Os7gCG_d-lg-table {
    display: table !important;
  }

  .Os7gCG_d-lg-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-lg-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-lg-flex {
    display: flex !important;
  }

  .Os7gCG_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-lg-none {
    display: none !important;
  }

  .Os7gCG_flex-lg-fill {
    flex: auto !important;
  }

  .Os7gCG_flex-lg-row {
    flex-direction: row !important;
  }

  .Os7gCG_flex-lg-column {
    flex-direction: column !important;
  }

  .Os7gCG_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Os7gCG_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Os7gCG_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .Os7gCG_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .Os7gCG_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Os7gCG_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Os7gCG_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .Os7gCG_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .Os7gCG_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Os7gCG_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .Os7gCG_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .Os7gCG_justify-content-lg-center {
    justify-content: center !important;
  }

  .Os7gCG_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .Os7gCG_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .Os7gCG_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .Os7gCG_align-items-lg-start {
    align-items: flex-start !important;
  }

  .Os7gCG_align-items-lg-end {
    align-items: flex-end !important;
  }

  .Os7gCG_align-items-lg-center {
    align-items: center !important;
  }

  .Os7gCG_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .Os7gCG_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .Os7gCG_align-content-lg-start {
    align-content: flex-start !important;
  }

  .Os7gCG_align-content-lg-end {
    align-content: flex-end !important;
  }

  .Os7gCG_align-content-lg-center {
    align-content: center !important;
  }

  .Os7gCG_align-content-lg-between {
    align-content: space-between !important;
  }

  .Os7gCG_align-content-lg-around {
    align-content: space-around !important;
  }

  .Os7gCG_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .Os7gCG_align-self-lg-auto {
    align-self: auto !important;
  }

  .Os7gCG_align-self-lg-start {
    align-self: flex-start !important;
  }

  .Os7gCG_align-self-lg-end {
    align-self: flex-end !important;
  }

  .Os7gCG_align-self-lg-center {
    align-self: center !important;
  }

  .Os7gCG_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .Os7gCG_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .Os7gCG_order-lg-first {
    order: -1 !important;
  }

  .Os7gCG_order-lg-0 {
    order: 0 !important;
  }

  .Os7gCG_order-lg-1 {
    order: 1 !important;
  }

  .Os7gCG_order-lg-2 {
    order: 2 !important;
  }

  .Os7gCG_order-lg-3 {
    order: 3 !important;
  }

  .Os7gCG_order-lg-4 {
    order: 4 !important;
  }

  .Os7gCG_order-lg-5 {
    order: 5 !important;
  }

  .Os7gCG_order-lg-last {
    order: 6 !important;
  }

  .Os7gCG_m-lg-0 {
    margin: 0 !important;
  }

  .Os7gCG_m-lg-1 {
    margin: .25rem !important;
  }

  .Os7gCG_m-lg-2 {
    margin: .5rem !important;
  }

  .Os7gCG_m-lg-3 {
    margin: 1rem !important;
  }

  .Os7gCG_m-lg-4 {
    margin: 1.5rem !important;
  }

  .Os7gCG_m-lg-5 {
    margin: 3rem !important;
  }

  .Os7gCG_m-lg-auto {
    margin: auto !important;
  }

  .Os7gCG_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Os7gCG_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Os7gCG_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Os7gCG_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Os7gCG_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Os7gCG_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Os7gCG_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Os7gCG_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Os7gCG_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Os7gCG_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Os7gCG_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Os7gCG_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Os7gCG_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Os7gCG_mt-lg-0 {
    margin-top: 0 !important;
  }

  .Os7gCG_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .Os7gCG_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .Os7gCG_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .Os7gCG_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .Os7gCG_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .Os7gCG_mt-lg-auto {
    margin-top: auto !important;
  }

  .Os7gCG_me-lg-0 {
    margin-right: 0 !important;
  }

  .Os7gCG_me-lg-1 {
    margin-right: .25rem !important;
  }

  .Os7gCG_me-lg-2 {
    margin-right: .5rem !important;
  }

  .Os7gCG_me-lg-3 {
    margin-right: 1rem !important;
  }

  .Os7gCG_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .Os7gCG_me-lg-5 {
    margin-right: 3rem !important;
  }

  .Os7gCG_me-lg-auto {
    margin-right: auto !important;
  }

  .Os7gCG_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .Os7gCG_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .Os7gCG_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .Os7gCG_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .Os7gCG_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .Os7gCG_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .Os7gCG_ms-lg-0 {
    margin-left: 0 !important;
  }

  .Os7gCG_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .Os7gCG_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .Os7gCG_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .Os7gCG_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .Os7gCG_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .Os7gCG_ms-lg-auto {
    margin-left: auto !important;
  }

  .Os7gCG_p-lg-0 {
    padding: 0 !important;
  }

  .Os7gCG_p-lg-1 {
    padding: .25rem !important;
  }

  .Os7gCG_p-lg-2 {
    padding: .5rem !important;
  }

  .Os7gCG_p-lg-3 {
    padding: 1rem !important;
  }

  .Os7gCG_p-lg-4 {
    padding: 1.5rem !important;
  }

  .Os7gCG_p-lg-5 {
    padding: 3rem !important;
  }

  .Os7gCG_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Os7gCG_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Os7gCG_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Os7gCG_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Os7gCG_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Os7gCG_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Os7gCG_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Os7gCG_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Os7gCG_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Os7gCG_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Os7gCG_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Os7gCG_pt-lg-0 {
    padding-top: 0 !important;
  }

  .Os7gCG_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .Os7gCG_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .Os7gCG_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .Os7gCG_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .Os7gCG_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .Os7gCG_pe-lg-0 {
    padding-right: 0 !important;
  }

  .Os7gCG_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .Os7gCG_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .Os7gCG_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .Os7gCG_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .Os7gCG_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .Os7gCG_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .Os7gCG_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .Os7gCG_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .Os7gCG_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .Os7gCG_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .Os7gCG_ps-lg-0 {
    padding-left: 0 !important;
  }

  .Os7gCG_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .Os7gCG_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .Os7gCG_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .Os7gCG_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .Os7gCG_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .Os7gCG_gap-lg-0 {
    gap: 0 !important;
  }

  .Os7gCG_gap-lg-1 {
    gap: .25rem !important;
  }

  .Os7gCG_gap-lg-2 {
    gap: .5rem !important;
  }

  .Os7gCG_gap-lg-3 {
    gap: 1rem !important;
  }

  .Os7gCG_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .Os7gCG_gap-lg-5 {
    gap: 3rem !important;
  }

  .Os7gCG_text-lg-start {
    text-align: left !important;
  }

  .Os7gCG_text-lg-end {
    text-align: right !important;
  }

  .Os7gCG_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_float-xl-start {
    float: left !important;
  }

  .Os7gCG_float-xl-end {
    float: right !important;
  }

  .Os7gCG_float-xl-none {
    float: none !important;
  }

  .Os7gCG_d-xl-inline {
    display: inline !important;
  }

  .Os7gCG_d-xl-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-xl-block {
    display: block !important;
  }

  .Os7gCG_d-xl-grid {
    display: grid !important;
  }

  .Os7gCG_d-xl-table {
    display: table !important;
  }

  .Os7gCG_d-xl-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-xl-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-xl-flex {
    display: flex !important;
  }

  .Os7gCG_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-xl-none {
    display: none !important;
  }

  .Os7gCG_flex-xl-fill {
    flex: auto !important;
  }

  .Os7gCG_flex-xl-row {
    flex-direction: row !important;
  }

  .Os7gCG_flex-xl-column {
    flex-direction: column !important;
  }

  .Os7gCG_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Os7gCG_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Os7gCG_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .Os7gCG_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .Os7gCG_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Os7gCG_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Os7gCG_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .Os7gCG_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Os7gCG_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Os7gCG_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .Os7gCG_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .Os7gCG_justify-content-xl-center {
    justify-content: center !important;
  }

  .Os7gCG_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .Os7gCG_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .Os7gCG_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .Os7gCG_align-items-xl-start {
    align-items: flex-start !important;
  }

  .Os7gCG_align-items-xl-end {
    align-items: flex-end !important;
  }

  .Os7gCG_align-items-xl-center {
    align-items: center !important;
  }

  .Os7gCG_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .Os7gCG_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .Os7gCG_align-content-xl-start {
    align-content: flex-start !important;
  }

  .Os7gCG_align-content-xl-end {
    align-content: flex-end !important;
  }

  .Os7gCG_align-content-xl-center {
    align-content: center !important;
  }

  .Os7gCG_align-content-xl-between {
    align-content: space-between !important;
  }

  .Os7gCG_align-content-xl-around {
    align-content: space-around !important;
  }

  .Os7gCG_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .Os7gCG_align-self-xl-auto {
    align-self: auto !important;
  }

  .Os7gCG_align-self-xl-start {
    align-self: flex-start !important;
  }

  .Os7gCG_align-self-xl-end {
    align-self: flex-end !important;
  }

  .Os7gCG_align-self-xl-center {
    align-self: center !important;
  }

  .Os7gCG_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .Os7gCG_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .Os7gCG_order-xl-first {
    order: -1 !important;
  }

  .Os7gCG_order-xl-0 {
    order: 0 !important;
  }

  .Os7gCG_order-xl-1 {
    order: 1 !important;
  }

  .Os7gCG_order-xl-2 {
    order: 2 !important;
  }

  .Os7gCG_order-xl-3 {
    order: 3 !important;
  }

  .Os7gCG_order-xl-4 {
    order: 4 !important;
  }

  .Os7gCG_order-xl-5 {
    order: 5 !important;
  }

  .Os7gCG_order-xl-last {
    order: 6 !important;
  }

  .Os7gCG_m-xl-0 {
    margin: 0 !important;
  }

  .Os7gCG_m-xl-1 {
    margin: .25rem !important;
  }

  .Os7gCG_m-xl-2 {
    margin: .5rem !important;
  }

  .Os7gCG_m-xl-3 {
    margin: 1rem !important;
  }

  .Os7gCG_m-xl-4 {
    margin: 1.5rem !important;
  }

  .Os7gCG_m-xl-5 {
    margin: 3rem !important;
  }

  .Os7gCG_m-xl-auto {
    margin: auto !important;
  }

  .Os7gCG_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Os7gCG_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Os7gCG_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Os7gCG_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Os7gCG_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Os7gCG_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Os7gCG_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Os7gCG_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Os7gCG_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Os7gCG_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Os7gCG_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Os7gCG_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Os7gCG_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Os7gCG_mt-xl-0 {
    margin-top: 0 !important;
  }

  .Os7gCG_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .Os7gCG_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .Os7gCG_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .Os7gCG_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .Os7gCG_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .Os7gCG_mt-xl-auto {
    margin-top: auto !important;
  }

  .Os7gCG_me-xl-0 {
    margin-right: 0 !important;
  }

  .Os7gCG_me-xl-1 {
    margin-right: .25rem !important;
  }

  .Os7gCG_me-xl-2 {
    margin-right: .5rem !important;
  }

  .Os7gCG_me-xl-3 {
    margin-right: 1rem !important;
  }

  .Os7gCG_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .Os7gCG_me-xl-5 {
    margin-right: 3rem !important;
  }

  .Os7gCG_me-xl-auto {
    margin-right: auto !important;
  }

  .Os7gCG_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .Os7gCG_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .Os7gCG_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .Os7gCG_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .Os7gCG_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .Os7gCG_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .Os7gCG_ms-xl-0 {
    margin-left: 0 !important;
  }

  .Os7gCG_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .Os7gCG_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .Os7gCG_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .Os7gCG_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .Os7gCG_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .Os7gCG_ms-xl-auto {
    margin-left: auto !important;
  }

  .Os7gCG_p-xl-0 {
    padding: 0 !important;
  }

  .Os7gCG_p-xl-1 {
    padding: .25rem !important;
  }

  .Os7gCG_p-xl-2 {
    padding: .5rem !important;
  }

  .Os7gCG_p-xl-3 {
    padding: 1rem !important;
  }

  .Os7gCG_p-xl-4 {
    padding: 1.5rem !important;
  }

  .Os7gCG_p-xl-5 {
    padding: 3rem !important;
  }

  .Os7gCG_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Os7gCG_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Os7gCG_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Os7gCG_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Os7gCG_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Os7gCG_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Os7gCG_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Os7gCG_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Os7gCG_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Os7gCG_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Os7gCG_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Os7gCG_pt-xl-0 {
    padding-top: 0 !important;
  }

  .Os7gCG_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .Os7gCG_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .Os7gCG_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .Os7gCG_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .Os7gCG_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .Os7gCG_pe-xl-0 {
    padding-right: 0 !important;
  }

  .Os7gCG_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .Os7gCG_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .Os7gCG_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .Os7gCG_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .Os7gCG_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .Os7gCG_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .Os7gCG_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .Os7gCG_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .Os7gCG_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .Os7gCG_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .Os7gCG_ps-xl-0 {
    padding-left: 0 !important;
  }

  .Os7gCG_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .Os7gCG_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .Os7gCG_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .Os7gCG_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .Os7gCG_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .Os7gCG_gap-xl-0 {
    gap: 0 !important;
  }

  .Os7gCG_gap-xl-1 {
    gap: .25rem !important;
  }

  .Os7gCG_gap-xl-2 {
    gap: .5rem !important;
  }

  .Os7gCG_gap-xl-3 {
    gap: 1rem !important;
  }

  .Os7gCG_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .Os7gCG_gap-xl-5 {
    gap: 3rem !important;
  }

  .Os7gCG_text-xl-start {
    text-align: left !important;
  }

  .Os7gCG_text-xl-end {
    text-align: right !important;
  }

  .Os7gCG_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .Os7gCG_float-xxl-start {
    float: left !important;
  }

  .Os7gCG_float-xxl-end {
    float: right !important;
  }

  .Os7gCG_float-xxl-none {
    float: none !important;
  }

  .Os7gCG_d-xxl-inline {
    display: inline !important;
  }

  .Os7gCG_d-xxl-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-xxl-block {
    display: block !important;
  }

  .Os7gCG_d-xxl-grid {
    display: grid !important;
  }

  .Os7gCG_d-xxl-table {
    display: table !important;
  }

  .Os7gCG_d-xxl-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-xxl-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-xxl-flex {
    display: flex !important;
  }

  .Os7gCG_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-xxl-none {
    display: none !important;
  }

  .Os7gCG_flex-xxl-fill {
    flex: auto !important;
  }

  .Os7gCG_flex-xxl-row {
    flex-direction: row !important;
  }

  .Os7gCG_flex-xxl-column {
    flex-direction: column !important;
  }

  .Os7gCG_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Os7gCG_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Os7gCG_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .Os7gCG_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .Os7gCG_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Os7gCG_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Os7gCG_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .Os7gCG_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Os7gCG_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Os7gCG_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .Os7gCG_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .Os7gCG_justify-content-xxl-center {
    justify-content: center !important;
  }

  .Os7gCG_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .Os7gCG_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .Os7gCG_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .Os7gCG_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .Os7gCG_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .Os7gCG_align-items-xxl-center {
    align-items: center !important;
  }

  .Os7gCG_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .Os7gCG_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .Os7gCG_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .Os7gCG_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .Os7gCG_align-content-xxl-center {
    align-content: center !important;
  }

  .Os7gCG_align-content-xxl-between {
    align-content: space-between !important;
  }

  .Os7gCG_align-content-xxl-around {
    align-content: space-around !important;
  }

  .Os7gCG_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .Os7gCG_align-self-xxl-auto {
    align-self: auto !important;
  }

  .Os7gCG_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .Os7gCG_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .Os7gCG_align-self-xxl-center {
    align-self: center !important;
  }

  .Os7gCG_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .Os7gCG_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .Os7gCG_order-xxl-first {
    order: -1 !important;
  }

  .Os7gCG_order-xxl-0 {
    order: 0 !important;
  }

  .Os7gCG_order-xxl-1 {
    order: 1 !important;
  }

  .Os7gCG_order-xxl-2 {
    order: 2 !important;
  }

  .Os7gCG_order-xxl-3 {
    order: 3 !important;
  }

  .Os7gCG_order-xxl-4 {
    order: 4 !important;
  }

  .Os7gCG_order-xxl-5 {
    order: 5 !important;
  }

  .Os7gCG_order-xxl-last {
    order: 6 !important;
  }

  .Os7gCG_m-xxl-0 {
    margin: 0 !important;
  }

  .Os7gCG_m-xxl-1 {
    margin: .25rem !important;
  }

  .Os7gCG_m-xxl-2 {
    margin: .5rem !important;
  }

  .Os7gCG_m-xxl-3 {
    margin: 1rem !important;
  }

  .Os7gCG_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .Os7gCG_m-xxl-5 {
    margin: 3rem !important;
  }

  .Os7gCG_m-xxl-auto {
    margin: auto !important;
  }

  .Os7gCG_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Os7gCG_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Os7gCG_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Os7gCG_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Os7gCG_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Os7gCG_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Os7gCG_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Os7gCG_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Os7gCG_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Os7gCG_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Os7gCG_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Os7gCG_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Os7gCG_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Os7gCG_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .Os7gCG_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .Os7gCG_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .Os7gCG_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .Os7gCG_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .Os7gCG_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .Os7gCG_mt-xxl-auto {
    margin-top: auto !important;
  }

  .Os7gCG_me-xxl-0 {
    margin-right: 0 !important;
  }

  .Os7gCG_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .Os7gCG_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .Os7gCG_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .Os7gCG_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .Os7gCG_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .Os7gCG_me-xxl-auto {
    margin-right: auto !important;
  }

  .Os7gCG_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .Os7gCG_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .Os7gCG_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .Os7gCG_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .Os7gCG_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Os7gCG_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .Os7gCG_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .Os7gCG_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .Os7gCG_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .Os7gCG_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .Os7gCG_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .Os7gCG_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .Os7gCG_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .Os7gCG_ms-xxl-auto {
    margin-left: auto !important;
  }

  .Os7gCG_p-xxl-0 {
    padding: 0 !important;
  }

  .Os7gCG_p-xxl-1 {
    padding: .25rem !important;
  }

  .Os7gCG_p-xxl-2 {
    padding: .5rem !important;
  }

  .Os7gCG_p-xxl-3 {
    padding: 1rem !important;
  }

  .Os7gCG_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .Os7gCG_p-xxl-5 {
    padding: 3rem !important;
  }

  .Os7gCG_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Os7gCG_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Os7gCG_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Os7gCG_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Os7gCG_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Os7gCG_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Os7gCG_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Os7gCG_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Os7gCG_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Os7gCG_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Os7gCG_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Os7gCG_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .Os7gCG_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .Os7gCG_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .Os7gCG_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .Os7gCG_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .Os7gCG_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .Os7gCG_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .Os7gCG_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .Os7gCG_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .Os7gCG_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .Os7gCG_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .Os7gCG_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .Os7gCG_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .Os7gCG_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .Os7gCG_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .Os7gCG_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .Os7gCG_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Os7gCG_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .Os7gCG_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .Os7gCG_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .Os7gCG_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .Os7gCG_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .Os7gCG_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .Os7gCG_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .Os7gCG_gap-xxl-0 {
    gap: 0 !important;
  }

  .Os7gCG_gap-xxl-1 {
    gap: .25rem !important;
  }

  .Os7gCG_gap-xxl-2 {
    gap: .5rem !important;
  }

  .Os7gCG_gap-xxl-3 {
    gap: 1rem !important;
  }

  .Os7gCG_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .Os7gCG_gap-xxl-5 {
    gap: 3rem !important;
  }

  .Os7gCG_text-xxl-start {
    text-align: left !important;
  }

  .Os7gCG_text-xxl-end {
    text-align: right !important;
  }

  .Os7gCG_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Os7gCG_fs-1 {
    font-size: 2.5rem !important;
  }

  .Os7gCG_fs-2 {
    font-size: 2rem !important;
  }

  .Os7gCG_fs-3 {
    font-size: 1.75rem !important;
  }

  .Os7gCG_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .Os7gCG_d-print-inline {
    display: inline !important;
  }

  .Os7gCG_d-print-inline-block {
    display: inline-block !important;
  }

  .Os7gCG_d-print-block {
    display: block !important;
  }

  .Os7gCG_d-print-grid {
    display: grid !important;
  }

  .Os7gCG_d-print-table {
    display: table !important;
  }

  .Os7gCG_d-print-table-row {
    display: table-row !important;
  }

  .Os7gCG_d-print-table-cell {
    display: table-cell !important;
  }

  .Os7gCG_d-print-flex {
    display: flex !important;
  }

  .Os7gCG_d-print-inline-flex {
    display: inline-flex !important;
  }

  .Os7gCG_d-print-none {
    display: none !important;
  }
}

.Os7gCG_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.Os7gCG_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.Os7gCG_nav-tabs .Os7gCG_nav-item .Os7gCG_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.Os7gCG_nav-tabs .Os7gCG_nav-item .Os7gCG_nav-link.Os7gCG_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.Os7gCG_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#Os7gCG_root {
  height: 100%;
}

.Os7gCG_highcharts-credits {
  display: none !important;
}

h3, .Os7gCG_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.Os7gCG_modal .Os7gCG_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.Os7gCG_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Os7gCG_table thead tr th.Os7gCG_col-actions {
  width: 110px !important;
}

table.Os7gCG_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Os7gCG_table tbody tr.Os7gCG_deleting {
  background-color: #ff323233 !important;
}

.Os7gCG_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.Os7gCG_btn-sm, .Os7gCG_btn-group-sm > .Os7gCG_btn {
  font-size: .6rem !important;
}

.Os7gCG_modal {
  background-color: #0a0a0a4d;
}

.Os7gCG_btn:focus {
  box-shadow: none !important;
}

.Os7gCG_content-filter .Os7gCG_react-datepicker-wrapper {
  width: auto !important;
}

.Os7gCG_content-filter .Os7gCG_react-datepicker-wrapper .Os7gCG_form-control {
  font-size: .85rem !important;
}

.Os7gCG_content-filter .Os7gCG_input-group-text, .Os7gCG_content-filter .Os7gCG_btn {
  height: 2.15rem !important;
}

.Os7gCG_content-filter .Os7gCG_btn {
  border-color: #ccc;
}

.Os7gCG_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.Os7gCG_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.Os7gCG_table > :not(:first-child) {
  border-top: unset !important;
}

.Os7gCG_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.Os7gCG_highcharts-series.Os7gCG_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.Os7gCG_expandable {
  transition: all .5s;
}

.Os7gCG_expandable > .Os7gCG_expander-icon {
  cursor: pointer;
}

.Os7gCG_expandable > .Os7gCG_expander-icon:hover {
  color: #328cfa66;
}

.Os7gCG_expandable.Os7gCG_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .Os7gCG_modal .Os7gCG_modal-xl {
    width: 100% !important;
  }

  .Os7gCG_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.Os7gCG_d-none, .Os7gCG_d-block {
  transition: all .5s;
}

.Os7gCG_wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Os7gCG_nav {
  width: 250px;
  z-index: 4;
}

@media only screen and (max-width: 676px) {
  .Os7gCG_nav {
    z-index: 4;
    transition: all .3s ease-in;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -250px;
  }

  .Os7gCG_nav.Os7gCG_showSideMenu {
    transition: all .3s ease-out;
    left: 0;
  }
}

.Os7gCG_content {
  height: 100vh;
  background-color: #fff;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Os7gCG_sideMenuOverlay {
  height: 100vh;
  width: 100vw;
  z-index: 3;
  background-color: #0000004d;
  display: none;
  position: fixed;
}

@media only screen and (max-width: 676px) {
  .Os7gCG_sideMenuOverlay {
    display: block;
  }
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .Zo5x2q_h6, h5, .Zo5x2q_h5, h4, .Zo5x2q_h4, h3, .Zo5x2q_h3, h2, .Zo5x2q_h2, h1, .Zo5x2q_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .Zo5x2q_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .Zo5x2q_h1 {
    font-size: 2.5rem;
  }
}

h2, .Zo5x2q_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .Zo5x2q_h2 {
    font-size: 2rem;
  }
}

h3, .Zo5x2q_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .Zo5x2q_h3 {
    font-size: 1.75rem;
  }
}

h4, .Zo5x2q_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .Zo5x2q_h4 {
    font-size: 1.5rem;
  }
}

h5, .Zo5x2q_h5 {
  font-size: 1.25rem;
}

h6, .Zo5x2q_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .Zo5x2q_small {
  font-size: .875em;
}

mark, .Zo5x2q_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.Zo5x2q_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.Zo5x2q_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-1 {
    font-size: 5rem;
  }
}

.Zo5x2q_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-2 {
    font-size: 4.5rem;
  }
}

.Zo5x2q_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-3 {
    font-size: 4rem;
  }
}

.Zo5x2q_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-4 {
    font-size: 3.5rem;
  }
}

.Zo5x2q_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-5 {
    font-size: 3rem;
  }
}

.Zo5x2q_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .Zo5x2q_display-6 {
    font-size: 2.5rem;
  }
}

.Zo5x2q_list-unstyled, .Zo5x2q_list-inline {
  padding-left: 0;
  list-style: none;
}

.Zo5x2q_list-inline-item {
  display: inline-block;
}

.Zo5x2q_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.Zo5x2q_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.Zo5x2q_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.Zo5x2q_blockquote > :last-child {
  margin-bottom: 0;
}

.Zo5x2q_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.Zo5x2q_blockquote-footer:before {
  content: "— ";
}

.Zo5x2q_img-fluid {
  max-width: 100%;
  height: auto;
}

.Zo5x2q_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.Zo5x2q_figure {
  display: inline-block;
}

.Zo5x2q_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.Zo5x2q_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.Zo5x2q_container, .Zo5x2q_container-fluid, .Zo5x2q_container-xxl, .Zo5x2q_container-xl, .Zo5x2q_container-lg, .Zo5x2q_container-md, .Zo5x2q_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .Zo5x2q_container-sm, .Zo5x2q_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_container-md, .Zo5x2q_container-sm, .Zo5x2q_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_container-lg, .Zo5x2q_container-md, .Zo5x2q_container-sm, .Zo5x2q_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_container-xl, .Zo5x2q_container-lg, .Zo5x2q_container-md, .Zo5x2q_container-sm, .Zo5x2q_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_container-xxl, .Zo5x2q_container-xl, .Zo5x2q_container-lg, .Zo5x2q_container-md, .Zo5x2q_container-sm, .Zo5x2q_container {
    max-width: 1320px;
  }
}

.Zo5x2q_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.Zo5x2q_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.Zo5x2q_col {
  flex: 1 0;
}

.Zo5x2q_row-cols-auto > * {
  width: auto;
  flex: none;
}

.Zo5x2q_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.Zo5x2q_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.Zo5x2q_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.Zo5x2q_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.Zo5x2q_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.Zo5x2q_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.Zo5x2q_col-auto {
  width: auto;
  flex: none;
}

.Zo5x2q_col-1 {
  width: 8.33333%;
  flex: none;
}

.Zo5x2q_col-2 {
  width: 16.6667%;
  flex: none;
}

.Zo5x2q_col-3 {
  width: 25%;
  flex: none;
}

.Zo5x2q_col-4 {
  width: 33.3333%;
  flex: none;
}

.Zo5x2q_col-5 {
  width: 41.6667%;
  flex: none;
}

.Zo5x2q_col-6 {
  width: 50%;
  flex: none;
}

.Zo5x2q_col-7 {
  width: 58.3333%;
  flex: none;
}

.Zo5x2q_col-8 {
  width: 66.6667%;
  flex: none;
}

.Zo5x2q_col-9 {
  width: 75%;
  flex: none;
}

.Zo5x2q_col-10 {
  width: 83.3333%;
  flex: none;
}

.Zo5x2q_col-11 {
  width: 91.6667%;
  flex: none;
}

.Zo5x2q_col-12 {
  width: 100%;
  flex: none;
}

.Zo5x2q_offset-1 {
  margin-left: 8.33333%;
}

.Zo5x2q_offset-2 {
  margin-left: 16.6667%;
}

.Zo5x2q_offset-3 {
  margin-left: 25%;
}

.Zo5x2q_offset-4 {
  margin-left: 33.3333%;
}

.Zo5x2q_offset-5 {
  margin-left: 41.6667%;
}

.Zo5x2q_offset-6 {
  margin-left: 50%;
}

.Zo5x2q_offset-7 {
  margin-left: 58.3333%;
}

.Zo5x2q_offset-8 {
  margin-left: 66.6667%;
}

.Zo5x2q_offset-9 {
  margin-left: 75%;
}

.Zo5x2q_offset-10 {
  margin-left: 83.3333%;
}

.Zo5x2q_offset-11 {
  margin-left: 91.6667%;
}

.Zo5x2q_g-0, .Zo5x2q_gx-0 {
  --bs-gutter-x: 0;
}

.Zo5x2q_g-0, .Zo5x2q_gy-0 {
  --bs-gutter-y: 0;
}

.Zo5x2q_g-1, .Zo5x2q_gx-1 {
  --bs-gutter-x: .25rem;
}

.Zo5x2q_g-1, .Zo5x2q_gy-1 {
  --bs-gutter-y: .25rem;
}

.Zo5x2q_g-2, .Zo5x2q_gx-2 {
  --bs-gutter-x: .5rem;
}

.Zo5x2q_g-2, .Zo5x2q_gy-2 {
  --bs-gutter-y: .5rem;
}

.Zo5x2q_g-3, .Zo5x2q_gx-3 {
  --bs-gutter-x: 1rem;
}

.Zo5x2q_g-3, .Zo5x2q_gy-3 {
  --bs-gutter-y: 1rem;
}

.Zo5x2q_g-4, .Zo5x2q_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.Zo5x2q_g-4, .Zo5x2q_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.Zo5x2q_g-5, .Zo5x2q_gx-5 {
  --bs-gutter-x: 3rem;
}

.Zo5x2q_g-5, .Zo5x2q_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .Zo5x2q_col-sm {
    flex: 1 0;
  }

  .Zo5x2q_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .Zo5x2q_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-sm-auto {
    width: auto;
    flex: none;
  }

  .Zo5x2q_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .Zo5x2q_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .Zo5x2q_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .Zo5x2q_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .Zo5x2q_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .Zo5x2q_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .Zo5x2q_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .Zo5x2q_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_offset-sm-0 {
    margin-left: 0;
  }

  .Zo5x2q_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .Zo5x2q_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .Zo5x2q_offset-sm-3 {
    margin-left: 25%;
  }

  .Zo5x2q_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .Zo5x2q_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .Zo5x2q_offset-sm-6 {
    margin-left: 50%;
  }

  .Zo5x2q_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .Zo5x2q_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .Zo5x2q_offset-sm-9 {
    margin-left: 75%;
  }

  .Zo5x2q_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .Zo5x2q_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .Zo5x2q_g-sm-0, .Zo5x2q_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .Zo5x2q_g-sm-0, .Zo5x2q_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .Zo5x2q_g-sm-1, .Zo5x2q_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .Zo5x2q_g-sm-1, .Zo5x2q_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .Zo5x2q_g-sm-2, .Zo5x2q_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .Zo5x2q_g-sm-2, .Zo5x2q_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .Zo5x2q_g-sm-3, .Zo5x2q_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .Zo5x2q_g-sm-3, .Zo5x2q_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .Zo5x2q_g-sm-4, .Zo5x2q_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Zo5x2q_g-sm-4, .Zo5x2q_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Zo5x2q_g-sm-5, .Zo5x2q_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .Zo5x2q_g-sm-5, .Zo5x2q_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_col-md {
    flex: 1 0;
  }

  .Zo5x2q_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .Zo5x2q_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .Zo5x2q_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-md-auto {
    width: auto;
    flex: none;
  }

  .Zo5x2q_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .Zo5x2q_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-md-3 {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .Zo5x2q_col-md-6 {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .Zo5x2q_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .Zo5x2q_col-md-9 {
    width: 75%;
    flex: none;
  }

  .Zo5x2q_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .Zo5x2q_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .Zo5x2q_col-md-12 {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_offset-md-0 {
    margin-left: 0;
  }

  .Zo5x2q_offset-md-1 {
    margin-left: 8.33333%;
  }

  .Zo5x2q_offset-md-2 {
    margin-left: 16.6667%;
  }

  .Zo5x2q_offset-md-3 {
    margin-left: 25%;
  }

  .Zo5x2q_offset-md-4 {
    margin-left: 33.3333%;
  }

  .Zo5x2q_offset-md-5 {
    margin-left: 41.6667%;
  }

  .Zo5x2q_offset-md-6 {
    margin-left: 50%;
  }

  .Zo5x2q_offset-md-7 {
    margin-left: 58.3333%;
  }

  .Zo5x2q_offset-md-8 {
    margin-left: 66.6667%;
  }

  .Zo5x2q_offset-md-9 {
    margin-left: 75%;
  }

  .Zo5x2q_offset-md-10 {
    margin-left: 83.3333%;
  }

  .Zo5x2q_offset-md-11 {
    margin-left: 91.6667%;
  }

  .Zo5x2q_g-md-0, .Zo5x2q_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .Zo5x2q_g-md-0, .Zo5x2q_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .Zo5x2q_g-md-1, .Zo5x2q_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .Zo5x2q_g-md-1, .Zo5x2q_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .Zo5x2q_g-md-2, .Zo5x2q_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .Zo5x2q_g-md-2, .Zo5x2q_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .Zo5x2q_g-md-3, .Zo5x2q_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .Zo5x2q_g-md-3, .Zo5x2q_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .Zo5x2q_g-md-4, .Zo5x2q_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Zo5x2q_g-md-4, .Zo5x2q_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Zo5x2q_g-md-5, .Zo5x2q_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .Zo5x2q_g-md-5, .Zo5x2q_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_col-lg {
    flex: 1 0;
  }

  .Zo5x2q_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .Zo5x2q_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-lg-auto {
    width: auto;
    flex: none;
  }

  .Zo5x2q_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .Zo5x2q_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .Zo5x2q_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .Zo5x2q_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .Zo5x2q_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .Zo5x2q_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .Zo5x2q_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .Zo5x2q_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_offset-lg-0 {
    margin-left: 0;
  }

  .Zo5x2q_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .Zo5x2q_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .Zo5x2q_offset-lg-3 {
    margin-left: 25%;
  }

  .Zo5x2q_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .Zo5x2q_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .Zo5x2q_offset-lg-6 {
    margin-left: 50%;
  }

  .Zo5x2q_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .Zo5x2q_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .Zo5x2q_offset-lg-9 {
    margin-left: 75%;
  }

  .Zo5x2q_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .Zo5x2q_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .Zo5x2q_g-lg-0, .Zo5x2q_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .Zo5x2q_g-lg-0, .Zo5x2q_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .Zo5x2q_g-lg-1, .Zo5x2q_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .Zo5x2q_g-lg-1, .Zo5x2q_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .Zo5x2q_g-lg-2, .Zo5x2q_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .Zo5x2q_g-lg-2, .Zo5x2q_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .Zo5x2q_g-lg-3, .Zo5x2q_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .Zo5x2q_g-lg-3, .Zo5x2q_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .Zo5x2q_g-lg-4, .Zo5x2q_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Zo5x2q_g-lg-4, .Zo5x2q_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Zo5x2q_g-lg-5, .Zo5x2q_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .Zo5x2q_g-lg-5, .Zo5x2q_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_col-xl {
    flex: 1 0;
  }

  .Zo5x2q_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .Zo5x2q_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-xl-auto {
    width: auto;
    flex: none;
  }

  .Zo5x2q_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Zo5x2q_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Zo5x2q_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Zo5x2q_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Zo5x2q_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .Zo5x2q_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Zo5x2q_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Zo5x2q_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_offset-xl-0 {
    margin-left: 0;
  }

  .Zo5x2q_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .Zo5x2q_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .Zo5x2q_offset-xl-3 {
    margin-left: 25%;
  }

  .Zo5x2q_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .Zo5x2q_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .Zo5x2q_offset-xl-6 {
    margin-left: 50%;
  }

  .Zo5x2q_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .Zo5x2q_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .Zo5x2q_offset-xl-9 {
    margin-left: 75%;
  }

  .Zo5x2q_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .Zo5x2q_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .Zo5x2q_g-xl-0, .Zo5x2q_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .Zo5x2q_g-xl-0, .Zo5x2q_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .Zo5x2q_g-xl-1, .Zo5x2q_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .Zo5x2q_g-xl-1, .Zo5x2q_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .Zo5x2q_g-xl-2, .Zo5x2q_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .Zo5x2q_g-xl-2, .Zo5x2q_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .Zo5x2q_g-xl-3, .Zo5x2q_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .Zo5x2q_g-xl-3, .Zo5x2q_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .Zo5x2q_g-xl-4, .Zo5x2q_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Zo5x2q_g-xl-4, .Zo5x2q_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Zo5x2q_g-xl-5, .Zo5x2q_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .Zo5x2q_g-xl-5, .Zo5x2q_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_col-xxl {
    flex: 1 0;
  }

  .Zo5x2q_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .Zo5x2q_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .Zo5x2q_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .Zo5x2q_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .Zo5x2q_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .Zo5x2q_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .Zo5x2q_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .Zo5x2q_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .Zo5x2q_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .Zo5x2q_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .Zo5x2q_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .Zo5x2q_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .Zo5x2q_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .Zo5x2q_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .Zo5x2q_offset-xxl-0 {
    margin-left: 0;
  }

  .Zo5x2q_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .Zo5x2q_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .Zo5x2q_offset-xxl-3 {
    margin-left: 25%;
  }

  .Zo5x2q_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .Zo5x2q_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .Zo5x2q_offset-xxl-6 {
    margin-left: 50%;
  }

  .Zo5x2q_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .Zo5x2q_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .Zo5x2q_offset-xxl-9 {
    margin-left: 75%;
  }

  .Zo5x2q_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .Zo5x2q_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .Zo5x2q_g-xxl-0, .Zo5x2q_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .Zo5x2q_g-xxl-0, .Zo5x2q_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .Zo5x2q_g-xxl-1, .Zo5x2q_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .Zo5x2q_g-xxl-1, .Zo5x2q_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .Zo5x2q_g-xxl-2, .Zo5x2q_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .Zo5x2q_g-xxl-2, .Zo5x2q_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .Zo5x2q_g-xxl-3, .Zo5x2q_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .Zo5x2q_g-xxl-3, .Zo5x2q_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .Zo5x2q_g-xxl-4, .Zo5x2q_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .Zo5x2q_g-xxl-4, .Zo5x2q_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .Zo5x2q_g-xxl-5, .Zo5x2q_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .Zo5x2q_g-xxl-5, .Zo5x2q_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.Zo5x2q_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.Zo5x2q_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.Zo5x2q_table > tbody {
  vertical-align: inherit;
}

.Zo5x2q_table > thead {
  vertical-align: bottom;
}

.Zo5x2q_table-group-divider {
  border-top: 2px solid;
}

.Zo5x2q_caption-top {
  caption-side: top;
}

.Zo5x2q_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.Zo5x2q_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.Zo5x2q_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.Zo5x2q_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.Zo5x2q_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.Zo5x2q_table-striped > tbody > tr:nth-of-type(2n+1) > *, .Zo5x2q_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.Zo5x2q_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.Zo5x2q_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.Zo5x2q_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.Zo5x2q_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .Zo5x2q_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .Zo5x2q_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .Zo5x2q_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .Zo5x2q_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .Zo5x2q_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.Zo5x2q_form-label {
  margin-bottom: .5rem;
}

.Zo5x2q_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.Zo5x2q_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.Zo5x2q_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.Zo5x2q_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.Zo5x2q_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-control {
    transition: none;
  }
}

.Zo5x2q_form-control[type="file"] {
  overflow: hidden;
}

.Zo5x2q_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Zo5x2q_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.Zo5x2q_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.Zo5x2q_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.Zo5x2q_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-control::file-selector-button {
    transition: none;
  }
}

.Zo5x2q_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.Zo5x2q_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.Zo5x2q_form-control-plaintext:focus {
  outline: 0;
}

.Zo5x2q_form-control-plaintext.Zo5x2q_form-control-sm, .Zo5x2q_form-control-plaintext.Zo5x2q_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.Zo5x2q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Zo5x2q_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.Zo5x2q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Zo5x2q_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.Zo5x2q_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.Zo5x2q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.Zo5x2q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.Zo5x2q_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.Zo5x2q_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.Zo5x2q_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.Zo5x2q_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.Zo5x2q_form-control-color.Zo5x2q_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.Zo5x2q_form-control-color.Zo5x2q_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.Zo5x2q_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-select {
    transition: none;
  }
}

.Zo5x2q_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_form-select[multiple], .Zo5x2q_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.Zo5x2q_form-select:disabled {
  background-color: #e9ecef;
}

.Zo5x2q_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.Zo5x2q_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.Zo5x2q_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.Zo5x2q_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.Zo5x2q_form-check .Zo5x2q_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.Zo5x2q_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.Zo5x2q_form-check-reverse .Zo5x2q_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.Zo5x2q_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.Zo5x2q_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.Zo5x2q_form-check-input[type="radio"] {
  border-radius: 50%;
}

.Zo5x2q_form-check-input:active {
  filter: brightness(90%);
}

.Zo5x2q_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.Zo5x2q_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.Zo5x2q_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.Zo5x2q_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.Zo5x2q_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.Zo5x2q_form-check-input[disabled] ~ .Zo5x2q_form-check-label, .Zo5x2q_form-check-input:disabled ~ .Zo5x2q_form-check-label {
  cursor: default;
  opacity: .5;
}

.Zo5x2q_form-switch {
  padding-left: 2.5em;
}

.Zo5x2q_form-switch .Zo5x2q_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-switch .Zo5x2q_form-check-input {
    transition: none;
  }
}

.Zo5x2q_form-switch .Zo5x2q_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.Zo5x2q_form-switch .Zo5x2q_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.Zo5x2q_form-switch.Zo5x2q_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.Zo5x2q_form-switch.Zo5x2q_form-check-reverse .Zo5x2q_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.Zo5x2q_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.Zo5x2q_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.Zo5x2q_btn-check[disabled] + .Zo5x2q_btn, .Zo5x2q_btn-check:disabled + .Zo5x2q_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.Zo5x2q_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.Zo5x2q_form-range:focus {
  outline: 0;
}

.Zo5x2q_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_form-range::-moz-focus-outer {
  border: 0;
}

.Zo5x2q_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.Zo5x2q_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.Zo5x2q_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Zo5x2q_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-range::-moz-range-thumb {
    transition: none;
  }
}

.Zo5x2q_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.Zo5x2q_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.Zo5x2q_form-range:disabled {
  pointer-events: none;
}

.Zo5x2q_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.Zo5x2q_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.Zo5x2q_form-floating {
  position: relative;
}

.Zo5x2q_form-floating > .Zo5x2q_form-control, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext, .Zo5x2q_form-floating > .Zo5x2q_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.Zo5x2q_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_form-floating > label {
    transition: none;
  }
}

.Zo5x2q_form-floating > .Zo5x2q_form-control, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext {
  padding: 1rem .75rem;
}

.Zo5x2q_form-floating > .Zo5x2q_form-control::placeholder, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext::placeholder {
  color: #0000;
}

.Zo5x2q_form-floating > .Zo5x2q_form-control:focus, .Zo5x2q_form-floating > .Zo5x2q_form-control:not(:placeholder-shown), .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext:focus, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Zo5x2q_form-floating > .Zo5x2q_form-control:-webkit-autofill, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Zo5x2q_form-floating > .Zo5x2q_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.Zo5x2q_form-floating > .Zo5x2q_form-control:focus ~ label, .Zo5x2q_form-floating > .Zo5x2q_form-control:not(:placeholder-shown) ~ label, .Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext ~ label, .Zo5x2q_form-floating > .Zo5x2q_form-select ~ label, .Zo5x2q_form-floating > .Zo5x2q_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.Zo5x2q_form-floating > .Zo5x2q_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.Zo5x2q_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.Zo5x2q_input-group > .Zo5x2q_form-control, .Zo5x2q_input-group > .Zo5x2q_form-select, .Zo5x2q_input-group > .Zo5x2q_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.Zo5x2q_input-group > .Zo5x2q_form-control:focus, .Zo5x2q_input-group > .Zo5x2q_form-select:focus, .Zo5x2q_input-group > .Zo5x2q_form-floating:focus-within {
  z-index: 5;
}

.Zo5x2q_input-group .Zo5x2q_btn {
  z-index: 2;
  position: relative;
}

.Zo5x2q_input-group .Zo5x2q_btn:focus {
  z-index: 5;
}

.Zo5x2q_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.Zo5x2q_input-group-lg > .Zo5x2q_form-control, .Zo5x2q_input-group-lg > .Zo5x2q_form-select, .Zo5x2q_input-group-lg > .Zo5x2q_input-group-text, .Zo5x2q_input-group-lg > .Zo5x2q_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.Zo5x2q_input-group-sm > .Zo5x2q_form-control, .Zo5x2q_input-group-sm > .Zo5x2q_form-select, .Zo5x2q_input-group-sm > .Zo5x2q_input-group-text, .Zo5x2q_input-group-sm > .Zo5x2q_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.Zo5x2q_input-group-lg > .Zo5x2q_form-select, .Zo5x2q_input-group-sm > .Zo5x2q_form-select {
  padding-right: 3rem;
}

.Zo5x2q_input-group:not(.Zo5x2q_has-validation) > :not(:last-child):not(.Zo5x2q_dropdown-toggle):not(.Zo5x2q_dropdown-menu):not(.Zo5x2q_form-floating), .Zo5x2q_input-group:not(.Zo5x2q_has-validation) > .Zo5x2q_dropdown-toggle:nth-last-child(n+3), .Zo5x2q_input-group:not(.Zo5x2q_has-validation) > .Zo5x2q_form-floating:not(:last-child) > .Zo5x2q_form-control, .Zo5x2q_input-group:not(.Zo5x2q_has-validation) > .Zo5x2q_form-floating:not(:last-child) > .Zo5x2q_form-select, .Zo5x2q_input-group.Zo5x2q_has-validation > :nth-last-child(n+3):not(.Zo5x2q_dropdown-toggle):not(.Zo5x2q_dropdown-menu):not(.Zo5x2q_form-floating), .Zo5x2q_input-group.Zo5x2q_has-validation > .Zo5x2q_dropdown-toggle:nth-last-child(n+4), .Zo5x2q_input-group.Zo5x2q_has-validation > .Zo5x2q_form-floating:nth-last-child(n+3) > .Zo5x2q_form-control, .Zo5x2q_input-group.Zo5x2q_has-validation > .Zo5x2q_form-floating:nth-last-child(n+3) > .Zo5x2q_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Zo5x2q_input-group > :not(:first-child):not(.Zo5x2q_dropdown-menu):not(.Zo5x2q_valid-tooltip):not(.Zo5x2q_valid-feedback):not(.Zo5x2q_invalid-tooltip):not(.Zo5x2q_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.Zo5x2q_input-group > .Zo5x2q_form-floating:not(:first-child) > .Zo5x2q_form-control, .Zo5x2q_input-group > .Zo5x2q_form-floating:not(:first-child) > .Zo5x2q_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Zo5x2q_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Zo5x2q_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Zo5x2q_was-validated :valid ~ .Zo5x2q_valid-feedback, .Zo5x2q_was-validated :valid ~ .Zo5x2q_valid-tooltip, .Zo5x2q_is-valid ~ .Zo5x2q_valid-feedback, .Zo5x2q_is-valid ~ .Zo5x2q_valid-tooltip {
  display: block;
}

.Zo5x2q_was-validated .Zo5x2q_form-control:valid, .Zo5x2q_form-control.Zo5x2q_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-control:valid:focus, .Zo5x2q_form-control.Zo5x2q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Zo5x2q_was-validated textarea.Zo5x2q_form-control:valid, textarea.Zo5x2q_form-control.Zo5x2q_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-select:valid, .Zo5x2q_form-select.Zo5x2q_is-valid {
  border-color: #198754;
}

.Zo5x2q_was-validated .Zo5x2q_form-select:valid:not([multiple]):not([size]), .Zo5x2q_was-validated .Zo5x2q_form-select:valid:not([multiple])[size="1"], .Zo5x2q_form-select.Zo5x2q_is-valid:not([multiple]):not([size]), .Zo5x2q_form-select.Zo5x2q_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Zo5x2q_was-validated .Zo5x2q_form-select:valid:focus, .Zo5x2q_form-select.Zo5x2q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.Zo5x2q_was-validated .Zo5x2q_form-control-color:valid, .Zo5x2q_form-control-color.Zo5x2q_is-valid {
  width: calc(1.5em + 3.75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:valid, .Zo5x2q_form-check-input.Zo5x2q_is-valid {
  border-color: #198754;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:valid:checked, .Zo5x2q_form-check-input.Zo5x2q_is-valid:checked {
  background-color: #198754;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:valid:focus, .Zo5x2q_form-check-input.Zo5x2q_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:valid ~ .Zo5x2q_form-check-label, .Zo5x2q_form-check-input.Zo5x2q_is-valid ~ .Zo5x2q_form-check-label {
  color: #198754;
}

.Zo5x2q_form-check-inline .Zo5x2q_form-check-input ~ .Zo5x2q_valid-feedback {
  margin-left: .5em;
}

.Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-control:not(:focus):valid, .Zo5x2q_input-group > .Zo5x2q_form-control:not(:focus).Zo5x2q_is-valid, .Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-select:not(:focus):valid, .Zo5x2q_input-group > .Zo5x2q_form-select:not(:focus).Zo5x2q_is-valid, .Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-floating:not(:focus-within):valid, .Zo5x2q_input-group > .Zo5x2q_form-floating:not(:focus-within).Zo5x2q_is-valid {
  z-index: 3;
}

.Zo5x2q_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.Zo5x2q_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.Zo5x2q_was-validated :invalid ~ .Zo5x2q_invalid-feedback, .Zo5x2q_was-validated :invalid ~ .Zo5x2q_invalid-tooltip, .Zo5x2q_is-invalid ~ .Zo5x2q_invalid-feedback, .Zo5x2q_is-invalid ~ .Zo5x2q_invalid-tooltip {
  display: block;
}

.Zo5x2q_was-validated .Zo5x2q_form-control:invalid, .Zo5x2q_form-control.Zo5x2q_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-control:invalid:focus, .Zo5x2q_form-control.Zo5x2q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Zo5x2q_was-validated textarea.Zo5x2q_form-control:invalid, textarea.Zo5x2q_form-control.Zo5x2q_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-select:invalid, .Zo5x2q_form-select.Zo5x2q_is-invalid {
  border-color: #dc3545;
}

.Zo5x2q_was-validated .Zo5x2q_form-select:invalid:not([multiple]):not([size]), .Zo5x2q_was-validated .Zo5x2q_form-select:invalid:not([multiple])[size="1"], .Zo5x2q_form-select.Zo5x2q_is-invalid:not([multiple]):not([size]), .Zo5x2q_form-select.Zo5x2q_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.Zo5x2q_was-validated .Zo5x2q_form-select:invalid:focus, .Zo5x2q_form-select.Zo5x2q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Zo5x2q_was-validated .Zo5x2q_form-control-color:invalid, .Zo5x2q_form-control-color.Zo5x2q_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:invalid, .Zo5x2q_form-check-input.Zo5x2q_is-invalid {
  border-color: #dc3545;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:invalid:checked, .Zo5x2q_form-check-input.Zo5x2q_is-invalid:checked {
  background-color: #dc3545;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:invalid:focus, .Zo5x2q_form-check-input.Zo5x2q_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.Zo5x2q_was-validated .Zo5x2q_form-check-input:invalid ~ .Zo5x2q_form-check-label, .Zo5x2q_form-check-input.Zo5x2q_is-invalid ~ .Zo5x2q_form-check-label {
  color: #dc3545;
}

.Zo5x2q_form-check-inline .Zo5x2q_form-check-input ~ .Zo5x2q_invalid-feedback {
  margin-left: .5em;
}

.Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-control:not(:focus):invalid, .Zo5x2q_input-group > .Zo5x2q_form-control:not(:focus).Zo5x2q_is-invalid, .Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-select:not(:focus):invalid, .Zo5x2q_input-group > .Zo5x2q_form-select:not(:focus).Zo5x2q_is-invalid, .Zo5x2q_was-validated .Zo5x2q_input-group > .Zo5x2q_form-floating:not(:focus-within):invalid, .Zo5x2q_input-group > .Zo5x2q_form-floating:not(:focus-within).Zo5x2q_is-invalid {
  z-index: 4;
}

.Zo5x2q_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_btn {
    transition: none;
  }
}

.Zo5x2q_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.Zo5x2q_btn-check + .Zo5x2q_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.Zo5x2q_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Zo5x2q_btn-check:focus-visible + .Zo5x2q_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.Zo5x2q_btn-check:checked + .Zo5x2q_btn, :not(.Zo5x2q_btn-check) + .Zo5x2q_btn:active, .Zo5x2q_btn:first-child:active, .Zo5x2q_btn.Zo5x2q_active, .Zo5x2q_btn.Zo5x2q_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.Zo5x2q_btn-check:checked + .Zo5x2q_btn:focus-visible, :not(.Zo5x2q_btn-check) + .Zo5x2q_btn:active:focus-visible, .Zo5x2q_btn:first-child:active:focus-visible, .Zo5x2q_btn.Zo5x2q_active:focus-visible, .Zo5x2q_btn.Zo5x2q_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.Zo5x2q_btn:disabled, .Zo5x2q_btn.Zo5x2q_disabled, fieldset:disabled .Zo5x2q_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.Zo5x2q_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.Zo5x2q_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.Zo5x2q_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.Zo5x2q_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.Zo5x2q_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.Zo5x2q_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.Zo5x2q_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.Zo5x2q_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.Zo5x2q_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.Zo5x2q_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.Zo5x2q_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.Zo5x2q_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.Zo5x2q_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.Zo5x2q_btn-lg, .Zo5x2q_btn-group-lg > .Zo5x2q_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.Zo5x2q_btn-sm, .Zo5x2q_btn-group-sm > .Zo5x2q_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.Zo5x2q_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_fade {
    transition: none;
  }
}

.Zo5x2q_fade:not(.Zo5x2q_show) {
  opacity: 0;
}

.Zo5x2q_collapse:not(.Zo5x2q_show) {
  display: none;
}

.Zo5x2q_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_collapsing {
    transition: none;
  }
}

.Zo5x2q_collapsing.Zo5x2q_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_collapsing.Zo5x2q_collapse-horizontal {
    transition: none;
  }
}

.Zo5x2q_dropup, .Zo5x2q_dropend, .Zo5x2q_dropdown, .Zo5x2q_dropstart, .Zo5x2q_dropup-center, .Zo5x2q_dropdown-center {
  position: relative;
}

.Zo5x2q_dropdown-toggle {
  white-space: nowrap;
}

.Zo5x2q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.Zo5x2q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Zo5x2q_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.Zo5x2q_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.Zo5x2q_dropdown-menu-start {
  --bs-position: start;
}

.Zo5x2q_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.Zo5x2q_dropdown-menu-end {
  --bs-position: end;
}

.Zo5x2q_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .Zo5x2q_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .Zo5x2q_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Zo5x2q_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .Zo5x2q_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_dropdown-menu-md-start {
    --bs-position: start;
  }

  .Zo5x2q_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Zo5x2q_dropdown-menu-md-end {
    --bs-position: end;
  }

  .Zo5x2q_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .Zo5x2q_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Zo5x2q_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .Zo5x2q_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .Zo5x2q_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Zo5x2q_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .Zo5x2q_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .Zo5x2q_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .Zo5x2q_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .Zo5x2q_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.Zo5x2q_dropup .Zo5x2q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.Zo5x2q_dropup .Zo5x2q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.Zo5x2q_dropup .Zo5x2q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Zo5x2q_dropend .Zo5x2q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.Zo5x2q_dropend .Zo5x2q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.Zo5x2q_dropend .Zo5x2q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Zo5x2q_dropend .Zo5x2q_dropdown-toggle:after {
  vertical-align: 0;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-toggle:empty:after {
  margin-left: 0;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-toggle:before {
  vertical-align: 0;
}

.Zo5x2q_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.Zo5x2q_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.Zo5x2q_dropdown-item:hover, .Zo5x2q_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.Zo5x2q_dropdown-item.Zo5x2q_active, .Zo5x2q_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.Zo5x2q_dropdown-item.Zo5x2q_disabled, .Zo5x2q_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.Zo5x2q_dropdown-menu.Zo5x2q_show {
  display: block;
}

.Zo5x2q_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.Zo5x2q_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.Zo5x2q_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.Zo5x2q_btn-group, .Zo5x2q_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.Zo5x2q_btn-group > .Zo5x2q_btn, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn {
  flex: auto;
  position: relative;
}

.Zo5x2q_btn-group > .Zo5x2q_btn-check:checked + .Zo5x2q_btn, .Zo5x2q_btn-group > .Zo5x2q_btn-check:focus + .Zo5x2q_btn, .Zo5x2q_btn-group > .Zo5x2q_btn:hover, .Zo5x2q_btn-group > .Zo5x2q_btn:focus, .Zo5x2q_btn-group > .Zo5x2q_btn:active, .Zo5x2q_btn-group > .Zo5x2q_btn.Zo5x2q_active, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-check:checked + .Zo5x2q_btn, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-check:focus + .Zo5x2q_btn, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn:hover, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn:focus, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn:active, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn.Zo5x2q_active {
  z-index: 1;
}

.Zo5x2q_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.Zo5x2q_btn-toolbar .Zo5x2q_input-group {
  width: auto;
}

.Zo5x2q_btn-group {
  border-radius: .375rem;
}

.Zo5x2q_btn-group > :not(.Zo5x2q_btn-check:first-child) + .Zo5x2q_btn, .Zo5x2q_btn-group > .Zo5x2q_btn-group:not(:first-child) {
  margin-left: -1px;
}

.Zo5x2q_btn-group > .Zo5x2q_btn:not(:last-child):not(.Zo5x2q_dropdown-toggle), .Zo5x2q_btn-group > .Zo5x2q_btn.Zo5x2q_dropdown-toggle-split:first-child, .Zo5x2q_btn-group > .Zo5x2q_btn-group:not(:last-child) > .Zo5x2q_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Zo5x2q_btn-group > .Zo5x2q_btn:nth-child(n+3), .Zo5x2q_btn-group > :not(.Zo5x2q_btn-check) + .Zo5x2q_btn, .Zo5x2q_btn-group > .Zo5x2q_btn-group:not(:first-child) > .Zo5x2q_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Zo5x2q_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.Zo5x2q_dropdown-toggle-split:after, .Zo5x2q_dropup .Zo5x2q_dropdown-toggle-split:after, .Zo5x2q_dropend .Zo5x2q_dropdown-toggle-split:after {
  margin-left: 0;
}

.Zo5x2q_dropstart .Zo5x2q_dropdown-toggle-split:before {
  margin-right: 0;
}

.Zo5x2q_btn-sm + .Zo5x2q_dropdown-toggle-split, .Zo5x2q_btn-group-sm > .Zo5x2q_btn + .Zo5x2q_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.Zo5x2q_btn-lg + .Zo5x2q_dropdown-toggle-split, .Zo5x2q_btn-group-lg > .Zo5x2q_btn + .Zo5x2q_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.Zo5x2q_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Zo5x2q_btn-group-vertical > .Zo5x2q_btn, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-group {
  width: 100%;
}

.Zo5x2q_btn-group-vertical > .Zo5x2q_btn:not(:first-child), .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-group:not(:first-child) {
  margin-top: -1px;
}

.Zo5x2q_btn-group-vertical > .Zo5x2q_btn:not(:last-child):not(.Zo5x2q_dropdown-toggle), .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-group:not(:last-child) > .Zo5x2q_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Zo5x2q_btn-group-vertical > .Zo5x2q_btn ~ .Zo5x2q_btn, .Zo5x2q_btn-group-vertical > .Zo5x2q_btn-group:not(:first-child) > .Zo5x2q_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Zo5x2q_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Zo5x2q_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_nav-link {
    transition: none;
  }
}

.Zo5x2q_nav-link:hover, .Zo5x2q_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.Zo5x2q_nav-link.Zo5x2q_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.Zo5x2q_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-link:hover, .Zo5x2q_nav-tabs .Zo5x2q_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-link.Zo5x2q_disabled, .Zo5x2q_nav-tabs .Zo5x2q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-link.Zo5x2q_active, .Zo5x2q_nav-tabs .Zo5x2q_nav-item.Zo5x2q_show .Zo5x2q_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.Zo5x2q_nav-tabs .Zo5x2q_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Zo5x2q_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.Zo5x2q_nav-pills .Zo5x2q_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.Zo5x2q_nav-pills .Zo5x2q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.Zo5x2q_nav-pills .Zo5x2q_nav-link.Zo5x2q_active, .Zo5x2q_nav-pills .Zo5x2q_show > .Zo5x2q_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.Zo5x2q_nav-fill > .Zo5x2q_nav-link, .Zo5x2q_nav-fill .Zo5x2q_nav-item {
  text-align: center;
  flex: auto;
}

.Zo5x2q_nav-justified > .Zo5x2q_nav-link, .Zo5x2q_nav-justified .Zo5x2q_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.Zo5x2q_nav-fill .Zo5x2q_nav-item .Zo5x2q_nav-link, .Zo5x2q_nav-justified .Zo5x2q_nav-item .Zo5x2q_nav-link {
  width: 100%;
}

.Zo5x2q_tab-content > .Zo5x2q_tab-pane {
  display: none;
}

.Zo5x2q_tab-content > .Zo5x2q_active {
  display: block;
}

.Zo5x2q_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.Zo5x2q_navbar > .Zo5x2q_container, .Zo5x2q_navbar > .Zo5x2q_container-fluid, .Zo5x2q_navbar > .Zo5x2q_container-sm, .Zo5x2q_navbar > .Zo5x2q_container-md, .Zo5x2q_navbar > .Zo5x2q_container-lg, .Zo5x2q_navbar > .Zo5x2q_container-xl, .Zo5x2q_navbar > .Zo5x2q_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Zo5x2q_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.Zo5x2q_navbar-brand:hover, .Zo5x2q_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.Zo5x2q_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Zo5x2q_navbar-nav .Zo5x2q_show > .Zo5x2q_nav-link, .Zo5x2q_navbar-nav .Zo5x2q_nav-link.Zo5x2q_active {
  color: var(--bs-navbar-active-color);
}

.Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
  position: static;
}

.Zo5x2q_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Zo5x2q_navbar-text a, .Zo5x2q_navbar-text a:hover, .Zo5x2q_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.Zo5x2q_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.Zo5x2q_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_navbar-toggler {
    transition: none;
  }
}

.Zo5x2q_navbar-toggler:hover {
  text-decoration: none;
}

.Zo5x2q_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.Zo5x2q_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.Zo5x2q_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .Zo5x2q_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-nav {
    flex-direction: row;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
    position: absolute;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-nav-scroll {
    overflow: visible;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_navbar-toggler {
    display: none;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_navbar-expand-sm .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-nav {
    flex-direction: row;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
    position: absolute;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-nav-scroll {
    overflow: visible;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_navbar-toggler {
    display: none;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_navbar-expand-md .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-nav {
    flex-direction: row;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
    position: absolute;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-nav-scroll {
    overflow: visible;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_navbar-toggler {
    display: none;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_navbar-expand-lg .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-nav {
    flex-direction: row;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
    position: absolute;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-nav-scroll {
    overflow: visible;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_navbar-toggler {
    display: none;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_navbar-expand-xl .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-nav {
    flex-direction: row;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
    position: absolute;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-nav-scroll {
    overflow: visible;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_navbar-toggler {
    display: none;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_navbar-expand-xxl .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.Zo5x2q_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-nav {
  flex-direction: row;
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-nav .Zo5x2q_dropdown-menu {
  position: absolute;
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-nav .Zo5x2q_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-nav-scroll {
  overflow: visible;
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.Zo5x2q_navbar-expand .Zo5x2q_navbar-toggler {
  display: none;
}

.Zo5x2q_navbar-expand .Zo5x2q_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.Zo5x2q_navbar-expand .Zo5x2q_offcanvas .Zo5x2q_offcanvas-header {
  display: none;
}

.Zo5x2q_navbar-expand .Zo5x2q_offcanvas .Zo5x2q_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.Zo5x2q_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.Zo5x2q_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Zo5x2q_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.Zo5x2q_card > .Zo5x2q_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.Zo5x2q_card > .Zo5x2q_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.Zo5x2q_card > .Zo5x2q_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.Zo5x2q_card > .Zo5x2q_card-header + .Zo5x2q_list-group, .Zo5x2q_card > .Zo5x2q_list-group + .Zo5x2q_card-footer {
  border-top: 0;
}

.Zo5x2q_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.Zo5x2q_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.Zo5x2q_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.Zo5x2q_card-text:last-child {
  margin-bottom: 0;
}

.Zo5x2q_card-link + .Zo5x2q_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.Zo5x2q_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.Zo5x2q_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.Zo5x2q_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.Zo5x2q_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.Zo5x2q_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.Zo5x2q_card-header-tabs .Zo5x2q_nav-link.Zo5x2q_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.Zo5x2q_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.Zo5x2q_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.Zo5x2q_card-img, .Zo5x2q_card-img-top, .Zo5x2q_card-img-bottom {
  width: 100%;
}

.Zo5x2q_card-img, .Zo5x2q_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.Zo5x2q_card-img, .Zo5x2q_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.Zo5x2q_card-group > .Zo5x2q_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .Zo5x2q_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .Zo5x2q_card-group > .Zo5x2q_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card + .Zo5x2q_card {
    border-left: 0;
    margin-left: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:last-child) .Zo5x2q_card-img-top, .Zo5x2q_card-group > .Zo5x2q_card:not(:last-child) .Zo5x2q_card-header {
    border-top-right-radius: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:last-child) .Zo5x2q_card-img-bottom, .Zo5x2q_card-group > .Zo5x2q_card:not(:last-child) .Zo5x2q_card-footer {
    border-bottom-right-radius: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:first-child) .Zo5x2q_card-img-top, .Zo5x2q_card-group > .Zo5x2q_card:not(:first-child) .Zo5x2q_card-header {
    border-top-left-radius: 0;
  }

  .Zo5x2q_card-group > .Zo5x2q_card:not(:first-child) .Zo5x2q_card-img-bottom, .Zo5x2q_card-group > .Zo5x2q_card:not(:first-child) .Zo5x2q_card-footer {
    border-bottom-left-radius: 0;
  }
}

.Zo5x2q_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.Zo5x2q_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_accordion-button {
    transition: none;
  }
}

.Zo5x2q_accordion-button:not(.Zo5x2q_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.Zo5x2q_accordion-button:not(.Zo5x2q_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.Zo5x2q_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_accordion-button:after {
    transition: none;
  }
}

.Zo5x2q_accordion-button:hover {
  z-index: 2;
}

.Zo5x2q_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.Zo5x2q_accordion-header {
  margin-bottom: 0;
}

.Zo5x2q_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.Zo5x2q_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.Zo5x2q_accordion-item:first-of-type .Zo5x2q_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.Zo5x2q_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.Zo5x2q_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Zo5x2q_accordion-item:last-of-type .Zo5x2q_accordion-button.Zo5x2q_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.Zo5x2q_accordion-item:last-of-type .Zo5x2q_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.Zo5x2q_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.Zo5x2q_accordion-flush .Zo5x2q_accordion-collapse {
  border-width: 0;
}

.Zo5x2q_accordion-flush .Zo5x2q_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.Zo5x2q_accordion-flush .Zo5x2q_accordion-item:first-child {
  border-top: 0;
}

.Zo5x2q_accordion-flush .Zo5x2q_accordion-item:last-child {
  border-bottom: 0;
}

.Zo5x2q_accordion-flush .Zo5x2q_accordion-item .Zo5x2q_accordion-button, .Zo5x2q_accordion-flush .Zo5x2q_accordion-item .Zo5x2q_accordion-button.Zo5x2q_collapsed {
  border-radius: 0;
}

.Zo5x2q_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.Zo5x2q_breadcrumb-item + .Zo5x2q_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.Zo5x2q_breadcrumb-item + .Zo5x2q_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.Zo5x2q_breadcrumb-item.Zo5x2q_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.Zo5x2q_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.Zo5x2q_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_page-link {
    transition: none;
  }
}

.Zo5x2q_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.Zo5x2q_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.Zo5x2q_page-link.Zo5x2q_active, .Zo5x2q_active > .Zo5x2q_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.Zo5x2q_page-link.Zo5x2q_disabled, .Zo5x2q_disabled > .Zo5x2q_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.Zo5x2q_page-item:not(:first-child) .Zo5x2q_page-link {
  margin-left: -1px;
}

.Zo5x2q_page-item:first-child .Zo5x2q_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.Zo5x2q_page-item:last-child .Zo5x2q_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.Zo5x2q_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.Zo5x2q_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.Zo5x2q_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.Zo5x2q_badge:empty {
  display: none;
}

.Zo5x2q_btn .Zo5x2q_badge {
  position: relative;
  top: -1px;
}

.Zo5x2q_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.Zo5x2q_alert-heading {
  color: inherit;
}

.Zo5x2q_alert-link {
  font-weight: 700;
}

.Zo5x2q_alert-dismissible {
  padding-right: 3rem;
}

.Zo5x2q_alert-dismissible .Zo5x2q_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.Zo5x2q_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.Zo5x2q_alert-primary .Zo5x2q_alert-link {
  color: #25224b;
}

.Zo5x2q_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.Zo5x2q_alert-secondary .Zo5x2q_alert-link {
  color: #34383c;
}

.Zo5x2q_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.Zo5x2q_alert-success .Zo5x2q_alert-link {
  color: #0c4128;
}

.Zo5x2q_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.Zo5x2q_alert-info .Zo5x2q_alert-link {
  color: #04414d;
}

.Zo5x2q_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.Zo5x2q_alert-warning .Zo5x2q_alert-link {
  color: #523e02;
}

.Zo5x2q_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.Zo5x2q_alert-danger .Zo5x2q_alert-link {
  color: #6a1a21;
}

.Zo5x2q_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.Zo5x2q_alert-light .Zo5x2q_alert-link {
  color: #4f5050;
}

.Zo5x2q_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.Zo5x2q_alert-dark .Zo5x2q_alert-link {
  color: #101214;
}

@keyframes Zo5x2q_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.Zo5x2q_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.Zo5x2q_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_progress-bar {
    transition: none;
  }
}

.Zo5x2q_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.Zo5x2q_progress-bar-animated {
  animation: 1s linear infinite Zo5x2q_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_progress-bar-animated {
    animation: none;
  }
}

.Zo5x2q_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.Zo5x2q_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.Zo5x2q_list-group-numbered > .Zo5x2q_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.Zo5x2q_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.Zo5x2q_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.Zo5x2q_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.Zo5x2q_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.Zo5x2q_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.Zo5x2q_list-group-item.Zo5x2q_disabled, .Zo5x2q_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.Zo5x2q_list-group-item.Zo5x2q_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
  border-top-width: 0;
}

.Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.Zo5x2q_list-group-horizontal {
  flex-direction: row;
}

.Zo5x2q_list-group-horizontal > .Zo5x2q_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.Zo5x2q_list-group-horizontal > .Zo5x2q_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.Zo5x2q_list-group-horizontal > .Zo5x2q_list-group-item.Zo5x2q_active {
  margin-top: 0;
}

.Zo5x2q_list-group-horizontal > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.Zo5x2q_list-group-horizontal > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .Zo5x2q_list-group-horizontal-sm {
    flex-direction: row;
  }

  .Zo5x2q_list-group-horizontal-sm > .Zo5x2q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-sm > .Zo5x2q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-sm > .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-top: 0;
  }

  .Zo5x2q_list-group-horizontal-sm > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Zo5x2q_list-group-horizontal-sm > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .Zo5x2q_list-group-horizontal-md {
    flex-direction: row;
  }

  .Zo5x2q_list-group-horizontal-md > .Zo5x2q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-md > .Zo5x2q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-md > .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-top: 0;
  }

  .Zo5x2q_list-group-horizontal-md > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Zo5x2q_list-group-horizontal-md > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .Zo5x2q_list-group-horizontal-lg {
    flex-direction: row;
  }

  .Zo5x2q_list-group-horizontal-lg > .Zo5x2q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-lg > .Zo5x2q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-lg > .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-top: 0;
  }

  .Zo5x2q_list-group-horizontal-lg > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Zo5x2q_list-group-horizontal-lg > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_list-group-horizontal-xl {
    flex-direction: row;
  }

  .Zo5x2q_list-group-horizontal-xl > .Zo5x2q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-xl > .Zo5x2q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-xl > .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-top: 0;
  }

  .Zo5x2q_list-group-horizontal-xl > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Zo5x2q_list-group-horizontal-xl > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .Zo5x2q_list-group-horizontal-xxl > .Zo5x2q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-xxl > .Zo5x2q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .Zo5x2q_list-group-horizontal-xxl > .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-top: 0;
  }

  .Zo5x2q_list-group-horizontal-xxl > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .Zo5x2q_list-group-horizontal-xxl > .Zo5x2q_list-group-item + .Zo5x2q_list-group-item.Zo5x2q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.Zo5x2q_list-group-flush {
  border-radius: 0;
}

.Zo5x2q_list-group-flush > .Zo5x2q_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.Zo5x2q_list-group-flush > .Zo5x2q_list-group-item:last-child {
  border-bottom-width: 0;
}

.Zo5x2q_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.Zo5x2q_list-group-item-primary.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-primary.Zo5x2q_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.Zo5x2q_list-group-item-primary.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.Zo5x2q_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.Zo5x2q_list-group-item-secondary.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-secondary.Zo5x2q_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.Zo5x2q_list-group-item-secondary.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.Zo5x2q_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.Zo5x2q_list-group-item-success.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-success.Zo5x2q_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.Zo5x2q_list-group-item-success.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.Zo5x2q_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.Zo5x2q_list-group-item-info.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-info.Zo5x2q_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.Zo5x2q_list-group-item-info.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.Zo5x2q_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.Zo5x2q_list-group-item-warning.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-warning.Zo5x2q_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.Zo5x2q_list-group-item-warning.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.Zo5x2q_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.Zo5x2q_list-group-item-danger.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-danger.Zo5x2q_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.Zo5x2q_list-group-item-danger.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.Zo5x2q_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.Zo5x2q_list-group-item-light.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-light.Zo5x2q_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.Zo5x2q_list-group-item-light.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.Zo5x2q_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.Zo5x2q_list-group-item-dark.Zo5x2q_list-group-item-action:hover, .Zo5x2q_list-group-item-dark.Zo5x2q_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.Zo5x2q_list-group-item-dark.Zo5x2q_list-group-item-action.Zo5x2q_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.Zo5x2q_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.Zo5x2q_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.Zo5x2q_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.Zo5x2q_btn-close:disabled, .Zo5x2q_btn-close.Zo5x2q_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.Zo5x2q_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.Zo5x2q_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.Zo5x2q_toast.Zo5x2q_showing {
  opacity: 0;
}

.Zo5x2q_toast:not(.Zo5x2q_show) {
  display: none;
}

.Zo5x2q_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.Zo5x2q_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.Zo5x2q_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.Zo5x2q_toast-header .Zo5x2q_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.Zo5x2q_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.Zo5x2q_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.Zo5x2q_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.Zo5x2q_modal.Zo5x2q_fade .Zo5x2q_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_modal.Zo5x2q_fade .Zo5x2q_modal-dialog {
    transition: none;
  }
}

.Zo5x2q_modal.Zo5x2q_show .Zo5x2q_modal-dialog {
  transform: none;
}

.Zo5x2q_modal.Zo5x2q_modal-static .Zo5x2q_modal-dialog {
  transform: scale(1.02);
}

.Zo5x2q_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.Zo5x2q_modal-dialog-scrollable .Zo5x2q_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.Zo5x2q_modal-dialog-scrollable .Zo5x2q_modal-body {
  overflow-y: auto;
}

.Zo5x2q_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.Zo5x2q_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Zo5x2q_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.Zo5x2q_modal-backdrop.Zo5x2q_fade {
  opacity: 0;
}

.Zo5x2q_modal-backdrop.Zo5x2q_show {
  opacity: var(--bs-backdrop-opacity);
}

.Zo5x2q_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Zo5x2q_modal-header .Zo5x2q_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.Zo5x2q_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.Zo5x2q_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.Zo5x2q_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Zo5x2q_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .Zo5x2q_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .Zo5x2q_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .Zo5x2q_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_modal-lg, .Zo5x2q_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.Zo5x2q_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.Zo5x2q_modal-fullscreen .Zo5x2q_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.Zo5x2q_modal-fullscreen .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen .Zo5x2q_modal-footer {
  border-radius: 0;
}

.Zo5x2q_modal-fullscreen .Zo5x2q_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .Zo5x2q_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Zo5x2q_modal-fullscreen-sm-down .Zo5x2q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-sm-down .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen-sm-down .Zo5x2q_modal-footer {
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-sm-down .Zo5x2q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .Zo5x2q_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Zo5x2q_modal-fullscreen-md-down .Zo5x2q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-md-down .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen-md-down .Zo5x2q_modal-footer {
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-md-down .Zo5x2q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .Zo5x2q_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Zo5x2q_modal-fullscreen-lg-down .Zo5x2q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-lg-down .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen-lg-down .Zo5x2q_modal-footer {
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-lg-down .Zo5x2q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .Zo5x2q_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Zo5x2q_modal-fullscreen-xl-down .Zo5x2q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-xl-down .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen-xl-down .Zo5x2q_modal-footer {
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-xl-down .Zo5x2q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .Zo5x2q_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Zo5x2q_modal-fullscreen-xxl-down .Zo5x2q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-xxl-down .Zo5x2q_modal-header, .Zo5x2q_modal-fullscreen-xxl-down .Zo5x2q_modal-footer {
    border-radius: 0;
  }

  .Zo5x2q_modal-fullscreen-xxl-down .Zo5x2q_modal-body {
    overflow-y: auto;
  }
}

.Zo5x2q_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.Zo5x2q_tooltip.Zo5x2q_show {
  opacity: var(--bs-tooltip-opacity);
}

.Zo5x2q_tooltip .Zo5x2q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.Zo5x2q_tooltip .Zo5x2q_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.Zo5x2q_bs-tooltip-top .Zo5x2q_tooltip-arrow, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="top"] .Zo5x2q_tooltip-arrow {
  bottom: 0;
}

.Zo5x2q_bs-tooltip-top .Zo5x2q_tooltip-arrow:before, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="top"] .Zo5x2q_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.Zo5x2q_bs-tooltip-end .Zo5x2q_tooltip-arrow, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="right"] .Zo5x2q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.Zo5x2q_bs-tooltip-end .Zo5x2q_tooltip-arrow:before, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="right"] .Zo5x2q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.Zo5x2q_bs-tooltip-bottom .Zo5x2q_tooltip-arrow, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="bottom"] .Zo5x2q_tooltip-arrow {
  top: 0;
}

.Zo5x2q_bs-tooltip-bottom .Zo5x2q_tooltip-arrow:before, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="bottom"] .Zo5x2q_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.Zo5x2q_bs-tooltip-start .Zo5x2q_tooltip-arrow, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="left"] .Zo5x2q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.Zo5x2q_bs-tooltip-start .Zo5x2q_tooltip-arrow:before, .Zo5x2q_bs-tooltip-auto[data-popper-placement^="left"] .Zo5x2q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.Zo5x2q_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.Zo5x2q_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.Zo5x2q_popover .Zo5x2q_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.Zo5x2q_popover .Zo5x2q_popover-arrow:before, .Zo5x2q_popover .Zo5x2q_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.Zo5x2q_bs-popover-top > .Zo5x2q_popover-arrow, .Zo5x2q_bs-popover-auto[data-popper-placement^="top"] > .Zo5x2q_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Zo5x2q_bs-popover-top > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="top"] > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-top > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="top"] > .Zo5x2q_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Zo5x2q_bs-popover-top > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="top"] > .Zo5x2q_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.Zo5x2q_bs-popover-top > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="top"] > .Zo5x2q_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.Zo5x2q_bs-popover-end > .Zo5x2q_popover-arrow, .Zo5x2q_bs-popover-auto[data-popper-placement^="right"] > .Zo5x2q_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Zo5x2q_bs-popover-end > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="right"] > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-end > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="right"] > .Zo5x2q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.Zo5x2q_bs-popover-end > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="right"] > .Zo5x2q_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.Zo5x2q_bs-popover-end > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="right"] > .Zo5x2q_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.Zo5x2q_bs-popover-bottom > .Zo5x2q_popover-arrow, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] > .Zo5x2q_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.Zo5x2q_bs-popover-bottom > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-bottom > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] > .Zo5x2q_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Zo5x2q_bs-popover-bottom > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] > .Zo5x2q_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.Zo5x2q_bs-popover-bottom > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] > .Zo5x2q_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.Zo5x2q_bs-popover-bottom .Zo5x2q_popover-header:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="bottom"] .Zo5x2q_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.Zo5x2q_bs-popover-start > .Zo5x2q_popover-arrow, .Zo5x2q_bs-popover-auto[data-popper-placement^="left"] > .Zo5x2q_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.Zo5x2q_bs-popover-start > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="left"] > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-start > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="left"] > .Zo5x2q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.Zo5x2q_bs-popover-start > .Zo5x2q_popover-arrow:before, .Zo5x2q_bs-popover-auto[data-popper-placement^="left"] > .Zo5x2q_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.Zo5x2q_bs-popover-start > .Zo5x2q_popover-arrow:after, .Zo5x2q_bs-popover-auto[data-popper-placement^="left"] > .Zo5x2q_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.Zo5x2q_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.Zo5x2q_popover-header:empty {
  display: none;
}

.Zo5x2q_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.Zo5x2q_carousel {
  position: relative;
}

.Zo5x2q_carousel.Zo5x2q_pointer-event {
  touch-action: pan-y;
}

.Zo5x2q_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Zo5x2q_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.Zo5x2q_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_carousel-item {
    transition: none;
  }
}

.Zo5x2q_carousel-item.Zo5x2q_active, .Zo5x2q_carousel-item-next, .Zo5x2q_carousel-item-prev {
  display: block;
}

.Zo5x2q_carousel-item-next:not(.Zo5x2q_carousel-item-start), .Zo5x2q_active.Zo5x2q_carousel-item-end {
  transform: translateX(100%);
}

.Zo5x2q_carousel-item-prev:not(.Zo5x2q_carousel-item-end), .Zo5x2q_active.Zo5x2q_carousel-item-start {
  transform: translateX(-100%);
}

.Zo5x2q_carousel-fade .Zo5x2q_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.Zo5x2q_carousel-fade .Zo5x2q_carousel-item.Zo5x2q_active, .Zo5x2q_carousel-fade .Zo5x2q_carousel-item-next.Zo5x2q_carousel-item-start, .Zo5x2q_carousel-fade .Zo5x2q_carousel-item-prev.Zo5x2q_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.Zo5x2q_carousel-fade .Zo5x2q_active.Zo5x2q_carousel-item-start, .Zo5x2q_carousel-fade .Zo5x2q_active.Zo5x2q_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_carousel-fade .Zo5x2q_active.Zo5x2q_carousel-item-start, .Zo5x2q_carousel-fade .Zo5x2q_active.Zo5x2q_carousel-item-end {
    transition: none;
  }
}

.Zo5x2q_carousel-control-prev, .Zo5x2q_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_carousel-control-prev, .Zo5x2q_carousel-control-next {
    transition: none;
  }
}

.Zo5x2q_carousel-control-prev:hover, .Zo5x2q_carousel-control-prev:focus, .Zo5x2q_carousel-control-next:hover, .Zo5x2q_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.Zo5x2q_carousel-control-prev {
  left: 0;
}

.Zo5x2q_carousel-control-next {
  right: 0;
}

.Zo5x2q_carousel-control-prev-icon, .Zo5x2q_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Zo5x2q_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.Zo5x2q_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.Zo5x2q_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Zo5x2q_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.Zo5x2q_carousel-indicators .Zo5x2q_active {
  opacity: 1;
}

.Zo5x2q_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.Zo5x2q_carousel-dark .Zo5x2q_carousel-control-prev-icon, .Zo5x2q_carousel-dark .Zo5x2q_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.Zo5x2q_carousel-dark .Zo5x2q_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.Zo5x2q_carousel-dark .Zo5x2q_carousel-caption {
  color: #000;
}

.Zo5x2q_spinner-grow, .Zo5x2q_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes Zo5x2q_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.Zo5x2q_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.Zo5x2q_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes Zo5x2q_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.Zo5x2q_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.Zo5x2q_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_spinner-border, .Zo5x2q_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.Zo5x2q_offcanvas, .Zo5x2q_offcanvas-xxl, .Zo5x2q_offcanvas-xl, .Zo5x2q_offcanvas-lg, .Zo5x2q_offcanvas-md, .Zo5x2q_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .Zo5x2q_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .Zo5x2q_offcanvas-sm.Zo5x2q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Zo5x2q_offcanvas-sm.Zo5x2q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Zo5x2q_offcanvas-sm.Zo5x2q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Zo5x2q_offcanvas-sm.Zo5x2q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Zo5x2q_offcanvas-sm.Zo5x2q_showing, .Zo5x2q_offcanvas-sm.Zo5x2q_show:not(.Zo5x2q_hiding) {
    transform: none;
  }

  .Zo5x2q_offcanvas-sm.Zo5x2q_showing, .Zo5x2q_offcanvas-sm.Zo5x2q_hiding, .Zo5x2q_offcanvas-sm.Zo5x2q_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .Zo5x2q_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Zo5x2q_offcanvas-sm .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_offcanvas-sm .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .Zo5x2q_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .Zo5x2q_offcanvas-md.Zo5x2q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Zo5x2q_offcanvas-md.Zo5x2q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Zo5x2q_offcanvas-md.Zo5x2q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Zo5x2q_offcanvas-md.Zo5x2q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Zo5x2q_offcanvas-md.Zo5x2q_showing, .Zo5x2q_offcanvas-md.Zo5x2q_show:not(.Zo5x2q_hiding) {
    transform: none;
  }

  .Zo5x2q_offcanvas-md.Zo5x2q_showing, .Zo5x2q_offcanvas-md.Zo5x2q_hiding, .Zo5x2q_offcanvas-md.Zo5x2q_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Zo5x2q_offcanvas-md .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_offcanvas-md .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .Zo5x2q_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .Zo5x2q_offcanvas-lg.Zo5x2q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Zo5x2q_offcanvas-lg.Zo5x2q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Zo5x2q_offcanvas-lg.Zo5x2q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Zo5x2q_offcanvas-lg.Zo5x2q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Zo5x2q_offcanvas-lg.Zo5x2q_showing, .Zo5x2q_offcanvas-lg.Zo5x2q_show:not(.Zo5x2q_hiding) {
    transform: none;
  }

  .Zo5x2q_offcanvas-lg.Zo5x2q_showing, .Zo5x2q_offcanvas-lg.Zo5x2q_hiding, .Zo5x2q_offcanvas-lg.Zo5x2q_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Zo5x2q_offcanvas-lg .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_offcanvas-lg .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .Zo5x2q_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .Zo5x2q_offcanvas-xl.Zo5x2q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Zo5x2q_offcanvas-xl.Zo5x2q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Zo5x2q_offcanvas-xl.Zo5x2q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Zo5x2q_offcanvas-xl.Zo5x2q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Zo5x2q_offcanvas-xl.Zo5x2q_showing, .Zo5x2q_offcanvas-xl.Zo5x2q_show:not(.Zo5x2q_hiding) {
    transform: none;
  }

  .Zo5x2q_offcanvas-xl.Zo5x2q_showing, .Zo5x2q_offcanvas-xl.Zo5x2q_hiding, .Zo5x2q_offcanvas-xl.Zo5x2q_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Zo5x2q_offcanvas-xl .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_offcanvas-xl .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .Zo5x2q_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .Zo5x2q_offcanvas-xxl.Zo5x2q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .Zo5x2q_offcanvas-xxl.Zo5x2q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .Zo5x2q_offcanvas-xxl.Zo5x2q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .Zo5x2q_offcanvas-xxl.Zo5x2q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .Zo5x2q_offcanvas-xxl.Zo5x2q_showing, .Zo5x2q_offcanvas-xxl.Zo5x2q_show:not(.Zo5x2q_hiding) {
    transform: none;
  }

  .Zo5x2q_offcanvas-xxl.Zo5x2q_showing, .Zo5x2q_offcanvas-xxl.Zo5x2q_hiding, .Zo5x2q_offcanvas-xxl.Zo5x2q_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .Zo5x2q_offcanvas-xxl .Zo5x2q_offcanvas-header {
    display: none;
  }

  .Zo5x2q_offcanvas-xxl .Zo5x2q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.Zo5x2q_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .Zo5x2q_offcanvas {
    transition: none;
  }
}

.Zo5x2q_offcanvas.Zo5x2q_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.Zo5x2q_offcanvas.Zo5x2q_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.Zo5x2q_offcanvas.Zo5x2q_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.Zo5x2q_offcanvas.Zo5x2q_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.Zo5x2q_offcanvas.Zo5x2q_showing, .Zo5x2q_offcanvas.Zo5x2q_show:not(.Zo5x2q_hiding) {
  transform: none;
}

.Zo5x2q_offcanvas.Zo5x2q_showing, .Zo5x2q_offcanvas.Zo5x2q_hiding, .Zo5x2q_offcanvas.Zo5x2q_show {
  visibility: visible;
}

.Zo5x2q_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.Zo5x2q_offcanvas-backdrop.Zo5x2q_fade {
  opacity: 0;
}

.Zo5x2q_offcanvas-backdrop.Zo5x2q_show {
  opacity: .5;
}

.Zo5x2q_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Zo5x2q_offcanvas-header .Zo5x2q_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.Zo5x2q_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.Zo5x2q_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.Zo5x2q_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.Zo5x2q_placeholder.Zo5x2q_btn:before {
  content: "";
  display: inline-block;
}

.Zo5x2q_placeholder-xs {
  min-height: .6em;
}

.Zo5x2q_placeholder-sm {
  min-height: .8em;
}

.Zo5x2q_placeholder-lg {
  min-height: 1.2em;
}

.Zo5x2q_placeholder-glow .Zo5x2q_placeholder {
  animation: 2s ease-in-out infinite Zo5x2q_placeholder-glow;
}

@keyframes Zo5x2q_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.Zo5x2q_placeholder-wave {
  animation: 2s linear infinite Zo5x2q_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes Zo5x2q_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.Zo5x2q_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.Zo5x2q_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.Zo5x2q_link-primary {
  color: #4c469c !important;
}

.Zo5x2q_link-primary:hover, .Zo5x2q_link-primary:focus {
  color: #3d387d !important;
}

.Zo5x2q_link-secondary {
  color: #6c757d !important;
}

.Zo5x2q_link-secondary:hover, .Zo5x2q_link-secondary:focus {
  color: #565e64 !important;
}

.Zo5x2q_link-success {
  color: #198754 !important;
}

.Zo5x2q_link-success:hover, .Zo5x2q_link-success:focus {
  color: #146c43 !important;
}

.Zo5x2q_link-info {
  color: #0dcaf0 !important;
}

.Zo5x2q_link-info:hover, .Zo5x2q_link-info:focus {
  color: #3dd5f3 !important;
}

.Zo5x2q_link-warning {
  color: #ffc107 !important;
}

.Zo5x2q_link-warning:hover, .Zo5x2q_link-warning:focus {
  color: #ffcd39 !important;
}

.Zo5x2q_link-danger {
  color: #dc3545 !important;
}

.Zo5x2q_link-danger:hover, .Zo5x2q_link-danger:focus {
  color: #b02a37 !important;
}

.Zo5x2q_link-light {
  color: #f8f9fa !important;
}

.Zo5x2q_link-light:hover, .Zo5x2q_link-light:focus {
  color: #f9fafb !important;
}

.Zo5x2q_link-dark {
  color: #212529 !important;
}

.Zo5x2q_link-dark:hover, .Zo5x2q_link-dark:focus {
  color: #1a1e21 !important;
}

.Zo5x2q_ratio {
  width: 100%;
  position: relative;
}

.Zo5x2q_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.Zo5x2q_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Zo5x2q_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.Zo5x2q_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.Zo5x2q_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.Zo5x2q_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.Zo5x2q_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Zo5x2q_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.Zo5x2q_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.Zo5x2q_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .Zo5x2q_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Zo5x2q_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Zo5x2q_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Zo5x2q_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Zo5x2q_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .Zo5x2q_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.Zo5x2q_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.Zo5x2q_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.Zo5x2q_visually-hidden, .Zo5x2q_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Zo5x2q_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.Zo5x2q_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Zo5x2q_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.Zo5x2q_align-baseline {
  vertical-align: baseline !important;
}

.Zo5x2q_align-top {
  vertical-align: top !important;
}

.Zo5x2q_align-middle {
  vertical-align: middle !important;
}

.Zo5x2q_align-bottom {
  vertical-align: bottom !important;
}

.Zo5x2q_align-text-bottom {
  vertical-align: text-bottom !important;
}

.Zo5x2q_align-text-top {
  vertical-align: text-top !important;
}

.Zo5x2q_float-start {
  float: left !important;
}

.Zo5x2q_float-end {
  float: right !important;
}

.Zo5x2q_float-none {
  float: none !important;
}

.Zo5x2q_opacity-0 {
  opacity: 0 !important;
}

.Zo5x2q_opacity-25 {
  opacity: .25 !important;
}

.Zo5x2q_opacity-50 {
  opacity: .5 !important;
}

.Zo5x2q_opacity-75 {
  opacity: .75 !important;
}

.Zo5x2q_opacity-100 {
  opacity: 1 !important;
}

.Zo5x2q_overflow-auto {
  overflow: auto !important;
}

.Zo5x2q_overflow-hidden {
  overflow: hidden !important;
}

.Zo5x2q_overflow-visible {
  overflow: visible !important;
}

.Zo5x2q_overflow-scroll {
  overflow: scroll !important;
}

.Zo5x2q_d-inline {
  display: inline !important;
}

.Zo5x2q_d-inline-block {
  display: inline-block !important;
}

.Zo5x2q_d-block {
  display: block !important;
}

.Zo5x2q_d-grid {
  display: grid !important;
}

.Zo5x2q_d-table {
  display: table !important;
}

.Zo5x2q_d-table-row {
  display: table-row !important;
}

.Zo5x2q_d-table-cell {
  display: table-cell !important;
}

.Zo5x2q_d-flex {
  display: flex !important;
}

.Zo5x2q_d-inline-flex {
  display: inline-flex !important;
}

.Zo5x2q_d-none {
  display: none !important;
}

.Zo5x2q_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.Zo5x2q_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.Zo5x2q_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.Zo5x2q_shadow-none {
  box-shadow: none !important;
}

.Zo5x2q_position-static {
  position: static !important;
}

.Zo5x2q_position-relative {
  position: relative !important;
}

.Zo5x2q_position-absolute {
  position: absolute !important;
}

.Zo5x2q_position-fixed {
  position: fixed !important;
}

.Zo5x2q_position-sticky {
  position: sticky !important;
}

.Zo5x2q_top-0 {
  top: 0 !important;
}

.Zo5x2q_top-50 {
  top: 50% !important;
}

.Zo5x2q_top-100 {
  top: 100% !important;
}

.Zo5x2q_bottom-0 {
  bottom: 0 !important;
}

.Zo5x2q_bottom-50 {
  bottom: 50% !important;
}

.Zo5x2q_bottom-100 {
  bottom: 100% !important;
}

.Zo5x2q_start-0 {
  left: 0 !important;
}

.Zo5x2q_start-50 {
  left: 50% !important;
}

.Zo5x2q_start-100 {
  left: 100% !important;
}

.Zo5x2q_end-0 {
  right: 0 !important;
}

.Zo5x2q_end-50 {
  right: 50% !important;
}

.Zo5x2q_end-100 {
  right: 100% !important;
}

.Zo5x2q_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.Zo5x2q_translate-middle-x {
  transform: translateX(-50%) !important;
}

.Zo5x2q_translate-middle-y {
  transform: translateY(-50%) !important;
}

.Zo5x2q_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Zo5x2q_border-0 {
  border: 0 !important;
}

.Zo5x2q_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Zo5x2q_border-top-0 {
  border-top: 0 !important;
}

.Zo5x2q_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Zo5x2q_border-end-0 {
  border-right: 0 !important;
}

.Zo5x2q_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Zo5x2q_border-bottom-0 {
  border-bottom: 0 !important;
}

.Zo5x2q_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Zo5x2q_border-start-0 {
  border-left: 0 !important;
}

.Zo5x2q_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.Zo5x2q_border-1 {
  --bs-border-width: 1px;
}

.Zo5x2q_border-2 {
  --bs-border-width: 2px;
}

.Zo5x2q_border-3 {
  --bs-border-width: 3px;
}

.Zo5x2q_border-4 {
  --bs-border-width: 4px;
}

.Zo5x2q_border-5 {
  --bs-border-width: 5px;
}

.Zo5x2q_border-opacity-10 {
  --bs-border-opacity: .1;
}

.Zo5x2q_border-opacity-25 {
  --bs-border-opacity: .25;
}

.Zo5x2q_border-opacity-50 {
  --bs-border-opacity: .5;
}

.Zo5x2q_border-opacity-75 {
  --bs-border-opacity: .75;
}

.Zo5x2q_border-opacity-100 {
  --bs-border-opacity: 1;
}

.Zo5x2q_w-25 {
  width: 25% !important;
}

.Zo5x2q_w-50 {
  width: 50% !important;
}

.Zo5x2q_w-75 {
  width: 75% !important;
}

.Zo5x2q_w-100 {
  width: 100% !important;
}

.Zo5x2q_w-auto {
  width: auto !important;
}

.Zo5x2q_mw-100 {
  max-width: 100% !important;
}

.Zo5x2q_vw-100 {
  width: 100vw !important;
}

.Zo5x2q_min-vw-100 {
  min-width: 100vw !important;
}

.Zo5x2q_h-25 {
  height: 25% !important;
}

.Zo5x2q_h-50 {
  height: 50% !important;
}

.Zo5x2q_h-75 {
  height: 75% !important;
}

.Zo5x2q_h-100 {
  height: 100% !important;
}

.Zo5x2q_h-auto {
  height: auto !important;
}

.Zo5x2q_mh-100 {
  max-height: 100% !important;
}

.Zo5x2q_vh-100 {
  height: 100vh !important;
}

.Zo5x2q_min-vh-100 {
  min-height: 100vh !important;
}

.Zo5x2q_flex-fill {
  flex: auto !important;
}

.Zo5x2q_flex-row {
  flex-direction: row !important;
}

.Zo5x2q_flex-column {
  flex-direction: column !important;
}

.Zo5x2q_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.Zo5x2q_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.Zo5x2q_flex-grow-0 {
  flex-grow: 0 !important;
}

.Zo5x2q_flex-grow-1 {
  flex-grow: 1 !important;
}

.Zo5x2q_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.Zo5x2q_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.Zo5x2q_flex-wrap {
  flex-wrap: wrap !important;
}

.Zo5x2q_flex-nowrap {
  flex-wrap: nowrap !important;
}

.Zo5x2q_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.Zo5x2q_justify-content-start {
  justify-content: flex-start !important;
}

.Zo5x2q_justify-content-end {
  justify-content: flex-end !important;
}

.Zo5x2q_justify-content-center {
  justify-content: center !important;
}

.Zo5x2q_justify-content-between {
  justify-content: space-between !important;
}

.Zo5x2q_justify-content-around {
  justify-content: space-around !important;
}

.Zo5x2q_justify-content-evenly {
  justify-content: space-evenly !important;
}

.Zo5x2q_align-items-start {
  align-items: flex-start !important;
}

.Zo5x2q_align-items-end {
  align-items: flex-end !important;
}

.Zo5x2q_align-items-center {
  align-items: center !important;
}

.Zo5x2q_align-items-baseline {
  align-items: baseline !important;
}

.Zo5x2q_align-items-stretch {
  align-items: stretch !important;
}

.Zo5x2q_align-content-start {
  align-content: flex-start !important;
}

.Zo5x2q_align-content-end {
  align-content: flex-end !important;
}

.Zo5x2q_align-content-center {
  align-content: center !important;
}

.Zo5x2q_align-content-between {
  align-content: space-between !important;
}

.Zo5x2q_align-content-around {
  align-content: space-around !important;
}

.Zo5x2q_align-content-stretch {
  align-content: stretch !important;
}

.Zo5x2q_align-self-auto {
  align-self: auto !important;
}

.Zo5x2q_align-self-start {
  align-self: flex-start !important;
}

.Zo5x2q_align-self-end {
  align-self: flex-end !important;
}

.Zo5x2q_align-self-center {
  align-self: center !important;
}

.Zo5x2q_align-self-baseline {
  align-self: baseline !important;
}

.Zo5x2q_align-self-stretch {
  align-self: stretch !important;
}

.Zo5x2q_order-first {
  order: -1 !important;
}

.Zo5x2q_order-0 {
  order: 0 !important;
}

.Zo5x2q_order-1 {
  order: 1 !important;
}

.Zo5x2q_order-2 {
  order: 2 !important;
}

.Zo5x2q_order-3 {
  order: 3 !important;
}

.Zo5x2q_order-4 {
  order: 4 !important;
}

.Zo5x2q_order-5 {
  order: 5 !important;
}

.Zo5x2q_order-last {
  order: 6 !important;
}

.Zo5x2q_m-0 {
  margin: 0 !important;
}

.Zo5x2q_m-1 {
  margin: .25rem !important;
}

.Zo5x2q_m-2 {
  margin: .5rem !important;
}

.Zo5x2q_m-3 {
  margin: 1rem !important;
}

.Zo5x2q_m-4 {
  margin: 1.5rem !important;
}

.Zo5x2q_m-5 {
  margin: 3rem !important;
}

.Zo5x2q_m-auto {
  margin: auto !important;
}

.Zo5x2q_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.Zo5x2q_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.Zo5x2q_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.Zo5x2q_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.Zo5x2q_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.Zo5x2q_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.Zo5x2q_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.Zo5x2q_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Zo5x2q_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.Zo5x2q_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.Zo5x2q_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.Zo5x2q_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.Zo5x2q_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.Zo5x2q_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.Zo5x2q_mt-0 {
  margin-top: 0 !important;
}

.Zo5x2q_mt-1 {
  margin-top: .25rem !important;
}

.Zo5x2q_mt-2 {
  margin-top: .5rem !important;
}

.Zo5x2q_mt-3 {
  margin-top: 1rem !important;
}

.Zo5x2q_mt-4 {
  margin-top: 1.5rem !important;
}

.Zo5x2q_mt-5 {
  margin-top: 3rem !important;
}

.Zo5x2q_mt-auto {
  margin-top: auto !important;
}

.Zo5x2q_me-0 {
  margin-right: 0 !important;
}

.Zo5x2q_me-1 {
  margin-right: .25rem !important;
}

.Zo5x2q_me-2 {
  margin-right: .5rem !important;
}

.Zo5x2q_me-3 {
  margin-right: 1rem !important;
}

.Zo5x2q_me-4 {
  margin-right: 1.5rem !important;
}

.Zo5x2q_me-5 {
  margin-right: 3rem !important;
}

.Zo5x2q_me-auto {
  margin-right: auto !important;
}

.Zo5x2q_mb-0 {
  margin-bottom: 0 !important;
}

.Zo5x2q_mb-1 {
  margin-bottom: .25rem !important;
}

.Zo5x2q_mb-2 {
  margin-bottom: .5rem !important;
}

.Zo5x2q_mb-3 {
  margin-bottom: 1rem !important;
}

.Zo5x2q_mb-4 {
  margin-bottom: 1.5rem !important;
}

.Zo5x2q_mb-5 {
  margin-bottom: 3rem !important;
}

.Zo5x2q_mb-auto {
  margin-bottom: auto !important;
}

.Zo5x2q_ms-0 {
  margin-left: 0 !important;
}

.Zo5x2q_ms-1 {
  margin-left: .25rem !important;
}

.Zo5x2q_ms-2 {
  margin-left: .5rem !important;
}

.Zo5x2q_ms-3 {
  margin-left: 1rem !important;
}

.Zo5x2q_ms-4 {
  margin-left: 1.5rem !important;
}

.Zo5x2q_ms-5 {
  margin-left: 3rem !important;
}

.Zo5x2q_ms-auto {
  margin-left: auto !important;
}

.Zo5x2q_p-0 {
  padding: 0 !important;
}

.Zo5x2q_p-1 {
  padding: .25rem !important;
}

.Zo5x2q_p-2 {
  padding: .5rem !important;
}

.Zo5x2q_p-3 {
  padding: 1rem !important;
}

.Zo5x2q_p-4 {
  padding: 1.5rem !important;
}

.Zo5x2q_p-5 {
  padding: 3rem !important;
}

.Zo5x2q_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Zo5x2q_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.Zo5x2q_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.Zo5x2q_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.Zo5x2q_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.Zo5x2q_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.Zo5x2q_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Zo5x2q_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.Zo5x2q_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.Zo5x2q_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.Zo5x2q_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.Zo5x2q_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.Zo5x2q_pt-0 {
  padding-top: 0 !important;
}

.Zo5x2q_pt-1 {
  padding-top: .25rem !important;
}

.Zo5x2q_pt-2 {
  padding-top: .5rem !important;
}

.Zo5x2q_pt-3 {
  padding-top: 1rem !important;
}

.Zo5x2q_pt-4 {
  padding-top: 1.5rem !important;
}

.Zo5x2q_pt-5 {
  padding-top: 3rem !important;
}

.Zo5x2q_pe-0 {
  padding-right: 0 !important;
}

.Zo5x2q_pe-1 {
  padding-right: .25rem !important;
}

.Zo5x2q_pe-2 {
  padding-right: .5rem !important;
}

.Zo5x2q_pe-3 {
  padding-right: 1rem !important;
}

.Zo5x2q_pe-4 {
  padding-right: 1.5rem !important;
}

.Zo5x2q_pe-5 {
  padding-right: 3rem !important;
}

.Zo5x2q_pb-0 {
  padding-bottom: 0 !important;
}

.Zo5x2q_pb-1 {
  padding-bottom: .25rem !important;
}

.Zo5x2q_pb-2 {
  padding-bottom: .5rem !important;
}

.Zo5x2q_pb-3 {
  padding-bottom: 1rem !important;
}

.Zo5x2q_pb-4 {
  padding-bottom: 1.5rem !important;
}

.Zo5x2q_pb-5 {
  padding-bottom: 3rem !important;
}

.Zo5x2q_ps-0 {
  padding-left: 0 !important;
}

.Zo5x2q_ps-1 {
  padding-left: .25rem !important;
}

.Zo5x2q_ps-2 {
  padding-left: .5rem !important;
}

.Zo5x2q_ps-3 {
  padding-left: 1rem !important;
}

.Zo5x2q_ps-4 {
  padding-left: 1.5rem !important;
}

.Zo5x2q_ps-5 {
  padding-left: 3rem !important;
}

.Zo5x2q_gap-0 {
  gap: 0 !important;
}

.Zo5x2q_gap-1 {
  gap: .25rem !important;
}

.Zo5x2q_gap-2 {
  gap: .5rem !important;
}

.Zo5x2q_gap-3 {
  gap: 1rem !important;
}

.Zo5x2q_gap-4 {
  gap: 1.5rem !important;
}

.Zo5x2q_gap-5 {
  gap: 3rem !important;
}

.Zo5x2q_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.Zo5x2q_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.Zo5x2q_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.Zo5x2q_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.Zo5x2q_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.Zo5x2q_fs-5 {
  font-size: 1.25rem !important;
}

.Zo5x2q_fs-6 {
  font-size: 1rem !important;
}

.Zo5x2q_fst-italic {
  font-style: italic !important;
}

.Zo5x2q_fst-normal {
  font-style: normal !important;
}

.Zo5x2q_fw-light {
  font-weight: 300 !important;
}

.Zo5x2q_fw-lighter {
  font-weight: lighter !important;
}

.Zo5x2q_fw-normal {
  font-weight: 400 !important;
}

.Zo5x2q_fw-bold {
  font-weight: 700 !important;
}

.Zo5x2q_fw-semibold {
  font-weight: 600 !important;
}

.Zo5x2q_fw-bolder {
  font-weight: bolder !important;
}

.Zo5x2q_lh-1 {
  line-height: 1 !important;
}

.Zo5x2q_lh-sm {
  line-height: 1.25 !important;
}

.Zo5x2q_lh-base {
  line-height: 1.5 !important;
}

.Zo5x2q_lh-lg {
  line-height: 2 !important;
}

.Zo5x2q_text-start {
  text-align: left !important;
}

.Zo5x2q_text-end {
  text-align: right !important;
}

.Zo5x2q_text-center {
  text-align: center !important;
}

.Zo5x2q_text-decoration-none {
  text-decoration: none !important;
}

.Zo5x2q_text-decoration-underline {
  text-decoration: underline !important;
}

.Zo5x2q_text-decoration-line-through {
  text-decoration: line-through !important;
}

.Zo5x2q_text-lowercase {
  text-transform: lowercase !important;
}

.Zo5x2q_text-uppercase {
  text-transform: uppercase !important;
}

.Zo5x2q_text-capitalize {
  text-transform: capitalize !important;
}

.Zo5x2q_text-wrap {
  white-space: normal !important;
}

.Zo5x2q_text-nowrap {
  white-space: nowrap !important;
}

.Zo5x2q_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.Zo5x2q_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.Zo5x2q_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.Zo5x2q_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.Zo5x2q_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.Zo5x2q_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.Zo5x2q_text-opacity-25 {
  --bs-text-opacity: .25;
}

.Zo5x2q_text-opacity-50 {
  --bs-text-opacity: .5;
}

.Zo5x2q_text-opacity-75 {
  --bs-text-opacity: .75;
}

.Zo5x2q_text-opacity-100 {
  --bs-text-opacity: 1;
}

.Zo5x2q_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.Zo5x2q_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.Zo5x2q_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.Zo5x2q_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.Zo5x2q_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.Zo5x2q_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.Zo5x2q_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.Zo5x2q_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.Zo5x2q_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.Zo5x2q_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.Zo5x2q_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.Zo5x2q_pe-none {
  pointer-events: none !important;
}

.Zo5x2q_pe-auto {
  pointer-events: auto !important;
}

.Zo5x2q_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_rounded-0 {
  border-radius: 0 !important;
}

.Zo5x2q_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.Zo5x2q_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.Zo5x2q_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.Zo5x2q_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.Zo5x2q_rounded-circle {
  border-radius: 50% !important;
}

.Zo5x2q_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.Zo5x2q_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.Zo5x2q_visible {
  visibility: visible !important;
}

.Zo5x2q_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .Zo5x2q_float-sm-start {
    float: left !important;
  }

  .Zo5x2q_float-sm-end {
    float: right !important;
  }

  .Zo5x2q_float-sm-none {
    float: none !important;
  }

  .Zo5x2q_d-sm-inline {
    display: inline !important;
  }

  .Zo5x2q_d-sm-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-sm-block {
    display: block !important;
  }

  .Zo5x2q_d-sm-grid {
    display: grid !important;
  }

  .Zo5x2q_d-sm-table {
    display: table !important;
  }

  .Zo5x2q_d-sm-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-sm-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-sm-flex {
    display: flex !important;
  }

  .Zo5x2q_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-sm-none {
    display: none !important;
  }

  .Zo5x2q_flex-sm-fill {
    flex: auto !important;
  }

  .Zo5x2q_flex-sm-row {
    flex-direction: row !important;
  }

  .Zo5x2q_flex-sm-column {
    flex-direction: column !important;
  }

  .Zo5x2q_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Zo5x2q_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Zo5x2q_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .Zo5x2q_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .Zo5x2q_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Zo5x2q_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Zo5x2q_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .Zo5x2q_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .Zo5x2q_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Zo5x2q_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .Zo5x2q_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .Zo5x2q_justify-content-sm-center {
    justify-content: center !important;
  }

  .Zo5x2q_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .Zo5x2q_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .Zo5x2q_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .Zo5x2q_align-items-sm-start {
    align-items: flex-start !important;
  }

  .Zo5x2q_align-items-sm-end {
    align-items: flex-end !important;
  }

  .Zo5x2q_align-items-sm-center {
    align-items: center !important;
  }

  .Zo5x2q_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .Zo5x2q_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .Zo5x2q_align-content-sm-start {
    align-content: flex-start !important;
  }

  .Zo5x2q_align-content-sm-end {
    align-content: flex-end !important;
  }

  .Zo5x2q_align-content-sm-center {
    align-content: center !important;
  }

  .Zo5x2q_align-content-sm-between {
    align-content: space-between !important;
  }

  .Zo5x2q_align-content-sm-around {
    align-content: space-around !important;
  }

  .Zo5x2q_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .Zo5x2q_align-self-sm-auto {
    align-self: auto !important;
  }

  .Zo5x2q_align-self-sm-start {
    align-self: flex-start !important;
  }

  .Zo5x2q_align-self-sm-end {
    align-self: flex-end !important;
  }

  .Zo5x2q_align-self-sm-center {
    align-self: center !important;
  }

  .Zo5x2q_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .Zo5x2q_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .Zo5x2q_order-sm-first {
    order: -1 !important;
  }

  .Zo5x2q_order-sm-0 {
    order: 0 !important;
  }

  .Zo5x2q_order-sm-1 {
    order: 1 !important;
  }

  .Zo5x2q_order-sm-2 {
    order: 2 !important;
  }

  .Zo5x2q_order-sm-3 {
    order: 3 !important;
  }

  .Zo5x2q_order-sm-4 {
    order: 4 !important;
  }

  .Zo5x2q_order-sm-5 {
    order: 5 !important;
  }

  .Zo5x2q_order-sm-last {
    order: 6 !important;
  }

  .Zo5x2q_m-sm-0 {
    margin: 0 !important;
  }

  .Zo5x2q_m-sm-1 {
    margin: .25rem !important;
  }

  .Zo5x2q_m-sm-2 {
    margin: .5rem !important;
  }

  .Zo5x2q_m-sm-3 {
    margin: 1rem !important;
  }

  .Zo5x2q_m-sm-4 {
    margin: 1.5rem !important;
  }

  .Zo5x2q_m-sm-5 {
    margin: 3rem !important;
  }

  .Zo5x2q_m-sm-auto {
    margin: auto !important;
  }

  .Zo5x2q_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Zo5x2q_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Zo5x2q_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Zo5x2q_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Zo5x2q_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Zo5x2q_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Zo5x2q_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Zo5x2q_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Zo5x2q_mt-sm-0 {
    margin-top: 0 !important;
  }

  .Zo5x2q_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .Zo5x2q_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .Zo5x2q_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .Zo5x2q_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .Zo5x2q_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .Zo5x2q_mt-sm-auto {
    margin-top: auto !important;
  }

  .Zo5x2q_me-sm-0 {
    margin-right: 0 !important;
  }

  .Zo5x2q_me-sm-1 {
    margin-right: .25rem !important;
  }

  .Zo5x2q_me-sm-2 {
    margin-right: .5rem !important;
  }

  .Zo5x2q_me-sm-3 {
    margin-right: 1rem !important;
  }

  .Zo5x2q_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_me-sm-5 {
    margin-right: 3rem !important;
  }

  .Zo5x2q_me-sm-auto {
    margin-right: auto !important;
  }

  .Zo5x2q_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .Zo5x2q_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .Zo5x2q_ms-sm-0 {
    margin-left: 0 !important;
  }

  .Zo5x2q_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .Zo5x2q_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .Zo5x2q_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .Zo5x2q_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .Zo5x2q_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .Zo5x2q_ms-sm-auto {
    margin-left: auto !important;
  }

  .Zo5x2q_p-sm-0 {
    padding: 0 !important;
  }

  .Zo5x2q_p-sm-1 {
    padding: .25rem !important;
  }

  .Zo5x2q_p-sm-2 {
    padding: .5rem !important;
  }

  .Zo5x2q_p-sm-3 {
    padding: 1rem !important;
  }

  .Zo5x2q_p-sm-4 {
    padding: 1.5rem !important;
  }

  .Zo5x2q_p-sm-5 {
    padding: 3rem !important;
  }

  .Zo5x2q_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Zo5x2q_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Zo5x2q_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Zo5x2q_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Zo5x2q_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Zo5x2q_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Zo5x2q_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_pt-sm-0 {
    padding-top: 0 !important;
  }

  .Zo5x2q_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .Zo5x2q_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .Zo5x2q_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .Zo5x2q_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .Zo5x2q_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .Zo5x2q_pe-sm-0 {
    padding-right: 0 !important;
  }

  .Zo5x2q_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .Zo5x2q_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .Zo5x2q_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .Zo5x2q_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .Zo5x2q_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .Zo5x2q_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_ps-sm-0 {
    padding-left: 0 !important;
  }

  .Zo5x2q_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .Zo5x2q_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .Zo5x2q_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .Zo5x2q_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .Zo5x2q_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .Zo5x2q_gap-sm-0 {
    gap: 0 !important;
  }

  .Zo5x2q_gap-sm-1 {
    gap: .25rem !important;
  }

  .Zo5x2q_gap-sm-2 {
    gap: .5rem !important;
  }

  .Zo5x2q_gap-sm-3 {
    gap: 1rem !important;
  }

  .Zo5x2q_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .Zo5x2q_gap-sm-5 {
    gap: 3rem !important;
  }

  .Zo5x2q_text-sm-start {
    text-align: left !important;
  }

  .Zo5x2q_text-sm-end {
    text-align: right !important;
  }

  .Zo5x2q_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .Zo5x2q_float-md-start {
    float: left !important;
  }

  .Zo5x2q_float-md-end {
    float: right !important;
  }

  .Zo5x2q_float-md-none {
    float: none !important;
  }

  .Zo5x2q_d-md-inline {
    display: inline !important;
  }

  .Zo5x2q_d-md-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-md-block {
    display: block !important;
  }

  .Zo5x2q_d-md-grid {
    display: grid !important;
  }

  .Zo5x2q_d-md-table {
    display: table !important;
  }

  .Zo5x2q_d-md-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-md-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-md-flex {
    display: flex !important;
  }

  .Zo5x2q_d-md-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-md-none {
    display: none !important;
  }

  .Zo5x2q_flex-md-fill {
    flex: auto !important;
  }

  .Zo5x2q_flex-md-row {
    flex-direction: row !important;
  }

  .Zo5x2q_flex-md-column {
    flex-direction: column !important;
  }

  .Zo5x2q_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Zo5x2q_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Zo5x2q_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .Zo5x2q_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .Zo5x2q_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Zo5x2q_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Zo5x2q_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .Zo5x2q_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .Zo5x2q_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Zo5x2q_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .Zo5x2q_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .Zo5x2q_justify-content-md-center {
    justify-content: center !important;
  }

  .Zo5x2q_justify-content-md-between {
    justify-content: space-between !important;
  }

  .Zo5x2q_justify-content-md-around {
    justify-content: space-around !important;
  }

  .Zo5x2q_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .Zo5x2q_align-items-md-start {
    align-items: flex-start !important;
  }

  .Zo5x2q_align-items-md-end {
    align-items: flex-end !important;
  }

  .Zo5x2q_align-items-md-center {
    align-items: center !important;
  }

  .Zo5x2q_align-items-md-baseline {
    align-items: baseline !important;
  }

  .Zo5x2q_align-items-md-stretch {
    align-items: stretch !important;
  }

  .Zo5x2q_align-content-md-start {
    align-content: flex-start !important;
  }

  .Zo5x2q_align-content-md-end {
    align-content: flex-end !important;
  }

  .Zo5x2q_align-content-md-center {
    align-content: center !important;
  }

  .Zo5x2q_align-content-md-between {
    align-content: space-between !important;
  }

  .Zo5x2q_align-content-md-around {
    align-content: space-around !important;
  }

  .Zo5x2q_align-content-md-stretch {
    align-content: stretch !important;
  }

  .Zo5x2q_align-self-md-auto {
    align-self: auto !important;
  }

  .Zo5x2q_align-self-md-start {
    align-self: flex-start !important;
  }

  .Zo5x2q_align-self-md-end {
    align-self: flex-end !important;
  }

  .Zo5x2q_align-self-md-center {
    align-self: center !important;
  }

  .Zo5x2q_align-self-md-baseline {
    align-self: baseline !important;
  }

  .Zo5x2q_align-self-md-stretch {
    align-self: stretch !important;
  }

  .Zo5x2q_order-md-first {
    order: -1 !important;
  }

  .Zo5x2q_order-md-0 {
    order: 0 !important;
  }

  .Zo5x2q_order-md-1 {
    order: 1 !important;
  }

  .Zo5x2q_order-md-2 {
    order: 2 !important;
  }

  .Zo5x2q_order-md-3 {
    order: 3 !important;
  }

  .Zo5x2q_order-md-4 {
    order: 4 !important;
  }

  .Zo5x2q_order-md-5 {
    order: 5 !important;
  }

  .Zo5x2q_order-md-last {
    order: 6 !important;
  }

  .Zo5x2q_m-md-0 {
    margin: 0 !important;
  }

  .Zo5x2q_m-md-1 {
    margin: .25rem !important;
  }

  .Zo5x2q_m-md-2 {
    margin: .5rem !important;
  }

  .Zo5x2q_m-md-3 {
    margin: 1rem !important;
  }

  .Zo5x2q_m-md-4 {
    margin: 1.5rem !important;
  }

  .Zo5x2q_m-md-5 {
    margin: 3rem !important;
  }

  .Zo5x2q_m-md-auto {
    margin: auto !important;
  }

  .Zo5x2q_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Zo5x2q_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Zo5x2q_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Zo5x2q_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Zo5x2q_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Zo5x2q_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Zo5x2q_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Zo5x2q_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Zo5x2q_mt-md-0 {
    margin-top: 0 !important;
  }

  .Zo5x2q_mt-md-1 {
    margin-top: .25rem !important;
  }

  .Zo5x2q_mt-md-2 {
    margin-top: .5rem !important;
  }

  .Zo5x2q_mt-md-3 {
    margin-top: 1rem !important;
  }

  .Zo5x2q_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .Zo5x2q_mt-md-5 {
    margin-top: 3rem !important;
  }

  .Zo5x2q_mt-md-auto {
    margin-top: auto !important;
  }

  .Zo5x2q_me-md-0 {
    margin-right: 0 !important;
  }

  .Zo5x2q_me-md-1 {
    margin-right: .25rem !important;
  }

  .Zo5x2q_me-md-2 {
    margin-right: .5rem !important;
  }

  .Zo5x2q_me-md-3 {
    margin-right: 1rem !important;
  }

  .Zo5x2q_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_me-md-5 {
    margin-right: 3rem !important;
  }

  .Zo5x2q_me-md-auto {
    margin-right: auto !important;
  }

  .Zo5x2q_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .Zo5x2q_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_mb-md-auto {
    margin-bottom: auto !important;
  }

  .Zo5x2q_ms-md-0 {
    margin-left: 0 !important;
  }

  .Zo5x2q_ms-md-1 {
    margin-left: .25rem !important;
  }

  .Zo5x2q_ms-md-2 {
    margin-left: .5rem !important;
  }

  .Zo5x2q_ms-md-3 {
    margin-left: 1rem !important;
  }

  .Zo5x2q_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .Zo5x2q_ms-md-5 {
    margin-left: 3rem !important;
  }

  .Zo5x2q_ms-md-auto {
    margin-left: auto !important;
  }

  .Zo5x2q_p-md-0 {
    padding: 0 !important;
  }

  .Zo5x2q_p-md-1 {
    padding: .25rem !important;
  }

  .Zo5x2q_p-md-2 {
    padding: .5rem !important;
  }

  .Zo5x2q_p-md-3 {
    padding: 1rem !important;
  }

  .Zo5x2q_p-md-4 {
    padding: 1.5rem !important;
  }

  .Zo5x2q_p-md-5 {
    padding: 3rem !important;
  }

  .Zo5x2q_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Zo5x2q_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Zo5x2q_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Zo5x2q_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Zo5x2q_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Zo5x2q_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Zo5x2q_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_pt-md-0 {
    padding-top: 0 !important;
  }

  .Zo5x2q_pt-md-1 {
    padding-top: .25rem !important;
  }

  .Zo5x2q_pt-md-2 {
    padding-top: .5rem !important;
  }

  .Zo5x2q_pt-md-3 {
    padding-top: 1rem !important;
  }

  .Zo5x2q_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .Zo5x2q_pt-md-5 {
    padding-top: 3rem !important;
  }

  .Zo5x2q_pe-md-0 {
    padding-right: 0 !important;
  }

  .Zo5x2q_pe-md-1 {
    padding-right: .25rem !important;
  }

  .Zo5x2q_pe-md-2 {
    padding-right: .5rem !important;
  }

  .Zo5x2q_pe-md-3 {
    padding-right: 1rem !important;
  }

  .Zo5x2q_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_pe-md-5 {
    padding-right: 3rem !important;
  }

  .Zo5x2q_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .Zo5x2q_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_ps-md-0 {
    padding-left: 0 !important;
  }

  .Zo5x2q_ps-md-1 {
    padding-left: .25rem !important;
  }

  .Zo5x2q_ps-md-2 {
    padding-left: .5rem !important;
  }

  .Zo5x2q_ps-md-3 {
    padding-left: 1rem !important;
  }

  .Zo5x2q_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .Zo5x2q_ps-md-5 {
    padding-left: 3rem !important;
  }

  .Zo5x2q_gap-md-0 {
    gap: 0 !important;
  }

  .Zo5x2q_gap-md-1 {
    gap: .25rem !important;
  }

  .Zo5x2q_gap-md-2 {
    gap: .5rem !important;
  }

  .Zo5x2q_gap-md-3 {
    gap: 1rem !important;
  }

  .Zo5x2q_gap-md-4 {
    gap: 1.5rem !important;
  }

  .Zo5x2q_gap-md-5 {
    gap: 3rem !important;
  }

  .Zo5x2q_text-md-start {
    text-align: left !important;
  }

  .Zo5x2q_text-md-end {
    text-align: right !important;
  }

  .Zo5x2q_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .Zo5x2q_float-lg-start {
    float: left !important;
  }

  .Zo5x2q_float-lg-end {
    float: right !important;
  }

  .Zo5x2q_float-lg-none {
    float: none !important;
  }

  .Zo5x2q_d-lg-inline {
    display: inline !important;
  }

  .Zo5x2q_d-lg-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-lg-block {
    display: block !important;
  }

  .Zo5x2q_d-lg-grid {
    display: grid !important;
  }

  .Zo5x2q_d-lg-table {
    display: table !important;
  }

  .Zo5x2q_d-lg-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-lg-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-lg-flex {
    display: flex !important;
  }

  .Zo5x2q_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-lg-none {
    display: none !important;
  }

  .Zo5x2q_flex-lg-fill {
    flex: auto !important;
  }

  .Zo5x2q_flex-lg-row {
    flex-direction: row !important;
  }

  .Zo5x2q_flex-lg-column {
    flex-direction: column !important;
  }

  .Zo5x2q_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Zo5x2q_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Zo5x2q_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .Zo5x2q_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .Zo5x2q_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Zo5x2q_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Zo5x2q_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .Zo5x2q_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .Zo5x2q_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Zo5x2q_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .Zo5x2q_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .Zo5x2q_justify-content-lg-center {
    justify-content: center !important;
  }

  .Zo5x2q_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .Zo5x2q_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .Zo5x2q_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .Zo5x2q_align-items-lg-start {
    align-items: flex-start !important;
  }

  .Zo5x2q_align-items-lg-end {
    align-items: flex-end !important;
  }

  .Zo5x2q_align-items-lg-center {
    align-items: center !important;
  }

  .Zo5x2q_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .Zo5x2q_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .Zo5x2q_align-content-lg-start {
    align-content: flex-start !important;
  }

  .Zo5x2q_align-content-lg-end {
    align-content: flex-end !important;
  }

  .Zo5x2q_align-content-lg-center {
    align-content: center !important;
  }

  .Zo5x2q_align-content-lg-between {
    align-content: space-between !important;
  }

  .Zo5x2q_align-content-lg-around {
    align-content: space-around !important;
  }

  .Zo5x2q_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .Zo5x2q_align-self-lg-auto {
    align-self: auto !important;
  }

  .Zo5x2q_align-self-lg-start {
    align-self: flex-start !important;
  }

  .Zo5x2q_align-self-lg-end {
    align-self: flex-end !important;
  }

  .Zo5x2q_align-self-lg-center {
    align-self: center !important;
  }

  .Zo5x2q_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .Zo5x2q_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .Zo5x2q_order-lg-first {
    order: -1 !important;
  }

  .Zo5x2q_order-lg-0 {
    order: 0 !important;
  }

  .Zo5x2q_order-lg-1 {
    order: 1 !important;
  }

  .Zo5x2q_order-lg-2 {
    order: 2 !important;
  }

  .Zo5x2q_order-lg-3 {
    order: 3 !important;
  }

  .Zo5x2q_order-lg-4 {
    order: 4 !important;
  }

  .Zo5x2q_order-lg-5 {
    order: 5 !important;
  }

  .Zo5x2q_order-lg-last {
    order: 6 !important;
  }

  .Zo5x2q_m-lg-0 {
    margin: 0 !important;
  }

  .Zo5x2q_m-lg-1 {
    margin: .25rem !important;
  }

  .Zo5x2q_m-lg-2 {
    margin: .5rem !important;
  }

  .Zo5x2q_m-lg-3 {
    margin: 1rem !important;
  }

  .Zo5x2q_m-lg-4 {
    margin: 1.5rem !important;
  }

  .Zo5x2q_m-lg-5 {
    margin: 3rem !important;
  }

  .Zo5x2q_m-lg-auto {
    margin: auto !important;
  }

  .Zo5x2q_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Zo5x2q_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Zo5x2q_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Zo5x2q_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Zo5x2q_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Zo5x2q_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Zo5x2q_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Zo5x2q_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Zo5x2q_mt-lg-0 {
    margin-top: 0 !important;
  }

  .Zo5x2q_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .Zo5x2q_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .Zo5x2q_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .Zo5x2q_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .Zo5x2q_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .Zo5x2q_mt-lg-auto {
    margin-top: auto !important;
  }

  .Zo5x2q_me-lg-0 {
    margin-right: 0 !important;
  }

  .Zo5x2q_me-lg-1 {
    margin-right: .25rem !important;
  }

  .Zo5x2q_me-lg-2 {
    margin-right: .5rem !important;
  }

  .Zo5x2q_me-lg-3 {
    margin-right: 1rem !important;
  }

  .Zo5x2q_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_me-lg-5 {
    margin-right: 3rem !important;
  }

  .Zo5x2q_me-lg-auto {
    margin-right: auto !important;
  }

  .Zo5x2q_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .Zo5x2q_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .Zo5x2q_ms-lg-0 {
    margin-left: 0 !important;
  }

  .Zo5x2q_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .Zo5x2q_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .Zo5x2q_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .Zo5x2q_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .Zo5x2q_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .Zo5x2q_ms-lg-auto {
    margin-left: auto !important;
  }

  .Zo5x2q_p-lg-0 {
    padding: 0 !important;
  }

  .Zo5x2q_p-lg-1 {
    padding: .25rem !important;
  }

  .Zo5x2q_p-lg-2 {
    padding: .5rem !important;
  }

  .Zo5x2q_p-lg-3 {
    padding: 1rem !important;
  }

  .Zo5x2q_p-lg-4 {
    padding: 1.5rem !important;
  }

  .Zo5x2q_p-lg-5 {
    padding: 3rem !important;
  }

  .Zo5x2q_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Zo5x2q_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Zo5x2q_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Zo5x2q_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Zo5x2q_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Zo5x2q_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Zo5x2q_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_pt-lg-0 {
    padding-top: 0 !important;
  }

  .Zo5x2q_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .Zo5x2q_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .Zo5x2q_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .Zo5x2q_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .Zo5x2q_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .Zo5x2q_pe-lg-0 {
    padding-right: 0 !important;
  }

  .Zo5x2q_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .Zo5x2q_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .Zo5x2q_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .Zo5x2q_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .Zo5x2q_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .Zo5x2q_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_ps-lg-0 {
    padding-left: 0 !important;
  }

  .Zo5x2q_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .Zo5x2q_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .Zo5x2q_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .Zo5x2q_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .Zo5x2q_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .Zo5x2q_gap-lg-0 {
    gap: 0 !important;
  }

  .Zo5x2q_gap-lg-1 {
    gap: .25rem !important;
  }

  .Zo5x2q_gap-lg-2 {
    gap: .5rem !important;
  }

  .Zo5x2q_gap-lg-3 {
    gap: 1rem !important;
  }

  .Zo5x2q_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .Zo5x2q_gap-lg-5 {
    gap: 3rem !important;
  }

  .Zo5x2q_text-lg-start {
    text-align: left !important;
  }

  .Zo5x2q_text-lg-end {
    text-align: right !important;
  }

  .Zo5x2q_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_float-xl-start {
    float: left !important;
  }

  .Zo5x2q_float-xl-end {
    float: right !important;
  }

  .Zo5x2q_float-xl-none {
    float: none !important;
  }

  .Zo5x2q_d-xl-inline {
    display: inline !important;
  }

  .Zo5x2q_d-xl-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-xl-block {
    display: block !important;
  }

  .Zo5x2q_d-xl-grid {
    display: grid !important;
  }

  .Zo5x2q_d-xl-table {
    display: table !important;
  }

  .Zo5x2q_d-xl-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-xl-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-xl-flex {
    display: flex !important;
  }

  .Zo5x2q_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-xl-none {
    display: none !important;
  }

  .Zo5x2q_flex-xl-fill {
    flex: auto !important;
  }

  .Zo5x2q_flex-xl-row {
    flex-direction: row !important;
  }

  .Zo5x2q_flex-xl-column {
    flex-direction: column !important;
  }

  .Zo5x2q_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Zo5x2q_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Zo5x2q_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .Zo5x2q_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .Zo5x2q_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Zo5x2q_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Zo5x2q_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .Zo5x2q_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Zo5x2q_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Zo5x2q_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .Zo5x2q_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .Zo5x2q_justify-content-xl-center {
    justify-content: center !important;
  }

  .Zo5x2q_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .Zo5x2q_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .Zo5x2q_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .Zo5x2q_align-items-xl-start {
    align-items: flex-start !important;
  }

  .Zo5x2q_align-items-xl-end {
    align-items: flex-end !important;
  }

  .Zo5x2q_align-items-xl-center {
    align-items: center !important;
  }

  .Zo5x2q_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .Zo5x2q_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .Zo5x2q_align-content-xl-start {
    align-content: flex-start !important;
  }

  .Zo5x2q_align-content-xl-end {
    align-content: flex-end !important;
  }

  .Zo5x2q_align-content-xl-center {
    align-content: center !important;
  }

  .Zo5x2q_align-content-xl-between {
    align-content: space-between !important;
  }

  .Zo5x2q_align-content-xl-around {
    align-content: space-around !important;
  }

  .Zo5x2q_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .Zo5x2q_align-self-xl-auto {
    align-self: auto !important;
  }

  .Zo5x2q_align-self-xl-start {
    align-self: flex-start !important;
  }

  .Zo5x2q_align-self-xl-end {
    align-self: flex-end !important;
  }

  .Zo5x2q_align-self-xl-center {
    align-self: center !important;
  }

  .Zo5x2q_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .Zo5x2q_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .Zo5x2q_order-xl-first {
    order: -1 !important;
  }

  .Zo5x2q_order-xl-0 {
    order: 0 !important;
  }

  .Zo5x2q_order-xl-1 {
    order: 1 !important;
  }

  .Zo5x2q_order-xl-2 {
    order: 2 !important;
  }

  .Zo5x2q_order-xl-3 {
    order: 3 !important;
  }

  .Zo5x2q_order-xl-4 {
    order: 4 !important;
  }

  .Zo5x2q_order-xl-5 {
    order: 5 !important;
  }

  .Zo5x2q_order-xl-last {
    order: 6 !important;
  }

  .Zo5x2q_m-xl-0 {
    margin: 0 !important;
  }

  .Zo5x2q_m-xl-1 {
    margin: .25rem !important;
  }

  .Zo5x2q_m-xl-2 {
    margin: .5rem !important;
  }

  .Zo5x2q_m-xl-3 {
    margin: 1rem !important;
  }

  .Zo5x2q_m-xl-4 {
    margin: 1.5rem !important;
  }

  .Zo5x2q_m-xl-5 {
    margin: 3rem !important;
  }

  .Zo5x2q_m-xl-auto {
    margin: auto !important;
  }

  .Zo5x2q_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Zo5x2q_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Zo5x2q_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Zo5x2q_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Zo5x2q_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Zo5x2q_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Zo5x2q_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Zo5x2q_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Zo5x2q_mt-xl-0 {
    margin-top: 0 !important;
  }

  .Zo5x2q_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .Zo5x2q_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .Zo5x2q_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .Zo5x2q_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .Zo5x2q_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .Zo5x2q_mt-xl-auto {
    margin-top: auto !important;
  }

  .Zo5x2q_me-xl-0 {
    margin-right: 0 !important;
  }

  .Zo5x2q_me-xl-1 {
    margin-right: .25rem !important;
  }

  .Zo5x2q_me-xl-2 {
    margin-right: .5rem !important;
  }

  .Zo5x2q_me-xl-3 {
    margin-right: 1rem !important;
  }

  .Zo5x2q_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_me-xl-5 {
    margin-right: 3rem !important;
  }

  .Zo5x2q_me-xl-auto {
    margin-right: auto !important;
  }

  .Zo5x2q_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .Zo5x2q_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .Zo5x2q_ms-xl-0 {
    margin-left: 0 !important;
  }

  .Zo5x2q_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .Zo5x2q_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .Zo5x2q_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .Zo5x2q_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .Zo5x2q_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .Zo5x2q_ms-xl-auto {
    margin-left: auto !important;
  }

  .Zo5x2q_p-xl-0 {
    padding: 0 !important;
  }

  .Zo5x2q_p-xl-1 {
    padding: .25rem !important;
  }

  .Zo5x2q_p-xl-2 {
    padding: .5rem !important;
  }

  .Zo5x2q_p-xl-3 {
    padding: 1rem !important;
  }

  .Zo5x2q_p-xl-4 {
    padding: 1.5rem !important;
  }

  .Zo5x2q_p-xl-5 {
    padding: 3rem !important;
  }

  .Zo5x2q_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Zo5x2q_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Zo5x2q_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Zo5x2q_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Zo5x2q_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Zo5x2q_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Zo5x2q_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_pt-xl-0 {
    padding-top: 0 !important;
  }

  .Zo5x2q_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .Zo5x2q_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .Zo5x2q_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .Zo5x2q_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .Zo5x2q_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .Zo5x2q_pe-xl-0 {
    padding-right: 0 !important;
  }

  .Zo5x2q_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .Zo5x2q_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .Zo5x2q_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .Zo5x2q_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .Zo5x2q_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .Zo5x2q_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_ps-xl-0 {
    padding-left: 0 !important;
  }

  .Zo5x2q_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .Zo5x2q_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .Zo5x2q_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .Zo5x2q_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .Zo5x2q_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .Zo5x2q_gap-xl-0 {
    gap: 0 !important;
  }

  .Zo5x2q_gap-xl-1 {
    gap: .25rem !important;
  }

  .Zo5x2q_gap-xl-2 {
    gap: .5rem !important;
  }

  .Zo5x2q_gap-xl-3 {
    gap: 1rem !important;
  }

  .Zo5x2q_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .Zo5x2q_gap-xl-5 {
    gap: 3rem !important;
  }

  .Zo5x2q_text-xl-start {
    text-align: left !important;
  }

  .Zo5x2q_text-xl-end {
    text-align: right !important;
  }

  .Zo5x2q_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .Zo5x2q_float-xxl-start {
    float: left !important;
  }

  .Zo5x2q_float-xxl-end {
    float: right !important;
  }

  .Zo5x2q_float-xxl-none {
    float: none !important;
  }

  .Zo5x2q_d-xxl-inline {
    display: inline !important;
  }

  .Zo5x2q_d-xxl-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-xxl-block {
    display: block !important;
  }

  .Zo5x2q_d-xxl-grid {
    display: grid !important;
  }

  .Zo5x2q_d-xxl-table {
    display: table !important;
  }

  .Zo5x2q_d-xxl-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-xxl-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-xxl-flex {
    display: flex !important;
  }

  .Zo5x2q_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-xxl-none {
    display: none !important;
  }

  .Zo5x2q_flex-xxl-fill {
    flex: auto !important;
  }

  .Zo5x2q_flex-xxl-row {
    flex-direction: row !important;
  }

  .Zo5x2q_flex-xxl-column {
    flex-direction: column !important;
  }

  .Zo5x2q_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .Zo5x2q_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .Zo5x2q_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .Zo5x2q_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .Zo5x2q_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .Zo5x2q_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .Zo5x2q_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .Zo5x2q_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .Zo5x2q_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .Zo5x2q_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .Zo5x2q_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .Zo5x2q_justify-content-xxl-center {
    justify-content: center !important;
  }

  .Zo5x2q_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .Zo5x2q_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .Zo5x2q_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .Zo5x2q_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .Zo5x2q_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .Zo5x2q_align-items-xxl-center {
    align-items: center !important;
  }

  .Zo5x2q_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .Zo5x2q_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .Zo5x2q_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .Zo5x2q_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .Zo5x2q_align-content-xxl-center {
    align-content: center !important;
  }

  .Zo5x2q_align-content-xxl-between {
    align-content: space-between !important;
  }

  .Zo5x2q_align-content-xxl-around {
    align-content: space-around !important;
  }

  .Zo5x2q_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .Zo5x2q_align-self-xxl-auto {
    align-self: auto !important;
  }

  .Zo5x2q_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .Zo5x2q_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .Zo5x2q_align-self-xxl-center {
    align-self: center !important;
  }

  .Zo5x2q_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .Zo5x2q_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .Zo5x2q_order-xxl-first {
    order: -1 !important;
  }

  .Zo5x2q_order-xxl-0 {
    order: 0 !important;
  }

  .Zo5x2q_order-xxl-1 {
    order: 1 !important;
  }

  .Zo5x2q_order-xxl-2 {
    order: 2 !important;
  }

  .Zo5x2q_order-xxl-3 {
    order: 3 !important;
  }

  .Zo5x2q_order-xxl-4 {
    order: 4 !important;
  }

  .Zo5x2q_order-xxl-5 {
    order: 5 !important;
  }

  .Zo5x2q_order-xxl-last {
    order: 6 !important;
  }

  .Zo5x2q_m-xxl-0 {
    margin: 0 !important;
  }

  .Zo5x2q_m-xxl-1 {
    margin: .25rem !important;
  }

  .Zo5x2q_m-xxl-2 {
    margin: .5rem !important;
  }

  .Zo5x2q_m-xxl-3 {
    margin: 1rem !important;
  }

  .Zo5x2q_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .Zo5x2q_m-xxl-5 {
    margin: 3rem !important;
  }

  .Zo5x2q_m-xxl-auto {
    margin: auto !important;
  }

  .Zo5x2q_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Zo5x2q_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .Zo5x2q_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .Zo5x2q_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .Zo5x2q_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .Zo5x2q_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .Zo5x2q_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .Zo5x2q_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .Zo5x2q_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .Zo5x2q_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .Zo5x2q_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .Zo5x2q_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .Zo5x2q_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .Zo5x2q_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .Zo5x2q_mt-xxl-auto {
    margin-top: auto !important;
  }

  .Zo5x2q_me-xxl-0 {
    margin-right: 0 !important;
  }

  .Zo5x2q_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .Zo5x2q_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .Zo5x2q_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .Zo5x2q_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .Zo5x2q_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .Zo5x2q_me-xxl-auto {
    margin-right: auto !important;
  }

  .Zo5x2q_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .Zo5x2q_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .Zo5x2q_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .Zo5x2q_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .Zo5x2q_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .Zo5x2q_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .Zo5x2q_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .Zo5x2q_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .Zo5x2q_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .Zo5x2q_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .Zo5x2q_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .Zo5x2q_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .Zo5x2q_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .Zo5x2q_ms-xxl-auto {
    margin-left: auto !important;
  }

  .Zo5x2q_p-xxl-0 {
    padding: 0 !important;
  }

  .Zo5x2q_p-xxl-1 {
    padding: .25rem !important;
  }

  .Zo5x2q_p-xxl-2 {
    padding: .5rem !important;
  }

  .Zo5x2q_p-xxl-3 {
    padding: 1rem !important;
  }

  .Zo5x2q_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .Zo5x2q_p-xxl-5 {
    padding: 3rem !important;
  }

  .Zo5x2q_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Zo5x2q_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .Zo5x2q_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .Zo5x2q_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Zo5x2q_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .Zo5x2q_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .Zo5x2q_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .Zo5x2q_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .Zo5x2q_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .Zo5x2q_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .Zo5x2q_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .Zo5x2q_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .Zo5x2q_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .Zo5x2q_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .Zo5x2q_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .Zo5x2q_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .Zo5x2q_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .Zo5x2q_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .Zo5x2q_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .Zo5x2q_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .Zo5x2q_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .Zo5x2q_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .Zo5x2q_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .Zo5x2q_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .Zo5x2q_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .Zo5x2q_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .Zo5x2q_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .Zo5x2q_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .Zo5x2q_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .Zo5x2q_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .Zo5x2q_gap-xxl-0 {
    gap: 0 !important;
  }

  .Zo5x2q_gap-xxl-1 {
    gap: .25rem !important;
  }

  .Zo5x2q_gap-xxl-2 {
    gap: .5rem !important;
  }

  .Zo5x2q_gap-xxl-3 {
    gap: 1rem !important;
  }

  .Zo5x2q_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .Zo5x2q_gap-xxl-5 {
    gap: 3rem !important;
  }

  .Zo5x2q_text-xxl-start {
    text-align: left !important;
  }

  .Zo5x2q_text-xxl-end {
    text-align: right !important;
  }

  .Zo5x2q_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .Zo5x2q_fs-1 {
    font-size: 2.5rem !important;
  }

  .Zo5x2q_fs-2 {
    font-size: 2rem !important;
  }

  .Zo5x2q_fs-3 {
    font-size: 1.75rem !important;
  }

  .Zo5x2q_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .Zo5x2q_d-print-inline {
    display: inline !important;
  }

  .Zo5x2q_d-print-inline-block {
    display: inline-block !important;
  }

  .Zo5x2q_d-print-block {
    display: block !important;
  }

  .Zo5x2q_d-print-grid {
    display: grid !important;
  }

  .Zo5x2q_d-print-table {
    display: table !important;
  }

  .Zo5x2q_d-print-table-row {
    display: table-row !important;
  }

  .Zo5x2q_d-print-table-cell {
    display: table-cell !important;
  }

  .Zo5x2q_d-print-flex {
    display: flex !important;
  }

  .Zo5x2q_d-print-inline-flex {
    display: inline-flex !important;
  }

  .Zo5x2q_d-print-none {
    display: none !important;
  }
}

.Zo5x2q_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.Zo5x2q_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-item .Zo5x2q_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.Zo5x2q_nav-tabs .Zo5x2q_nav-item .Zo5x2q_nav-link.Zo5x2q_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.Zo5x2q_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#Zo5x2q_root {
  height: 100%;
}

.Zo5x2q_highcharts-credits {
  display: none !important;
}

h3, .Zo5x2q_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.Zo5x2q_modal .Zo5x2q_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.Zo5x2q_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Zo5x2q_table thead tr th.Zo5x2q_col-actions {
  width: 110px !important;
}

table.Zo5x2q_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.Zo5x2q_table tbody tr.Zo5x2q_deleting {
  background-color: #ff323233 !important;
}

.Zo5x2q_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.Zo5x2q_btn-sm, .Zo5x2q_btn-group-sm > .Zo5x2q_btn {
  font-size: .6rem !important;
}

.Zo5x2q_modal {
  background-color: #0a0a0a4d;
}

.Zo5x2q_btn:focus {
  box-shadow: none !important;
}

.Zo5x2q_content-filter .Zo5x2q_react-datepicker-wrapper {
  width: auto !important;
}

.Zo5x2q_content-filter .Zo5x2q_react-datepicker-wrapper .Zo5x2q_form-control {
  font-size: .85rem !important;
}

.Zo5x2q_content-filter .Zo5x2q_input-group-text, .Zo5x2q_content-filter .Zo5x2q_btn {
  height: 2.15rem !important;
}

.Zo5x2q_content-filter .Zo5x2q_btn {
  border-color: #ccc;
}

.Zo5x2q_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.Zo5x2q_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.Zo5x2q_table > :not(:first-child) {
  border-top: unset !important;
}

.Zo5x2q_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.Zo5x2q_highcharts-series.Zo5x2q_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.Zo5x2q_expandable {
  transition: all .5s;
}

.Zo5x2q_expandable > .Zo5x2q_expander-icon {
  cursor: pointer;
}

.Zo5x2q_expandable > .Zo5x2q_expander-icon:hover {
  color: #328cfa66;
}

.Zo5x2q_expandable.Zo5x2q_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .Zo5x2q_modal .Zo5x2q_modal-xl {
    width: 100% !important;
  }

  .Zo5x2q_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.Zo5x2q_d-none, .Zo5x2q_d-block {
  transition: all .5s;
}

.Zo5x2q_wrapper {
  height: 70px;
  z-index: 5;
  background-color: #fff;
  position: sticky;
  top: 0;
  box-shadow: 0 1px 5px 2px #96969666;
}

.Zo5x2q_row, .Zo5x2q_content {
  height: 100%;
  align-items: center;
  display: flex;
}

.fWclgW_appSearch {
  padding: 16px 0;
}

.fWclgW_appSearch input {
  height: 38px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f3f3f9;
  border: none;
  border-radius: 30px;
  padding-left: 40px;
  padding-right: 20px;
}

.fWclgW_appSearch span {
  z-index: 10;
  color: #74788d;
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 13px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 15px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -87px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #000;
  background-color: #bad9f1;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  height: auto;
  margin: .4rem;
  padding-left: .2rem;
  padding-right: .2rem;
}

.react-datepicker__aria-live {
  clip-path: circle(0);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .GvlQTG_h6, h5, .GvlQTG_h5, h4, .GvlQTG_h4, h3, .GvlQTG_h3, h2, .GvlQTG_h2, h1, .GvlQTG_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .GvlQTG_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .GvlQTG_h1 {
    font-size: 2.5rem;
  }
}

h2, .GvlQTG_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .GvlQTG_h2 {
    font-size: 2rem;
  }
}

h3, .GvlQTG_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .GvlQTG_h3 {
    font-size: 1.75rem;
  }
}

h4, .GvlQTG_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .GvlQTG_h4 {
    font-size: 1.5rem;
  }
}

h5, .GvlQTG_h5 {
  font-size: 1.25rem;
}

h6, .GvlQTG_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .GvlQTG_small {
  font-size: .875em;
}

mark, .GvlQTG_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.GvlQTG_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.GvlQTG_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-1 {
    font-size: 5rem;
  }
}

.GvlQTG_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-2 {
    font-size: 4.5rem;
  }
}

.GvlQTG_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-3 {
    font-size: 4rem;
  }
}

.GvlQTG_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-4 {
    font-size: 3.5rem;
  }
}

.GvlQTG_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-5 {
    font-size: 3rem;
  }
}

.GvlQTG_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .GvlQTG_display-6 {
    font-size: 2.5rem;
  }
}

.GvlQTG_list-unstyled, .GvlQTG_list-inline {
  padding-left: 0;
  list-style: none;
}

.GvlQTG_list-inline-item {
  display: inline-block;
}

.GvlQTG_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.GvlQTG_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.GvlQTG_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.GvlQTG_blockquote > :last-child {
  margin-bottom: 0;
}

.GvlQTG_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.GvlQTG_blockquote-footer:before {
  content: "— ";
}

.GvlQTG_img-fluid {
  max-width: 100%;
  height: auto;
}

.GvlQTG_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.GvlQTG_figure {
  display: inline-block;
}

.GvlQTG_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.GvlQTG_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.GvlQTG_container, .GvlQTG_container-fluid, .GvlQTG_container-xxl, .GvlQTG_container-xl, .GvlQTG_container-lg, .GvlQTG_container-md, .GvlQTG_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .GvlQTG_container-sm, .GvlQTG_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .GvlQTG_container-md, .GvlQTG_container-sm, .GvlQTG_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .GvlQTG_container-lg, .GvlQTG_container-md, .GvlQTG_container-sm, .GvlQTG_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_container-xl, .GvlQTG_container-lg, .GvlQTG_container-md, .GvlQTG_container-sm, .GvlQTG_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_container-xxl, .GvlQTG_container-xl, .GvlQTG_container-lg, .GvlQTG_container-md, .GvlQTG_container-sm, .GvlQTG_container {
    max-width: 1320px;
  }
}

.GvlQTG_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.GvlQTG_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.GvlQTG_col {
  flex: 1 0;
}

.GvlQTG_row-cols-auto > * {
  width: auto;
  flex: none;
}

.GvlQTG_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.GvlQTG_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.GvlQTG_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.GvlQTG_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.GvlQTG_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.GvlQTG_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.GvlQTG_col-auto {
  width: auto;
  flex: none;
}

.GvlQTG_col-1 {
  width: 8.33333%;
  flex: none;
}

.GvlQTG_col-2 {
  width: 16.6667%;
  flex: none;
}

.GvlQTG_col-3 {
  width: 25%;
  flex: none;
}

.GvlQTG_col-4 {
  width: 33.3333%;
  flex: none;
}

.GvlQTG_col-5 {
  width: 41.6667%;
  flex: none;
}

.GvlQTG_col-6 {
  width: 50%;
  flex: none;
}

.GvlQTG_col-7 {
  width: 58.3333%;
  flex: none;
}

.GvlQTG_col-8 {
  width: 66.6667%;
  flex: none;
}

.GvlQTG_col-9 {
  width: 75%;
  flex: none;
}

.GvlQTG_col-10 {
  width: 83.3333%;
  flex: none;
}

.GvlQTG_col-11 {
  width: 91.6667%;
  flex: none;
}

.GvlQTG_col-12 {
  width: 100%;
  flex: none;
}

.GvlQTG_offset-1 {
  margin-left: 8.33333%;
}

.GvlQTG_offset-2 {
  margin-left: 16.6667%;
}

.GvlQTG_offset-3 {
  margin-left: 25%;
}

.GvlQTG_offset-4 {
  margin-left: 33.3333%;
}

.GvlQTG_offset-5 {
  margin-left: 41.6667%;
}

.GvlQTG_offset-6 {
  margin-left: 50%;
}

.GvlQTG_offset-7 {
  margin-left: 58.3333%;
}

.GvlQTG_offset-8 {
  margin-left: 66.6667%;
}

.GvlQTG_offset-9 {
  margin-left: 75%;
}

.GvlQTG_offset-10 {
  margin-left: 83.3333%;
}

.GvlQTG_offset-11 {
  margin-left: 91.6667%;
}

.GvlQTG_g-0, .GvlQTG_gx-0 {
  --bs-gutter-x: 0;
}

.GvlQTG_g-0, .GvlQTG_gy-0 {
  --bs-gutter-y: 0;
}

.GvlQTG_g-1, .GvlQTG_gx-1 {
  --bs-gutter-x: .25rem;
}

.GvlQTG_g-1, .GvlQTG_gy-1 {
  --bs-gutter-y: .25rem;
}

.GvlQTG_g-2, .GvlQTG_gx-2 {
  --bs-gutter-x: .5rem;
}

.GvlQTG_g-2, .GvlQTG_gy-2 {
  --bs-gutter-y: .5rem;
}

.GvlQTG_g-3, .GvlQTG_gx-3 {
  --bs-gutter-x: 1rem;
}

.GvlQTG_g-3, .GvlQTG_gy-3 {
  --bs-gutter-y: 1rem;
}

.GvlQTG_g-4, .GvlQTG_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.GvlQTG_g-4, .GvlQTG_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.GvlQTG_g-5, .GvlQTG_gx-5 {
  --bs-gutter-x: 3rem;
}

.GvlQTG_g-5, .GvlQTG_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .GvlQTG_col-sm {
    flex: 1 0;
  }

  .GvlQTG_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .GvlQTG_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .GvlQTG_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .GvlQTG_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .GvlQTG_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .GvlQTG_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-sm-auto {
    width: auto;
    flex: none;
  }

  .GvlQTG_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .GvlQTG_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .GvlQTG_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .GvlQTG_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .GvlQTG_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .GvlQTG_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .GvlQTG_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .GvlQTG_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .GvlQTG_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .GvlQTG_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .GvlQTG_offset-sm-0 {
    margin-left: 0;
  }

  .GvlQTG_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .GvlQTG_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .GvlQTG_offset-sm-3 {
    margin-left: 25%;
  }

  .GvlQTG_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .GvlQTG_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .GvlQTG_offset-sm-6 {
    margin-left: 50%;
  }

  .GvlQTG_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .GvlQTG_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .GvlQTG_offset-sm-9 {
    margin-left: 75%;
  }

  .GvlQTG_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .GvlQTG_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .GvlQTG_g-sm-0, .GvlQTG_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .GvlQTG_g-sm-0, .GvlQTG_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .GvlQTG_g-sm-1, .GvlQTG_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .GvlQTG_g-sm-1, .GvlQTG_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .GvlQTG_g-sm-2, .GvlQTG_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .GvlQTG_g-sm-2, .GvlQTG_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .GvlQTG_g-sm-3, .GvlQTG_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .GvlQTG_g-sm-3, .GvlQTG_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .GvlQTG_g-sm-4, .GvlQTG_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .GvlQTG_g-sm-4, .GvlQTG_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .GvlQTG_g-sm-5, .GvlQTG_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .GvlQTG_g-sm-5, .GvlQTG_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .GvlQTG_col-md {
    flex: 1 0;
  }

  .GvlQTG_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .GvlQTG_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .GvlQTG_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .GvlQTG_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .GvlQTG_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .GvlQTG_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-md-auto {
    width: auto;
    flex: none;
  }

  .GvlQTG_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .GvlQTG_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-md-3 {
    width: 25%;
    flex: none;
  }

  .GvlQTG_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .GvlQTG_col-md-6 {
    width: 50%;
    flex: none;
  }

  .GvlQTG_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .GvlQTG_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .GvlQTG_col-md-9 {
    width: 75%;
    flex: none;
  }

  .GvlQTG_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .GvlQTG_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .GvlQTG_col-md-12 {
    width: 100%;
    flex: none;
  }

  .GvlQTG_offset-md-0 {
    margin-left: 0;
  }

  .GvlQTG_offset-md-1 {
    margin-left: 8.33333%;
  }

  .GvlQTG_offset-md-2 {
    margin-left: 16.6667%;
  }

  .GvlQTG_offset-md-3 {
    margin-left: 25%;
  }

  .GvlQTG_offset-md-4 {
    margin-left: 33.3333%;
  }

  .GvlQTG_offset-md-5 {
    margin-left: 41.6667%;
  }

  .GvlQTG_offset-md-6 {
    margin-left: 50%;
  }

  .GvlQTG_offset-md-7 {
    margin-left: 58.3333%;
  }

  .GvlQTG_offset-md-8 {
    margin-left: 66.6667%;
  }

  .GvlQTG_offset-md-9 {
    margin-left: 75%;
  }

  .GvlQTG_offset-md-10 {
    margin-left: 83.3333%;
  }

  .GvlQTG_offset-md-11 {
    margin-left: 91.6667%;
  }

  .GvlQTG_g-md-0, .GvlQTG_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .GvlQTG_g-md-0, .GvlQTG_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .GvlQTG_g-md-1, .GvlQTG_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .GvlQTG_g-md-1, .GvlQTG_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .GvlQTG_g-md-2, .GvlQTG_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .GvlQTG_g-md-2, .GvlQTG_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .GvlQTG_g-md-3, .GvlQTG_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .GvlQTG_g-md-3, .GvlQTG_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .GvlQTG_g-md-4, .GvlQTG_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .GvlQTG_g-md-4, .GvlQTG_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .GvlQTG_g-md-5, .GvlQTG_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .GvlQTG_g-md-5, .GvlQTG_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .GvlQTG_col-lg {
    flex: 1 0;
  }

  .GvlQTG_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .GvlQTG_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .GvlQTG_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .GvlQTG_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .GvlQTG_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .GvlQTG_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-lg-auto {
    width: auto;
    flex: none;
  }

  .GvlQTG_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .GvlQTG_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .GvlQTG_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .GvlQTG_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .GvlQTG_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .GvlQTG_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .GvlQTG_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .GvlQTG_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .GvlQTG_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .GvlQTG_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .GvlQTG_offset-lg-0 {
    margin-left: 0;
  }

  .GvlQTG_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .GvlQTG_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .GvlQTG_offset-lg-3 {
    margin-left: 25%;
  }

  .GvlQTG_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .GvlQTG_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .GvlQTG_offset-lg-6 {
    margin-left: 50%;
  }

  .GvlQTG_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .GvlQTG_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .GvlQTG_offset-lg-9 {
    margin-left: 75%;
  }

  .GvlQTG_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .GvlQTG_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .GvlQTG_g-lg-0, .GvlQTG_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .GvlQTG_g-lg-0, .GvlQTG_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .GvlQTG_g-lg-1, .GvlQTG_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .GvlQTG_g-lg-1, .GvlQTG_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .GvlQTG_g-lg-2, .GvlQTG_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .GvlQTG_g-lg-2, .GvlQTG_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .GvlQTG_g-lg-3, .GvlQTG_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .GvlQTG_g-lg-3, .GvlQTG_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .GvlQTG_g-lg-4, .GvlQTG_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .GvlQTG_g-lg-4, .GvlQTG_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .GvlQTG_g-lg-5, .GvlQTG_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .GvlQTG_g-lg-5, .GvlQTG_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_col-xl {
    flex: 1 0;
  }

  .GvlQTG_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .GvlQTG_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .GvlQTG_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .GvlQTG_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .GvlQTG_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .GvlQTG_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-xl-auto {
    width: auto;
    flex: none;
  }

  .GvlQTG_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .GvlQTG_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .GvlQTG_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .GvlQTG_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .GvlQTG_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .GvlQTG_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .GvlQTG_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .GvlQTG_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .GvlQTG_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .GvlQTG_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .GvlQTG_offset-xl-0 {
    margin-left: 0;
  }

  .GvlQTG_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .GvlQTG_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .GvlQTG_offset-xl-3 {
    margin-left: 25%;
  }

  .GvlQTG_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .GvlQTG_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .GvlQTG_offset-xl-6 {
    margin-left: 50%;
  }

  .GvlQTG_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .GvlQTG_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .GvlQTG_offset-xl-9 {
    margin-left: 75%;
  }

  .GvlQTG_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .GvlQTG_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .GvlQTG_g-xl-0, .GvlQTG_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .GvlQTG_g-xl-0, .GvlQTG_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .GvlQTG_g-xl-1, .GvlQTG_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .GvlQTG_g-xl-1, .GvlQTG_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .GvlQTG_g-xl-2, .GvlQTG_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .GvlQTG_g-xl-2, .GvlQTG_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .GvlQTG_g-xl-3, .GvlQTG_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .GvlQTG_g-xl-3, .GvlQTG_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .GvlQTG_g-xl-4, .GvlQTG_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .GvlQTG_g-xl-4, .GvlQTG_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .GvlQTG_g-xl-5, .GvlQTG_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .GvlQTG_g-xl-5, .GvlQTG_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_col-xxl {
    flex: 1 0;
  }

  .GvlQTG_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .GvlQTG_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .GvlQTG_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .GvlQTG_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .GvlQTG_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .GvlQTG_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .GvlQTG_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .GvlQTG_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .GvlQTG_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .GvlQTG_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .GvlQTG_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .GvlQTG_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .GvlQTG_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .GvlQTG_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .GvlQTG_offset-xxl-0 {
    margin-left: 0;
  }

  .GvlQTG_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .GvlQTG_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .GvlQTG_offset-xxl-3 {
    margin-left: 25%;
  }

  .GvlQTG_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .GvlQTG_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .GvlQTG_offset-xxl-6 {
    margin-left: 50%;
  }

  .GvlQTG_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .GvlQTG_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .GvlQTG_offset-xxl-9 {
    margin-left: 75%;
  }

  .GvlQTG_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .GvlQTG_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .GvlQTG_g-xxl-0, .GvlQTG_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .GvlQTG_g-xxl-0, .GvlQTG_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .GvlQTG_g-xxl-1, .GvlQTG_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .GvlQTG_g-xxl-1, .GvlQTG_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .GvlQTG_g-xxl-2, .GvlQTG_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .GvlQTG_g-xxl-2, .GvlQTG_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .GvlQTG_g-xxl-3, .GvlQTG_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .GvlQTG_g-xxl-3, .GvlQTG_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .GvlQTG_g-xxl-4, .GvlQTG_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .GvlQTG_g-xxl-4, .GvlQTG_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .GvlQTG_g-xxl-5, .GvlQTG_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .GvlQTG_g-xxl-5, .GvlQTG_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.GvlQTG_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.GvlQTG_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.GvlQTG_table > tbody {
  vertical-align: inherit;
}

.GvlQTG_table > thead {
  vertical-align: bottom;
}

.GvlQTG_table-group-divider {
  border-top: 2px solid;
}

.GvlQTG_caption-top {
  caption-side: top;
}

.GvlQTG_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.GvlQTG_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.GvlQTG_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.GvlQTG_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.GvlQTG_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.GvlQTG_table-striped > tbody > tr:nth-of-type(2n+1) > *, .GvlQTG_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.GvlQTG_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.GvlQTG_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.GvlQTG_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.GvlQTG_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .GvlQTG_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .GvlQTG_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .GvlQTG_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .GvlQTG_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .GvlQTG_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.GvlQTG_form-label {
  margin-bottom: .5rem;
}

.GvlQTG_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.GvlQTG_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.GvlQTG_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.GvlQTG_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.GvlQTG_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-control {
    transition: none;
  }
}

.GvlQTG_form-control[type="file"] {
  overflow: hidden;
}

.GvlQTG_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.GvlQTG_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.GvlQTG_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.GvlQTG_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.GvlQTG_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.GvlQTG_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-control::file-selector-button {
    transition: none;
  }
}

.GvlQTG_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.GvlQTG_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.GvlQTG_form-control-plaintext:focus {
  outline: 0;
}

.GvlQTG_form-control-plaintext.GvlQTG_form-control-sm, .GvlQTG_form-control-plaintext.GvlQTG_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.GvlQTG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.GvlQTG_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.GvlQTG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.GvlQTG_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.GvlQTG_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.GvlQTG_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.GvlQTG_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.GvlQTG_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.GvlQTG_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.GvlQTG_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.GvlQTG_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.GvlQTG_form-control-color.GvlQTG_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.GvlQTG_form-control-color.GvlQTG_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.GvlQTG_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-select {
    transition: none;
  }
}

.GvlQTG_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.GvlQTG_form-select[multiple], .GvlQTG_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.GvlQTG_form-select:disabled {
  background-color: #e9ecef;
}

.GvlQTG_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.GvlQTG_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.GvlQTG_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.GvlQTG_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.GvlQTG_form-check .GvlQTG_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.GvlQTG_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.GvlQTG_form-check-reverse .GvlQTG_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.GvlQTG_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.GvlQTG_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.GvlQTG_form-check-input[type="radio"] {
  border-radius: 50%;
}

.GvlQTG_form-check-input:active {
  filter: brightness(90%);
}

.GvlQTG_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.GvlQTG_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.GvlQTG_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.GvlQTG_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.GvlQTG_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.GvlQTG_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.GvlQTG_form-check-input[disabled] ~ .GvlQTG_form-check-label, .GvlQTG_form-check-input:disabled ~ .GvlQTG_form-check-label {
  cursor: default;
  opacity: .5;
}

.GvlQTG_form-switch {
  padding-left: 2.5em;
}

.GvlQTG_form-switch .GvlQTG_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-switch .GvlQTG_form-check-input {
    transition: none;
  }
}

.GvlQTG_form-switch .GvlQTG_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.GvlQTG_form-switch .GvlQTG_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.GvlQTG_form-switch.GvlQTG_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.GvlQTG_form-switch.GvlQTG_form-check-reverse .GvlQTG_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.GvlQTG_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.GvlQTG_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.GvlQTG_btn-check[disabled] + .GvlQTG_btn, .GvlQTG_btn-check:disabled + .GvlQTG_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.GvlQTG_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.GvlQTG_form-range:focus {
  outline: 0;
}

.GvlQTG_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.GvlQTG_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.GvlQTG_form-range::-moz-focus-outer {
  border: 0;
}

.GvlQTG_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.GvlQTG_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.GvlQTG_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.GvlQTG_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-range::-moz-range-thumb {
    transition: none;
  }
}

.GvlQTG_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.GvlQTG_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.GvlQTG_form-range:disabled {
  pointer-events: none;
}

.GvlQTG_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.GvlQTG_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.GvlQTG_form-floating {
  position: relative;
}

.GvlQTG_form-floating > .GvlQTG_form-control, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext, .GvlQTG_form-floating > .GvlQTG_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.GvlQTG_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_form-floating > label {
    transition: none;
  }
}

.GvlQTG_form-floating > .GvlQTG_form-control, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext {
  padding: 1rem .75rem;
}

.GvlQTG_form-floating > .GvlQTG_form-control::placeholder, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext::placeholder {
  color: #0000;
}

.GvlQTG_form-floating > .GvlQTG_form-control:focus, .GvlQTG_form-floating > .GvlQTG_form-control:not(:placeholder-shown), .GvlQTG_form-floating > .GvlQTG_form-control-plaintext:focus, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.GvlQTG_form-floating > .GvlQTG_form-control:-webkit-autofill, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.GvlQTG_form-floating > .GvlQTG_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.GvlQTG_form-floating > .GvlQTG_form-control:focus ~ label, .GvlQTG_form-floating > .GvlQTG_form-control:not(:placeholder-shown) ~ label, .GvlQTG_form-floating > .GvlQTG_form-control-plaintext ~ label, .GvlQTG_form-floating > .GvlQTG_form-select ~ label, .GvlQTG_form-floating > .GvlQTG_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.GvlQTG_form-floating > .GvlQTG_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.GvlQTG_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.GvlQTG_input-group > .GvlQTG_form-control, .GvlQTG_input-group > .GvlQTG_form-select, .GvlQTG_input-group > .GvlQTG_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.GvlQTG_input-group > .GvlQTG_form-control:focus, .GvlQTG_input-group > .GvlQTG_form-select:focus, .GvlQTG_input-group > .GvlQTG_form-floating:focus-within {
  z-index: 5;
}

.GvlQTG_input-group .GvlQTG_btn {
  z-index: 2;
  position: relative;
}

.GvlQTG_input-group .GvlQTG_btn:focus {
  z-index: 5;
}

.GvlQTG_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.GvlQTG_input-group-lg > .GvlQTG_form-control, .GvlQTG_input-group-lg > .GvlQTG_form-select, .GvlQTG_input-group-lg > .GvlQTG_input-group-text, .GvlQTG_input-group-lg > .GvlQTG_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.GvlQTG_input-group-sm > .GvlQTG_form-control, .GvlQTG_input-group-sm > .GvlQTG_form-select, .GvlQTG_input-group-sm > .GvlQTG_input-group-text, .GvlQTG_input-group-sm > .GvlQTG_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.GvlQTG_input-group-lg > .GvlQTG_form-select, .GvlQTG_input-group-sm > .GvlQTG_form-select {
  padding-right: 3rem;
}

.GvlQTG_input-group:not(.GvlQTG_has-validation) > :not(:last-child):not(.GvlQTG_dropdown-toggle):not(.GvlQTG_dropdown-menu):not(.GvlQTG_form-floating), .GvlQTG_input-group:not(.GvlQTG_has-validation) > .GvlQTG_dropdown-toggle:nth-last-child(n+3), .GvlQTG_input-group:not(.GvlQTG_has-validation) > .GvlQTG_form-floating:not(:last-child) > .GvlQTG_form-control, .GvlQTG_input-group:not(.GvlQTG_has-validation) > .GvlQTG_form-floating:not(:last-child) > .GvlQTG_form-select, .GvlQTG_input-group.GvlQTG_has-validation > :nth-last-child(n+3):not(.GvlQTG_dropdown-toggle):not(.GvlQTG_dropdown-menu):not(.GvlQTG_form-floating), .GvlQTG_input-group.GvlQTG_has-validation > .GvlQTG_dropdown-toggle:nth-last-child(n+4), .GvlQTG_input-group.GvlQTG_has-validation > .GvlQTG_form-floating:nth-last-child(n+3) > .GvlQTG_form-control, .GvlQTG_input-group.GvlQTG_has-validation > .GvlQTG_form-floating:nth-last-child(n+3) > .GvlQTG_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.GvlQTG_input-group > :not(:first-child):not(.GvlQTG_dropdown-menu):not(.GvlQTG_valid-tooltip):not(.GvlQTG_valid-feedback):not(.GvlQTG_invalid-tooltip):not(.GvlQTG_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.GvlQTG_input-group > .GvlQTG_form-floating:not(:first-child) > .GvlQTG_form-control, .GvlQTG_input-group > .GvlQTG_form-floating:not(:first-child) > .GvlQTG_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.GvlQTG_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.GvlQTG_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.GvlQTG_was-validated :valid ~ .GvlQTG_valid-feedback, .GvlQTG_was-validated :valid ~ .GvlQTG_valid-tooltip, .GvlQTG_is-valid ~ .GvlQTG_valid-feedback, .GvlQTG_is-valid ~ .GvlQTG_valid-tooltip {
  display: block;
}

.GvlQTG_was-validated .GvlQTG_form-control:valid, .GvlQTG_form-control.GvlQTG_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.GvlQTG_was-validated .GvlQTG_form-control:valid:focus, .GvlQTG_form-control.GvlQTG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.GvlQTG_was-validated textarea.GvlQTG_form-control:valid, textarea.GvlQTG_form-control.GvlQTG_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.GvlQTG_was-validated .GvlQTG_form-select:valid, .GvlQTG_form-select.GvlQTG_is-valid {
  border-color: #198754;
}

.GvlQTG_was-validated .GvlQTG_form-select:valid:not([multiple]):not([size]), .GvlQTG_was-validated .GvlQTG_form-select:valid:not([multiple])[size="1"], .GvlQTG_form-select.GvlQTG_is-valid:not([multiple]):not([size]), .GvlQTG_form-select.GvlQTG_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.GvlQTG_was-validated .GvlQTG_form-select:valid:focus, .GvlQTG_form-select.GvlQTG_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.GvlQTG_was-validated .GvlQTG_form-control-color:valid, .GvlQTG_form-control-color.GvlQTG_is-valid {
  width: calc(1.5em + 3.75rem);
}

.GvlQTG_was-validated .GvlQTG_form-check-input:valid, .GvlQTG_form-check-input.GvlQTG_is-valid {
  border-color: #198754;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:valid:checked, .GvlQTG_form-check-input.GvlQTG_is-valid:checked {
  background-color: #198754;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:valid:focus, .GvlQTG_form-check-input.GvlQTG_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:valid ~ .GvlQTG_form-check-label, .GvlQTG_form-check-input.GvlQTG_is-valid ~ .GvlQTG_form-check-label {
  color: #198754;
}

.GvlQTG_form-check-inline .GvlQTG_form-check-input ~ .GvlQTG_valid-feedback {
  margin-left: .5em;
}

.GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-control:not(:focus):valid, .GvlQTG_input-group > .GvlQTG_form-control:not(:focus).GvlQTG_is-valid, .GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-select:not(:focus):valid, .GvlQTG_input-group > .GvlQTG_form-select:not(:focus).GvlQTG_is-valid, .GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-floating:not(:focus-within):valid, .GvlQTG_input-group > .GvlQTG_form-floating:not(:focus-within).GvlQTG_is-valid {
  z-index: 3;
}

.GvlQTG_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.GvlQTG_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.GvlQTG_was-validated :invalid ~ .GvlQTG_invalid-feedback, .GvlQTG_was-validated :invalid ~ .GvlQTG_invalid-tooltip, .GvlQTG_is-invalid ~ .GvlQTG_invalid-feedback, .GvlQTG_is-invalid ~ .GvlQTG_invalid-tooltip {
  display: block;
}

.GvlQTG_was-validated .GvlQTG_form-control:invalid, .GvlQTG_form-control.GvlQTG_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.GvlQTG_was-validated .GvlQTG_form-control:invalid:focus, .GvlQTG_form-control.GvlQTG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.GvlQTG_was-validated textarea.GvlQTG_form-control:invalid, textarea.GvlQTG_form-control.GvlQTG_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.GvlQTG_was-validated .GvlQTG_form-select:invalid, .GvlQTG_form-select.GvlQTG_is-invalid {
  border-color: #dc3545;
}

.GvlQTG_was-validated .GvlQTG_form-select:invalid:not([multiple]):not([size]), .GvlQTG_was-validated .GvlQTG_form-select:invalid:not([multiple])[size="1"], .GvlQTG_form-select.GvlQTG_is-invalid:not([multiple]):not([size]), .GvlQTG_form-select.GvlQTG_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.GvlQTG_was-validated .GvlQTG_form-select:invalid:focus, .GvlQTG_form-select.GvlQTG_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.GvlQTG_was-validated .GvlQTG_form-control-color:invalid, .GvlQTG_form-control-color.GvlQTG_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.GvlQTG_was-validated .GvlQTG_form-check-input:invalid, .GvlQTG_form-check-input.GvlQTG_is-invalid {
  border-color: #dc3545;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:invalid:checked, .GvlQTG_form-check-input.GvlQTG_is-invalid:checked {
  background-color: #dc3545;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:invalid:focus, .GvlQTG_form-check-input.GvlQTG_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.GvlQTG_was-validated .GvlQTG_form-check-input:invalid ~ .GvlQTG_form-check-label, .GvlQTG_form-check-input.GvlQTG_is-invalid ~ .GvlQTG_form-check-label {
  color: #dc3545;
}

.GvlQTG_form-check-inline .GvlQTG_form-check-input ~ .GvlQTG_invalid-feedback {
  margin-left: .5em;
}

.GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-control:not(:focus):invalid, .GvlQTG_input-group > .GvlQTG_form-control:not(:focus).GvlQTG_is-invalid, .GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-select:not(:focus):invalid, .GvlQTG_input-group > .GvlQTG_form-select:not(:focus).GvlQTG_is-invalid, .GvlQTG_was-validated .GvlQTG_input-group > .GvlQTG_form-floating:not(:focus-within):invalid, .GvlQTG_input-group > .GvlQTG_form-floating:not(:focus-within).GvlQTG_is-invalid {
  z-index: 4;
}

.GvlQTG_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_btn {
    transition: none;
  }
}

.GvlQTG_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.GvlQTG_btn-check + .GvlQTG_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.GvlQTG_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.GvlQTG_btn-check:focus-visible + .GvlQTG_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.GvlQTG_btn-check:checked + .GvlQTG_btn, :not(.GvlQTG_btn-check) + .GvlQTG_btn:active, .GvlQTG_btn:first-child:active, .GvlQTG_btn.GvlQTG_active, .GvlQTG_btn.GvlQTG_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.GvlQTG_btn-check:checked + .GvlQTG_btn:focus-visible, :not(.GvlQTG_btn-check) + .GvlQTG_btn:active:focus-visible, .GvlQTG_btn:first-child:active:focus-visible, .GvlQTG_btn.GvlQTG_active:focus-visible, .GvlQTG_btn.GvlQTG_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.GvlQTG_btn:disabled, .GvlQTG_btn.GvlQTG_disabled, fieldset:disabled .GvlQTG_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.GvlQTG_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.GvlQTG_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.GvlQTG_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.GvlQTG_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.GvlQTG_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.GvlQTG_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.GvlQTG_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.GvlQTG_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.GvlQTG_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.GvlQTG_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.GvlQTG_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.GvlQTG_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.GvlQTG_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.GvlQTG_btn-lg, .GvlQTG_btn-group-lg > .GvlQTG_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.GvlQTG_btn-sm, .GvlQTG_btn-group-sm > .GvlQTG_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.GvlQTG_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_fade {
    transition: none;
  }
}

.GvlQTG_fade:not(.GvlQTG_show) {
  opacity: 0;
}

.GvlQTG_collapse:not(.GvlQTG_show) {
  display: none;
}

.GvlQTG_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_collapsing {
    transition: none;
  }
}

.GvlQTG_collapsing.GvlQTG_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_collapsing.GvlQTG_collapse-horizontal {
    transition: none;
  }
}

.GvlQTG_dropup, .GvlQTG_dropend, .GvlQTG_dropdown, .GvlQTG_dropstart, .GvlQTG_dropup-center, .GvlQTG_dropdown-center {
  position: relative;
}

.GvlQTG_dropdown-toggle {
  white-space: nowrap;
}

.GvlQTG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.GvlQTG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.GvlQTG_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.GvlQTG_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.GvlQTG_dropdown-menu-start {
  --bs-position: start;
}

.GvlQTG_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.GvlQTG_dropdown-menu-end {
  --bs-position: end;
}

.GvlQTG_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .GvlQTG_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .GvlQTG_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .GvlQTG_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .GvlQTG_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .GvlQTG_dropdown-menu-md-start {
    --bs-position: start;
  }

  .GvlQTG_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .GvlQTG_dropdown-menu-md-end {
    --bs-position: end;
  }

  .GvlQTG_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .GvlQTG_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .GvlQTG_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .GvlQTG_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .GvlQTG_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .GvlQTG_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .GvlQTG_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .GvlQTG_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .GvlQTG_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .GvlQTG_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .GvlQTG_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.GvlQTG_dropup .GvlQTG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.GvlQTG_dropup .GvlQTG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.GvlQTG_dropup .GvlQTG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.GvlQTG_dropend .GvlQTG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.GvlQTG_dropend .GvlQTG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.GvlQTG_dropend .GvlQTG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.GvlQTG_dropend .GvlQTG_dropdown-toggle:after {
  vertical-align: 0;
}

.GvlQTG_dropstart .GvlQTG_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.GvlQTG_dropstart .GvlQTG_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.GvlQTG_dropstart .GvlQTG_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.GvlQTG_dropstart .GvlQTG_dropdown-toggle:empty:after {
  margin-left: 0;
}

.GvlQTG_dropstart .GvlQTG_dropdown-toggle:before {
  vertical-align: 0;
}

.GvlQTG_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.GvlQTG_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.GvlQTG_dropdown-item:hover, .GvlQTG_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.GvlQTG_dropdown-item.GvlQTG_active, .GvlQTG_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.GvlQTG_dropdown-item.GvlQTG_disabled, .GvlQTG_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.GvlQTG_dropdown-menu.GvlQTG_show {
  display: block;
}

.GvlQTG_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.GvlQTG_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.GvlQTG_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.GvlQTG_btn-group, .GvlQTG_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.GvlQTG_btn-group > .GvlQTG_btn, .GvlQTG_btn-group-vertical > .GvlQTG_btn {
  flex: auto;
  position: relative;
}

.GvlQTG_btn-group > .GvlQTG_btn-check:checked + .GvlQTG_btn, .GvlQTG_btn-group > .GvlQTG_btn-check:focus + .GvlQTG_btn, .GvlQTG_btn-group > .GvlQTG_btn:hover, .GvlQTG_btn-group > .GvlQTG_btn:focus, .GvlQTG_btn-group > .GvlQTG_btn:active, .GvlQTG_btn-group > .GvlQTG_btn.GvlQTG_active, .GvlQTG_btn-group-vertical > .GvlQTG_btn-check:checked + .GvlQTG_btn, .GvlQTG_btn-group-vertical > .GvlQTG_btn-check:focus + .GvlQTG_btn, .GvlQTG_btn-group-vertical > .GvlQTG_btn:hover, .GvlQTG_btn-group-vertical > .GvlQTG_btn:focus, .GvlQTG_btn-group-vertical > .GvlQTG_btn:active, .GvlQTG_btn-group-vertical > .GvlQTG_btn.GvlQTG_active {
  z-index: 1;
}

.GvlQTG_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.GvlQTG_btn-toolbar .GvlQTG_input-group {
  width: auto;
}

.GvlQTG_btn-group {
  border-radius: .375rem;
}

.GvlQTG_btn-group > :not(.GvlQTG_btn-check:first-child) + .GvlQTG_btn, .GvlQTG_btn-group > .GvlQTG_btn-group:not(:first-child) {
  margin-left: -1px;
}

.GvlQTG_btn-group > .GvlQTG_btn:not(:last-child):not(.GvlQTG_dropdown-toggle), .GvlQTG_btn-group > .GvlQTG_btn.GvlQTG_dropdown-toggle-split:first-child, .GvlQTG_btn-group > .GvlQTG_btn-group:not(:last-child) > .GvlQTG_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.GvlQTG_btn-group > .GvlQTG_btn:nth-child(n+3), .GvlQTG_btn-group > :not(.GvlQTG_btn-check) + .GvlQTG_btn, .GvlQTG_btn-group > .GvlQTG_btn-group:not(:first-child) > .GvlQTG_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.GvlQTG_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.GvlQTG_dropdown-toggle-split:after, .GvlQTG_dropup .GvlQTG_dropdown-toggle-split:after, .GvlQTG_dropend .GvlQTG_dropdown-toggle-split:after {
  margin-left: 0;
}

.GvlQTG_dropstart .GvlQTG_dropdown-toggle-split:before {
  margin-right: 0;
}

.GvlQTG_btn-sm + .GvlQTG_dropdown-toggle-split, .GvlQTG_btn-group-sm > .GvlQTG_btn + .GvlQTG_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.GvlQTG_btn-lg + .GvlQTG_dropdown-toggle-split, .GvlQTG_btn-group-lg > .GvlQTG_btn + .GvlQTG_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.GvlQTG_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.GvlQTG_btn-group-vertical > .GvlQTG_btn, .GvlQTG_btn-group-vertical > .GvlQTG_btn-group {
  width: 100%;
}

.GvlQTG_btn-group-vertical > .GvlQTG_btn:not(:first-child), .GvlQTG_btn-group-vertical > .GvlQTG_btn-group:not(:first-child) {
  margin-top: -1px;
}

.GvlQTG_btn-group-vertical > .GvlQTG_btn:not(:last-child):not(.GvlQTG_dropdown-toggle), .GvlQTG_btn-group-vertical > .GvlQTG_btn-group:not(:last-child) > .GvlQTG_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.GvlQTG_btn-group-vertical > .GvlQTG_btn ~ .GvlQTG_btn, .GvlQTG_btn-group-vertical > .GvlQTG_btn-group:not(:first-child) > .GvlQTG_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.GvlQTG_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.GvlQTG_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_nav-link {
    transition: none;
  }
}

.GvlQTG_nav-link:hover, .GvlQTG_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.GvlQTG_nav-link.GvlQTG_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.GvlQTG_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.GvlQTG_nav-tabs .GvlQTG_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.GvlQTG_nav-tabs .GvlQTG_nav-link:hover, .GvlQTG_nav-tabs .GvlQTG_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.GvlQTG_nav-tabs .GvlQTG_nav-link.GvlQTG_disabled, .GvlQTG_nav-tabs .GvlQTG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.GvlQTG_nav-tabs .GvlQTG_nav-link.GvlQTG_active, .GvlQTG_nav-tabs .GvlQTG_nav-item.GvlQTG_show .GvlQTG_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.GvlQTG_nav-tabs .GvlQTG_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.GvlQTG_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.GvlQTG_nav-pills .GvlQTG_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.GvlQTG_nav-pills .GvlQTG_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.GvlQTG_nav-pills .GvlQTG_nav-link.GvlQTG_active, .GvlQTG_nav-pills .GvlQTG_show > .GvlQTG_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.GvlQTG_nav-fill > .GvlQTG_nav-link, .GvlQTG_nav-fill .GvlQTG_nav-item {
  text-align: center;
  flex: auto;
}

.GvlQTG_nav-justified > .GvlQTG_nav-link, .GvlQTG_nav-justified .GvlQTG_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.GvlQTG_nav-fill .GvlQTG_nav-item .GvlQTG_nav-link, .GvlQTG_nav-justified .GvlQTG_nav-item .GvlQTG_nav-link {
  width: 100%;
}

.GvlQTG_tab-content > .GvlQTG_tab-pane {
  display: none;
}

.GvlQTG_tab-content > .GvlQTG_active {
  display: block;
}

.GvlQTG_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.GvlQTG_navbar > .GvlQTG_container, .GvlQTG_navbar > .GvlQTG_container-fluid, .GvlQTG_navbar > .GvlQTG_container-sm, .GvlQTG_navbar > .GvlQTG_container-md, .GvlQTG_navbar > .GvlQTG_container-lg, .GvlQTG_navbar > .GvlQTG_container-xl, .GvlQTG_navbar > .GvlQTG_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.GvlQTG_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.GvlQTG_navbar-brand:hover, .GvlQTG_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.GvlQTG_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.GvlQTG_navbar-nav .GvlQTG_show > .GvlQTG_nav-link, .GvlQTG_navbar-nav .GvlQTG_nav-link.GvlQTG_active {
  color: var(--bs-navbar-active-color);
}

.GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
  position: static;
}

.GvlQTG_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.GvlQTG_navbar-text a, .GvlQTG_navbar-text a:hover, .GvlQTG_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.GvlQTG_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.GvlQTG_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_navbar-toggler {
    transition: none;
  }
}

.GvlQTG_navbar-toggler:hover {
  text-decoration: none;
}

.GvlQTG_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.GvlQTG_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.GvlQTG_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .GvlQTG_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-nav {
    flex-direction: row;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
    position: absolute;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-nav .GvlQTG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-nav-scroll {
    overflow: visible;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_navbar-toggler {
    display: none;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_navbar-expand-sm .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .GvlQTG_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-nav {
    flex-direction: row;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
    position: absolute;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-nav .GvlQTG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-nav-scroll {
    overflow: visible;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_navbar-toggler {
    display: none;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_navbar-expand-md .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .GvlQTG_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-nav {
    flex-direction: row;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
    position: absolute;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-nav .GvlQTG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-nav-scroll {
    overflow: visible;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_navbar-toggler {
    display: none;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_navbar-expand-lg .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-nav {
    flex-direction: row;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
    position: absolute;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-nav .GvlQTG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-nav-scroll {
    overflow: visible;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_navbar-toggler {
    display: none;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_navbar-expand-xl .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-nav {
    flex-direction: row;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
    position: absolute;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-nav .GvlQTG_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-nav-scroll {
    overflow: visible;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_navbar-toggler {
    display: none;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_navbar-expand-xxl .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.GvlQTG_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.GvlQTG_navbar-expand .GvlQTG_navbar-nav {
  flex-direction: row;
}

.GvlQTG_navbar-expand .GvlQTG_navbar-nav .GvlQTG_dropdown-menu {
  position: absolute;
}

.GvlQTG_navbar-expand .GvlQTG_navbar-nav .GvlQTG_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.GvlQTG_navbar-expand .GvlQTG_navbar-nav-scroll {
  overflow: visible;
}

.GvlQTG_navbar-expand .GvlQTG_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.GvlQTG_navbar-expand .GvlQTG_navbar-toggler {
  display: none;
}

.GvlQTG_navbar-expand .GvlQTG_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.GvlQTG_navbar-expand .GvlQTG_offcanvas .GvlQTG_offcanvas-header {
  display: none;
}

.GvlQTG_navbar-expand .GvlQTG_offcanvas .GvlQTG_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.GvlQTG_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.GvlQTG_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.GvlQTG_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.GvlQTG_card > .GvlQTG_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.GvlQTG_card > .GvlQTG_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.GvlQTG_card > .GvlQTG_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.GvlQTG_card > .GvlQTG_card-header + .GvlQTG_list-group, .GvlQTG_card > .GvlQTG_list-group + .GvlQTG_card-footer {
  border-top: 0;
}

.GvlQTG_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.GvlQTG_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.GvlQTG_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.GvlQTG_card-text:last-child {
  margin-bottom: 0;
}

.GvlQTG_card-link + .GvlQTG_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.GvlQTG_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.GvlQTG_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.GvlQTG_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.GvlQTG_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.GvlQTG_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.GvlQTG_card-header-tabs .GvlQTG_nav-link.GvlQTG_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.GvlQTG_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.GvlQTG_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.GvlQTG_card-img, .GvlQTG_card-img-top, .GvlQTG_card-img-bottom {
  width: 100%;
}

.GvlQTG_card-img, .GvlQTG_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.GvlQTG_card-img, .GvlQTG_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.GvlQTG_card-group > .GvlQTG_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .GvlQTG_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .GvlQTG_card-group > .GvlQTG_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card + .GvlQTG_card {
    border-left: 0;
    margin-left: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:last-child) .GvlQTG_card-img-top, .GvlQTG_card-group > .GvlQTG_card:not(:last-child) .GvlQTG_card-header {
    border-top-right-radius: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:last-child) .GvlQTG_card-img-bottom, .GvlQTG_card-group > .GvlQTG_card:not(:last-child) .GvlQTG_card-footer {
    border-bottom-right-radius: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:first-child) .GvlQTG_card-img-top, .GvlQTG_card-group > .GvlQTG_card:not(:first-child) .GvlQTG_card-header {
    border-top-left-radius: 0;
  }

  .GvlQTG_card-group > .GvlQTG_card:not(:first-child) .GvlQTG_card-img-bottom, .GvlQTG_card-group > .GvlQTG_card:not(:first-child) .GvlQTG_card-footer {
    border-bottom-left-radius: 0;
  }
}

.GvlQTG_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.GvlQTG_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_accordion-button {
    transition: none;
  }
}

.GvlQTG_accordion-button:not(.GvlQTG_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.GvlQTG_accordion-button:not(.GvlQTG_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.GvlQTG_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_accordion-button:after {
    transition: none;
  }
}

.GvlQTG_accordion-button:hover {
  z-index: 2;
}

.GvlQTG_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.GvlQTG_accordion-header {
  margin-bottom: 0;
}

.GvlQTG_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.GvlQTG_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.GvlQTG_accordion-item:first-of-type .GvlQTG_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.GvlQTG_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.GvlQTG_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.GvlQTG_accordion-item:last-of-type .GvlQTG_accordion-button.GvlQTG_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.GvlQTG_accordion-item:last-of-type .GvlQTG_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.GvlQTG_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.GvlQTG_accordion-flush .GvlQTG_accordion-collapse {
  border-width: 0;
}

.GvlQTG_accordion-flush .GvlQTG_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.GvlQTG_accordion-flush .GvlQTG_accordion-item:first-child {
  border-top: 0;
}

.GvlQTG_accordion-flush .GvlQTG_accordion-item:last-child {
  border-bottom: 0;
}

.GvlQTG_accordion-flush .GvlQTG_accordion-item .GvlQTG_accordion-button, .GvlQTG_accordion-flush .GvlQTG_accordion-item .GvlQTG_accordion-button.GvlQTG_collapsed {
  border-radius: 0;
}

.GvlQTG_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.GvlQTG_breadcrumb-item + .GvlQTG_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.GvlQTG_breadcrumb-item + .GvlQTG_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.GvlQTG_breadcrumb-item.GvlQTG_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.GvlQTG_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.GvlQTG_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_page-link {
    transition: none;
  }
}

.GvlQTG_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.GvlQTG_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.GvlQTG_page-link.GvlQTG_active, .GvlQTG_active > .GvlQTG_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.GvlQTG_page-link.GvlQTG_disabled, .GvlQTG_disabled > .GvlQTG_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.GvlQTG_page-item:not(:first-child) .GvlQTG_page-link {
  margin-left: -1px;
}

.GvlQTG_page-item:first-child .GvlQTG_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.GvlQTG_page-item:last-child .GvlQTG_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.GvlQTG_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.GvlQTG_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.GvlQTG_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.GvlQTG_badge:empty {
  display: none;
}

.GvlQTG_btn .GvlQTG_badge {
  position: relative;
  top: -1px;
}

.GvlQTG_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.GvlQTG_alert-heading {
  color: inherit;
}

.GvlQTG_alert-link {
  font-weight: 700;
}

.GvlQTG_alert-dismissible {
  padding-right: 3rem;
}

.GvlQTG_alert-dismissible .GvlQTG_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.GvlQTG_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.GvlQTG_alert-primary .GvlQTG_alert-link {
  color: #25224b;
}

.GvlQTG_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.GvlQTG_alert-secondary .GvlQTG_alert-link {
  color: #34383c;
}

.GvlQTG_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.GvlQTG_alert-success .GvlQTG_alert-link {
  color: #0c4128;
}

.GvlQTG_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.GvlQTG_alert-info .GvlQTG_alert-link {
  color: #04414d;
}

.GvlQTG_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.GvlQTG_alert-warning .GvlQTG_alert-link {
  color: #523e02;
}

.GvlQTG_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.GvlQTG_alert-danger .GvlQTG_alert-link {
  color: #6a1a21;
}

.GvlQTG_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.GvlQTG_alert-light .GvlQTG_alert-link {
  color: #4f5050;
}

.GvlQTG_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.GvlQTG_alert-dark .GvlQTG_alert-link {
  color: #101214;
}

@keyframes GvlQTG_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.GvlQTG_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.GvlQTG_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_progress-bar {
    transition: none;
  }
}

.GvlQTG_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.GvlQTG_progress-bar-animated {
  animation: 1s linear infinite GvlQTG_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_progress-bar-animated {
    animation: none;
  }
}

.GvlQTG_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.GvlQTG_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.GvlQTG_list-group-numbered > .GvlQTG_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.GvlQTG_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.GvlQTG_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.GvlQTG_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.GvlQTG_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.GvlQTG_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.GvlQTG_list-group-item.GvlQTG_disabled, .GvlQTG_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.GvlQTG_list-group-item.GvlQTG_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.GvlQTG_list-group-item + .GvlQTG_list-group-item {
  border-top-width: 0;
}

.GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.GvlQTG_list-group-horizontal {
  flex-direction: row;
}

.GvlQTG_list-group-horizontal > .GvlQTG_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.GvlQTG_list-group-horizontal > .GvlQTG_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.GvlQTG_list-group-horizontal > .GvlQTG_list-group-item.GvlQTG_active {
  margin-top: 0;
}

.GvlQTG_list-group-horizontal > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.GvlQTG_list-group-horizontal > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .GvlQTG_list-group-horizontal-sm {
    flex-direction: row;
  }

  .GvlQTG_list-group-horizontal-sm > .GvlQTG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .GvlQTG_list-group-horizontal-sm > .GvlQTG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .GvlQTG_list-group-horizontal-sm > .GvlQTG_list-group-item.GvlQTG_active {
    margin-top: 0;
  }

  .GvlQTG_list-group-horizontal-sm > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .GvlQTG_list-group-horizontal-sm > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .GvlQTG_list-group-horizontal-md {
    flex-direction: row;
  }

  .GvlQTG_list-group-horizontal-md > .GvlQTG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .GvlQTG_list-group-horizontal-md > .GvlQTG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .GvlQTG_list-group-horizontal-md > .GvlQTG_list-group-item.GvlQTG_active {
    margin-top: 0;
  }

  .GvlQTG_list-group-horizontal-md > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .GvlQTG_list-group-horizontal-md > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .GvlQTG_list-group-horizontal-lg {
    flex-direction: row;
  }

  .GvlQTG_list-group-horizontal-lg > .GvlQTG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .GvlQTG_list-group-horizontal-lg > .GvlQTG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .GvlQTG_list-group-horizontal-lg > .GvlQTG_list-group-item.GvlQTG_active {
    margin-top: 0;
  }

  .GvlQTG_list-group-horizontal-lg > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .GvlQTG_list-group-horizontal-lg > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .GvlQTG_list-group-horizontal-xl {
    flex-direction: row;
  }

  .GvlQTG_list-group-horizontal-xl > .GvlQTG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .GvlQTG_list-group-horizontal-xl > .GvlQTG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .GvlQTG_list-group-horizontal-xl > .GvlQTG_list-group-item.GvlQTG_active {
    margin-top: 0;
  }

  .GvlQTG_list-group-horizontal-xl > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .GvlQTG_list-group-horizontal-xl > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .GvlQTG_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .GvlQTG_list-group-horizontal-xxl > .GvlQTG_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .GvlQTG_list-group-horizontal-xxl > .GvlQTG_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .GvlQTG_list-group-horizontal-xxl > .GvlQTG_list-group-item.GvlQTG_active {
    margin-top: 0;
  }

  .GvlQTG_list-group-horizontal-xxl > .GvlQTG_list-group-item + .GvlQTG_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .GvlQTG_list-group-horizontal-xxl > .GvlQTG_list-group-item + .GvlQTG_list-group-item.GvlQTG_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.GvlQTG_list-group-flush {
  border-radius: 0;
}

.GvlQTG_list-group-flush > .GvlQTG_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.GvlQTG_list-group-flush > .GvlQTG_list-group-item:last-child {
  border-bottom-width: 0;
}

.GvlQTG_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.GvlQTG_list-group-item-primary.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-primary.GvlQTG_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.GvlQTG_list-group-item-primary.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.GvlQTG_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.GvlQTG_list-group-item-secondary.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-secondary.GvlQTG_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.GvlQTG_list-group-item-secondary.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.GvlQTG_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.GvlQTG_list-group-item-success.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-success.GvlQTG_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.GvlQTG_list-group-item-success.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.GvlQTG_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.GvlQTG_list-group-item-info.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-info.GvlQTG_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.GvlQTG_list-group-item-info.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.GvlQTG_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.GvlQTG_list-group-item-warning.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-warning.GvlQTG_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.GvlQTG_list-group-item-warning.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.GvlQTG_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.GvlQTG_list-group-item-danger.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-danger.GvlQTG_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.GvlQTG_list-group-item-danger.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.GvlQTG_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.GvlQTG_list-group-item-light.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-light.GvlQTG_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.GvlQTG_list-group-item-light.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.GvlQTG_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.GvlQTG_list-group-item-dark.GvlQTG_list-group-item-action:hover, .GvlQTG_list-group-item-dark.GvlQTG_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.GvlQTG_list-group-item-dark.GvlQTG_list-group-item-action.GvlQTG_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.GvlQTG_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.GvlQTG_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.GvlQTG_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.GvlQTG_btn-close:disabled, .GvlQTG_btn-close.GvlQTG_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.GvlQTG_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.GvlQTG_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.GvlQTG_toast.GvlQTG_showing {
  opacity: 0;
}

.GvlQTG_toast:not(.GvlQTG_show) {
  display: none;
}

.GvlQTG_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.GvlQTG_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.GvlQTG_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.GvlQTG_toast-header .GvlQTG_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.GvlQTG_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.GvlQTG_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.GvlQTG_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.GvlQTG_modal.GvlQTG_fade .GvlQTG_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_modal.GvlQTG_fade .GvlQTG_modal-dialog {
    transition: none;
  }
}

.GvlQTG_modal.GvlQTG_show .GvlQTG_modal-dialog {
  transform: none;
}

.GvlQTG_modal.GvlQTG_modal-static .GvlQTG_modal-dialog {
  transform: scale(1.02);
}

.GvlQTG_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.GvlQTG_modal-dialog-scrollable .GvlQTG_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.GvlQTG_modal-dialog-scrollable .GvlQTG_modal-body {
  overflow-y: auto;
}

.GvlQTG_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.GvlQTG_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.GvlQTG_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.GvlQTG_modal-backdrop.GvlQTG_fade {
  opacity: 0;
}

.GvlQTG_modal-backdrop.GvlQTG_show {
  opacity: var(--bs-backdrop-opacity);
}

.GvlQTG_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.GvlQTG_modal-header .GvlQTG_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.GvlQTG_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.GvlQTG_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.GvlQTG_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.GvlQTG_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .GvlQTG_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .GvlQTG_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .GvlQTG_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .GvlQTG_modal-lg, .GvlQTG_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.GvlQTG_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.GvlQTG_modal-fullscreen .GvlQTG_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.GvlQTG_modal-fullscreen .GvlQTG_modal-header, .GvlQTG_modal-fullscreen .GvlQTG_modal-footer {
  border-radius: 0;
}

.GvlQTG_modal-fullscreen .GvlQTG_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .GvlQTG_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .GvlQTG_modal-fullscreen-sm-down .GvlQTG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-sm-down .GvlQTG_modal-header, .GvlQTG_modal-fullscreen-sm-down .GvlQTG_modal-footer {
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-sm-down .GvlQTG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .GvlQTG_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .GvlQTG_modal-fullscreen-md-down .GvlQTG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-md-down .GvlQTG_modal-header, .GvlQTG_modal-fullscreen-md-down .GvlQTG_modal-footer {
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-md-down .GvlQTG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .GvlQTG_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .GvlQTG_modal-fullscreen-lg-down .GvlQTG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-lg-down .GvlQTG_modal-header, .GvlQTG_modal-fullscreen-lg-down .GvlQTG_modal-footer {
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-lg-down .GvlQTG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .GvlQTG_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .GvlQTG_modal-fullscreen-xl-down .GvlQTG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-xl-down .GvlQTG_modal-header, .GvlQTG_modal-fullscreen-xl-down .GvlQTG_modal-footer {
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-xl-down .GvlQTG_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .GvlQTG_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .GvlQTG_modal-fullscreen-xxl-down .GvlQTG_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-xxl-down .GvlQTG_modal-header, .GvlQTG_modal-fullscreen-xxl-down .GvlQTG_modal-footer {
    border-radius: 0;
  }

  .GvlQTG_modal-fullscreen-xxl-down .GvlQTG_modal-body {
    overflow-y: auto;
  }
}

.GvlQTG_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.GvlQTG_tooltip.GvlQTG_show {
  opacity: var(--bs-tooltip-opacity);
}

.GvlQTG_tooltip .GvlQTG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.GvlQTG_tooltip .GvlQTG_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.GvlQTG_bs-tooltip-top .GvlQTG_tooltip-arrow, .GvlQTG_bs-tooltip-auto[data-popper-placement^="top"] .GvlQTG_tooltip-arrow {
  bottom: 0;
}

.GvlQTG_bs-tooltip-top .GvlQTG_tooltip-arrow:before, .GvlQTG_bs-tooltip-auto[data-popper-placement^="top"] .GvlQTG_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.GvlQTG_bs-tooltip-end .GvlQTG_tooltip-arrow, .GvlQTG_bs-tooltip-auto[data-popper-placement^="right"] .GvlQTG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.GvlQTG_bs-tooltip-end .GvlQTG_tooltip-arrow:before, .GvlQTG_bs-tooltip-auto[data-popper-placement^="right"] .GvlQTG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.GvlQTG_bs-tooltip-bottom .GvlQTG_tooltip-arrow, .GvlQTG_bs-tooltip-auto[data-popper-placement^="bottom"] .GvlQTG_tooltip-arrow {
  top: 0;
}

.GvlQTG_bs-tooltip-bottom .GvlQTG_tooltip-arrow:before, .GvlQTG_bs-tooltip-auto[data-popper-placement^="bottom"] .GvlQTG_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.GvlQTG_bs-tooltip-start .GvlQTG_tooltip-arrow, .GvlQTG_bs-tooltip-auto[data-popper-placement^="left"] .GvlQTG_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.GvlQTG_bs-tooltip-start .GvlQTG_tooltip-arrow:before, .GvlQTG_bs-tooltip-auto[data-popper-placement^="left"] .GvlQTG_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.GvlQTG_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.GvlQTG_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.GvlQTG_popover .GvlQTG_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.GvlQTG_popover .GvlQTG_popover-arrow:before, .GvlQTG_popover .GvlQTG_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.GvlQTG_bs-popover-top > .GvlQTG_popover-arrow, .GvlQTG_bs-popover-auto[data-popper-placement^="top"] > .GvlQTG_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.GvlQTG_bs-popover-top > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="top"] > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-top > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="top"] > .GvlQTG_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.GvlQTG_bs-popover-top > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="top"] > .GvlQTG_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.GvlQTG_bs-popover-top > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="top"] > .GvlQTG_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.GvlQTG_bs-popover-end > .GvlQTG_popover-arrow, .GvlQTG_bs-popover-auto[data-popper-placement^="right"] > .GvlQTG_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.GvlQTG_bs-popover-end > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="right"] > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-end > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="right"] > .GvlQTG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.GvlQTG_bs-popover-end > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="right"] > .GvlQTG_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.GvlQTG_bs-popover-end > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="right"] > .GvlQTG_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.GvlQTG_bs-popover-bottom > .GvlQTG_popover-arrow, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] > .GvlQTG_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.GvlQTG_bs-popover-bottom > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-bottom > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] > .GvlQTG_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.GvlQTG_bs-popover-bottom > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] > .GvlQTG_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.GvlQTG_bs-popover-bottom > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] > .GvlQTG_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.GvlQTG_bs-popover-bottom .GvlQTG_popover-header:before, .GvlQTG_bs-popover-auto[data-popper-placement^="bottom"] .GvlQTG_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.GvlQTG_bs-popover-start > .GvlQTG_popover-arrow, .GvlQTG_bs-popover-auto[data-popper-placement^="left"] > .GvlQTG_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.GvlQTG_bs-popover-start > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="left"] > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-start > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="left"] > .GvlQTG_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.GvlQTG_bs-popover-start > .GvlQTG_popover-arrow:before, .GvlQTG_bs-popover-auto[data-popper-placement^="left"] > .GvlQTG_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.GvlQTG_bs-popover-start > .GvlQTG_popover-arrow:after, .GvlQTG_bs-popover-auto[data-popper-placement^="left"] > .GvlQTG_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.GvlQTG_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.GvlQTG_popover-header:empty {
  display: none;
}

.GvlQTG_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.GvlQTG_carousel {
  position: relative;
}

.GvlQTG_carousel.GvlQTG_pointer-event {
  touch-action: pan-y;
}

.GvlQTG_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.GvlQTG_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.GvlQTG_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_carousel-item {
    transition: none;
  }
}

.GvlQTG_carousel-item.GvlQTG_active, .GvlQTG_carousel-item-next, .GvlQTG_carousel-item-prev {
  display: block;
}

.GvlQTG_carousel-item-next:not(.GvlQTG_carousel-item-start), .GvlQTG_active.GvlQTG_carousel-item-end {
  transform: translateX(100%);
}

.GvlQTG_carousel-item-prev:not(.GvlQTG_carousel-item-end), .GvlQTG_active.GvlQTG_carousel-item-start {
  transform: translateX(-100%);
}

.GvlQTG_carousel-fade .GvlQTG_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.GvlQTG_carousel-fade .GvlQTG_carousel-item.GvlQTG_active, .GvlQTG_carousel-fade .GvlQTG_carousel-item-next.GvlQTG_carousel-item-start, .GvlQTG_carousel-fade .GvlQTG_carousel-item-prev.GvlQTG_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.GvlQTG_carousel-fade .GvlQTG_active.GvlQTG_carousel-item-start, .GvlQTG_carousel-fade .GvlQTG_active.GvlQTG_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_carousel-fade .GvlQTG_active.GvlQTG_carousel-item-start, .GvlQTG_carousel-fade .GvlQTG_active.GvlQTG_carousel-item-end {
    transition: none;
  }
}

.GvlQTG_carousel-control-prev, .GvlQTG_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_carousel-control-prev, .GvlQTG_carousel-control-next {
    transition: none;
  }
}

.GvlQTG_carousel-control-prev:hover, .GvlQTG_carousel-control-prev:focus, .GvlQTG_carousel-control-next:hover, .GvlQTG_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.GvlQTG_carousel-control-prev {
  left: 0;
}

.GvlQTG_carousel-control-next {
  right: 0;
}

.GvlQTG_carousel-control-prev-icon, .GvlQTG_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.GvlQTG_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.GvlQTG_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.GvlQTG_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.GvlQTG_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.GvlQTG_carousel-indicators .GvlQTG_active {
  opacity: 1;
}

.GvlQTG_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.GvlQTG_carousel-dark .GvlQTG_carousel-control-prev-icon, .GvlQTG_carousel-dark .GvlQTG_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.GvlQTG_carousel-dark .GvlQTG_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.GvlQTG_carousel-dark .GvlQTG_carousel-caption {
  color: #000;
}

.GvlQTG_spinner-grow, .GvlQTG_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes GvlQTG_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.GvlQTG_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.GvlQTG_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes GvlQTG_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.GvlQTG_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.GvlQTG_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_spinner-border, .GvlQTG_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.GvlQTG_offcanvas, .GvlQTG_offcanvas-xxl, .GvlQTG_offcanvas-xl, .GvlQTG_offcanvas-lg, .GvlQTG_offcanvas-md, .GvlQTG_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .GvlQTG_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .GvlQTG_offcanvas-sm.GvlQTG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .GvlQTG_offcanvas-sm.GvlQTG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .GvlQTG_offcanvas-sm.GvlQTG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .GvlQTG_offcanvas-sm.GvlQTG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .GvlQTG_offcanvas-sm.GvlQTG_showing, .GvlQTG_offcanvas-sm.GvlQTG_show:not(.GvlQTG_hiding) {
    transform: none;
  }

  .GvlQTG_offcanvas-sm.GvlQTG_showing, .GvlQTG_offcanvas-sm.GvlQTG_hiding, .GvlQTG_offcanvas-sm.GvlQTG_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .GvlQTG_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .GvlQTG_offcanvas-sm .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_offcanvas-sm .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .GvlQTG_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .GvlQTG_offcanvas-md.GvlQTG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .GvlQTG_offcanvas-md.GvlQTG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .GvlQTG_offcanvas-md.GvlQTG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .GvlQTG_offcanvas-md.GvlQTG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .GvlQTG_offcanvas-md.GvlQTG_showing, .GvlQTG_offcanvas-md.GvlQTG_show:not(.GvlQTG_hiding) {
    transform: none;
  }

  .GvlQTG_offcanvas-md.GvlQTG_showing, .GvlQTG_offcanvas-md.GvlQTG_hiding, .GvlQTG_offcanvas-md.GvlQTG_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .GvlQTG_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .GvlQTG_offcanvas-md .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_offcanvas-md .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .GvlQTG_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .GvlQTG_offcanvas-lg.GvlQTG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .GvlQTG_offcanvas-lg.GvlQTG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .GvlQTG_offcanvas-lg.GvlQTG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .GvlQTG_offcanvas-lg.GvlQTG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .GvlQTG_offcanvas-lg.GvlQTG_showing, .GvlQTG_offcanvas-lg.GvlQTG_show:not(.GvlQTG_hiding) {
    transform: none;
  }

  .GvlQTG_offcanvas-lg.GvlQTG_showing, .GvlQTG_offcanvas-lg.GvlQTG_hiding, .GvlQTG_offcanvas-lg.GvlQTG_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .GvlQTG_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .GvlQTG_offcanvas-lg .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_offcanvas-lg .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .GvlQTG_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .GvlQTG_offcanvas-xl.GvlQTG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .GvlQTG_offcanvas-xl.GvlQTG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .GvlQTG_offcanvas-xl.GvlQTG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .GvlQTG_offcanvas-xl.GvlQTG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .GvlQTG_offcanvas-xl.GvlQTG_showing, .GvlQTG_offcanvas-xl.GvlQTG_show:not(.GvlQTG_hiding) {
    transform: none;
  }

  .GvlQTG_offcanvas-xl.GvlQTG_showing, .GvlQTG_offcanvas-xl.GvlQTG_hiding, .GvlQTG_offcanvas-xl.GvlQTG_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .GvlQTG_offcanvas-xl .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_offcanvas-xl .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .GvlQTG_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .GvlQTG_offcanvas-xxl.GvlQTG_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .GvlQTG_offcanvas-xxl.GvlQTG_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .GvlQTG_offcanvas-xxl.GvlQTG_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .GvlQTG_offcanvas-xxl.GvlQTG_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .GvlQTG_offcanvas-xxl.GvlQTG_showing, .GvlQTG_offcanvas-xxl.GvlQTG_show:not(.GvlQTG_hiding) {
    transform: none;
  }

  .GvlQTG_offcanvas-xxl.GvlQTG_showing, .GvlQTG_offcanvas-xxl.GvlQTG_hiding, .GvlQTG_offcanvas-xxl.GvlQTG_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .GvlQTG_offcanvas-xxl .GvlQTG_offcanvas-header {
    display: none;
  }

  .GvlQTG_offcanvas-xxl .GvlQTG_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.GvlQTG_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .GvlQTG_offcanvas {
    transition: none;
  }
}

.GvlQTG_offcanvas.GvlQTG_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.GvlQTG_offcanvas.GvlQTG_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.GvlQTG_offcanvas.GvlQTG_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.GvlQTG_offcanvas.GvlQTG_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.GvlQTG_offcanvas.GvlQTG_showing, .GvlQTG_offcanvas.GvlQTG_show:not(.GvlQTG_hiding) {
  transform: none;
}

.GvlQTG_offcanvas.GvlQTG_showing, .GvlQTG_offcanvas.GvlQTG_hiding, .GvlQTG_offcanvas.GvlQTG_show {
  visibility: visible;
}

.GvlQTG_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.GvlQTG_offcanvas-backdrop.GvlQTG_fade {
  opacity: 0;
}

.GvlQTG_offcanvas-backdrop.GvlQTG_show {
  opacity: .5;
}

.GvlQTG_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.GvlQTG_offcanvas-header .GvlQTG_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.GvlQTG_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.GvlQTG_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.GvlQTG_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.GvlQTG_placeholder.GvlQTG_btn:before {
  content: "";
  display: inline-block;
}

.GvlQTG_placeholder-xs {
  min-height: .6em;
}

.GvlQTG_placeholder-sm {
  min-height: .8em;
}

.GvlQTG_placeholder-lg {
  min-height: 1.2em;
}

.GvlQTG_placeholder-glow .GvlQTG_placeholder {
  animation: 2s ease-in-out infinite GvlQTG_placeholder-glow;
}

@keyframes GvlQTG_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.GvlQTG_placeholder-wave {
  animation: 2s linear infinite GvlQTG_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes GvlQTG_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.GvlQTG_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.GvlQTG_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.GvlQTG_link-primary {
  color: #4c469c !important;
}

.GvlQTG_link-primary:hover, .GvlQTG_link-primary:focus {
  color: #3d387d !important;
}

.GvlQTG_link-secondary {
  color: #6c757d !important;
}

.GvlQTG_link-secondary:hover, .GvlQTG_link-secondary:focus {
  color: #565e64 !important;
}

.GvlQTG_link-success {
  color: #198754 !important;
}

.GvlQTG_link-success:hover, .GvlQTG_link-success:focus {
  color: #146c43 !important;
}

.GvlQTG_link-info {
  color: #0dcaf0 !important;
}

.GvlQTG_link-info:hover, .GvlQTG_link-info:focus {
  color: #3dd5f3 !important;
}

.GvlQTG_link-warning {
  color: #ffc107 !important;
}

.GvlQTG_link-warning:hover, .GvlQTG_link-warning:focus {
  color: #ffcd39 !important;
}

.GvlQTG_link-danger {
  color: #dc3545 !important;
}

.GvlQTG_link-danger:hover, .GvlQTG_link-danger:focus {
  color: #b02a37 !important;
}

.GvlQTG_link-light {
  color: #f8f9fa !important;
}

.GvlQTG_link-light:hover, .GvlQTG_link-light:focus {
  color: #f9fafb !important;
}

.GvlQTG_link-dark {
  color: #212529 !important;
}

.GvlQTG_link-dark:hover, .GvlQTG_link-dark:focus {
  color: #1a1e21 !important;
}

.GvlQTG_ratio {
  width: 100%;
  position: relative;
}

.GvlQTG_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.GvlQTG_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.GvlQTG_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.GvlQTG_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.GvlQTG_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.GvlQTG_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.GvlQTG_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.GvlQTG_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.GvlQTG_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.GvlQTG_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .GvlQTG_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .GvlQTG_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .GvlQTG_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .GvlQTG_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .GvlQTG_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .GvlQTG_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .GvlQTG_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .GvlQTG_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.GvlQTG_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.GvlQTG_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.GvlQTG_visually-hidden, .GvlQTG_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.GvlQTG_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.GvlQTG_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.GvlQTG_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.GvlQTG_align-baseline {
  vertical-align: baseline !important;
}

.GvlQTG_align-top {
  vertical-align: top !important;
}

.GvlQTG_align-middle {
  vertical-align: middle !important;
}

.GvlQTG_align-bottom {
  vertical-align: bottom !important;
}

.GvlQTG_align-text-bottom {
  vertical-align: text-bottom !important;
}

.GvlQTG_align-text-top {
  vertical-align: text-top !important;
}

.GvlQTG_float-start {
  float: left !important;
}

.GvlQTG_float-end {
  float: right !important;
}

.GvlQTG_float-none {
  float: none !important;
}

.GvlQTG_opacity-0 {
  opacity: 0 !important;
}

.GvlQTG_opacity-25 {
  opacity: .25 !important;
}

.GvlQTG_opacity-50 {
  opacity: .5 !important;
}

.GvlQTG_opacity-75 {
  opacity: .75 !important;
}

.GvlQTG_opacity-100 {
  opacity: 1 !important;
}

.GvlQTG_overflow-auto {
  overflow: auto !important;
}

.GvlQTG_overflow-hidden {
  overflow: hidden !important;
}

.GvlQTG_overflow-visible {
  overflow: visible !important;
}

.GvlQTG_overflow-scroll {
  overflow: scroll !important;
}

.GvlQTG_d-inline {
  display: inline !important;
}

.GvlQTG_d-inline-block {
  display: inline-block !important;
}

.GvlQTG_d-block {
  display: block !important;
}

.GvlQTG_d-grid {
  display: grid !important;
}

.GvlQTG_d-table {
  display: table !important;
}

.GvlQTG_d-table-row {
  display: table-row !important;
}

.GvlQTG_d-table-cell {
  display: table-cell !important;
}

.GvlQTG_d-flex {
  display: flex !important;
}

.GvlQTG_d-inline-flex {
  display: inline-flex !important;
}

.GvlQTG_d-none {
  display: none !important;
}

.GvlQTG_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.GvlQTG_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.GvlQTG_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.GvlQTG_shadow-none {
  box-shadow: none !important;
}

.GvlQTG_position-static {
  position: static !important;
}

.GvlQTG_position-relative {
  position: relative !important;
}

.GvlQTG_position-absolute {
  position: absolute !important;
}

.GvlQTG_position-fixed {
  position: fixed !important;
}

.GvlQTG_position-sticky {
  position: sticky !important;
}

.GvlQTG_top-0 {
  top: 0 !important;
}

.GvlQTG_top-50 {
  top: 50% !important;
}

.GvlQTG_top-100 {
  top: 100% !important;
}

.GvlQTG_bottom-0 {
  bottom: 0 !important;
}

.GvlQTG_bottom-50 {
  bottom: 50% !important;
}

.GvlQTG_bottom-100 {
  bottom: 100% !important;
}

.GvlQTG_start-0 {
  left: 0 !important;
}

.GvlQTG_start-50 {
  left: 50% !important;
}

.GvlQTG_start-100 {
  left: 100% !important;
}

.GvlQTG_end-0 {
  right: 0 !important;
}

.GvlQTG_end-50 {
  right: 50% !important;
}

.GvlQTG_end-100 {
  right: 100% !important;
}

.GvlQTG_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.GvlQTG_translate-middle-x {
  transform: translateX(-50%) !important;
}

.GvlQTG_translate-middle-y {
  transform: translateY(-50%) !important;
}

.GvlQTG_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.GvlQTG_border-0 {
  border: 0 !important;
}

.GvlQTG_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.GvlQTG_border-top-0 {
  border-top: 0 !important;
}

.GvlQTG_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.GvlQTG_border-end-0 {
  border-right: 0 !important;
}

.GvlQTG_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.GvlQTG_border-bottom-0 {
  border-bottom: 0 !important;
}

.GvlQTG_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.GvlQTG_border-start-0 {
  border-left: 0 !important;
}

.GvlQTG_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.GvlQTG_border-1 {
  --bs-border-width: 1px;
}

.GvlQTG_border-2 {
  --bs-border-width: 2px;
}

.GvlQTG_border-3 {
  --bs-border-width: 3px;
}

.GvlQTG_border-4 {
  --bs-border-width: 4px;
}

.GvlQTG_border-5 {
  --bs-border-width: 5px;
}

.GvlQTG_border-opacity-10 {
  --bs-border-opacity: .1;
}

.GvlQTG_border-opacity-25 {
  --bs-border-opacity: .25;
}

.GvlQTG_border-opacity-50 {
  --bs-border-opacity: .5;
}

.GvlQTG_border-opacity-75 {
  --bs-border-opacity: .75;
}

.GvlQTG_border-opacity-100 {
  --bs-border-opacity: 1;
}

.GvlQTG_w-25 {
  width: 25% !important;
}

.GvlQTG_w-50 {
  width: 50% !important;
}

.GvlQTG_w-75 {
  width: 75% !important;
}

.GvlQTG_w-100 {
  width: 100% !important;
}

.GvlQTG_w-auto {
  width: auto !important;
}

.GvlQTG_mw-100 {
  max-width: 100% !important;
}

.GvlQTG_vw-100 {
  width: 100vw !important;
}

.GvlQTG_min-vw-100 {
  min-width: 100vw !important;
}

.GvlQTG_h-25 {
  height: 25% !important;
}

.GvlQTG_h-50 {
  height: 50% !important;
}

.GvlQTG_h-75 {
  height: 75% !important;
}

.GvlQTG_h-100 {
  height: 100% !important;
}

.GvlQTG_h-auto {
  height: auto !important;
}

.GvlQTG_mh-100 {
  max-height: 100% !important;
}

.GvlQTG_vh-100 {
  height: 100vh !important;
}

.GvlQTG_min-vh-100 {
  min-height: 100vh !important;
}

.GvlQTG_flex-fill {
  flex: auto !important;
}

.GvlQTG_flex-row {
  flex-direction: row !important;
}

.GvlQTG_flex-column {
  flex-direction: column !important;
}

.GvlQTG_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.GvlQTG_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.GvlQTG_flex-grow-0 {
  flex-grow: 0 !important;
}

.GvlQTG_flex-grow-1 {
  flex-grow: 1 !important;
}

.GvlQTG_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.GvlQTG_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.GvlQTG_flex-wrap {
  flex-wrap: wrap !important;
}

.GvlQTG_flex-nowrap {
  flex-wrap: nowrap !important;
}

.GvlQTG_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.GvlQTG_justify-content-start {
  justify-content: flex-start !important;
}

.GvlQTG_justify-content-end {
  justify-content: flex-end !important;
}

.GvlQTG_justify-content-center {
  justify-content: center !important;
}

.GvlQTG_justify-content-between {
  justify-content: space-between !important;
}

.GvlQTG_justify-content-around {
  justify-content: space-around !important;
}

.GvlQTG_justify-content-evenly {
  justify-content: space-evenly !important;
}

.GvlQTG_align-items-start {
  align-items: flex-start !important;
}

.GvlQTG_align-items-end {
  align-items: flex-end !important;
}

.GvlQTG_align-items-center {
  align-items: center !important;
}

.GvlQTG_align-items-baseline {
  align-items: baseline !important;
}

.GvlQTG_align-items-stretch {
  align-items: stretch !important;
}

.GvlQTG_align-content-start {
  align-content: flex-start !important;
}

.GvlQTG_align-content-end {
  align-content: flex-end !important;
}

.GvlQTG_align-content-center {
  align-content: center !important;
}

.GvlQTG_align-content-between {
  align-content: space-between !important;
}

.GvlQTG_align-content-around {
  align-content: space-around !important;
}

.GvlQTG_align-content-stretch {
  align-content: stretch !important;
}

.GvlQTG_align-self-auto {
  align-self: auto !important;
}

.GvlQTG_align-self-start {
  align-self: flex-start !important;
}

.GvlQTG_align-self-end {
  align-self: flex-end !important;
}

.GvlQTG_align-self-center {
  align-self: center !important;
}

.GvlQTG_align-self-baseline {
  align-self: baseline !important;
}

.GvlQTG_align-self-stretch {
  align-self: stretch !important;
}

.GvlQTG_order-first {
  order: -1 !important;
}

.GvlQTG_order-0 {
  order: 0 !important;
}

.GvlQTG_order-1 {
  order: 1 !important;
}

.GvlQTG_order-2 {
  order: 2 !important;
}

.GvlQTG_order-3 {
  order: 3 !important;
}

.GvlQTG_order-4 {
  order: 4 !important;
}

.GvlQTG_order-5 {
  order: 5 !important;
}

.GvlQTG_order-last {
  order: 6 !important;
}

.GvlQTG_m-0 {
  margin: 0 !important;
}

.GvlQTG_m-1 {
  margin: .25rem !important;
}

.GvlQTG_m-2 {
  margin: .5rem !important;
}

.GvlQTG_m-3 {
  margin: 1rem !important;
}

.GvlQTG_m-4 {
  margin: 1.5rem !important;
}

.GvlQTG_m-5 {
  margin: 3rem !important;
}

.GvlQTG_m-auto {
  margin: auto !important;
}

.GvlQTG_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.GvlQTG_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.GvlQTG_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.GvlQTG_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.GvlQTG_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.GvlQTG_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.GvlQTG_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.GvlQTG_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.GvlQTG_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.GvlQTG_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.GvlQTG_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.GvlQTG_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.GvlQTG_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.GvlQTG_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.GvlQTG_mt-0 {
  margin-top: 0 !important;
}

.GvlQTG_mt-1 {
  margin-top: .25rem !important;
}

.GvlQTG_mt-2 {
  margin-top: .5rem !important;
}

.GvlQTG_mt-3 {
  margin-top: 1rem !important;
}

.GvlQTG_mt-4 {
  margin-top: 1.5rem !important;
}

.GvlQTG_mt-5 {
  margin-top: 3rem !important;
}

.GvlQTG_mt-auto {
  margin-top: auto !important;
}

.GvlQTG_me-0 {
  margin-right: 0 !important;
}

.GvlQTG_me-1 {
  margin-right: .25rem !important;
}

.GvlQTG_me-2 {
  margin-right: .5rem !important;
}

.GvlQTG_me-3 {
  margin-right: 1rem !important;
}

.GvlQTG_me-4 {
  margin-right: 1.5rem !important;
}

.GvlQTG_me-5 {
  margin-right: 3rem !important;
}

.GvlQTG_me-auto {
  margin-right: auto !important;
}

.GvlQTG_mb-0 {
  margin-bottom: 0 !important;
}

.GvlQTG_mb-1 {
  margin-bottom: .25rem !important;
}

.GvlQTG_mb-2 {
  margin-bottom: .5rem !important;
}

.GvlQTG_mb-3 {
  margin-bottom: 1rem !important;
}

.GvlQTG_mb-4 {
  margin-bottom: 1.5rem !important;
}

.GvlQTG_mb-5 {
  margin-bottom: 3rem !important;
}

.GvlQTG_mb-auto {
  margin-bottom: auto !important;
}

.GvlQTG_ms-0 {
  margin-left: 0 !important;
}

.GvlQTG_ms-1 {
  margin-left: .25rem !important;
}

.GvlQTG_ms-2 {
  margin-left: .5rem !important;
}

.GvlQTG_ms-3 {
  margin-left: 1rem !important;
}

.GvlQTG_ms-4 {
  margin-left: 1.5rem !important;
}

.GvlQTG_ms-5 {
  margin-left: 3rem !important;
}

.GvlQTG_ms-auto {
  margin-left: auto !important;
}

.GvlQTG_p-0 {
  padding: 0 !important;
}

.GvlQTG_p-1 {
  padding: .25rem !important;
}

.GvlQTG_p-2 {
  padding: .5rem !important;
}

.GvlQTG_p-3 {
  padding: 1rem !important;
}

.GvlQTG_p-4 {
  padding: 1.5rem !important;
}

.GvlQTG_p-5 {
  padding: 3rem !important;
}

.GvlQTG_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.GvlQTG_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.GvlQTG_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.GvlQTG_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.GvlQTG_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.GvlQTG_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.GvlQTG_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.GvlQTG_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.GvlQTG_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.GvlQTG_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.GvlQTG_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.GvlQTG_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.GvlQTG_pt-0 {
  padding-top: 0 !important;
}

.GvlQTG_pt-1 {
  padding-top: .25rem !important;
}

.GvlQTG_pt-2 {
  padding-top: .5rem !important;
}

.GvlQTG_pt-3 {
  padding-top: 1rem !important;
}

.GvlQTG_pt-4 {
  padding-top: 1.5rem !important;
}

.GvlQTG_pt-5 {
  padding-top: 3rem !important;
}

.GvlQTG_pe-0 {
  padding-right: 0 !important;
}

.GvlQTG_pe-1 {
  padding-right: .25rem !important;
}

.GvlQTG_pe-2 {
  padding-right: .5rem !important;
}

.GvlQTG_pe-3 {
  padding-right: 1rem !important;
}

.GvlQTG_pe-4 {
  padding-right: 1.5rem !important;
}

.GvlQTG_pe-5 {
  padding-right: 3rem !important;
}

.GvlQTG_pb-0 {
  padding-bottom: 0 !important;
}

.GvlQTG_pb-1 {
  padding-bottom: .25rem !important;
}

.GvlQTG_pb-2 {
  padding-bottom: .5rem !important;
}

.GvlQTG_pb-3 {
  padding-bottom: 1rem !important;
}

.GvlQTG_pb-4 {
  padding-bottom: 1.5rem !important;
}

.GvlQTG_pb-5 {
  padding-bottom: 3rem !important;
}

.GvlQTG_ps-0 {
  padding-left: 0 !important;
}

.GvlQTG_ps-1 {
  padding-left: .25rem !important;
}

.GvlQTG_ps-2 {
  padding-left: .5rem !important;
}

.GvlQTG_ps-3 {
  padding-left: 1rem !important;
}

.GvlQTG_ps-4 {
  padding-left: 1.5rem !important;
}

.GvlQTG_ps-5 {
  padding-left: 3rem !important;
}

.GvlQTG_gap-0 {
  gap: 0 !important;
}

.GvlQTG_gap-1 {
  gap: .25rem !important;
}

.GvlQTG_gap-2 {
  gap: .5rem !important;
}

.GvlQTG_gap-3 {
  gap: 1rem !important;
}

.GvlQTG_gap-4 {
  gap: 1.5rem !important;
}

.GvlQTG_gap-5 {
  gap: 3rem !important;
}

.GvlQTG_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.GvlQTG_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.GvlQTG_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.GvlQTG_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.GvlQTG_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.GvlQTG_fs-5 {
  font-size: 1.25rem !important;
}

.GvlQTG_fs-6 {
  font-size: 1rem !important;
}

.GvlQTG_fst-italic {
  font-style: italic !important;
}

.GvlQTG_fst-normal {
  font-style: normal !important;
}

.GvlQTG_fw-light {
  font-weight: 300 !important;
}

.GvlQTG_fw-lighter {
  font-weight: lighter !important;
}

.GvlQTG_fw-normal {
  font-weight: 400 !important;
}

.GvlQTG_fw-bold {
  font-weight: 700 !important;
}

.GvlQTG_fw-semibold {
  font-weight: 600 !important;
}

.GvlQTG_fw-bolder {
  font-weight: bolder !important;
}

.GvlQTG_lh-1 {
  line-height: 1 !important;
}

.GvlQTG_lh-sm {
  line-height: 1.25 !important;
}

.GvlQTG_lh-base {
  line-height: 1.5 !important;
}

.GvlQTG_lh-lg {
  line-height: 2 !important;
}

.GvlQTG_text-start {
  text-align: left !important;
}

.GvlQTG_text-end {
  text-align: right !important;
}

.GvlQTG_text-center {
  text-align: center !important;
}

.GvlQTG_text-decoration-none {
  text-decoration: none !important;
}

.GvlQTG_text-decoration-underline {
  text-decoration: underline !important;
}

.GvlQTG_text-decoration-line-through {
  text-decoration: line-through !important;
}

.GvlQTG_text-lowercase {
  text-transform: lowercase !important;
}

.GvlQTG_text-uppercase {
  text-transform: uppercase !important;
}

.GvlQTG_text-capitalize {
  text-transform: capitalize !important;
}

.GvlQTG_text-wrap {
  white-space: normal !important;
}

.GvlQTG_text-nowrap {
  white-space: nowrap !important;
}

.GvlQTG_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.GvlQTG_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.GvlQTG_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.GvlQTG_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.GvlQTG_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.GvlQTG_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.GvlQTG_text-opacity-25 {
  --bs-text-opacity: .25;
}

.GvlQTG_text-opacity-50 {
  --bs-text-opacity: .5;
}

.GvlQTG_text-opacity-75 {
  --bs-text-opacity: .75;
}

.GvlQTG_text-opacity-100 {
  --bs-text-opacity: 1;
}

.GvlQTG_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.GvlQTG_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.GvlQTG_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.GvlQTG_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.GvlQTG_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.GvlQTG_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.GvlQTG_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.GvlQTG_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.GvlQTG_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.GvlQTG_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.GvlQTG_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.GvlQTG_pe-none {
  pointer-events: none !important;
}

.GvlQTG_pe-auto {
  pointer-events: auto !important;
}

.GvlQTG_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.GvlQTG_rounded-0 {
  border-radius: 0 !important;
}

.GvlQTG_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.GvlQTG_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.GvlQTG_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.GvlQTG_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.GvlQTG_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.GvlQTG_rounded-circle {
  border-radius: 50% !important;
}

.GvlQTG_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.GvlQTG_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.GvlQTG_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.GvlQTG_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.GvlQTG_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.GvlQTG_visible {
  visibility: visible !important;
}

.GvlQTG_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .GvlQTG_float-sm-start {
    float: left !important;
  }

  .GvlQTG_float-sm-end {
    float: right !important;
  }

  .GvlQTG_float-sm-none {
    float: none !important;
  }

  .GvlQTG_d-sm-inline {
    display: inline !important;
  }

  .GvlQTG_d-sm-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-sm-block {
    display: block !important;
  }

  .GvlQTG_d-sm-grid {
    display: grid !important;
  }

  .GvlQTG_d-sm-table {
    display: table !important;
  }

  .GvlQTG_d-sm-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-sm-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-sm-flex {
    display: flex !important;
  }

  .GvlQTG_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-sm-none {
    display: none !important;
  }

  .GvlQTG_flex-sm-fill {
    flex: auto !important;
  }

  .GvlQTG_flex-sm-row {
    flex-direction: row !important;
  }

  .GvlQTG_flex-sm-column {
    flex-direction: column !important;
  }

  .GvlQTG_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .GvlQTG_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .GvlQTG_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .GvlQTG_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .GvlQTG_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .GvlQTG_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .GvlQTG_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .GvlQTG_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .GvlQTG_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .GvlQTG_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .GvlQTG_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .GvlQTG_justify-content-sm-center {
    justify-content: center !important;
  }

  .GvlQTG_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .GvlQTG_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .GvlQTG_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .GvlQTG_align-items-sm-start {
    align-items: flex-start !important;
  }

  .GvlQTG_align-items-sm-end {
    align-items: flex-end !important;
  }

  .GvlQTG_align-items-sm-center {
    align-items: center !important;
  }

  .GvlQTG_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .GvlQTG_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .GvlQTG_align-content-sm-start {
    align-content: flex-start !important;
  }

  .GvlQTG_align-content-sm-end {
    align-content: flex-end !important;
  }

  .GvlQTG_align-content-sm-center {
    align-content: center !important;
  }

  .GvlQTG_align-content-sm-between {
    align-content: space-between !important;
  }

  .GvlQTG_align-content-sm-around {
    align-content: space-around !important;
  }

  .GvlQTG_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .GvlQTG_align-self-sm-auto {
    align-self: auto !important;
  }

  .GvlQTG_align-self-sm-start {
    align-self: flex-start !important;
  }

  .GvlQTG_align-self-sm-end {
    align-self: flex-end !important;
  }

  .GvlQTG_align-self-sm-center {
    align-self: center !important;
  }

  .GvlQTG_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .GvlQTG_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .GvlQTG_order-sm-first {
    order: -1 !important;
  }

  .GvlQTG_order-sm-0 {
    order: 0 !important;
  }

  .GvlQTG_order-sm-1 {
    order: 1 !important;
  }

  .GvlQTG_order-sm-2 {
    order: 2 !important;
  }

  .GvlQTG_order-sm-3 {
    order: 3 !important;
  }

  .GvlQTG_order-sm-4 {
    order: 4 !important;
  }

  .GvlQTG_order-sm-5 {
    order: 5 !important;
  }

  .GvlQTG_order-sm-last {
    order: 6 !important;
  }

  .GvlQTG_m-sm-0 {
    margin: 0 !important;
  }

  .GvlQTG_m-sm-1 {
    margin: .25rem !important;
  }

  .GvlQTG_m-sm-2 {
    margin: .5rem !important;
  }

  .GvlQTG_m-sm-3 {
    margin: 1rem !important;
  }

  .GvlQTG_m-sm-4 {
    margin: 1.5rem !important;
  }

  .GvlQTG_m-sm-5 {
    margin: 3rem !important;
  }

  .GvlQTG_m-sm-auto {
    margin: auto !important;
  }

  .GvlQTG_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .GvlQTG_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .GvlQTG_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .GvlQTG_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .GvlQTG_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .GvlQTG_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .GvlQTG_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .GvlQTG_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .GvlQTG_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .GvlQTG_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .GvlQTG_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .GvlQTG_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .GvlQTG_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .GvlQTG_mt-sm-0 {
    margin-top: 0 !important;
  }

  .GvlQTG_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .GvlQTG_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .GvlQTG_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .GvlQTG_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .GvlQTG_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .GvlQTG_mt-sm-auto {
    margin-top: auto !important;
  }

  .GvlQTG_me-sm-0 {
    margin-right: 0 !important;
  }

  .GvlQTG_me-sm-1 {
    margin-right: .25rem !important;
  }

  .GvlQTG_me-sm-2 {
    margin-right: .5rem !important;
  }

  .GvlQTG_me-sm-3 {
    margin-right: 1rem !important;
  }

  .GvlQTG_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .GvlQTG_me-sm-5 {
    margin-right: 3rem !important;
  }

  .GvlQTG_me-sm-auto {
    margin-right: auto !important;
  }

  .GvlQTG_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .GvlQTG_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .GvlQTG_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .GvlQTG_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .GvlQTG_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .GvlQTG_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .GvlQTG_ms-sm-0 {
    margin-left: 0 !important;
  }

  .GvlQTG_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .GvlQTG_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .GvlQTG_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .GvlQTG_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .GvlQTG_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .GvlQTG_ms-sm-auto {
    margin-left: auto !important;
  }

  .GvlQTG_p-sm-0 {
    padding: 0 !important;
  }

  .GvlQTG_p-sm-1 {
    padding: .25rem !important;
  }

  .GvlQTG_p-sm-2 {
    padding: .5rem !important;
  }

  .GvlQTG_p-sm-3 {
    padding: 1rem !important;
  }

  .GvlQTG_p-sm-4 {
    padding: 1.5rem !important;
  }

  .GvlQTG_p-sm-5 {
    padding: 3rem !important;
  }

  .GvlQTG_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .GvlQTG_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .GvlQTG_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .GvlQTG_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .GvlQTG_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .GvlQTG_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .GvlQTG_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .GvlQTG_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .GvlQTG_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .GvlQTG_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .GvlQTG_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .GvlQTG_pt-sm-0 {
    padding-top: 0 !important;
  }

  .GvlQTG_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .GvlQTG_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .GvlQTG_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .GvlQTG_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .GvlQTG_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .GvlQTG_pe-sm-0 {
    padding-right: 0 !important;
  }

  .GvlQTG_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .GvlQTG_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .GvlQTG_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .GvlQTG_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .GvlQTG_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .GvlQTG_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .GvlQTG_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .GvlQTG_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .GvlQTG_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .GvlQTG_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .GvlQTG_ps-sm-0 {
    padding-left: 0 !important;
  }

  .GvlQTG_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .GvlQTG_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .GvlQTG_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .GvlQTG_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .GvlQTG_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .GvlQTG_gap-sm-0 {
    gap: 0 !important;
  }

  .GvlQTG_gap-sm-1 {
    gap: .25rem !important;
  }

  .GvlQTG_gap-sm-2 {
    gap: .5rem !important;
  }

  .GvlQTG_gap-sm-3 {
    gap: 1rem !important;
  }

  .GvlQTG_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .GvlQTG_gap-sm-5 {
    gap: 3rem !important;
  }

  .GvlQTG_text-sm-start {
    text-align: left !important;
  }

  .GvlQTG_text-sm-end {
    text-align: right !important;
  }

  .GvlQTG_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .GvlQTG_float-md-start {
    float: left !important;
  }

  .GvlQTG_float-md-end {
    float: right !important;
  }

  .GvlQTG_float-md-none {
    float: none !important;
  }

  .GvlQTG_d-md-inline {
    display: inline !important;
  }

  .GvlQTG_d-md-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-md-block {
    display: block !important;
  }

  .GvlQTG_d-md-grid {
    display: grid !important;
  }

  .GvlQTG_d-md-table {
    display: table !important;
  }

  .GvlQTG_d-md-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-md-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-md-flex {
    display: flex !important;
  }

  .GvlQTG_d-md-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-md-none {
    display: none !important;
  }

  .GvlQTG_flex-md-fill {
    flex: auto !important;
  }

  .GvlQTG_flex-md-row {
    flex-direction: row !important;
  }

  .GvlQTG_flex-md-column {
    flex-direction: column !important;
  }

  .GvlQTG_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .GvlQTG_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .GvlQTG_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .GvlQTG_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .GvlQTG_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .GvlQTG_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .GvlQTG_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .GvlQTG_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .GvlQTG_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .GvlQTG_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .GvlQTG_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .GvlQTG_justify-content-md-center {
    justify-content: center !important;
  }

  .GvlQTG_justify-content-md-between {
    justify-content: space-between !important;
  }

  .GvlQTG_justify-content-md-around {
    justify-content: space-around !important;
  }

  .GvlQTG_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .GvlQTG_align-items-md-start {
    align-items: flex-start !important;
  }

  .GvlQTG_align-items-md-end {
    align-items: flex-end !important;
  }

  .GvlQTG_align-items-md-center {
    align-items: center !important;
  }

  .GvlQTG_align-items-md-baseline {
    align-items: baseline !important;
  }

  .GvlQTG_align-items-md-stretch {
    align-items: stretch !important;
  }

  .GvlQTG_align-content-md-start {
    align-content: flex-start !important;
  }

  .GvlQTG_align-content-md-end {
    align-content: flex-end !important;
  }

  .GvlQTG_align-content-md-center {
    align-content: center !important;
  }

  .GvlQTG_align-content-md-between {
    align-content: space-between !important;
  }

  .GvlQTG_align-content-md-around {
    align-content: space-around !important;
  }

  .GvlQTG_align-content-md-stretch {
    align-content: stretch !important;
  }

  .GvlQTG_align-self-md-auto {
    align-self: auto !important;
  }

  .GvlQTG_align-self-md-start {
    align-self: flex-start !important;
  }

  .GvlQTG_align-self-md-end {
    align-self: flex-end !important;
  }

  .GvlQTG_align-self-md-center {
    align-self: center !important;
  }

  .GvlQTG_align-self-md-baseline {
    align-self: baseline !important;
  }

  .GvlQTG_align-self-md-stretch {
    align-self: stretch !important;
  }

  .GvlQTG_order-md-first {
    order: -1 !important;
  }

  .GvlQTG_order-md-0 {
    order: 0 !important;
  }

  .GvlQTG_order-md-1 {
    order: 1 !important;
  }

  .GvlQTG_order-md-2 {
    order: 2 !important;
  }

  .GvlQTG_order-md-3 {
    order: 3 !important;
  }

  .GvlQTG_order-md-4 {
    order: 4 !important;
  }

  .GvlQTG_order-md-5 {
    order: 5 !important;
  }

  .GvlQTG_order-md-last {
    order: 6 !important;
  }

  .GvlQTG_m-md-0 {
    margin: 0 !important;
  }

  .GvlQTG_m-md-1 {
    margin: .25rem !important;
  }

  .GvlQTG_m-md-2 {
    margin: .5rem !important;
  }

  .GvlQTG_m-md-3 {
    margin: 1rem !important;
  }

  .GvlQTG_m-md-4 {
    margin: 1.5rem !important;
  }

  .GvlQTG_m-md-5 {
    margin: 3rem !important;
  }

  .GvlQTG_m-md-auto {
    margin: auto !important;
  }

  .GvlQTG_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .GvlQTG_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .GvlQTG_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .GvlQTG_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .GvlQTG_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .GvlQTG_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .GvlQTG_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .GvlQTG_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .GvlQTG_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .GvlQTG_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .GvlQTG_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .GvlQTG_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .GvlQTG_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .GvlQTG_mt-md-0 {
    margin-top: 0 !important;
  }

  .GvlQTG_mt-md-1 {
    margin-top: .25rem !important;
  }

  .GvlQTG_mt-md-2 {
    margin-top: .5rem !important;
  }

  .GvlQTG_mt-md-3 {
    margin-top: 1rem !important;
  }

  .GvlQTG_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .GvlQTG_mt-md-5 {
    margin-top: 3rem !important;
  }

  .GvlQTG_mt-md-auto {
    margin-top: auto !important;
  }

  .GvlQTG_me-md-0 {
    margin-right: 0 !important;
  }

  .GvlQTG_me-md-1 {
    margin-right: .25rem !important;
  }

  .GvlQTG_me-md-2 {
    margin-right: .5rem !important;
  }

  .GvlQTG_me-md-3 {
    margin-right: 1rem !important;
  }

  .GvlQTG_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .GvlQTG_me-md-5 {
    margin-right: 3rem !important;
  }

  .GvlQTG_me-md-auto {
    margin-right: auto !important;
  }

  .GvlQTG_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .GvlQTG_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .GvlQTG_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .GvlQTG_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .GvlQTG_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .GvlQTG_mb-md-auto {
    margin-bottom: auto !important;
  }

  .GvlQTG_ms-md-0 {
    margin-left: 0 !important;
  }

  .GvlQTG_ms-md-1 {
    margin-left: .25rem !important;
  }

  .GvlQTG_ms-md-2 {
    margin-left: .5rem !important;
  }

  .GvlQTG_ms-md-3 {
    margin-left: 1rem !important;
  }

  .GvlQTG_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .GvlQTG_ms-md-5 {
    margin-left: 3rem !important;
  }

  .GvlQTG_ms-md-auto {
    margin-left: auto !important;
  }

  .GvlQTG_p-md-0 {
    padding: 0 !important;
  }

  .GvlQTG_p-md-1 {
    padding: .25rem !important;
  }

  .GvlQTG_p-md-2 {
    padding: .5rem !important;
  }

  .GvlQTG_p-md-3 {
    padding: 1rem !important;
  }

  .GvlQTG_p-md-4 {
    padding: 1.5rem !important;
  }

  .GvlQTG_p-md-5 {
    padding: 3rem !important;
  }

  .GvlQTG_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .GvlQTG_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .GvlQTG_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .GvlQTG_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .GvlQTG_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .GvlQTG_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .GvlQTG_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .GvlQTG_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .GvlQTG_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .GvlQTG_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .GvlQTG_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .GvlQTG_pt-md-0 {
    padding-top: 0 !important;
  }

  .GvlQTG_pt-md-1 {
    padding-top: .25rem !important;
  }

  .GvlQTG_pt-md-2 {
    padding-top: .5rem !important;
  }

  .GvlQTG_pt-md-3 {
    padding-top: 1rem !important;
  }

  .GvlQTG_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .GvlQTG_pt-md-5 {
    padding-top: 3rem !important;
  }

  .GvlQTG_pe-md-0 {
    padding-right: 0 !important;
  }

  .GvlQTG_pe-md-1 {
    padding-right: .25rem !important;
  }

  .GvlQTG_pe-md-2 {
    padding-right: .5rem !important;
  }

  .GvlQTG_pe-md-3 {
    padding-right: 1rem !important;
  }

  .GvlQTG_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .GvlQTG_pe-md-5 {
    padding-right: 3rem !important;
  }

  .GvlQTG_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .GvlQTG_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .GvlQTG_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .GvlQTG_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .GvlQTG_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .GvlQTG_ps-md-0 {
    padding-left: 0 !important;
  }

  .GvlQTG_ps-md-1 {
    padding-left: .25rem !important;
  }

  .GvlQTG_ps-md-2 {
    padding-left: .5rem !important;
  }

  .GvlQTG_ps-md-3 {
    padding-left: 1rem !important;
  }

  .GvlQTG_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .GvlQTG_ps-md-5 {
    padding-left: 3rem !important;
  }

  .GvlQTG_gap-md-0 {
    gap: 0 !important;
  }

  .GvlQTG_gap-md-1 {
    gap: .25rem !important;
  }

  .GvlQTG_gap-md-2 {
    gap: .5rem !important;
  }

  .GvlQTG_gap-md-3 {
    gap: 1rem !important;
  }

  .GvlQTG_gap-md-4 {
    gap: 1.5rem !important;
  }

  .GvlQTG_gap-md-5 {
    gap: 3rem !important;
  }

  .GvlQTG_text-md-start {
    text-align: left !important;
  }

  .GvlQTG_text-md-end {
    text-align: right !important;
  }

  .GvlQTG_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .GvlQTG_float-lg-start {
    float: left !important;
  }

  .GvlQTG_float-lg-end {
    float: right !important;
  }

  .GvlQTG_float-lg-none {
    float: none !important;
  }

  .GvlQTG_d-lg-inline {
    display: inline !important;
  }

  .GvlQTG_d-lg-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-lg-block {
    display: block !important;
  }

  .GvlQTG_d-lg-grid {
    display: grid !important;
  }

  .GvlQTG_d-lg-table {
    display: table !important;
  }

  .GvlQTG_d-lg-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-lg-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-lg-flex {
    display: flex !important;
  }

  .GvlQTG_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-lg-none {
    display: none !important;
  }

  .GvlQTG_flex-lg-fill {
    flex: auto !important;
  }

  .GvlQTG_flex-lg-row {
    flex-direction: row !important;
  }

  .GvlQTG_flex-lg-column {
    flex-direction: column !important;
  }

  .GvlQTG_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .GvlQTG_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .GvlQTG_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .GvlQTG_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .GvlQTG_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .GvlQTG_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .GvlQTG_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .GvlQTG_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .GvlQTG_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .GvlQTG_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .GvlQTG_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .GvlQTG_justify-content-lg-center {
    justify-content: center !important;
  }

  .GvlQTG_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .GvlQTG_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .GvlQTG_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .GvlQTG_align-items-lg-start {
    align-items: flex-start !important;
  }

  .GvlQTG_align-items-lg-end {
    align-items: flex-end !important;
  }

  .GvlQTG_align-items-lg-center {
    align-items: center !important;
  }

  .GvlQTG_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .GvlQTG_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .GvlQTG_align-content-lg-start {
    align-content: flex-start !important;
  }

  .GvlQTG_align-content-lg-end {
    align-content: flex-end !important;
  }

  .GvlQTG_align-content-lg-center {
    align-content: center !important;
  }

  .GvlQTG_align-content-lg-between {
    align-content: space-between !important;
  }

  .GvlQTG_align-content-lg-around {
    align-content: space-around !important;
  }

  .GvlQTG_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .GvlQTG_align-self-lg-auto {
    align-self: auto !important;
  }

  .GvlQTG_align-self-lg-start {
    align-self: flex-start !important;
  }

  .GvlQTG_align-self-lg-end {
    align-self: flex-end !important;
  }

  .GvlQTG_align-self-lg-center {
    align-self: center !important;
  }

  .GvlQTG_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .GvlQTG_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .GvlQTG_order-lg-first {
    order: -1 !important;
  }

  .GvlQTG_order-lg-0 {
    order: 0 !important;
  }

  .GvlQTG_order-lg-1 {
    order: 1 !important;
  }

  .GvlQTG_order-lg-2 {
    order: 2 !important;
  }

  .GvlQTG_order-lg-3 {
    order: 3 !important;
  }

  .GvlQTG_order-lg-4 {
    order: 4 !important;
  }

  .GvlQTG_order-lg-5 {
    order: 5 !important;
  }

  .GvlQTG_order-lg-last {
    order: 6 !important;
  }

  .GvlQTG_m-lg-0 {
    margin: 0 !important;
  }

  .GvlQTG_m-lg-1 {
    margin: .25rem !important;
  }

  .GvlQTG_m-lg-2 {
    margin: .5rem !important;
  }

  .GvlQTG_m-lg-3 {
    margin: 1rem !important;
  }

  .GvlQTG_m-lg-4 {
    margin: 1.5rem !important;
  }

  .GvlQTG_m-lg-5 {
    margin: 3rem !important;
  }

  .GvlQTG_m-lg-auto {
    margin: auto !important;
  }

  .GvlQTG_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .GvlQTG_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .GvlQTG_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .GvlQTG_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .GvlQTG_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .GvlQTG_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .GvlQTG_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .GvlQTG_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .GvlQTG_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .GvlQTG_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .GvlQTG_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .GvlQTG_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .GvlQTG_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .GvlQTG_mt-lg-0 {
    margin-top: 0 !important;
  }

  .GvlQTG_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .GvlQTG_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .GvlQTG_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .GvlQTG_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .GvlQTG_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .GvlQTG_mt-lg-auto {
    margin-top: auto !important;
  }

  .GvlQTG_me-lg-0 {
    margin-right: 0 !important;
  }

  .GvlQTG_me-lg-1 {
    margin-right: .25rem !important;
  }

  .GvlQTG_me-lg-2 {
    margin-right: .5rem !important;
  }

  .GvlQTG_me-lg-3 {
    margin-right: 1rem !important;
  }

  .GvlQTG_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .GvlQTG_me-lg-5 {
    margin-right: 3rem !important;
  }

  .GvlQTG_me-lg-auto {
    margin-right: auto !important;
  }

  .GvlQTG_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .GvlQTG_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .GvlQTG_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .GvlQTG_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .GvlQTG_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .GvlQTG_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .GvlQTG_ms-lg-0 {
    margin-left: 0 !important;
  }

  .GvlQTG_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .GvlQTG_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .GvlQTG_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .GvlQTG_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .GvlQTG_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .GvlQTG_ms-lg-auto {
    margin-left: auto !important;
  }

  .GvlQTG_p-lg-0 {
    padding: 0 !important;
  }

  .GvlQTG_p-lg-1 {
    padding: .25rem !important;
  }

  .GvlQTG_p-lg-2 {
    padding: .5rem !important;
  }

  .GvlQTG_p-lg-3 {
    padding: 1rem !important;
  }

  .GvlQTG_p-lg-4 {
    padding: 1.5rem !important;
  }

  .GvlQTG_p-lg-5 {
    padding: 3rem !important;
  }

  .GvlQTG_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .GvlQTG_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .GvlQTG_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .GvlQTG_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .GvlQTG_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .GvlQTG_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .GvlQTG_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .GvlQTG_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .GvlQTG_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .GvlQTG_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .GvlQTG_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .GvlQTG_pt-lg-0 {
    padding-top: 0 !important;
  }

  .GvlQTG_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .GvlQTG_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .GvlQTG_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .GvlQTG_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .GvlQTG_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .GvlQTG_pe-lg-0 {
    padding-right: 0 !important;
  }

  .GvlQTG_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .GvlQTG_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .GvlQTG_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .GvlQTG_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .GvlQTG_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .GvlQTG_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .GvlQTG_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .GvlQTG_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .GvlQTG_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .GvlQTG_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .GvlQTG_ps-lg-0 {
    padding-left: 0 !important;
  }

  .GvlQTG_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .GvlQTG_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .GvlQTG_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .GvlQTG_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .GvlQTG_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .GvlQTG_gap-lg-0 {
    gap: 0 !important;
  }

  .GvlQTG_gap-lg-1 {
    gap: .25rem !important;
  }

  .GvlQTG_gap-lg-2 {
    gap: .5rem !important;
  }

  .GvlQTG_gap-lg-3 {
    gap: 1rem !important;
  }

  .GvlQTG_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .GvlQTG_gap-lg-5 {
    gap: 3rem !important;
  }

  .GvlQTG_text-lg-start {
    text-align: left !important;
  }

  .GvlQTG_text-lg-end {
    text-align: right !important;
  }

  .GvlQTG_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_float-xl-start {
    float: left !important;
  }

  .GvlQTG_float-xl-end {
    float: right !important;
  }

  .GvlQTG_float-xl-none {
    float: none !important;
  }

  .GvlQTG_d-xl-inline {
    display: inline !important;
  }

  .GvlQTG_d-xl-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-xl-block {
    display: block !important;
  }

  .GvlQTG_d-xl-grid {
    display: grid !important;
  }

  .GvlQTG_d-xl-table {
    display: table !important;
  }

  .GvlQTG_d-xl-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-xl-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-xl-flex {
    display: flex !important;
  }

  .GvlQTG_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-xl-none {
    display: none !important;
  }

  .GvlQTG_flex-xl-fill {
    flex: auto !important;
  }

  .GvlQTG_flex-xl-row {
    flex-direction: row !important;
  }

  .GvlQTG_flex-xl-column {
    flex-direction: column !important;
  }

  .GvlQTG_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .GvlQTG_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .GvlQTG_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .GvlQTG_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .GvlQTG_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .GvlQTG_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .GvlQTG_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .GvlQTG_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .GvlQTG_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .GvlQTG_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .GvlQTG_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .GvlQTG_justify-content-xl-center {
    justify-content: center !important;
  }

  .GvlQTG_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .GvlQTG_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .GvlQTG_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .GvlQTG_align-items-xl-start {
    align-items: flex-start !important;
  }

  .GvlQTG_align-items-xl-end {
    align-items: flex-end !important;
  }

  .GvlQTG_align-items-xl-center {
    align-items: center !important;
  }

  .GvlQTG_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .GvlQTG_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .GvlQTG_align-content-xl-start {
    align-content: flex-start !important;
  }

  .GvlQTG_align-content-xl-end {
    align-content: flex-end !important;
  }

  .GvlQTG_align-content-xl-center {
    align-content: center !important;
  }

  .GvlQTG_align-content-xl-between {
    align-content: space-between !important;
  }

  .GvlQTG_align-content-xl-around {
    align-content: space-around !important;
  }

  .GvlQTG_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .GvlQTG_align-self-xl-auto {
    align-self: auto !important;
  }

  .GvlQTG_align-self-xl-start {
    align-self: flex-start !important;
  }

  .GvlQTG_align-self-xl-end {
    align-self: flex-end !important;
  }

  .GvlQTG_align-self-xl-center {
    align-self: center !important;
  }

  .GvlQTG_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .GvlQTG_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .GvlQTG_order-xl-first {
    order: -1 !important;
  }

  .GvlQTG_order-xl-0 {
    order: 0 !important;
  }

  .GvlQTG_order-xl-1 {
    order: 1 !important;
  }

  .GvlQTG_order-xl-2 {
    order: 2 !important;
  }

  .GvlQTG_order-xl-3 {
    order: 3 !important;
  }

  .GvlQTG_order-xl-4 {
    order: 4 !important;
  }

  .GvlQTG_order-xl-5 {
    order: 5 !important;
  }

  .GvlQTG_order-xl-last {
    order: 6 !important;
  }

  .GvlQTG_m-xl-0 {
    margin: 0 !important;
  }

  .GvlQTG_m-xl-1 {
    margin: .25rem !important;
  }

  .GvlQTG_m-xl-2 {
    margin: .5rem !important;
  }

  .GvlQTG_m-xl-3 {
    margin: 1rem !important;
  }

  .GvlQTG_m-xl-4 {
    margin: 1.5rem !important;
  }

  .GvlQTG_m-xl-5 {
    margin: 3rem !important;
  }

  .GvlQTG_m-xl-auto {
    margin: auto !important;
  }

  .GvlQTG_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .GvlQTG_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .GvlQTG_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .GvlQTG_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .GvlQTG_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .GvlQTG_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .GvlQTG_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .GvlQTG_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .GvlQTG_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .GvlQTG_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .GvlQTG_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .GvlQTG_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .GvlQTG_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .GvlQTG_mt-xl-0 {
    margin-top: 0 !important;
  }

  .GvlQTG_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .GvlQTG_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .GvlQTG_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .GvlQTG_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .GvlQTG_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .GvlQTG_mt-xl-auto {
    margin-top: auto !important;
  }

  .GvlQTG_me-xl-0 {
    margin-right: 0 !important;
  }

  .GvlQTG_me-xl-1 {
    margin-right: .25rem !important;
  }

  .GvlQTG_me-xl-2 {
    margin-right: .5rem !important;
  }

  .GvlQTG_me-xl-3 {
    margin-right: 1rem !important;
  }

  .GvlQTG_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .GvlQTG_me-xl-5 {
    margin-right: 3rem !important;
  }

  .GvlQTG_me-xl-auto {
    margin-right: auto !important;
  }

  .GvlQTG_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .GvlQTG_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .GvlQTG_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .GvlQTG_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .GvlQTG_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .GvlQTG_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .GvlQTG_ms-xl-0 {
    margin-left: 0 !important;
  }

  .GvlQTG_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .GvlQTG_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .GvlQTG_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .GvlQTG_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .GvlQTG_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .GvlQTG_ms-xl-auto {
    margin-left: auto !important;
  }

  .GvlQTG_p-xl-0 {
    padding: 0 !important;
  }

  .GvlQTG_p-xl-1 {
    padding: .25rem !important;
  }

  .GvlQTG_p-xl-2 {
    padding: .5rem !important;
  }

  .GvlQTG_p-xl-3 {
    padding: 1rem !important;
  }

  .GvlQTG_p-xl-4 {
    padding: 1.5rem !important;
  }

  .GvlQTG_p-xl-5 {
    padding: 3rem !important;
  }

  .GvlQTG_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .GvlQTG_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .GvlQTG_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .GvlQTG_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .GvlQTG_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .GvlQTG_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .GvlQTG_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .GvlQTG_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .GvlQTG_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .GvlQTG_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .GvlQTG_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .GvlQTG_pt-xl-0 {
    padding-top: 0 !important;
  }

  .GvlQTG_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .GvlQTG_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .GvlQTG_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .GvlQTG_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .GvlQTG_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .GvlQTG_pe-xl-0 {
    padding-right: 0 !important;
  }

  .GvlQTG_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .GvlQTG_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .GvlQTG_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .GvlQTG_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .GvlQTG_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .GvlQTG_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .GvlQTG_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .GvlQTG_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .GvlQTG_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .GvlQTG_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .GvlQTG_ps-xl-0 {
    padding-left: 0 !important;
  }

  .GvlQTG_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .GvlQTG_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .GvlQTG_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .GvlQTG_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .GvlQTG_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .GvlQTG_gap-xl-0 {
    gap: 0 !important;
  }

  .GvlQTG_gap-xl-1 {
    gap: .25rem !important;
  }

  .GvlQTG_gap-xl-2 {
    gap: .5rem !important;
  }

  .GvlQTG_gap-xl-3 {
    gap: 1rem !important;
  }

  .GvlQTG_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .GvlQTG_gap-xl-5 {
    gap: 3rem !important;
  }

  .GvlQTG_text-xl-start {
    text-align: left !important;
  }

  .GvlQTG_text-xl-end {
    text-align: right !important;
  }

  .GvlQTG_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .GvlQTG_float-xxl-start {
    float: left !important;
  }

  .GvlQTG_float-xxl-end {
    float: right !important;
  }

  .GvlQTG_float-xxl-none {
    float: none !important;
  }

  .GvlQTG_d-xxl-inline {
    display: inline !important;
  }

  .GvlQTG_d-xxl-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-xxl-block {
    display: block !important;
  }

  .GvlQTG_d-xxl-grid {
    display: grid !important;
  }

  .GvlQTG_d-xxl-table {
    display: table !important;
  }

  .GvlQTG_d-xxl-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-xxl-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-xxl-flex {
    display: flex !important;
  }

  .GvlQTG_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-xxl-none {
    display: none !important;
  }

  .GvlQTG_flex-xxl-fill {
    flex: auto !important;
  }

  .GvlQTG_flex-xxl-row {
    flex-direction: row !important;
  }

  .GvlQTG_flex-xxl-column {
    flex-direction: column !important;
  }

  .GvlQTG_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .GvlQTG_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .GvlQTG_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .GvlQTG_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .GvlQTG_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .GvlQTG_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .GvlQTG_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .GvlQTG_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .GvlQTG_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .GvlQTG_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .GvlQTG_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .GvlQTG_justify-content-xxl-center {
    justify-content: center !important;
  }

  .GvlQTG_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .GvlQTG_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .GvlQTG_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .GvlQTG_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .GvlQTG_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .GvlQTG_align-items-xxl-center {
    align-items: center !important;
  }

  .GvlQTG_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .GvlQTG_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .GvlQTG_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .GvlQTG_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .GvlQTG_align-content-xxl-center {
    align-content: center !important;
  }

  .GvlQTG_align-content-xxl-between {
    align-content: space-between !important;
  }

  .GvlQTG_align-content-xxl-around {
    align-content: space-around !important;
  }

  .GvlQTG_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .GvlQTG_align-self-xxl-auto {
    align-self: auto !important;
  }

  .GvlQTG_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .GvlQTG_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .GvlQTG_align-self-xxl-center {
    align-self: center !important;
  }

  .GvlQTG_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .GvlQTG_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .GvlQTG_order-xxl-first {
    order: -1 !important;
  }

  .GvlQTG_order-xxl-0 {
    order: 0 !important;
  }

  .GvlQTG_order-xxl-1 {
    order: 1 !important;
  }

  .GvlQTG_order-xxl-2 {
    order: 2 !important;
  }

  .GvlQTG_order-xxl-3 {
    order: 3 !important;
  }

  .GvlQTG_order-xxl-4 {
    order: 4 !important;
  }

  .GvlQTG_order-xxl-5 {
    order: 5 !important;
  }

  .GvlQTG_order-xxl-last {
    order: 6 !important;
  }

  .GvlQTG_m-xxl-0 {
    margin: 0 !important;
  }

  .GvlQTG_m-xxl-1 {
    margin: .25rem !important;
  }

  .GvlQTG_m-xxl-2 {
    margin: .5rem !important;
  }

  .GvlQTG_m-xxl-3 {
    margin: 1rem !important;
  }

  .GvlQTG_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .GvlQTG_m-xxl-5 {
    margin: 3rem !important;
  }

  .GvlQTG_m-xxl-auto {
    margin: auto !important;
  }

  .GvlQTG_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .GvlQTG_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .GvlQTG_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .GvlQTG_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .GvlQTG_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .GvlQTG_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .GvlQTG_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .GvlQTG_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .GvlQTG_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .GvlQTG_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .GvlQTG_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .GvlQTG_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .GvlQTG_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .GvlQTG_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .GvlQTG_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .GvlQTG_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .GvlQTG_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .GvlQTG_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .GvlQTG_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .GvlQTG_mt-xxl-auto {
    margin-top: auto !important;
  }

  .GvlQTG_me-xxl-0 {
    margin-right: 0 !important;
  }

  .GvlQTG_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .GvlQTG_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .GvlQTG_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .GvlQTG_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .GvlQTG_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .GvlQTG_me-xxl-auto {
    margin-right: auto !important;
  }

  .GvlQTG_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .GvlQTG_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .GvlQTG_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .GvlQTG_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .GvlQTG_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .GvlQTG_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .GvlQTG_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .GvlQTG_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .GvlQTG_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .GvlQTG_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .GvlQTG_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .GvlQTG_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .GvlQTG_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .GvlQTG_ms-xxl-auto {
    margin-left: auto !important;
  }

  .GvlQTG_p-xxl-0 {
    padding: 0 !important;
  }

  .GvlQTG_p-xxl-1 {
    padding: .25rem !important;
  }

  .GvlQTG_p-xxl-2 {
    padding: .5rem !important;
  }

  .GvlQTG_p-xxl-3 {
    padding: 1rem !important;
  }

  .GvlQTG_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .GvlQTG_p-xxl-5 {
    padding: 3rem !important;
  }

  .GvlQTG_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .GvlQTG_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .GvlQTG_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .GvlQTG_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .GvlQTG_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .GvlQTG_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .GvlQTG_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .GvlQTG_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .GvlQTG_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .GvlQTG_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .GvlQTG_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .GvlQTG_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .GvlQTG_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .GvlQTG_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .GvlQTG_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .GvlQTG_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .GvlQTG_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .GvlQTG_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .GvlQTG_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .GvlQTG_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .GvlQTG_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .GvlQTG_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .GvlQTG_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .GvlQTG_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .GvlQTG_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .GvlQTG_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .GvlQTG_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .GvlQTG_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .GvlQTG_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .GvlQTG_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .GvlQTG_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .GvlQTG_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .GvlQTG_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .GvlQTG_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .GvlQTG_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .GvlQTG_gap-xxl-0 {
    gap: 0 !important;
  }

  .GvlQTG_gap-xxl-1 {
    gap: .25rem !important;
  }

  .GvlQTG_gap-xxl-2 {
    gap: .5rem !important;
  }

  .GvlQTG_gap-xxl-3 {
    gap: 1rem !important;
  }

  .GvlQTG_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .GvlQTG_gap-xxl-5 {
    gap: 3rem !important;
  }

  .GvlQTG_text-xxl-start {
    text-align: left !important;
  }

  .GvlQTG_text-xxl-end {
    text-align: right !important;
  }

  .GvlQTG_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .GvlQTG_fs-1 {
    font-size: 2.5rem !important;
  }

  .GvlQTG_fs-2 {
    font-size: 2rem !important;
  }

  .GvlQTG_fs-3 {
    font-size: 1.75rem !important;
  }

  .GvlQTG_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .GvlQTG_d-print-inline {
    display: inline !important;
  }

  .GvlQTG_d-print-inline-block {
    display: inline-block !important;
  }

  .GvlQTG_d-print-block {
    display: block !important;
  }

  .GvlQTG_d-print-grid {
    display: grid !important;
  }

  .GvlQTG_d-print-table {
    display: table !important;
  }

  .GvlQTG_d-print-table-row {
    display: table-row !important;
  }

  .GvlQTG_d-print-table-cell {
    display: table-cell !important;
  }

  .GvlQTG_d-print-flex {
    display: flex !important;
  }

  .GvlQTG_d-print-inline-flex {
    display: inline-flex !important;
  }

  .GvlQTG_d-print-none {
    display: none !important;
  }
}

.GvlQTG_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.GvlQTG_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.GvlQTG_nav-tabs .GvlQTG_nav-item .GvlQTG_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.GvlQTG_nav-tabs .GvlQTG_nav-item .GvlQTG_nav-link.GvlQTG_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.GvlQTG_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#GvlQTG_root {
  height: 100%;
}

.GvlQTG_highcharts-credits {
  display: none !important;
}

h3, .GvlQTG_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.GvlQTG_modal .GvlQTG_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.GvlQTG_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.GvlQTG_table thead tr th.GvlQTG_col-actions {
  width: 110px !important;
}

table.GvlQTG_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.GvlQTG_table tbody tr.GvlQTG_deleting {
  background-color: #ff323233 !important;
}

.GvlQTG_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.GvlQTG_btn-sm, .GvlQTG_btn-group-sm > .GvlQTG_btn {
  font-size: .6rem !important;
}

.GvlQTG_modal {
  background-color: #0a0a0a4d;
}

.GvlQTG_btn:focus {
  box-shadow: none !important;
}

.GvlQTG_content-filter .GvlQTG_react-datepicker-wrapper {
  width: auto !important;
}

.GvlQTG_content-filter .GvlQTG_react-datepicker-wrapper .GvlQTG_form-control {
  font-size: .85rem !important;
}

.GvlQTG_content-filter .GvlQTG_input-group-text, .GvlQTG_content-filter .GvlQTG_btn {
  height: 2.15rem !important;
}

.GvlQTG_content-filter .GvlQTG_btn {
  border-color: #ccc;
}

.GvlQTG_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.GvlQTG_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.GvlQTG_table > :not(:first-child) {
  border-top: unset !important;
}

.GvlQTG_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.GvlQTG_highcharts-series.GvlQTG_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.GvlQTG_expandable {
  transition: all .5s;
}

.GvlQTG_expandable > .GvlQTG_expander-icon {
  cursor: pointer;
}

.GvlQTG_expandable > .GvlQTG_expander-icon:hover {
  color: #328cfa66;
}

.GvlQTG_expandable.GvlQTG_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .GvlQTG_modal .GvlQTG_modal-xl {
    width: 100% !important;
  }

  .GvlQTG_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.GvlQTG_d-none, .GvlQTG_d-block {
  transition: all .5s;
}

.GvlQTG_quantity span {
  cursor: pointer;
  margin-left: 5px;
  font-size: 20px;
}

._8eYf5G_productSelector {
  min-height: 65vh;
}

._8eYf5G_productSelector ._8eYf5G_categories {
  min-width: 200px;
  border-right: 1px solid #eaeaea;
  align-items: stretch;
  margin: 0;
  padding: 0 1rem 0 0;
  overflow-y: scroll;
}

._8eYf5G_productSelector ._8eYf5G_categories ._8eYf5G_listGroup {
  padding: 0;
  list-style-type: none;
}

._8eYf5G_productSelector ._8eYf5G_categories ._8eYf5G_listGroup li {
  min-height: 3rem;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
  padding: 0 1rem;
  line-height: 3rem;
}

._8eYf5G_productSelector ._8eYf5G_categories ._8eYf5G_listGroup._8eYf5G_selected {
  color: #4c469c;
  background-color: #c8c8c81a;
}

._8eYf5G_productSelector ._8eYf5G_categories ._8eYf5G_listGroup li:hover {
  background-color: #c8c8c81a;
}

._8eYf5G_productSelector ._8eYf5G_productCard {
  min-height: 110px;
  cursor: pointer;
}

._8eYf5G_productSelector ._8eYf5G_productCard._8eYf5G_selected {
  border: 1px solid #56d;
  box-shadow: 1px 3px 8px 5px #dcdcdc33;
}

._8eYf5G_productSelector ._8eYf5G_categoryChips {
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

._8eYf5G_productSelector ._8eYf5G_categoryChips li {
  cursor: pointer;
  min-width: 3rem;
  border: 1px solid #ddd;
  border-radius: 30px;
  flex-direction: row;
  margin: .1rem .3rem;
  padding: 0 .4rem;
  display: flex;
}

._8eYf5G_productSelector ._8eYf5G_categoryChips li._8eYf5G_selected {
  border-color: #4c469c !important;
}

.dudMhW_notifications {
  padding: 0;
  list-style-type: none;
}

.dudMhW_notifications li {
  border: 1px solid #eaeaea;
  border-radius: .4rem;
  align-items: center;
  margin-bottom: .5rem;
  padding: .2rem;
}

.dudMhW_notifications li .dudMhW_icon {
  width: 3rem;
  border-radius: .4rem;
  flex-grow: 0;
  margin-right: .5rem;
}

.dudMhW_notifications li .dudMhW_content {
  flex-grow: 1;
}

.dudMhW_notifications li .dudMhW_action {
  width: 3rem;
  height: 3rem;
  background-color: #dcdcdc66;
  border-radius: 50%;
  flex-grow: 0;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .aohMDa_h6, h5, .aohMDa_h5, h4, .aohMDa_h4, h3, .aohMDa_h3, h2, .aohMDa_h2, h1, .aohMDa_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .aohMDa_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .aohMDa_h1 {
    font-size: 2.5rem;
  }
}

h2, .aohMDa_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .aohMDa_h2 {
    font-size: 2rem;
  }
}

h3, .aohMDa_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .aohMDa_h3 {
    font-size: 1.75rem;
  }
}

h4, .aohMDa_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .aohMDa_h4 {
    font-size: 1.5rem;
  }
}

h5, .aohMDa_h5 {
  font-size: 1.25rem;
}

h6, .aohMDa_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .aohMDa_small {
  font-size: .875em;
}

mark, .aohMDa_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.aohMDa_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.aohMDa_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-1 {
    font-size: 5rem;
  }
}

.aohMDa_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-2 {
    font-size: 4.5rem;
  }
}

.aohMDa_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-3 {
    font-size: 4rem;
  }
}

.aohMDa_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-4 {
    font-size: 3.5rem;
  }
}

.aohMDa_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-5 {
    font-size: 3rem;
  }
}

.aohMDa_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .aohMDa_display-6 {
    font-size: 2.5rem;
  }
}

.aohMDa_list-unstyled, .aohMDa_list-inline {
  padding-left: 0;
  list-style: none;
}

.aohMDa_list-inline-item {
  display: inline-block;
}

.aohMDa_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.aohMDa_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.aohMDa_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.aohMDa_blockquote > :last-child {
  margin-bottom: 0;
}

.aohMDa_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.aohMDa_blockquote-footer:before {
  content: "— ";
}

.aohMDa_img-fluid {
  max-width: 100%;
  height: auto;
}

.aohMDa_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.aohMDa_figure {
  display: inline-block;
}

.aohMDa_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.aohMDa_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.aohMDa_container, .aohMDa_container-fluid, .aohMDa_container-xxl, .aohMDa_container-xl, .aohMDa_container-lg, .aohMDa_container-md, .aohMDa_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .aohMDa_container-sm, .aohMDa_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .aohMDa_container-md, .aohMDa_container-sm, .aohMDa_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .aohMDa_container-lg, .aohMDa_container-md, .aohMDa_container-sm, .aohMDa_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .aohMDa_container-xl, .aohMDa_container-lg, .aohMDa_container-md, .aohMDa_container-sm, .aohMDa_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .aohMDa_container-xxl, .aohMDa_container-xl, .aohMDa_container-lg, .aohMDa_container-md, .aohMDa_container-sm, .aohMDa_container {
    max-width: 1320px;
  }
}

.aohMDa_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.aohMDa_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.aohMDa_col {
  flex: 1 0;
}

.aohMDa_row-cols-auto > * {
  width: auto;
  flex: none;
}

.aohMDa_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.aohMDa_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.aohMDa_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.aohMDa_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.aohMDa_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.aohMDa_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.aohMDa_col-auto {
  width: auto;
  flex: none;
}

.aohMDa_col-1 {
  width: 8.33333%;
  flex: none;
}

.aohMDa_col-2 {
  width: 16.6667%;
  flex: none;
}

.aohMDa_col-3 {
  width: 25%;
  flex: none;
}

.aohMDa_col-4 {
  width: 33.3333%;
  flex: none;
}

.aohMDa_col-5 {
  width: 41.6667%;
  flex: none;
}

.aohMDa_col-6 {
  width: 50%;
  flex: none;
}

.aohMDa_col-7 {
  width: 58.3333%;
  flex: none;
}

.aohMDa_col-8 {
  width: 66.6667%;
  flex: none;
}

.aohMDa_col-9 {
  width: 75%;
  flex: none;
}

.aohMDa_col-10 {
  width: 83.3333%;
  flex: none;
}

.aohMDa_col-11 {
  width: 91.6667%;
  flex: none;
}

.aohMDa_col-12 {
  width: 100%;
  flex: none;
}

.aohMDa_offset-1 {
  margin-left: 8.33333%;
}

.aohMDa_offset-2 {
  margin-left: 16.6667%;
}

.aohMDa_offset-3 {
  margin-left: 25%;
}

.aohMDa_offset-4 {
  margin-left: 33.3333%;
}

.aohMDa_offset-5 {
  margin-left: 41.6667%;
}

.aohMDa_offset-6 {
  margin-left: 50%;
}

.aohMDa_offset-7 {
  margin-left: 58.3333%;
}

.aohMDa_offset-8 {
  margin-left: 66.6667%;
}

.aohMDa_offset-9 {
  margin-left: 75%;
}

.aohMDa_offset-10 {
  margin-left: 83.3333%;
}

.aohMDa_offset-11 {
  margin-left: 91.6667%;
}

.aohMDa_g-0, .aohMDa_gx-0 {
  --bs-gutter-x: 0;
}

.aohMDa_g-0, .aohMDa_gy-0 {
  --bs-gutter-y: 0;
}

.aohMDa_g-1, .aohMDa_gx-1 {
  --bs-gutter-x: .25rem;
}

.aohMDa_g-1, .aohMDa_gy-1 {
  --bs-gutter-y: .25rem;
}

.aohMDa_g-2, .aohMDa_gx-2 {
  --bs-gutter-x: .5rem;
}

.aohMDa_g-2, .aohMDa_gy-2 {
  --bs-gutter-y: .5rem;
}

.aohMDa_g-3, .aohMDa_gx-3 {
  --bs-gutter-x: 1rem;
}

.aohMDa_g-3, .aohMDa_gy-3 {
  --bs-gutter-y: 1rem;
}

.aohMDa_g-4, .aohMDa_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.aohMDa_g-4, .aohMDa_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.aohMDa_g-5, .aohMDa_gx-5 {
  --bs-gutter-x: 3rem;
}

.aohMDa_g-5, .aohMDa_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .aohMDa_col-sm {
    flex: 1 0;
  }

  .aohMDa_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .aohMDa_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .aohMDa_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .aohMDa_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .aohMDa_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .aohMDa_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-sm-auto {
    width: auto;
    flex: none;
  }

  .aohMDa_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .aohMDa_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .aohMDa_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .aohMDa_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .aohMDa_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .aohMDa_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .aohMDa_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .aohMDa_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .aohMDa_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .aohMDa_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .aohMDa_offset-sm-0 {
    margin-left: 0;
  }

  .aohMDa_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .aohMDa_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .aohMDa_offset-sm-3 {
    margin-left: 25%;
  }

  .aohMDa_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .aohMDa_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .aohMDa_offset-sm-6 {
    margin-left: 50%;
  }

  .aohMDa_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .aohMDa_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .aohMDa_offset-sm-9 {
    margin-left: 75%;
  }

  .aohMDa_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .aohMDa_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .aohMDa_g-sm-0, .aohMDa_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .aohMDa_g-sm-0, .aohMDa_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .aohMDa_g-sm-1, .aohMDa_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .aohMDa_g-sm-1, .aohMDa_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .aohMDa_g-sm-2, .aohMDa_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .aohMDa_g-sm-2, .aohMDa_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .aohMDa_g-sm-3, .aohMDa_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .aohMDa_g-sm-3, .aohMDa_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .aohMDa_g-sm-4, .aohMDa_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .aohMDa_g-sm-4, .aohMDa_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .aohMDa_g-sm-5, .aohMDa_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .aohMDa_g-sm-5, .aohMDa_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .aohMDa_col-md {
    flex: 1 0;
  }

  .aohMDa_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .aohMDa_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .aohMDa_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .aohMDa_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .aohMDa_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .aohMDa_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-md-auto {
    width: auto;
    flex: none;
  }

  .aohMDa_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .aohMDa_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-md-3 {
    width: 25%;
    flex: none;
  }

  .aohMDa_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .aohMDa_col-md-6 {
    width: 50%;
    flex: none;
  }

  .aohMDa_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .aohMDa_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .aohMDa_col-md-9 {
    width: 75%;
    flex: none;
  }

  .aohMDa_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .aohMDa_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .aohMDa_col-md-12 {
    width: 100%;
    flex: none;
  }

  .aohMDa_offset-md-0 {
    margin-left: 0;
  }

  .aohMDa_offset-md-1 {
    margin-left: 8.33333%;
  }

  .aohMDa_offset-md-2 {
    margin-left: 16.6667%;
  }

  .aohMDa_offset-md-3 {
    margin-left: 25%;
  }

  .aohMDa_offset-md-4 {
    margin-left: 33.3333%;
  }

  .aohMDa_offset-md-5 {
    margin-left: 41.6667%;
  }

  .aohMDa_offset-md-6 {
    margin-left: 50%;
  }

  .aohMDa_offset-md-7 {
    margin-left: 58.3333%;
  }

  .aohMDa_offset-md-8 {
    margin-left: 66.6667%;
  }

  .aohMDa_offset-md-9 {
    margin-left: 75%;
  }

  .aohMDa_offset-md-10 {
    margin-left: 83.3333%;
  }

  .aohMDa_offset-md-11 {
    margin-left: 91.6667%;
  }

  .aohMDa_g-md-0, .aohMDa_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .aohMDa_g-md-0, .aohMDa_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .aohMDa_g-md-1, .aohMDa_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .aohMDa_g-md-1, .aohMDa_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .aohMDa_g-md-2, .aohMDa_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .aohMDa_g-md-2, .aohMDa_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .aohMDa_g-md-3, .aohMDa_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .aohMDa_g-md-3, .aohMDa_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .aohMDa_g-md-4, .aohMDa_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .aohMDa_g-md-4, .aohMDa_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .aohMDa_g-md-5, .aohMDa_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .aohMDa_g-md-5, .aohMDa_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .aohMDa_col-lg {
    flex: 1 0;
  }

  .aohMDa_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .aohMDa_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .aohMDa_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .aohMDa_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .aohMDa_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .aohMDa_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-lg-auto {
    width: auto;
    flex: none;
  }

  .aohMDa_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .aohMDa_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .aohMDa_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .aohMDa_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .aohMDa_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .aohMDa_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .aohMDa_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .aohMDa_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .aohMDa_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .aohMDa_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .aohMDa_offset-lg-0 {
    margin-left: 0;
  }

  .aohMDa_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .aohMDa_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .aohMDa_offset-lg-3 {
    margin-left: 25%;
  }

  .aohMDa_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .aohMDa_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .aohMDa_offset-lg-6 {
    margin-left: 50%;
  }

  .aohMDa_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .aohMDa_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .aohMDa_offset-lg-9 {
    margin-left: 75%;
  }

  .aohMDa_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .aohMDa_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .aohMDa_g-lg-0, .aohMDa_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .aohMDa_g-lg-0, .aohMDa_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .aohMDa_g-lg-1, .aohMDa_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .aohMDa_g-lg-1, .aohMDa_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .aohMDa_g-lg-2, .aohMDa_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .aohMDa_g-lg-2, .aohMDa_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .aohMDa_g-lg-3, .aohMDa_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .aohMDa_g-lg-3, .aohMDa_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .aohMDa_g-lg-4, .aohMDa_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .aohMDa_g-lg-4, .aohMDa_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .aohMDa_g-lg-5, .aohMDa_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .aohMDa_g-lg-5, .aohMDa_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .aohMDa_col-xl {
    flex: 1 0;
  }

  .aohMDa_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .aohMDa_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .aohMDa_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .aohMDa_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .aohMDa_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .aohMDa_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-xl-auto {
    width: auto;
    flex: none;
  }

  .aohMDa_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .aohMDa_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .aohMDa_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .aohMDa_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .aohMDa_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .aohMDa_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .aohMDa_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .aohMDa_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .aohMDa_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .aohMDa_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .aohMDa_offset-xl-0 {
    margin-left: 0;
  }

  .aohMDa_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .aohMDa_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .aohMDa_offset-xl-3 {
    margin-left: 25%;
  }

  .aohMDa_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .aohMDa_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .aohMDa_offset-xl-6 {
    margin-left: 50%;
  }

  .aohMDa_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .aohMDa_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .aohMDa_offset-xl-9 {
    margin-left: 75%;
  }

  .aohMDa_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .aohMDa_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .aohMDa_g-xl-0, .aohMDa_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .aohMDa_g-xl-0, .aohMDa_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .aohMDa_g-xl-1, .aohMDa_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .aohMDa_g-xl-1, .aohMDa_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .aohMDa_g-xl-2, .aohMDa_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .aohMDa_g-xl-2, .aohMDa_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .aohMDa_g-xl-3, .aohMDa_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .aohMDa_g-xl-3, .aohMDa_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .aohMDa_g-xl-4, .aohMDa_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .aohMDa_g-xl-4, .aohMDa_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .aohMDa_g-xl-5, .aohMDa_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .aohMDa_g-xl-5, .aohMDa_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .aohMDa_col-xxl {
    flex: 1 0;
  }

  .aohMDa_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .aohMDa_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .aohMDa_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .aohMDa_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .aohMDa_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .aohMDa_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .aohMDa_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .aohMDa_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .aohMDa_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .aohMDa_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .aohMDa_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .aohMDa_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .aohMDa_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .aohMDa_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .aohMDa_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .aohMDa_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .aohMDa_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .aohMDa_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .aohMDa_offset-xxl-0 {
    margin-left: 0;
  }

  .aohMDa_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .aohMDa_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .aohMDa_offset-xxl-3 {
    margin-left: 25%;
  }

  .aohMDa_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .aohMDa_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .aohMDa_offset-xxl-6 {
    margin-left: 50%;
  }

  .aohMDa_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .aohMDa_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .aohMDa_offset-xxl-9 {
    margin-left: 75%;
  }

  .aohMDa_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .aohMDa_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .aohMDa_g-xxl-0, .aohMDa_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .aohMDa_g-xxl-0, .aohMDa_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .aohMDa_g-xxl-1, .aohMDa_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .aohMDa_g-xxl-1, .aohMDa_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .aohMDa_g-xxl-2, .aohMDa_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .aohMDa_g-xxl-2, .aohMDa_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .aohMDa_g-xxl-3, .aohMDa_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .aohMDa_g-xxl-3, .aohMDa_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .aohMDa_g-xxl-4, .aohMDa_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .aohMDa_g-xxl-4, .aohMDa_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .aohMDa_g-xxl-5, .aohMDa_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .aohMDa_g-xxl-5, .aohMDa_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.aohMDa_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.aohMDa_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.aohMDa_table > tbody {
  vertical-align: inherit;
}

.aohMDa_table > thead {
  vertical-align: bottom;
}

.aohMDa_table-group-divider {
  border-top: 2px solid;
}

.aohMDa_caption-top {
  caption-side: top;
}

.aohMDa_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.aohMDa_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.aohMDa_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.aohMDa_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.aohMDa_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.aohMDa_table-striped > tbody > tr:nth-of-type(2n+1) > *, .aohMDa_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.aohMDa_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.aohMDa_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.aohMDa_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.aohMDa_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .aohMDa_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .aohMDa_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .aohMDa_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .aohMDa_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .aohMDa_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.aohMDa_form-label {
  margin-bottom: .5rem;
}

.aohMDa_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.aohMDa_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.aohMDa_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.aohMDa_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.aohMDa_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-control {
    transition: none;
  }
}

.aohMDa_form-control[type="file"] {
  overflow: hidden;
}

.aohMDa_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.aohMDa_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.aohMDa_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.aohMDa_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.aohMDa_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.aohMDa_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-control::file-selector-button {
    transition: none;
  }
}

.aohMDa_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.aohMDa_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.aohMDa_form-control-plaintext:focus {
  outline: 0;
}

.aohMDa_form-control-plaintext.aohMDa_form-control-sm, .aohMDa_form-control-plaintext.aohMDa_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.aohMDa_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.aohMDa_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.aohMDa_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.aohMDa_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.aohMDa_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.aohMDa_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.aohMDa_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.aohMDa_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.aohMDa_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.aohMDa_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.aohMDa_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.aohMDa_form-control-color.aohMDa_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.aohMDa_form-control-color.aohMDa_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.aohMDa_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-select {
    transition: none;
  }
}

.aohMDa_form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.aohMDa_form-select[multiple], .aohMDa_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.aohMDa_form-select:disabled {
  background-color: #e9ecef;
}

.aohMDa_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.aohMDa_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.aohMDa_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.aohMDa_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.aohMDa_form-check .aohMDa_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.aohMDa_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.aohMDa_form-check-reverse .aohMDa_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.aohMDa_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.aohMDa_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.aohMDa_form-check-input[type="radio"] {
  border-radius: 50%;
}

.aohMDa_form-check-input:active {
  filter: brightness(90%);
}

.aohMDa_form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.aohMDa_form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.aohMDa_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.aohMDa_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.aohMDa_form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.aohMDa_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.aohMDa_form-check-input[disabled] ~ .aohMDa_form-check-label, .aohMDa_form-check-input:disabled ~ .aohMDa_form-check-label {
  cursor: default;
  opacity: .5;
}

.aohMDa_form-switch {
  padding-left: 2.5em;
}

.aohMDa_form-switch .aohMDa_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-switch .aohMDa_form-check-input {
    transition: none;
  }
}

.aohMDa_form-switch .aohMDa_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.aohMDa_form-switch .aohMDa_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.aohMDa_form-switch.aohMDa_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.aohMDa_form-switch.aohMDa_form-check-reverse .aohMDa_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.aohMDa_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.aohMDa_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.aohMDa_btn-check[disabled] + .aohMDa_btn, .aohMDa_btn-check:disabled + .aohMDa_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.aohMDa_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.aohMDa_form-range:focus {
  outline: 0;
}

.aohMDa_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.aohMDa_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.aohMDa_form-range::-moz-focus-outer {
  border: 0;
}

.aohMDa_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.aohMDa_form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.aohMDa_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.aohMDa_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-range::-moz-range-thumb {
    transition: none;
  }
}

.aohMDa_form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.aohMDa_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.aohMDa_form-range:disabled {
  pointer-events: none;
}

.aohMDa_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.aohMDa_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.aohMDa_form-floating {
  position: relative;
}

.aohMDa_form-floating > .aohMDa_form-control, .aohMDa_form-floating > .aohMDa_form-control-plaintext, .aohMDa_form-floating > .aohMDa_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.aohMDa_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_form-floating > label {
    transition: none;
  }
}

.aohMDa_form-floating > .aohMDa_form-control, .aohMDa_form-floating > .aohMDa_form-control-plaintext {
  padding: 1rem .75rem;
}

.aohMDa_form-floating > .aohMDa_form-control::placeholder, .aohMDa_form-floating > .aohMDa_form-control-plaintext::placeholder {
  color: #0000;
}

.aohMDa_form-floating > .aohMDa_form-control:focus, .aohMDa_form-floating > .aohMDa_form-control:not(:placeholder-shown), .aohMDa_form-floating > .aohMDa_form-control-plaintext:focus, .aohMDa_form-floating > .aohMDa_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.aohMDa_form-floating > .aohMDa_form-control:-webkit-autofill, .aohMDa_form-floating > .aohMDa_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.aohMDa_form-floating > .aohMDa_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.aohMDa_form-floating > .aohMDa_form-control:focus ~ label, .aohMDa_form-floating > .aohMDa_form-control:not(:placeholder-shown) ~ label, .aohMDa_form-floating > .aohMDa_form-control-plaintext ~ label, .aohMDa_form-floating > .aohMDa_form-select ~ label, .aohMDa_form-floating > .aohMDa_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.aohMDa_form-floating > .aohMDa_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.aohMDa_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.aohMDa_input-group > .aohMDa_form-control, .aohMDa_input-group > .aohMDa_form-select, .aohMDa_input-group > .aohMDa_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.aohMDa_input-group > .aohMDa_form-control:focus, .aohMDa_input-group > .aohMDa_form-select:focus, .aohMDa_input-group > .aohMDa_form-floating:focus-within {
  z-index: 5;
}

.aohMDa_input-group .aohMDa_btn {
  z-index: 2;
  position: relative;
}

.aohMDa_input-group .aohMDa_btn:focus {
  z-index: 5;
}

.aohMDa_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.aohMDa_input-group-lg > .aohMDa_form-control, .aohMDa_input-group-lg > .aohMDa_form-select, .aohMDa_input-group-lg > .aohMDa_input-group-text, .aohMDa_input-group-lg > .aohMDa_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.aohMDa_input-group-sm > .aohMDa_form-control, .aohMDa_input-group-sm > .aohMDa_form-select, .aohMDa_input-group-sm > .aohMDa_input-group-text, .aohMDa_input-group-sm > .aohMDa_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.aohMDa_input-group-lg > .aohMDa_form-select, .aohMDa_input-group-sm > .aohMDa_form-select {
  padding-right: 3rem;
}

.aohMDa_input-group:not(.aohMDa_has-validation) > :not(:last-child):not(.aohMDa_dropdown-toggle):not(.aohMDa_dropdown-menu):not(.aohMDa_form-floating), .aohMDa_input-group:not(.aohMDa_has-validation) > .aohMDa_dropdown-toggle:nth-last-child(n+3), .aohMDa_input-group:not(.aohMDa_has-validation) > .aohMDa_form-floating:not(:last-child) > .aohMDa_form-control, .aohMDa_input-group:not(.aohMDa_has-validation) > .aohMDa_form-floating:not(:last-child) > .aohMDa_form-select, .aohMDa_input-group.aohMDa_has-validation > :nth-last-child(n+3):not(.aohMDa_dropdown-toggle):not(.aohMDa_dropdown-menu):not(.aohMDa_form-floating), .aohMDa_input-group.aohMDa_has-validation > .aohMDa_dropdown-toggle:nth-last-child(n+4), .aohMDa_input-group.aohMDa_has-validation > .aohMDa_form-floating:nth-last-child(n+3) > .aohMDa_form-control, .aohMDa_input-group.aohMDa_has-validation > .aohMDa_form-floating:nth-last-child(n+3) > .aohMDa_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.aohMDa_input-group > :not(:first-child):not(.aohMDa_dropdown-menu):not(.aohMDa_valid-tooltip):not(.aohMDa_valid-feedback):not(.aohMDa_invalid-tooltip):not(.aohMDa_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.aohMDa_input-group > .aohMDa_form-floating:not(:first-child) > .aohMDa_form-control, .aohMDa_input-group > .aohMDa_form-floating:not(:first-child) > .aohMDa_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.aohMDa_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.aohMDa_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.aohMDa_was-validated :valid ~ .aohMDa_valid-feedback, .aohMDa_was-validated :valid ~ .aohMDa_valid-tooltip, .aohMDa_is-valid ~ .aohMDa_valid-feedback, .aohMDa_is-valid ~ .aohMDa_valid-tooltip {
  display: block;
}

.aohMDa_was-validated .aohMDa_form-control:valid, .aohMDa_form-control.aohMDa_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.aohMDa_was-validated .aohMDa_form-control:valid:focus, .aohMDa_form-control.aohMDa_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.aohMDa_was-validated textarea.aohMDa_form-control:valid, textarea.aohMDa_form-control.aohMDa_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.aohMDa_was-validated .aohMDa_form-select:valid, .aohMDa_form-select.aohMDa_is-valid {
  border-color: #198754;
}

.aohMDa_was-validated .aohMDa_form-select:valid:not([multiple]):not([size]), .aohMDa_was-validated .aohMDa_form-select:valid:not([multiple])[size="1"], .aohMDa_form-select.aohMDa_is-valid:not([multiple]):not([size]), .aohMDa_form-select.aohMDa_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.aohMDa_was-validated .aohMDa_form-select:valid:focus, .aohMDa_form-select.aohMDa_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.aohMDa_was-validated .aohMDa_form-control-color:valid, .aohMDa_form-control-color.aohMDa_is-valid {
  width: calc(1.5em + 3.75rem);
}

.aohMDa_was-validated .aohMDa_form-check-input:valid, .aohMDa_form-check-input.aohMDa_is-valid {
  border-color: #198754;
}

.aohMDa_was-validated .aohMDa_form-check-input:valid:checked, .aohMDa_form-check-input.aohMDa_is-valid:checked {
  background-color: #198754;
}

.aohMDa_was-validated .aohMDa_form-check-input:valid:focus, .aohMDa_form-check-input.aohMDa_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.aohMDa_was-validated .aohMDa_form-check-input:valid ~ .aohMDa_form-check-label, .aohMDa_form-check-input.aohMDa_is-valid ~ .aohMDa_form-check-label {
  color: #198754;
}

.aohMDa_form-check-inline .aohMDa_form-check-input ~ .aohMDa_valid-feedback {
  margin-left: .5em;
}

.aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-control:not(:focus):valid, .aohMDa_input-group > .aohMDa_form-control:not(:focus).aohMDa_is-valid, .aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-select:not(:focus):valid, .aohMDa_input-group > .aohMDa_form-select:not(:focus).aohMDa_is-valid, .aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-floating:not(:focus-within):valid, .aohMDa_input-group > .aohMDa_form-floating:not(:focus-within).aohMDa_is-valid {
  z-index: 3;
}

.aohMDa_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.aohMDa_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.aohMDa_was-validated :invalid ~ .aohMDa_invalid-feedback, .aohMDa_was-validated :invalid ~ .aohMDa_invalid-tooltip, .aohMDa_is-invalid ~ .aohMDa_invalid-feedback, .aohMDa_is-invalid ~ .aohMDa_invalid-tooltip {
  display: block;
}

.aohMDa_was-validated .aohMDa_form-control:invalid, .aohMDa_form-control.aohMDa_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.aohMDa_was-validated .aohMDa_form-control:invalid:focus, .aohMDa_form-control.aohMDa_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.aohMDa_was-validated textarea.aohMDa_form-control:invalid, textarea.aohMDa_form-control.aohMDa_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.aohMDa_was-validated .aohMDa_form-select:invalid, .aohMDa_form-select.aohMDa_is-invalid {
  border-color: #dc3545;
}

.aohMDa_was-validated .aohMDa_form-select:invalid:not([multiple]):not([size]), .aohMDa_was-validated .aohMDa_form-select:invalid:not([multiple])[size="1"], .aohMDa_form-select.aohMDa_is-invalid:not([multiple]):not([size]), .aohMDa_form-select.aohMDa_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.aohMDa_was-validated .aohMDa_form-select:invalid:focus, .aohMDa_form-select.aohMDa_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.aohMDa_was-validated .aohMDa_form-control-color:invalid, .aohMDa_form-control-color.aohMDa_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.aohMDa_was-validated .aohMDa_form-check-input:invalid, .aohMDa_form-check-input.aohMDa_is-invalid {
  border-color: #dc3545;
}

.aohMDa_was-validated .aohMDa_form-check-input:invalid:checked, .aohMDa_form-check-input.aohMDa_is-invalid:checked {
  background-color: #dc3545;
}

.aohMDa_was-validated .aohMDa_form-check-input:invalid:focus, .aohMDa_form-check-input.aohMDa_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.aohMDa_was-validated .aohMDa_form-check-input:invalid ~ .aohMDa_form-check-label, .aohMDa_form-check-input.aohMDa_is-invalid ~ .aohMDa_form-check-label {
  color: #dc3545;
}

.aohMDa_form-check-inline .aohMDa_form-check-input ~ .aohMDa_invalid-feedback {
  margin-left: .5em;
}

.aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-control:not(:focus):invalid, .aohMDa_input-group > .aohMDa_form-control:not(:focus).aohMDa_is-invalid, .aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-select:not(:focus):invalid, .aohMDa_input-group > .aohMDa_form-select:not(:focus).aohMDa_is-invalid, .aohMDa_was-validated .aohMDa_input-group > .aohMDa_form-floating:not(:focus-within):invalid, .aohMDa_input-group > .aohMDa_form-floating:not(:focus-within).aohMDa_is-invalid {
  z-index: 4;
}

.aohMDa_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_btn {
    transition: none;
  }
}

.aohMDa_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.aohMDa_btn-check + .aohMDa_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.aohMDa_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.aohMDa_btn-check:focus-visible + .aohMDa_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.aohMDa_btn-check:checked + .aohMDa_btn, :not(.aohMDa_btn-check) + .aohMDa_btn:active, .aohMDa_btn:first-child:active, .aohMDa_btn.aohMDa_active, .aohMDa_btn.aohMDa_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.aohMDa_btn-check:checked + .aohMDa_btn:focus-visible, :not(.aohMDa_btn-check) + .aohMDa_btn:active:focus-visible, .aohMDa_btn:first-child:active:focus-visible, .aohMDa_btn.aohMDa_active:focus-visible, .aohMDa_btn.aohMDa_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.aohMDa_btn:disabled, .aohMDa_btn.aohMDa_disabled, fieldset:disabled .aohMDa_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.aohMDa_btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.aohMDa_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.aohMDa_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.aohMDa_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.aohMDa_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.aohMDa_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.aohMDa_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.aohMDa_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.aohMDa_btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.aohMDa_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.aohMDa_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.aohMDa_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.aohMDa_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.aohMDa_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.aohMDa_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.aohMDa_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.aohMDa_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.aohMDa_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.aohMDa_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.aohMDa_btn-lg, .aohMDa_btn-group-lg > .aohMDa_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.aohMDa_btn-sm, .aohMDa_btn-group-sm > .aohMDa_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.aohMDa_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_fade {
    transition: none;
  }
}

.aohMDa_fade:not(.aohMDa_show) {
  opacity: 0;
}

.aohMDa_collapse:not(.aohMDa_show) {
  display: none;
}

.aohMDa_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_collapsing {
    transition: none;
  }
}

.aohMDa_collapsing.aohMDa_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_collapsing.aohMDa_collapse-horizontal {
    transition: none;
  }
}

.aohMDa_dropup, .aohMDa_dropend, .aohMDa_dropdown, .aohMDa_dropstart, .aohMDa_dropup-center, .aohMDa_dropdown-center {
  position: relative;
}

.aohMDa_dropdown-toggle {
  white-space: nowrap;
}

.aohMDa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.aohMDa_dropdown-toggle:empty:after {
  margin-left: 0;
}

.aohMDa_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.aohMDa_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.aohMDa_dropdown-menu-start {
  --bs-position: start;
}

.aohMDa_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.aohMDa_dropdown-menu-end {
  --bs-position: end;
}

.aohMDa_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .aohMDa_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .aohMDa_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .aohMDa_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .aohMDa_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .aohMDa_dropdown-menu-md-start {
    --bs-position: start;
  }

  .aohMDa_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .aohMDa_dropdown-menu-md-end {
    --bs-position: end;
  }

  .aohMDa_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .aohMDa_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .aohMDa_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .aohMDa_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .aohMDa_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .aohMDa_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .aohMDa_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .aohMDa_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .aohMDa_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .aohMDa_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .aohMDa_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .aohMDa_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .aohMDa_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.aohMDa_dropup .aohMDa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.aohMDa_dropup .aohMDa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.aohMDa_dropup .aohMDa_dropdown-toggle:empty:after {
  margin-left: 0;
}

.aohMDa_dropend .aohMDa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.aohMDa_dropend .aohMDa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.aohMDa_dropend .aohMDa_dropdown-toggle:empty:after {
  margin-left: 0;
}

.aohMDa_dropend .aohMDa_dropdown-toggle:after {
  vertical-align: 0;
}

.aohMDa_dropstart .aohMDa_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.aohMDa_dropstart .aohMDa_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.aohMDa_dropstart .aohMDa_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.aohMDa_dropstart .aohMDa_dropdown-toggle:empty:after {
  margin-left: 0;
}

.aohMDa_dropstart .aohMDa_dropdown-toggle:before {
  vertical-align: 0;
}

.aohMDa_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.aohMDa_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.aohMDa_dropdown-item:hover, .aohMDa_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.aohMDa_dropdown-item.aohMDa_active, .aohMDa_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.aohMDa_dropdown-item.aohMDa_disabled, .aohMDa_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.aohMDa_dropdown-menu.aohMDa_show {
  display: block;
}

.aohMDa_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.aohMDa_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.aohMDa_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.aohMDa_btn-group, .aohMDa_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.aohMDa_btn-group > .aohMDa_btn, .aohMDa_btn-group-vertical > .aohMDa_btn {
  flex: auto;
  position: relative;
}

.aohMDa_btn-group > .aohMDa_btn-check:checked + .aohMDa_btn, .aohMDa_btn-group > .aohMDa_btn-check:focus + .aohMDa_btn, .aohMDa_btn-group > .aohMDa_btn:hover, .aohMDa_btn-group > .aohMDa_btn:focus, .aohMDa_btn-group > .aohMDa_btn:active, .aohMDa_btn-group > .aohMDa_btn.aohMDa_active, .aohMDa_btn-group-vertical > .aohMDa_btn-check:checked + .aohMDa_btn, .aohMDa_btn-group-vertical > .aohMDa_btn-check:focus + .aohMDa_btn, .aohMDa_btn-group-vertical > .aohMDa_btn:hover, .aohMDa_btn-group-vertical > .aohMDa_btn:focus, .aohMDa_btn-group-vertical > .aohMDa_btn:active, .aohMDa_btn-group-vertical > .aohMDa_btn.aohMDa_active {
  z-index: 1;
}

.aohMDa_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.aohMDa_btn-toolbar .aohMDa_input-group {
  width: auto;
}

.aohMDa_btn-group {
  border-radius: .375rem;
}

.aohMDa_btn-group > :not(.aohMDa_btn-check:first-child) + .aohMDa_btn, .aohMDa_btn-group > .aohMDa_btn-group:not(:first-child) {
  margin-left: -1px;
}

.aohMDa_btn-group > .aohMDa_btn:not(:last-child):not(.aohMDa_dropdown-toggle), .aohMDa_btn-group > .aohMDa_btn.aohMDa_dropdown-toggle-split:first-child, .aohMDa_btn-group > .aohMDa_btn-group:not(:last-child) > .aohMDa_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.aohMDa_btn-group > .aohMDa_btn:nth-child(n+3), .aohMDa_btn-group > :not(.aohMDa_btn-check) + .aohMDa_btn, .aohMDa_btn-group > .aohMDa_btn-group:not(:first-child) > .aohMDa_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.aohMDa_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.aohMDa_dropdown-toggle-split:after, .aohMDa_dropup .aohMDa_dropdown-toggle-split:after, .aohMDa_dropend .aohMDa_dropdown-toggle-split:after {
  margin-left: 0;
}

.aohMDa_dropstart .aohMDa_dropdown-toggle-split:before {
  margin-right: 0;
}

.aohMDa_btn-sm + .aohMDa_dropdown-toggle-split, .aohMDa_btn-group-sm > .aohMDa_btn + .aohMDa_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.aohMDa_btn-lg + .aohMDa_dropdown-toggle-split, .aohMDa_btn-group-lg > .aohMDa_btn + .aohMDa_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.aohMDa_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.aohMDa_btn-group-vertical > .aohMDa_btn, .aohMDa_btn-group-vertical > .aohMDa_btn-group {
  width: 100%;
}

.aohMDa_btn-group-vertical > .aohMDa_btn:not(:first-child), .aohMDa_btn-group-vertical > .aohMDa_btn-group:not(:first-child) {
  margin-top: -1px;
}

.aohMDa_btn-group-vertical > .aohMDa_btn:not(:last-child):not(.aohMDa_dropdown-toggle), .aohMDa_btn-group-vertical > .aohMDa_btn-group:not(:last-child) > .aohMDa_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.aohMDa_btn-group-vertical > .aohMDa_btn ~ .aohMDa_btn, .aohMDa_btn-group-vertical > .aohMDa_btn-group:not(:first-child) > .aohMDa_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.aohMDa_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.aohMDa_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_nav-link {
    transition: none;
  }
}

.aohMDa_nav-link:hover, .aohMDa_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.aohMDa_nav-link.aohMDa_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.aohMDa_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.aohMDa_nav-tabs .aohMDa_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.aohMDa_nav-tabs .aohMDa_nav-link:hover, .aohMDa_nav-tabs .aohMDa_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.aohMDa_nav-tabs .aohMDa_nav-link.aohMDa_disabled, .aohMDa_nav-tabs .aohMDa_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.aohMDa_nav-tabs .aohMDa_nav-link.aohMDa_active, .aohMDa_nav-tabs .aohMDa_nav-item.aohMDa_show .aohMDa_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.aohMDa_nav-tabs .aohMDa_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.aohMDa_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.aohMDa_nav-pills .aohMDa_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.aohMDa_nav-pills .aohMDa_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.aohMDa_nav-pills .aohMDa_nav-link.aohMDa_active, .aohMDa_nav-pills .aohMDa_show > .aohMDa_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.aohMDa_nav-fill > .aohMDa_nav-link, .aohMDa_nav-fill .aohMDa_nav-item {
  text-align: center;
  flex: auto;
}

.aohMDa_nav-justified > .aohMDa_nav-link, .aohMDa_nav-justified .aohMDa_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.aohMDa_nav-fill .aohMDa_nav-item .aohMDa_nav-link, .aohMDa_nav-justified .aohMDa_nav-item .aohMDa_nav-link {
  width: 100%;
}

.aohMDa_tab-content > .aohMDa_tab-pane {
  display: none;
}

.aohMDa_tab-content > .aohMDa_active {
  display: block;
}

.aohMDa_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.aohMDa_navbar > .aohMDa_container, .aohMDa_navbar > .aohMDa_container-fluid, .aohMDa_navbar > .aohMDa_container-sm, .aohMDa_navbar > .aohMDa_container-md, .aohMDa_navbar > .aohMDa_container-lg, .aohMDa_navbar > .aohMDa_container-xl, .aohMDa_navbar > .aohMDa_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.aohMDa_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.aohMDa_navbar-brand:hover, .aohMDa_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.aohMDa_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.aohMDa_navbar-nav .aohMDa_show > .aohMDa_nav-link, .aohMDa_navbar-nav .aohMDa_nav-link.aohMDa_active {
  color: var(--bs-navbar-active-color);
}

.aohMDa_navbar-nav .aohMDa_dropdown-menu {
  position: static;
}

.aohMDa_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.aohMDa_navbar-text a, .aohMDa_navbar-text a:hover, .aohMDa_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.aohMDa_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.aohMDa_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_navbar-toggler {
    transition: none;
  }
}

.aohMDa_navbar-toggler:hover {
  text-decoration: none;
}

.aohMDa_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.aohMDa_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.aohMDa_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .aohMDa_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-nav {
    flex-direction: row;
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-nav .aohMDa_dropdown-menu {
    position: absolute;
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-nav .aohMDa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-nav-scroll {
    overflow: visible;
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .aohMDa_navbar-expand-sm .aohMDa_navbar-toggler {
    display: none;
  }

  .aohMDa_navbar-expand-sm .aohMDa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .aohMDa_navbar-expand-sm .aohMDa_offcanvas .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_navbar-expand-sm .aohMDa_offcanvas .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .aohMDa_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-nav {
    flex-direction: row;
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-nav .aohMDa_dropdown-menu {
    position: absolute;
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-nav .aohMDa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-nav-scroll {
    overflow: visible;
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .aohMDa_navbar-expand-md .aohMDa_navbar-toggler {
    display: none;
  }

  .aohMDa_navbar-expand-md .aohMDa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .aohMDa_navbar-expand-md .aohMDa_offcanvas .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_navbar-expand-md .aohMDa_offcanvas .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .aohMDa_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-nav {
    flex-direction: row;
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-nav .aohMDa_dropdown-menu {
    position: absolute;
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-nav .aohMDa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-nav-scroll {
    overflow: visible;
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .aohMDa_navbar-expand-lg .aohMDa_navbar-toggler {
    display: none;
  }

  .aohMDa_navbar-expand-lg .aohMDa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .aohMDa_navbar-expand-lg .aohMDa_offcanvas .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_navbar-expand-lg .aohMDa_offcanvas .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .aohMDa_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-nav {
    flex-direction: row;
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-nav .aohMDa_dropdown-menu {
    position: absolute;
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-nav .aohMDa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-nav-scroll {
    overflow: visible;
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .aohMDa_navbar-expand-xl .aohMDa_navbar-toggler {
    display: none;
  }

  .aohMDa_navbar-expand-xl .aohMDa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .aohMDa_navbar-expand-xl .aohMDa_offcanvas .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_navbar-expand-xl .aohMDa_offcanvas .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .aohMDa_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-nav {
    flex-direction: row;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-nav .aohMDa_dropdown-menu {
    position: absolute;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-nav .aohMDa_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-nav-scroll {
    overflow: visible;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_navbar-toggler {
    display: none;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_offcanvas .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_navbar-expand-xxl .aohMDa_offcanvas .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.aohMDa_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.aohMDa_navbar-expand .aohMDa_navbar-nav {
  flex-direction: row;
}

.aohMDa_navbar-expand .aohMDa_navbar-nav .aohMDa_dropdown-menu {
  position: absolute;
}

.aohMDa_navbar-expand .aohMDa_navbar-nav .aohMDa_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.aohMDa_navbar-expand .aohMDa_navbar-nav-scroll {
  overflow: visible;
}

.aohMDa_navbar-expand .aohMDa_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.aohMDa_navbar-expand .aohMDa_navbar-toggler {
  display: none;
}

.aohMDa_navbar-expand .aohMDa_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.aohMDa_navbar-expand .aohMDa_offcanvas .aohMDa_offcanvas-header {
  display: none;
}

.aohMDa_navbar-expand .aohMDa_offcanvas .aohMDa_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.aohMDa_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.aohMDa_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.aohMDa_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.aohMDa_card > .aohMDa_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.aohMDa_card > .aohMDa_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.aohMDa_card > .aohMDa_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.aohMDa_card > .aohMDa_card-header + .aohMDa_list-group, .aohMDa_card > .aohMDa_list-group + .aohMDa_card-footer {
  border-top: 0;
}

.aohMDa_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.aohMDa_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.aohMDa_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.aohMDa_card-text:last-child {
  margin-bottom: 0;
}

.aohMDa_card-link + .aohMDa_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.aohMDa_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.aohMDa_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.aohMDa_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.aohMDa_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.aohMDa_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.aohMDa_card-header-tabs .aohMDa_nav-link.aohMDa_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.aohMDa_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.aohMDa_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.aohMDa_card-img, .aohMDa_card-img-top, .aohMDa_card-img-bottom {
  width: 100%;
}

.aohMDa_card-img, .aohMDa_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.aohMDa_card-img, .aohMDa_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.aohMDa_card-group > .aohMDa_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .aohMDa_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .aohMDa_card-group > .aohMDa_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .aohMDa_card-group > .aohMDa_card + .aohMDa_card {
    border-left: 0;
    margin-left: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:last-child) .aohMDa_card-img-top, .aohMDa_card-group > .aohMDa_card:not(:last-child) .aohMDa_card-header {
    border-top-right-radius: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:last-child) .aohMDa_card-img-bottom, .aohMDa_card-group > .aohMDa_card:not(:last-child) .aohMDa_card-footer {
    border-bottom-right-radius: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:first-child) .aohMDa_card-img-top, .aohMDa_card-group > .aohMDa_card:not(:first-child) .aohMDa_card-header {
    border-top-left-radius: 0;
  }

  .aohMDa_card-group > .aohMDa_card:not(:first-child) .aohMDa_card-img-bottom, .aohMDa_card-group > .aohMDa_card:not(:first-child) .aohMDa_card-footer {
    border-bottom-left-radius: 0;
  }
}

.aohMDa_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.aohMDa_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_accordion-button {
    transition: none;
  }
}

.aohMDa_accordion-button:not(.aohMDa_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.aohMDa_accordion-button:not(.aohMDa_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.aohMDa_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_accordion-button:after {
    transition: none;
  }
}

.aohMDa_accordion-button:hover {
  z-index: 2;
}

.aohMDa_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.aohMDa_accordion-header {
  margin-bottom: 0;
}

.aohMDa_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.aohMDa_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.aohMDa_accordion-item:first-of-type .aohMDa_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.aohMDa_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.aohMDa_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.aohMDa_accordion-item:last-of-type .aohMDa_accordion-button.aohMDa_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.aohMDa_accordion-item:last-of-type .aohMDa_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.aohMDa_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.aohMDa_accordion-flush .aohMDa_accordion-collapse {
  border-width: 0;
}

.aohMDa_accordion-flush .aohMDa_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.aohMDa_accordion-flush .aohMDa_accordion-item:first-child {
  border-top: 0;
}

.aohMDa_accordion-flush .aohMDa_accordion-item:last-child {
  border-bottom: 0;
}

.aohMDa_accordion-flush .aohMDa_accordion-item .aohMDa_accordion-button, .aohMDa_accordion-flush .aohMDa_accordion-item .aohMDa_accordion-button.aohMDa_collapsed {
  border-radius: 0;
}

.aohMDa_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.aohMDa_breadcrumb-item + .aohMDa_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.aohMDa_breadcrumb-item + .aohMDa_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.aohMDa_breadcrumb-item.aohMDa_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.aohMDa_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.aohMDa_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_page-link {
    transition: none;
  }
}

.aohMDa_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.aohMDa_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.aohMDa_page-link.aohMDa_active, .aohMDa_active > .aohMDa_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.aohMDa_page-link.aohMDa_disabled, .aohMDa_disabled > .aohMDa_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.aohMDa_page-item:not(:first-child) .aohMDa_page-link {
  margin-left: -1px;
}

.aohMDa_page-item:first-child .aohMDa_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.aohMDa_page-item:last-child .aohMDa_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.aohMDa_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.aohMDa_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.aohMDa_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.aohMDa_badge:empty {
  display: none;
}

.aohMDa_btn .aohMDa_badge {
  position: relative;
  top: -1px;
}

.aohMDa_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.aohMDa_alert-heading {
  color: inherit;
}

.aohMDa_alert-link {
  font-weight: 700;
}

.aohMDa_alert-dismissible {
  padding-right: 3rem;
}

.aohMDa_alert-dismissible .aohMDa_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.aohMDa_alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.aohMDa_alert-primary .aohMDa_alert-link {
  color: #25224b;
}

.aohMDa_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.aohMDa_alert-secondary .aohMDa_alert-link {
  color: #34383c;
}

.aohMDa_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.aohMDa_alert-success .aohMDa_alert-link {
  color: #0c4128;
}

.aohMDa_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.aohMDa_alert-info .aohMDa_alert-link {
  color: #04414d;
}

.aohMDa_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.aohMDa_alert-warning .aohMDa_alert-link {
  color: #523e02;
}

.aohMDa_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.aohMDa_alert-danger .aohMDa_alert-link {
  color: #6a1a21;
}

.aohMDa_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.aohMDa_alert-light .aohMDa_alert-link {
  color: #4f5050;
}

.aohMDa_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.aohMDa_alert-dark .aohMDa_alert-link {
  color: #101214;
}

@keyframes aohMDa_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.aohMDa_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.aohMDa_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_progress-bar {
    transition: none;
  }
}

.aohMDa_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.aohMDa_progress-bar-animated {
  animation: 1s linear infinite aohMDa_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_progress-bar-animated {
    animation: none;
  }
}

.aohMDa_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.aohMDa_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.aohMDa_list-group-numbered > .aohMDa_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.aohMDa_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.aohMDa_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.aohMDa_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.aohMDa_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.aohMDa_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.aohMDa_list-group-item.aohMDa_disabled, .aohMDa_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.aohMDa_list-group-item.aohMDa_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.aohMDa_list-group-item + .aohMDa_list-group-item {
  border-top-width: 0;
}

.aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.aohMDa_list-group-horizontal {
  flex-direction: row;
}

.aohMDa_list-group-horizontal > .aohMDa_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.aohMDa_list-group-horizontal > .aohMDa_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.aohMDa_list-group-horizontal > .aohMDa_list-group-item.aohMDa_active {
  margin-top: 0;
}

.aohMDa_list-group-horizontal > .aohMDa_list-group-item + .aohMDa_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.aohMDa_list-group-horizontal > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .aohMDa_list-group-horizontal-sm {
    flex-direction: row;
  }

  .aohMDa_list-group-horizontal-sm > .aohMDa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .aohMDa_list-group-horizontal-sm > .aohMDa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .aohMDa_list-group-horizontal-sm > .aohMDa_list-group-item.aohMDa_active {
    margin-top: 0;
  }

  .aohMDa_list-group-horizontal-sm > .aohMDa_list-group-item + .aohMDa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .aohMDa_list-group-horizontal-sm > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .aohMDa_list-group-horizontal-md {
    flex-direction: row;
  }

  .aohMDa_list-group-horizontal-md > .aohMDa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .aohMDa_list-group-horizontal-md > .aohMDa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .aohMDa_list-group-horizontal-md > .aohMDa_list-group-item.aohMDa_active {
    margin-top: 0;
  }

  .aohMDa_list-group-horizontal-md > .aohMDa_list-group-item + .aohMDa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .aohMDa_list-group-horizontal-md > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .aohMDa_list-group-horizontal-lg {
    flex-direction: row;
  }

  .aohMDa_list-group-horizontal-lg > .aohMDa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .aohMDa_list-group-horizontal-lg > .aohMDa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .aohMDa_list-group-horizontal-lg > .aohMDa_list-group-item.aohMDa_active {
    margin-top: 0;
  }

  .aohMDa_list-group-horizontal-lg > .aohMDa_list-group-item + .aohMDa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .aohMDa_list-group-horizontal-lg > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .aohMDa_list-group-horizontal-xl {
    flex-direction: row;
  }

  .aohMDa_list-group-horizontal-xl > .aohMDa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .aohMDa_list-group-horizontal-xl > .aohMDa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .aohMDa_list-group-horizontal-xl > .aohMDa_list-group-item.aohMDa_active {
    margin-top: 0;
  }

  .aohMDa_list-group-horizontal-xl > .aohMDa_list-group-item + .aohMDa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .aohMDa_list-group-horizontal-xl > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .aohMDa_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .aohMDa_list-group-horizontal-xxl > .aohMDa_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .aohMDa_list-group-horizontal-xxl > .aohMDa_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .aohMDa_list-group-horizontal-xxl > .aohMDa_list-group-item.aohMDa_active {
    margin-top: 0;
  }

  .aohMDa_list-group-horizontal-xxl > .aohMDa_list-group-item + .aohMDa_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .aohMDa_list-group-horizontal-xxl > .aohMDa_list-group-item + .aohMDa_list-group-item.aohMDa_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.aohMDa_list-group-flush {
  border-radius: 0;
}

.aohMDa_list-group-flush > .aohMDa_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.aohMDa_list-group-flush > .aohMDa_list-group-item:last-child {
  border-bottom-width: 0;
}

.aohMDa_list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.aohMDa_list-group-item-primary.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-primary.aohMDa_list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.aohMDa_list-group-item-primary.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.aohMDa_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.aohMDa_list-group-item-secondary.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-secondary.aohMDa_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.aohMDa_list-group-item-secondary.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.aohMDa_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.aohMDa_list-group-item-success.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-success.aohMDa_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.aohMDa_list-group-item-success.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.aohMDa_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.aohMDa_list-group-item-info.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-info.aohMDa_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.aohMDa_list-group-item-info.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.aohMDa_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.aohMDa_list-group-item-warning.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-warning.aohMDa_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.aohMDa_list-group-item-warning.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.aohMDa_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.aohMDa_list-group-item-danger.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-danger.aohMDa_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.aohMDa_list-group-item-danger.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.aohMDa_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.aohMDa_list-group-item-light.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-light.aohMDa_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.aohMDa_list-group-item-light.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.aohMDa_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.aohMDa_list-group-item-dark.aohMDa_list-group-item-action:hover, .aohMDa_list-group-item-dark.aohMDa_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.aohMDa_list-group-item-dark.aohMDa_list-group-item-action.aohMDa_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.aohMDa_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.aohMDa_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.aohMDa_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.aohMDa_btn-close:disabled, .aohMDa_btn-close.aohMDa_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.aohMDa_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.aohMDa_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.aohMDa_toast.aohMDa_showing {
  opacity: 0;
}

.aohMDa_toast:not(.aohMDa_show) {
  display: none;
}

.aohMDa_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.aohMDa_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.aohMDa_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.aohMDa_toast-header .aohMDa_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.aohMDa_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.aohMDa_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.aohMDa_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.aohMDa_modal.aohMDa_fade .aohMDa_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_modal.aohMDa_fade .aohMDa_modal-dialog {
    transition: none;
  }
}

.aohMDa_modal.aohMDa_show .aohMDa_modal-dialog {
  transform: none;
}

.aohMDa_modal.aohMDa_modal-static .aohMDa_modal-dialog {
  transform: scale(1.02);
}

.aohMDa_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.aohMDa_modal-dialog-scrollable .aohMDa_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.aohMDa_modal-dialog-scrollable .aohMDa_modal-body {
  overflow-y: auto;
}

.aohMDa_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.aohMDa_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.aohMDa_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.aohMDa_modal-backdrop.aohMDa_fade {
  opacity: 0;
}

.aohMDa_modal-backdrop.aohMDa_show {
  opacity: var(--bs-backdrop-opacity);
}

.aohMDa_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.aohMDa_modal-header .aohMDa_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.aohMDa_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.aohMDa_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.aohMDa_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.aohMDa_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .aohMDa_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .aohMDa_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .aohMDa_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .aohMDa_modal-lg, .aohMDa_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .aohMDa_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.aohMDa_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.aohMDa_modal-fullscreen .aohMDa_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.aohMDa_modal-fullscreen .aohMDa_modal-header, .aohMDa_modal-fullscreen .aohMDa_modal-footer {
  border-radius: 0;
}

.aohMDa_modal-fullscreen .aohMDa_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .aohMDa_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .aohMDa_modal-fullscreen-sm-down .aohMDa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-sm-down .aohMDa_modal-header, .aohMDa_modal-fullscreen-sm-down .aohMDa_modal-footer {
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-sm-down .aohMDa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .aohMDa_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .aohMDa_modal-fullscreen-md-down .aohMDa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-md-down .aohMDa_modal-header, .aohMDa_modal-fullscreen-md-down .aohMDa_modal-footer {
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-md-down .aohMDa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .aohMDa_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .aohMDa_modal-fullscreen-lg-down .aohMDa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-lg-down .aohMDa_modal-header, .aohMDa_modal-fullscreen-lg-down .aohMDa_modal-footer {
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-lg-down .aohMDa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .aohMDa_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .aohMDa_modal-fullscreen-xl-down .aohMDa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-xl-down .aohMDa_modal-header, .aohMDa_modal-fullscreen-xl-down .aohMDa_modal-footer {
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-xl-down .aohMDa_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .aohMDa_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .aohMDa_modal-fullscreen-xxl-down .aohMDa_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-xxl-down .aohMDa_modal-header, .aohMDa_modal-fullscreen-xxl-down .aohMDa_modal-footer {
    border-radius: 0;
  }

  .aohMDa_modal-fullscreen-xxl-down .aohMDa_modal-body {
    overflow-y: auto;
  }
}

.aohMDa_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.aohMDa_tooltip.aohMDa_show {
  opacity: var(--bs-tooltip-opacity);
}

.aohMDa_tooltip .aohMDa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.aohMDa_tooltip .aohMDa_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.aohMDa_bs-tooltip-top .aohMDa_tooltip-arrow, .aohMDa_bs-tooltip-auto[data-popper-placement^="top"] .aohMDa_tooltip-arrow {
  bottom: 0;
}

.aohMDa_bs-tooltip-top .aohMDa_tooltip-arrow:before, .aohMDa_bs-tooltip-auto[data-popper-placement^="top"] .aohMDa_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.aohMDa_bs-tooltip-end .aohMDa_tooltip-arrow, .aohMDa_bs-tooltip-auto[data-popper-placement^="right"] .aohMDa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.aohMDa_bs-tooltip-end .aohMDa_tooltip-arrow:before, .aohMDa_bs-tooltip-auto[data-popper-placement^="right"] .aohMDa_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.aohMDa_bs-tooltip-bottom .aohMDa_tooltip-arrow, .aohMDa_bs-tooltip-auto[data-popper-placement^="bottom"] .aohMDa_tooltip-arrow {
  top: 0;
}

.aohMDa_bs-tooltip-bottom .aohMDa_tooltip-arrow:before, .aohMDa_bs-tooltip-auto[data-popper-placement^="bottom"] .aohMDa_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.aohMDa_bs-tooltip-start .aohMDa_tooltip-arrow, .aohMDa_bs-tooltip-auto[data-popper-placement^="left"] .aohMDa_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.aohMDa_bs-tooltip-start .aohMDa_tooltip-arrow:before, .aohMDa_bs-tooltip-auto[data-popper-placement^="left"] .aohMDa_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.aohMDa_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.aohMDa_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.aohMDa_popover .aohMDa_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.aohMDa_popover .aohMDa_popover-arrow:before, .aohMDa_popover .aohMDa_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.aohMDa_bs-popover-top > .aohMDa_popover-arrow, .aohMDa_bs-popover-auto[data-popper-placement^="top"] > .aohMDa_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.aohMDa_bs-popover-top > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="top"] > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-top > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="top"] > .aohMDa_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.aohMDa_bs-popover-top > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="top"] > .aohMDa_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.aohMDa_bs-popover-top > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="top"] > .aohMDa_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.aohMDa_bs-popover-end > .aohMDa_popover-arrow, .aohMDa_bs-popover-auto[data-popper-placement^="right"] > .aohMDa_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.aohMDa_bs-popover-end > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="right"] > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-end > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="right"] > .aohMDa_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.aohMDa_bs-popover-end > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="right"] > .aohMDa_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.aohMDa_bs-popover-end > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="right"] > .aohMDa_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.aohMDa_bs-popover-bottom > .aohMDa_popover-arrow, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] > .aohMDa_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.aohMDa_bs-popover-bottom > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-bottom > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] > .aohMDa_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.aohMDa_bs-popover-bottom > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] > .aohMDa_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.aohMDa_bs-popover-bottom > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] > .aohMDa_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.aohMDa_bs-popover-bottom .aohMDa_popover-header:before, .aohMDa_bs-popover-auto[data-popper-placement^="bottom"] .aohMDa_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.aohMDa_bs-popover-start > .aohMDa_popover-arrow, .aohMDa_bs-popover-auto[data-popper-placement^="left"] > .aohMDa_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.aohMDa_bs-popover-start > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="left"] > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-start > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="left"] > .aohMDa_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.aohMDa_bs-popover-start > .aohMDa_popover-arrow:before, .aohMDa_bs-popover-auto[data-popper-placement^="left"] > .aohMDa_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.aohMDa_bs-popover-start > .aohMDa_popover-arrow:after, .aohMDa_bs-popover-auto[data-popper-placement^="left"] > .aohMDa_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.aohMDa_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.aohMDa_popover-header:empty {
  display: none;
}

.aohMDa_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.aohMDa_carousel {
  position: relative;
}

.aohMDa_carousel.aohMDa_pointer-event {
  touch-action: pan-y;
}

.aohMDa_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.aohMDa_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.aohMDa_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_carousel-item {
    transition: none;
  }
}

.aohMDa_carousel-item.aohMDa_active, .aohMDa_carousel-item-next, .aohMDa_carousel-item-prev {
  display: block;
}

.aohMDa_carousel-item-next:not(.aohMDa_carousel-item-start), .aohMDa_active.aohMDa_carousel-item-end {
  transform: translateX(100%);
}

.aohMDa_carousel-item-prev:not(.aohMDa_carousel-item-end), .aohMDa_active.aohMDa_carousel-item-start {
  transform: translateX(-100%);
}

.aohMDa_carousel-fade .aohMDa_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.aohMDa_carousel-fade .aohMDa_carousel-item.aohMDa_active, .aohMDa_carousel-fade .aohMDa_carousel-item-next.aohMDa_carousel-item-start, .aohMDa_carousel-fade .aohMDa_carousel-item-prev.aohMDa_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.aohMDa_carousel-fade .aohMDa_active.aohMDa_carousel-item-start, .aohMDa_carousel-fade .aohMDa_active.aohMDa_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_carousel-fade .aohMDa_active.aohMDa_carousel-item-start, .aohMDa_carousel-fade .aohMDa_active.aohMDa_carousel-item-end {
    transition: none;
  }
}

.aohMDa_carousel-control-prev, .aohMDa_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_carousel-control-prev, .aohMDa_carousel-control-next {
    transition: none;
  }
}

.aohMDa_carousel-control-prev:hover, .aohMDa_carousel-control-prev:focus, .aohMDa_carousel-control-next:hover, .aohMDa_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.aohMDa_carousel-control-prev {
  left: 0;
}

.aohMDa_carousel-control-next {
  right: 0;
}

.aohMDa_carousel-control-prev-icon, .aohMDa_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.aohMDa_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.aohMDa_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.aohMDa_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.aohMDa_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.aohMDa_carousel-indicators .aohMDa_active {
  opacity: 1;
}

.aohMDa_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.aohMDa_carousel-dark .aohMDa_carousel-control-prev-icon, .aohMDa_carousel-dark .aohMDa_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.aohMDa_carousel-dark .aohMDa_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.aohMDa_carousel-dark .aohMDa_carousel-caption {
  color: #000;
}

.aohMDa_spinner-grow, .aohMDa_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes aohMDa_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.aohMDa_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.aohMDa_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes aohMDa_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.aohMDa_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.aohMDa_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_spinner-border, .aohMDa_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.aohMDa_offcanvas, .aohMDa_offcanvas-xxl, .aohMDa_offcanvas-xl, .aohMDa_offcanvas-lg, .aohMDa_offcanvas-md, .aohMDa_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .aohMDa_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .aohMDa_offcanvas-sm.aohMDa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .aohMDa_offcanvas-sm.aohMDa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .aohMDa_offcanvas-sm.aohMDa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .aohMDa_offcanvas-sm.aohMDa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .aohMDa_offcanvas-sm.aohMDa_showing, .aohMDa_offcanvas-sm.aohMDa_show:not(.aohMDa_hiding) {
    transform: none;
  }

  .aohMDa_offcanvas-sm.aohMDa_showing, .aohMDa_offcanvas-sm.aohMDa_hiding, .aohMDa_offcanvas-sm.aohMDa_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .aohMDa_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .aohMDa_offcanvas-sm .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_offcanvas-sm .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .aohMDa_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .aohMDa_offcanvas-md.aohMDa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .aohMDa_offcanvas-md.aohMDa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .aohMDa_offcanvas-md.aohMDa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .aohMDa_offcanvas-md.aohMDa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .aohMDa_offcanvas-md.aohMDa_showing, .aohMDa_offcanvas-md.aohMDa_show:not(.aohMDa_hiding) {
    transform: none;
  }

  .aohMDa_offcanvas-md.aohMDa_showing, .aohMDa_offcanvas-md.aohMDa_hiding, .aohMDa_offcanvas-md.aohMDa_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .aohMDa_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .aohMDa_offcanvas-md .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_offcanvas-md .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .aohMDa_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .aohMDa_offcanvas-lg.aohMDa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .aohMDa_offcanvas-lg.aohMDa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .aohMDa_offcanvas-lg.aohMDa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .aohMDa_offcanvas-lg.aohMDa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .aohMDa_offcanvas-lg.aohMDa_showing, .aohMDa_offcanvas-lg.aohMDa_show:not(.aohMDa_hiding) {
    transform: none;
  }

  .aohMDa_offcanvas-lg.aohMDa_showing, .aohMDa_offcanvas-lg.aohMDa_hiding, .aohMDa_offcanvas-lg.aohMDa_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .aohMDa_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .aohMDa_offcanvas-lg .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_offcanvas-lg .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .aohMDa_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .aohMDa_offcanvas-xl.aohMDa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .aohMDa_offcanvas-xl.aohMDa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .aohMDa_offcanvas-xl.aohMDa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .aohMDa_offcanvas-xl.aohMDa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .aohMDa_offcanvas-xl.aohMDa_showing, .aohMDa_offcanvas-xl.aohMDa_show:not(.aohMDa_hiding) {
    transform: none;
  }

  .aohMDa_offcanvas-xl.aohMDa_showing, .aohMDa_offcanvas-xl.aohMDa_hiding, .aohMDa_offcanvas-xl.aohMDa_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .aohMDa_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .aohMDa_offcanvas-xl .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_offcanvas-xl .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .aohMDa_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .aohMDa_offcanvas-xxl.aohMDa_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .aohMDa_offcanvas-xxl.aohMDa_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .aohMDa_offcanvas-xxl.aohMDa_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .aohMDa_offcanvas-xxl.aohMDa_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .aohMDa_offcanvas-xxl.aohMDa_showing, .aohMDa_offcanvas-xxl.aohMDa_show:not(.aohMDa_hiding) {
    transform: none;
  }

  .aohMDa_offcanvas-xxl.aohMDa_showing, .aohMDa_offcanvas-xxl.aohMDa_hiding, .aohMDa_offcanvas-xxl.aohMDa_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .aohMDa_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .aohMDa_offcanvas-xxl .aohMDa_offcanvas-header {
    display: none;
  }

  .aohMDa_offcanvas-xxl .aohMDa_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.aohMDa_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .aohMDa_offcanvas {
    transition: none;
  }
}

.aohMDa_offcanvas.aohMDa_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.aohMDa_offcanvas.aohMDa_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.aohMDa_offcanvas.aohMDa_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.aohMDa_offcanvas.aohMDa_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.aohMDa_offcanvas.aohMDa_showing, .aohMDa_offcanvas.aohMDa_show:not(.aohMDa_hiding) {
  transform: none;
}

.aohMDa_offcanvas.aohMDa_showing, .aohMDa_offcanvas.aohMDa_hiding, .aohMDa_offcanvas.aohMDa_show {
  visibility: visible;
}

.aohMDa_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.aohMDa_offcanvas-backdrop.aohMDa_fade {
  opacity: 0;
}

.aohMDa_offcanvas-backdrop.aohMDa_show {
  opacity: .5;
}

.aohMDa_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.aohMDa_offcanvas-header .aohMDa_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.aohMDa_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.aohMDa_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.aohMDa_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.aohMDa_placeholder.aohMDa_btn:before {
  content: "";
  display: inline-block;
}

.aohMDa_placeholder-xs {
  min-height: .6em;
}

.aohMDa_placeholder-sm {
  min-height: .8em;
}

.aohMDa_placeholder-lg {
  min-height: 1.2em;
}

.aohMDa_placeholder-glow .aohMDa_placeholder {
  animation: 2s ease-in-out infinite aohMDa_placeholder-glow;
}

@keyframes aohMDa_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.aohMDa_placeholder-wave {
  animation: 2s linear infinite aohMDa_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes aohMDa_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.aohMDa_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.aohMDa_text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.aohMDa_link-primary {
  color: #4c469c !important;
}

.aohMDa_link-primary:hover, .aohMDa_link-primary:focus {
  color: #3d387d !important;
}

.aohMDa_link-secondary {
  color: #6c757d !important;
}

.aohMDa_link-secondary:hover, .aohMDa_link-secondary:focus {
  color: #565e64 !important;
}

.aohMDa_link-success {
  color: #198754 !important;
}

.aohMDa_link-success:hover, .aohMDa_link-success:focus {
  color: #146c43 !important;
}

.aohMDa_link-info {
  color: #0dcaf0 !important;
}

.aohMDa_link-info:hover, .aohMDa_link-info:focus {
  color: #3dd5f3 !important;
}

.aohMDa_link-warning {
  color: #ffc107 !important;
}

.aohMDa_link-warning:hover, .aohMDa_link-warning:focus {
  color: #ffcd39 !important;
}

.aohMDa_link-danger {
  color: #dc3545 !important;
}

.aohMDa_link-danger:hover, .aohMDa_link-danger:focus {
  color: #b02a37 !important;
}

.aohMDa_link-light {
  color: #f8f9fa !important;
}

.aohMDa_link-light:hover, .aohMDa_link-light:focus {
  color: #f9fafb !important;
}

.aohMDa_link-dark {
  color: #212529 !important;
}

.aohMDa_link-dark:hover, .aohMDa_link-dark:focus {
  color: #1a1e21 !important;
}

.aohMDa_ratio {
  width: 100%;
  position: relative;
}

.aohMDa_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.aohMDa_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aohMDa_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.aohMDa_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.aohMDa_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.aohMDa_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.aohMDa_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.aohMDa_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.aohMDa_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.aohMDa_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .aohMDa_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .aohMDa_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .aohMDa_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .aohMDa_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .aohMDa_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .aohMDa_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .aohMDa_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .aohMDa_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .aohMDa_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .aohMDa_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.aohMDa_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.aohMDa_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.aohMDa_visually-hidden, .aohMDa_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.aohMDa_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.aohMDa_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.aohMDa_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.aohMDa_align-baseline {
  vertical-align: baseline !important;
}

.aohMDa_align-top {
  vertical-align: top !important;
}

.aohMDa_align-middle {
  vertical-align: middle !important;
}

.aohMDa_align-bottom {
  vertical-align: bottom !important;
}

.aohMDa_align-text-bottom {
  vertical-align: text-bottom !important;
}

.aohMDa_align-text-top {
  vertical-align: text-top !important;
}

.aohMDa_float-start {
  float: left !important;
}

.aohMDa_float-end {
  float: right !important;
}

.aohMDa_float-none {
  float: none !important;
}

.aohMDa_opacity-0 {
  opacity: 0 !important;
}

.aohMDa_opacity-25 {
  opacity: .25 !important;
}

.aohMDa_opacity-50 {
  opacity: .5 !important;
}

.aohMDa_opacity-75 {
  opacity: .75 !important;
}

.aohMDa_opacity-100 {
  opacity: 1 !important;
}

.aohMDa_overflow-auto {
  overflow: auto !important;
}

.aohMDa_overflow-hidden {
  overflow: hidden !important;
}

.aohMDa_overflow-visible {
  overflow: visible !important;
}

.aohMDa_overflow-scroll {
  overflow: scroll !important;
}

.aohMDa_d-inline {
  display: inline !important;
}

.aohMDa_d-inline-block {
  display: inline-block !important;
}

.aohMDa_d-block {
  display: block !important;
}

.aohMDa_d-grid {
  display: grid !important;
}

.aohMDa_d-table {
  display: table !important;
}

.aohMDa_d-table-row {
  display: table-row !important;
}

.aohMDa_d-table-cell {
  display: table-cell !important;
}

.aohMDa_d-flex {
  display: flex !important;
}

.aohMDa_d-inline-flex {
  display: inline-flex !important;
}

.aohMDa_d-none {
  display: none !important;
}

.aohMDa_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.aohMDa_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.aohMDa_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.aohMDa_shadow-none {
  box-shadow: none !important;
}

.aohMDa_position-static {
  position: static !important;
}

.aohMDa_position-relative {
  position: relative !important;
}

.aohMDa_position-absolute {
  position: absolute !important;
}

.aohMDa_position-fixed {
  position: fixed !important;
}

.aohMDa_position-sticky {
  position: sticky !important;
}

.aohMDa_top-0 {
  top: 0 !important;
}

.aohMDa_top-50 {
  top: 50% !important;
}

.aohMDa_top-100 {
  top: 100% !important;
}

.aohMDa_bottom-0 {
  bottom: 0 !important;
}

.aohMDa_bottom-50 {
  bottom: 50% !important;
}

.aohMDa_bottom-100 {
  bottom: 100% !important;
}

.aohMDa_start-0 {
  left: 0 !important;
}

.aohMDa_start-50 {
  left: 50% !important;
}

.aohMDa_start-100 {
  left: 100% !important;
}

.aohMDa_end-0 {
  right: 0 !important;
}

.aohMDa_end-50 {
  right: 50% !important;
}

.aohMDa_end-100 {
  right: 100% !important;
}

.aohMDa_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.aohMDa_translate-middle-x {
  transform: translateX(-50%) !important;
}

.aohMDa_translate-middle-y {
  transform: translateY(-50%) !important;
}

.aohMDa_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.aohMDa_border-0 {
  border: 0 !important;
}

.aohMDa_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.aohMDa_border-top-0 {
  border-top: 0 !important;
}

.aohMDa_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.aohMDa_border-end-0 {
  border-right: 0 !important;
}

.aohMDa_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.aohMDa_border-bottom-0 {
  border-bottom: 0 !important;
}

.aohMDa_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.aohMDa_border-start-0 {
  border-left: 0 !important;
}

.aohMDa_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.aohMDa_border-1 {
  --bs-border-width: 1px;
}

.aohMDa_border-2 {
  --bs-border-width: 2px;
}

.aohMDa_border-3 {
  --bs-border-width: 3px;
}

.aohMDa_border-4 {
  --bs-border-width: 4px;
}

.aohMDa_border-5 {
  --bs-border-width: 5px;
}

.aohMDa_border-opacity-10 {
  --bs-border-opacity: .1;
}

.aohMDa_border-opacity-25 {
  --bs-border-opacity: .25;
}

.aohMDa_border-opacity-50 {
  --bs-border-opacity: .5;
}

.aohMDa_border-opacity-75 {
  --bs-border-opacity: .75;
}

.aohMDa_border-opacity-100 {
  --bs-border-opacity: 1;
}

.aohMDa_w-25 {
  width: 25% !important;
}

.aohMDa_w-50 {
  width: 50% !important;
}

.aohMDa_w-75 {
  width: 75% !important;
}

.aohMDa_w-100 {
  width: 100% !important;
}

.aohMDa_w-auto {
  width: auto !important;
}

.aohMDa_mw-100 {
  max-width: 100% !important;
}

.aohMDa_vw-100 {
  width: 100vw !important;
}

.aohMDa_min-vw-100 {
  min-width: 100vw !important;
}

.aohMDa_h-25 {
  height: 25% !important;
}

.aohMDa_h-50 {
  height: 50% !important;
}

.aohMDa_h-75 {
  height: 75% !important;
}

.aohMDa_h-100 {
  height: 100% !important;
}

.aohMDa_h-auto {
  height: auto !important;
}

.aohMDa_mh-100 {
  max-height: 100% !important;
}

.aohMDa_vh-100 {
  height: 100vh !important;
}

.aohMDa_min-vh-100 {
  min-height: 100vh !important;
}

.aohMDa_flex-fill {
  flex: auto !important;
}

.aohMDa_flex-row {
  flex-direction: row !important;
}

.aohMDa_flex-column {
  flex-direction: column !important;
}

.aohMDa_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.aohMDa_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.aohMDa_flex-grow-0 {
  flex-grow: 0 !important;
}

.aohMDa_flex-grow-1 {
  flex-grow: 1 !important;
}

.aohMDa_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.aohMDa_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.aohMDa_flex-wrap {
  flex-wrap: wrap !important;
}

.aohMDa_flex-nowrap {
  flex-wrap: nowrap !important;
}

.aohMDa_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.aohMDa_justify-content-start {
  justify-content: flex-start !important;
}

.aohMDa_justify-content-end {
  justify-content: flex-end !important;
}

.aohMDa_justify-content-center {
  justify-content: center !important;
}

.aohMDa_justify-content-between {
  justify-content: space-between !important;
}

.aohMDa_justify-content-around {
  justify-content: space-around !important;
}

.aohMDa_justify-content-evenly {
  justify-content: space-evenly !important;
}

.aohMDa_align-items-start {
  align-items: flex-start !important;
}

.aohMDa_align-items-end {
  align-items: flex-end !important;
}

.aohMDa_align-items-center {
  align-items: center !important;
}

.aohMDa_align-items-baseline {
  align-items: baseline !important;
}

.aohMDa_align-items-stretch {
  align-items: stretch !important;
}

.aohMDa_align-content-start {
  align-content: flex-start !important;
}

.aohMDa_align-content-end {
  align-content: flex-end !important;
}

.aohMDa_align-content-center {
  align-content: center !important;
}

.aohMDa_align-content-between {
  align-content: space-between !important;
}

.aohMDa_align-content-around {
  align-content: space-around !important;
}

.aohMDa_align-content-stretch {
  align-content: stretch !important;
}

.aohMDa_align-self-auto {
  align-self: auto !important;
}

.aohMDa_align-self-start {
  align-self: flex-start !important;
}

.aohMDa_align-self-end {
  align-self: flex-end !important;
}

.aohMDa_align-self-center {
  align-self: center !important;
}

.aohMDa_align-self-baseline {
  align-self: baseline !important;
}

.aohMDa_align-self-stretch {
  align-self: stretch !important;
}

.aohMDa_order-first {
  order: -1 !important;
}

.aohMDa_order-0 {
  order: 0 !important;
}

.aohMDa_order-1 {
  order: 1 !important;
}

.aohMDa_order-2 {
  order: 2 !important;
}

.aohMDa_order-3 {
  order: 3 !important;
}

.aohMDa_order-4 {
  order: 4 !important;
}

.aohMDa_order-5 {
  order: 5 !important;
}

.aohMDa_order-last {
  order: 6 !important;
}

.aohMDa_m-0 {
  margin: 0 !important;
}

.aohMDa_m-1 {
  margin: .25rem !important;
}

.aohMDa_m-2 {
  margin: .5rem !important;
}

.aohMDa_m-3 {
  margin: 1rem !important;
}

.aohMDa_m-4 {
  margin: 1.5rem !important;
}

.aohMDa_m-5 {
  margin: 3rem !important;
}

.aohMDa_m-auto {
  margin: auto !important;
}

.aohMDa_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.aohMDa_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.aohMDa_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.aohMDa_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.aohMDa_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.aohMDa_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.aohMDa_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.aohMDa_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.aohMDa_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.aohMDa_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.aohMDa_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.aohMDa_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.aohMDa_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.aohMDa_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.aohMDa_mt-0 {
  margin-top: 0 !important;
}

.aohMDa_mt-1 {
  margin-top: .25rem !important;
}

.aohMDa_mt-2 {
  margin-top: .5rem !important;
}

.aohMDa_mt-3 {
  margin-top: 1rem !important;
}

.aohMDa_mt-4 {
  margin-top: 1.5rem !important;
}

.aohMDa_mt-5 {
  margin-top: 3rem !important;
}

.aohMDa_mt-auto {
  margin-top: auto !important;
}

.aohMDa_me-0 {
  margin-right: 0 !important;
}

.aohMDa_me-1 {
  margin-right: .25rem !important;
}

.aohMDa_me-2 {
  margin-right: .5rem !important;
}

.aohMDa_me-3 {
  margin-right: 1rem !important;
}

.aohMDa_me-4 {
  margin-right: 1.5rem !important;
}

.aohMDa_me-5 {
  margin-right: 3rem !important;
}

.aohMDa_me-auto {
  margin-right: auto !important;
}

.aohMDa_mb-0 {
  margin-bottom: 0 !important;
}

.aohMDa_mb-1 {
  margin-bottom: .25rem !important;
}

.aohMDa_mb-2 {
  margin-bottom: .5rem !important;
}

.aohMDa_mb-3 {
  margin-bottom: 1rem !important;
}

.aohMDa_mb-4 {
  margin-bottom: 1.5rem !important;
}

.aohMDa_mb-5 {
  margin-bottom: 3rem !important;
}

.aohMDa_mb-auto {
  margin-bottom: auto !important;
}

.aohMDa_ms-0 {
  margin-left: 0 !important;
}

.aohMDa_ms-1 {
  margin-left: .25rem !important;
}

.aohMDa_ms-2 {
  margin-left: .5rem !important;
}

.aohMDa_ms-3 {
  margin-left: 1rem !important;
}

.aohMDa_ms-4 {
  margin-left: 1.5rem !important;
}

.aohMDa_ms-5 {
  margin-left: 3rem !important;
}

.aohMDa_ms-auto {
  margin-left: auto !important;
}

.aohMDa_p-0 {
  padding: 0 !important;
}

.aohMDa_p-1 {
  padding: .25rem !important;
}

.aohMDa_p-2 {
  padding: .5rem !important;
}

.aohMDa_p-3 {
  padding: 1rem !important;
}

.aohMDa_p-4 {
  padding: 1.5rem !important;
}

.aohMDa_p-5 {
  padding: 3rem !important;
}

.aohMDa_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.aohMDa_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.aohMDa_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.aohMDa_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.aohMDa_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.aohMDa_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.aohMDa_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.aohMDa_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.aohMDa_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.aohMDa_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.aohMDa_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.aohMDa_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.aohMDa_pt-0 {
  padding-top: 0 !important;
}

.aohMDa_pt-1 {
  padding-top: .25rem !important;
}

.aohMDa_pt-2 {
  padding-top: .5rem !important;
}

.aohMDa_pt-3 {
  padding-top: 1rem !important;
}

.aohMDa_pt-4 {
  padding-top: 1.5rem !important;
}

.aohMDa_pt-5 {
  padding-top: 3rem !important;
}

.aohMDa_pe-0 {
  padding-right: 0 !important;
}

.aohMDa_pe-1 {
  padding-right: .25rem !important;
}

.aohMDa_pe-2 {
  padding-right: .5rem !important;
}

.aohMDa_pe-3 {
  padding-right: 1rem !important;
}

.aohMDa_pe-4 {
  padding-right: 1.5rem !important;
}

.aohMDa_pe-5 {
  padding-right: 3rem !important;
}

.aohMDa_pb-0 {
  padding-bottom: 0 !important;
}

.aohMDa_pb-1 {
  padding-bottom: .25rem !important;
}

.aohMDa_pb-2 {
  padding-bottom: .5rem !important;
}

.aohMDa_pb-3 {
  padding-bottom: 1rem !important;
}

.aohMDa_pb-4 {
  padding-bottom: 1.5rem !important;
}

.aohMDa_pb-5 {
  padding-bottom: 3rem !important;
}

.aohMDa_ps-0 {
  padding-left: 0 !important;
}

.aohMDa_ps-1 {
  padding-left: .25rem !important;
}

.aohMDa_ps-2 {
  padding-left: .5rem !important;
}

.aohMDa_ps-3 {
  padding-left: 1rem !important;
}

.aohMDa_ps-4 {
  padding-left: 1.5rem !important;
}

.aohMDa_ps-5 {
  padding-left: 3rem !important;
}

.aohMDa_gap-0 {
  gap: 0 !important;
}

.aohMDa_gap-1 {
  gap: .25rem !important;
}

.aohMDa_gap-2 {
  gap: .5rem !important;
}

.aohMDa_gap-3 {
  gap: 1rem !important;
}

.aohMDa_gap-4 {
  gap: 1.5rem !important;
}

.aohMDa_gap-5 {
  gap: 3rem !important;
}

.aohMDa_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.aohMDa_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.aohMDa_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.aohMDa_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.aohMDa_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.aohMDa_fs-5 {
  font-size: 1.25rem !important;
}

.aohMDa_fs-6 {
  font-size: 1rem !important;
}

.aohMDa_fst-italic {
  font-style: italic !important;
}

.aohMDa_fst-normal {
  font-style: normal !important;
}

.aohMDa_fw-light {
  font-weight: 300 !important;
}

.aohMDa_fw-lighter {
  font-weight: lighter !important;
}

.aohMDa_fw-normal {
  font-weight: 400 !important;
}

.aohMDa_fw-bold {
  font-weight: 700 !important;
}

.aohMDa_fw-semibold {
  font-weight: 600 !important;
}

.aohMDa_fw-bolder {
  font-weight: bolder !important;
}

.aohMDa_lh-1 {
  line-height: 1 !important;
}

.aohMDa_lh-sm {
  line-height: 1.25 !important;
}

.aohMDa_lh-base {
  line-height: 1.5 !important;
}

.aohMDa_lh-lg {
  line-height: 2 !important;
}

.aohMDa_text-start {
  text-align: left !important;
}

.aohMDa_text-end {
  text-align: right !important;
}

.aohMDa_text-center {
  text-align: center !important;
}

.aohMDa_text-decoration-none {
  text-decoration: none !important;
}

.aohMDa_text-decoration-underline {
  text-decoration: underline !important;
}

.aohMDa_text-decoration-line-through {
  text-decoration: line-through !important;
}

.aohMDa_text-lowercase {
  text-transform: lowercase !important;
}

.aohMDa_text-uppercase {
  text-transform: uppercase !important;
}

.aohMDa_text-capitalize {
  text-transform: capitalize !important;
}

.aohMDa_text-wrap {
  white-space: normal !important;
}

.aohMDa_text-nowrap {
  white-space: nowrap !important;
}

.aohMDa_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.aohMDa_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.aohMDa_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.aohMDa_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.aohMDa_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.aohMDa_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.aohMDa_text-opacity-25 {
  --bs-text-opacity: .25;
}

.aohMDa_text-opacity-50 {
  --bs-text-opacity: .5;
}

.aohMDa_text-opacity-75 {
  --bs-text-opacity: .75;
}

.aohMDa_text-opacity-100 {
  --bs-text-opacity: 1;
}

.aohMDa_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.aohMDa_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.aohMDa_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.aohMDa_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.aohMDa_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.aohMDa_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.aohMDa_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.aohMDa_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.aohMDa_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.aohMDa_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.aohMDa_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.aohMDa_pe-none {
  pointer-events: none !important;
}

.aohMDa_pe-auto {
  pointer-events: auto !important;
}

.aohMDa_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.aohMDa_rounded-0 {
  border-radius: 0 !important;
}

.aohMDa_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.aohMDa_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.aohMDa_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.aohMDa_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.aohMDa_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.aohMDa_rounded-circle {
  border-radius: 50% !important;
}

.aohMDa_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.aohMDa_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.aohMDa_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.aohMDa_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.aohMDa_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.aohMDa_visible {
  visibility: visible !important;
}

.aohMDa_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .aohMDa_float-sm-start {
    float: left !important;
  }

  .aohMDa_float-sm-end {
    float: right !important;
  }

  .aohMDa_float-sm-none {
    float: none !important;
  }

  .aohMDa_d-sm-inline {
    display: inline !important;
  }

  .aohMDa_d-sm-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-sm-block {
    display: block !important;
  }

  .aohMDa_d-sm-grid {
    display: grid !important;
  }

  .aohMDa_d-sm-table {
    display: table !important;
  }

  .aohMDa_d-sm-table-row {
    display: table-row !important;
  }

  .aohMDa_d-sm-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-sm-flex {
    display: flex !important;
  }

  .aohMDa_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-sm-none {
    display: none !important;
  }

  .aohMDa_flex-sm-fill {
    flex: auto !important;
  }

  .aohMDa_flex-sm-row {
    flex-direction: row !important;
  }

  .aohMDa_flex-sm-column {
    flex-direction: column !important;
  }

  .aohMDa_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .aohMDa_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .aohMDa_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .aohMDa_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .aohMDa_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .aohMDa_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .aohMDa_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .aohMDa_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .aohMDa_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .aohMDa_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .aohMDa_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .aohMDa_justify-content-sm-center {
    justify-content: center !important;
  }

  .aohMDa_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .aohMDa_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .aohMDa_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .aohMDa_align-items-sm-start {
    align-items: flex-start !important;
  }

  .aohMDa_align-items-sm-end {
    align-items: flex-end !important;
  }

  .aohMDa_align-items-sm-center {
    align-items: center !important;
  }

  .aohMDa_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .aohMDa_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .aohMDa_align-content-sm-start {
    align-content: flex-start !important;
  }

  .aohMDa_align-content-sm-end {
    align-content: flex-end !important;
  }

  .aohMDa_align-content-sm-center {
    align-content: center !important;
  }

  .aohMDa_align-content-sm-between {
    align-content: space-between !important;
  }

  .aohMDa_align-content-sm-around {
    align-content: space-around !important;
  }

  .aohMDa_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .aohMDa_align-self-sm-auto {
    align-self: auto !important;
  }

  .aohMDa_align-self-sm-start {
    align-self: flex-start !important;
  }

  .aohMDa_align-self-sm-end {
    align-self: flex-end !important;
  }

  .aohMDa_align-self-sm-center {
    align-self: center !important;
  }

  .aohMDa_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .aohMDa_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .aohMDa_order-sm-first {
    order: -1 !important;
  }

  .aohMDa_order-sm-0 {
    order: 0 !important;
  }

  .aohMDa_order-sm-1 {
    order: 1 !important;
  }

  .aohMDa_order-sm-2 {
    order: 2 !important;
  }

  .aohMDa_order-sm-3 {
    order: 3 !important;
  }

  .aohMDa_order-sm-4 {
    order: 4 !important;
  }

  .aohMDa_order-sm-5 {
    order: 5 !important;
  }

  .aohMDa_order-sm-last {
    order: 6 !important;
  }

  .aohMDa_m-sm-0 {
    margin: 0 !important;
  }

  .aohMDa_m-sm-1 {
    margin: .25rem !important;
  }

  .aohMDa_m-sm-2 {
    margin: .5rem !important;
  }

  .aohMDa_m-sm-3 {
    margin: 1rem !important;
  }

  .aohMDa_m-sm-4 {
    margin: 1.5rem !important;
  }

  .aohMDa_m-sm-5 {
    margin: 3rem !important;
  }

  .aohMDa_m-sm-auto {
    margin: auto !important;
  }

  .aohMDa_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aohMDa_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .aohMDa_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .aohMDa_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .aohMDa_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .aohMDa_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .aohMDa_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .aohMDa_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .aohMDa_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .aohMDa_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .aohMDa_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .aohMDa_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .aohMDa_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .aohMDa_mt-sm-0 {
    margin-top: 0 !important;
  }

  .aohMDa_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .aohMDa_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .aohMDa_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .aohMDa_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .aohMDa_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .aohMDa_mt-sm-auto {
    margin-top: auto !important;
  }

  .aohMDa_me-sm-0 {
    margin-right: 0 !important;
  }

  .aohMDa_me-sm-1 {
    margin-right: .25rem !important;
  }

  .aohMDa_me-sm-2 {
    margin-right: .5rem !important;
  }

  .aohMDa_me-sm-3 {
    margin-right: 1rem !important;
  }

  .aohMDa_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .aohMDa_me-sm-5 {
    margin-right: 3rem !important;
  }

  .aohMDa_me-sm-auto {
    margin-right: auto !important;
  }

  .aohMDa_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .aohMDa_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .aohMDa_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .aohMDa_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .aohMDa_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .aohMDa_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .aohMDa_ms-sm-0 {
    margin-left: 0 !important;
  }

  .aohMDa_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .aohMDa_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .aohMDa_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .aohMDa_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .aohMDa_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .aohMDa_ms-sm-auto {
    margin-left: auto !important;
  }

  .aohMDa_p-sm-0 {
    padding: 0 !important;
  }

  .aohMDa_p-sm-1 {
    padding: .25rem !important;
  }

  .aohMDa_p-sm-2 {
    padding: .5rem !important;
  }

  .aohMDa_p-sm-3 {
    padding: 1rem !important;
  }

  .aohMDa_p-sm-4 {
    padding: 1.5rem !important;
  }

  .aohMDa_p-sm-5 {
    padding: 3rem !important;
  }

  .aohMDa_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .aohMDa_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .aohMDa_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .aohMDa_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .aohMDa_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .aohMDa_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .aohMDa_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .aohMDa_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .aohMDa_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .aohMDa_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .aohMDa_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aohMDa_pt-sm-0 {
    padding-top: 0 !important;
  }

  .aohMDa_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .aohMDa_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .aohMDa_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .aohMDa_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .aohMDa_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .aohMDa_pe-sm-0 {
    padding-right: 0 !important;
  }

  .aohMDa_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .aohMDa_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .aohMDa_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .aohMDa_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .aohMDa_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .aohMDa_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .aohMDa_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .aohMDa_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .aohMDa_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .aohMDa_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .aohMDa_ps-sm-0 {
    padding-left: 0 !important;
  }

  .aohMDa_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .aohMDa_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .aohMDa_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .aohMDa_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .aohMDa_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .aohMDa_gap-sm-0 {
    gap: 0 !important;
  }

  .aohMDa_gap-sm-1 {
    gap: .25rem !important;
  }

  .aohMDa_gap-sm-2 {
    gap: .5rem !important;
  }

  .aohMDa_gap-sm-3 {
    gap: 1rem !important;
  }

  .aohMDa_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .aohMDa_gap-sm-5 {
    gap: 3rem !important;
  }

  .aohMDa_text-sm-start {
    text-align: left !important;
  }

  .aohMDa_text-sm-end {
    text-align: right !important;
  }

  .aohMDa_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .aohMDa_float-md-start {
    float: left !important;
  }

  .aohMDa_float-md-end {
    float: right !important;
  }

  .aohMDa_float-md-none {
    float: none !important;
  }

  .aohMDa_d-md-inline {
    display: inline !important;
  }

  .aohMDa_d-md-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-md-block {
    display: block !important;
  }

  .aohMDa_d-md-grid {
    display: grid !important;
  }

  .aohMDa_d-md-table {
    display: table !important;
  }

  .aohMDa_d-md-table-row {
    display: table-row !important;
  }

  .aohMDa_d-md-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-md-flex {
    display: flex !important;
  }

  .aohMDa_d-md-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-md-none {
    display: none !important;
  }

  .aohMDa_flex-md-fill {
    flex: auto !important;
  }

  .aohMDa_flex-md-row {
    flex-direction: row !important;
  }

  .aohMDa_flex-md-column {
    flex-direction: column !important;
  }

  .aohMDa_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .aohMDa_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .aohMDa_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .aohMDa_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .aohMDa_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .aohMDa_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .aohMDa_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .aohMDa_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .aohMDa_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .aohMDa_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .aohMDa_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .aohMDa_justify-content-md-center {
    justify-content: center !important;
  }

  .aohMDa_justify-content-md-between {
    justify-content: space-between !important;
  }

  .aohMDa_justify-content-md-around {
    justify-content: space-around !important;
  }

  .aohMDa_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .aohMDa_align-items-md-start {
    align-items: flex-start !important;
  }

  .aohMDa_align-items-md-end {
    align-items: flex-end !important;
  }

  .aohMDa_align-items-md-center {
    align-items: center !important;
  }

  .aohMDa_align-items-md-baseline {
    align-items: baseline !important;
  }

  .aohMDa_align-items-md-stretch {
    align-items: stretch !important;
  }

  .aohMDa_align-content-md-start {
    align-content: flex-start !important;
  }

  .aohMDa_align-content-md-end {
    align-content: flex-end !important;
  }

  .aohMDa_align-content-md-center {
    align-content: center !important;
  }

  .aohMDa_align-content-md-between {
    align-content: space-between !important;
  }

  .aohMDa_align-content-md-around {
    align-content: space-around !important;
  }

  .aohMDa_align-content-md-stretch {
    align-content: stretch !important;
  }

  .aohMDa_align-self-md-auto {
    align-self: auto !important;
  }

  .aohMDa_align-self-md-start {
    align-self: flex-start !important;
  }

  .aohMDa_align-self-md-end {
    align-self: flex-end !important;
  }

  .aohMDa_align-self-md-center {
    align-self: center !important;
  }

  .aohMDa_align-self-md-baseline {
    align-self: baseline !important;
  }

  .aohMDa_align-self-md-stretch {
    align-self: stretch !important;
  }

  .aohMDa_order-md-first {
    order: -1 !important;
  }

  .aohMDa_order-md-0 {
    order: 0 !important;
  }

  .aohMDa_order-md-1 {
    order: 1 !important;
  }

  .aohMDa_order-md-2 {
    order: 2 !important;
  }

  .aohMDa_order-md-3 {
    order: 3 !important;
  }

  .aohMDa_order-md-4 {
    order: 4 !important;
  }

  .aohMDa_order-md-5 {
    order: 5 !important;
  }

  .aohMDa_order-md-last {
    order: 6 !important;
  }

  .aohMDa_m-md-0 {
    margin: 0 !important;
  }

  .aohMDa_m-md-1 {
    margin: .25rem !important;
  }

  .aohMDa_m-md-2 {
    margin: .5rem !important;
  }

  .aohMDa_m-md-3 {
    margin: 1rem !important;
  }

  .aohMDa_m-md-4 {
    margin: 1.5rem !important;
  }

  .aohMDa_m-md-5 {
    margin: 3rem !important;
  }

  .aohMDa_m-md-auto {
    margin: auto !important;
  }

  .aohMDa_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aohMDa_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .aohMDa_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .aohMDa_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .aohMDa_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .aohMDa_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .aohMDa_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .aohMDa_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .aohMDa_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .aohMDa_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .aohMDa_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .aohMDa_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .aohMDa_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .aohMDa_mt-md-0 {
    margin-top: 0 !important;
  }

  .aohMDa_mt-md-1 {
    margin-top: .25rem !important;
  }

  .aohMDa_mt-md-2 {
    margin-top: .5rem !important;
  }

  .aohMDa_mt-md-3 {
    margin-top: 1rem !important;
  }

  .aohMDa_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .aohMDa_mt-md-5 {
    margin-top: 3rem !important;
  }

  .aohMDa_mt-md-auto {
    margin-top: auto !important;
  }

  .aohMDa_me-md-0 {
    margin-right: 0 !important;
  }

  .aohMDa_me-md-1 {
    margin-right: .25rem !important;
  }

  .aohMDa_me-md-2 {
    margin-right: .5rem !important;
  }

  .aohMDa_me-md-3 {
    margin-right: 1rem !important;
  }

  .aohMDa_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .aohMDa_me-md-5 {
    margin-right: 3rem !important;
  }

  .aohMDa_me-md-auto {
    margin-right: auto !important;
  }

  .aohMDa_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .aohMDa_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .aohMDa_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .aohMDa_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .aohMDa_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .aohMDa_mb-md-auto {
    margin-bottom: auto !important;
  }

  .aohMDa_ms-md-0 {
    margin-left: 0 !important;
  }

  .aohMDa_ms-md-1 {
    margin-left: .25rem !important;
  }

  .aohMDa_ms-md-2 {
    margin-left: .5rem !important;
  }

  .aohMDa_ms-md-3 {
    margin-left: 1rem !important;
  }

  .aohMDa_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .aohMDa_ms-md-5 {
    margin-left: 3rem !important;
  }

  .aohMDa_ms-md-auto {
    margin-left: auto !important;
  }

  .aohMDa_p-md-0 {
    padding: 0 !important;
  }

  .aohMDa_p-md-1 {
    padding: .25rem !important;
  }

  .aohMDa_p-md-2 {
    padding: .5rem !important;
  }

  .aohMDa_p-md-3 {
    padding: 1rem !important;
  }

  .aohMDa_p-md-4 {
    padding: 1.5rem !important;
  }

  .aohMDa_p-md-5 {
    padding: 3rem !important;
  }

  .aohMDa_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .aohMDa_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .aohMDa_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .aohMDa_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .aohMDa_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .aohMDa_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .aohMDa_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .aohMDa_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .aohMDa_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .aohMDa_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .aohMDa_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aohMDa_pt-md-0 {
    padding-top: 0 !important;
  }

  .aohMDa_pt-md-1 {
    padding-top: .25rem !important;
  }

  .aohMDa_pt-md-2 {
    padding-top: .5rem !important;
  }

  .aohMDa_pt-md-3 {
    padding-top: 1rem !important;
  }

  .aohMDa_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .aohMDa_pt-md-5 {
    padding-top: 3rem !important;
  }

  .aohMDa_pe-md-0 {
    padding-right: 0 !important;
  }

  .aohMDa_pe-md-1 {
    padding-right: .25rem !important;
  }

  .aohMDa_pe-md-2 {
    padding-right: .5rem !important;
  }

  .aohMDa_pe-md-3 {
    padding-right: 1rem !important;
  }

  .aohMDa_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .aohMDa_pe-md-5 {
    padding-right: 3rem !important;
  }

  .aohMDa_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .aohMDa_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .aohMDa_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .aohMDa_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .aohMDa_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .aohMDa_ps-md-0 {
    padding-left: 0 !important;
  }

  .aohMDa_ps-md-1 {
    padding-left: .25rem !important;
  }

  .aohMDa_ps-md-2 {
    padding-left: .5rem !important;
  }

  .aohMDa_ps-md-3 {
    padding-left: 1rem !important;
  }

  .aohMDa_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .aohMDa_ps-md-5 {
    padding-left: 3rem !important;
  }

  .aohMDa_gap-md-0 {
    gap: 0 !important;
  }

  .aohMDa_gap-md-1 {
    gap: .25rem !important;
  }

  .aohMDa_gap-md-2 {
    gap: .5rem !important;
  }

  .aohMDa_gap-md-3 {
    gap: 1rem !important;
  }

  .aohMDa_gap-md-4 {
    gap: 1.5rem !important;
  }

  .aohMDa_gap-md-5 {
    gap: 3rem !important;
  }

  .aohMDa_text-md-start {
    text-align: left !important;
  }

  .aohMDa_text-md-end {
    text-align: right !important;
  }

  .aohMDa_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .aohMDa_float-lg-start {
    float: left !important;
  }

  .aohMDa_float-lg-end {
    float: right !important;
  }

  .aohMDa_float-lg-none {
    float: none !important;
  }

  .aohMDa_d-lg-inline {
    display: inline !important;
  }

  .aohMDa_d-lg-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-lg-block {
    display: block !important;
  }

  .aohMDa_d-lg-grid {
    display: grid !important;
  }

  .aohMDa_d-lg-table {
    display: table !important;
  }

  .aohMDa_d-lg-table-row {
    display: table-row !important;
  }

  .aohMDa_d-lg-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-lg-flex {
    display: flex !important;
  }

  .aohMDa_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-lg-none {
    display: none !important;
  }

  .aohMDa_flex-lg-fill {
    flex: auto !important;
  }

  .aohMDa_flex-lg-row {
    flex-direction: row !important;
  }

  .aohMDa_flex-lg-column {
    flex-direction: column !important;
  }

  .aohMDa_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .aohMDa_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .aohMDa_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .aohMDa_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .aohMDa_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .aohMDa_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .aohMDa_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .aohMDa_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .aohMDa_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .aohMDa_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .aohMDa_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .aohMDa_justify-content-lg-center {
    justify-content: center !important;
  }

  .aohMDa_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .aohMDa_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .aohMDa_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .aohMDa_align-items-lg-start {
    align-items: flex-start !important;
  }

  .aohMDa_align-items-lg-end {
    align-items: flex-end !important;
  }

  .aohMDa_align-items-lg-center {
    align-items: center !important;
  }

  .aohMDa_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .aohMDa_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .aohMDa_align-content-lg-start {
    align-content: flex-start !important;
  }

  .aohMDa_align-content-lg-end {
    align-content: flex-end !important;
  }

  .aohMDa_align-content-lg-center {
    align-content: center !important;
  }

  .aohMDa_align-content-lg-between {
    align-content: space-between !important;
  }

  .aohMDa_align-content-lg-around {
    align-content: space-around !important;
  }

  .aohMDa_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .aohMDa_align-self-lg-auto {
    align-self: auto !important;
  }

  .aohMDa_align-self-lg-start {
    align-self: flex-start !important;
  }

  .aohMDa_align-self-lg-end {
    align-self: flex-end !important;
  }

  .aohMDa_align-self-lg-center {
    align-self: center !important;
  }

  .aohMDa_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .aohMDa_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .aohMDa_order-lg-first {
    order: -1 !important;
  }

  .aohMDa_order-lg-0 {
    order: 0 !important;
  }

  .aohMDa_order-lg-1 {
    order: 1 !important;
  }

  .aohMDa_order-lg-2 {
    order: 2 !important;
  }

  .aohMDa_order-lg-3 {
    order: 3 !important;
  }

  .aohMDa_order-lg-4 {
    order: 4 !important;
  }

  .aohMDa_order-lg-5 {
    order: 5 !important;
  }

  .aohMDa_order-lg-last {
    order: 6 !important;
  }

  .aohMDa_m-lg-0 {
    margin: 0 !important;
  }

  .aohMDa_m-lg-1 {
    margin: .25rem !important;
  }

  .aohMDa_m-lg-2 {
    margin: .5rem !important;
  }

  .aohMDa_m-lg-3 {
    margin: 1rem !important;
  }

  .aohMDa_m-lg-4 {
    margin: 1.5rem !important;
  }

  .aohMDa_m-lg-5 {
    margin: 3rem !important;
  }

  .aohMDa_m-lg-auto {
    margin: auto !important;
  }

  .aohMDa_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aohMDa_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .aohMDa_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .aohMDa_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .aohMDa_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .aohMDa_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .aohMDa_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .aohMDa_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .aohMDa_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .aohMDa_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .aohMDa_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .aohMDa_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .aohMDa_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .aohMDa_mt-lg-0 {
    margin-top: 0 !important;
  }

  .aohMDa_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .aohMDa_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .aohMDa_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .aohMDa_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .aohMDa_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .aohMDa_mt-lg-auto {
    margin-top: auto !important;
  }

  .aohMDa_me-lg-0 {
    margin-right: 0 !important;
  }

  .aohMDa_me-lg-1 {
    margin-right: .25rem !important;
  }

  .aohMDa_me-lg-2 {
    margin-right: .5rem !important;
  }

  .aohMDa_me-lg-3 {
    margin-right: 1rem !important;
  }

  .aohMDa_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .aohMDa_me-lg-5 {
    margin-right: 3rem !important;
  }

  .aohMDa_me-lg-auto {
    margin-right: auto !important;
  }

  .aohMDa_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .aohMDa_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .aohMDa_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .aohMDa_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .aohMDa_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .aohMDa_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .aohMDa_ms-lg-0 {
    margin-left: 0 !important;
  }

  .aohMDa_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .aohMDa_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .aohMDa_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .aohMDa_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .aohMDa_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .aohMDa_ms-lg-auto {
    margin-left: auto !important;
  }

  .aohMDa_p-lg-0 {
    padding: 0 !important;
  }

  .aohMDa_p-lg-1 {
    padding: .25rem !important;
  }

  .aohMDa_p-lg-2 {
    padding: .5rem !important;
  }

  .aohMDa_p-lg-3 {
    padding: 1rem !important;
  }

  .aohMDa_p-lg-4 {
    padding: 1.5rem !important;
  }

  .aohMDa_p-lg-5 {
    padding: 3rem !important;
  }

  .aohMDa_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .aohMDa_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .aohMDa_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .aohMDa_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .aohMDa_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .aohMDa_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .aohMDa_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .aohMDa_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .aohMDa_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .aohMDa_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .aohMDa_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aohMDa_pt-lg-0 {
    padding-top: 0 !important;
  }

  .aohMDa_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .aohMDa_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .aohMDa_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .aohMDa_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .aohMDa_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .aohMDa_pe-lg-0 {
    padding-right: 0 !important;
  }

  .aohMDa_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .aohMDa_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .aohMDa_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .aohMDa_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .aohMDa_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .aohMDa_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .aohMDa_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .aohMDa_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .aohMDa_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .aohMDa_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .aohMDa_ps-lg-0 {
    padding-left: 0 !important;
  }

  .aohMDa_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .aohMDa_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .aohMDa_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .aohMDa_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .aohMDa_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .aohMDa_gap-lg-0 {
    gap: 0 !important;
  }

  .aohMDa_gap-lg-1 {
    gap: .25rem !important;
  }

  .aohMDa_gap-lg-2 {
    gap: .5rem !important;
  }

  .aohMDa_gap-lg-3 {
    gap: 1rem !important;
  }

  .aohMDa_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .aohMDa_gap-lg-5 {
    gap: 3rem !important;
  }

  .aohMDa_text-lg-start {
    text-align: left !important;
  }

  .aohMDa_text-lg-end {
    text-align: right !important;
  }

  .aohMDa_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .aohMDa_float-xl-start {
    float: left !important;
  }

  .aohMDa_float-xl-end {
    float: right !important;
  }

  .aohMDa_float-xl-none {
    float: none !important;
  }

  .aohMDa_d-xl-inline {
    display: inline !important;
  }

  .aohMDa_d-xl-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-xl-block {
    display: block !important;
  }

  .aohMDa_d-xl-grid {
    display: grid !important;
  }

  .aohMDa_d-xl-table {
    display: table !important;
  }

  .aohMDa_d-xl-table-row {
    display: table-row !important;
  }

  .aohMDa_d-xl-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-xl-flex {
    display: flex !important;
  }

  .aohMDa_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-xl-none {
    display: none !important;
  }

  .aohMDa_flex-xl-fill {
    flex: auto !important;
  }

  .aohMDa_flex-xl-row {
    flex-direction: row !important;
  }

  .aohMDa_flex-xl-column {
    flex-direction: column !important;
  }

  .aohMDa_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .aohMDa_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .aohMDa_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .aohMDa_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .aohMDa_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .aohMDa_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .aohMDa_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .aohMDa_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .aohMDa_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .aohMDa_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .aohMDa_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .aohMDa_justify-content-xl-center {
    justify-content: center !important;
  }

  .aohMDa_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .aohMDa_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .aohMDa_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .aohMDa_align-items-xl-start {
    align-items: flex-start !important;
  }

  .aohMDa_align-items-xl-end {
    align-items: flex-end !important;
  }

  .aohMDa_align-items-xl-center {
    align-items: center !important;
  }

  .aohMDa_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .aohMDa_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .aohMDa_align-content-xl-start {
    align-content: flex-start !important;
  }

  .aohMDa_align-content-xl-end {
    align-content: flex-end !important;
  }

  .aohMDa_align-content-xl-center {
    align-content: center !important;
  }

  .aohMDa_align-content-xl-between {
    align-content: space-between !important;
  }

  .aohMDa_align-content-xl-around {
    align-content: space-around !important;
  }

  .aohMDa_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .aohMDa_align-self-xl-auto {
    align-self: auto !important;
  }

  .aohMDa_align-self-xl-start {
    align-self: flex-start !important;
  }

  .aohMDa_align-self-xl-end {
    align-self: flex-end !important;
  }

  .aohMDa_align-self-xl-center {
    align-self: center !important;
  }

  .aohMDa_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .aohMDa_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .aohMDa_order-xl-first {
    order: -1 !important;
  }

  .aohMDa_order-xl-0 {
    order: 0 !important;
  }

  .aohMDa_order-xl-1 {
    order: 1 !important;
  }

  .aohMDa_order-xl-2 {
    order: 2 !important;
  }

  .aohMDa_order-xl-3 {
    order: 3 !important;
  }

  .aohMDa_order-xl-4 {
    order: 4 !important;
  }

  .aohMDa_order-xl-5 {
    order: 5 !important;
  }

  .aohMDa_order-xl-last {
    order: 6 !important;
  }

  .aohMDa_m-xl-0 {
    margin: 0 !important;
  }

  .aohMDa_m-xl-1 {
    margin: .25rem !important;
  }

  .aohMDa_m-xl-2 {
    margin: .5rem !important;
  }

  .aohMDa_m-xl-3 {
    margin: 1rem !important;
  }

  .aohMDa_m-xl-4 {
    margin: 1.5rem !important;
  }

  .aohMDa_m-xl-5 {
    margin: 3rem !important;
  }

  .aohMDa_m-xl-auto {
    margin: auto !important;
  }

  .aohMDa_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aohMDa_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .aohMDa_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .aohMDa_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .aohMDa_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .aohMDa_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .aohMDa_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .aohMDa_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .aohMDa_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .aohMDa_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .aohMDa_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .aohMDa_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .aohMDa_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .aohMDa_mt-xl-0 {
    margin-top: 0 !important;
  }

  .aohMDa_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .aohMDa_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .aohMDa_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .aohMDa_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .aohMDa_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .aohMDa_mt-xl-auto {
    margin-top: auto !important;
  }

  .aohMDa_me-xl-0 {
    margin-right: 0 !important;
  }

  .aohMDa_me-xl-1 {
    margin-right: .25rem !important;
  }

  .aohMDa_me-xl-2 {
    margin-right: .5rem !important;
  }

  .aohMDa_me-xl-3 {
    margin-right: 1rem !important;
  }

  .aohMDa_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .aohMDa_me-xl-5 {
    margin-right: 3rem !important;
  }

  .aohMDa_me-xl-auto {
    margin-right: auto !important;
  }

  .aohMDa_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .aohMDa_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .aohMDa_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .aohMDa_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .aohMDa_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .aohMDa_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .aohMDa_ms-xl-0 {
    margin-left: 0 !important;
  }

  .aohMDa_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .aohMDa_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .aohMDa_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .aohMDa_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .aohMDa_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .aohMDa_ms-xl-auto {
    margin-left: auto !important;
  }

  .aohMDa_p-xl-0 {
    padding: 0 !important;
  }

  .aohMDa_p-xl-1 {
    padding: .25rem !important;
  }

  .aohMDa_p-xl-2 {
    padding: .5rem !important;
  }

  .aohMDa_p-xl-3 {
    padding: 1rem !important;
  }

  .aohMDa_p-xl-4 {
    padding: 1.5rem !important;
  }

  .aohMDa_p-xl-5 {
    padding: 3rem !important;
  }

  .aohMDa_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .aohMDa_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .aohMDa_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .aohMDa_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .aohMDa_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .aohMDa_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .aohMDa_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .aohMDa_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .aohMDa_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .aohMDa_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .aohMDa_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aohMDa_pt-xl-0 {
    padding-top: 0 !important;
  }

  .aohMDa_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .aohMDa_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .aohMDa_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .aohMDa_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .aohMDa_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .aohMDa_pe-xl-0 {
    padding-right: 0 !important;
  }

  .aohMDa_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .aohMDa_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .aohMDa_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .aohMDa_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .aohMDa_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .aohMDa_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .aohMDa_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .aohMDa_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .aohMDa_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .aohMDa_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .aohMDa_ps-xl-0 {
    padding-left: 0 !important;
  }

  .aohMDa_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .aohMDa_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .aohMDa_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .aohMDa_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .aohMDa_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .aohMDa_gap-xl-0 {
    gap: 0 !important;
  }

  .aohMDa_gap-xl-1 {
    gap: .25rem !important;
  }

  .aohMDa_gap-xl-2 {
    gap: .5rem !important;
  }

  .aohMDa_gap-xl-3 {
    gap: 1rem !important;
  }

  .aohMDa_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .aohMDa_gap-xl-5 {
    gap: 3rem !important;
  }

  .aohMDa_text-xl-start {
    text-align: left !important;
  }

  .aohMDa_text-xl-end {
    text-align: right !important;
  }

  .aohMDa_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .aohMDa_float-xxl-start {
    float: left !important;
  }

  .aohMDa_float-xxl-end {
    float: right !important;
  }

  .aohMDa_float-xxl-none {
    float: none !important;
  }

  .aohMDa_d-xxl-inline {
    display: inline !important;
  }

  .aohMDa_d-xxl-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-xxl-block {
    display: block !important;
  }

  .aohMDa_d-xxl-grid {
    display: grid !important;
  }

  .aohMDa_d-xxl-table {
    display: table !important;
  }

  .aohMDa_d-xxl-table-row {
    display: table-row !important;
  }

  .aohMDa_d-xxl-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-xxl-flex {
    display: flex !important;
  }

  .aohMDa_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-xxl-none {
    display: none !important;
  }

  .aohMDa_flex-xxl-fill {
    flex: auto !important;
  }

  .aohMDa_flex-xxl-row {
    flex-direction: row !important;
  }

  .aohMDa_flex-xxl-column {
    flex-direction: column !important;
  }

  .aohMDa_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .aohMDa_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .aohMDa_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .aohMDa_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .aohMDa_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .aohMDa_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .aohMDa_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .aohMDa_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .aohMDa_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .aohMDa_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .aohMDa_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .aohMDa_justify-content-xxl-center {
    justify-content: center !important;
  }

  .aohMDa_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .aohMDa_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .aohMDa_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .aohMDa_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .aohMDa_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .aohMDa_align-items-xxl-center {
    align-items: center !important;
  }

  .aohMDa_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .aohMDa_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .aohMDa_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .aohMDa_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .aohMDa_align-content-xxl-center {
    align-content: center !important;
  }

  .aohMDa_align-content-xxl-between {
    align-content: space-between !important;
  }

  .aohMDa_align-content-xxl-around {
    align-content: space-around !important;
  }

  .aohMDa_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .aohMDa_align-self-xxl-auto {
    align-self: auto !important;
  }

  .aohMDa_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .aohMDa_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .aohMDa_align-self-xxl-center {
    align-self: center !important;
  }

  .aohMDa_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .aohMDa_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .aohMDa_order-xxl-first {
    order: -1 !important;
  }

  .aohMDa_order-xxl-0 {
    order: 0 !important;
  }

  .aohMDa_order-xxl-1 {
    order: 1 !important;
  }

  .aohMDa_order-xxl-2 {
    order: 2 !important;
  }

  .aohMDa_order-xxl-3 {
    order: 3 !important;
  }

  .aohMDa_order-xxl-4 {
    order: 4 !important;
  }

  .aohMDa_order-xxl-5 {
    order: 5 !important;
  }

  .aohMDa_order-xxl-last {
    order: 6 !important;
  }

  .aohMDa_m-xxl-0 {
    margin: 0 !important;
  }

  .aohMDa_m-xxl-1 {
    margin: .25rem !important;
  }

  .aohMDa_m-xxl-2 {
    margin: .5rem !important;
  }

  .aohMDa_m-xxl-3 {
    margin: 1rem !important;
  }

  .aohMDa_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .aohMDa_m-xxl-5 {
    margin: 3rem !important;
  }

  .aohMDa_m-xxl-auto {
    margin: auto !important;
  }

  .aohMDa_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aohMDa_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .aohMDa_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .aohMDa_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .aohMDa_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .aohMDa_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .aohMDa_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .aohMDa_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .aohMDa_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .aohMDa_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .aohMDa_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .aohMDa_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .aohMDa_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .aohMDa_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .aohMDa_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .aohMDa_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .aohMDa_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .aohMDa_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .aohMDa_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .aohMDa_mt-xxl-auto {
    margin-top: auto !important;
  }

  .aohMDa_me-xxl-0 {
    margin-right: 0 !important;
  }

  .aohMDa_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .aohMDa_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .aohMDa_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .aohMDa_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .aohMDa_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .aohMDa_me-xxl-auto {
    margin-right: auto !important;
  }

  .aohMDa_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .aohMDa_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .aohMDa_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .aohMDa_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .aohMDa_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .aohMDa_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .aohMDa_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .aohMDa_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .aohMDa_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .aohMDa_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .aohMDa_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .aohMDa_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .aohMDa_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .aohMDa_ms-xxl-auto {
    margin-left: auto !important;
  }

  .aohMDa_p-xxl-0 {
    padding: 0 !important;
  }

  .aohMDa_p-xxl-1 {
    padding: .25rem !important;
  }

  .aohMDa_p-xxl-2 {
    padding: .5rem !important;
  }

  .aohMDa_p-xxl-3 {
    padding: 1rem !important;
  }

  .aohMDa_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .aohMDa_p-xxl-5 {
    padding: 3rem !important;
  }

  .aohMDa_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .aohMDa_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .aohMDa_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .aohMDa_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .aohMDa_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .aohMDa_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .aohMDa_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .aohMDa_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .aohMDa_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .aohMDa_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .aohMDa_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .aohMDa_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .aohMDa_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .aohMDa_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .aohMDa_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .aohMDa_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .aohMDa_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .aohMDa_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .aohMDa_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .aohMDa_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .aohMDa_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .aohMDa_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .aohMDa_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .aohMDa_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .aohMDa_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .aohMDa_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .aohMDa_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .aohMDa_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .aohMDa_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .aohMDa_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .aohMDa_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .aohMDa_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .aohMDa_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .aohMDa_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .aohMDa_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .aohMDa_gap-xxl-0 {
    gap: 0 !important;
  }

  .aohMDa_gap-xxl-1 {
    gap: .25rem !important;
  }

  .aohMDa_gap-xxl-2 {
    gap: .5rem !important;
  }

  .aohMDa_gap-xxl-3 {
    gap: 1rem !important;
  }

  .aohMDa_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .aohMDa_gap-xxl-5 {
    gap: 3rem !important;
  }

  .aohMDa_text-xxl-start {
    text-align: left !important;
  }

  .aohMDa_text-xxl-end {
    text-align: right !important;
  }

  .aohMDa_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .aohMDa_fs-1 {
    font-size: 2.5rem !important;
  }

  .aohMDa_fs-2 {
    font-size: 2rem !important;
  }

  .aohMDa_fs-3 {
    font-size: 1.75rem !important;
  }

  .aohMDa_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .aohMDa_d-print-inline {
    display: inline !important;
  }

  .aohMDa_d-print-inline-block {
    display: inline-block !important;
  }

  .aohMDa_d-print-block {
    display: block !important;
  }

  .aohMDa_d-print-grid {
    display: grid !important;
  }

  .aohMDa_d-print-table {
    display: table !important;
  }

  .aohMDa_d-print-table-row {
    display: table-row !important;
  }

  .aohMDa_d-print-table-cell {
    display: table-cell !important;
  }

  .aohMDa_d-print-flex {
    display: flex !important;
  }

  .aohMDa_d-print-inline-flex {
    display: inline-flex !important;
  }

  .aohMDa_d-print-none {
    display: none !important;
  }
}

.aohMDa_card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.aohMDa_nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.aohMDa_nav-tabs .aohMDa_nav-item .aohMDa_nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.aohMDa_nav-tabs .aohMDa_nav-item .aohMDa_nav-link.aohMDa_active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.aohMDa_modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#aohMDa_root {
  height: 100%;
}

.aohMDa_highcharts-credits {
  display: none !important;
}

h3, .aohMDa_h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.aohMDa_modal .aohMDa_modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.aohMDa_table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.aohMDa_table thead tr th.aohMDa_col-actions {
  width: 110px !important;
}

table.aohMDa_table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.aohMDa_table tbody tr.aohMDa_deleting {
  background-color: #ff323233 !important;
}

.aohMDa_spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.aohMDa_btn-sm, .aohMDa_btn-group-sm > .aohMDa_btn {
  font-size: .6rem !important;
}

.aohMDa_modal {
  background-color: #0a0a0a4d;
}

.aohMDa_btn:focus {
  box-shadow: none !important;
}

.aohMDa_content-filter .aohMDa_react-datepicker-wrapper {
  width: auto !important;
}

.aohMDa_content-filter .aohMDa_react-datepicker-wrapper .aohMDa_form-control {
  font-size: .85rem !important;
}

.aohMDa_content-filter .aohMDa_input-group-text, .aohMDa_content-filter .aohMDa_btn {
  height: 2.15rem !important;
}

.aohMDa_content-filter .aohMDa_btn {
  border-color: #ccc;
}

.aohMDa_shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.aohMDa_form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.aohMDa_table > :not(:first-child) {
  border-top: unset !important;
}

.aohMDa_chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.aohMDa_highcharts-series.aohMDa_highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.aohMDa_expandable {
  transition: all .5s;
}

.aohMDa_expandable > .aohMDa_expander-icon {
  cursor: pointer;
}

.aohMDa_expandable > .aohMDa_expander-icon:hover {
  color: #328cfa66;
}

.aohMDa_expandable.aohMDa_expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .aohMDa_modal .aohMDa_modal-xl {
    width: 100% !important;
  }

  .aohMDa_modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.aohMDa_d-none, .aohMDa_d-block {
  transition: all .5s;
}

body {
  background-color: #e5e5e5 !important;
}

.aohMDa_toolbar {
  height: 64px;
  background-color: #fff;
  align-items: center;
  display: flex;
}

.aohMDa_toolbar .aohMDa_slot {
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.aohMDa_toolbar .aohMDa_steps {
  flex: 1;
  justify-content: center;
  gap: 5px;
  display: flex;
}

.aohMDa_toolbar .aohMDa_steps .aohMDa_step {
  width: 75px;
  height: 4px;
  background-color: #d3d6e4;
  border-radius: 100px;
}

.aohMDa_toolbar .aohMDa_steps .aohMDa_step.aohMDa_active {
  background-color: #4c469c;
}

.aohMDa_header {
  max-width: 786px;
  text-align: center;
  margin: 50px auto;
}

.aohMDa_content {
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  display: grid;
}

@media (max-width: 767px) {
  .aohMDa_content {
    grid-template-columns: auto auto;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .aohMDa_content {
    grid-template-columns: auto auto auto;
  }
}

.aohMDa_content .aohMDa_card {
  height: 180px;
  width: 180px;
  cursor: pointer;
  background: #fff;
  border: 2px solid #4c469c00;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all .3s ease-in;
  display: flex;
  position: relative;
  box-shadow: 0 20px 44px #e5e5e540;
}

.aohMDa_content .aohMDa_card.aohMDa_active {
  border-color: #4c469c;
  transition: all .3s ease-out;
}

.aohMDa_content .aohMDa_card img {
  height: 63px;
  width: 63px;
  object-fit: contain;
}

.aohMDa_content .aohMDa_card p {
  text-align: center;
  color: #1e2034;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}

.aohMDa_actions {
  justify-content: center;
  margin: 50px 0;
  display: flex;
}

.FrgJRa_header {
  max-width: 786px;
  text-align: center;
  margin: 50px auto;
}

.FrgJRa_content {
  max-width: 360px;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  display: flex;
}

.eeqO1G_header {
  max-width: 786px;
  text-align: center;
  margin: 50px auto;
}

.eeqO1G_content {
  max-width: 450px;
  margin: 0 auto;
}

.eeqO1G_card {
  background: #fff;
  border-radius: 24px;
  padding: 36px;
}

.eeqO1G_grid {
  grid-template-columns: auto auto;
  justify-content: space-between;
  display: grid;
}

.YWageq_card {
  text-align: center;
  background: #fff;
  border-radius: 24px;
  padding: 36px;
}

.YWageq_content {
  max-width: 450px;
  margin: 50px auto;
}

.YQsD3W_card {
  text-align: center;
  background: #fff;
  border-radius: 24px;
  padding: 36px;
}

.YQsD3W_content {
  max-width: 450px;
  margin: 50px auto;
}

.JA7vkq_blankPage {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: row;
  place-content: center;
  align-items: center;
  display: flex;
}

.JA7vkq_blankPage .JA7vkq_blankContent {
  min-width: 50%;
  min-height: 50%;
  background-color: #f6f6f6;
  border-radius: 1rem;
  padding: 5rem;
}

.JA7vkq_blankPage .JA7vkq_blankContent h3 {
  color: #272727;
  font-size: 30px;
}

.JA7vkq_blankPage .JA7vkq_blankContent h5 {
  color: #272727;
  font-size: 24px;
}

.JA7vkq_blankPage .JA7vkq_blankContent img {
  max-height: 100px;
}

.JA7vkq_fullPage {
  height: 100vh !important;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Thin.edd991b1.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-UltraLight.b01f739d.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Light.8e9d009a.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Regular.fb1f6720.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Medium.363e195b.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-SemiBold.a4cc74ba.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Bold.43615017.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-ExtraBold.9ddb8f94.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("Radomir Tinkov - Gilroy-Black.826583ae.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4c469c;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 76, 70, 156;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #4c469c;
  --bs-link-hover-color: #3d387d;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdaeb;
  --bs-table-border-color: #c5c4d4;
  --bs-table-striped-bg: #d0cfdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5c4d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbcad9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #a6a3ce;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.form-check-input:checked {
  background-color: #4c469c;
  border-color: #4c469c;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #4c469c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #4c469c;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a6a3ce'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #4c469c40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c9c8e1;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4c469c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c9c8e1;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #413c85;
  --bs-btn-hover-border-color: #3d387d;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d387d;
  --bs-btn-active-border-color: #393575;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4c469c;
  --bs-btn-disabled-border-color: #4c469c;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #4c469c;
  --bs-btn-border-color: #4c469c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c469c;
  --bs-btn-hover-border-color: #4c469c;
  --bs-btn-focus-shadow-rgb: 76, 70, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c469c;
  --bs-btn-active-border-color: #4c469c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #4c469c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c469c;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 103, 98, 171;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4c469c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #4c469c;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23443f8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #a6a3ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #443f8c;
  --bs-accordion-active-bg: #ededf5;
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #4c469c40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #4c469c;
  --bs-pagination-active-border-color: #4c469c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #2e2a5e;
  --bs-alert-bg: #dbdaeb;
  --bs-alert-border-color: #c9c8e1;
}

.alert-primary .alert-link {
  color: #25224b;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #4c469c;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #4c469c;
  --bs-list-group-active-border-color: #4c469c;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #2e2a5e;
  background-color: #dbdaeb;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #2e2a5e;
  background-color: #c5c4d4;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2e2a5e;
  border-color: #2e2a5e;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #4c469c40;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(76, 70, 156, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #4c469c !important;
}

.link-primary:hover, .link-primary:focus {
  color: #3d387d !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.nav-tabs {
  border-bottom: unset;
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.nav-tabs .nav-item .nav-link {
  box-sizing: border-box;
  color: #616371;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  display: flex;
}

.nav-tabs .nav-item .nav-link.active {
  color: #4c469c;
  background-color: #0000;
  border: 1px solid #4c469c;
}

.modal-content {
  border-radius: 12px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  transition: all .2s;
  font-family: Gilroy, sans-serif !important;
  font-size: .9rem !important;
}

#root {
  height: 100%;
}

.highcharts-credits {
  display: none !important;
}

h3, .h3 {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p {
  color: #616371;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modal .modal-xl {
  width: 90%;
  max-width: 1200px !important;
}

table.table thead tr th {
  color: #787985;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.table thead tr th.col-actions {
  width: 110px !important;
}

table.table tbody tr {
  color: #4b4d5d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

table.table tbody tr.deleting {
  background-color: #ff323233 !important;
}

.spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: .6rem !important;
}

.modal {
  background-color: #0a0a0a4d;
}

.btn:focus {
  box-shadow: none !important;
}

.content-filter .react-datepicker-wrapper {
  width: auto !important;
}

.content-filter .react-datepicker-wrapper .form-control {
  font-size: .85rem !important;
}

.content-filter .input-group-text, .content-filter .btn {
  height: 2.15rem !important;
}

.content-filter .btn {
  border-color: #ccc;
}

.shadow-hover:hover {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 .5cm #64646433;
}

.form-control::placeholder {
  opacity: 1;
  color: #e0e0e0 !important;
}

.table > :not(:first-child) {
  border-top: unset !important;
}

.chip {
  height: 2rem;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: .1rem .5rem;
  line-height: 1.8rem;
}

.highcharts-series.highcharts-line-series path {
  stroke-width: 1px;
}

page[size="TRA"] {
  width: 3in;
  height: 7in;
  background: #fff;
  margin: 0 auto .5cm;
  display: block;
  box-shadow: 0 0 .5cm #00000080;
}

.expandable {
  transition: all .5s;
}

.expandable > .expander-icon {
  cursor: pointer;
}

.expandable > .expander-icon:hover {
  color: #328cfa66;
}

.expandable.expanded {
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .modal .modal-xl {
    width: 100% !important;
  }

  .modal-dialog {
    margin: auto !important;
  }
}

@media print {
  page[size="TRA"] {
    margin: 0;
  }
}

.d-none, .d-block {
  transition: all .5s;
}

/*# sourceMappingURL=index.2c3fae5f.css.map */
